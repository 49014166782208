import {
  Box,
  Flex,
  Text,
  Icon,
  Tooltip,
  useBreakpointValue,
  useColorMode,
} from "@chakra-ui/react";
import MerchantDomainBase from "entities/domain/admin/merchants/merchant-domain";
import ContactDomain from "entities/domain/customers/contact-domain";
import { useAppSelector } from "redux/hooks";
import React from "react";
import { useLocation, matchPath } from "react-router-dom";
import { ReactSVG } from "react-svg";
import { getChannelIcon } from "util/constants";

interface ChannelsAreaProps {
  contactToDisplay: ContactDomain;
  onChannelClick?: (chanId: string) => void;
  merchant: MerchantDomainBase;
}

const ChannelsArea = ({
  contactToDisplay,
  onChannelClick,
  merchant,
}: ChannelsAreaProps) => {
  const { colorMode } = useColorMode();
  const isBaseSize = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );
  const { activeConversation } = useAppSelector((state) => state.conversations);
  const { colorScheme } = useAppSelector((state) => state.theme);
  const { pathname } = useLocation();

  const isInboxRoute = matchPath(
    `/${merchant.id}/inbox/:conversation_id`,
    pathname
  );

  const isChannelSelected = (channelId: string) => {
    const isActiveChannel = activeConversation?.customerChannelId === channelId;

    return isActiveChannel && isInboxRoute;
  };

  return (
    <Flex
      direction="column"
      align="center"
      borderBottom="1px"
      borderColor="gray.50"
      py={2}
      gridGap={2}
    >
      <Text fontSize="sm" fontWeight={700}>
        Channels
      </Text>
      <Box fontSize="sm">
        {contactToDisplay.channels.map((chan) => {
          return chan.isActive ? (
            <Tooltip
              isDisabled={
                merchant.isMerchantChannelEnabled(chan.type) || isBaseSize
              }
              label={`Channel ${chan.getDisplayChannelName()} is disconnected from the account. Please connect it to use it.`}
              borderRadius="1rem"
              px={3}
              py={1}
              backgroundColor="rgba(54, 178, 250, 0.9)"
            >
              <Flex
                _hover={
                  merchant.isMerchantChannelEnabled(chan.type) &&
                  !isChannelSelected(chan.id!)
                    ? { opacity: 0.8 }
                    : {
                        bgColor: isChannelSelected(chan.id!)
                          ? colorMode === "dark"
                            ? `${colorScheme}.300`
                            : `${colorScheme}.600`
                          : "inherit",
                      }
                }
                bgColor={
                  isChannelSelected(chan.id!)
                    ? colorMode === "dark"
                      ? `${colorScheme}.200`
                      : `${colorScheme}.500`
                    : "inherit"
                }
                color={
                  !merchant.isMerchantChannelEnabled(chan.type)
                    ? "gray.400"
                    : isChannelSelected(chan.id!)
                    ? colorMode === "dark"
                      ? "black"
                      : "white"
                    : "inherit"
                }
                key={chan.id}
                justifyContent="flex-start"
                alignItems="center"
                pt={2}
                onClick={(e) => {
                  e.preventDefault();
                  if (
                    onChannelClick &&
                    merchant.isMerchantChannelEnabled(chan.type) &&
                    !contactToDisplay.isArchived
                  ) {
                    onChannelClick(chan.id!);
                  }
                }}
                cursor={
                  merchant.isMerchantChannelEnabled(chan.type)
                    ? "pointer"
                    : "not-allowed"
                }
                alignContent="flex-start"
                w="100%"
                gridGap={4}
                px={4}
                py={2}
                borderRadius="full"
              >
                <Icon
                  as={ReactSVG}
                  src={getChannelIcon(chan.type)}
                  __css={{
                    svg: {
                      height: "1rem",
                      width: "1rem",
                      margin: "0 auto",
                    },
                  }}
                />
                <Text>{chan.displayHandle}</Text>
              </Flex>
            </Tooltip>
          ) : null;
        })}
      </Box>
    </Flex>
  );
};

export default ChannelsArea;
