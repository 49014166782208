import { Button } from "@chakra-ui/button";
import ConfirmationDialog from "components/shared/ConfirmationDialog";
import React, { useState } from "react";

interface DisconnectIntegrationButtonProps {
  onClick: () => void;
}

const DisconnectIntegrationButton = ({
  onClick,
}: DisconnectIntegrationButtonProps) => {
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);

  return (
    <>
      <Button
        size="sm"
        colorScheme="red"
        variant="ghost"
        onClick={() => setShowConfirmationDialog(true)}
      >
        Disconnect
      </Button>
      <ConfirmationDialog
        headerText="Disconnect?"
        isDangerous={true}
        messageText="Are you sure you want to disconnect this integration?"
        buttonText="Yes"
        isOpen={showConfirmationDialog}
        setIsOpen={setShowConfirmationDialog}
        confirmationCallback={() => {
          setShowConfirmationDialog(false);
          onClick();
        }}
      />
    </>
  );
};

export default DisconnectIntegrationButton;
