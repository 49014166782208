import { useAuth0 } from "@auth0/auth0-react";
import { SettingsIcon } from "@chakra-ui/icons";
import {
  Icon,
  IconButton,
  StyleProps,
  useBreakpointValue,
  useColorMode,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import ChatBackgroundModal from "components/user-settings/TeamMemberSettings/ChatBackgroundModal";
import React, { useState } from "react";
import { useAppSelector } from "redux/hooks";
import AgentsService from "services/agents";

interface BackgroundSettingsProps {
  onBackgroundChange: (backgroundStyles: StyleProps) => void;
}

const BackgroundSettings: React.FC<BackgroundSettingsProps> = ({
  onBackgroundChange,
}) => {
  const auth0Context = useAuth0();
  const isBaseSize = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );
  const toast = useToast();
  const { colorMode } = useColorMode();
  const { currentAgent } = useAppSelector((state) => state.agents);
  const { merchant } = useAppSelector((state) => state.merchant);

  const [isHovered, setIsHovered] = useState<boolean>(false);
  const { isOpen, onClose, onOpen } = useDisclosure();

  const handleChatBackgroundChangeCompleted = async (value: string) => {
    try {
      await AgentsService.updateAgent(
        auth0Context,
        {
          id: currentAgent!.id,
          name: currentAgent!.name,
          surname: currentAgent!.surname,
          email: currentAgent!.email,
          notification_handle: currentAgent!.notificationConfig?.handle || "",
          notification_channel:
            currentAgent!.notificationConfig?.contactChannel || "",
          notification_preference:
            currentAgent!.notificationConfig?.preference || "",
          notification_timezone:
            currentAgent!.notificationConfig?.timezone || "",
          role_id: currentAgent!.getRole(merchant.id).id,
          active: currentAgent!.isActive,
          profile_picture_url: currentAgent!.getPicture(),
          player_ids: currentAgent!.playerIds || null,
          style_preferences: {
            chat_background: value || null,
            color_scheme: currentAgent!.stylePreferences?.colorScheme || null,
          },
        },
        merchant.id
      );

      return null;
    } catch (err) {
      /* eslint-disable no-console */
      console.error("Failed to save a agent: ", err);
      toast({
        status: "error",
        title: "Failed to save chat background settings!",
      });
      /* eslint-enable no-console */
      return null;
    }
  };

  return (
    <>
      <IconButton
        aria-label="Background settings"
        icon={
          <Icon
            as={SettingsIcon}
            color={
              isHovered
                ? "white"
                : colorMode === "dark"
                ? "gray.200"
                : "gray.600"
            }
          />
        }
        ml={isBaseSize ? 2 : 0}
        position="fixed"
        zIndex={999}
        colorScheme="gray"
        variant={isBaseSize || !isHovered ? "ghost" : "solid"}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        onClick={onOpen}
      />
      <ChatBackgroundModal
        isOpen={isOpen}
        onClose={onClose}
        onChatBackgroundChangeCompleted={handleChatBackgroundChangeCompleted}
        onChatBackgroundChange={onBackgroundChange}
      />
    </>
  );
};

export default BackgroundSettings;
