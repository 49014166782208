export enum MerchantUserGuides {
  ONBOARDING = "onboarding",
}

export default class MerchantUserGuidesDomain {
  pending: MerchantUserGuides[];

  constructor(pending: MerchantUserGuides[]) {
    this.pending = pending;
  }
}
