import { VStack, useColorMode } from "@chakra-ui/react";
import React from "react";

interface BuildingBlockProps {
  children: React.ReactNode;
}

const BuildingBlockWrapper = ({ children }: BuildingBlockProps) => {
  const { colorMode } = useColorMode();

  return (
    <VStack
      w="100%"
      borderRadius="xl"
      borderStyle="dashed"
      borderWidth="2px"
      borderColor={colorMode === "dark" ? "gray.600" : "gray.200"}
      position="relative"
      overflow="hidden"
      spacing={0}
      flexShrink={0}
    >
      {children}
    </VStack>
  );
};

export default BuildingBlockWrapper;
