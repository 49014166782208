import ChannelDomain from "entities/domain/contacts/contact-domain";
import ConversationDomain, {
  AllowedMessageType,
} from "entities/domain/conversations/conversation-domain";
import MessageDomain, {
  MessageStatus,
} from "entities/domain/conversations/message-domain";
import CustomerChannelDomain from "entities/domain/customers/contact-channel-domain";
import unionBy from "lodash.unionby";

export interface ConversationDomainUpdate {
  isOpen?: boolean;
  unreadCount?: number;
  assignedAgentId?: number;
  assignedTeamId?: string;
  isSubscribed?: boolean;
  previewText?: string;
  displayDate?: Date;
  allowedMessageType?: AllowedMessageType;
  lastMessageStatus?: MessageStatus;
  lastMessageTitle?: string;
  messageId?: number;
  displayName?: string;
  picture?: string;
  tagIds?: string[];
  channels?: CustomerChannelDomain[];
}

export const updateConversation = (
  conversation: ConversationDomain,
  updates: ConversationDomainUpdate
): ConversationDomain => {
  let newIsSubscribed: boolean | undefined = updates.isSubscribed;

  if (
    updates.isSubscribed === undefined &&
    updates.channels &&
    updates.channels.length > 0
  ) {
    const conversationChannel = updates.channels.find(
      (c) => c.id === conversation.customerChannelId
    );

    if (conversationChannel) {
      newIsSubscribed = conversationChannel.isSubscribed;
    }
  }

  return new ConversationDomain(
    conversation.id,
    conversation.merchantId,
    conversation.customerId,
    updates.displayName !== undefined
      ? updates.displayName
      : conversation.displayName,
    updates.picture !== undefined ? updates.picture : conversation.picture,
    conversation.customerChannelId,
    conversation.alias,
    conversation.channel,
    updates.isOpen !== undefined ? updates.isOpen : conversation.isOpen,
    conversation.createdAt,
    updates.unreadCount !== undefined
      ? updates.unreadCount
      : conversation.unreadCount,
    updates.assignedAgentId
      ? updates.assignedAgentId
      : conversation.assignedAgentId,
    updates.assignedTeamId
      ? updates.assignedTeamId
      : conversation.assignedTeamId,
    typeof newIsSubscribed !== "undefined"
      ? newIsSubscribed
      : conversation.isSubscribed,
    updates.previewText ? updates.previewText : conversation.previewText,
    updates.lastMessageStatus
      ? updates.lastMessageStatus
      : conversation.lastMessageStatus,
    updates.displayDate ? updates.displayDate : conversation.displayDate,
    updates.messageId ? updates.messageId : conversation.messageId,
    updates.allowedMessageType
      ? updates.allowedMessageType
      : conversation.allowedMessagesType,
    updates.tagIds !== undefined ? updates.tagIds : conversation.tagIds,
    updates.lastMessageTitle
      ? updates.lastMessageTitle
      : conversation.lastMessageTitle
  );
};

export const sortDates = (c1: Date, c2: Date): number => {
  if (c1.getTime() === c2.getTime()) {
    return 0;
  }

  if (c1.getTime() > c2.getTime()) {
    return -1;
  }

  return 1;
};

export const sortConversations = (
  c1: ConversationDomain,
  c2: ConversationDomain
): number => {
  if (c1.unreadCount !== c2.unreadCount) {
    return c1.unreadCount > c2.unreadCount ? -1 : 1;
  }

  if (c1.displayDate.getTime() !== c2.displayDate.getTime()) {
    return c1.displayDate.getTime() > c2.displayDate.getTime() ? -1 : 1;
  }

  return 0;
};

export const replaceOrAppendConversationInArray = (
  conversation: ConversationDomain,
  conversations: ConversationDomain[]
): ConversationDomain[] => {
  if (conversations.filter((c) => c.id === conversation.id).length !== 0) {
    return conversations.map((c) =>
      conversation?.id === c.id ? conversation : c
    );
  }

  return [conversation, ...conversations];
};

export const replaceOrAppendMessagesInArray = (
  newMessages: MessageDomain[],
  oldMessages: MessageDomain[]
): MessageDomain[] => {
  oldMessages.forEach((oldMessage, currentIndex) => {
    const foundIndex = newMessages.findIndex(
      (newMessage) => newMessage.id === oldMessage.id
    );

    if (foundIndex !== -1) {
      oldMessages[currentIndex] = newMessages[foundIndex];
    }

    return newMessages;
  });

  return unionBy(newMessages, oldMessages, "id");
};
