import React from "react";
import { ReactComponent as UploadIcon } from "assets/icons/Union-ds.svg";
import { Icon, Button, Text, Heading } from "@chakra-ui/react";
import { useAppSelector } from "redux/hooks";

interface PaymentsStepTwoProps {
  amount: string;
  servicesRendered: string;
  proceed: () => void;
  proceedWithInvoice: boolean;
  showInvoice: boolean;
}

const PaymentsStepTwo = ({
  amount,
  servicesRendered,
  proceed,
  proceedWithInvoice,
  showInvoice,
}: PaymentsStepTwoProps) => {
  const { colorScheme } = useAppSelector((state) => state.theme);

  return (
    <>
      <Heading display="block" mb="22px" textAlign="center" size="sm">
        Are you sure you want to send this request?
      </Heading>

      <Heading display="block" mb="8px" size="sm" color={`${colorScheme}.400`}>
        {amount}
      </Heading>

      <Text
        display="block"
        overflowY="scroll"
        __css={{
          "::-webkit-scrollbar": {
            display: "none",
          },
        }}
      >
        {servicesRendered}
      </Text>
      {showInvoice && (
        <Text fontSize="sm" mb="5px" mt="15px">
          {proceedWithInvoice ? "* Invoice included" : "* Invoice not included"}
        </Text>
      )}

      <Button colorScheme={colorScheme} onClick={proceed} w="100%" mt={2}>
        Confirm and Send
        <Icon
          position="absolute"
          right={3}
          as={UploadIcon}
          __css={{
            path: {
              fill: "white",
            },
          }}
        />
      </Button>
    </>
  );
};

export default PaymentsStepTwo;
