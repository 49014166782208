import React, {
  ChangeEvent,
  forwardRef,
  ForwardRefRenderFunction,
} from "react";
import ReachPhoneNumberInput, {
  DefaultInputComponentProps,
  FeatureProps as PhoneInputPropsLib,
} from "react-phone-number-input";
import Input, { InputProps } from "./Input";

type PhoneInputProps = {
  setPhoneNumber?: (value: string) => void;
} & PhoneInputPropsLib<DefaultInputComponentProps> &
  InputProps;

const PhoneInput: ForwardRefRenderFunction<
  HTMLInputElement,
  PhoneInputProps
> = ({ setPhoneNumber, onChange, error, ...rest }: PhoneInputProps, ref) => {
  return (
    <Input
      as={ReachPhoneNumberInput}
      international={true}
      defaultCountry="GB"
      style={
        error && {
          borderColor: "#E53E3E",
          boxShadow: "0 0 0 1px #e53e3e",
        }
      }
      sx={{
        ".PhoneInputInput": {
          backgroundColor: "transparent",
          border: 0,
          _autofill: {
            boxShadow: `0 0 0px 20px transparent inset`,
            transition: "background-color 5000s ease-in-out 0s",
          },
        },
        ".PhoneInputInput::placeholder": {
          color: rest.placeholderColor,
        },
      }}
      error={error}
      ref={ref}
      {...rest}
      onChange={(e) => {
        if (setPhoneNumber) {
          setPhoneNumber(e?.toString());
        }
        // thanks to react-phone-number-input that changed the default onChange event type to string
        // we need to work around it to have Chakra + react hook form working as expected
        if (onChange) {
          onChange({
            target: {
              name: rest.name,
              type: rest.type,
              value: e?.toString(),
            },
          } as ChangeEvent<HTMLInputElement>);
        }
      }}
    />
  );
};

export default forwardRef(PhoneInput);
