import { ThemeTypings } from "@chakra-ui/react";

export type ContactFormInputConfigProps = {
  borderColor?: string;
  usePlaceholders?: boolean;
  placeholderColor?: string;
};

export type ContactFormConfigProps = {
  merchantId: string;
  title?: string;
  subtitle?: string;
  bgColor?: string;
  textColor?: string;
  buttonColorScheme?: ThemeTypings["colorSchemes"];
  noFooter?: boolean;
  compactVersion?: boolean;
} & ContactFormInputConfigProps;

export const parseContactFormConfig = (
  queryParams: URLSearchParams
): [ContactFormConfigProps | null, ContactFormInputConfigProps | null] => {
  let config: ContactFormConfigProps | null = null;
  let inputConfig: ContactFormInputConfigProps | null = null;

  const rawConfig = queryParams.get("config");
  if (rawConfig) {
    config = JSON.parse(rawConfig);
    inputConfig = {
      borderColor: config?.borderColor,
      usePlaceholders: config?.usePlaceholders,
      placeholderColor: config?.placeholderColor,
    };
  } else {
    const providedMerchantId = queryParams.get("merchant-id");
    if (providedMerchantId) {
      config = { merchantId: providedMerchantId };
    }
  }

  return [config, inputConfig];
};
