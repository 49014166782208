import FileDomain from "entities/domain/file";
import { SIMPLE_TYPE, getSimpleType } from "components/shared/FilePreview";
import React, { memo } from "react";
import ImageThumbnail from "./ImageThumbnail";
import VideoThumbnail from "./VideoThumbnail";
import DocumentThumbnail from "./DocumentThumbnail";

const FileAvatarColumn = ({
  item,
  openGallery,
}: {
  item: FileDomain;
  openGallery?: (startId: string) => void;
}) => {
  switch (getSimpleType(item.type)) {
    case SIMPLE_TYPE.IMAGE:
      return (
        <ImageThumbnail
          url={item.url}
          itemId={item.id}
          openGallery={openGallery}
        />
      );
    case SIMPLE_TYPE.VIDEO:
      return (
        <VideoThumbnail
          url={item.url}
          itemId={item.id}
          openGallery={openGallery}
        />
      );
    case SIMPLE_TYPE.PDF:
      return (
        <DocumentThumbnail
          url={item.url}
          itemId={item.id}
          openGallery={openGallery}
        />
      );
    case SIMPLE_TYPE.UNKNOWN:
    case SIMPLE_TYPE.BROKEN:
    default:
      return null;
  }
};

export default memo(FileAvatarColumn);
