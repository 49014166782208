import { ChevronRightIcon } from "@chakra-ui/icons";
import {
  Button,
  Flex,
  Icon,
  SlideFade,
  useBreakpointValue,
  useColorMode,
} from "@chakra-ui/react";
import { useAppSelector } from "redux/hooks";
import React, { memo, useCallback } from "react";
import EmojiShortcut from "./EmojiShortcut";
import MoreActionsMenu from "./MoreActionsMenu";
import TemplatesShortcut from "./TemplatesShortcut";

interface InputActionsProps {
  isCollapsed: boolean;
  message: string | undefined;
  isDisabled?: boolean;
  showTemplatesPopover: boolean;
  setFile: (url: string | null, type: string | null, id: string | null) => void;
  setShowTemplatesPopover: (show: boolean) => void;
  updateEditorText: (m: string) => void;
  setIsLoadingSuggestion: (loading: boolean) => void;
  expand?: () => void;
  insertText: (text: string) => void;
}

const InputActions = ({
  isCollapsed,
  message,
  isDisabled = false,
  showTemplatesPopover,
  setFile,
  insertText,
  setShowTemplatesPopover,
  updateEditorText,
  setIsLoadingSuggestion,
  expand,
}: InputActionsProps) => {
  const isBaseSize = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );
  const { colorMode } = useColorMode();
  const { colorScheme } = useAppSelector((state) => state.theme);
  const { activeConversation } = useAppSelector((state) => state.conversations);

  const setShowTemplatesPopoverCallback = useCallback(
    setShowTemplatesPopover,
    []
  );
  const onEmojiClick = useCallback(({ native }: { native: string }) => {
    insertText(native);
  }, []);

  return (
    <Flex alignSelf="center">
      {isBaseSize ? (
        <SlideFade in={isCollapsed} offsetY={0} offsetX={8}>
          <Button
            opacity={isCollapsed ? 1 : 0}
            colorScheme={colorScheme}
            display={isCollapsed ? "initial" : "none"}
            variant="ghost"
            width="2rem"
            height="2rem"
            p={0}
            isDisabled={isDisabled}
            onClick={() => {
              if (expand === undefined) {
                return;
              }

              expand();
            }}
          >
            <Icon
              aria-label="Select an action"
              as={ChevronRightIcon}
              __css={{
                width: "2rem",
                height: "2rem",
                path: {
                  fill:
                    colorMode === "light"
                      ? `${colorScheme}.500`
                      : `${colorScheme}.200`,
                },
              }}
            />
          </Button>
        </SlideFade>
      ) : null}
      <SlideFade in={isBaseSize ? !isCollapsed : true} offsetY={0} offsetX={-8}>
        <Flex
          alignItems="center"
          justifyContent="start"
          gridGap={1}
          display={isBaseSize ? (!isCollapsed ? "flex" : "none") : "flex"}
        >
          <MoreActionsMenu
            setFile={setFile}
            message={message}
            updateEditorText={updateEditorText}
            setIsLoadingSuggestion={setIsLoadingSuggestion}
            isDisabled={isDisabled}
          />
          <TemplatesShortcut
            showTemplatesPopover={showTemplatesPopover}
            setShowTemplatesPopover={setShowTemplatesPopoverCallback}
            isDisabled={isDisabled}
          />
          {isBaseSize ||
          activeConversation?.isTemplatesOnly() ||
          isDisabled ? null : (
            <EmojiShortcut onEmojiClick={onEmojiClick} />
          )}
        </Flex>
      </SlideFade>
    </Flex>
  );
};

export default memo(InputActions);
