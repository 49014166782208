import {
  Flex,
  Icon,
  IconButton,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  useColorMode,
} from "@chakra-ui/react";
import MergeContact from "components/modals/merge-contact/MergeContact";
import ConfirmationDialog from "components/shared/ConfirmationDialog";
import ContactDomain from "entities/domain/customers/contact-domain";
import useContactsStore from "hooks/use-contacts-store";
import React, { useState } from "react";
import { FiMoreHorizontal } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "redux/hooks";
import { canDeleteContacts } from "util/permissions";

interface ActionsProps {
  contactToDisplay: ContactDomain;
  setRecentlyUpdatedContact: (contact: ContactDomain | undefined) => void;
}

const Actions = ({
  contactToDisplay,
  setRecentlyUpdatedContact,
}: ActionsProps) => {
  const { colorMode } = useColorMode();
  const { deleteContact } = useContactsStore();
  const { currentAgent } = useAppSelector((state) => state.agents);
  const { merchant } = useAppSelector((state) => state.merchant);
  const navigate = useNavigate();

  const [openMergeForm, setOpenMergeForm] = useState<boolean>(false);

  const [isDeleteConfirmationDialogOpen, setIsDeleteConfirmationDialogOpen] =
    useState<boolean>(false);

  const handleCloseMergeModal = (
    updatedContactResponse: ContactDomain | undefined
  ) => {
    if (updatedContactResponse) {
      setRecentlyUpdatedContact(updatedContactResponse);
    }
    setOpenMergeForm(false);
  };

  return (
    <>
      <Popover>
        <PopoverTrigger>
          <IconButton
            aria-label="More actions"
            variant="ghost"
            colorScheme="gray"
          >
            <Icon as={FiMoreHorizontal} />
          </IconButton>
        </PopoverTrigger>

        <PopoverContent
          _focus={{ outline: "none" }}
          boxShadow="0px 3px 33px rgba(137, 156, 197, 0.393111)"
          w="170px"
          borderRadius="12px"
          style={{
            transition: "all 0.1s",
            outline: "none",
          }}
        >
          <PopoverArrow />
          <PopoverBody p={0}>
            <Flex
              bg="transparent"
              w="100%"
              p={4}
              cursor="pointer"
              onClick={() =>
                navigate(
                  `/${merchant.id}/contacts/edit/${contactToDisplay?.id}`
                )
              }
              _hover={{
                background: colorMode === "dark" ? "gray.900" : "gray.50",
                borderTopRadius: "12px",
              }}
            >
              Edit Contact
            </Flex>
            <Flex
              bg="transparent"
              w="100%"
              p={4}
              cursor="pointer"
              onClick={() => setOpenMergeForm(true)}
              _hover={{
                background: colorMode === "dark" ? "gray.900" : "gray.50",
                borderBottomRadius: canDeleteContacts(
                  merchant.id,
                  currentAgent!
                )
                  ? "0px"
                  : "12px",
              }}
            >
              Merge Contact
            </Flex>
            {canDeleteContacts(merchant.id, currentAgent!) && (
              <Flex
                bg="transparent"
                w="100%"
                p={4}
                cursor="pointer"
                onClick={() => {
                  if (!contactToDisplay) {
                    return;
                  }

                  setIsDeleteConfirmationDialogOpen(true);
                }}
                _hover={{
                  background: colorMode === "dark" ? "gray.900" : "gray.50",
                  borderBottomRadius: "12px",
                }}
              >
                Delete Contact
              </Flex>
            )}
          </PopoverBody>
        </PopoverContent>
        <ConfirmationDialog
          headerText="Delete Contact?"
          messageText="Are you sure you want to delete this contact?"
          buttonText="Yes"
          cancelButtonText="No"
          isDangerous={true}
          isOpen={isDeleteConfirmationDialogOpen}
          setIsOpen={setIsDeleteConfirmationDialogOpen}
          confirmationCallback={() => deleteContact(contactToDisplay.id!)}
        />
      </Popover>
      <MergeContact
        contactToMerge={contactToDisplay}
        isOpen={openMergeForm}
        onClose={handleCloseMergeModal}
      />
    </>
  );
};

export default Actions;
