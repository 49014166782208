import {
  Flex,
  Heading,
  Icon,
  Image,
  Text,
  useColorMode,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import SadEmojiImage from "assets/icons/sad-emoji.png";
import { ReactSVG } from "react-svg";
import { useLocation } from "react-router-dom";

export interface CallbackFailContent {
  title: string;
  description: string;
}

const CallbackFail = () => {
  const { search } = useLocation();

  const [title, setTitle] = useState<string>("So sorry!");
  const [description, setDescription] = useState<string>(
    "Whatever we tried to help you with - we couldn't get it done!"
  );

  const queryParams = new URLSearchParams(search);
  const integration = queryParams.get("integration");

  useEffect(() => {
    const content = localStorage.getItem("callback_fail");

    if (content) {
      try {
        const { title: parsedTitle, description: parsedDescription } =
          JSON.parse(content) as CallbackFailContent;

        setTitle(parsedTitle);
        setDescription(parsedDescription);
      } catch (e: unknown) {
        // eslint-disable-next-line
        console.error(e);
      } finally {
        localStorage.removeItem("callback_fail");
      }
    } else if (integration) {
      setTitle(`So sorry!`);
      setDescription(`We couldn't connect your ${integration} integration.`);
    }
  }, []);

  const { colorMode } = useColorMode();

  return (
    <Flex
      justifyContent="center"
      alignItems="center"
      direction="column"
      p={8}
      textAlign="center"
    >
      <Icon
        w="5rem"
        h="5rem"
        as={ReactSVG}
        __css={{
          svg: {
            height: "5rem",
            width: "5rem",
          },
        }}
        src={
          colorMode === "dark" ? "/fuzey-target-light.svg" : "/fuzey-target.svg"
        }
      />
      <Heading my={8} size="xl">
        {title}
      </Heading>
      <Image src={SadEmojiImage} />
      <Heading
        mt={8}
        size="lg"
        fontWeight={900}
        color={colorMode === "dark" ? "gray.200" : "gray.500"}
      >
        Feel free to close this tab now
      </Heading>
      <Text color="gray.300">{description}</Text>
    </Flex>
  );
};

export default CallbackFail;
