import React from "react";
import { createRoot } from "react-dom/client";
import "./index.css";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { Provider } from "react-redux";
import { ErrorBoundary } from "react-error-boundary";
import { ColorModeScript } from "@chakra-ui/color-mode";
import FuzeyTheme from "theme";
import Auth0ProviderWithHistory from "auth0ProviderWithHistory";
import Auth0Wrapper from "auth0Wrapper";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import Store from "./redux/store";
import ErrorFallback from "./ErrorFallback";

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [new Integrations.BrowserTracing()],
  environment: process.env.REACT_APP_ENVIRONMENT,
  release: process.env.REACT_APP_SENTRY_RELEASE,

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

const container = document.getElementById("root");
const root = createRoot(container!);

root.render(
  <React.StrictMode>
    <Provider store={Store}>
      <Auth0ProviderWithHistory>
        <Auth0Wrapper>
          <ColorModeScript
            initialColorMode={FuzeyTheme.config.initialColorMode}
          />
          <ErrorBoundary
            FallbackComponent={ErrorFallback}
            onError={(error) => {
              Sentry.captureException(new Error("Global App Failure"), {
                extra: {
                  url: window.location.href,
                  reason: error.message,
                  merchantId:
                    localStorage.getItem("fuzey:merchant:id") || "undefined",
                  stackTrace: error.stack,
                  errorCause: error.cause,
                  errorName: error.name,
                },
              });
            }}
          >
            <App />
          </ErrorBoundary>
        </Auth0Wrapper>
      </Auth0ProviderWithHistory>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
