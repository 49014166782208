export default class AdminKeyloopAccountDomain {
  enterpriseId: string;

  storeId: string;

  accountName: string | null;

  merchantIds: number[];

  constructor(
    enterpriseId: string,
    storeId: string,
    accountName: string | null,
    merchantIds: number[]
  ) {
    this.enterpriseId = enterpriseId;
    this.storeId = storeId;
    this.accountName = accountName;
    this.merchantIds = merchantIds;
  }
}
