import React from "react";
import { Box } from "@chakra-ui/react";
import Spinner from "./index";

const FullScreenSpinner = () => (
  <Box height={`${window.innerHeight}px`}>
    <Spinner />
  </Box>
);

export default FullScreenSpinner;
