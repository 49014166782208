import { CheckIcon, CopyIcon } from "@chakra-ui/icons";
import {
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  useClipboard,
} from "@chakra-ui/react";
import FileDomain from "entities/domain/file";
import React from "react";

const FileUrlColumn = ({ item }: { item: FileDomain }) => {
  const { onCopy, value, setValue, hasCopied } = useClipboard(item.url);

  return (
    <InputGroup size="sm">
      <Input
        value={value}
        isDisabled={true}
        pr="2rem"
        textOverflow="ellipsis"
      />
      <InputRightElement width="2rem">
        <IconButton
          aria-label="Copy File URL"
          size="xs"
          onClick={onCopy}
          variant="ghost"
          icon={hasCopied ? <Icon as={CheckIcon} /> : <Icon as={CopyIcon} />}
        />
      </InputRightElement>
    </InputGroup>
  );
};

export default FileUrlColumn;
