import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import MerchantDomainBase from "entities/domain/admin/merchants/merchant-domain";
import { IntegrationName } from "entities/domain/admin/merchants/merchant-integrations";
import { MerchantUsageDomain } from "entities/domain/admin/merchants/merchant-usage";
import MerchantBranchDomain from "entities/domain/branch";
import ChannelDomain from "entities/domain/contacts/contact-domain";
import { VehicleMakeAndModel } from "entities/domain/vehicle";
import { WorkingHoursDayDomain } from "entities/domain/working-hours";
import { getUTCOffsetByCountryCode } from "util/methods";

interface MerchantMakesAndModels {
  [key: string]: string[];
}

interface MerchantState {
  isInitialLoading: boolean;
  loading: boolean;
  errors: any[];
  makesAndModels: MerchantMakesAndModels;
  branches: MerchantBranchDomain[];
  merchant: MerchantDomainBase;
  isSelectorOpened: boolean;
  usage: MerchantUsageDomain | undefined;
  workingHours: Array<WorkingHoursDayDomain | null> | null;
  timeZoneOffset: number;
}
const initialState: MerchantState = {
  merchant: {} as MerchantDomainBase,
  branches: [],
  makesAndModels: {} as MerchantMakesAndModels,
  isInitialLoading: false,
  loading: false,
  workingHours: null,
  errors: [],
  isSelectorOpened: false,
  usage: undefined,
  timeZoneOffset: 0, // UTC
};

const merchantSlice = createSlice({
  name: "merchant",
  initialState,
  reducers: {
    fetchMerchant: (state) => {
      state.isInitialLoading = true;
      state.loading = true;
    },
    fetchMerchantSuccess: (
      state,
      action: PayloadAction<MerchantDomainBase>
    ) => {
      state.merchant = action.payload;
      state.timeZoneOffset = getUTCOffsetByCountryCode(action.payload.country);
      state.isInitialLoading = false;
      state.loading = false;
      state.errors = [];
    },
    setIsSelectorOpened: (state, action: PayloadAction<boolean>) => {
      state.isSelectorOpened = action.payload;
    },
    fetchMerchantFail: (state, action: PayloadAction<string[]>) => {
      state.errors = action.payload;
      state.isInitialLoading = false;
      state.loading = false;
    },
    setMerchantBranches: (
      state,
      action: PayloadAction<MerchantBranchDomain[]>
    ) => {
      state.branches = action.payload;
    },
    setWorkingHours: (
      state,
      action: PayloadAction<Array<WorkingHoursDayDomain | null>>
    ) => {
      state.workingHours = action.payload;
    },
    setMerchantMakesAndModels: (
      state,
      action: PayloadAction<VehicleMakeAndModel[]>
    ) => {
      const makesAndModels: MerchantMakesAndModels = {};

      action.payload.forEach((makeAndModel) => {
        if (!makesAndModels[makeAndModel.name]) {
          makesAndModels[makeAndModel.name] = [];
        }

        makesAndModels[makeAndModel.name].push(...makeAndModel.models);
      });

      state.makesAndModels = makesAndModels;
    },
    updateMerchant: (state) => {
      state.loading = true;
    },
    updateMerchantSuccess: (
      state,
      action: PayloadAction<MerchantDomainBase>
    ) => {
      state.merchant = action.payload;
      state.loading = false;
      state.errors = [];
    },
    updateMerchantFail: (state, action: PayloadAction<string[]>) => {
      state.errors = action.payload;
      state.loading = false;
    },
    assignMerchantChannelWebhook: (
      state,
      action: PayloadAction<ChannelDomain>
    ) => {
      state.merchant.channels = state.merchant.channels.map((channel) => {
        if (channel.id === action.payload.id) {
          return action.payload;
        }
        return channel;
      });
    },
    completeUserGuide: (state, action: PayloadAction<string>) => {
      state.merchant.userGuides.pending =
        state.merchant.userGuides.pending.filter(
          (guide) => guide !== action.payload
        );
    },
    fetchMerchantUsageSuccess: (
      state,
      action: PayloadAction<MerchantUsageDomain>
    ) => {
      state.usage = action.payload;
      state.errors = [];
    },
    fetchMerchantUsageFail: (state, action: PayloadAction<string[]>) => {
      state.errors = action.payload;
    },
  },
});

export const {
  fetchMerchant,
  fetchMerchantSuccess,
  fetchMerchantFail,
  updateMerchant,
  updateMerchantSuccess,
  updateMerchantFail,
  setIsSelectorOpened,
  assignMerchantChannelWebhook,
  completeUserGuide,
  fetchMerchantUsageSuccess,
  fetchMerchantUsageFail,
  setMerchantBranches,
  setMerchantMakesAndModels,
  setWorkingHours,
} = merchantSlice.actions;

export default merchantSlice.reducer;
