import { Box, Flex, Icon, useBreakpointValue } from "@chakra-ui/react";
import React from "react";
import { ReactComponent as FuzeyIcon } from "assets/icons/fuzey-target.svg";
import { ReactComponent as CellphoneIcon } from "assets/icons/smartphone.svg";
import { Keyframes, motion } from "framer-motion";
import { ReactSVG } from "react-svg";

interface MessageAnimationProps {
  animation: string;
}

const MessageAnimation = ({ animation }: MessageAnimationProps) => {
  const isBaseSize = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );

  return (
    <Flex
      justifyContent="space-between"
      position="relative"
      w={isBaseSize ? "100%" : "75%"}
      mx="auto"
      alignItems="center"
    >
      <Icon
        as={CellphoneIcon}
        boxSize={12}
        __css={{
          stroke: "#090C9B",
          // stroke: "#046095",
        }}
      />

      <Box
        as={motion.div}
        animation={animation}
        display="flex"
        position="absolute"
        left="10%"
        height="12"
      >
        <Icon
          as={ReactSVG}
          animation={animation}
          boxSize={8}
          src="/email.svg"
          __css={{
            svg: {
              width: "100%",
              height: "100%",
            },
          }}
        />
      </Box>

      <Icon as={FuzeyIcon} boxSize={14} />
    </Flex>
  );
};

export default MessageAnimation;
