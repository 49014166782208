import React, { useEffect, useState } from "react";
import ReviewReplyDomain from "entities/domain/reviews/review-reply-domain";
import useMerchantStore from "hooks/use-merchant-store";
import { Avatar, Flex, Heading, Link, Text } from "@chakra-ui/react";
import AgentDomain from "entities/domain/agents/agent-domain";
import { useAppSelector } from "redux/hooks";

interface ReviewReplyCardProps {
  reply: ReviewReplyDomain;
}

const ReviewReplyCard = ({ reply }: ReviewReplyCardProps) => {
  const { merchant } = useAppSelector((state) => state.merchant);
  const { agents } = useAppSelector((state) => state.agents);

  const [replyAuthor, setReplyAuthor] = useState<string>(merchant.name);
  const [replyAuthorAvatar, setReplyAuthorAvatar] = useState<
    string | undefined
  >(undefined);

  useEffect(() => {
    if (!agents.length || !reply.owner.agentId) {
      return;
    }

    const agent = agents.find((a: AgentDomain) => a.id === reply.owner.agentId);

    if (!agent) {
      return;
    }

    setReplyAuthor(agent.getFullName());
    setReplyAuthorAvatar(agent.getPicture() || undefined);
  }, [reply.owner.agentId, agents]);

  return (
    <Flex alignItems="flex-start" w="100%" gridGap={2}>
      <Avatar
        key={replyAuthor}
        size="sm"
        src={replyAuthorAvatar}
        name={replyAuthor}
      />
      <Flex width="100%" flexFlow="column nowrap" gridGap={2}>
        {reply.owner.agentId ? (
          <Link
            href={`/${merchant.id}/settings/teammates/${reply.owner.agentId}`}
            target="_blank"
          >
            <Heading as="h6" size="sm" textAlign="left">
              {replyAuthor}
            </Heading>
          </Link>
        ) : (
          <Heading as="h6" size="sm" textAlign="left">
            {replyAuthor}
          </Heading>
        )}
        <Text>{reply.content}</Text>
        <Text fontSize="xs" color="gray.500">
          {reply.createdAt}
        </Text>
      </Flex>
    </Flex>
  );
};

export default ReviewReplyCard;
