import {
  Box,
  Button,
  Card,
  CardBody,
  Flex,
  FormControl,
  FormLabel,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Text,
  useColorMode,
} from "@chakra-ui/react";
import { useAppSelector } from "redux/hooks";
import ReactDatePicker from "react-datepicker";
import React from "react";

interface ExportContactsModalProps {
  isOpen: boolean;
  isExportingCsv: boolean;
  setIsOpen: (isOpen: boolean) => void;
  handleExportAll: (from?: Date | null, to?: Date | null) => void;
  handleExportUnsub: (from?: Date | null, to?: Date | null) => void;
}

enum EXPORT_TYPE {
  ALL = "all",
  UNSUB = "unsub",
}

const ExportContactsModal: React.FC<ExportContactsModalProps> = ({
  isOpen,
  isExportingCsv,
  setIsOpen,
  handleExportAll,
  handleExportUnsub,
}) => {
  const { colorScheme } = useAppSelector((state) => state.theme);
  const { colorMode } = useColorMode();
  const [exportType, setExportType] = React.useState<EXPORT_TYPE | undefined>();
  const [fromDate, setFromDate] = React.useState<Date | null>(null);
  const [toDate, setToDate] = React.useState<Date | null>(null);

  const exportContacts = () => {
    switch (exportType) {
      case EXPORT_TYPE.ALL:
        handleExportAll(fromDate, toDate);
        break;
      case EXPORT_TYPE.UNSUB:
        handleExportUnsub(fromDate, toDate);
        break;
      default:
        break;
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={() => setIsOpen(false)}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Export Contacts</ModalHeader>
        <ModalCloseButton />
        <ModalBody
          display="flex"
          gridGap={4}
          flexDirection="column"
          position="relative"
        >
          <Flex
            display="flex"
            gridGap={4}
            justifyContent="center"
            alignItems="center"
          >
            <Card
              w="100%"
              onClick={() => {
                if (exportType === EXPORT_TYPE.ALL) {
                  setExportType(undefined);
                } else {
                  setExportType(EXPORT_TYPE.ALL);
                }
              }}
              {...(exportType === EXPORT_TYPE.ALL && {
                bg: colorMode === "dark" ? "gray.900" : "gray.50",
                borderColor:
                  colorMode === "dark"
                    ? `${colorScheme}.200`
                    : `${colorScheme}.500`,
                borderWidth: "3px",
                borderStyle: "solid",
                boxShadow: "md",
              })}
              cursor="pointer"
              p={4}
            >
              <CardBody
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <Text fontWeight="bold">All</Text>
              </CardBody>
            </Card>
            <Card
              w="100%"
              onClick={() => {
                if (exportType === EXPORT_TYPE.UNSUB) {
                  setExportType(undefined);
                } else {
                  setExportType(EXPORT_TYPE.UNSUB);
                }
              }}
              {...(exportType === EXPORT_TYPE.UNSUB && {
                bg: colorMode === "dark" ? "gray.900" : "gray.50",
                borderColor:
                  colorMode === "dark"
                    ? `${colorScheme}.200`
                    : `${colorScheme}.500`,
                borderWidth: "3px",
                borderStyle: "solid",
                boxShadow: "md",
              })}
              cursor="pointer"
              p={4}
            >
              <CardBody
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <Text fontWeight="bold">Unsubscribed</Text>
              </CardBody>
            </Card>
          </Flex>
          {exportType && (
            <Flex
              justifyContent="center"
              direction="column"
              alignItems="center"
              gridGap={2}
            >
              <FormControl mt={6}>
                <FormLabel fontWeight={700}>From</FormLabel>
                <Box
                  rounded="full"
                  borderWidth="1px"
                  borderColor={
                    colorMode === "dark"
                      ? `${colorScheme}.200`
                      : `${colorScheme}.500`
                  }
                  textAlign="left"
                  py={1}
                  px={3}
                  css={{
                    input: {
                      width: "100%",
                      backgroundColor: "inherit",
                    },
                    ".react-datepicker-wrapper": {
                      width: "100%",
                    },
                  }}
                >
                  <ReactDatePicker
                    id="export-from-date"
                    selected={fromDate}
                    placeholderText="Starting point"
                    onChange={(newDate) => {
                      setFromDate(newDate);
                    }}
                    startDate={new Date()}
                    showTimeSelect
                    timeFormat="HH:mm"
                    dateFormat="MMMM d, h:mm aa"
                  />
                </Box>
              </FormControl>
              <FormControl mt={6}>
                <FormLabel fontWeight={700}>To</FormLabel>
                <Box
                  rounded="full"
                  borderWidth="1px"
                  borderColor={
                    colorMode === "dark"
                      ? `${colorScheme}.200`
                      : `${colorScheme}.500`
                  }
                  textAlign="left"
                  py={1}
                  px={3}
                  css={{
                    input: {
                      width: "100%",
                      backgroundColor: "inherit",
                    },
                    ".react-datepicker-wrapper": {
                      width: "100%",
                    },
                  }}
                >
                  <ReactDatePicker
                    id="export-to-date"
                    selected={toDate}
                    placeholderText="Ending point"
                    onChange={(newDate) => {
                      setToDate(newDate);
                    }}
                    startDate={new Date()}
                    showTimeSelect
                    timeFormat="HH:mm"
                    dateFormat="MMMM d, h:mm aa"
                  />
                </Box>
              </FormControl>
            </Flex>
          )}
          {isExportingCsv ? (
            <Flex
              position="absolute"
              zIndex={99999}
              top={0}
              left={0}
              width="100%"
              height="100%"
              justifyContent="center"
              alignItems="center"
              bgColor={
                colorMode === "dark" ? "blackAlpha.900" : "whiteAlpha.900"
              }
            >
              <Spinner />
            </Flex>
          ) : null}
        </ModalBody>
        <ModalFooter
          mt={2}
          display="flex"
          gridGap={2}
          justifyContent="space-between"
          alignItems="center"
        >
          <Button
            variant="ghost"
            colorScheme={colorScheme}
            onClick={() => setIsOpen(false)}
          >
            Cancel
          </Button>
          <Button
            onClick={() => exportContacts()}
            colorScheme={colorScheme}
            isDisabled={isExportingCsv || !exportType}
          >
            Export
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ExportContactsModal;
