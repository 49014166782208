import { useEffect } from "react";
import {
  $getSelection,
  $isRangeSelection,
  COMMAND_PRIORITY_HIGH,
  COMMAND_PRIORITY_NORMAL,
  KEY_BACKSPACE_COMMAND,
  SELECTION_CHANGE_COMMAND,
  KEY_ARROW_LEFT_COMMAND,
} from "lexical";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { $isCustomFieldNode } from "../nodes/CustomFieldNode";

export default function TreatingCustomFieldPlugin() {
  const [lexicalEditor] = useLexicalComposerContext();

  useEffect(() => {
    lexicalEditor.registerCommand(
      KEY_BACKSPACE_COMMAND,
      (event: KeyboardEvent) => {
        const selection = $getSelection();
        const selectionNodes = selection?.getNodes() || [];
        const containsCustomFieldNode = selectionNodes.find($isCustomFieldNode);

        if (containsCustomFieldNode) {
          event.preventDefault();

          lexicalEditor.update(() => {
            containsCustomFieldNode.remove();
          });

          return true;
        } else if (
          selectionNodes.length === 1 &&
          selection &&
          $isRangeSelection(selection) &&
          selection.anchor.offset === 0
        ) {
          const previousSibling = selectionNodes[0].getPreviousSibling();

          if (previousSibling && $isCustomFieldNode(previousSibling)) {
            event.preventDefault();

            lexicalEditor.update(() => {
              previousSibling.remove();
            });

            return true;
          }
        }

        return false;
      },
      COMMAND_PRIORITY_NORMAL
    );

    lexicalEditor.registerCommand(
      SELECTION_CHANGE_COMMAND,
      () => {
        const selection = $getSelection();
        const selectionNodes = selection?.getNodes() || [];
        const customFieldNode = selectionNodes.find($isCustomFieldNode);

        if (!$isRangeSelection(selection)) {
          return false;
        }

        if (
          selection.focus.offset === selection.anchor.offset &&
          customFieldNode
        ) {
          lexicalEditor.update(() => {
            customFieldNode.selectNext(0, 0);
          });

          return true;
        }

        return false;
      },
      COMMAND_PRIORITY_NORMAL
    );

    lexicalEditor.registerCommand(
      KEY_ARROW_LEFT_COMMAND,
      () => {
        const selection = $getSelection();
        const selectionNodes = selection?.getNodes() || [];

        if (
          selectionNodes.length === 1 &&
          selection &&
          $isRangeSelection(selection) &&
          selection.anchor.offset === 0
        ) {
          const previousSibling = selectionNodes[0].getPreviousSibling();

          if (previousSibling && $isCustomFieldNode(previousSibling)) {
            lexicalEditor.update(() => {
              previousSibling.selectPrevious();
            });

            return true;
          }
        }

        return false;
      },
      COMMAND_PRIORITY_HIGH
    );
  }, [lexicalEditor]);

  return null;
}
