import React, { useEffect, useState } from "react";
import { Box, Image as ChakraImage, Image } from "@chakra-ui/react";
import { ConversationChannel } from "entities/domain/conversations/conversation-domain";
import MessageDomain, {
  AttachmentType,
} from "entities/domain/conversations/message-domain";
import EditorUltra, { EditorUltraPlugin } from "components/editor-ultra";
import AgentDomain from "entities/domain/agents/agent-domain";
import { useAppSelector } from "redux/hooks";
import DownloadExpiredIconButton from "./DownloadExpiredButton";
import MessageWrapper from "./MessageWrapper";
import ExpandableText from "./ExpandableText";

export default ({
  message,
  showTail,
}: {
  message: MessageDomain;
  showTail: boolean;
}) => {
  const { agents } = useAppSelector((state) => state.agents);
  const { activeConversation } = useAppSelector((state) => state.conversations);

  const [agent, setAgent] = useState<AgentDomain | undefined>(
    agents.find((a: AgentDomain) => a.id === message.agentId)
  );
  const [isOpenImg, setIsOpenImg] = useState<boolean>(false);
  const [brokenMediaType, setBrokenMediaType] = useState<
    AttachmentType | undefined
  >(undefined);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    setAgent(agents.find((a: AgentDomain) => a.id === message.agentId));
  }, [agents]);

  return (
    <MessageWrapper
      showTail={showTail}
      message={message}
      activeConversation={activeConversation}
      agent={agent}
      modalContent={
        <ChakraImage
          src={message.media?.url || message.attachments[0].url}
          onError={(event: any) => {
            setBrokenMediaType(message?.getAttachmentType());
          }}
        />
      }
      setIsOpen={setIsOpenImg}
      isOpen={isOpenImg}
    >
      <>
        {activeConversation?.isChannelFacebookOrInstagram() &&
        brokenMediaType === AttachmentType.IMAGE ? (
          <DownloadExpiredIconButton
            message={message}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            setBrokenMediaType={setBrokenMediaType}
          />
        ) : (
          <>
            <Image
              mt={2}
              height="250px"
              maxWidth="250px"
              maxHeight="250px"
              loading="lazy"
              alt="attachment"
              objectFit="cover"
              src={message.media?.url || message.attachments[0].url}
              borderRadius="lg"
              onError={(event: any) => {
                setBrokenMediaType(message?.getAttachmentType());
              }}
              onClick={() => setIsOpenImg(true)}
            />
          </>
        )}
        <Box mt={1}>
          {activeConversation?.channel === ConversationChannel.WHATSAPP ? (
            <EditorUltra
              fontColor={message.getFontColor()}
              isEditable={false}
              isScrollable={false}
              maxHeight="150px"
              showMore={true}
              backgroundColor="inherit"
              highlightUnknownCustomFields={false}
              defaultText={{
                value: message?.body,
              }}
              channels={[ConversationChannel.WHATSAPP]}
              enabledPlugins={[EditorUltraPlugin.RICHTEXT]}
            />
          ) : (
            <ExpandableText message={message} />
          )}
        </Box>
      </>
    </MessageWrapper>
  );
};
