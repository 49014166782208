import { Box, HStack, IconButton, Icon, useToast } from "@chakra-ui/react";
import { useAppSelector } from "redux/hooks";
import React, { useState } from "react";

import ContactDomain from "entities/domain/customers/contact-domain";
import { CheckIcon, CloseIcon } from "@chakra-ui/icons";
import ReviewsService from "services/reviews";
import { useAuth0 } from "@auth0/auth0-react";
import ReviewDomain from "entities/domain/reviews/review-domain";
import SelectCustomer from "./SelectCustomer";

interface AssignToCustomerFormProps {
  review: ReviewDomain;
  onClose: () => void;
}

const AssignToCustomerForm = ({
  review,
  onClose,
}: AssignToCustomerFormProps) => {
  const auth0Context = useAuth0();
  const { colorScheme } = useAppSelector((state) => state.theme);
  const { merchant } = useAppSelector((state) => state.merchant);
  const toast = useToast();

  const [selectedCustomer, setSelectedCustomer] =
    useState<ContactDomain | null>(null);

  const resetValues = () => {
    setSelectedCustomer(null);
  };

  const saveReview = async () => {
    try {
      await ReviewsService.assignCustomerToReview(
        auth0Context,
        merchant.id,
        review.id,
        selectedCustomer!.id!
      );
      toast({
        status: "success",
        title: "Customer was successfully assigned to review",
      });
    } catch (e: unknown) {
      // eslint-disable-next-line
      console.error("Couldn't assign customer to review", e);
      toast({ status: "error", title: "Couldn't assign customer to review" });
    } finally {
      resetValues();
      onClose();
    }
  };

  return (
    <Box w="100%">
      <HStack w="100%" spacing={3}>
        <SelectCustomer
          selectedCustomer={selectedCustomer}
          setSelectedCustomer={setSelectedCustomer}
        />
        <IconButton
          icon={<Icon as={CloseIcon} />}
          aria-label="Cancel Assigning Customer to Review"
          colorScheme={colorScheme}
          onClick={() => onClose()}
        />
        <IconButton
          icon={<Icon as={CheckIcon} />}
          aria-label="Select Customer for Review"
          colorScheme={colorScheme}
          onClick={saveReview}
          isDisabled={!selectedCustomer}
        />
      </HStack>
    </Box>
  );
};

export default AssignToCustomerForm;
