import { Auth0ContextInterface } from "@auth0/auth0-react/src/auth0-context";
import {
  CreateTemplatePayload,
  DeleteTemplatePayload,
  EditTemplatePayload,
  SyncTemplatePayload,
} from "redux/features/templates";
import axios from "axios";
import { canSendPayments } from "util/permissions";
import MerchantDomainBase from "entities/domain/admin/merchants/merchant-domain";
import TemplateDomain from "entities/domain/templates";
import FullTemplateDomain from "entities/domain/templates/full_template";
import { apiUrl } from "util/constants";
import {
  templateTransformFromDtoToDomain,
  templateTransformFromFullDtoToDomain,
} from "entities/transformers/templateTransformers";
import NewAgentDomain from "entities/domain/agents/new-agent-domain";
import {
  WhatsappButtonDTO,
  WhatsappTemplateDTO,
} from "entities/dto/WhatsappTemplateDTO";
import WhatsappTemplate, {
  WabaTemplateCategory,
} from "entities/domain/whatsapp-template";
import { whatsappTemplateTransformFromDtoToDomain } from "entities/transformers/whatsapp-template-transformer";
import { RequestType } from "./request-type";
import { FullTemplateDto, TemplateDTO } from "../entities/dto/TemplateDTO";
import { deleteRequest, putRequest, request } from "../util/methods";

export interface CreateWhatsappTemplatePayload {
  name: string;
  category: WabaTemplateCategory;
  headerText: string;
  bodyText: string;
  footerText: string;
  buttons: WhatsappButtonDTO[];
  examples: { [key: string]: string };
  file: File | null;
  mediaUrl: string | null;
  mediaType: string | null;
}

class TemplatesService {
  public static async getTemplatesForConversation(
    { getAccessTokenSilently, user }: Auth0ContextInterface,
    conversationId: number,
    merchant: MerchantDomainBase,
    currentAgent: NewAgentDomain
  ): Promise<TemplateDomain[]> {
    const accessToken = await getAccessTokenSilently();

    const templatesResponse = (
      await request<TemplateDTO[]>(
        RequestType.GET,
        accessToken,
        `/merchants/${merchant.id}/conversations/${conversationId}/templates`
      )
    ).data;

    return templatesResponse
      .map((t) => templateTransformFromDtoToDomain(t))
      .filter((t) => {
        if (t.id === "payment_reminder") {
          return canSendPayments(merchant.id, currentAgent);
        }

        return true;
      });
  }

  public static async getWhatsappTemplates(
    { getAccessTokenSilently, user }: Auth0ContextInterface,
    merchant: MerchantDomainBase,
    searchText?: string
  ): Promise<WhatsappTemplate[]> {
    const accessToken = await getAccessTokenSilently();

    const queryParams = new URLSearchParams();
    if (searchText) {
      queryParams.append("q", searchText);
    }

    const templatesResponse = (
      await request<WhatsappTemplateDTO[]>(
        RequestType.GET,
        accessToken,
        `/merchants/${merchant.id}/whatsapp-templates?${queryParams.toString()}`
      )
    ).data;

    return templatesResponse.map(whatsappTemplateTransformFromDtoToDomain);
  }

  public static async getTemplates(
    { getAccessTokenSilently }: Auth0ContextInterface,
    merchant: MerchantDomainBase,
    currentAgent: NewAgentDomain,
    searchText?: string,
    options?: {
      forMarketing?: boolean;
    }
  ): Promise<FullTemplateDomain[]> {
    const accessToken = await getAccessTokenSilently();

    const queryParams = new URLSearchParams();
    if (options?.forMarketing) {
      queryParams.append("category", "marketing");
    }
    if (searchText) {
      queryParams.append("q", searchText);
    }
    const url = `/merchants/${merchant.id}/templates?${queryParams.toString()}`;

    const templatesResponse = (
      await request<FullTemplateDto[]>(RequestType.GET, accessToken, url)
    ).data;

    return templatesResponse
      .map((t) => templateTransformFromFullDtoToDomain(t))
      .filter((t) => {
        if (t.name === "payment_reminder") {
          return canSendPayments(merchant.id, currentAgent);
        }

        return true;
      })
      .sort((t1, t2) =>
        t1.title.toLowerCase().localeCompare(t2.title.toLowerCase())
      );
  }

  public static async createTemplate(
    { getAccessTokenSilently }: Auth0ContextInterface,
    createTemplate: CreateTemplatePayload,
    merchantId: number
  ): Promise<FullTemplateDomain> {
    const accessToken = await getAccessTokenSilently();

    const {
      /* eslint-disable camelcase */
      title,
      text,
      channels,
      favourite,
      shortcut,
      subject,
      category,
      file,
      mediaUrl,
      mediaType,
    } = createTemplate;

    const formData = new FormData();

    formData.append("title", title);
    formData.append("text", text);
    formData.append("channels", channels ? channels.join(",") : "");
    formData.append("favourite", favourite ? "true" : "false");
    formData.append("shortcut", shortcut || "");
    formData.append("subject", subject || "");
    formData.append("category", category || "");

    if (file) {
      formData.append("file", file);
    } else if (mediaUrl && mediaType) {
      formData.append("media_url", mediaUrl);
      formData.append("media_type", mediaType);
    }

    const template = (
      await axios.post(
        `${apiUrl}/merchants/${merchantId}/templates`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-type": "multipart/form-data",
          },
        }
      )
    ).data;

    return templateTransformFromFullDtoToDomain(template);
  }

  public static async createWhatsappTemplate(
    { getAccessTokenSilently }: Auth0ContextInterface,
    payload: CreateWhatsappTemplatePayload,
    merchantId: number
  ): Promise<WhatsappTemplate | void> {
    const accessToken = await getAccessTokenSilently();

    const {
      /* eslint-disable camelcase */
      name,
      category,
      headerText,
      bodyText,
      footerText,
      buttons,
      mediaType,
      mediaUrl,
      examples,
      file,
    } = payload;

    if (!headerText && !bodyText && !footerText && !file) {
      return;
    }

    const formData = new FormData();

    formData.append("name", name);
    formData.append("category", category);

    if (file) {
      formData.append("file", file);
    } else if (mediaUrl && mediaType) {
      formData.append("media_url", mediaUrl);
      formData.append("media_type", mediaType);
    }

    if (examples) {
      formData.append("examples", JSON.stringify(examples));
    }

    if (headerText) {
      formData.append("header_text", headerText);
    }
    if (bodyText) {
      formData.append("body_text", bodyText);
    }
    if (footerText) {
      formData.append("footer_text", footerText);
    }
    if (buttons && buttons.length > 0) {
      formData.append("buttons", JSON.stringify(buttons));
    }

    const template = (
      await axios.post(
        `${apiUrl}/merchants/${merchantId}/whatsapp-templates`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-type": "multipart/form-data",
          },
        }
      )
    ).data;

    return whatsappTemplateTransformFromDtoToDomain(template);
  }

  public static async editTemplate(
    { getAccessTokenSilently }: Auth0ContextInterface,
    payload: EditTemplatePayload,
    merchantId: number
  ): Promise<FullTemplateDomain> {
    const accessToken = await getAccessTokenSilently();
    const {
      id,
      title,
      text,
      channels,
      favourite,
      shortcut,
      subject,
      category,
      file,
      mediaType,
      mediaUrl,
    } = payload;

    const formData = new FormData();

    formData.append("id", id);
    formData.append("title", title);
    formData.append("text", text);
    formData.append("channels", channels ? channels.join(",") : "");
    formData.append("favourite", favourite ? "true" : "false");
    formData.append("shortcut", shortcut || "");
    formData.append("subject", subject || "");
    formData.append("category", category || "");

    if (file) {
      formData.append("file", file);
    } else if (mediaType && mediaUrl) {
      formData.append("media_type", mediaType);
      formData.append("media_url", mediaUrl);
    }

    const templateResponse = (
      await axios.put(
        `${apiUrl}/merchants/${merchantId}/templates/${encodeURIComponent(id)}`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-type": "multipart/form-data",
          },
        }
      )
    ).data;

    return templateTransformFromFullDtoToDomain(templateResponse);
  }

  public static async deleteTemplate(
    { getAccessTokenSilently }: Auth0ContextInterface,
    payload: DeleteTemplatePayload,
    merchantId: number
  ): Promise<string> {
    const accessToken = await getAccessTokenSilently();
    const { id } = payload;

    await deleteRequest(
      RequestType.DELETE,
      accessToken,
      `/merchants/${merchantId}/templates/${id}`
    );

    return id;
  }

  public static async syncTemplate(
    { getAccessTokenSilently }: Auth0ContextInterface,
    payload: SyncTemplatePayload,
    merchantId: number
  ): Promise<FullTemplateDomain> {
    const accessToken = await getAccessTokenSilently();
    const { id } = payload;

    const templateResponse = (
      await putRequest(
        RequestType.PUT,
        accessToken,
        `/merchants/${merchantId}/templates/${id}/sync`
      )
    ).data;

    return templateTransformFromFullDtoToDomain(templateResponse);
  }

  public static async syncWhatsappTemplate(
    { getAccessTokenSilently }: Auth0ContextInterface,
    payload: SyncTemplatePayload,
    merchantId: number
  ): Promise<WhatsappTemplate> {
    const accessToken = await getAccessTokenSilently();
    const { id } = payload;
    const templateResponse = (
      await axios.post(
        `${apiUrl}/merchants/${merchantId}/whatsapp-templates/${id}/sync`,
        {},
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      )
    ).data;

    return whatsappTemplateTransformFromDtoToDomain(templateResponse);
  }
}

export default TemplatesService;
