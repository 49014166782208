import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Badge,
  Box,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  HStack,
  Icon,
  IconButton,
  Spinner,
  Text,
  VStack,
  useBreakpointValue,
  useColorMode,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import SmartList from "components/shared/SmartList";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { ArrowBackIcon } from "@chakra-ui/icons";
import {
  setInventoryVehicleFilterOptions,
  setInventoryVehicles,
  setSelectedVehicle,
} from "redux/features/inventory";
import InventoryService from "services/inventory";
import { useAuth0 } from "@auth0/auth0-react";
import { ReactComponent as CarIcon } from "assets/icons/vehicle-car-svgrepo-com.svg";
import InventoryVehicleDomain from "entities/domain/inventory-vehicle";
import { IntegrationName } from "entities/domain/admin/merchants/merchant-integrations";
import { numberOfInventoryVehiclesPerLoad } from "util/constants";
import PriceFilter from "./PriceFilter";
import YearFilter from "./YearFilter";
import MakeFilter from "./MakeFilter";
import ModelFilter from "./ModelFilter";
import ConditionsFilter, {
  DEFAULT_VEHICLE_CONDITION,
} from "./ConditionsFilter";
import VehicleItem from "./VehicleItem";
import AvailabilityFilter from "./AvailabilityFilter";

interface InventoryProps {}

const hasMoreVehicles = (
  vehiclesAmount: number,
  currentPageAmount: number
): boolean => {
  if (vehiclesAmount < numberOfInventoryVehiclesPerLoad) {
    return false;
  }

  if (!currentPageAmount) {
    return false;
  }

  return true;
};

const Inventory = (_props: InventoryProps) => {
  const isBaseSize = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );
  const dispatch = useAppDispatch();
  const {
    inventoryVehicles,
    filterByCondition,
    filterByMake,
    filterByModel,
    filterByPriceMax,
    filterByPriceMin,
    filterByYearMax,
    filterByYearMin,
    selectedVehicle,
    filterByAvailability,
  } = useAppSelector((state) => state.inventory);
  const auth0Context = useAuth0();
  const { colorMode } = useColorMode();
  const [hasNextPage, setHasNextPage] = useState<boolean>(false);
  const { colorScheme } = useAppSelector((state) => state.theme);
  const { merchant } = useAppSelector((state) => state.merchant);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const listContainerRef = useRef<HTMLDivElement>(null);

  const [numberOfHiddenFiltersUsed, setNumberOfHiddenFiltersUsed] =
    useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isInitialLoading, setIsInitialLoading] = useState<boolean>(true);

  const updateHiddenFiltersUsage = () => {
    let newNumberOfHiddenFiltersUsed = 0;

    if (filterByModel) {
      newNumberOfHiddenFiltersUsed++;
    }

    if (filterByCondition && filterByCondition !== DEFAULT_VEHICLE_CONDITION) {
      newNumberOfHiddenFiltersUsed++;
    }

    if (filterByPriceMin || filterByPriceMax) {
      newNumberOfHiddenFiltersUsed++;
    }

    if (filterByYearMin || filterByYearMax) {
      newNumberOfHiddenFiltersUsed++;
    }

    setNumberOfHiddenFiltersUsed(newNumberOfHiddenFiltersUsed);
  };

  const fetchVehicles = async (isFirstPage: boolean = false) => {
    if (isLoading) {
      return;
    }

    setIsLoading(true);

    try {
      const vehiclesNextPage = await InventoryService.getVehicles(
        auth0Context,
        merchant.id,
        isFirstPage ? 0 : inventoryVehicles.length,
        "desc",
        filterByModel,
        filterByMake,
        filterByYearMin,
        filterByYearMax,
        filterByPriceMin,
        filterByPriceMax,
        filterByCondition,
        filterByAvailability
      );

      const newVehicles: InventoryVehicleDomain[] = !isFirstPage
        ? [...inventoryVehicles].concat(vehiclesNextPage)
        : vehiclesNextPage;

      dispatch(setInventoryVehicles(newVehicles));
      setHasNextPage(
        hasMoreVehicles(newVehicles.length, vehiclesNextPage?.length || 0)
      );
    } finally {
      setIsLoading(false);
      setIsInitialLoading(false);
    }
  };

  useEffect(() => {
    InventoryService.getVehicleFilterOptions(
      auth0Context,
      merchant.id,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null
    ).then((filterOptions) => {
      dispatch(setInventoryVehicleFilterOptions(filterOptions));
    });
  }, [merchant.id]);

  useEffect(() => {
    updateHiddenFiltersUsage();
    fetchVehicles(true);
  }, [
    filterByCondition,
    filterByMake,
    filterByModel,
    filterByPriceMax,
    filterByPriceMin,
    filterByYearMax,
    filterByYearMin,
    filterByAvailability,
    merchant.id,
  ]);

  return !merchant.isIntegrationEnabled(IntegrationName.KEYLOOP) ||
    !inventoryVehicles.length ? null : (
    <>
      <IconButton
        justifySelf="end"
        aria-label="Inventory"
        colorScheme="gray"
        id="inventory-button"
        icon={
          <Icon
            as={CarIcon}
            __css={{
              path: {
                fill: colorMode === "dark" ? "gray.800" : "white",
              },
            }}
          />
        }
        isRound={true}
        onClick={() => onOpen()}
      />
      <Drawer
        onClose={onClose}
        isOpen={isOpen}
        size={isBaseSize ? "full" : "sm"}
      >
        <DrawerOverlay />
        <DrawerContent borderRadius="0 !important">
          <DrawerCloseButton />
          {selectedVehicle ? (
            <>
              <DrawerHeader>
                <IconButton
                  aria-label="Back"
                  colorScheme="gray"
                  id="inventory-back-button"
                  icon={
                    <Icon
                      as={ArrowBackIcon}
                      __css={{
                        path: {
                          fill: colorMode === "dark" ? "gray.800" : "white",
                        },
                      }}
                    />
                  }
                  isRound={true}
                  onClick={() => dispatch(setSelectedVehicle(null))}
                />
              </DrawerHeader>
              <DrawerBody py={0} px={4}>
                {selectedVehicle.make || selectedVehicle.model ? (
                  <HStack alignItems="center" justifyContent="center" w="100%">
                    <Text fontWeight="bold">
                      {selectedVehicle.make} {selectedVehicle.model}
                    </Text>
                  </HStack>
                ) : null}
                {selectedVehicle.description ? (
                  <HStack
                    alignItems="center"
                    my={2}
                    justifyContent="center"
                    w="100%"
                  >
                    <Text
                      color={colorMode === "dark" ? "gray.600" : "gray.400"}
                    >
                      {selectedVehicle.description}
                    </Text>
                  </HStack>
                ) : null}
                {selectedVehicle.grossPrice ? (
                  <HStack
                    alignItems="center"
                    my={2}
                    justifyContent="space-between"
                    w="100%"
                  >
                    <Text fontWeight="bold">Price</Text>
                    <Text>
                      {merchant.getCurrencySymbol()}{" "}
                      {selectedVehicle.grossPrice}
                    </Text>
                  </HStack>
                ) : null}
                {selectedVehicle.year ? (
                  <HStack
                    alignItems="center"
                    my={2}
                    justifyContent="space-between"
                    w="100%"
                  >
                    <Text fontWeight="bold">Year</Text>
                    <Text>{selectedVehicle.year}</Text>
                  </HStack>
                ) : null}
                {selectedVehicle.exteriorColor ? (
                  <HStack
                    alignItems="center"
                    my={2}
                    justifyContent="space-between"
                    w="100%"
                  >
                    <Text fontWeight="bold">Exterior Colour</Text>
                    <Text>{selectedVehicle.exteriorColor}</Text>
                  </HStack>
                ) : null}
                {selectedVehicle.interiorColor ? (
                  <HStack
                    alignItems="center"
                    my={2}
                    justifyContent="space-between"
                    w="100%"
                  >
                    <Text fontWeight="bold">Interior Colour</Text>
                    <Text>{selectedVehicle.interiorColor}</Text>
                  </HStack>
                ) : null}
                {selectedVehicle.availabilityStatus ? (
                  <HStack
                    alignItems="center"
                    my={2}
                    justifyContent="space-between"
                    w="100%"
                  >
                    <Text fontWeight="bold">Availability Status</Text>
                    <Text>{selectedVehicle.availabilityStatus}</Text>
                  </HStack>
                ) : null}
                {selectedVehicle.condition ? (
                  <HStack
                    alignItems="center"
                    my={2}
                    justifyContent="space-between"
                    w="100%"
                  >
                    <Text fontWeight="bold">Condition</Text>
                    <Text>{selectedVehicle.condition}</Text>
                  </HStack>
                ) : null}
                {selectedVehicle.vin ? (
                  <HStack
                    alignItems="center"
                    my={2}
                    justifyContent="space-between"
                    w="100%"
                  >
                    <Text fontWeight="bold">VIN</Text>
                    <Text>{selectedVehicle.vin}</Text>
                  </HStack>
                ) : null}
                {selectedVehicle.licensePlate ? (
                  <HStack
                    alignItems="center"
                    my={2}
                    justifyContent="space-between"
                    w="100%"
                  >
                    <Text fontWeight="bold">License Plate</Text>
                    <Text>{selectedVehicle.licensePlate}</Text>
                  </HStack>
                ) : null}
                {selectedVehicle.chassis ? (
                  <HStack
                    alignItems="center"
                    my={2}
                    justifyContent="space-between"
                    w="100%"
                  >
                    <Text fontWeight="bold">Chassis</Text>
                    <Text>{selectedVehicle.chassis}</Text>
                  </HStack>
                ) : null}
                {selectedVehicle.engineNumber ? (
                  <HStack
                    alignItems="center"
                    my={2}
                    justifyContent="space-between"
                    w="100%"
                  >
                    <Text fontWeight="bold">Engine Number</Text>
                    <Text>{selectedVehicle.engineNumber}</Text>
                  </HStack>
                ) : null}
                {selectedVehicle.vehicleClass ? (
                  <HStack
                    alignItems="center"
                    my={2}
                    justifyContent="space-between"
                    w="100%"
                  >
                    <Text fontWeight="bold">Vehicle Class</Text>
                    <Text>{selectedVehicle.vehicleClass}</Text>
                  </HStack>
                ) : null}
                {selectedVehicle.variant ? (
                  <HStack
                    alignItems="center"
                    my={2}
                    justifyContent="space-between"
                    w="100%"
                  >
                    <Text fontWeight="bold">Variant</Text>
                    <Text>{selectedVehicle.variant}</Text>
                  </HStack>
                ) : null}
              </DrawerBody>
            </>
          ) : (
            <>
              <DrawerHeader>Inventory</DrawerHeader>
              <DrawerBody padding={0} ref={listContainerRef}>
                {isInitialLoading ? (
                  <Flex
                    h="100%"
                    w="100%"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Spinner />
                  </Flex>
                ) : (
                  <>
                    <Box px={4} py={1}>
                      <MakeFilter />
                    </Box>
                    <Accordion allowToggle={true} width="100%">
                      <AccordionItem
                        border="none"
                        sx={{
                          ".chakra-collapse": {
                            overflow: "initial !important",
                          },
                        }}
                      >
                        {({ isExpanded }) => (
                          <>
                            <AccordionButton
                              width="100%"
                              mt={1}
                              justifyContent="center"
                              bg={colorMode === "dark" ? "gray.900" : "gray.50"}
                            >
                              {isExpanded ? "Hide filters" : "Show filters"}
                              <Badge ml={1} colorScheme={colorScheme}>
                                {numberOfHiddenFiltersUsed || ""}
                              </Badge>
                              <AccordionIcon />
                            </AccordionButton>
                            <AccordionPanel py={4}>
                              <VStack spacing={4}>
                                <ModelFilter />
                                <AvailabilityFilter />
                                <PriceFilter />
                                <YearFilter />
                                <ConditionsFilter />
                              </VStack>
                            </AccordionPanel>
                          </>
                        )}
                      </AccordionItem>
                    </Accordion>
                    <Box py={4}>
                      <SmartList
                        containerRef={listContainerRef}
                        hasHeader={false}
                        columns={[
                          {
                            label: "Vehicle",
                            component: VehicleItem,
                          },
                        ]}
                        items={inventoryVehicles}
                        itemIdentifier="id"
                        hasDivider={false}
                        hasNextPage={hasNextPage}
                        canFetchMore={true}
                        fetchMore={fetchVehicles}
                      />
                    </Box>
                  </>
                )}
              </DrawerBody>
            </>
          )}
        </DrawerContent>
      </Drawer>
    </>
  );
};

export default Inventory;
