import { formatDistanceToNow, parseISO } from "date-fns";
import ReviewReplyDomain from "./review-reply-domain";
import ReviewerDomain from "./reviewer-domain";

export enum ReviewProvider {
  FACEBOOK = "facebook",
  GOOGLE = "google",
}

export enum ReviewStatus {
  PENDING = "pending",
  SUBMITTED = "submitted",
}

export enum PrettyReviewStatus {
  PENDING = "Pending",
  SUBMITTED = "Submitted",
  REQUIRES_RESPONSE = "Requires Response",
}

const getPrettyStatus = (
  status: ReviewStatus,
  repliesLength: number
): PrettyReviewStatus => {
  if (status === "pending") {
    return PrettyReviewStatus.PENDING;
  }

  if (status === "submitted" && repliesLength > 0) {
    return PrettyReviewStatus.SUBMITTED;
  }

  return PrettyReviewStatus.REQUIRES_RESPONSE;
};

class ReviewDomain {
  public id: string;

  public provider: ReviewProvider;

  public content: string | null;

  public displayDateRaw: string;

  public createdAtRaw: string;

  public updatedAtRaw: string | null;

  public submittedAtRaw: string | null;

  public lastSentAtRaw: string | null;

  public displayDate: string;

  public createdAt: string;

  public updatedAt: string | null;

  public submittedAt: string | null;

  public lastSentAt: string | null;

  public status: ReviewStatus;

  public prettyStatus: PrettyReviewStatus;

  public reviewer: ReviewerDomain;

  public rating: number;

  public replies: ReviewReplyDomain[];

  constructor(
    id: string,
    provider: ReviewProvider,
    content: string | null,
    displayDate: string,
    createdAt: string,
    updatedAt: string | null,
    submittedAt: string | null,
    lastSentAt: string | null,
    status: ReviewStatus,
    reviewer: ReviewerDomain,
    rating: number,
    replies: ReviewReplyDomain[]
  ) {
    this.id = id;
    this.provider = provider;
    this.content = content;
    this.displayDate = formatDistanceToNow(parseISO(displayDate), {
      addSuffix: true,
    });
    this.createdAt = formatDistanceToNow(parseISO(createdAt), {
      addSuffix: true,
    });
    this.updatedAt = formatDistanceToNow(
      updatedAt ? parseISO(updatedAt) : new Date(),
      { addSuffix: true }
    );
    this.submittedAt = formatDistanceToNow(
      submittedAt ? parseISO(submittedAt) : new Date(),
      { addSuffix: true }
    );
    this.lastSentAt = formatDistanceToNow(
      lastSentAt ? parseISO(lastSentAt) : new Date(),
      { addSuffix: true }
    );
    this.status = status;
    this.prettyStatus = getPrettyStatus(status, replies.length);
    this.reviewer = reviewer;
    this.rating = rating;
    this.replies = replies;
    this.displayDateRaw = displayDate;
    this.createdAtRaw = createdAt;
    this.updatedAtRaw = updatedAt;
    this.submittedAtRaw = submittedAt;
    this.lastSentAtRaw = lastSentAt;
  }
}

export default ReviewDomain;
