import React, { useCallback, useState } from "react";
import FormGroup from "../form-group";
import AddAgentForm from "../forms/agent-form";
import AssignToMerchantForm from "../forms/AssignToMerchantForm";
import DisconnectFromMerchantForm from "../forms/DisconnectFromMerchantForm";

const Agents = () => {
  const [cache, setCache] = useState({});

  const clearCache = () => {
    setCache({});
  };

  return (
    <>
      <FormGroup title="Add Agent">
        <AddAgentForm clearCache={clearCache} />
      </FormGroup>
      <FormGroup title="Assign to Merchant">
        <AssignToMerchantForm clearCache={clearCache} cache={cache} />
      </FormGroup>
      <FormGroup title="Disconnect from Merchants">
        <DisconnectFromMerchantForm clearCache={clearCache} cache={cache} />
      </FormGroup>
    </>
  );
};

export default Agents;
