import {
  FormControl,
  FormHelperText,
  FormLabel,
  Text,
  useColorMode,
} from "@chakra-ui/react";
import Select, { SingleValue } from "react-select";
import React, { useEffect, useState } from "react";
import AudienceDomain from "entities/domain/audience";
import AudiencesService from "services/audiences";
import { useAuth0 } from "@auth0/auth0-react";
import { getReactSelectStyles } from "util/methods";
import { CampaignStatus } from "entities/domain/campaign";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { propagateAudienceUpdate } from "redux/features/audiences";

interface ExistingAudienceTabProps {
  audienceId: string | null;
  campaignStatus: CampaignStatus;
  setAudienceId: (audienceId: string | null) => void;
}

interface SelectOption {
  value: string;
  label: string;
}

const ExistingAudienceTab = ({
  audienceId,
  campaignStatus,
  setAudienceId,
}: ExistingAudienceTabProps) => {
  const auth0Context = useAuth0();
  const { colorMode } = useColorMode();
  const { colorScheme } = useAppSelector((state) => state.theme);
  const { audiences } = useAppSelector((state) => state.audiences);
  const { merchant } = useAppSelector((state) => state.merchant);
  const dispatch = useAppDispatch();

  const [audienceCustomersCount, setAudienceCustomersCount] =
    useState<number>(0);
  const [showCustomersCount, setShowCustomersCount] = useState<boolean>(false);

  const audienceOptions: SelectOption[] = audiences.map(
    (a: AudienceDomain) => ({ label: a.name, value: a.id as string })
  );

  const [selectedAudienceValue, setSelectedAudienceValue] = useState<
    SingleValue<SelectOption>
  >(audienceOptions.find(({ value }) => value === audienceId) || null);

  const handleAudienceChange = async (e: SingleValue<SelectOption>) => {
    let foundAudience = e?.value
      ? audiences.find((a: AudienceDomain) => a.id === e.value)
      : null;

    setAudienceCustomersCount(0);

    if (foundAudience && foundAudience.count === 0) {
      try {
        foundAudience = await AudiencesService.getAudience(
          auth0Context,
          merchant.id,
          foundAudience.id as string
        );
        setShowCustomersCount(true);
      } catch (error) {
        // eslint-disable-next-line
        console.error("Failed to fetch a single audience", error);
        setShowCustomersCount(false);
      }
    }
    if (foundAudience?.id) {
      dispatch(propagateAudienceUpdate(foundAudience));
    }
    setAudienceId(foundAudience?.id || null);
    setSelectedAudienceValue(e);
  };

  useEffect(() => {
    if (audienceId !== selectedAudienceValue?.value) {
      const selectedAudienceOption =
        audienceOptions.find(({ value }) => value === audienceId) || null;

      handleAudienceChange(selectedAudienceOption);
    }
  }, [audienceId]);

  useEffect(() => {
    if (!audienceId || !audiences.length) {
      setShowCustomersCount(false);
      return;
    }

    const foundAudience = audiences.find(
      (a: AudienceDomain) => a.id === audienceId
    );
    setAudienceCustomersCount(foundAudience?.count || 0);
    setShowCustomersCount(true);
  }, [audienceId, audiences]);

  return (
    <FormControl mt={6}>
      <FormLabel>
        <Text fontWeight={700} fontSize="xl" textAlign="center" mr={0} mb={6}>
          Choose an audience
        </Text>
      </FormLabel>
      <Select
        placeholder="Select existing audience"
        onChange={handleAudienceChange}
        options={audienceOptions}
        value={selectedAudienceValue}
        styles={getReactSelectStyles(colorMode, colorScheme)}
        isDisabled={campaignStatus === CampaignStatus.DONE}
      />
      {showCustomersCount ? (
        <FormHelperText>
          <Text color="gray.400" fontWeight={600}>
            {`${audienceCustomersCount} ${
              audienceCustomersCount === 1 ? "member" : "members"
            } in this audience`}
          </Text>
        </FormHelperText>
      ) : null}
    </FormControl>
  );
};

export default ExistingAudienceTab;
