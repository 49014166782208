import { Box, Icon, IconButton, useColorMode } from "@chakra-ui/react";
import React, { memo, useState } from "react";
import { FaExpand } from "react-icons/fa";

interface ThumbnailWrapperProps {
  children: React.ReactNode;
  itemId: string;
  openGallery?: (startId: string) => void;
}

const ThumbnailWrapper = ({
  children,
  itemId,
  openGallery,
}: ThumbnailWrapperProps) => {
  const { colorMode } = useColorMode();
  const [showExpand, setShowExpand] = useState<boolean>(false);

  return (
    <Box
      overflow="hidden"
      borderRadius="xl"
      height={16}
      width={16}
      onMouseEnter={() => setShowExpand(true)}
      onMouseLeave={() => setShowExpand(false)}
      position="relative"
      ml={4}
      bgColor={colorMode === "dark" ? "gray.900" : "gray.50"}
      cursor={openGallery === undefined ? "default" : "zoom-in"}
      onClick={() => {
        if (typeof openGallery === "undefined") {
          return;
        }

        openGallery(itemId);
      }}
    >
      {children}
      {showExpand && openGallery !== undefined ? (
        <Icon
          as={FaExpand}
          position="absolute"
          fill={colorMode === "dark" ? "gray.400" : "gray.200"}
          left="50%"
          top="50%"
          ml={-7}
          mt={-7}
          boxSize={14}
        />
      ) : null}
    </Box>
  );
};

export default memo(ThumbnailWrapper);
