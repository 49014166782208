import { Auth0ContextInterface } from "@auth0/auth0-react/src/auth0-context";
import AutomationDomain from "entities/domain/automation";
import { automationTransformFromDtoToDomain } from "entities/transformers/automationTransformers";
import { RequestType } from "./request-type";
import { AutomationDTO } from "../entities/dto/AutomationDTO";
import { request, putRequest } from "../util/methods";

export interface EditAutomationPayload {
  id: string;
  name: string;
  merchantId: number;
  triggerType: string;
  time: {
    unit: string;
    amount: number;
    operation: string;
  } | null;
  frequency: {
    unit: string;
    amount: number;
  } | null;
  task: string;
  templateId: string;
  enabled: boolean;
}

class AutomationsService {
  public static async getAutomations(
    { getAccessTokenSilently }: Auth0ContextInterface,
    merchantId: number
  ): Promise<AutomationDomain[]> {
    const accessToken = await getAccessTokenSilently();

    const automationsResponse = (
      await request<AutomationDTO[]>(
        RequestType.GET,
        accessToken,
        `/merchants/${merchantId}/automations`
      )
    ).data;

    return automationsResponse.map((t) =>
      automationTransformFromDtoToDomain(t)
    );
  }

  public static async editAutomation(
    { getAccessTokenSilently }: Auth0ContextInterface,
    payload: EditAutomationPayload
  ): Promise<AutomationDomain> {
    const accessToken = await getAccessTokenSilently();
    const {
      id,
      name,
      merchantId,
      triggerType,
      time,
      frequency,
      task,
      templateId,
      enabled,
    } = payload;

    const automationResponse = await putRequest<AutomationDTO>(
      RequestType.PUT,
      accessToken,
      `/merchants/${merchantId}/automations/${encodeURIComponent(id)}`,
      {
        name,
        merchant_id: merchantId,
        trigger_type: triggerType,
        time,
        frequency,
        task,
        payload: {
          template_id: templateId,
        },
        enabled,
      }
    );

    return automationTransformFromDtoToDomain(automationResponse.data);
  }
}

export default AutomationsService;
