import { Auth0ContextInterface } from "@auth0/auth0-react";
import { postRequest, request } from "util/methods";
import { leadEmailHandlerDtoToDomain } from "entities/transformers/leadTransformer";
import { LeadEmailHandlerDto } from "entities/dto/LeadEmailHandlerDTO";
import LeadEmailHandlerDomain from "entities/domain/leads/domain";
import { RequestType } from "./request-type";

export default class LeadEmailHandlersService {
  public static async getAllLeadEmailHandlers(
    { getAccessTokenSilently }: Auth0ContextInterface,
    merchantId: number
  ): Promise<LeadEmailHandlerDomain[]> {
    const accessToken = await getAccessTokenSilently();

    const response = await request<LeadEmailHandlerDto[]>(
      RequestType.GET,
      accessToken,
      `/merchants/${merchantId}/leads/email-handlers`
    );

    const handlers = response.data;

    return handlers.map((handler) => leadEmailHandlerDtoToDomain(handler));
  }

  public static async createLeadEmailHandler(
    { getAccessTokenSilently }: Auth0ContextInterface,
    merchantId: number,
    email: string,
    source: string
  ): Promise<LeadEmailHandlerDomain> {
    const accessToken = await getAccessTokenSilently();

    const response = await postRequest<LeadEmailHandlerDto>(
      accessToken,
      `/merchants/${merchantId}/leads/email-handlers`,
      {
        email,
        source,
      }
    );

    return leadEmailHandlerDtoToDomain(response.data);
  }
}
