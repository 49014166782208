import { useAuth0 } from "@auth0/auth0-react";
import {
  FormControl,
  FormLabel,
  Input,
  Stack,
  useColorMode,
  useToast,
} from "@chakra-ui/react";
import WarningTextComponent, {
  WarningTextProps,
} from "components/shared/WarningTextComponent";
import {
  Button,
  ButtonSize,
  ButtonType,
} from "theme/old-design-system/styled-components";
import { useAppSelector } from "redux/hooks";
import React, { ChangeEvent, useEffect, useState } from "react";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import Select, { SingleValue } from "react-select";

import AuthorizationService from "services/authorization";
import AdminService from "services/admin";
import { getReactSelectStyles } from "util/methods";
import AdminMerchantDomain from "entities/domain/admin/merchants/admin-merchant-domain";
import SelectMerchant from "./select-merchant";

interface ChannelOptionTypes {
  value: string;
  label: string;
}

interface RoleOptionTypes {
  value: number;
  label: string;
}

const AddAgentForm = ({ clearCache }: { clearCache: () => void }) => {
  const auth0 = useAuth0();
  const channelOptions: ChannelOptionTypes[] = [
    { value: "sms", label: "SMS" },
    { value: "whatsapp", label: "WhatsApp" },
  ];
  const { colorMode } = useColorMode();
  const toast = useToast();
  const { colorScheme } = useAppSelector((state) => state.theme);
  const [firstNameValue, setFirstNameValue] = useState<string>("");
  const [surnameValue, setSurnameValue] = useState<string>("");
  const [emailValue, setEmailValue] = useState<string>("");
  const [selectedMerchant, setSelectedMerchant] =
    useState<AdminMerchantDomain | null>(null);
  const [phoneNumberValue, setPhoneNumberValue] = useState<string>("");
  const [selectedContactValue, setSelectedContactValue] = useState<
    SingleValue<ChannelOptionTypes>
  >(channelOptions[1]);
  const [roleOptions, setRoleOptions] = useState<RoleOptionTypes[]>();
  const [selectedRoleValue, setSelectedRoleValue] =
    useState<RoleOptionTypes | null>(null);
  const [warning, setWarning] = useState<WarningTextProps | undefined>(
    undefined
  );

  useEffect(() => {
    setWarning(undefined);
  }, []);

  useEffect(() => {
    setSelectedRoleValue(null);
    setRoleOptions([] as RoleOptionTypes[]);
    if (selectedMerchant) {
      AuthorizationService.getRoles(auth0, selectedMerchant.id).then(
        (rolesReturned) => {
          setRoleOptions(
            rolesReturned.map<RoleOptionTypes>((role) => ({
              value: role.id,
              label: role.name,
            }))
          );
        }
      );
    }
  }, [selectedMerchant]);

  const isValidPhone = () => {
    return phoneNumberValue &&
      phoneNumberValue !== "" &&
      !isValidPhoneNumber(phoneNumberValue)
      ? setWarning({
          key: "invalid_phone_number",
          description: "* Invalid phone number format",
        })
      : setWarning(undefined);
  };

  const handleNameChange = (event: ChangeEvent<HTMLInputElement>) =>
    setFirstNameValue(event.target.value);

  const handleSurnameChange = (event: ChangeEvent<HTMLInputElement>) =>
    setSurnameValue(event.target.value);

  const handleEmailChange = (event: ChangeEvent<HTMLInputElement>) =>
    setEmailValue(event.target.value);

  const handleContactChange = (e: SingleValue<any>) => {
    setSelectedContactValue(e);
  };

  const isDisabled = (): boolean =>
    !selectedMerchant ||
    !firstNameValue ||
    !surnameValue ||
    !emailValue ||
    !selectedRoleValue ||
    !!warning;

  const resetValues = () => {
    setFirstNameValue("");
    setSurnameValue("");
    setEmailValue("");
    setSelectedMerchant(null);
    setPhoneNumberValue("");
    setSelectedContactValue(channelOptions[1]);
    setSelectedRoleValue(null);
    clearCache();
  };

  const saveAgent = async () => {
    try {
      await AdminService.createAgent(auth0, {
        merchant_id: selectedMerchant!.id,
        first_name: firstNameValue,
        last_name: surnameValue,
        email: emailValue,
        phone: phoneNumberValue,
        contact_channel: selectedContactValue!.value,
        role_id: selectedRoleValue?.value,
      });

      toast({
        status: "success",
        title: "Agent Created Successfully",
      });
    } catch (_error: unknown) {
      toast({
        status: "error",
        title: "Failed to create an agent!",
      });
    } finally {
      resetValues();
    }
  };

  return (
    <>
      <div id="agent-form-input">
        <Stack spacing={3}>
          <FormControl isRequired>
            <FormLabel> Select Merchant </FormLabel>
            <SelectMerchant
              selectedMerchant={selectedMerchant}
              setSelectedMerchant={setSelectedMerchant}
            />
          </FormControl>
          <FormControl isRequired>
            <FormLabel mb="8px"> Agent First Name</FormLabel>
            <Input
              value={firstNameValue}
              onChange={handleNameChange}
              placeholder="John"
            />
          </FormControl>
          <FormControl isRequired>
            <FormLabel mb="8px"> Agent Last Name</FormLabel>
            <Input
              value={surnameValue}
              onChange={handleSurnameChange}
              placeholder="Doe"
            />
          </FormControl>

          <FormControl isRequired>
            <FormLabel> Agent Email Address </FormLabel>
            <Input
              value={emailValue}
              onChange={handleEmailChange}
              placeholder="johndoe@fuzey.io"
            />
          </FormControl>
          <FormControl isRequired>
            <FormLabel> Role </FormLabel>
            <Select
              placeholder="Select Role"
              onChange={(v) => setSelectedRoleValue(v || null)}
              options={roleOptions}
              value={selectedRoleValue}
              styles={getReactSelectStyles(colorMode, colorScheme)}
              isDisabled={!selectedMerchant}
            />
          </FormControl>
          <FormControl
            sx={{
              ".PhoneInputInput": {
                backgroundColor: "inherit",
                color: "inherit",
              },
            }}
          >
            <FormLabel> Agent Phone Number </FormLabel>

            {warning && warning.key === "invalid_phone_number" && (
              <WarningTextComponent
                isValid={false}
                text={warning.description}
              />
            )}

            <PhoneInput
              id="phoneInput"
              style={{ borderRadius: "18px" }}
              placeholder="Enter agent's phone number"
              onChange={(value: string) => {
                setPhoneNumberValue(value);
              }}
              value={phoneNumberValue}
              autoFocus={true}
              onBlur={isValidPhone}
            />
          </FormControl>
          <FormControl>
            <FormLabel> Preferred Contact Channel </FormLabel>
            <Select
              placeholder="Select Channel"
              onChange={handleContactChange}
              options={channelOptions}
              value={selectedContactValue}
              styles={getReactSelectStyles(colorMode, colorScheme)}
            />
          </FormControl>
        </Stack>
        <Button
          type={ButtonType.PRIMARY}
          onClick={saveAgent}
          isDisabled={isDisabled()}
          mt={30}
          mb={30}
          size={ButtonSize.LARGE}
        >
          {" "}
          Save Agent{" "}
        </Button>
      </div>
    </>
  );
};

export default AddAgentForm;
