import {
  Button,
  Flex,
  HStack,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  VStack,
  useBreakpointValue,
  useColorMode,
} from "@chakra-ui/react";
import React from "react";
import { useAppSelector } from "redux/hooks";
import { ReactComponent as WhatsappIcon } from "assets/icons/whatsapp-new.svg";
import { ReactComponent as SmsIcon } from "assets/icons/sms-message.svg";
import { ReactComponent as EmailIcon } from "assets/icons/email.svg";
import { ReactComponent as MessengerIcon } from "assets/icons/messenger.svg";
import { ReactComponent as InstagramIcon } from "assets/icons/instagram.svg";
import { FaPlus } from "react-icons/fa";
import { IntegrationName } from "entities/domain/admin/merchants/merchant-integrations";
import { ConversationChannel } from "entities/domain/conversations/conversation-domain";
import { useNavigate } from "react-router-dom";

interface ChooseTemplateTypeModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const ChooseTemplateTypeModal = ({
  isOpen,
  onClose,
}: ChooseTemplateTypeModalProps) => {
  const navigate = useNavigate();
  const isBaseSize = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );
  const { colorMode } = useColorMode();
  const { merchant } = useAppSelector((state) => state.merchant);
  const { colorScheme } = useAppSelector((state) => state.theme);

  return (
    <Modal isOpen={isOpen} onClose={onClose} size={isBaseSize ? "full" : "xl"}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Create Template</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <HStack
            h="100%"
            w="100%"
            spacing={4}
            justifyContent="space-between"
            pb={6}
          >
            <Button
              colorScheme={colorScheme}
              variant="outline"
              borderRadius="xl"
              py={8}
              px={6}
              flexDirection="column"
              gridGap={4}
              height="15rem"
              w="100%"
              justifyContent="space-between"
              id="choose-template-for-whatsapp"
              position="relative"
              onClick={() => {
                navigate(`/${merchant.id}/settings/templates/whatsapp/create`);
              }}
            >
              <Flex
                flexGrow={1}
                flexShrink={0}
                flexBasis="auto"
                alignItems="center"
              >
                <Icon as={WhatsappIcon} boxSize={12} />
              </Flex>
              <Text flexGrow={0} flexShrink={0} flexBasis="auto">
                WhatsApp
              </Text>
            </Button>
            <Button
              colorScheme={colorScheme}
              variant="outline"
              borderRadius="xl"
              py={8}
              px={6}
              flexDirection="column"
              gridGap={4}
              height="15rem"
              id="choose-template-for-everything-else"
              justifyContent="space-between"
              w="100%"
              position="relative"
              onClick={() => {
                navigate(`/${merchant.id}/settings/templates/create/`);
              }}
            >
              <VStack spacing={2}>
                <HStack>
                  <Icon
                    as={MessengerIcon}
                    boxSize={8}
                    opacity={
                      merchant.isIntegrationEnabled(IntegrationName.MESSENGER)
                        ? 1
                        : 0.25
                    }
                  />
                </HStack>
                <HStack spacing={2}>
                  <Icon
                    as={EmailIcon}
                    boxSize={8}
                    opacity={
                      merchant.isIntegrationEnabled(IntegrationName.GMAIL) ||
                      merchant.isIntegrationEnabled(IntegrationName.OUTLOOK)
                        ? 1
                        : 0.25
                    }
                  />
                  <Icon
                    as={FaPlus}
                    boxSize={6}
                    color={
                      colorMode === "dark"
                        ? `${colorScheme}.200`
                        : `${colorScheme}.500`
                    }
                  />
                  <Icon
                    as={SmsIcon}
                    boxSize={8}
                    opacity={
                      merchant.isMerchantChannelEnabled(ConversationChannel.SMS)
                        ? 1
                        : 0.25
                    }
                    __css={{
                      path: {
                        fill:
                          colorMode === "dark"
                            ? `${colorScheme}.200`
                            : `${colorScheme}.500`,
                      },
                    }}
                  />
                </HStack>
                <HStack>
                  <Icon
                    as={InstagramIcon}
                    boxSize={8}
                    opacity={
                      merchant.isIntegrationEnabled(IntegrationName.INSTAGRAM)
                        ? 1
                        : 0.25
                    }
                  />
                </HStack>
              </VStack>
              <Text>Everything else</Text>
            </Button>
          </HStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ChooseTemplateTypeModal;
