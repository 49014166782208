import {
  Box,
  Divider,
  Flex,
  SkeletonCircle,
  SkeletonText,
  VStack,
  useBreakpointValue,
  useColorMode,
} from "@chakra-ui/react";
import React from "react";

const SkeletonOverlay = () => {
  const { colorMode } = useColorMode();
  const isBaseSize = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );

  return (
    <VStack
      h="100%"
      w="100%"
      position="absolute"
      top={0}
      left={0}
      overflow="hidden"
      zIndex={50}
      bgColor={colorMode === "dark" ? "gray.800" : "white"}
    >
      <SkeletonText
        noOfLines={1}
        skeletonHeight={4}
        width="100%"
        px={4}
        mt={isBaseSize ? 6 : 16}
        mb={2}
      />
      {[...Array(5).keys()].map((i) => (
        <>
          {i === 0 ? (
            <Box
              height="2.5rem"
              width="100%"
              py={2}
              px={4}
              bgColor={colorMode === "dark" ? "gray.700" : "gray.100"}
              fontWeight="bold"
              color={colorMode === "dark" ? "gray.300" : "gray.600"}
            >
              <SkeletonText noOfLines={1} skeletonHeight={4} width="8rem" />
            </Box>
          ) : (
            <Divider />
          )}
          <Flex
            key={`skeleton-payment-${i}`}
            padding="6"
            w="100%"
            h="fit-content"
            gridGap={4}
          >
            <SkeletonCircle size="10" flexShrink={0} flexGrow={0} />
            <SkeletonText
              noOfLines={2}
              spacing={4}
              skeletonHeight={2}
              flexShrink={1}
              flexGrow={1}
            />
          </Flex>
        </>
      ))}
    </VStack>
  );
};

export default SkeletonOverlay;
