import { Auth0ContextInterface } from "@auth0/auth0-react";
import { request } from "util/methods";
import { SummaryDto } from "entities/dto/SummaryDTO";
import { summaryTransformFromDtoToString } from "entities/transformers/summary-transformer";
import { RequestType } from "./request-type";

export default class SummaryService {
  public static async generateSummary(
    { getAccessTokenSilently }: Auth0ContextInterface,
    contactId: number,
    merchantId: number
  ): Promise<string> {
    const accessToken = await getAccessTokenSilently();

    const summaryResponse = await request<SummaryDto>(
      RequestType.GET,
      accessToken,
      `/merchants/${merchantId}/customers/${contactId}/summary`
    );

    const summary = summaryResponse.data;

    return summaryTransformFromDtoToString(summary);
  }
}
