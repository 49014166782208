import { useAuth0 } from "@auth0/auth0-react";
import { Button } from "@chakra-ui/button";
import { useColorMode } from "@chakra-ui/color-mode";
import { Icon } from "@chakra-ui/icon";
import { Divider, HStack } from "@chakra-ui/layout";
import { useToast } from "@chakra-ui/toast";
import React, { useEffect, useState } from "react";
import {
  OpenClosedFilter,
  clearSelectedConversations,
  disableBulkActionsToolbar,
  enableBulkActionsToolbar,
  startLoadingBulkActionsToolbar,
  stopLoadingBulkActionsToolbar,
  updateConversationSelection,
} from "redux/features/conversations";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { ReactComponent as BulkEditIcon } from "assets/icons/bulk-edit-svgrepo-com.svg";
import { ChevronDownIcon } from "@chakra-ui/icons";
import { ReactComponent as SelectAllIcon } from "assets/icons/select-all-svgrepo-com.svg";
import { ReactComponent as DeselectAllIcon } from "assets/icons/select-all-off-svgrepo-com.svg";
import { ReactComponent as OpenLockIcon } from "assets/icons/open-lock.svg";
import { ReactComponent as CloseLockIcon } from "assets/icons/close-lock.svg";
import { ReactComponent as EyeIcon } from "assets/icons/eye.svg";
import { ReactComponent as EyeOffIcon } from "assets/icons/eye-off.svg";
import { ReactComponent as TagAddIcon } from "assets/icons/tag-plus-outline.svg";
import { ReactComponent as TagRemoveIcon } from "assets/icons/tag-minus-outline.svg";
import { ReactComponent as AssignAgentIcon } from "assets/icons/personal-conversations.svg";
import InboxService from "services/inbox";
import {
  MAX_ALLOWED_BULK_SELECTION,
  MAX_SAFE_SELECTION_AMOUNT,
} from "util/constants";
import { batch } from "react-redux";
import { Menu, MenuButton, MenuItem, MenuList } from "@chakra-ui/menu";
import { useDisclosure } from "@chakra-ui/hooks";
import AssignAgentModal from "components/modals/AssignAgent";
import UpdateTags from "components/modals/tags/UpdateTags";
import ContactsService from "services/contacts";
import ConfirmationDialog from "components/shared/ConfirmationDialog";

const MobileBulkActions = () => {
  const auth0Context = useAuth0();
  const toast = useToast();
  const dispatch = useAppDispatch();
  const { colorScheme } = useAppSelector((state) => state.theme);
  const { colorMode } = useColorMode();
  const assignModal = useDisclosure();
  const { tags } = useAppSelector((state) => state.tags);
  const { merchant } = useAppSelector((state) => state.merchant);
  const {
    selectedConversationIds,
    isOpenOrClosed,
    conversations,
    bulkActionsToolbarEnabled,
  } = useAppSelector((state) => state.conversations);

  const [shouldShowConfirmation, setShouldShowConfirmation] =
    useState<boolean>(false);
  const [confirmationAction, setConfirmationAction] = useState<() => void>(
    () => () => {}
  );
  const [shouldShowTagsModal, setShouldShowTagsModal] =
    useState<boolean>(false);
  const [isRemovingTag, setIsRemovingTag] = useState<boolean>(false);

  useEffect(() => {
    if (!shouldShowTagsModal) {
      setIsRemovingTag(false);
    }
  }, [shouldShowTagsModal]);

  const openOrCloseAll = async () => {
    try {
      const updatedConversationIds =
        await InboxService.bulkOpenOrCloseConversations(
          auth0Context,
          merchant.id,
          selectedConversationIds,
          isOpenOrClosed === OpenClosedFilter.Closed
        );

      if (updatedConversationIds.length === 0) {
        toast({
          status: "error",
          title: "Failed to perform bulk action.",
          description: "Please try again later.",
        });

        return;
      }

      if (updatedConversationIds.length === selectedConversationIds.length) {
        dispatch(clearSelectedConversations());
      }
    } catch (_error: unknown) {
      toast({
        status: "error",
        title: "Failed to perform bulk action.",
        description: "Please try again later.",
      });
    }
  };

  const markAllAsReadOrUnread = async (shouldMarkRead: boolean) => {
    try {
      const updatedConversationIds = await InboxService.bulkMarkReadOrUnread(
        auth0Context,
        merchant.id,
        selectedConversationIds,
        shouldMarkRead
      );

      if (updatedConversationIds.length === 0) {
        toast({
          status: "error",
          title: "Failed to perform bulk action.",
          description: "Please try again later.",
        });

        return;
      }

      if (updatedConversationIds.length === selectedConversationIds.length) {
        dispatch(clearSelectedConversations());
      }
    } catch (_error: unknown) {
      toast({
        status: "error",
        title: "Failed to perform bulk action.",
        description: "Please try again later.",
      });
    }
  };

  const handleBulkReadOrUnread = (shouldMarkRead: boolean) => {
    dispatch(disableBulkActionsToolbar());
    dispatch(startLoadingBulkActionsToolbar());

    markAllAsReadOrUnread(shouldMarkRead).finally(() => {
      dispatch(enableBulkActionsToolbar());
      dispatch(stopLoadingBulkActionsToolbar());
    });
  };

  const handleBulkOpenOrClose = () => {
    dispatch(disableBulkActionsToolbar());
    dispatch(startLoadingBulkActionsToolbar());

    openOrCloseAll().finally(() => {
      dispatch(enableBulkActionsToolbar());
      dispatch(stopLoadingBulkActionsToolbar());
    });
  };

  return (
    <>
      <HStack alignItems="center" justifyContent="space-between" p={4}>
        <Button
          colorScheme={colorScheme}
          leftIcon={
            <Icon
              as={
                selectedConversationIds.length ===
                conversations.slice(0, MAX_ALLOWED_BULK_SELECTION).length
                  ? DeselectAllIcon
                  : SelectAllIcon
              }
              w={4}
              h={4}
              __css={{
                g: {
                  fill: colorMode === "dark" ? "gray.800" : "white",
                },
              }}
            />
          }
          onClick={() => {
            if (
              selectedConversationIds.length ===
              conversations.slice(0, MAX_ALLOWED_BULK_SELECTION).length
            ) {
              dispatch(clearSelectedConversations());

              return;
            }

            batch(() => {
              conversations
                .slice(0, MAX_ALLOWED_BULK_SELECTION)
                .forEach((c) => {
                  dispatch(
                    updateConversationSelection({
                      conversationId: c.id,
                      isSelected: true,
                    })
                  );
                });
            });
          }}
        >
          {conversations.slice(0, MAX_ALLOWED_BULK_SELECTION).length ===
          selectedConversationIds.length
            ? "Deselect All"
            : "Select All"}
        </Button>
        <Menu>
          <MenuButton
            as={Button}
            width="auto"
            aria-label="Bulk Actions"
            variant="solid"
            isDisabled={!bulkActionsToolbarEnabled}
            colorScheme={colorScheme}
            leftIcon={
              <Icon
                as={BulkEditIcon}
                height="1rem"
                width="1rem"
                __css={{
                  path: {
                    fill: colorMode === "dark" ? "gray.800" : "white",
                  },
                }}
              />
            }
          >
            Bulk actions <ChevronDownIcon />
          </MenuButton>
          <MenuList>
            <MenuItem
              isDisabled={!bulkActionsToolbarEnabled}
              onClick={() => {
                if (
                  selectedConversationIds.length > MAX_SAFE_SELECTION_AMOUNT
                ) {
                  setConfirmationAction(() => () => {
                    setShouldShowTagsModal(true);
                  });
                  setShouldShowConfirmation(true);
                  return;
                }
                setShouldShowTagsModal(true);
              }}
              icon={
                <Icon
                  as={TagAddIcon}
                  fill={colorMode === "dark" ? "white" : "gray.800"}
                />
              }
            >
              Add Tag
            </MenuItem>
            <MenuItem
              isDisabled={!bulkActionsToolbarEnabled}
              onClick={() => {
                if (
                  selectedConversationIds.length > MAX_SAFE_SELECTION_AMOUNT
                ) {
                  setConfirmationAction(() => () => {
                    setIsRemovingTag(true);
                    setShouldShowTagsModal(true);
                  });
                  setShouldShowConfirmation(true);
                  return;
                }
                setIsRemovingTag(true);
                setShouldShowTagsModal(true);
              }}
              icon={
                <Icon
                  as={TagRemoveIcon}
                  fill={colorMode === "dark" ? "white" : "gray.800"}
                />
              }
            >
              Remove Tag
            </MenuItem>
            <MenuItem
              isDisabled={!bulkActionsToolbarEnabled}
              onClick={() => {
                if (
                  selectedConversationIds.length > MAX_SAFE_SELECTION_AMOUNT
                ) {
                  setConfirmationAction(() => () => assignModal.onOpen());
                  setShouldShowConfirmation(true);
                  return;
                }
                assignModal.onOpen();
              }}
              icon={
                <Icon
                  as={AssignAgentIcon}
                  __css={{
                    path: {
                      stroke: colorMode === "dark" ? "white" : "gray.800",
                    },
                  }}
                />
              }
            >
              Assign to
            </MenuItem>
            <MenuItem
              isDisabled={!bulkActionsToolbarEnabled}
              onClick={() => {
                if (
                  selectedConversationIds.length > MAX_SAFE_SELECTION_AMOUNT
                ) {
                  setConfirmationAction(() => () => handleBulkOpenOrClose());
                  setShouldShowConfirmation(true);
                  return;
                }
                handleBulkOpenOrClose();
              }}
              icon={
                isOpenOrClosed === OpenClosedFilter.Open ? (
                  <Icon
                    as={CloseLockIcon}
                    __css={{
                      path: {
                        fill: colorMode === "dark" ? "white" : "gray.800",
                      },
                    }}
                  />
                ) : (
                  <Icon
                    as={OpenLockIcon}
                    __css={{
                      path: {
                        fill: colorMode === "dark" ? "white" : "gray.800",
                      },
                    }}
                  />
                )
              }
            >
              {isOpenOrClosed === OpenClosedFilter.Open ? "Close" : "Open"}
            </MenuItem>
            <MenuItem
              isDisabled={!bulkActionsToolbarEnabled}
              onClick={() => {
                if (
                  selectedConversationIds.length > MAX_SAFE_SELECTION_AMOUNT
                ) {
                  setConfirmationAction(
                    () => () => handleBulkReadOrUnread(true)
                  );
                  setShouldShowConfirmation(true);
                  return;
                }
                handleBulkReadOrUnread(true);
              }}
              icon={
                <Icon
                  as={EyeIcon}
                  fill={colorMode === "dark" ? "white" : "gray.800"}
                />
              }
            >
              Mark Read
            </MenuItem>
            <MenuItem
              isDisabled={!bulkActionsToolbarEnabled}
              onClick={() => {
                if (
                  selectedConversationIds.length > MAX_SAFE_SELECTION_AMOUNT
                ) {
                  setConfirmationAction(
                    () => () => handleBulkReadOrUnread(false)
                  );
                  setShouldShowConfirmation(true);
                  return;
                }
                handleBulkReadOrUnread(false);
              }}
              icon={
                <Icon
                  as={EyeOffIcon}
                  fill={colorMode === "dark" ? "white" : "gray.800"}
                />
              }
            >
              Mark Unread
            </MenuItem>
          </MenuList>
        </Menu>
      </HStack>
      <Divider />
      <AssignAgentModal
        isOpen={assignModal.isOpen}
        onClose={() => {
          dispatch(enableBulkActionsToolbar());
          assignModal.onClose();
        }}
        assignedAgentId={undefined}
        assignedTeamId={undefined}
        customerId={undefined}
        conversationId={undefined}
        conversationChannel={undefined}
      />
      {!selectedConversationIds.length ? null : (
        <UpdateTags
          isOpen={shouldShowTagsModal}
          isRemoval={isRemovingTag}
          onClose={() => {
            dispatch(enableBulkActionsToolbar());
            setShouldShowTagsModal(false);
          }}
          tagIds={[]}
          onSubmit={async (tagIds) => {
            dispatch(startLoadingBulkActionsToolbar());

            const selectedTags = tags.filter((t) => tagIds.includes(t.id));
            const selectedContactIds: number[] = [];

            selectedConversationIds.forEach((conversationId) => {
              const conversation = conversations.find(
                (c) => c.id === conversationId
              );

              if (
                conversation &&
                selectedContactIds.includes(conversation.customerId) === false
              ) {
                selectedContactIds.push(conversation.customerId);
              }
            });

            try {
              let updatedContactIds: number[] = [];

              if (isRemovingTag) {
                updatedContactIds = await ContactsService.bulkRemoveTags(
                  auth0Context,
                  merchant.groupId,
                  selectedContactIds,
                  selectedTags.map((tag) => tag.tag),
                  selectedTags.map((tag) => tag.id)
                );
              } else {
                updatedContactIds = await ContactsService.bulkAddTags(
                  auth0Context,
                  merchant.groupId,
                  selectedContactIds,
                  selectedTags.map((tag) => tag.tag),
                  selectedTags.map((tag) => tag.id)
                );
              }

              if (updatedContactIds.length === 0) {
                toast({
                  status: "error",
                  title: "Failed to perform bulk action.",
                  description: "Please try again later.",
                });

                return;
              }

              if (updatedContactIds.length === selectedContactIds.length) {
                dispatch(clearSelectedConversations());
              }
            } catch (_error: unknown) {
              toast({
                status: "error",
                title: "Failed to perform bulk action.",
                description: "Please try again later.",
              });
            } finally {
              dispatch(enableBulkActionsToolbar());
              dispatch(stopLoadingBulkActionsToolbar());
              setShouldShowTagsModal(false);
            }
          }}
        />
      )}
      <ConfirmationDialog
        isOpen={shouldShowConfirmation}
        setIsOpen={setShouldShowConfirmation}
        headerText="Are you sure about this?"
        messageText={`You're about to do a bulk action over ${selectedConversationIds.length} conversations.`}
        buttonText="Yes"
        confirmationCallback={() => {
          confirmationAction();
          setShouldShowConfirmation(false);
          setConfirmationAction(() => () => {});
        }}
      />
    </>
  );
};

export default MobileBulkActions;
