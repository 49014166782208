import TagsList from "components/tags/list";
import ContactDomain from "entities/domain/customers/contact-domain";
import Tag from "components/tags";
import React from "react";

const ContactTagsColumn = ({
  item,
  handleOpenEditTags,
}: {
  item: ContactDomain;
  handleOpenEditTags?: (val: ContactDomain) => void;
}) => {
  return (
    <TagsList
      defaultMaxVisibleTags={1}
      tagIds={item.tagIds}
      defaultTag={
        <Tag
          label="+ Add Tag"
          color="#9496A9"
          fontSize="12px"
          onClick={(e) => {
            if (!handleOpenEditTags) {
              return;
            }

            e.stopPropagation();
            e.preventDefault();
            handleOpenEditTags(item);
          }}
          _hover={{ opacity: 0.5, cursor: "pointer" }}
        />
      }
    />
  );
};

export default ContactTagsColumn;
