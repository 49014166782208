import { Box, Divider, Flex, Icon, Text, useColorMode } from "@chakra-ui/react";
import React from "react";
import { ReactComponent as FuzeyTarget } from "assets/icons/Fuzey_Logomark_Dark.svg";
import AutomationDomain from "entities/domain/automation";
import { useAppSelector } from "redux/hooks";
import { ICON_BY_TEMPLATE_ID } from "../AutomationCard";

interface TriggerProps {
  automation: AutomationDomain;
}

enum TriggerType {
  PAYMENT_CREATED = "payment_created",
  BOOKING_STARTED = "booking_started",
  FIRST_ENQUIRY = "first_enquiry",
}

const TRIGGER_LABEL_BY_TYPE: {
  [key in TriggerType]: string;
} = {
  payment_created: "Payment has been created",
  booking_started: "Booking Started",
  first_enquiry: "First Enquiry",
};

const Trigger = ({ automation }: TriggerProps) => {
  const { colorMode } = useColorMode();
  const { colorScheme } = useAppSelector((state) => state.theme);

  return (
    <>
      <Box px={6}>
        <Text fontWeight={700} pb="1rem">
          When this occurs:
        </Text>
        <Flex alignItems="center" pb="2rem">
          <Icon
            as={ICON_BY_TEMPLATE_ID[automation.templateId] || FuzeyTarget}
            mr="1rem"
            fill={`${colorScheme}.600`}
            __css={{
              height: "30px",
              width: "30px",
            }}
          />
          <Flex
            borderRadius="full"
            border="1px solid #E7EDF4"
            height="2.5rem"
            w="100%"
            px="1rem"
            bgColor={colorMode === "dark" ? "gray.900" : "gray.50"}
            alignItems="center"
          >
            <Text overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap">
              {automation.triggerType
                ? TRIGGER_LABEL_BY_TYPE[automation.triggerType as TriggerType]
                : ""}
            </Text>
          </Flex>
        </Flex>
      </Box>
      <Divider />
    </>
  );
};

export default Trigger;
