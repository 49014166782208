import React, { ReactNode, useEffect, useState } from "react";
import { Auth0ContextInterface, useAuth0 } from "@auth0/auth0-react";
import useAnalytics from "hooks/use-analytics";
import useAgentsStore from "hooks/use-agents-store";
import { Socket } from "socket.io-client";
import { useWebSocket } from "hooks/use-socket";
import { askForNotificationsPermission } from "util/browser_notifications";
import { Flex, Spinner, Text } from "@chakra-ui/react";
import {
  ChannelCreatedMessage,
  InAppNotificationCreated,
  SocketAgentUpdated,
  SocketTagCreated,
  SocketTagDeleted,
} from "entities/ISocketArgs";
import AgentsService from "services/agents";
import { AgentMerchantDomain } from "entities/domain/agents/new-agent-domain";
import { isMobileApp } from "util/methods";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { updateMerchantSuccess } from "redux/features/merchant";
import { IntegrationName } from "entities/domain/admin/merchants/merchant-integrations";
import TagsService from "services/tags";
import { addTag, removeTag, setTags } from "redux/features/tags";
import { appendNotification } from "redux/features/notifications";
import { fromDtoToDomain } from "services/notifications";
import { setColorScheme } from "redux/features/theme";
import { setCurrentAgent } from "redux/features/agents";
import PrivatePageWrapper from "./PrivatePageWrapper";

const PrivateRoute = ({ children }: { children?: ReactNode }) => {
  const auth0Context = useAuth0();
  const { user }: Auth0ContextInterface = useAuth0();
  const { track } = useAnalytics();
  const { merchant, isInitialLoading } = useAppSelector(
    (state) => state.merchant
  );
  const { fetchAgents } = useAgentsStore();
  const {
    currentAgent,
    loading: agentsLoading,
    agents,
  } = useAppSelector((state) => state.agents);
  const { socket, addEventHandler, removeEventHandler } = useWebSocket();
  const dispatch = useAppDispatch();

  const [notificationPermissionRequested, setNotificationPermissionRequested] =
    useState<boolean>(false);

  const updateSignalPlayerIds = async () => {
    if (!currentAgent || !merchant || !Object.keys(merchant).length) {
      return;
    }

    const signalPlayerId = localStorage.getItem("fuzeySignalPlayerId");

    if (
      signalPlayerId &&
      (!currentAgent.playerIds ||
        !currentAgent.playerIds.includes(signalPlayerId))
    ) {
      const selectedMerchant = currentAgent.merchants.find(
        (m: AgentMerchantDomain) => m.id === merchant.id
      )!;

      AgentsService.updateAgent(
        auth0Context,
        {
          id: Number(currentAgent.id),
          name: currentAgent.name,
          surname: currentAgent.surname,
          email: currentAgent.email,
          notification_handle: currentAgent.notificationConfig?.handle
            ? currentAgent.notificationConfig?.handle
            : "",
          notification_channel: currentAgent.notificationConfig?.contactChannel
            ? currentAgent.notificationConfig?.contactChannel
            : "",
          notification_preference: currentAgent.notificationConfig?.preference
            ? currentAgent.notificationConfig?.preference
            : "",
          notification_timezone: currentAgent.notificationConfig?.timezone
            ? currentAgent.notificationConfig?.timezone
            : "",
          role_id: selectedMerchant.role.id,
          active: currentAgent.isActive,
          profile_picture_url: currentAgent.profilePic,
          player_ids: [...(currentAgent.playerIds || []), signalPlayerId],
          style_preferences: {
            chat_background:
              currentAgent.stylePreferences?.chatBackground || null,
            color_scheme: currentAgent.stylePreferences?.colorScheme || null,
          },
        },
        merchant.id
      );
    }
  };

  useEffect(() => {
    if (!merchant || !Object.keys(merchant).length) {
      return;
    }

    TagsService.getTags(auth0Context, merchant.groupId).then((tags) => {
      dispatch(setTags(tags));
    });
  }, [merchant.groupId]);

  useEffect(() => {
    if (!merchant || !Object.keys(merchant).length) {
      return;
    }

    localStorage.setItem("fuzey:merchant:country", merchant.country);

    if (isMobileApp()) {
      updateSignalPlayerIds();
    }

    if (!notificationPermissionRequested) {
      askForNotificationsPermission(track);
      setNotificationPermissionRequested(true);
    }
  }, [merchant.id, notificationPermissionRequested]);

  useEffect(() => {
    if (merchant && Object.keys(merchant).length) {
      fetchAgents();
    }
  }, [merchant.id]);

  const handleTagCreated = (args: SocketTagCreated) => {
    if (args.tag.group_id !== merchant.groupId) {
      return;
    }

    dispatch(
      addTag({
        id: args.tag.id,
        name: args.tag.name,
      })
    );
  };

  const handleAgentUpdated = (args: SocketAgentUpdated) => {
    if (currentAgent && args.agent.id === currentAgent.id) {
      AgentsService.getCurrentAgent(auth0Context).then((agent) => {
        if (!agent) {
          return;
        }

        dispatch(setCurrentAgent(agent));
      });
    }
  };

  useEffect(() => {
    if (!isInitialLoading && merchant && Object.keys(merchant).length) {
      addEventHandler("agent_updated", handleAgentUpdated);
      addEventHandler("tag_created", handleTagCreated);
      addEventHandler("tag_deleted", handleTagDeleted);
      addEventHandler("channel_created", handleChannelCreated);
      addEventHandler(
        "in_app_notification_created",
        handleInAppNotificationCreated
      );
      return () => {
        removeEventHandler("agent_updated", handleAgentUpdated);
        removeEventHandler("tag_created", handleTagCreated);
        removeEventHandler("tag_deleted", handleTagDeleted);
        removeEventHandler("channel_created", handleChannelCreated);
        removeEventHandler(
          "in_app_notification_created",
          handleInAppNotificationCreated
        );
      };
    }
  }, [addEventHandler, removeEventHandler, merchant.id, isInitialLoading]);

  const handleTagDeleted = (args: SocketTagDeleted) => {
    if (args.tag.group_id !== merchant.groupId) {
      return;
    }

    dispatch(removeTag(args.tag.id));
  };

  useEffect(() => {
    if (
      !isInitialLoading &&
      merchant &&
      Object.keys(merchant).length &&
      socket instanceof Socket
    ) {
      socket.on("tag_deleted", handleTagDeleted);

      return () => {
        socket.off("tag_deleted", handleTagDeleted);
      };
    }
  }, [socket, merchant.id, isInitialLoading]);

  useEffect(() => {
    if (
      !currentAgent ||
      !currentAgent.stylePreferences ||
      !currentAgent.stylePreferences.colorScheme
    ) {
      return;
    }

    dispatch(setColorScheme(currentAgent.stylePreferences.colorScheme));
  }, [currentAgent]);

  const handleChannelCreated = (args: ChannelCreatedMessage) => {
    if (args.merchant_id !== merchant.id) {
      return;
    }

    if (args.channel_name === "email" && args.provider === "google") {
      merchant.integrations[IntegrationName.GMAIL].connected = true;
      dispatch(updateMerchantSuccess(merchant));
    }
  };

  const handleInAppNotificationCreated = (args: InAppNotificationCreated) => {
    if (
      args.notification.merchant_id === merchant.id &&
      currentAgent?.id === args.notification.agent_id
    ) {
      dispatch(appendNotification(fromDtoToDomain(args.notification)));
    }
  };

  if (
    !socket ||
    isInitialLoading ||
    !user ||
    !currentAgent ||
    !Object.keys(merchant).length ||
    (agentsLoading && !agents.length)
  ) {
    return (
      <Flex justifyContent="center" alignItems="center" h="100%">
        <Spinner size="xl" />
      </Flex>
    );
  }

  return <PrivatePageWrapper>{children}</PrivatePageWrapper>;
};

export default PrivateRoute;
