import { Text } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { RegularOrWhatsappTemplate } from "redux/features/templates";

const TemplateTitleColumn = ({ item }: { item: RegularOrWhatsappTemplate }) => {
  const [text, setText] = useState<string>("");

  useEffect(() => {
    if (item.isRegularTemplate()) {
      setText(item.title);
    } else if (item.isWhatsappTemplate()) {
      setText(item.name);
    }
  }, [item]);

  return (
    <Text
      textOverflow="ellipsis"
      textAlign="left"
      whiteSpace="nowrap"
      overflow="hidden"
    >
      {text}
    </Text>
  );
};

export default TemplateTitleColumn;
