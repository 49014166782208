import PromptDomain, { PromptAction, PromptType } from "entities/domain/prompt";
import { PromptDTO } from "entities/dto/PromptDTO";

export function promptTransformFromDtoToDomain(p: PromptDTO): PromptDomain {
  return new PromptDomain(
    p.id,
    p.conversation_id,
    p.body,
    p.type as PromptType,
    p.actions as PromptAction[],
    p.last_updated_at,
    p.payload
  );
}
