export default class NotificationDomain {
  id: string;

  title: string;

  message: string;

  deepLink: string;

  read: boolean;

  createdAt: string;

  constructor(
    id: string,
    title: string,
    message: string,
    deepLink: string,
    read: boolean,
    createdAt: string
  ) {
    this.id = id;
    this.title = title;
    this.message = message;
    this.deepLink = deepLink;
    this.read = read;
    this.createdAt = createdAt;
  }
}
