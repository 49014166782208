import React, { ReactNode } from "react";
import { As, Icon } from "@chakra-ui/react";
import { ReactSVG } from "react-svg";

interface FooterIconProps {
  as?: As;
  src?: string;
  w?: number;
  h?: number;
  fill?: string;
  width?: string;
  height?: string;
}

const FooterIcon = ({
  as,
  src,
  w = 8,
  h = 8,
  fill,
  width = "2rem",
  height = "2rem",
}: FooterIconProps) => {
  const additionalProps: { [key: string]: string } = {};

  if (src) {
    additionalProps.src = src;
  }

  return (
    <Icon
      as={as || ReactSVG}
      w={w}
      h={h}
      {...additionalProps}
      __css={{
        path: {
          fill: fill || "white",
        },
        rect: {
          fill: "none",
        },
        svg: {
          width,
          height,
        },
      }}
    />
  );
};

export default FooterIcon;
