/* eslint-disable import/prefer-default-export */
import { PaymentStatus } from "entities/domain/payments/payment-domain";
import BankAccountDetailsDomain from "entities/domain/public/payments/bankAccountDetails";
import PublicInvoiceDomain from "entities/domain/public/payments/invoice";
import PublicPaymentLinkDomain from "entities/domain/public/payments/paymentLink";
import PublicPaymentDomain from "entities/domain/public/payments/payments";
import {
  BankAccountDetailsType,
  PublicInvoiceType,
  PublicPaymentDto,
  PublicPaymentLinkType,
} from "entities/dto/PublicPaymentDTO";

export const publicPaymentLinkTransformerFromDtoToDomain = (
  data: PublicPaymentLinkType
): PublicPaymentLinkDomain => new PublicPaymentLinkDomain(data.url);

const publicPaymentInvoiceTransformerFromDtoToDomain = (
  data: PublicInvoiceType
): PublicInvoiceDomain => new PublicInvoiceDomain(data.id, data.url);

const publicPaymentBankAccountDetailsTransformerFromDtoToDomain = (
  data: BankAccountDetailsType
): BankAccountDetailsDomain =>
  new BankAccountDetailsDomain(
    data.account_holder_name,
    data.account_number,
    data.sort_code,
    data.country,
    data.iban,
    data.bic
  );

export const publicPaymentTransformerFromDtoToDomain = (
  dto: PublicPaymentDto
): PublicPaymentDomain =>
  new PublicPaymentDomain(
    dto.id,
    dto.amount,
    dto.currency,
    dto.status as PaymentStatus,
    dto.description,
    dto.merchant_name,
    dto.invoice !== null
      ? publicPaymentInvoiceTransformerFromDtoToDomain(dto.invoice!)
      : null,
    dto.payment_methods,
    dto.bank_account_details !== null
      ? publicPaymentBankAccountDetailsTransformerFromDtoToDomain(
          dto.bank_account_details
        )
      : null,
    dto.logo
  );
