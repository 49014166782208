import { Avatar } from "@chakra-ui/avatar";
import { Flex, Text } from "@chakra-ui/layout";
import React from "react";
import { components, OptionProps } from "react-select";

export interface AvailableAgentOption {
  value: string;
  label: string;
  avatarUrl?: string;
}

export const sortOptions = (arr: AvailableAgentOption[] | undefined) => {
  return arr
    ? arr.sort((op1, op2) =>
        op1.label.toLowerCase().localeCompare(op2.label.toLowerCase())
      )
    : undefined;
};

const AgentColumn = ({ item }: { item: AvailableAgentOption }) => {
  return (
    <Flex
      justifyContent="start"
      alignItems="center"
      gridGap={4}
      overflow="hidden"
      whiteSpace="nowrap"
      textOverflow="ellipsis"
    >
      <Avatar
        key={item.value}
        size="xs"
        src={item.avatarUrl}
        name={item.label}
      />
      <Text>{item.label}</Text>
    </Flex>
  );
};

const AgentOption = function (props: OptionProps<AvailableAgentOption>) {
  return (
    <components.Option {...props}>
      <AgentColumn item={props.data} />
    </components.Option>
  );
};

export default AgentOption;
