import { createSlice, PayloadAction, Draft } from "@reduxjs/toolkit";
import ConversationDomain from "entities/domain/conversations/conversation-domain";
import TemplateDomain from "entities/domain/templates";
import { UnreadCountDTO } from "entities/dto/UnreadCountDTO";
import { RootState } from "redux/store";
import { sortConversations } from "util/conversations";

export interface AssignAgentPayload {
  agentId?: number | null;
  teamId?: string | null;
  conversationId: number;
}

export enum ConversationTab {
  Personal = "personal",
  Team = "team",
  Unassigned = "unassigned",
}

export enum OpenClosedFilter {
  Open = "open",
  Closed = "closed",
}

interface CreateConversationState {
  isVisible: boolean;
  loading: boolean;
  errors: string[];
}

interface ConversationsState {
  conversations: ConversationDomain[];
  selectedConversationIds: number[];
  loading: boolean;
  errors: string[];
  activeConversationId: number | undefined;
  activeConversation: ConversationDomain | undefined;
  isLoadingActiveConversation: boolean;
  templates: TemplateDomain[];
  searchText: string;
  activeTab: ConversationTab | undefined;
  new: CreateConversationState;
  isOpenOrClosed: OpenClosedFilter;
  filterChannels: string[];
  filterAgents: string[];
  filterCustomerTagIds: string[];
  bulkActionsToolbarEnabled: boolean;
  selectedInbox: string | null;
  bulkActionsToolbarLoading: boolean;
  unreadCounts: UnreadCountDTO[];
}

const initialState: ConversationsState = {
  new: {
    isVisible: false,
    loading: false,
    errors: [],
  },
  conversations: [],
  selectedConversationIds: [],
  loading: true,
  searchText: "",
  selectedInbox: null,
  errors: [],
  activeConversationId: undefined,
  activeConversation: undefined,
  isLoadingActiveConversation: false,
  templates: [],
  activeTab: undefined,
  isOpenOrClosed: OpenClosedFilter.Open,
  filterChannels: [],
  filterAgents: [],
  filterCustomerTagIds: [],
  bulkActionsToolbarEnabled: true,
  bulkActionsToolbarLoading: false,
  unreadCounts: [],
};

const getRefreshedConversations = (
  updatedConversation: ConversationDomain,
  currentAgentId: number,
  {
    conversations,
    isOpenOrClosed,
    activeTab,
    searchText,
    filterChannels,
    filterAgents,
    filterCustomerTagIds,
    selectedInbox,
  }: Draft<ConversationsState>
) => {
  const alreadyExists = conversations.find(
    (c) => c.id === updatedConversation.id
  );

  if (alreadyExists) {
    conversations = conversations.map((c) =>
      c.id === updatedConversation.id ? updatedConversation : c
    );
  } else {
    conversations = [updatedConversation, ...conversations];
  }

  return conversations
    .filter((c) =>
      isOpenOrClosed === OpenClosedFilter.Open ? c.isOpen : !c.isOpen
    )
    .filter((c) => {
      if (activeTab === undefined) {
        return true;
      }

      if (activeTab === ConversationTab.Personal) {
        return c.assignedAgentId === currentAgentId;
      }

      if (activeTab === ConversationTab.Unassigned) {
        return c.assignedAgentId === null;
      }

      return true;
    })
    .filter((c) =>
      searchText
        ? c.displayName.toLowerCase().includes(searchText.toLowerCase())
        : true
    )
    .filter((c) =>
      filterChannels.length ? filterChannels.includes(c.channel) : true
    )
    .filter((c) => (selectedInbox ? c.assignedTeamId === selectedInbox : true))
    .filter((c) =>
      filterAgents.length
        ? filterAgents.includes((c.assignedAgentId || "unassigned").toString())
        : true
    )
    .filter((c) =>
      filterCustomerTagIds.length && c.tagIds.length
        ? c.tagIds.some((tagId) => filterCustomerTagIds.includes(tagId))
        : true
    )
    .sort(sortConversations);
};

export const appendOrUpdateTemplate = (
  template: TemplateDomain,
  templates: TemplateDomain[]
): TemplateDomain[] => {
  if (templates.filter((t) => t.id === template.id).length !== 0) {
    return templates.map((t) => (template.id === t.id ? template : t));
  }

  return [template, ...templates];
};

const conversationsSlice = createSlice({
  name: "conversations",
  initialState,
  reducers: {
    fetchConversations: (state) => {
      state.loading = true;
    },
    fetchConversationsSuccess: (state) => {
      state.loading = false;
    },
    fetchConversationsFail: (state, action: PayloadAction<string[]>) => {
      state.loading = false;
      state.errors = action.payload;
    },
    setSearchText: (state, action: PayloadAction<string>) => {
      state.searchText = action.payload.trim().replace(/\s{2,}/g, " ");
    },
    setActiveConversation: (
      state,
      action: PayloadAction<{
        conversationId: number | undefined;
        isPreloaded?: boolean;
      }>
    ) => {
      if (action.payload.conversationId && action.payload.isPreloaded) {
        state.activeConversationId = action.payload.conversationId;
        state.activeConversation = state.conversations.find(
          (c) => c.id === action.payload.conversationId
        );
        state.isLoadingActiveConversation = false;
      } else {
        state.activeConversationId = action.payload.conversationId;
        state.activeConversation = state.conversations.find(
          (c) => c.id === action.payload.conversationId
        );
        state.isLoadingActiveConversation = true;
      }
    },
    setSelectedInbox: (state, action: PayloadAction<string | null>) => {
      state.selectedInbox = action.payload;
    },
    setConversations: (state, action: PayloadAction<ConversationDomain[]>) => {
      state.conversations = action.payload;
    },
    bulkAppendOrReplaceConversations: (
      state,
      action: PayloadAction<{
        conversations: ConversationDomain[];
        currentAgentId: number;
      }>
    ) => {
      const currentConversationsLength =
        state.conversations.length !== 0
          ? state.conversations.length
          : action.payload.conversations.length;

      action.payload.conversations.forEach((conversation) => {
        state.conversations = getRefreshedConversations(
          conversation,
          action.payload.currentAgentId,
          state
        );
      });

      state.conversations = state.conversations.slice(
        0,
        currentConversationsLength
      );
    },
    appendOrReplaceConversation: (
      state,
      action: PayloadAction<{
        conversation: ConversationDomain;
        currentAgentId: number;
      }>
    ) => {
      state.conversations = getRefreshedConversations(
        action.payload.conversation,
        action.payload.currentAgentId,
        state
      );

      if (action.payload.conversation.id === state.activeConversationId) {
        state.isLoadingActiveConversation = false;
        state.activeConversation = action.payload.conversation;
      }
    },
    updateConversationIsSubscribed: (
      state,
      action: PayloadAction<{
        conversationId: number;
        isSubscribed: boolean;
      }>
    ) => {
      state.conversations = state.conversations.map((c) => {
        if (c.id === action.payload.conversationId) {
          c.isSubscribed = action.payload.isSubscribed;
        }
        return c;
      });
    },
    assignAgentSuccess: (
      state,
      action: PayloadAction<{
        conversation: ConversationDomain;
        currentAgentId: number;
      }>
    ) => {
      state.conversations = getRefreshedConversations(
        action.payload.conversation,
        action.payload.currentAgentId,
        state
      );
    },
    setTemplates: (state, action: PayloadAction<TemplateDomain[]>) => {
      state.templates = action.payload;
    },
    propagateTemplateUpdate: (state, action: PayloadAction<TemplateDomain>) => {
      state.templates = appendOrUpdateTemplate(action.payload, state.templates);
    },
    setActiveConversationTab: (
      state,
      action: PayloadAction<ConversationTab | undefined>
    ) => {
      state.activeTab = action.payload;
    },
    setActiveConversationOpenClosedFilter: (
      state,
      action: PayloadAction<OpenClosedFilter>
    ) => {
      state.isOpenOrClosed = action.payload;
    },
    setFilterChannels: (state, action: PayloadAction<string[]>) => {
      state.filterChannels = action.payload;
    },
    setFilterAgents: (state, action: PayloadAction<string[]>) => {
      state.filterAgents = action.payload;
    },
    setFilterCustomerTagIds: (state, action: PayloadAction<string[]>) => {
      state.filterCustomerTagIds = action.payload;
    },
    updateConversationSelection: (
      state,
      action: PayloadAction<{ conversationId: number; isSelected: boolean }>
    ) => {
      if (
        action.payload.isSelected &&
        !state.selectedConversationIds.includes(action.payload.conversationId)
      ) {
        state.selectedConversationIds.push(action.payload.conversationId);
      } else if (!action.payload.isSelected) {
        state.selectedConversationIds = state.selectedConversationIds.filter(
          (id) => id !== action.payload.conversationId
        );
      }
    },
    clearSelectedConversations: (state) => {
      state.selectedConversationIds = [];
    },
    markConversationsAsRead: (state, action: PayloadAction<number[]>) => {
      state.conversations = state.conversations.map((c) => {
        if (action.payload.includes(c.id)) {
          c.unreadCount = 0;
        }
        return c;
      });
    },
    markConversationsAsUnread: (state, action: PayloadAction<number[]>) => {
      state.conversations = state.conversations.map((c) => {
        if (action.payload.includes(c.id) && c.unreadCount === 0) {
          c.unreadCount = 1;
        }
        return c;
      });
    },
    markConversationsAsOpenedOrClosed: (
      state,
      action: PayloadAction<number[]>
    ) => {
      state.conversations = state.conversations.filter((c) => {
        return !action.payload.includes(c.id);
      });
    },
    assignAgentToConversations: (
      state,
      action: PayloadAction<{
        agentId: number | null;
        conversationIds: number[];
      }>
    ) => {
      state.conversations = state.conversations.map((c) => {
        if (action.payload.conversationIds.includes(c.id)) {
          c.assignedAgentId = action.payload.agentId;
        }
        return c;
      });
    },
    enableBulkActionsToolbar: (state) => {
      state.bulkActionsToolbarEnabled = true;
    },
    disableBulkActionsToolbar: (state) => {
      state.bulkActionsToolbarEnabled = false;
    },
    startLoadingBulkActionsToolbar: (state) => {
      state.bulkActionsToolbarLoading = true;
    },
    stopLoadingBulkActionsToolbar: (state) => {
      state.bulkActionsToolbarLoading = false;
    },
    setUnreadCounts: (state, action: PayloadAction<UnreadCountDTO[]>) => {
      state.unreadCounts = action.payload;
    },
    resetStore: (state) => {
      state.new = {
        isVisible: false,
        loading: false,
        errors: [],
      };
      state.conversations = [];
      state.selectedConversationIds = [];
      state.loading = true;
      state.searchText = "";
      state.selectedInbox = null;
      state.errors = [];
      state.activeConversationId = undefined;
      state.activeConversation = undefined;
      state.isLoadingActiveConversation = false;
      state.templates = [];
      state.activeTab = undefined;
      state.isOpenOrClosed = OpenClosedFilter.Open;
      state.filterChannels = [];
      state.filterAgents = [];
      state.filterCustomerTagIds = [];
      state.bulkActionsToolbarEnabled = true;
      state.bulkActionsToolbarLoading = false;
      state.unreadCounts = [];
    },
  },
});

export const conversationsSelector = (state: RootState) =>
  state.conversations.conversations;

export const activeConversationIdSelector = (state: RootState) =>
  state.conversations.activeConversationId;

export const activeConversationSelector = (state: RootState) =>
  state.conversations.activeConversation;

export const searchTextSelector = (state: RootState) =>
  state.conversations.searchText;

export const templatesSelector = (state: RootState) =>
  state.conversations.templates;

export const activeTabSelector = (state: RootState) =>
  state.conversations.activeTab;

export const openClosedSelector = (state: RootState) =>
  state.conversations.isOpenOrClosed;

export const filterChannelsSelector = (state: RootState) =>
  state.conversations.filterChannels;

export const filterAgentsSelector = (state: RootState) =>
  state.conversations.filterAgents;

export const filterCustomerTagIdsSelector = (state: RootState) =>
  state.conversations.filterCustomerTagIds;

export const loadingSelector = (state: RootState) =>
  state.conversations.loading;

export const isLoadingActiveConversationSelector = (state: RootState) =>
  state.conversations.isLoadingActiveConversation;

export const errorsSelector = (state: RootState) => state.conversations.errors;

export const {
  fetchConversations,
  fetchConversationsSuccess,
  fetchConversationsFail,
  setConversations,
  appendOrReplaceConversation,
  setActiveConversation,
  assignAgentSuccess,
  setTemplates,
  setActiveConversationTab,
  setActiveConversationOpenClosedFilter,
  setFilterChannels,
  setFilterAgents,
  setFilterCustomerTagIds,
  setSearchText,
  updateConversationSelection,
  clearSelectedConversations,
  enableBulkActionsToolbar,
  disableBulkActionsToolbar,
  startLoadingBulkActionsToolbar,
  stopLoadingBulkActionsToolbar,
  bulkAppendOrReplaceConversations,
  propagateTemplateUpdate,
  setUnreadCounts,
  setSelectedInbox,
  resetStore,
  updateConversationIsSubscribed,
} = conversationsSlice.actions;

export default conversationsSlice.reducer;
