import React, { useEffect, useState } from "react";
import {
  Flex,
  Text,
  Heading,
  Icon,
  useBreakpointValue,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Box,
  VStack,
  useColorMode,
  Button,
  Link,
  IconButton,
  Tooltip,
} from "@chakra-ui/react";
import ReviewDomain, {
  PrettyReviewStatus,
  ReviewStatus,
} from "entities/domain/reviews/review-domain";
import { ReactComponent as SendIcon } from "assets/icons/send.svg";
import { useAppSelector } from "redux/hooks";
import { useOutletContext } from "react-router-dom";
import Rating from "./ReviewRatings";
import SendReply from "./SendReply";
import ReviewReplyCard from "./ReviewReplyCard";
import AssignToCustomerForm from "./AssignToCustomerForm";
import ReviewAvatar from "./ReviewAvatar";

interface ReviewPageProps {
  isOpen: boolean;
  onClose: () => void;
  review: ReviewDomain | undefined;
  sendReply: (content: string, reviewId: string) => void;
}

const ReviewPage = () => {
  const context = useOutletContext<ReviewPageProps>();
  const { isOpen, onClose, review, sendReply } = context;
  const { merchant } = useAppSelector((state) => state.merchant);
  const { colorMode } = useColorMode();
  const { colorScheme } = useAppSelector((state) => state.theme);
  const isBaseSize = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );

  const [showAssignToCustomerForm, setShowAssignToCustomerForm] =
    useState<boolean>(false);

  useEffect(() => {
    setShowAssignToCustomerForm(false);
  }, [review?.id]);

  return !review ? null : (
    <Modal size={isBaseSize ? "full" : "xl"} isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader
          display="flex"
          alignItems="center"
          justifyContent="start"
          gridGap={6}
        >
          <ReviewAvatar isBig={true} review={review} />
          <VStack alignItems="start" gridGap={2} w="100%">
            {review.reviewer.customerId ? (
              <Link
                href={`/${merchant.id}/contacts/${review.reviewer.customerId}`}
                target="_blank"
              >
                <Heading display="block">
                  {review.reviewer.name || "Anonymous"}
                </Heading>
              </Link>
            ) : (
              <Heading display="block">
                {review.reviewer.name || "Anonymous"}
              </Heading>
            )}
            {showAssignToCustomerForm ||
            review.status === ReviewStatus.PENDING ? null : (
              <Button
                variant="link"
                colorScheme={colorScheme}
                size="xs"
                onClick={() => setShowAssignToCustomerForm(true)}
              >
                {!review.reviewer.customerId
                  ? "Assign Customer"
                  : "Reassign Customer"}
              </Button>
            )}
            {showAssignToCustomerForm ? (
              <AssignToCustomerForm
                review={review}
                onClose={() => setShowAssignToCustomerForm(false)}
              />
            ) : null}
          </VStack>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Flex alignItems="center" justifyContent="space-between" gridGap={4}>
            <Flex alignItems="center" gridGap={4}>
              <Rating value={review.rating} />
              <Text fontSize="xs" color="gray.500">
                {review.displayDate}
              </Text>
            </Flex>
          </Flex>
          {review.content ? (
            <Box
              textAlign="center"
              overflow="hidden"
              my={4}
              bgColor={colorMode === "dark" ? "black" : "gray.50"}
              borderRadius="lg"
              p={8}
            >
              <Text overflowY="auto" maxHeight="200px" fontSize="lg">
                {review.content}
              </Text>
            </Box>
          ) : null}
          {review.replies.length > 0
            ? review.replies.map((r, i) => (
                <ReviewReplyCard reply={r} key={i.toString()} />
              ))
            : null}
        </ModalBody>
        <ModalFooter>
          {review.replies.length === 0 ? (
            <SendReply review={review} sendReply={sendReply} />
          ) : null}
          {review.reviewer.conversationId && review.replies.length > 0 ? (
            <Tooltip
              isDisabled={isBaseSize}
              label="Jump to conversation"
              borderRadius="1rem"
              px={3}
              py={1}
              backgroundColor="rgba(54, 178, 250, 0.9)"
            >
              <Link
                href={`/${merchant.id}/inbox/${review.reviewer.conversationId}`}
                target="_blank"
              >
                <IconButton
                  variant="solid"
                  size="md"
                  aria-label="Start conversation"
                  icon={
                    <Icon
                      as={SendIcon}
                      __css={{
                        path: {
                          fill: colorMode === "dark" ? "black" : "white",
                        },
                      }}
                    />
                  }
                />
              </Link>
            </Tooltip>
          ) : null}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ReviewPage;
