const ERROR_DESCRIPTION_BY_CODE: { [key: string]: string } = {
  phone_based_channels_with_different_handles:
    "It is not possible to merge contacts with different phone numbers.",
  channel_already_assigned_to_another_customer:
    "There is already a contact with the same channel and phone number.",
  contact_form_not_properly_set_up:
    "Contact Form is not properly configured. If you are using custom configurations, " +
    "please ensure the query param is correctly encoded.",
  error_while_submitting_contact_form: "Error while sending your enquiry.",
  template_already_exists: "A template already exists with the same name.",
  template_not_found: "Oops! Something went wrong. Please try again!",
  NO_CHANNEL_PROVIDED: "No e-mail or phone number provided.",
  INVALID_EMAIL: "Invalid e-email.",
  INVALID_PHONE_NUMBER: "Invalid phone number.",
  INVALID_FILE_TYPE:
    "The provided file is not supported. Make sure it is a plain text CSV file and try again.",
  NO_FILES_RECEIVED:
    "No file was selected. Please select a file and try again.",
  FILE_TOO_LARGE:
    "The provided file is too large. Please select a file smaller than 10MB and try again.",
  INVALID_ROWS: "Invalid lines were found. Please fix them and try again.",
};

function getErrorDescription(errorCode: string): string {
  return ERROR_DESCRIPTION_BY_CODE[errorCode];
}

export function getErrorDescriptionOrDefault(
  errorCode: string,
  defaultMessage: string
): string {
  const errorMessage = getErrorDescription(errorCode);
  if (!errorMessage) {
    return defaultMessage;
  }
  return errorMessage;
}

export default getErrorDescription;
