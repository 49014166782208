import { AspectRatio, Icon, Image } from "@chakra-ui/react";
import { ReactComponent as FileUnknownIcon } from "assets/icons/file-unknown-svgrepo-com.svg";
import React, { useEffect, useMemo, useState } from "react";
import { IoDocument } from "react-icons/io5";
import FileThumbnailWrapper from "./FileThumbnailWrapper";

interface FileThumbnailProps {
  size: "sm" | "md" | "lg";
  file?: File;
  fileUrl?: string;
  fileType?: string;
  fileName?: string;
  showText?: boolean;
  onDelete?: () => void;
}

const FileThumbnail = ({
  size,
  file,
  fileUrl,
  fileType,
  fileName,
  showText = true,
  onDelete,
}: FileThumbnailProps) => {
  const [fileFormat, setFileFormat] = useState<string>(
    (file?.type || fileType || "").split("/")[0]
  );
  const [boxSize, setBoxSize] = useState<number>(4);

  useEffect(() => {
    setFileFormat((file?.type || fileType || "").split("/")[0]);
  }, [file, fileType]);

  useEffect(() => {
    switch (size) {
      case "sm":
        setBoxSize(10);
        break;
      case "md":
        setBoxSize(14);
        break;
      case "lg":
        setBoxSize(18);
        break;
      default:
        setBoxSize(10);
        break;
    }
  }, [size]);

  const thumbnail = useMemo(() => {
    switch (fileFormat) {
      case "image":
        return (
          <Image
            src={file ? URL.createObjectURL(file) : fileUrl}
            boxSize={boxSize}
            objectFit="cover"
          />
        );
      case "video":
        return (
          <AspectRatio boxSize={boxSize}>
            <video src={file ? URL.createObjectURL(file) : fileUrl} />
          </AspectRatio>
        );
      case "application":
        return <Icon as={IoDocument} boxSize={boxSize} />;
      default:
        return <Icon as={FileUnknownIcon} boxSize={boxSize} />;
    }
  }, [boxSize, file, fileFormat]);

  return (
    <FileThumbnailWrapper
      fileName={file?.name || fileName || ""}
      size={size}
      showText={showText}
      onDelete={onDelete}
    >
      {thumbnail}
    </FileThumbnailWrapper>
  );
};

export default FileThumbnail;
