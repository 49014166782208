import {
  Button,
  FormControl,
  FormLabel,
  Input,
  Stack,
  useToast,
} from "@chakra-ui/react";
import AdminMerchantDomain from "entities/domain/admin/merchants/admin-merchant-domain";
import React, { useState } from "react";
import { useAppSelector } from "redux/hooks";
import AdminService from "services/admin";
import { useAuth0 } from "@auth0/auth0-react";
import Select, { SingleValue } from "react-select";
import SelectMerchant from "./select-merchant";

interface DmsOptionTypes {
  value: string;
  label: string;
}

const AddKeyloopAccount = () => {
  const auth0Context = useAuth0();
  const toast = useToast();
  const { colorScheme } = useAppSelector((state) => state.theme);

  const [accountName, setAccountName] = useState("");
  const [enterpriseId, setEnterpriseId] = useState("");
  const dmsOptions: DmsOptionTypes[] = [
    { value: "rev8", label: "Rev8" },
    { value: "drive", label: "Drive" },
  ];
  const [selectedDmsType, setSelectedDmsType] = useState<
    SingleValue<DmsOptionTypes>
  >(dmsOptions[0]);
  const [storeId, setStoreId] = useState("");

  const [selectedMerchants, setSelectedMerchants] = useState<
    AdminMerchantDomain[]
  >([]);

  const resetValues = () => {
    setAccountName("");
    setEnterpriseId("");
    setStoreId("");
    setSelectedDmsType(dmsOptions[0]);
    setSelectedMerchants([]);
  };

  const handleDmsChange = (e: SingleValue<any>) => {
    setSelectedDmsType(e);
  };

  const addKeyloopAccount = async () => {
    try {
      await AdminService.addKeyloopAccount(auth0Context, {
        accountName,
        enterpriseId,
        storeId,
        dmsType: selectedDmsType!.value,
        merchantIds: selectedMerchants.map((m) => m.id),
      });
      toast({
        status: "success",
        title: "Keyloop account added successfully!",
      });
    } catch (e: unknown) {
      // eslint-disable-next-line
      console.error("Couldn't add keyloop account", e);
      toast({
        status: "error",
        title: "Couldn't add keyloop account",
      });
    } finally {
      resetValues();
    }
  };

  return (
    <Stack spacing={3}>
      <FormControl isRequired>
        <FormLabel>Account Name</FormLabel>
        <Input
          value={accountName}
          onChange={(e) => setAccountName(e.target.value)}
        />
      </FormControl>
      <FormControl isRequired>
        <FormLabel>Enterprise ID</FormLabel>
        <Input
          value={enterpriseId}
          onChange={(e) => setEnterpriseId(e.target.value)}
        />
      </FormControl>
      <FormControl isRequired>
        <FormLabel>Store ID</FormLabel>
        <Input value={storeId} onChange={(e) => setStoreId(e.target.value)} />
      </FormControl>
      <FormControl isRequired>
        <FormLabel>DMS Type</FormLabel>
        <Select
          placeholder="Select DMS"
          onChange={handleDmsChange}
          options={dmsOptions}
          value={selectedDmsType}
        />
      </FormControl>
      <FormControl>
        <FormLabel>Choose Merchants</FormLabel>
        <SelectMerchant
          isMulti={true}
          selectedMerchants={selectedMerchants}
          setSelectedMerchants={setSelectedMerchants}
        />
      </FormControl>
      <Button
        colorScheme={colorScheme}
        onClick={addKeyloopAccount}
        isDisabled={!storeId || !enterpriseId || !accountName}
        mt={8}
        mb={8}
        size="lg"
      >
        Add Account
      </Button>
    </Stack>
  );
};

export default AddKeyloopAccount;
