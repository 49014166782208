import { Flex } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";

import OriginalSendButton from "../new-message-input-box/SendButton";

interface SendButtonProps {
  isVisible: boolean;
  isDisabled: boolean;
  onSend: () => void;
}

const SendButton = ({ isVisible, onSend, isDisabled }: SendButtonProps) => {
  const [shouldRender, setShouldRender] = useState<boolean>(true);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setShouldRender(isVisible);
    }, 500);

    return () => {
      clearTimeout(timeout);
    };
  }, [isVisible]);

  if (!shouldRender) {
    return null;
  }

  return (
    <Flex alignSelf="center">
      <OriginalSendButton
        isVisible={isVisible}
        send={onSend}
        isDisabled={isDisabled}
      />
    </Flex>
  );
};

export default SendButton;
