import { configureStore } from "@reduxjs/toolkit";
// import thunk from "redux-thunk";
// import logger from "redux-logger";
import conversationsReducer from "./features/conversations";
import messagesReducer from "./features/messages";
import attachmentsReducer from "./features/attachments";
import agentsReducer from "./features/agents";
import templatesReducer from "./features/templates";
import campaignsReducer from "./features/campaigns";
import audiencesReducer from "./features/audiences";
import paymentsReducer from "./features/payments";
import notificationsReducer from "./features/notifications";
import merchantReducer from "./features/merchant";
import publicPaymentsReducer from "./features/publicPayments";
import xeroReducer from "./features/xero";
import calendarReducer from "./features/calendar";
import contactsReducer from "./features/contacts";
import themeReducer from "./features/theme";
import onboardingReducer from "./features/onboarding";
import tagsReducer from "./features/tags";
import inventoryReducer from "./features/inventory";
import filesReducer from "./features/files";
import attributesReducer from "./features/attributes";

// const isDev = process.env.NODE_ENV === `development`;

// const middlewares = [];

// if (isDev) {
//   middlewares.push(logger);
// }

// // NOTE: Thunk has to be the last
// middlewares.push(thunk);

const store = configureStore({
  reducer: {
    attachments: attachmentsReducer,
    agents: agentsReducer,
    audiences: audiencesReducer,
    calendar: calendarReducer,
    tags: tagsReducer,
    campaigns: campaignsReducer,
    contacts: contactsReducer,
    conversations: conversationsReducer,
    merchant: merchantReducer,
    messages: messagesReducer,
    notifications: notificationsReducer,
    onboarding: onboardingReducer,
    payments: paymentsReducer,
    templates: templatesReducer,
    publicPayments: publicPaymentsReducer,
    xero: xeroReducer,
    theme: themeReducer,
    inventory: inventoryReducer,
    files: filesReducer,
    attributes: attributesReducer,
  },
});

export default store;
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
