import { Heading, Text } from "@chakra-ui/react";
import React from "react";
import MessagePreviewSnippet from "./MessagePreviewSnippet";

interface OnboardingMessagePreviewProps {
  AutoSuggestionReply?: string;
  newMessage?: string;
}

const OnboardingMessagePreview = ({
  AutoSuggestionReply,
  newMessage,
}: OnboardingMessagePreviewProps) => {
  return (
    <>
      <Heading> Hooray! </Heading>
      <Text fontSize="sm" textAlign="center">
        {" "}
        {AutoSuggestionReply
          ? "Check your phone for the following message!"
          : "We have received the following message..."}{" "}
      </Text>
      <MessagePreviewSnippet
        newMessage={newMessage}
        AutoSuggestionReply={AutoSuggestionReply}
      />
      <Text fontSize="sm" textAlign="center">
        As you can see, your account has auto replies enabled by default.
      </Text>
      <Text fontSize="sm" textAlign="center">
        Check your phone for a friendly reply!
      </Text>
    </>
  );
};

export default OnboardingMessagePreview;
