import { Flex, Text } from "@chakra-ui/react";
import React from "react";
import FileThumbnail from "./FileThumbnail";

interface FileListToSubmitProps {
  size: "sm" | "md" | "lg";
  files: File[];
  setFiles: React.Dispatch<React.SetStateAction<File[]>>;
}

const FileListToSubmit = ({ size, files, setFiles }: FileListToSubmitProps) => {
  return (
    <Flex
      {...(size === "sm"
        ? {
            direction: "row",
            alignItems: "center",
            justifyContent: "center",
          }
        : {
            direction: "column",
            alignItems: "start",
            justifyContent: "start",
            w: "100%",
          })}
    >
      {size === "sm" || files.length > 1 ? (
        <Text>{files.length} files selected</Text>
      ) : null}
      {size !== "sm" || files.length === 1
        ? files.map((file, index) => (
            <FileThumbnail
              key={index}
              size={size}
              file={file}
              onDelete={
                files.length === 1
                  ? undefined
                  : () =>
                      setFiles((prevFiles) =>
                        prevFiles.filter((_, i) => i !== index)
                      )
              }
            />
          ))
        : null}
    </Flex>
  );
};

export default FileListToSubmit;
