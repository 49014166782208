import React from "react";
import { Heading, VStack } from "@chakra-ui/react";

type HeaderProps = {
  title?: string;
  subtitle?: string;
  compactVersion?: boolean;
};

export default function Header({
  title,
  subtitle,
  compactVersion,
}: HeaderProps) {
  return (
    <VStack
      spacing={compactVersion ? "1" : { base: 1, md: 2 }}
      pt={compactVersion ? "1" : { base: "1", md: "3" }}
      pb={compactVersion ? "1" : { base: "2", md: "8" }}
    >
      {title && (
        <Heading size="lg" textAlign="center">
          {title}
        </Heading>
      )}
      {subtitle && (
        <Heading size="md" textAlign="center">
          {subtitle}
        </Heading>
      )}
    </VStack>
  );
}
