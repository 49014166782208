import React, { ChangeEvent } from "react";
import { Icon, Input, InputGroup, InputLeftElement } from "@chakra-ui/react";
import { useAppSelector } from "redux/hooks";
import { SearchIcon } from "@chakra-ui/icons";

interface ReviewsSearchProps {
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  value: string;
  placeholder: string;
}

const ReviewsSearch = ({
  onChange,
  value,
  placeholder,
}: ReviewsSearchProps) => {
  const { colorScheme } = useAppSelector((state) => state.theme);

  return (
    <InputGroup maxWidth="500px">
      <InputLeftElement pointerEvents="none">
        <Icon as={SearchIcon} color="gray.500" />
      </InputLeftElement>
      <Input
        colorScheme={colorScheme}
        placeholder={placeholder}
        onChange={onChange}
        value={value}
      />
    </InputGroup>
  );
};

export default ReviewsSearch;
