import { useAuth0 } from "@auth0/auth0-react";
import ConversationDomain, {
  AllowedMessageType,
} from "entities/domain/conversations/conversation-domain";
import MessageDomain, {
  MessageDirection,
} from "entities/domain/conversations/message-domain";
import { useCallback } from "react";
import {
  ConversationTab,
  OpenClosedFilter,
  AssignAgentPayload,
  assignAgentSuccess,
  fetchConversations,
  fetchConversationsFail,
  fetchConversationsSuccess,
  setActiveConversation as setActiveConversationAction,
  setConversations,
  appendOrReplaceConversation,
  setTemplates,
  activeConversationIdSelector,
  activeTabSelector,
  conversationsSelector,
  filterAgentsSelector,
  filterChannelsSelector,
  filterCustomerTagIdsSelector,
  openClosedSelector,
  searchTextSelector,
} from "redux/features/conversations";
import InboxService from "services/inbox";
import TemplatesService from "services/templates";
import {
  ConversationDomainUpdate,
  updateConversation,
} from "util/conversations";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { clearMessages } from "redux/features/messages";
import { batch } from "react-redux";

export default function useConversationsStore() {
  const dispatch = useAppDispatch();
  const auth0Context = useAuth0();
  const { merchant } = useAppSelector((state) => state.merchant);
  const { currentAgent, agents } = useAppSelector((state) => state.agents);

  const searchText: string = useAppSelector(searchTextSelector);
  const conversations: ConversationDomain[] = useAppSelector(
    conversationsSelector
  );
  const activeTab: ConversationTab | undefined =
    useAppSelector(activeTabSelector);
  const selectedInbox: string | null = useAppSelector(
    (state) => state.conversations.selectedInbox
  );
  const isOpenOrClosed: OpenClosedFilter = useAppSelector(openClosedSelector);
  const filterChannels: string[] = useAppSelector(filterChannelsSelector);
  const filterAgents: string[] = useAppSelector(filterAgentsSelector);
  const filterCustomerTagIds: string[] = useAppSelector(
    filterCustomerTagIdsSelector
  );
  const activeConversationId: number | undefined = useAppSelector(
    activeConversationIdSelector
  );

  const findConversationInList = (
    conversationId: number
  ): ConversationDomain | undefined => {
    return conversations.find((c) => c.id === conversationId);
  };

  const findCustomerConversations = (
    customerId: number
  ): ConversationDomain[] => {
    return conversations.filter((c) => c.customerId === customerId);
  };

  const updateConversationWithNewMessage = (message: MessageDomain) => {
    const conversation = findConversationInList(message.conversationId);
    if (conversation) {
      let { unreadCount } = conversation;

      if (message.direction === MessageDirection.INCOMING) {
        if (message.conversationId !== activeConversationId) {
          unreadCount++;
        }
      }

      dispatch(
        appendOrReplaceConversation({
          conversation: updateConversation(conversation, {
            unreadCount,
            messageId: message.id,
            previewText: message.body,
            displayDate: message.createdAt,
            allowedMessageType: message.isIncoming
              ? AllowedMessageType.ALL
              : conversation.allowedMessagesType,
          }),
          currentAgentId: currentAgent!.id,
        })
      );
    } else {
      getConversation(message.conversationId, currentAgent!.id);
    }
  };

  const updateConversationsWithCustomerUpdate = (
    customerId: number,
    updates: ConversationDomainUpdate
  ) => {
    const convos = findCustomerConversations(customerId);
    if (convos.length > 0) {
      batch(() => {
        for (const c of convos) {
          dispatch(
            appendOrReplaceConversation({
              conversation: updateConversation(c, updates),
              currentAgentId: currentAgent!.id,
            })
          );
        }
      });
    }
  };

  const setActiveConversationWaterfall =
    (conversationId: number | undefined) => async (): Promise<void> => {
      const conversation = conversationId
        ? findConversationInList(conversationId)
        : undefined;

      dispatch(
        setActiveConversationAction({
          conversationId,
          isPreloaded: !!conversation,
        })
      );

      dispatch(clearMessages({ conversationId }));

      if (!conversation && !conversationId) {
        return;
      }

      if (!conversation && conversationId) {
        getConversation(conversationId, currentAgent!.id);
      }

      try {
        const templates = await TemplatesService.getTemplatesForConversation(
          auth0Context,
          conversationId as number,
          merchant,
          currentAgent!
        );

        dispatch(setTemplates(templates));
      } catch (error) {
        // eslint-disable-next-line
        console.error(
          "Failed to fetch messages and templates for active conversation",
          error
        );
      }
    };

  const setActiveConversation = useCallback(
    (conversationId: number | undefined) =>
      setActiveConversationWaterfall(conversationId)(),
    [dispatch, merchant, currentAgent, agents, activeConversationId]
  );

  const getConversationWaterfall =
    (conversationId: number, currentAgentId: number) =>
    async (): Promise<ConversationDomain | undefined> => {
      try {
        const conversationResponse = await InboxService.getConversation(
          auth0Context,
          conversationId,
          merchant.id
        );

        dispatch(
          appendOrReplaceConversation({
            conversation: conversationResponse,
            currentAgentId,
          })
        );
        return conversationResponse;
      } catch (err) {
        return undefined;
      }
    };

  const getConversationsWaterfall =
    (offset: number) => async (): Promise<ConversationDomain[] | undefined> => {
      try {
        dispatch(fetchConversations());

        let fetchedConversations: ConversationDomain[] = [];

        if (activeTab === ConversationTab.Team) {
          fetchedConversations = await InboxService.getConversations(
            auth0Context,
            isOpenOrClosed === OpenClosedFilter.Open ? "open" : "closed",
            searchText,
            offset,
            merchant.id,
            filterChannels,
            filterAgents,
            filterCustomerTagIds,
            selectedInbox
          );
        } else if (activeTab === ConversationTab.Personal) {
          fetchedConversations = await InboxService.getPersonalConversations(
            auth0Context,
            isOpenOrClosed === OpenClosedFilter.Open ? "open" : "closed",
            searchText,
            offset,
            merchant.id,
            filterChannels,
            filterCustomerTagIds,
            selectedInbox
          );
        } else if (activeTab === ConversationTab.Unassigned) {
          fetchedConversations = await InboxService.getUnassignedConversations(
            auth0Context,
            isOpenOrClosed === OpenClosedFilter.Open ? "open" : "closed",
            searchText,
            offset,
            merchant.id,
            filterChannels,
            filterCustomerTagIds,
            selectedInbox
          );
        }

        const fetchedConversationsWithoutDuplicates = [
          ...new Map(fetchedConversations.map((c) => [c.id, c])).values(),
        ];

        dispatch(setConversations(fetchedConversationsWithoutDuplicates));
        dispatch(fetchConversationsSuccess());

        return fetchedConversationsWithoutDuplicates;
      } catch (err) {
        dispatch(
          fetchConversationsFail(["Oops. Could not retrieve conversations"])
        );
        return undefined;
      }
    };

  const getConversation = useCallback(
    (conversationId: number, currentAgentId: number) =>
      getConversationWaterfall(conversationId, currentAgentId)(),
    [dispatch, merchant, currentAgent, agents]
  );

  const getConversations = useCallback(
    (offset: number) => {
      return getConversationsWaterfall(offset)();
    },
    [
      dispatch,
      merchant,
      currentAgent,
      agents,
      searchText,
      isOpenOrClosed,
      filterChannels,
      filterAgents,
      filterCustomerTagIds,
      activeTab,
      selectedInbox,
    ]
  );

  const assignAgentWaterfall =
    (payload: AssignAgentPayload, currentAgentId: number) =>
    async (): Promise<ConversationDomain | undefined> => {
      try {
        const { conversationId, agentId, teamId } = payload;

        const conversationResponse = await InboxService.assignAgentOrTeam(
          auth0Context,
          {
            conversationId,
            agentId,
            teamId,
          },
          merchant.id
        );

        dispatch(
          assignAgentSuccess({
            conversation: conversationResponse,
            currentAgentId,
          })
        );
        return conversationResponse;
      } catch (err) {
        return undefined;
      }
    };

  const assignAgent = useCallback(
    (payload: AssignAgentPayload, currentAgentId: number) =>
      assignAgentWaterfall(payload, currentAgentId)(),
    [dispatch, merchant, currentAgent, agents]
  );

  const closeOrOpenConversationWaterfall =
    (conversationId: number, isOpen: boolean, currentAgentId: number) =>
    async (): Promise<ConversationDomain | undefined> => {
      try {
        const convResponse = await InboxService.updateConversation(
          auth0Context,
          {
            conversationId,
            is_open: isOpen,
          },
          merchant.id
        );

        return convResponse;
      } catch (err: any) {
        return undefined;
      }
    };

  const closeOrOpenConversation = useCallback(
    (conversationId: number, isOpen: boolean, currentAgentId: number) =>
      closeOrOpenConversationWaterfall(
        conversationId,
        isOpen,
        currentAgentId
      )(),
    [dispatch, merchant]
  );

  return {
    findConversationInList,
    setActiveConversation,
    assignAgent,
    getConversations,
    getConversation,
    closeOrOpenConversation,
    updateConversationWithNewMessage,
    updateConversationsWithCustomerUpdate,
  };
}
