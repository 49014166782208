import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  UseDisclosureProps,
  useBreakpointValue,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useCallback } from "react";
import SubmitFileForm from "./SubmitFileForm";

interface SubmitFileFormProps {
  disclosureProps?: UseDisclosureProps;
  files: File[];
  size: "sm" | "md" | "lg";
  submitButtonText?: string;
  setFiles: React.Dispatch<React.SetStateAction<File[]>>;
  onSubmit?: (files: File[]) => Promise<void>;
}

const SubmitFile = ({
  disclosureProps,
  size,
  submitButtonText,
  files,
  setFiles,
  onSubmit,
}: SubmitFileFormProps) => {
  const isBaseSize = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );

  const onClose = useCallback(() => {
    setFiles([]);
    if (
      disclosureProps !== undefined &&
      disclosureProps.onClose !== undefined
    ) {
      disclosureProps.onClose();
    }
  }, [disclosureProps]);

  if (onSubmit === undefined) {
    return null;
  }

  return disclosureProps ? (
    <Modal
      size={isBaseSize ? "full" : "md"}
      isOpen={disclosureProps.isOpen || false}
      onClose={onClose}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Upload File</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <SubmitFileForm
            submitButtonText={submitButtonText}
            isInModal={true}
            size={isBaseSize ? "md" : size}
            files={files}
            setFiles={setFiles}
            onSubmit={onSubmit}
          />
        </ModalBody>
      </ModalContent>
    </Modal>
  ) : (
    <SubmitFileForm
      submitButtonText={submitButtonText}
      size={size}
      files={files}
      setFiles={setFiles}
      onSubmit={onSubmit}
    />
  );
};

export default SubmitFile;
