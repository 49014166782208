import { StyleConfig } from "@chakra-ui/theme-tools";

const HeadingStyles = {
  baseStyle: {
    fontWeight: "semibold",
    textAlign: "center",
  },
  defaultProps: {
    size: "lg",
  },
} as StyleConfig;

export default HeadingStyles;
