import PermissionDomain from "./permission";

class RoleDomain {
  id: number;

  name: string;

  description: string;

  permissions: PermissionDomain[];

  constructor(
    id: number,
    name: string,
    description: string,
    permissions: PermissionDomain[]
  ) {
    this.id = id;
    this.name = name;
    this.description = description;
    this.permissions = permissions;
  }
}

export default RoleDomain;
