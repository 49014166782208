import { StyleConfig } from "@chakra-ui/theme-tools";

const ModalStyles = {
  sizes: {
    full: {
      dialogContainer: {
        borderRadius: "0!important",
      },
      dialog: {
        borderRadius: "0!important",
      },
    },
  },
} as StyleConfig;

export default ModalStyles;
