import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import PublicPaymentLinkDomain from "entities/domain/public/payments/paymentLink";
import PublicPaymentDomain from "entities/domain/public/payments/payments";
import YapilyBankDomain from "entities/domain/public/payments/yapily/yapilyBank";

export interface generatePaymentUrlPayload {
  payment_id: string;
  provider: string;
  bank_id?: string;
}

interface PublicPaymentState {
  loading: boolean;
  errors: any[];
  publicPayment: PublicPaymentDomain | undefined;
  paymentUrl: PublicPaymentLinkDomain | undefined;
  popularBankList: YapilyBankDomain[];
  otherBankList: YapilyBankDomain[];
}

const initialState: PublicPaymentState = {
  publicPayment: undefined,
  loading: false,
  errors: [],
  paymentUrl: undefined,
  popularBankList: [],
  otherBankList: [],
};

const publicPaymentsSlice = createSlice({
  name: "publicPayments",
  initialState,
  reducers: {
    fetchPublicPayment(state, action: PayloadAction<string>) {
      state.loading = true;
    },
    fetchPublicPaymentSuccess(
      state,
      action: PayloadAction<PublicPaymentDomain>
    ) {
      state.publicPayment = action.payload;
      state.loading = false;
      state.errors = [];
    },
    fetchPublicPaymentFail(state, action: PayloadAction<any[]>) {
      state.errors = action.payload;
      state.loading = false;
    },
    fetchPublicPaymentUrl(state, action: PayloadAction<string>) {
      state.loading = true;
    },
    fetchPublicPaymentUrlSuccess(
      state,
      action: PayloadAction<PublicPaymentLinkDomain>
    ) {
      state.paymentUrl = action.payload;
      state.loading = false;
      state.errors = [];
    },
    fetchPublicPaymentUrlFail(state, action: PayloadAction<any[]>) {
      state.errors = action.payload;
      state.loading = false;
    },
    fetchBanksFromYapily(state) {
      state.loading = true;
      state.errors = [];
    },
    fetchBanksFromYapilyFail(state, action: PayloadAction<any[]>) {
      state.errors = action.payload;
      state.loading = false;
    },
    fetchBanksFromYapilySuccess(
      state,
      action: PayloadAction<YapilyBankDomain[]>
    ) {
      state.popularBankList = action.payload.filter((bank) => bank.popular);
      state.otherBankList = action.payload.filter((bank) => !bank.popular);
      state.loading = false;
    },
    resetStore(state) {
      state.publicPayment = undefined;
      state.loading = false;
      state.errors = [];
      state.paymentUrl = undefined;
      state.popularBankList = [];
      state.otherBankList = [];
    },
  },
});

export const {
  fetchPublicPayment,
  fetchPublicPaymentSuccess,
  fetchPublicPaymentFail,
  fetchPublicPaymentUrl,
  fetchPublicPaymentUrlSuccess,
  fetchPublicPaymentUrlFail,
  fetchBanksFromYapily,
  fetchBanksFromYapilyFail,
  fetchBanksFromYapilySuccess,
  resetStore,
} = publicPaymentsSlice.actions;

export default publicPaymentsSlice.reducer;
