import { StyleConfig } from "@chakra-ui/theme-tools";

const InputStyles = {
  variants: {
    flushed: ({ colorMode, colorScheme }) => ({
      field: {
        _focus: {
          borderColor:
            colorMode === "dark" ? `${colorScheme}.200` : `${colorScheme}.500`,
        },
      },
    }),
    outline: ({ colorMode, colorScheme }) => ({
      field: {
        border: "none",
        borderRadius: "full",
        background: colorMode === "dark" ? "gray.900" : "gray.50",
        _focus: {
          border: "1px solid",
          borderColor:
            colorMode === "dark" ? `${colorScheme}.200` : `${colorScheme}.500`,
        },
        _placeholder: {
          color: colorMode === "dark" ? "gray.200" : "gray.500",
        },
      },
    }),
  },
  defaultProps: {
    size: "md",
    variant: "outline",
    colorScheme: "blue",
  },
} as StyleConfig;

export default InputStyles;
