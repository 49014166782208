import { useAuth0 } from "@auth0/auth0-react";
import {
  fetchPayments,
  fetchPaymentsFail,
  fetchPaymentsSuccess,
  updatePayment,
  updatePaymentFail,
  UpdatePaymentPayload,
  updatePaymentSuccess,
} from "redux/features/payments";
import PaymentDomain, {
  PaymentStatus,
} from "entities/domain/payments/payment-domain";
import { useCallback } from "react";
import PaymentsService from "services/payments";
import { useAppDispatch, useAppSelector } from "redux/hooks";

export type SelectedPayment = PaymentDomain | undefined;

export default function usePaymentsStore() {
  const dispatch = useAppDispatch();
  const auth0Context = useAuth0();
  const { merchant } = useAppSelector((state) => state.merchant);

  const fetchPaymentsWaterfall = () => async () => {
    try {
      dispatch(fetchPayments());

      const paymentsResponse = await PaymentsService.getPayments(
        auth0Context,
        merchant.id
      );

      dispatch(fetchPaymentsSuccess(paymentsResponse));
    } catch (err) {
      dispatch(fetchPaymentsFail(["Oops. Please try again."]));
    }
  };

  const updatePaymentWaterfall =
    (payload: UpdatePaymentPayload) => async () => {
      try {
        dispatch(updatePayment());

        const paymentResponse = await PaymentsService.updatePayment(
          auth0Context,
          payload,
          merchant.id
        );

        dispatch(updatePaymentSuccess(paymentResponse));
      } catch (err) {
        dispatch(
          updatePaymentFail([
            "Oops. We couldn't update this payment request. Please try again!",
          ])
        );
      }
    };

  const fetchPaymentsAsync = useCallback(
    () => dispatch(fetchPaymentsWaterfall()),
    [dispatch, merchant]
  );

  const editPayment = useCallback(
    (payload: UpdatePaymentPayload) =>
      dispatch(updatePaymentWaterfall(payload)),
    [dispatch, merchant]
  );

  const displayPaymentGroup = (payment: PaymentDomain) =>
    payment.getPaymentStatusGroup() === PaymentStatus.CANCELED
      ? "cancelled"
      : payment.getPaymentStatusGroup();

  return {
    fetchPayments: fetchPaymentsAsync,
    editPayment,
    displayPaymentGroup,
  };
}
