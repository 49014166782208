import {
  Button,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";
import { useAppSelector } from "redux/hooks";
import React, { ReactElement, memo, useEffect, useState } from "react";
import { CustomFieldKey, CustomFields } from "entities/domain/templates";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";

interface EditCustomFieldModalProps {
  isOpen: boolean;
  field: {
    key: CustomFieldKey;
    setNewValue: (newValue: string) => void;
  } | null;
  customFields: CustomFields;
  setIsOpen: (isOpen: boolean) => void;
  addOrReplaceCustomField: (key: string, value: string) => void;
}

const EditCustomFieldModal = ({
  isOpen,
  field,
  customFields,
  setIsOpen,
  addOrReplaceCustomField,
}: EditCustomFieldModalProps) => {
  const [editor] = useLexicalComposerContext();

  const { colorScheme } = useAppSelector((state) => state.theme);
  const [customFieldValueModal, setCustomFieldValueModal] = useState<string>(
    field?.key ? customFields[field.key] || "" : ""
  );
  const [customFieldKeyModal, setCustomFieldKeyModal] = useState<string>("");
  const [customFieldTipModal, setCustomFieldTipModal] =
    useState<ReactElement | null>(null);

  const isBaseSize = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );

  const onClose = () => {
    setCustomFieldValueModal("");
    setCustomFieldTipModal(null);
    setIsOpen(false);
  };

  useEffect(() => {
    if (!field) {
      return;
    }

    if (customFields[field.key] === `{${field.key}}`) {
      setCustomFieldValueModal("");
      setCustomFieldKeyModal(field.key);
      return;
    }

    setCustomFieldValueModal(customFields[field.key] || "");
    setCustomFieldKeyModal(field.key);
  }, [field?.key, customFields, isOpen]);

  if (!field) {
    return null;
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        onClose();
      }}
      size={isBaseSize ? "md" : "xl"}
      autoFocus={false}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader textAlign="center">
          {customFieldTipModal || <Text mt="1rem">Insert Custom Text</Text>}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody mx={4}>
          <Input
            colorScheme={colorScheme}
            placeholder={customFieldKeyModal}
            type="text"
            value={customFieldValueModal}
            onChange={(e) => setCustomFieldValueModal(e.target.value)}
          />
        </ModalBody>
        <ModalFooter display="flex" justifyContent="center" alignItems="center">
          <Button
            colorScheme={colorScheme}
            data-testid="submit_custom_field_value"
            onClick={() => {
              addOrReplaceCustomField(
                customFieldKeyModal,
                customFieldValueModal
              );
              editor.update(() => {
                field.setNewValue(customFieldValueModal);
              });
              onClose();
            }}
          >
            Done
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default memo(EditCustomFieldModal);
