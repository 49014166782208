import { useAuth0 } from "@auth0/auth0-react";
import {
  Avatar,
  AvatarBadge,
  Button,
  Flex,
  Icon,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Portal,
  StackDivider,
  VStack,
  useColorMode,
  useToast,
} from "@chakra-ui/react";
import CustomerChannelDomain from "entities/domain/customers/contact-channel-domain";
import ContactDomain from "entities/domain/customers/contact-domain";
import React from "react";
import { useNavigate } from "react-router-dom";
import { ReactSVG } from "react-svg";

import { useAppSelector } from "redux/hooks";
import InboxService from "services/inbox";
import { getChannelIcon, getChannelIconElement } from "util/constants";
import { ArrowForwardIcon } from "@chakra-ui/icons";

const ContactChannelsColumn = ({ item }: { item: ContactDomain }) => {
  const { colorMode } = useColorMode();
  const { colorScheme } = useAppSelector((state) => state.theme);
  const auth0Context = useAuth0();
  const navigate = useNavigate();
  const { merchant } = useAppSelector((state) => state.merchant);
  const toast = useToast();

  const createConversationWithChannelId = async (chanId: string) => {
    const result = await InboxService.createConversationWithChannelId(
      auth0Context,
      chanId,
      merchant.id
    );

    return result;
  };

  const findConversationWithChannelId = async (chanId: string) => {
    const result = await InboxService.getConversationByChannelId(
      auth0Context,
      chanId,
      merchant.id
    );

    return result;
  };

  const handleCreate = async (chanId: string) => {
    try {
      const existingConversation = await findConversationWithChannelId(chanId);

      if (existingConversation) {
        navigate(`/${merchant.id}/inbox/${existingConversation.id}`);

        return;
      }

      const newConversation = await createConversationWithChannelId(chanId);

      navigate(`/${merchant.id}/inbox/${newConversation.id}`);
    } catch (_e) {
      toast({
        status: "error",
        title: "Error has happened while opening this conversation.",
        description: "Please try doing it from the inbox page.",
      });
    }
  };

  return (
    <Flex justifyContent="start" alignItems="center" gridGap={1}>
      {item.channels
        .filter(
          (chan, index, originalArray) =>
            originalArray.findIndex((c) => c.type === chan.type) === index
        )
        .sort((chan) => (chan.type === "whatsapp" ? -1 : 1))
        .map((chan) => {
          if (!chan.isActive) {
            return null;
          }

          const contactHasMultipleChannelsOfSameType =
            item.channels.filter(
              (channel: CustomerChannelDomain) => channel.type === chan.type
            ).length > 1;

          if (!contactHasMultipleChannelsOfSameType) {
            return (
              <Button
                variant="unstyled"
                size="xs"
                _focus={{ outline: "none" }}
                onClick={() => {
                  handleCreate(chan.id!);
                }}
                _hover={{ opacity: "0.5" }}
                key={chan.id}
                isDisabled={!merchant.isMerchantChannelEnabled(chan.type)}
              >
                <Icon
                  as={ReactSVG}
                  __css={{
                    svg: { height: "1rem", width: "1rem" },
                  }}
                  src={getChannelIcon(chan.type)}
                />
              </Button>
            );
          }

          return (
            <Popover isLazy={true} key={chan.id}>
              <PopoverTrigger>
                <Button
                  variant="unstyled"
                  height="auto"
                  padding={0}
                  size="sm"
                  _focus={{ outline: "none" }}
                  _hover={{ opacity: "0.5" }}
                  isDisabled={!merchant.isMerchantChannelEnabled(chan.type)}
                >
                  <Avatar
                    size="sm"
                    borderRadius={0}
                    p={0}
                    icon={
                      <Icon
                        as={getChannelIconElement(chan.type)}
                        boxSize="1rem"
                        __css={{
                          path: {
                            fill:
                              colorMode === "dark"
                                ? `${colorScheme}.200`
                                : `${colorScheme}.400`,
                          },
                        }}
                      />
                    }
                    bg="transparent"
                  >
                    <AvatarBadge
                      bg={colorMode === "dark" ? "gray.200" : "gray.700"}
                      boxSize="0.75rem"
                      bottom="10%"
                      right="10%"
                      border={0}
                      transform="none"
                      fontSize="2xs"
                    >
                      {
                        item.channels.filter((ch) => ch.type === chan.type)
                          .length
                      }
                    </AvatarBadge>
                  </Avatar>
                </Button>
              </PopoverTrigger>
              <Portal>
                <PopoverContent>
                  <PopoverArrow />
                  <PopoverBody p={0}>
                    <VStack divider={<StackDivider m={0} />} spacing={0}>
                      {item.channels
                        .filter(
                          (ch: CustomerChannelDomain) => ch.type === chan.type
                        )
                        .map(
                          (
                            channelOfSameType: CustomerChannelDomain,
                            index,
                            fullArray
                          ) => (
                            <Button
                              key={channelOfSameType.id}
                              w="100%"
                              colorScheme={colorScheme}
                              variant="unstyled"
                              borderTopRadius={index === 0 ? "md" : 0}
                              borderBottomRadius={
                                index === fullArray.length - 1 ? "md" : 0
                              }
                              onClick={() => {
                                handleCreate(channelOfSameType.id!);
                              }}
                              _hover={{
                                backgroundColor:
                                  colorMode === "dark" ? "gray.900" : "gray.50",
                              }}
                            >
                              {channelOfSameType.handle}
                              <Icon as={ArrowForwardIcon} ml={2} />
                            </Button>
                          )
                        )}
                    </VStack>
                  </PopoverBody>
                </PopoverContent>
              </Portal>
            </Popover>
          );
        })}
    </Flex>
  );
};

export default ContactChannelsColumn;
