import React from "react";
import {
  Flex,
  Step,
  StepIcon,
  StepIndicator,
  StepNumber,
  StepSeparator,
  StepStatus,
  Stepper,
} from "@chakra-ui/react";
import { useAppSelector } from "redux/hooks";
import { ExtendedConnectSteps } from "../../../Integrations/ConnectIntegrationTypes";

interface ProgressStepsProps {
  size: string;
  steps: ExtendedConnectSteps[];
  activeStep: number;
}

const ProgressSteps = ({ size, steps, activeStep }: ProgressStepsProps) => {
  const { colorScheme } = useAppSelector((state) => state.theme);

  return (
    <Flex flexDir="column" width="100%">
      <Stepper index={activeStep} colorScheme={colorScheme}>
        {steps.map((_step, index) => (
          <Step key={index}>
            <StepIndicator>
              <StepStatus
                complete={<StepIcon />}
                incomplete={<StepNumber />}
                active={<StepNumber />}
              />
            </StepIndicator>

            <StepSeparator />
          </Step>
        ))}
      </Stepper>
    </Flex>
  );
};

export default ProgressSteps;
