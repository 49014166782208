import React, { memo } from "react";
import { ReactComponent as EmojiButtonIcon } from "assets/icons/fi-rr-smile.svg";
import data from "@emoji-mart/data";
import Picker from "@emoji-mart/react";
import {
  Button,
  Icon,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Portal,
  useColorMode,
} from "@chakra-ui/react";
import { useAppSelector } from "redux/hooks";

interface EmojiShortcutProps {
  onEmojiClick: (emoji: { native: string }) => void;
}

const EmojiShortcut = ({ onEmojiClick }: EmojiShortcutProps) => {
  const { colorMode } = useColorMode();
  const { colorScheme } = useAppSelector((state) => state.theme);

  return (
    <Popover closeOnBlur={true} placement="top" flip={false}>
      <Portal>
        <PopoverContent>
          <Picker data={data} theme={colorMode} onEmojiSelect={onEmojiClick} />
        </PopoverContent>
      </Portal>

      <PopoverTrigger>
        <Button variant="ghost" size="xs" p={0} colorScheme={colorScheme}>
          <Icon
            as={EmojiButtonIcon}
            width="1rem"
            height="1rem"
            __css={{
              path: {
                fill:
                  colorMode === "light"
                    ? `${colorScheme}.500`
                    : `${colorScheme}.200`,
              },
            }}
          />
        </Button>
      </PopoverTrigger>
    </Popover>
  );
};

export default memo(EmojiShortcut);
