export default class LeadEmailHandlerDomain {
  id: string;

  email: string;

  source: string;

  merchantId: number;

  createdAt: string;

  constructor(
    id: string,
    email: string,
    source: string,
    merchantId: number,
    createdAt: string
  ) {
    this.id = id;
    this.email = email;
    this.source = source;
    this.merchantId = merchantId;
    this.createdAt = createdAt;
  }
}
