import { Box } from "@chakra-ui/react";
import { useAppSelector } from "redux/hooks";
import React, { forwardRef, ForwardRefRenderFunction, memo } from "react";

interface MessageRowProps {
  children: React.ReactNode;
  isFirstUnread: boolean;
  messageId: number;
}

const MessageRow: ForwardRefRenderFunction<HTMLDivElement, MessageRowProps> = (
  { children, isFirstUnread, messageId },
  ref
) => {
  const { colorScheme } = useAppSelector((state) => state.theme);

  return (
    <Box
      width="100%"
      id={`${messageId}`}
      key={messageId}
      ref={ref}
      borderRadius="lg"
      position="relative"
      borderTopStyle="solid"
      borderTopWidth={isFirstUnread ? "3px" : "0"}
      borderTopLeftRadius={isFirstUnread ? "0" : "lg"}
      borderTopRightRadius={isFirstUnread ? "0" : "lg"}
      borderTopColor={`${colorScheme}.400`}
    >
      {children}
    </Box>
  );
};

export default memo(forwardRef(MessageRow));
