export enum AvailabilityStatus {
  AVAILABLE = "available",
  UNAVAILABLE = "unavailable",
  SOLD = "sold",
  RESERVED = "reserved",
  UNKNOWN = "unknown",
}

export enum VehicleCondition {
  NEW = "new",
  USED = "used",
  DEMO = "demo",
}

export default class InventoryVehicleDomain {
  id: string;

  availabilityStatus: AvailabilityStatus | null;

  condition: VehicleCondition | null;

  vin: string | null;

  licensePlate: string | null;

  chassis: string | null;

  engineNumber: string | null;

  vehicleClass: string | null;

  make: string | null;

  model: string | null;

  variant: string | null;

  year: number | null;

  grossPrice: number | null;

  discount: number | null;

  currencyCode: string | null;

  description: string | null;

  interiorColor: string | null;

  exteriorColor: string | null;

  constructor(
    id: string,
    availabilityStatus: AvailabilityStatus | null,
    condition: VehicleCondition | null,
    vin: string | null,
    licensePlate: string | null,
    chassis: string | null,
    engineNumber: string | null,
    vehicleClass: string | null,
    make: string | null,
    model: string | null,
    variant: string | null,
    year: number | null,
    grossPrice: number | null,
    discount: number | null,
    currencyCode: string | null,
    description: string | null,
    interiorColor: string | null,
    exteriorColor: string | null
  ) {
    this.id = id;
    this.availabilityStatus = availabilityStatus;
    this.condition = condition;
    this.vin = vin;
    this.licensePlate = licensePlate;
    this.chassis = chassis;
    this.engineNumber = engineNumber;
    this.vehicleClass = vehicleClass;
    this.make = make;
    this.model = model;
    this.variant = variant;
    this.year = year;
    this.grossPrice = grossPrice;
    this.description = description;
    this.interiorColor = interiorColor;
    this.exteriorColor = exteriorColor;
    this.currencyCode = currencyCode;
    this.discount = discount;
  }
}
