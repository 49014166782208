import React, { ReactNode } from "react";
import { AppState, Auth0Provider } from "@auth0/auth0-react";

const Auth0ProviderWithHistory = ({ children }: { children: ReactNode }) => {
  const domain = process.env.REACT_APP_AUTH0_DOMAIN;
  const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;

  const onRedirectCallback = (appState: AppState | undefined) => {
    window.location.href = appState?.returnTo || window.location.href;
  };

  const skipRedirectCallback = (): boolean =>
    window.location.pathname.includes("/quickbooks") ||
    window.location.pathname.includes("/xero") ||
    window.location.pathname.includes("/reviews/google/callback") ||
    window.location.pathname.includes("/google/gmail/callback") ||
    window.location.pathname.includes("/microsoft/callback") ||
    window.location.pathname.includes("/facebook/callback/reviews") ||
    window.location.pathname.includes("/facebook/callback/messenger") ||
    window.location.pathname.includes("/facebook/callback/instagram");

  return (
    <Auth0Provider
      useRefreshTokens
      cacheLocation="localstorage"
      domain={domain as string}
      clientId={clientId as string}
      authorizationParams={{
        audience: process.env.REACT_APP_AUTH0_AUDIENCE,
        redirect_uri: `${window.location.origin}/inbox`,
        grant_type: "refresh_token",
      }}
      onRedirectCallback={onRedirectCallback}
      skipRedirectCallback={skipRedirectCallback()}
      useRefreshTokensFallback={true}
    >
      {children}
    </Auth0Provider>
  );
};

export default Auth0ProviderWithHistory;
