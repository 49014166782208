export default class TagsDomain {
  id: string;

  tag: string;

  color: string;

  constructor(tag: string, color: string, id: string) {
    this.tag = tag;
    this.color = color;
    this.id = id;
  }
}
