import {
  fetchBanksFromYapily,
  fetchBanksFromYapilyFail,
  fetchBanksFromYapilySuccess,
  fetchPublicPayment,
  fetchPublicPaymentFail,
  fetchPublicPaymentSuccess,
  fetchPublicPaymentUrl,
  fetchPublicPaymentUrlFail,
  fetchPublicPaymentUrlSuccess,
  generatePaymentUrlPayload,
} from "redux/features/publicPayments";
import PublicPaymentLinkDomain from "entities/domain/public/payments/paymentLink";
import { useCallback } from "react";
import PublicPagesService from "services/publicPages";
import { useAppDispatch } from "redux/hooks";

export default function usePublicPaymentsStore() {
  const dispatch = useAppDispatch();

  const fetchPublicPaymentWaterfall = (payment_id: string) => async () => {
    try {
      dispatch(fetchPublicPayment(payment_id));

      const publicPaymentResponse =
        await PublicPagesService.getPublicPaymentRequest(payment_id);

      dispatch(fetchPublicPaymentSuccess(publicPaymentResponse));
    } catch (err) {
      dispatch(fetchPublicPaymentFail(["Oops. Please try again."]));
    }
  };

  const fetchPublicPaymentUrlWaterfall =
    (payload: generatePaymentUrlPayload) =>
    async (): Promise<PublicPaymentLinkDomain | undefined> => {
      try {
        dispatch(fetchPublicPaymentUrl(payload.payment_id));

        const paymentUrlResponse =
          await PublicPagesService.generatePublicPaymentLink(payload);

        dispatch(fetchPublicPaymentUrlSuccess(paymentUrlResponse));
        return paymentUrlResponse;
      } catch (err) {
        dispatch(fetchPublicPaymentUrlFail(["Oops. Please try again."]));
        return undefined;
      }
    };

  const fetchYapilyBanksWaterfall = (country: string) => async () => {
    try {
      dispatch(fetchBanksFromYapily());

      const bankListResponse = await PublicPagesService.getBankListFromYapily(
        country
      );

      dispatch(fetchBanksFromYapilySuccess(bankListResponse));
    } catch (err) {
      dispatch(fetchBanksFromYapilyFail(["Oops. Please try again."]));
    }
  };

  const fetchPublicPaymentAsync = useCallback(
    (payment_id: string) => dispatch(fetchPublicPaymentWaterfall(payment_id)),
    [dispatch]
  );

  const fetchPublicPaymentUrlAsync = useCallback(
    (payload: generatePaymentUrlPayload) =>
      fetchPublicPaymentUrlWaterfall(payload)(),
    [dispatch]
  );

  const fetchYapilyBankListAsync = useCallback(
    (country: string) => dispatch(fetchYapilyBanksWaterfall(country)),
    [dispatch]
  );

  return {
    fetchPublicPayment: fetchPublicPaymentAsync,
    fetchPublicPaymentUrl: fetchPublicPaymentUrlAsync,
    fetchYapilyBankList: fetchYapilyBankListAsync,
  };
}
