import {
  Box,
  useBreakpointValue,
  useColorMode,
  useToast,
} from "@chakra-ui/react";

import FullCalendar from "@fullcalendar/react";
// The import order DOES MATTER here. If you change it, you'll get an error!
import interactionPlugin, { Draggable } from "@fullcalendar/interaction";
import timeGridPlugin from "@fullcalendar/timegrid";
import daygridPlugin from "@fullcalendar/daygrid";
// The import order DOES MATTER ^
import { Color, ColorName } from "theme/old-design-system/styled-components";
import CalendarBookingDomain from "entities/domain/calendar/calendar-domain";
import useCalendarStore from "hooks/use-calendar-store";
import React, { useEffect, useRef, useState } from "react";

import { EventApi, EventChangeArg } from "@fullcalendar/core";
import { updateFormatAccordingToCountry } from "util/methods";
import { format, parseISO, subHours } from "date-fns";
import { UpdateCalendarEventPayload } from "redux/features/calendar";
import { cleanContactsToastMessages } from "redux/features/contacts";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import EditEventModal from "./EditEventModal";
import NewEventModal from "./NewEventModal";
import NotifyGuestModal from "./NotifyGuestModal";
import EventInfoPopover from "./EventInfoPopover";

interface FullCalendarProps {}

export interface AgentOptionTypes {
  value: number;
  label: string;
  color: string;
}

interface EventDetailsEType {
  event: EventApi;
}

interface ExtendedProps {
  booking: CalendarBookingDomain;
  agentId: number | null;
}
interface BookingEvent {
  title: string;
  start: string;
  end: string;
  extendedProps: ExtendedProps;
  backgroundColor: string;
}

const FullCalendarComponent = (_props: FullCalendarProps) => {
  const { fetchEvents, updateEvent } = useCalendarStore();
  const { events, errors, selectedAgentIds } = useAppSelector(
    (state) => state.calendar
  );
  const dispatch = useAppDispatch();
  const { colorMode } = useColorMode();
  const { colorScheme } = useAppSelector((state) => state.theme);
  const { currentAgent, agents } = useAppSelector((state) => state.agents);
  const toast = useToast();

  const { toastMessage: contactToastMessage, contacts } = useAppSelector(
    (state) => state.contacts
  );

  const [currentExtendedProps, setCurrentExtendedProps] =
    useState<ExtendedProps>();

  const [openEventForm, setOpenEventForm] = useState<boolean>(false);
  const [openEditForm, setOpenEditForm] = useState<boolean>(false);
  const [openEventInfo, setOpenEventInfo] = useState<boolean>(false);
  const [showNotifyModal, setShowNotifyModal] = useState<boolean>(false);
  const [newEventStartDate, setNewEventStartDate] = useState<Date | null>(
    new Date()
  );
  const [newEventEndDate, setNewEventEndDate] = useState<Date | null>(
    new Date()
  );
  const [changedEventInfo, setChangedEventInfo] = useState<EventChangeArg>();
  const [highlightColor, setHighlightColor] = useState<ColorName>(
    currentAgent!.color
  );

  const isBookingEventSelectedByAgent = (booking: BookingEvent): boolean => {
    if (selectedAgentIds.length === 0) {
      return true;
    }

    return (
      selectedAgentIds.filter(
        (a: number) => a === booking.extendedProps.agentId
      ).length !== 0
    );
  };

  const isBaseSize = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );

  const transformToEventsPerAgentId = (
    booking: CalendarBookingDomain,
    agentId: number | null
  ): BookingEvent => {
    const customer = contacts.find((c) => c.id === booking.customerId);

    return {
      title: `${booking.formatTitle()}${
        customer ? `, ${customer.getDisplayName()}` : ""
      }`,
      start: booking.startAt,
      end: booking.endAt,
      extendedProps: {
        booking,
        agentId,
      },
      backgroundColor: agentId
        ? agents.find((agent) => agent.id === agentId)!.color.value
        : `var(--chakra-colors-${colorScheme}-400)`,
    };
  };

  const transformToEvents = (
    booking: CalendarBookingDomain
  ): BookingEvent[] => {
    if (booking.agentIds.length === 0) {
      return [transformToEventsPerAgentId(booking, null)];
    }

    return booking.agentIds.map((aId) =>
      transformToEventsPerAgentId(booking, aId)
    );
  };

  const showOpenNewEventForm = (
    startDate: Date | null,
    endDate: Date | null
  ) => {
    setNewEventStartDate(startDate);
    setNewEventEndDate(endDate);
    setOpenEventForm(true);
  };

  const showEventDetails = (e: EventDetailsEType) => {
    if (
      currentExtendedProps?.agentId === e.event.extendedProps.agentId &&
      currentExtendedProps?.booking?.id === e.event.extendedProps.booking.id
    ) {
      setCurrentExtendedProps(undefined);
      setOpenEventInfo(false);
    } else {
      setCurrentExtendedProps(e.event.extendedProps as ExtendedProps);
      setOpenEventInfo(true);
    }
  };

  const changeBookingTime = (
    newBooking: CalendarBookingDomain,
    newStart: Date | null,
    newEnd: Date | null,
    notifyCustomer: boolean
  ) => {
    const payload: UpdateCalendarEventPayload = {
      bookingId: newBooking.id,
      startAt: format(
        newStart || parseISO(newBooking.startAt),
        updateFormatAccordingToCountry("yyyy-MM-dd-HH:mm:ss")
      ),
      endAt: format(
        newEnd || parseISO(newBooking.endAt),
        updateFormatAccordingToCountry("yyyy-MM-dd-HH:mm:ss")
      ),
      tz: Intl.DateTimeFormat().resolvedOptions().timeZone,
      title: newBooking.formatTitle(),
      description: newBooking.description,
      customerId: newBooking.customerId,
      agentIds: newBooking.agentIds,
      notifyCustomer,
    };
    updateEvent(payload);
    if (errors.length) {
      toast({ status: "error", title: errors[0] });
    } else {
      toast({ status: "success", title: "Event updated successfully!" });
    }
    setShowNotifyModal(false);
  };

  const useOutsideAlerterRef = useRef<HTMLDivElement>(null);

  const notifyCustomerSubmit = () => {
    setShowNotifyModal(false);
    changeBookingTime(
      changedEventInfo?.event.extendedProps.booking,
      changedEventInfo?.event.start || null,
      changedEventInfo?.event.end || null,
      true
    );
  };

  const dontNotifyCustomerSubmit = (eventInfo?: EventChangeArg) => {
    setShowNotifyModal(false);
    const event = eventInfo?.event || changedEventInfo?.event;
    changeBookingTime(
      event?.extendedProps.booking,
      event?.start || null,
      event?.end || null,
      false
    );
  };

  // rempove this shit??
  useEffect(() => {
    if (contactToastMessage.new) {
      if (contactToastMessage.success) {
        toast({ status: "success", title: contactToastMessage.success });
      } else if (contactToastMessage.errors) {
        toast({ status: "error", title: contactToastMessage.errors[0] });
      }
      dispatch(cleanContactsToastMessages());
    }
  }, [contactToastMessage]);

  return (
    <Box
      sx={{
        pt: isBaseSize ? 0 : "1rem",
        ".chakra-popover__popper": {
          marginTop: "250px",
          marginLeft: "400px",
        },
        ".fc-toolbar": {
          px: "1rem",
        },
        ".fc-toolbar-title": {
          marginLeft: "0!important",
        },
        ".fc-day-today": {
          backgroundColor: isBaseSize
            ? "transparent!important"
            : `var(--chakra-colors-${colorScheme}-${
                colorMode === "dark" ? "900" : "100"
              })!important`,
        },
        "button.fc-today-button": {
          borderRadius: 9999,
          borderWidth: 0,
          backgroundColor: `var(--chakra-colors-green-${
            colorMode === "dark" ? "200" : "500"
          })!important`,
          color: `${colorMode === "dark" ? "black" : "white"}!important`,
          textTransform: "capitalize",
          fontSize: "sm",
          padding: "0.5rem 1rem",
        },
        "button.fc-prev-button, button.fc-next-button": {
          background: "none !important",
          border: "none",
          ".fc-icon": {
            color: `${colorMode === "dark" ? "white" : "black"}!important`,
          },
        },
        ".fc-next-button": {
          marginLeft: "0!important",
        },
        ".fc-dayGridMonth-button, .fc-timeGridWeek-button, .fc-timeGridDay-button, .fc-timeGridFourDay-button":
          {
            borderRadius: 9999,
            borderWidth: 0,
            backgroundColor: `var(--chakra-colors-gray-${
              colorMode === "dark" ? "300" : "500"
            })!important`,
            color: `${colorMode === "dark" ? "black" : "white"}!important`,
            textTransform: "capitalize",
            fontSize: "sm",
            padding: "0.5rem 1rem",
          },
        ".fc-button-active": {
          color: `${colorMode === "dark" ? "black" : "white"}!important`,
          backgroundColor: `var(--chakra-colors-${colorScheme}-${
            colorMode === "dark" ? "200" : "500"
          })!important`,
        },
        ".fc-toolbar-chunk": {
          display: "flex",
          alignItems: "center",
        },
        "button.fc-prev-button .fc-icon, button.fc-next-button .fc-icon": {
          color: "darkgrey",
        },
        ".fc-scrollgrid": {
          border: "none",
          borderTop: `1px solid ${
            colorMode === "dark" ? "white" : "var(--chakra-colors-gray-200)"
          }`,
          overflow: "hidden",
        },
        "span.fc-scrollgrid-sync-inner": {
          textTransform: "capitalize",
        },
        ".fc-col-header-cell-cushion": {
          fontSize: "sm",
          color: "darkgrey",
          textTransform: "uppercase",
        },
        ".fc .fc-timegrid-slot-minor": {
          border: "none",
        },
        ".fc-timegrid-slot-label-cushion, span.fc-timegrid-axis-cushion": {
          color: "darkgrey",
          fontSize: "sm",
          fontWeight: "bold",
        },
        ".fc-timegrid-event, .fc-daygrid-event": {
          border: "none",
        },
        ".fc-event": {
          fontWeight: "semibold",
        },
        ".fc-highlight": {
          backgroundColor: `${highlightColor.value}!important`,
          opacity: 0.5,
          borderRadius: "0.5rem",
        },
        ".fc-media-screen": {
          height: "100%",
          display: "flex",
        },
        "@media screen and (max-width: 1024px)": {
          ".fc-header-toolbar": {
            flexWrap: "wrap",
            justifyContent: "center",
            gridGap: "1rem",
          },
          ".fc-toolbar-chunk:nth-child(2)": {
            display: "none",
          },
          ".fc .fc-toolbar-title": {
            fontSize: "md!important",
          },
          "button.fc-today-button, .fc-dayGridMonth-button, .fc-timeGridWeek-button, .fc-timeGridDay-button, .fc-timeGridFourDay-button":
            {
              padding: "0.25rem 0.5rem",
              fontSize: "xs",
            },
          ".fc-col-header-cell-cushion, .fc-timegrid-slot-label-cushion, .fc-daygrid-day-number":
            {
              fontSize: "xs",
            },
          ".fc-daygrid-event": {
            fontSize: "xs",
          },
          ".fc-event-title-container": {
            fontSize: "xs",
          },
          ".chakra-popover__popper": {
            marginTop: "250px",
            marginLeft: "55px",
          },
        },
      }}
      h="100%"
      display="flex"
      flexDir="column"
    >
      <Box ref={useOutsideAlerterRef} h="100%" overflowY="auto">
        <Box h="100%">
          {currentExtendedProps && openEventInfo && (
            <EventInfoPopover
              assignedAgentId={currentExtendedProps?.agentId}
              currentEvent={currentExtendedProps?.booking}
              closeEventPopover={() => setOpenEventInfo(false)}
              onEditClick={() => {
                setOpenEditForm(true);
                setOpenEventInfo(false);
              }}
            />
          )}
          {openEventForm && (
            <NewEventModal
              startDate={newEventStartDate}
              endDate={newEventEndDate}
              openEventForm={openEventForm}
              closeNewEventForm={() => setOpenEventForm(false)}
            />
          )}
          {currentExtendedProps && openEditForm && (
            <EditEventModal
              closeNewEventForm={() => {
                setOpenEditForm(false);
                setCurrentExtendedProps(undefined);
                setOpenEventInfo(false);
              }}
              currentEvent={currentExtendedProps?.booking}
            />
          )}
          {showNotifyModal && (
            <NotifyGuestModal
              showNotifyModal={showNotifyModal}
              closeNotifyModal={() => setShowNotifyModal(false)}
              onDontNotifyCustomer={dontNotifyCustomerSubmit}
              onNotifyCustomer={notifyCustomerSubmit}
            />
          )}

          <FullCalendar
            headerToolbar={{
              left: isBaseSize ? "prev title next" : "today prev title next",
              right: isBaseSize
                ? "today timeGridDay timeGridFourDay timeGridWeek dayGridMonth"
                : "timeGridDay timeGridFourDay timeGridWeek dayGridMonth",
            }}
            views={{
              dayGridMonth: {
                dayHeaderFormat: { weekday: "short" },
              },
              timeGridFourDay: {
                type: "timeGrid",
                duration: { days: 7 },
                buttonText: "Weekday",
                weekends: false,
                dateAlignment: "week",
                titleFormat: {
                  year: "numeric",
                  month: "long",
                  day: "numeric",
                },
                slotLabelFormat: {
                  hour: "numeric",
                  minute: "2-digit",
                  meridiem: true,
                },
                dayHeaderFormat: { weekday: "short", day: "numeric" },
              },
              timeGridWeek: {
                titleFormat: {
                  year: "numeric",
                  month: "long",
                  day: "numeric",
                },
                slotLabelFormat: {
                  hour: "numeric",
                  minute: "2-digit",
                  meridiem: true,
                },
                dayHeaderFormat: { day: "numeric", weekday: "short" },
              },
              timeGridDay: {
                dayHeaders: false,
                slotLabelFormat: {
                  hour: "numeric",
                  minute: "2-digit",
                  meridiem: true,
                },
              },
            }}
            contentHeight="100%"
            plugins={[interactionPlugin, timeGridPlugin, daygridPlugin]}
            datesSet={(dateInfo) => {
              fetchEvents({
                startAt: format(
                  dateInfo.start,
                  updateFormatAccordingToCountry("yyyy-MM-dd-HH:mm:ss")
                ),
                endAt: format(
                  dateInfo.end,
                  updateFormatAccordingToCountry("yyyy-MM-dd-HH:mm:ss")
                ),
                timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
              });
              setHighlightColor(
                dateInfo.view.type === "dayGridMonth"
                  ? Color.WHITE
                  : currentAgent!.color
              );
            }}
            initialView={isBaseSize ? "timeGridDay" : "timeGridWeek"}
            nowIndicator={true}
            editable={true}
            select={(e) =>
              !currentExtendedProps
                ? showOpenNewEventForm(e.start, e.end)
                : setCurrentExtendedProps(undefined)
            }
            eventClick={(e) => showEventDetails(e)}
            locale="en"
            selectable={true}
            events={events
              .flatMap(transformToEvents)
              .filter(isBookingEventSelectedByAgent)}
            scrollTime={format(subHours(new Date(), 1), "HH:mm")}
            eventChange={(e) => {
              if (e.oldEvent.extendedProps.booking.notifyChannelSelected) {
                setChangedEventInfo(e);
                setShowNotifyModal(true);
              } else {
                dontNotifyCustomerSubmit(e);
              }
            }}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default FullCalendarComponent;
