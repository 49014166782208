import { Box, Text } from "@chakra-ui/react";
import TemplateAttachment from "components/user-settings/shared/TemplateAttachment";
import React from "react";

interface HeaderPreviewProps {
  file: File | null;
  mediaUrl?: string;
  mediaType?: string;
  headerText: string;
}

const HeaderPreview = ({
  file,
  mediaUrl,
  mediaType,
  headerText,
}: HeaderPreviewProps) => {
  if (!file && !mediaUrl && !mediaType && !headerText) {
    return null;
  }

  return file || !!(mediaUrl && mediaType) ? (
    <Box px={3} width="100%" flexGrow={1}>
      <TemplateAttachment
        mediaUrl={mediaUrl || null}
        mediaType={mediaType || null}
        file={file}
      />
    </Box>
  ) : (
    <Text mx={3} fontSize="xl" fontWeight="extrabold" flexGrow={1}>
      {headerText}
    </Text>
  );
};

export default HeaderPreview;
