import { useAuth0 } from "@auth0/auth0-react";
import {
  createCampaign,
  createCampaignFail,
  CreateCampaignPayload,
  createCampaignSuccess,
  deleteCampaign,
  deleteCampaignFail,
  DeleteCampaignPayload,
  deleteCampaignSuccess,
  editCampaign,
  editCampaignFail,
  EditCampaignPayload,
  editCampaignSuccess,
  PauseCampaignPayload,
  ResumeCampaignPayload,
  CancelCampaignPayload,
  pauseCampaignSuccess,
  pauseCampaignFail,
  resumeCampaignFail,
  resumeCampaignSuccess,
  cancelCampaignSuccess,
  cancelCampaignFail,
  pauseCampaign,
  resumeCampaign,
  cancelCampaign,
} from "redux/features/campaigns";
import CampaignDomain from "entities/domain/campaign";
import { useCallback } from "react";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { RootState } from "redux/store";
import { getErrorDescriptionOrDefault } from "services/errorCodeConverter";
import CampaignsService from "services/campaigns";
import { AxiosError, AxiosResponse } from "axios";
import { CampaignErrorDTO } from "entities/dto/CampaignDTO";

const campaignsSelector = (state: RootState) => state.campaigns;

export default function useCampaignsStore() {
  const dispatch = useAppDispatch();
  const { merchant } = useAppSelector((state) => state.merchant);
  const auth0Context = useAuth0();

  const createCampaignWaterfall =
    (payload: CreateCampaignPayload) =>
    async (): Promise<CampaignDomain | undefined> => {
      try {
        dispatch(createCampaign());

        const campaignResponse = await CampaignsService.createCampaign(
          auth0Context,
          payload,
          merchant.id
        );

        dispatch(createCampaignSuccess());
        return campaignResponse;
      } catch (err: unknown) {
        let errorMessage =
          "Oops. We couldn't create this campaign. Please try again!";

        if (err instanceof AxiosError) {
          const errorResponse = (
            err.response as AxiosResponse<CampaignErrorDTO>
          ).data;

          if (errorResponse.code === "insufficient_limit") {
            errorMessage = `Insufficient limit to send ${errorResponse.intended_increase} messages. ${errorResponse.used}/${errorResponse.limit} have already been used.`;
          }
        }

        throw new Error(errorMessage);
      }
    };

  const editCampaignWaterfall =
    (payload: EditCampaignPayload) =>
    async (): Promise<CampaignDomain | undefined> => {
      try {
        dispatch(editCampaign());

        const campaignResponse = await CampaignsService.editCampaign(
          auth0Context,
          payload,
          merchant.id
        );

        dispatch(editCampaignSuccess(campaignResponse));
        return campaignResponse;
      } catch (err: unknown) {
        let errorMessage =
          "Oops. We couldn't update this campaign. Please try again!";

        if (err instanceof AxiosError) {
          const errorResponse = (
            err.response as AxiosResponse<CampaignErrorDTO>
          ).data;

          if (errorResponse.code === "insufficient_limit") {
            errorMessage = `Insufficient limit to send ${errorResponse.intended_increase} messages. ${errorResponse.used}/${errorResponse.limit} have already been used.`;
          }
        }

        throw new Error(errorMessage);
      }
    };

  const deleteCampaignWaterfall =
    (payload: DeleteCampaignPayload) => async () => {
      try {
        dispatch(deleteCampaign());

        const campaignResponse = await CampaignsService.deleteCampaign(
          auth0Context,
          payload,
          merchant.id
        );

        dispatch(deleteCampaignSuccess(campaignResponse));
      } catch (err: any) {
        const errorMessage = getErrorDescriptionOrDefault(
          err.response?.data?.code,
          "Oops. We couldn't delete this campaign. Please try again!"
        );
        dispatch(deleteCampaignFail([errorMessage]));
      }
    };

  const pauseCampaignWaterfall =
    (payload: PauseCampaignPayload) => async () => {
      try {
        dispatch(pauseCampaign());

        const campaignResponse = await CampaignsService.pauseCampaign(
          auth0Context,
          payload,
          merchant.id
        );

        dispatch(pauseCampaignSuccess(campaignResponse));
      } catch (err: any) {
        const errorMessage = getErrorDescriptionOrDefault(
          err.response?.data?.code,
          "Oops. We couldn't pause this campaign. Please try again!"
        );
        dispatch(pauseCampaignFail([errorMessage]));
      }
    };

  const resumeCampaignWaterfall =
    (payload: ResumeCampaignPayload) => async () => {
      try {
        dispatch(resumeCampaign());

        const campaignResponse = await CampaignsService.resumeCampaign(
          auth0Context,
          payload,
          merchant.id
        );

        dispatch(resumeCampaignSuccess(campaignResponse));
      } catch (err: any) {
        const errorMessage = getErrorDescriptionOrDefault(
          err.response?.data?.code,
          "Oops. We couldn't resume this campaign. Please try again!"
        );
        dispatch(resumeCampaignFail([errorMessage]));
      }
    };

  const cancelCampaignWaterfall =
    (payload: CancelCampaignPayload) => async () => {
      try {
        dispatch(cancelCampaign());

        const campaignResponse = await CampaignsService.cancelCampaign(
          auth0Context,
          payload,
          merchant.id
        );

        dispatch(cancelCampaignSuccess(campaignResponse));
      } catch (err: any) {
        const errorMessage = getErrorDescriptionOrDefault(
          err.response?.data?.code,
          "Oops. We couldn't cancel this campaign. Please try again!"
        );
        dispatch(cancelCampaignFail([errorMessage]));
      }
    };

  const createCampaignAsync = useCallback(
    (payload: CreateCampaignPayload) => createCampaignWaterfall(payload)(),
    [dispatch, merchant]
  );

  const editCampaignAsync = useCallback(
    (payload: EditCampaignPayload) => editCampaignWaterfall(payload)(),
    [dispatch, merchant]
  );

  const deleteCampaignAsync = useCallback(
    (payload: DeleteCampaignPayload) => deleteCampaignWaterfall(payload)(),
    [dispatch, merchant]
  );

  const pauseCampaignAsync = useCallback(
    (payload: PauseCampaignPayload) => pauseCampaignWaterfall(payload)(),
    [dispatch, merchant]
  );

  const resumeCampaignAsync = useCallback(
    (payload: ResumeCampaignPayload) => resumeCampaignWaterfall(payload)(),
    [dispatch, merchant]
  );

  const cancelCampaignAsync = useCallback(
    (payload: CancelCampaignPayload) => cancelCampaignWaterfall(payload)(),
    [dispatch, merchant]
  );

  return {
    createCampaign: createCampaignAsync,
    editCampaign: editCampaignAsync,
    deleteCampaign: deleteCampaignAsync,
    pauseCampaign: pauseCampaignAsync,
    resumeCampaign: resumeCampaignAsync,
    cancelCampaign: cancelCampaignAsync,
  };
}
