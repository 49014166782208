import { DeleteIcon } from "@chakra-ui/icons";
import {
  FormControl,
  FormErrorMessage,
  HStack,
  IconButton,
  Input,
  Stack,
  useBreakpointValue,
  useColorMode,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { isValidPhoneNumber } from "react-phone-number-input";
import {
  WhatsappButton,
  WhatsappButtonType,
} from "entities/domain/whatsapp-template";
import { isValidUrl } from "./utils";

interface ButtonInputProps {
  button: WhatsappButton;
  index: number;
  handleButtonChange: (index: number, field: string, value: string) => void;
  handleRemoveButton: (index: number) => void;
  isDisabled: boolean;
}

const MAX_BUTTON_TEXT_LENGTH = 25;
const MAX_URL_LENGTH = 2000;
const MAX_PHONE_LENGTH = 20;

const ButtonInput = ({
  button,
  index,
  handleButtonChange,
  handleRemoveButton,
  isDisabled,
}: ButtonInputProps) => {
  const isBaseSize = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );
  const { colorMode } = useColorMode();
  const [urlError, setUrlError] = useState<string>("");
  const [phoneNumberError, setPhoneNumberError] = useState<string>("");
  const [textError, setTextError] = useState<string>("");

  useEffect(() => {
    if (button.url && !isValidUrl(button.url)) {
      setUrlError("Invalid URL");
    } else if (!button.url) {
      setUrlError("URL is required");
    } else if (button.url.length > MAX_URL_LENGTH) {
      setUrlError("URL is too long");
    } else {
      setUrlError("");
    }
  }, [button.url]);

  useEffect(() => {
    if (!button.phoneNumber) {
      setPhoneNumberError("Phone number is required");
    } else if (!isValidPhoneNumber(button.phoneNumber)) {
      setPhoneNumberError("Invalid phone number");
    } else {
      setPhoneNumberError("");
    }
  }, [button.phoneNumber]);

  useEffect(() => {
    if (!button.text) {
      setTextError("Text is required");
    } else if (button.text.length > MAX_BUTTON_TEXT_LENGTH) {
      setTextError("Text is too long");
    } else {
      setTextError("");
    }
  }, [button.text]);

  return (
    <HStack key={index} alignItems="start" width="100%">
      <Stack
        direction={isBaseSize ? "column" : "row"}
        width="100%"
        {...(isBaseSize
          ? {
              padding: "0.5rem",
              borderRadius: "1rem",
              borderStyle: "dashed",
              borderWidth: "1px",
              borderColor: colorMode === "dark" ? "gray.500" : "gray.200",
              justifyContent: "space-between",
            }
          : {})}
      >
        <FormControl isInvalid={!!textError}>
          <Input
            className="button-text-input"
            placeholder="Button text (e.g. Learn more)"
            value={button.text}
            onChange={(e) => handleButtonChange(index, "text", e.target.value)}
            maxLength={MAX_BUTTON_TEXT_LENGTH}
            isDisabled={isDisabled}
          />
          <FormErrorMessage>{textError}</FormErrorMessage>
        </FormControl>
        {button.type === WhatsappButtonType.URL ? (
          <FormControl isInvalid={!!urlError}>
            <Input
              className="button-url-input"
              placeholder="https://example.com"
              value={button.url || ""}
              onChange={(e) => handleButtonChange(index, "url", e.target.value)}
              maxLength={MAX_URL_LENGTH}
              isDisabled={isDisabled}
            />
            <FormErrorMessage>{urlError}</FormErrorMessage>
          </FormControl>
        ) : null}
        {button.type === WhatsappButtonType.PHONE_NUMBER ? (
          <FormControl isInvalid={!!phoneNumberError}>
            <Input
              className="button-phone-input"
              placeholder="Phone number"
              value={button.phoneNumber || ""}
              onChange={(e) =>
                handleButtonChange(index, "phoneNumber", e.target.value)
              }
              maxLength={MAX_PHONE_LENGTH}
              isDisabled={isDisabled}
            />
            <FormErrorMessage>{phoneNumberError}</FormErrorMessage>
          </FormControl>
        ) : null}
      </Stack>
      <IconButton
        aria-label="Remove button"
        variant="ghost"
        colorScheme="gray"
        icon={<DeleteIcon />}
        onClick={() => handleRemoveButton(index)}
        isDisabled={isDisabled}
      />
    </HStack>
  );
};

export default ButtonInput;
