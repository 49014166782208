import ReviewReplyOwnerDomain from "./review-reply-owner-domain";

export default class ReviewReplyDomain {
  public owner: ReviewReplyOwnerDomain;

  public content: string;

  public createdAt: string;

  constructor(
    owner: ReviewReplyOwnerDomain,
    content: string,
    createdAt: string
  ) {
    this.owner = owner;
    this.content = content;
    this.createdAt = createdAt;
  }
}
