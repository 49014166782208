import {
  Modal,
  ModalContent,
  ModalOverlay,
  useBreakpointValue,
  useToast,
} from "@chakra-ui/react";
import UploadViaCsvOrIcloud from "components/modals/import-contacts/UploadViaFile";
import AudienceDomain from "entities/domain/audience";
import useAnalytics from "hooks/use-analytics";
import React from "react";
import { ExtendedImportOptions } from "components/modals/import-contacts/ExtendedImportContactsTypes";
import { useAppSelector } from "redux/hooks";
import { ConversationChannel } from "entities/domain/conversations/conversation-domain";

interface UploadAudienceModalProps {
  isOpen: boolean;
  campaignId: string | null;
  defaultChannels: ConversationChannel[];
  setIsOpen: (isOpen: boolean) => void;
  setCampaignAudienceId: (id: string | null) => void;
  handleAudienceSave: (
    callback: (audience: AudienceDomain | undefined) => void,
    audience?: AudienceDomain,
    uploadTagId?: string
  ) => void;
}

const UploadAudienceModal = ({
  campaignId,
  isOpen,
  defaultChannels,
  setCampaignAudienceId,
  handleAudienceSave,
  setIsOpen,
}: UploadAudienceModalProps) => {
  const { track } = useAnalytics();
  const { merchant } = useAppSelector((state) => state.merchant);
  const toast = useToast();
  const isBaseSize = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
      size={isBaseSize ? "full" : "xl"}
    >
      <ModalOverlay />
      <ModalContent>
        <UploadViaCsvOrIcloud
          defaultChannels={defaultChannels}
          afterUploadAsyncCallback={async (
            uploadTagId: string | undefined,
            uploadTag: string | undefined
          ) => {
            track("new_auto_generated_audience_attempt", {
              campaign_id: campaignId,
            });

            if (!uploadTagId || !uploadTag) {
              toast({
                status: "error",
                title: "There was an error uploading this document.",
                description:
                  "Please, try again later or if the problem persists - contact us for support",
              });

              return;
            }

            const freshAudience = new AudienceDomain(
              null,
              AudienceDomain.generateName(),
              merchant.id,
              0,
              null
            );

            freshAudience.addIncludedTagId(uploadTagId);

            handleAudienceSave(
              (createdAudience: AudienceDomain | undefined) => {
                track("new_auto_generated_audience_attempt_succeeded", {
                  campaign_id: campaignId,
                  audience_id: createdAudience?.id || null,
                });

                if (createdAudience && createdAudience.id) {
                  setCampaignAudienceId(createdAudience.id);
                  toast({
                    status: "success",
                    id: createdAudience.id,
                    title: "Audience created successfully!",
                  });
                } else {
                  toast({
                    status: "error",
                    title: "There was an error uploading this document.",
                    description:
                      "Please, try again later or if the problem persists - contact us for support",
                  });
                }

                setIsOpen(false);
              },
              freshAudience,
              uploadTagId
            );
          }}
          uploadMethod={ExtendedImportOptions.CSV}
          onClose={() => {
            setIsOpen(false);
          }}
        />
      </ModalContent>
    </Modal>
  );
};

export default UploadAudienceModal;
