import {
  Button,
  HStack,
  Icon,
  Input,
  VStack,
  useToast,
} from "@chakra-ui/react";
import ProfileAvatar from "components/profile/profile-avatar";
import { Color } from "theme/old-design-system/styled-components";
import { useAppSelector, useAppDispatch } from "redux/hooks";
import React, { ChangeEventHandler, useEffect } from "react";
import { ReactSVG } from "react-svg";

import {
  addFileAttachment,
  addImageAttachment,
  clearAttachments,
} from "redux/features/attachments";
import { maxAllowedSize } from "util/methods";

interface UploadComponentProps {
  profilePicSrc: string;
  name?: string;
  onButtonClick: () => void;
  onRemoveImage?: () => void;
  setProfilePicture: (val: File) => void;
  inputRef: any;
}

const UploadComponent = ({
  profilePicSrc,
  onButtonClick,
  name,
  onRemoveImage,
  setProfilePicture,
  inputRef,
}: UploadComponentProps) => {
  const { colorScheme } = useAppSelector((state) => state.theme);
  const dispatch = useAppDispatch();
  const toast = useToast();

  const reader = new FileReader();

  const onFileChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    dispatch(clearAttachments());

    if (!e.target.files) return;
    const file = e.target.files[0];

    if (!file) {
      toast({ status: "error", title: "No file selected" });
      return;
    }

    if (file.size > maxAllowedSize) {
      dispatch(clearAttachments());
      toast({
        status: "error",
        title: `File size should be less than ${
          maxAllowedSize / 1024 / 1024
        }MB`,
      });
      return;
    }

    dispatch(addFileAttachment(file));

    reader.readAsDataURL(file);

    reader.onload = () => {
      dispatch(addImageAttachment(reader.result as string));
    };

    if (setProfilePicture) setProfilePicture(file);

    reader.onerror = () => {
      toast({ status: "error", title: "Failed to load a file" });
    };
  };

  useEffect(() => {
    return () => {
      dispatch(clearAttachments());
    };
  }, []);

  return (
    <HStack spacing={4}>
      <Input
        colorScheme={colorScheme}
        display="none"
        ref={inputRef}
        type="file"
        multiple={false}
        onChange={onFileChange}
        id="input-file-upload"
        accept={"image/*"}
      />
      <ProfileAvatar profilePicture={profilePicSrc} name={name} />
      <VStack alignItems="flex-start" spacing={0}>
        <Button
          colorScheme={colorScheme}
          variant="unstyled"
          color={`${colorScheme}.400`}
          onClick={onButtonClick}
        >
          <Icon
            mr={1}
            as={ReactSVG}
            src="/upload-csv.svg"
            __css={{
              svg: {
                height: "17px",
                width: "17px",
                path: {
                  fill: `${colorScheme}.400`,
                },
              },
            }}
          />{" "}
          Upload new
        </Button>
        {typeof onRemoveImage === "undefined" ? null : (
          <Button
            colorScheme={colorScheme}
            variant="unstyled"
            color={Color.SMOKY_GREY.value}
            onClick={onRemoveImage}
          >
            <Icon
              mr={1}
              as={ReactSVG}
              src="/trash-can-light.svg"
              __css={{
                svg: {
                  height: "17px",
                  width: "17px",
                  path: {
                    fill: `${Color.SMOKY_GREY.value}`,
                  },
                },
              }}
            />{" "}
            Remove
          </Button>
        )}
      </VStack>
    </HStack>
  );
};

export default UploadComponent;
