import {
  FormControl,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  useBreakpointValue,
} from "@chakra-ui/react";
import {
  BodySize,
  BodyText,
  Button,
  ButtonSize,
  ButtonType,
  Color,
  Heading,
  HeadingSize,
} from "theme/old-design-system/styled-components";
import React from "react";

interface NotifyGuestModalProps {
  showNotifyModal: boolean;
  closeNotifyModal: () => void;
  onNotifyCustomer: () => void;
  onDontNotifyCustomer: () => void;
}

const NotifyGuestModal = ({
  showNotifyModal,
  closeNotifyModal,
  onNotifyCustomer,
  onDontNotifyCustomer,
}: NotifyGuestModalProps) => {
  const isBaseSize = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );

  return (
    <>
      <Modal
        size={isBaseSize ? "full" : "sm"}
        isOpen={showNotifyModal}
        onClose={closeNotifyModal}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalBody pb={6} mt={8}>
            <FormControl pb={4}>
              <Heading size={HeadingSize.THREE} colorName={Color.MIDNIGHT_BLUE}>
                Notify guests?
              </Heading>
            </FormControl>

            <FormControl pb={2}>
              <BodyText size={BodySize.THREE}>
                Send a notification to existing guests.
              </BodyText>
            </FormControl>
          </ModalBody>

          <ModalFooter mx="auto">
            <Button
              type={ButtonType.PRIMARY}
              onClick={onNotifyCustomer}
              mr={15}
              size={ButtonSize.LARGE}
            >
              Send
            </Button>
            <Button
              type={ButtonType.DEFAULT}
              onClick={onDontNotifyCustomer}
              size={ButtonSize.LARGE}
            >
              Dont Send
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default NotifyGuestModal;
