import ReportPerAgentDomain from "entities/domain/report-per-agent";
import { ReportPerAgentDTO } from "entities/dto/ReportPerAgentDTO";

export function reportPerAgentTransformFromDtoToDomain(
  r: ReportPerAgentDTO
): ReportPerAgentDomain {
  return new ReportPerAgentDomain(
    r.agent_id,
    r.unique_conversations,
    r.messages_sent,
    r.avg_time_to_reply_in_seconds
  );
}
