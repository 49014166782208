import { Circle } from "@chakra-ui/react";
import React from "react";

const AlertIcon = () => {
  return (
    <Circle size="1.125rem" bg="red" color="white">
      !
    </Circle>
  );
};

export default AlertIcon;
