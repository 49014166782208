import { useAuth0 } from "@auth0/auth0-react";
import { Icon, Tooltip } from "@chakra-ui/react";
import { ReactComponent as AutoFixIcon } from "assets/icons/auto-fix.svg";
import { ReactComponent as UndoIcon } from "assets/icons/undo.svg";
import ActionButton from "components/chat/shared/messages-area/new-message-input-box/ActionButton";
import MessageDomain from "entities/domain/conversations/message-domain";
import useAnalytics from "hooks/use-analytics";
import useConversationsStore from "hooks/use-conversations-store";
import React, { forwardRef, useEffect, useState } from "react";
import { useAppSelector } from "redux/hooks";
import InboxService from "services/inbox";

interface MagicWandProps {
  lastMessage?: MessageDomain;
  message?: string;
  setMessage: (m: string) => void;
  setIsLoading?: (loading: boolean) => void;
}

const MagicWand = forwardRef<HTMLButtonElement, MagicWandProps>(
  ({ lastMessage, message, setMessage, setIsLoading }: MagicWandProps, ref) => {
    const auth0Context = useAuth0();
    const { merchant } = useAppSelector((state) => state.merchant);
    const { track } = useAnalytics();
    const { activeConversationId } = useAppSelector(
      (state) => state.conversations
    );

    const [cachedMessage, setCachedMessage] = useState<string | undefined>();
    const [cachedImprovedMessage, setCachedImprovedMessage] = useState<
      string | undefined
    >();
    const [showUndoButton, setShowUndoButton] = useState<boolean>(false);
    const [canCreateAutoSuggestion, setCanCreateAutoSuggestion] =
      useState<boolean>(false);

    const generateAutoReply = async () => {
      if (!lastMessage) {
        return;
      }

      if (typeof setIsLoading !== "undefined") {
        setIsLoading(true);
      }

      track("generate_auto_reply_attempt", {
        conversation_id: activeConversationId,
      });

      try {
        const suggestedReply = (
          await InboxService.getAutoReplyMessage(
            auth0Context,
            merchant.id,
            lastMessage.conversationId
          )
        ).suggested_reply;

        setMessage(suggestedReply);

        track("generate_auto_reply_attempt_succeeded", {
          conversation_id: activeConversationId,
        });
      } catch (error) {
        track("generate_auto_reply_attempt_failed", {
          conversation_id: activeConversationId,
        });
      } finally {
        if (typeof setIsLoading !== "undefined") {
          setIsLoading(false);
        }
      }
    };

    const executeAutoFix = async () => {
      if (!message) {
        return;
      }

      if (message === cachedMessage && cachedImprovedMessage) {
        track("get_cached_improved_message", {
          conversation_id: activeConversationId,
        });
        setMessage(cachedImprovedMessage);

        return;
      }

      if (message === cachedImprovedMessage) {
        track("get_original_message_back", {
          conversation_id: activeConversationId,
        });
        setMessage(cachedMessage!);

        return;
      }

      setCachedMessage(message);

      if (typeof setIsLoading !== "undefined") {
        setIsLoading(true);
      }

      let updatedMessage: string = message;

      try {
        track("get_improved_message_attempt", {
          conversation_id: activeConversationId,
        });

        updatedMessage = (
          await InboxService.getAutoFixedMessage(auth0Context, message)
        ).prettified_text;

        track("get_improved_message_attempt_succeeded", {
          conversation_id: activeConversationId,
        });
      } finally {
        if (typeof setIsLoading !== "undefined") {
          setIsLoading(false);
        }
      }

      setCachedImprovedMessage(updatedMessage);
      setMessage(updatedMessage);
    };

    useEffect(() => {
      setShowUndoButton(message === cachedImprovedMessage);
    }, [message, cachedImprovedMessage]);

    useEffect(() => {
      setCanCreateAutoSuggestion(
        Boolean(
          lastMessage &&
            lastMessage.isIncoming &&
            lastMessage.body &&
            !lastMessage.isNotification() &&
            !lastMessage.getAttachmentType() &&
            !message
        )
      );
    }, [lastMessage, message]);

    return showUndoButton ? (
      <ActionButton
        action={() => executeAutoFix()}
        ariaLabel="Undo changes made with AI"
        ref={ref}
      >
        <Tooltip
          label={message ? "Undo changes made by AI" : null}
          borderRadius="1rem"
          px={3}
          py={1}
          backgroundColor="rgba(54, 178, 250, 0.9)"
        >
          <Icon as={UndoIcon} />
        </Tooltip>
      </ActionButton>
    ) : (
      <ActionButton
        ref={ref}
        action={() => {
          if (canCreateAutoSuggestion) {
            generateAutoReply();
          } else {
            executeAutoFix();
          }
        }}
        ariaLabel={
          canCreateAutoSuggestion
            ? "Generate AI reply suggestion"
            : "Improve message with AI"
        }
        isDisabled={!message && !canCreateAutoSuggestion}
      >
        <Tooltip
          label={message ? "Get help constructing a message from AI" : null}
          borderRadius="1rem"
          px={3}
          py={1}
          backgroundColor="rgba(54, 178, 250, 0.9)"
        >
          <Icon as={AutoFixIcon} />
        </Tooltip>
      </ActionButton>
    );
  }
);

export default MagicWand;
