import { Auth0ContextInterface } from "@auth0/auth0-react/src/auth0-context";
import ReviewDomain from "entities/domain/reviews/review-domain";
import { GoogleLocationDTO, ReviewDTO } from "entities/dto/ReviewsDTO";
import {
  fetchReviewsSuccessResponseDTOtransformToReviewsFetchSuccessPayload,
  reviewTransformDtoToDomain,
} from "entities/transformers/reviewTransformer";
import { numberOfReviewsPerLoad } from "util/constants";
import { RequestType } from "./request-type";
import {
  deleteRequest,
  mutationRequest,
  patchRequest,
  putRequest,
  request,
} from "../util/methods";

export interface FetchReviewsSuccessResponseDTO {
  reviews: ReviewDTO[];
  average_rating: number;
  total_count: number;
}

export interface FetchReviewsSuccessPayload {
  reviews: ReviewDomain[];
  averageRating: number;
  totalCount: number;
}

class ReviewsService {
  public static async getReviews(
    { getAccessTokenSilently }: Auth0ContextInterface,
    merchantId: number,
    offset: number
  ): Promise<FetchReviewsSuccessPayload> {
    const accessToken = await getAccessTokenSilently();

    const queryParams: Record<string, string> = {};

    queryParams.offset = `${offset}`;
    queryParams.maxPageSize = `${numberOfReviewsPerLoad}`;

    const queryString = new URLSearchParams(queryParams).toString();

    const reviewsListResponse = await request<FetchReviewsSuccessResponseDTO>(
      RequestType.GET,
      accessToken,
      `/merchants/${merchantId}/reviews?${queryString}`
    );

    return fetchReviewsSuccessResponseDTOtransformToReviewsFetchSuccessPayload(
      reviewsListResponse.data
    );
  }

  public static async deleteReview(
    { getAccessTokenSilently }: Auth0ContextInterface,
    merchantId: number,
    reviewId: string
  ): Promise<void> {
    const accessToken = await getAccessTokenSilently();

    await deleteRequest<FetchReviewsSuccessResponseDTO>(
      RequestType.DELETE,
      accessToken,
      `/merchants/${merchantId}/reviews/${reviewId}`
    );
  }

  public static async getReview(
    { getAccessTokenSilently }: Auth0ContextInterface,
    merchantId: number,
    reviewId: string
  ): Promise<ReviewDomain> {
    const accessToken = await getAccessTokenSilently();

    const reviewsResponse = await request<ReviewDTO>(
      RequestType.GET,
      accessToken,
      `/merchants/${merchantId}/reviews/${reviewId}`
    );

    return reviewTransformDtoToDomain(reviewsResponse.data);
  }

  public static async getReplySuggestion(
    { getAccessTokenSilently }: Auth0ContextInterface,
    merchantId: number,
    reviewId: string
  ): Promise<string> {
    const accessToken = await getAccessTokenSilently();

    const suggestionResponse = (
      await request<{ suggestion: string }>(
        RequestType.GET,
        accessToken,
        `/merchants/${merchantId}/suggestion/review/${reviewId}`
      )
    ).data;

    return suggestionResponse.suggestion;
  }

  public static async createReviewReply(
    { getAccessTokenSilently }: Auth0ContextInterface,
    reviewReplyPayload: {
      reviewId: string;
      content: string;
    },
    merchantId: number
  ): Promise<ReviewDomain> {
    const accessToken = await getAccessTokenSilently();
    const { reviewId, content } = reviewReplyPayload;

    const reviewResponse = await putRequest<ReviewDTO>(
      RequestType.PUT,
      accessToken,
      `/merchants/${merchantId}/reviews/${reviewId}/reply`,
      {
        content,
      }
    );

    return reviewTransformDtoToDomain(reviewResponse.data);
  }

  public static async assignCustomerToReview(
    { getAccessTokenSilently }: Auth0ContextInterface,
    merchantId: number,
    reviewId: string,
    customerId: number
  ): Promise<void> {
    const accessToken = await getAccessTokenSilently();

    await patchRequest(
      RequestType.PATCH,
      accessToken,
      `/merchants/${merchantId}/reviews/${reviewId}`,
      {
        customer_id: customerId,
      }
    );

    return;
  }

  public static async addGoogleReviewsIntegration(
    { getAccessTokenSilently }: Auth0ContextInterface,
    authorization_code: string,
    merchantId: number
  ) {
    const accessToken = await getAccessTokenSilently();

    await mutationRequest<ReviewDTO>(
      RequestType.POST,
      accessToken,
      `/merchants/${merchantId}/google/authorize`,
      {
        authorization_code,
      }
    );

    return undefined;
  }

  public static async selectGoogleLocation(
    { getAccessTokenSilently }: Auth0ContextInterface,
    merchantId: number,
    placeId: string
  ) {
    const accessToken = await getAccessTokenSilently();

    await mutationRequest<ReviewDTO>(
      RequestType.POST,
      accessToken,
      `/merchants/${merchantId}/google/locations`,
      {
        place_id: placeId,
      }
    );

    return undefined;
  }

  public static async addGoogleGmailIntegration(
    { getAccessTokenSilently }: Auth0ContextInterface,
    authorization_code: string,
    merchantId: number
  ) {
    const accessToken = await getAccessTokenSilently();

    await mutationRequest<ReviewDTO>(
      RequestType.POST,
      accessToken,
      `/merchants/${merchantId}/google/authorize/gmail`,
      {
        authorization_code,
      }
    );

    return undefined;
  }

  public static async addMicrosoftOutlookIntegration(
    { getAccessTokenSilently }: Auth0ContextInterface,
    authorization_code: string,
    merchantId: number
  ) {
    const accessToken = await getAccessTokenSilently();

    await mutationRequest<ReviewDTO>(
      RequestType.POST,
      accessToken,
      `/merchants/${merchantId}/microsoft/authorize/outlook`,
      {
        authorization_code,
      }
    );

    return undefined;
  }

  public static async addFacebookIntegration(
    { getAccessTokenSilently }: Auth0ContextInterface,
    authorization_code: string,
    redirect_uri: string
  ) {
    const accessToken = await getAccessTokenSilently();

    await mutationRequest<ReviewDTO>(
      RequestType.POST,
      accessToken,
      `/facebook/authorize/reviews`,
      {
        authorization_code,
        redirect_uri,
      }
    );

    return undefined;
  }

  public static async removeFacebookIntegration({
    getAccessTokenSilently,
  }: Auth0ContextInterface) {
    const accessToken = await getAccessTokenSilently();

    await deleteRequest<ReviewDTO>(
      RequestType.DELETE,
      accessToken,
      `/facebook/authorize/reviews`
    );

    return undefined;
  }

  public static async getGoogleLocations(
    { getAccessTokenSilently }: Auth0ContextInterface,
    merchantId: number
  ): Promise<GoogleLocationDTO[]> {
    const accessToken = await getAccessTokenSilently();

    const googleLocationsResponse = await request<GoogleLocationDTO[]>(
      RequestType.GET,
      accessToken,
      `/merchants/${merchantId}/google/locations`
    );

    return googleLocationsResponse.data;
  }
}

export default ReviewsService;
