import React, { memo } from "react";
import {
  Tag as ChakraTag,
  TagCloseButton,
  TagProps as ChakraTagProps,
  Text,
  Tooltip,
  useBreakpointValue,
  useColorMode,
} from "@chakra-ui/react";

type TagProps = {
  label: string;
  color?: string;
  fontSize?: string;
  fontColor?: string;
  onCloseTag?: () => void;
  displayToolTip?: boolean;
} & ChakraTagProps;

export const MAX_TAG_WIDTH_IN_PIXELS = 96;
export const MAX_TAG_WIDTH_IN_CHAKRA_UNITS = 24;

const Tag = memo(
  ({
    label,
    color,
    fontSize,
    fontColor,
    onCloseTag,
    displayToolTip = true,
    ...rest
  }: TagProps) => {
    const isBaseSize = useBreakpointValue(
      { base: true, md: false },
      { ssr: false }
    );
    const { colorMode } = useColorMode();

    return (
      <Tooltip
        label={label}
        placement="top"
        shouldWrapChildren
        bgColor={colorMode === "dark" ? "gray.600" : "gray.300"}
        fontSize="sm"
        display={displayToolTip ? "initial" : "none"}
        isDisabled={!displayToolTip || isBaseSize}
      >
        <ChakraTag
          fontSize={fontSize}
          variant="outline"
          px={1}
          boxShadow="none"
          border="1px solid"
          colorScheme={color}
          borderColor={color || (colorMode === "dark" ? "gray.50" : "gray.300")}
          color={fontColor || (colorMode === "dark" ? "gray.300" : "gray.600")}
          size="sm"
          maxWidth={24}
          bgColor="transparent"
          {...rest}
          overflow="hidden"
        >
          <Text noOfLines={1}>{label}</Text>

          {onCloseTag && (
            <TagCloseButton onClick={onCloseTag} _focus={{ outline: "none" }} />
          )}
        </ChakraTag>
      </Tooltip>
    );
  }
);

export default Tag;
