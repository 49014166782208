import {
  Button,
  Flex,
  FormHelperText,
  FormLabel,
  Icon,
  Input,
  Text,
  useColorMode,
} from "@chakra-ui/react";
import { ReactComponent as FuzeyBotIcon } from "assets/icons/robot.svg";
import { useAppSelector } from "redux/hooks";
import React, { memo, useState } from "react";

interface MessageInputCoWriterProps {
  askAiForHelp: (prompt: string) => void;
  isDisabled: boolean;
  motive: "template" | "campaign";
}

const HELPER_TEXT = {
  template: "Let Fuzey help you write the perfect template message",
  campaign: "Let Fuzey help you write the perfect marketing message",
};

const PLACEHOLDER_TEXT = {
  template:
    "Help me write a template to promote our VIP event this weekend where we launch a new car",
  campaign:
    "Help me write a SMS campaign to promote our VIP event this weekend where we launch a new car",
};

const MessageInputCoWriter = ({
  askAiForHelp,
  isDisabled,
  motive,
}: MessageInputCoWriterProps) => {
  const { colorMode } = useColorMode();
  const { colorScheme } = useAppSelector((state) => state.theme);
  const [aiPrompt, setAiPrompt] = useState<string>("");

  return (
    <>
      <FormLabel
        fontWeight={700}
        display="flex"
        alignItems="center"
        gridGap={2}
      >
        <Icon
          as={FuzeyBotIcon}
          __css={{
            path: {
              fill: colorMode === "dark" ? "gray.50" : "gray.900",
            },
          }}
        />
        <Text>AI Co-writer</Text>
      </FormLabel>
      <FormHelperText mt={0} mb={4}>
        {HELPER_TEXT[motive]}
      </FormHelperText>
      <Flex alignItems="center">
        <Input
          colorScheme={colorScheme}
          variant="flushed"
          placeholder={PLACEHOLDER_TEXT[motive]}
          value={aiPrompt}
          onChange={(e) => {
            setAiPrompt(e.target.value);
          }}
        />
        <Button
          variant="outline"
          colorScheme={colorScheme}
          size="sm"
          onClick={() => {
            askAiForHelp(aiPrompt);
            setAiPrompt("");
          }}
          isDisabled={isDisabled || !aiPrompt}
        >
          Ask
        </Button>
      </Flex>
    </>
  );
};

export default memo(MessageInputCoWriter);
