export default class KeyloopUser {
  id: string;

  name: string | null;

  email: string | null;

  jobTitle: string | null;

  constructor(
    id: string,
    name: string | null,
    email: string | null,
    jobTitle: string | null
  ) {
    this.id = id;
    this.name = name;
    this.email = email;
    this.jobTitle = jobTitle;
  }
}
