import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import NotificationDomain from "entities/domain/notifications/domain";

interface NotificationsState {
  notifications: NotificationDomain[];
  loading: boolean;
  errors: any[];
}

const initialState: NotificationsState = {
  notifications: [],
  loading: true,
  errors: [],
};

const notificationsSlice = createSlice({
  name: "notifications",
  initialState,
  reducers: {
    fetchNotifications(state) {
      state.loading = true;
    },
    fetchNotificationsSuccess(
      state,
      action: PayloadAction<NotificationDomain[]>
    ) {
      state.loading = false;
      state.notifications = action.payload;
      state.errors = [];
    },
    fetchNotificationsFail(state, action: PayloadAction<string[]>) {
      state.loading = false;
      state.errors = action.payload;
    },
    setAsRead(state, action: PayloadAction<string>) {
      return {
        ...state,
        notifications: state.notifications.map((notification) =>
          notification.id === action.payload
            ? { ...notification, read: true }
            : notification
        ),
      };
    },
    setAllAsRead(state) {
      return {
        ...state,
        notifications: state.notifications.map((notification) => ({
          ...notification,
          read: true,
        })),
      };
    },
    appendNotification(state, action: PayloadAction<NotificationDomain>) {
      if (state.notifications.find((n) => n.id === action.payload.id)) {
        return state;
      }

      return {
        ...state,
        notifications: [action.payload, ...state.notifications],
      };
    },
    resetStore(state) {
      state.notifications = [];
      state.loading = true;
      state.errors = [];
    },
  },
});

export const {
  fetchNotifications,
  fetchNotificationsFail,
  fetchNotificationsSuccess,
  setAsRead,
  setAllAsRead,
  appendNotification,
  resetStore,
} = notificationsSlice.actions;

export default notificationsSlice.reducer;
