import { StyleConfig } from "@chakra-ui/theme-tools";

const ButtonStyles = {
  baseStyle: {
    borderRadius: "full",
    textTransform: "uppercase",
    fontWeight: "semibold",
  },
  sizes: {
    xs: {
      fontSize: "xs",
      textTransform: "capitalize",
    },
    sm: {
      fontSize: "sm",
      textTransform: "capitalize",
      px: 4,
      py: 3,
    },
    md: {
      fontSize: "md",
      px: 6,
      py: 4,
    },
    lg: {
      fontSize: "lg",
      px: 8,
      py: 5,
    },
  },
  variants: {
    solid: ({ colorMode, colorScheme }) => ({
      borderRadius: "full",
      bgColor:
        colorMode === "dark" ? `${colorScheme}.200` : `${colorScheme}.500`,
      color: colorMode === "dark" ? "gray.800" : "white",
      _hover: {
        bgColor:
          colorMode === "dark" ? `${colorScheme}.400` : `${colorScheme}.400`,
        color: colorMode === "dark" ? "gray.800" : "white",
      },
      _active: {
        bgColor:
          colorMode === "dark" ? `${colorScheme}.500` : `${colorScheme}.300`,
        color: colorMode === "dark" ? "gray.800" : "white",
      },
    }),
    outline: ({ colorMode, colorScheme }) => ({
      bgColor: "transparent",
      color: colorMode === "dark" ? `${colorScheme}.200` : `${colorScheme}.500`,
      _hover: {
        bgColor:
          colorMode === "dark" ? `${colorScheme}.900` : `${colorScheme}.50`,
        color:
          colorMode === "dark" ? `${colorScheme}.400` : `${colorScheme}.400`,
      },
      _active: {
        bgColor: colorMode === "dark" ? `whiteAlpha.50` : `blackAlpha.50`,
        color:
          colorMode === "dark" ? `${colorScheme}.500` : `${colorScheme}.300`,
      },
    }),
  },
  defaultProps: {
    size: "sm",
    variant: "solid",
    colorScheme: "blue",
  },
} as StyleConfig;

export default ButtonStyles;
