import { Button } from "@chakra-ui/button";
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/modal";
import React, { useCallback, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import Select, { SingleValue } from "react-select";
import { AbsoluteCenter, Box, Divider, VStack } from "@chakra-ui/layout";
import { FormControl, FormLabel } from "@chakra-ui/form-control";
import { getReactSelectStyles } from "util/methods";
import { useColorMode } from "@chakra-ui/color-mode";
import AgentsService from "services/agents";
import { useAuth0 } from "@auth0/auth0-react";
import { ChevronDownIcon, ChevronUpIcon } from "@chakra-ui/icons";
import {
  addTeam,
  clearSelectedAgents,
  propagateTeamUpdate,
  setTeams,
} from "redux/features/agents";
import { batch } from "react-redux";
import { useBreakpointValue } from "@chakra-ui/media-query";
import { useToast } from "@chakra-ui/toast";
import TeamForm from "./TeamForm";

interface AddToTeamModalProps {
  isOpen: boolean;
  onClose: () => void;
}

interface TeamSelectOption {
  value: string;
  label: string;
}

const AddToTeamModal = ({ isOpen, onClose }: AddToTeamModalProps) => {
  const isBaseSize = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );
  const auth0Context = useAuth0();
  const { colorMode } = useColorMode();
  const { colorScheme } = useAppSelector((state) => state.theme);
  const { teams, selectedAgentIds } = useAppSelector((state) => state.agents);
  const { merchant } = useAppSelector((state) => state.merchant);
  const [newTeamName, setNewTeamName] = useState<string>("");
  const [newTeamPurpose, setNewTeamPurpose] = useState<string>("");
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [teamOptions, setTeamOptions] = useState<TeamSelectOption[]>(
    teams.map((team) => ({
      label: team.name,
      value: team.id,
    }))
  );

  useEffect(() => {
    setTeamOptions(
      teams.map((team) => ({
        label: team.name,
        value: team.id,
      }))
    );
  }, [teams]);

  const [selectedTeamValue, setSelectedTeamValue] =
    useState<SingleValue<TeamSelectOption>>(null);
  const toast = useToast();
  const [isCreatingNewTeam, setIsCreatingNewTeam] = useState<boolean>(false);

  const setNewTeamNameMemoized = useCallback(setNewTeamName, []);
  const setNewTeamPurposeMemoized = useCallback(setNewTeamPurpose, []);

  const updateTeam = async () => {
    setIsLoading(true);

    const selectedTeam = teams.find(
      (team) => team.id === selectedTeamValue?.value
    );

    if (!selectedTeam) {
      toast({
        title: "Please select a team and then try again!",
        status: "error",
      });

      return;
    }

    try {
      const updatedTeam = await AgentsService.updateTeam(auth0Context, {
        merchant_id: merchant.id,
        id: selectedTeam.id,
        agent_ids: [
          ...new Set([...selectedTeam.agentIds, ...selectedAgentIds]),
        ],
      });

      batch(() => {
        dispatch(propagateTeamUpdate(updatedTeam));
        dispatch(clearSelectedAgents());
      });
      onClose();
      toast({
        title: "Team updated successfully!",
        status: "success",
      });
    } catch (_e: unknown) {
      toast({
        title: "Error updating team, try again later or contact support!",
        status: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const createTeam = async () => {
    setIsLoading(true);

    try {
      const newTeam = await AgentsService.createTeam(auth0Context, {
        name: newTeamName,
        purpose: newTeamPurpose,
        merchant_id: merchant.id,
        agent_ids: selectedAgentIds,
      });

      batch(() => {
        dispatch(addTeam(newTeam));
        dispatch(clearSelectedAgents());
      });
      onClose();
      toast({
        title: "Team created successfully!",
        status: "success",
      });
    } catch (_e: unknown) {
      toast({
        title: "Error creating team, try again later or contact support!",
        status: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (teams.length === 0) {
      setIsCreatingNewTeam(true);
    } else if (!newTeamName && !newTeamPurpose) {
      setIsCreatingNewTeam(false);
    }
  }, [teams]);

  useEffect(() => {
    setSelectedTeamValue(null);
    setNewTeamName("");
    setNewTeamPurpose("");
  }, [isOpen]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} size={isBaseSize ? "full" : "md"}>
      <ModalOverlay />
      <ModalContent
        containerProps={{
          style: {
            zIndex: 9999,
          },
        }}
      >
        <ModalHeader>
          {isCreatingNewTeam ? "Create a new team" : "Add to a team"}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack>
            {isCreatingNewTeam ? null : (
              <FormControl>
                <FormLabel>Select a team</FormLabel>
                <Select
                  placeholder="Select team"
                  onChange={(newTeam) => setSelectedTeamValue(newTeam)}
                  isClearable={true}
                  options={teamOptions}
                  value={selectedTeamValue}
                  styles={getReactSelectStyles(colorMode, colorScheme)}
                />
              </FormControl>
            )}
            {teams.length === 0 ? null : (
              <Box
                position="relative"
                py={isCreatingNewTeam ? 4 : 8}
                px={2}
                w="100%"
              >
                <Divider />
                <AbsoluteCenter
                  bg={colorMode === "dark" ? "gray.700" : "white"}
                  px={4}
                >
                  <Button
                    variant="ghost"
                    colorScheme="gray"
                    rightIcon={
                      isCreatingNewTeam ? (
                        <ChevronUpIcon />
                      ) : (
                        <ChevronDownIcon />
                      )
                    }
                    onClick={() => {
                      const newIsCreatingNewTeam = !isCreatingNewTeam;

                      if (newIsCreatingNewTeam) {
                        setSelectedTeamValue(null);
                      } else {
                        setNewTeamName("");
                        setNewTeamPurpose("");
                      }

                      setIsCreatingNewTeam(!isCreatingNewTeam);
                    }}
                  >
                    {isCreatingNewTeam
                      ? "or add to existing one"
                      : "or create a new one"}
                  </Button>
                </AbsoluteCenter>
              </Box>
            )}
            {isCreatingNewTeam ? (
              <TeamForm
                teamName={newTeamName}
                setTeamName={setNewTeamNameMemoized}
                teamPurpose={newTeamPurpose}
                setTeamPurpose={setNewTeamPurposeMemoized}
                isLoading={isLoading}
              />
            ) : null}
          </VStack>
        </ModalBody>
        <ModalFooter>
          {!isCreatingNewTeam && selectedTeamValue ? (
            <Button
              onClick={updateTeam}
              colorScheme={colorScheme}
              isDisabled={isLoading}
            >
              Add
            </Button>
          ) : null}
          {isCreatingNewTeam && newTeamName && newTeamPurpose ? (
            <Button
              onClick={createTeam}
              colorScheme={colorScheme}
              isDisabled={isLoading}
            >
              Create Team
            </Button>
          ) : null}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default AddToTeamModal;
