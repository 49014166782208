import { Box, Button, Flex, Icon, Link } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useAppSelector } from "redux/hooks";
import { DownloadIcon } from "@chakra-ui/icons";
import { ConversationChannel } from "entities/domain/conversations/conversation-domain";
import AudienceDomain from "entities/domain/audience";
import UploadAudienceModal from "./UploadAudienceModal";

interface UploadAudienceTabProps {
  isSavingAudience: boolean;
  campaignId: string | null;
  setCampaignAudienceId: (aid: string | null) => void;
  handleAudienceSave: (
    callback: (audience: AudienceDomain | undefined) => void,
    audience?: AudienceDomain,
    uploadTagId?: string
  ) => void;
}

const UploadAudienceTab = ({
  isSavingAudience,
  campaignId,
  setCampaignAudienceId,
  handleAudienceSave,
}: UploadAudienceTabProps) => {
  const { colorScheme } = useAppSelector((state) => state.theme);
  const [isUploadModalOpen, setIsUploadModalOpen] = useState<boolean>(false);
  const { merchant } = useAppSelector((state) => state.merchant);
  const [defaultChannels, setDefaultChannels] = useState<ConversationChannel[]>(
    [ConversationChannel.SMS]
  );

  useEffect(() => {
    if (merchant.isMerchantChannelEnabled(ConversationChannel.WHATSAPP)) {
      setDefaultChannels([
        ConversationChannel.SMS,
        ConversationChannel.WHATSAPP,
      ]);
    } else {
      setDefaultChannels([ConversationChannel.SMS]);
    }
  }, [merchant]);

  return (
    <Box mt={12}>
      <Flex
        direction="column"
        gridGap={4}
        justifyContent="center"
        alignItems="center"
      >
        <Button
          colorScheme={colorScheme}
          variant="outline"
          p={10}
          mx="auto"
          color={`${colorScheme}.400`}
          display="flex"
          alignItems="center"
          justifyContent="center"
          gridGap={4}
          isDisabled={isSavingAudience}
          onClick={() => {
            setIsUploadModalOpen(true);
          }}
        >
          Start Uploading
        </Button>
        <Link
          href="https://staging.app.fuzey.io/sample-CSV.csv"
          target="_blank"
          fontSize="sm"
          download
          fontWeight={700}
        >
          <Icon as={DownloadIcon} /> Download our .csv sample
        </Link>
      </Flex>
      <UploadAudienceModal
        campaignId={campaignId}
        defaultChannels={defaultChannels}
        setCampaignAudienceId={setCampaignAudienceId}
        isOpen={isUploadModalOpen}
        setIsOpen={setIsUploadModalOpen}
        handleAudienceSave={handleAudienceSave}
      />
    </Box>
  );
};

export default UploadAudienceTab;
