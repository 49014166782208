import {
  HStack,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Text,
  useBreakpointValue,
  useColorMode,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { useAppSelector } from "redux/hooks";

interface Tier {
  description: string;
  name: string;
  isActive: boolean;
}

interface TiersBarProps {
  tiers: Tier[];
}

const TiersBar = ({ tiers }: TiersBarProps) => {
  const isBaseSize = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );
  const { colorScheme } = useAppSelector((state) => state.theme);
  const { colorMode } = useColorMode();
  const [isActiveTierOpen, setIsActiveTierOpen] = useState<boolean>(true);

  return (
    <HStack
      mt={isBaseSize ? 2 : 4}
      spacing={0}
      width="100%"
      borderRadius="full"
      bgGradient={`linear(to-r, yellow.${
        colorMode === "dark" ? "200" : "500"
      }, green.${colorMode === "dark" ? "200" : "500"})`}
    >
      {tiers.map((tier, index) => (
        <Popover
          closeOnBlur={false}
          closeOnEsc={false}
          defaultIsOpen={tier.isActive}
          key={tier.name}
          trigger={tier.isActive ? "click" : "hover"}
          placement={tier.isActive ? "bottom" : "top"}
          arrowShadowColor={
            colorMode === "dark" ? `${colorScheme}.200` : `${colorScheme}.500`
          }
          {...(tier.isActive
            ? {
                isOpen: isActiveTierOpen,
                onClose: () => setIsActiveTierOpen(false),
                onOpen: () => setIsActiveTierOpen(true),
              }
            : {})}
        >
          <PopoverTrigger>
            <HStack
              cursor="pointer"
              w="100%"
              mr={index !== tiers.length - 1 ? "-2px" : 0}
              justifyContent="center"
              borderColor={
                tier.isActive
                  ? colorMode === "dark"
                    ? `${colorScheme}.400`
                    : `${colorScheme}.500`
                  : "transparent"
              }
              borderWidth="2px"
              borderLeftRadius={index === 0 ? "full" : 0}
              borderRightRadius={index === tiers.length - 1 ? "full" : 0}
              _hover={{
                borderColor:
                  colorMode === "dark"
                    ? `${colorScheme}.400`
                    : `${colorScheme}.500`,
                borderWidth: "2px",
                borderStyle: "solid",
              }}
            >
              <Text
                fontSize="xs"
                color={colorMode === "dark" ? "black" : "white"}
              >
                {tier.name}
              </Text>
            </HStack>
          </PopoverTrigger>
          <PopoverContent
            borderRadius="xl"
            bg={
              colorMode === "dark" ? `${colorScheme}.200` : `${colorScheme}.500`
            }
            border="none"
          >
            <PopoverArrow
              bg={
                colorMode === "dark"
                  ? `${colorScheme}.200`
                  : `${colorScheme}.500`
              }
            />
            <PopoverBody color={colorMode === "dark" ? "black" : "white"}>
              {tier.description}
            </PopoverBody>
          </PopoverContent>
        </Popover>
      ))}
    </HStack>
  );
};

export default TiersBar;
