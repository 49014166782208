import {
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  VStack,
} from "@chakra-ui/react";
import KeyloopAccount from "entities/domain/keyloop-account";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "redux/hooks";
import { ArrowBackIcon } from "@chakra-ui/icons";
import SelectAccount from "./SelectAccount";
import EditAccount from "./EditAccount";

interface KeyloopProps {}

const Keyloop = (_props: KeyloopProps) => {
  const merchant = useAppSelector((state) => state.merchant.merchant);
  const navigate = useNavigate();
  const [selectedKeyloopAccount, setSelectedKeyloopAccount] =
    React.useState<KeyloopAccount | null>(null);

  return (
    <Modal
      isOpen={true}
      closeOnEsc={true}
      closeOnOverlayClick={true}
      size="2xl"
      onClose={() => {
        navigate(`/${merchant.id}/settings/integrations`);
      }}
    >
      <ModalOverlay />
      <ModalContent>
        {selectedKeyloopAccount ? (
          <IconButton
            position="absolute"
            left={2}
            top={2}
            isRound={true}
            variant="ghost"
            aria-label="Back"
            colorScheme="gray"
            icon={<ArrowBackIcon />}
            onClick={() => setSelectedKeyloopAccount(null)}
          />
        ) : null}
        <ModalHeader textAlign="center">
          {selectedKeyloopAccount ? "Edit Account" : "Select Account"}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack spacing={2} w="100%" mb={4}>
            {selectedKeyloopAccount ? (
              <EditAccount selectedKeyloopAccount={selectedKeyloopAccount} />
            ) : (
              <SelectAccount
                setSelectedKeyloopAccount={setSelectedKeyloopAccount}
              />
            )}
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default Keyloop;
