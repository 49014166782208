/* eslint-disable import/prefer-default-export */
import AutomationDomain, {
  TimeOperation,
  TimeUnit,
} from "entities/domain/automation";
import { AutomationDTO } from "entities/dto/AutomationDTO";

export const automationTransformFromDtoToDomain = (
  a: AutomationDTO
): AutomationDomain =>
  new AutomationDomain(
    a.id,
    a.name,
    a.description,
    a.merchant_id,
    a.trigger_type,
    a.time as {
      operation: TimeOperation;
      unit: TimeUnit;
      amount: number;
    },
    a.frequency as {
      unit: TimeUnit;
      amount: number;
    },
    a.task,
    a.payload.template_id,
    a.enabled
  );
