import { MessageButtonDTO } from "./ConversationDTO";

export interface TemplateDTO {
  id: string;
  name: string;
  title: string;
  merchant_id: number;
  text: string;
  custom_fields: { [key: string]: string } | undefined;
  favourite: boolean;
  shortcut: string | null;
  channels: string[];
  subject: string | null;
  type: string;
  editable: boolean;
  category: string | null;
  media_url: string | null;
  media_type: string | null;
  buttons: MessageButtonDTO[] | null;
}

export interface FullTemplateDto {
  id: string;
  name: string;
  title: string;
  text: string;
  merchant_id: number;
  type: string;
  channels: string[];
  favourite: boolean;
  shortcut: string | null;
  subject: string | null;
  whatsapp_template_status: string | null;
  custom_fields: { [key: string]: string } | undefined;
  editable: boolean;
  category: string | null;
  media_url: string | null;
  media_type: string | null;
}

export function isFullTemplateDTO(obj: any): obj is FullTemplateDto {
  return (
    obj &&
    typeof obj.id === "string" &&
    typeof obj.name === "string" &&
    typeof obj.title === "string" &&
    typeof obj.text === "string" &&
    typeof obj.merchant_id === "number" &&
    typeof obj.type === "string" &&
    Array.isArray(obj.channels) &&
    typeof obj.favourite === "boolean" &&
    (obj.shortcut === null || typeof obj.shortcut === "string") &&
    (obj.subject === null || typeof obj.subject === "string") &&
    (obj.whatsapp_template_status === null ||
      typeof obj.whatsapp_template_status === "string") &&
    (obj.custom_fields === undefined ||
      typeof obj.custom_fields === "object") &&
    typeof obj.editable === "boolean" &&
    (obj.category === null || typeof obj.category === "string") &&
    (obj.media_url === null || typeof obj.media_url === "string") &&
    (obj.media_type === null || typeof obj.media_type === "string")
  );
}
