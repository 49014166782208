import {
  Modal,
  Flex,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Button,
  Icon,
  useBreakpointValue,
  useColorMode,
  Avatar,
  useToast,
  FormControl,
  FormLabel,
} from "@chakra-ui/react";
import { ReactComponent as AssignAgentIcon } from "assets/icons/personal-conversations.svg";
import {
  Heading,
  HeadingSize,
} from "theme/old-design-system/styled-components";
import AgentDomain from "entities/domain/agents/agent-domain";
import { ConversationChannel } from "entities/domain/conversations/conversation-domain";
import useAnalytics from "hooks/use-analytics";
import useConversationsStore from "hooks/use-conversations-store";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import React, { useEffect, useState, memo } from "react";
import Select, { components, OptionProps, SingleValue } from "react-select";
import { getReactSelectStyles } from "util/methods";
import ProfileAvatar from "components/profile/profile-avatar";
import { ReactComponent as AccountRemove } from "assets/icons/account-remove.svg";
import {
  clearSelectedConversations,
  startLoadingBulkActionsToolbar,
  stopLoadingBulkActionsToolbar,
} from "redux/features/conversations";
import { batch } from "react-redux";

import InboxService from "services/inbox";
import { useAuth0 } from "@auth0/auth0-react";

interface AssignAgentModalProps {
  isOpen: boolean;
  onClose: () => void;
  assignedAgentId: number | null | undefined;
  assignedTeamId: string | null | undefined;
  conversationId: number | undefined;
  customerId: number | undefined;
  conversationChannel: ConversationChannel | undefined;
}

interface AgentSelectOption {
  value: number | null;
  label: string;
}

interface TeamSelectOption {
  value: string | null;
  label: string;
}

const AssignAgentModal = memo(
  ({
    onClose,
    isOpen,
    assignedAgentId,
    assignedTeamId,
    conversationId,
    conversationChannel,
    customerId,
  }: AssignAgentModalProps) => {
    const dispatch = useAppDispatch();
    const auth0Context = useAuth0();
    const [agentId, setAgentId] = useState<number | null | undefined>(
      undefined
    );
    const { colorScheme } = useAppSelector((state) => state.theme);
    const toast = useToast();
    const { selectedConversationIds } = useAppSelector(
      (state) => state.conversations
    );
    const { colorMode } = useColorMode();
    const { assignAgent } = useConversationsStore();
    const { merchant } = useAppSelector((state) => state.merchant);
    const { agents, currentAgent, teams } = useAppSelector(
      (state) => state.agents
    );
    const { track } = useAnalytics();

    const isBaseSize = useBreakpointValue(
      { base: true, md: false },
      { ssr: false }
    );

    const [isBulkAssigning, setIsBulkAssigning] = useState<boolean>(false);

    useEffect(() => {
      setAgentId(assignedAgentId);
      setSelectedAgentValue(
        agentOptions.find(({ value }) => value === assignedAgentId) || null
      );
    }, [assignedAgentId]);

    const [agentOptions, setAgentOptions] = useState<AgentSelectOption[]>([
      {
        label: "Unassign",
        value: null,
      },
      ...agents.map((a) => ({
        label: a.getFullName(),
        value: a.id,
      })),
    ]);

    const [newTeamId, setNewTeamId] = useState<string | null | undefined>(
      undefined
    );

    useEffect(() => {
      const newAgentOptions = [
        {
          label: "Unassign",
          value: null,
        },
        ...agents
          .filter((a) => {
            if (newTeamId === null) {
              return true;
            }

            const team = teams.find((t) => t.id === newTeamId);

            if (!team) {
              return true;
            }

            return team.agentIds.includes(a.id);
          })
          .map((a) => ({
            label: a.getFullName(),
            value: a.id,
          })),
      ];

      setAgentOptions(newAgentOptions);

      if (
        selectedAgentValue &&
        !newAgentOptions.map((o) => o.value).includes(selectedAgentValue.value)
      ) {
        setSelectedAgentValue(null);
      }
    }, [agents, newTeamId]);

    const [selectedAgentValue, setSelectedAgentValue] = useState<
      SingleValue<AgentSelectOption>
    >(agentOptions.find(({ value }) => value === agentId) || null);

    const handleAgentChange = (e: SingleValue<AgentSelectOption>) => {
      const newAgentId = e?.value && Number(e.value);

      setAgentId(newAgentId);
      setSelectedAgentValue(e);
    };

    useEffect(() => {
      setNewTeamId(assignedTeamId);
      setSelectedTeamValue(
        teamOptions.find(({ value }) => value === assignedTeamId) || null
      );
    }, [assignedTeamId]);

    const [teamOptions, setTeamOptions] = useState<TeamSelectOption[]>([
      {
        label: "Unassign",
        value: null,
      },
      ...teams.map((team) => ({
        label: team.name,
        value: team.id,
      })),
    ]);
    const [selectedTeamValue, setSelectedTeamValue] =
      useState<SingleValue<TeamSelectOption>>(null);

    useEffect(() => {
      const newTeamOptions = [
        {
          label: "Unassign",
          value: null,
        },
        ...teams.map((team) => ({
          label: team.name,
          value: team.id,
        })),
      ];

      if (selectedTeamValue) {
        const selectedTeamStillExists = newTeamOptions.find(
          (team) => team.value === selectedTeamValue.value
        );

        if (!selectedTeamStillExists) {
          setSelectedTeamValue(null);
        }
      }

      setTeamOptions(newTeamOptions);
    }, [teams]);

    const assignAndClose = async () => {
      if (!conversationId) {
        if (selectedConversationIds.length) {
          setIsBulkAssigning(true);
          dispatch(startLoadingBulkActionsToolbar());

          try {
            const updatedConversationIds =
              await InboxService.bulkAssignConversations(
                auth0Context,
                merchant.id,
                selectedConversationIds,
                agentId,
                newTeamId
              );

            if (updatedConversationIds.length === 0) {
              toast({
                status: "error",
                title: "Failed to perform bulk action. Please try again later.",
              });

              return;
            }

            if (
              updatedConversationIds.length === selectedConversationIds.length
            ) {
              dispatch(clearSelectedConversations());
            }
          } catch (_error: unknown) {
            toast({
              status: "error",
              title: "Failed to perform bulk action. Please try again later.",
            });
          } finally {
            dispatch(stopLoadingBulkActionsToolbar());
            setIsBulkAssigning(false);
            onClose();
          }
        }

        return;
      }

      await assignAgent(
        { conversationId, agentId, teamId: newTeamId },
        currentAgent!.id
      );
      onClose();
    };

    const AgentOption = ({
      children,
      ...props
    }: OptionProps<AgentSelectOption, false>) => {
      const {
        data: { value: optionAgentId },
      } = props;

      const optionAgent = agents.find(
        (a: AgentDomain) => a.id === optionAgentId
      );

      return (
        <components.Option {...props}>
          <Flex justifyContent="start" alignItems="center" gridGap={4}>
            {optionAgent ? (
              <ProfileAvatar
                profilePicture={optionAgent.getPicture()}
                name={optionAgent.getFullName()}
              />
            ) : (
              <Avatar
                key="unassign-agent"
                size="sm"
                bgColor={
                  colorMode === "dark"
                    ? `${colorScheme}.500`
                    : `${colorScheme}.50`
                }
                icon={
                  <Icon
                    as={AccountRemove}
                    fill={colorMode === "dark" ? "white" : "gray.800"}
                    boxSize="1.5rem"
                  />
                }
              />
            )}
            {children}
          </Flex>
        </components.Option>
      );
    };

    return (
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        size={isBaseSize ? "full" : "xl"}
      >
        <ModalOverlay />
        <ModalContent
          containerProps={{
            zIndex: 999999,
          }}
        >
          <ModalCloseButton _focus={{ outline: "none" }} />

          <Flex alignItems="center" padding="28px 40px">
            <Icon as={AssignAgentIcon} height="22.4px" marginRight="12px" />
            <Heading size={HeadingSize.FOUR}>Assign to</Heading>
          </Flex>

          <ModalBody>
            <FormControl>
              <FormLabel>Team</FormLabel>
              <Select
                placeholder="Select Team"
                onChange={(newTeam) => {
                  setNewTeamId(newTeam?.value);
                  setSelectedTeamValue(newTeam);
                }}
                isClearable={true}
                options={teamOptions}
                value={selectedTeamValue}
                styles={{
                  ...{
                    ...getReactSelectStyles(colorMode, colorScheme),
                    container: (provided: any) => ({
                      ...provided,
                      width: isBaseSize ? "100%" : "auto",
                    }),
                  },
                }}
              />
            </FormControl>
            <FormControl mt={4}>
              <FormLabel>Agent</FormLabel>
              <Select
                placeholder="Select an agent"
                isClearable={true}
                onChange={handleAgentChange}
                options={agentOptions}
                value={selectedAgentValue}
                styles={getReactSelectStyles(colorMode, colorScheme)}
                components={{ Option: AgentOption }}
              />
            </FormControl>
            <Button
              colorScheme={colorScheme}
              isDisabled={
                !(
                  (conversationId || selectedConversationIds.length > 0) &&
                  (selectedAgentValue !== null || selectedTeamValue !== null)
                ) || isBulkAssigning
              }
              onClick={() => {
                track("assign_agent_attempt_succeeded", {
                  assigned_agent_id: agentId,
                  assigned_team_id: newTeamId,
                  conversation_id: conversationId || null,
                  customer_id: customerId,
                  channel: conversationChannel,
                });
                assignAndClose();
              }}
              width="100%"
              mt={8}
              mb={4}
              size="md"
            >
              Assign
            </Button>
          </ModalBody>
        </ModalContent>
      </Modal>
    );
  }
);

export default AssignAgentModal;
