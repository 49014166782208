import PermissionDomain from "entities/domain/authorization/permission";
import RoleDomain from "entities/domain/authorization/role";
import { PermissionDTO } from "entities/dto/PermissionDTO";
import { RoleDTO } from "entities/dto/RoleDTO";

export const permissionTransformFromDtoToDomain = (
  permissionDto: PermissionDTO
): PermissionDomain => {
  return new PermissionDomain(
    permissionDto.id,
    permissionDto.name,
    permissionDto.description,
    permissionDto.group
  );
};

export const roleTransformFromDtoToDomain = (roleDto: RoleDTO): RoleDomain => {
  return new RoleDomain(
    roleDto.id,
    roleDto.name,
    roleDto.description,
    roleDto?.permissions?.map((permission) =>
      permissionTransformFromDtoToDomain(permission)
    )
  );
};
