import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Icon as ChakraIcon,
  Input,
  Text,
  useColorMode,
  useToast,
  IconButton,
} from "@chakra-ui/react";
import { useAppSelector, useAppDispatch } from "redux/hooks";
import React, {
  ChangeEventHandler,
  ReactNode,
  useEffect,
  useRef,
  useState,
} from "react";
import { ReactSVG } from "react-svg";

import {
  addFileAttachment,
  addImageAttachment,
  clearAttachments,
} from "redux/features/attachments";
import { CloseIcon } from "@chakra-ui/icons";

interface FileDragAndDropProps {
  accept: string;
  errorMessage?: string;
  onSubmit?: (files: File[]) => Promise<string | null>;
  onClose: () => void;
  setIsLoading: (val: boolean) => void;
  setFile?: (file: File | null) => void;
  extendedAction?: ReactNode;
  useStorage?: boolean;
  showClearButton?: boolean;
  isCompact?: boolean;
}

const reader = new FileReader();

const FileDragAndDrop = ({
  onClose,
  setIsLoading,
  onSubmit,
  useStorage = true,
  showClearButton = false,
  accept,
  errorMessage = "Upload failed",
  setFile,
  extendedAction,
  isCompact = false,
}: FileDragAndDropProps) => {
  const { colorScheme } = useAppSelector((state) => state.theme);
  const { colorMode } = useColorMode();
  const [dragActive, setDragActive] = useState(false);
  const inputRef = useRef<any>(null);
  const toast = useToast();
  const dispatch = useAppDispatch();

  const { files } = useAppSelector((state) => state.attachments);
  const [localFile, setLocalFile] = useState<File | null>(null);

  useEffect(() => {
    if (typeof setFile === "undefined") {
      return;
    }

    setFile(localFile);
  }, [localFile, setFile]);

  useEffect(() => {
    return () => {
      dispatch(clearAttachments());
      setLocalFile(null);
    };
  }, []);

  const handleDrag = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  const handleDrop = (e: any) => {
    if (useStorage) {
      dispatch(clearAttachments());
    }

    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);

    const file = e.dataTransfer.files[0];

    if (e.dataTransfer.files && file) {
      if (useStorage) {
        dispatch(addFileAttachment(file));
      }
      reader.readAsDataURL(file);
      reader.onload = () => {
        if (useStorage) {
          dispatch(addImageAttachment(reader.result as string));
        }
      };
      setLocalFile(file);
    }

    reader.onerror = () => {
      toast({ status: "error", title: errorMessage });
    };
  };

  const onFileChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    if (useStorage) {
      dispatch(clearAttachments());
    }

    if (!e.target.files) return;
    const file = e.target.files[0];

    if (!file) {
      toast({ status: "error", title: "No file selected" });
      return;
    }

    if (useStorage) {
      dispatch(addFileAttachment(file));
    }

    reader.readAsDataURL(file);

    reader.onload = () => {
      if (useStorage) {
        dispatch(addImageAttachment(reader.result as string));
      }
    };

    setLocalFile(file);

    reader.onerror = () => {
      toast({ status: "error", title: errorMessage });
    };
  };

  const onButtonClick = () => {
    inputRef.current.click();
  };

  const defaultPaddingTop = isCompact ? "1rem" : files[0] ? "50px" : "64px";

  const handleSubmit = async () => {
    try {
      setIsLoading(true);

      const successMessage = await onSubmit!(
        (useStorage && files) || (!useStorage && localFile && [localFile]) || []
      );

      if (successMessage) {
        toast({ status: "success", title: successMessage });
      }

      setIsLoading(false);
      onClose();
      dispatch(clearAttachments());
    } catch (err) {
      toast({ status: "error", title: errorMessage });

      setIsLoading(false);
      dispatch(clearAttachments());
    }
  };

  return (
    <FormControl
      {...(isCompact
        ? {}
        : {
            height: "273px",
            width: "473px",
          })}
      bgColor={colorMode === "dark" ? "gray.900" : "gray.50"}
      borderRadius="md"
      onDragEnter={handleDrag}
      mx="auto"
      onSubmit={(e) => e.preventDefault()}
      id="form-file-upload"
    >
      <Flex
        direction="column"
        alignContent="center"
        justify="center"
        textAlign="center"
        alignItems="center"
        pt={defaultPaddingTop}
        pb={isCompact ? "1rem" : 0}
      >
        {((useStorage && files[0]) || (!useStorage && localFile)) &&
        onSubmit ? (
          <>
            {showClearButton && (files[0] || localFile) ? (
              <IconButton
                aria-label="Clear file"
                icon={<CloseIcon />}
                colorScheme="blackAlpha"
                mr="-8rem"
                mb="-1rem"
                onClick={() => {
                  if (useStorage) {
                    dispatch(clearAttachments());
                  }
                  setLocalFile(null);
                }}
              />
            ) : null}
            <ChakraIcon
              as={ReactSVG}
              src="/address-card-light.svg"
              __css={{
                height: "80px",
                width: "80px",
              }}
            />
            <Text mx={3}>{(files[0] || localFile).name}</Text>
            <Button
              colorScheme="gray"
              mt={5}
              size="sm"
              borderRadius="65px"
              onClick={handleSubmit}
              _focus={{ border: "none" }}
            >
              {" "}
              Submit
            </Button>
          </>
        ) : (
          <>
            {isCompact ? null : !(files[0] || localFile)?.name ? (
              <>
                <Heading size="md" mb="14px">
                  {" "}
                  Drop file here{" "}
                </Heading>
                <Heading size="md" mb="14px">
                  {" "}
                  or{" "}
                </Heading>
              </>
            ) : (
              <Text mx={3}>{(files[0] || localFile).name}</Text>
            )}
            <Button
              colorScheme={colorScheme}
              size="md"
              borderRadius="65px"
              onClick={onButtonClick}
            >
              {" "}
              Browse
            </Button>
          </>
        )}
      </Flex>

      <Input
        colorScheme={colorScheme}
        display="none"
        ref={inputRef}
        type="file"
        multiple={false}
        onChange={onFileChange}
        id="input-file-upload"
        isDisabled={
          ((useStorage && !!files[0]) || (!useStorage && !!localFile)) &&
          !!onSubmit
        }
        accept={accept}
      />
      <FormLabel
        htmlFor="input-file-upload"
        height="10%"
        width="100%"
        className={dragActive ? "drag-active" : ""}
      />

      {dragActive && (
        <Box
          position="absolute"
          width="100%"
          height="100%"
          top="0px"
          right=" 0px"
          bottom=" 0px"
          left="0px"
          id="drag-file-element"
          onDragEnter={handleDrag}
          onDragLeave={handleDrag}
          onDragOver={handleDrag}
          onDrop={handleDrop}
        />
      )}
    </FormControl>
  );
};

export default FileDragAndDrop;
