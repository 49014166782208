import {
  Button,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";
import { useAppSelector } from "redux/hooks";
import React, { useEffect, useRef } from "react";

interface ConfirmationDialogProps {
  headerText?: string;
  messageText: string;
  buttonText: string;
  cancelButtonText?: string;
  isOpen: boolean;
  isDangerous?: boolean;
  setIsOpen: (isOpen: boolean) => void;
  confirmationCallback: () => void;
}

const ConfirmationDialog = ({
  headerText,
  messageText,
  buttonText,
  cancelButtonText,
  isOpen,
  isDangerous = false,
  setIsOpen,
  confirmationCallback,
}: ConfirmationDialogProps) => {
  const isBaseSize = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );
  const { colorScheme } = useAppSelector((state) => state.theme);
  const wasClicked = useRef<boolean>(false);

  // once confirmation dialog is opened again, reset wasClicked
  useEffect(() => {
    if (isOpen) {
      wasClicked.current = false;
    }
  }, [isOpen]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
      size={isBaseSize ? "full" : "xl"}
      isCentered
    >
      <ModalOverlay />
      <ModalContent mx={isBaseSize ? "0" : "2rem"}>
        <ModalHeader>{headerText}</ModalHeader>
        <ModalCloseButton />
        <ModalBody textAlign="left">
          <Text>{messageText}</Text>
        </ModalBody>
        <ModalFooter
          display="flex"
          justifyContent={cancelButtonText ? "space-between" : "end"}
          gridGap={8}
          alignItems="center"
        >
          {cancelButtonText && (
            <Button
              colorScheme={colorScheme}
              variant="outline"
              onClick={() => {
                setIsOpen(false);
              }}
            >
              {cancelButtonText}
            </Button>
          )}
          <Button
            colorScheme={isDangerous ? "red" : colorScheme}
            type="submit"
            onClick={() => {
              if (wasClicked.current) {
                return;
              }

              wasClicked.current = true;
              setIsOpen(false);
              confirmationCallback();
            }}
          >
            {buttonText}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ConfirmationDialog;
