import {
  Alert,
  AlertDescription,
  AlertIcon,
  Code,
  AlertTitle,
  Button,
  List,
  ListItem,
} from "@chakra-ui/react";
import CampaignDomain, { CampaignStatus } from "entities/domain/campaign";
import React, { useState } from "react";

interface CampaignIdeasProps {
  campaignStatus: CampaignStatus;
  isShown: boolean;
  useIdea: (idea: string) => void;
}

const CampaignIdeas = ({
  isShown,
  useIdea,
  campaignStatus,
}: CampaignIdeasProps) => {
  if (!isShown || campaignStatus === CampaignStatus.DONE) {
    return null;
  }

  const [campaignIdeas, setCampaignIdeas] = useState<string[]>([
    "Hey {contact_first_name}, how can I help you?",
    "What is up {contact_first_name}?",
  ]);

  return (
    <Alert
      status="info"
      mb={4}
      borderRadius="lg"
      variant="subtle"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      textAlign="center"
      height="200px"
    >
      <AlertIcon />
      <AlertTitle mt={4} mb={1} fontSize="2xl">
        Do not know where to start?
      </AlertTitle>
      <AlertDescription maxWidth="sm">
        Try using one of these AI suggestions:
        <List spacing={2} mt={2}>
          {campaignIdeas.map((idea) => (
            <ListItem display="flex" gridGap={4} alignItems="center">
              <Button
                variant="outline"
                size="sm"
                colorScheme="yellow"
                flexShrink={0}
                flexGrow={0}
                flexBasis="auto"
                onClick={() => {
                  useIdea(idea);
                }}
              >
                Use 👉
              </Button>
              <Code borderRadius="lg" textAlign="left" colorScheme="yellow">
                {idea}
              </Code>
            </ListItem>
          ))}
        </List>
      </AlertDescription>
    </Alert>
  );
};

export default CampaignIdeas;
