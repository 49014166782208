import { Text } from "@chakra-ui/react";
import { PreviewComponentProps } from "components/shared/QueryBuilder/QueryCondition";
import React from "react";
import { useAppSelector } from "redux/hooks";

const ChannelFieldPreview = ({ value }: PreviewComponentProps) => {
  const { merchant } = useAppSelector((state) => state.merchant);

  const channel = merchant.channels
    .find((ch) => ch.id === value)
    ?.getDisplayChannelName();

  return <Text fontWeight="bold">{channel || value}</Text>;
};

export default ChannelFieldPreview;
