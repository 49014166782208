import React from "react";
import { Alert, AlertIcon, AlertTitle } from "@chakra-ui/react";

interface UnexpectedErrorProps {
  message: string;
}

export default function UnexpectedError({ message }: UnexpectedErrorProps) {
  return (
    <Alert status="error">
      <AlertIcon />
      <AlertTitle>{message}</AlertTitle>
    </Alert>
  );
}
