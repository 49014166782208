import {
  Box,
  IconButton,
  Menu,
  MenuButton,
  MenuList,
  useColorMode,
} from "@chakra-ui/react";
import React, { ReactNode } from "react";
import { useAppSelector } from "redux/hooks";

interface FooterNestedNavLinksProps {
  children: ReactNode; // MobileNavButton
  hasNotifications?: boolean;
  mainIcon: any;
  isActive: boolean;
}

const FooterNestedNavLinks = ({
  children,
  mainIcon,
  isActive,
  hasNotifications = false,
}: FooterNestedNavLinksProps) => {
  const { colorScheme } = useAppSelector((state) => state.theme);
  const { colorMode } = useColorMode();

  return (
    <Menu>
      <MenuButton
        as={IconButton}
        variant="unstyled"
        sx={{
          svg: {
            path: {
              fill: isActive ? `${colorScheme}.400` : "white",
            },
          },
        }}
        icon={mainIcon}
      >
        {hasNotifications ? (
          <Box
            position="absolute"
            top="-0.25rem"
            right="-0.25rem"
            zIndex="1"
            borderRadius="full"
            height="1rem"
            width="1rem"
            bgColor={colorMode === "dark" ? "red.200" : "red.500"}
          />
        ) : null}
      </MenuButton>
      <MenuList zIndex={999999} opacity={1} bgColor="white">
        {children}
      </MenuList>
    </Menu>
  );
};

export default FooterNestedNavLinks;
