/* eslint-disable import/prefer-default-export */
import TemplateDomain from "entities/domain/templates";
import FullTemplateDomain, {
  TemplateCategory,
  TemplateType,
  WhatsAppTemplateStatuses,
} from "entities/domain/templates/full_template";
import { FullTemplateDto, TemplateDTO } from "entities/dto/TemplateDTO";
import { messageButtonTransformFromDtoToDomain } from "./conversationTransformers";

export const templateTransformFromFullTemplateDomainToDto = (
  t: FullTemplateDomain
): FullTemplateDto => ({
  id: t.id,
  merchant_id: t.merchantId,
  name: t.name,
  title: t.title,
  text: t.text,
  favourite: t.favourite,
  shortcut: t.shortcut,
  channels: t.channels,
  subject: t.subject,
  type: t.type === TemplateType.CUSTOM ? "custom" : "fuzey",
  whatsapp_template_status: t.whatsappTemplateStatus,
  custom_fields: t.customFields,
  editable: t.isEditable,
  category: t.category,
  media_url: t.mediaUrl,
  media_type: t.mediaType,
});

export const templateTransformFromFullDtoToDomain = (
  t: FullTemplateDto
): FullTemplateDomain =>
  new FullTemplateDomain(
    t.id,
    t.merchant_id,
    t.name,
    t.title,
    t.text,
    t.favourite,
    t.shortcut,
    t.channels,
    t.subject,
    t.type === "custom" ? TemplateType.CUSTOM : TemplateType.FUZEY,
    t.whatsapp_template_status as WhatsAppTemplateStatuses,
    t.custom_fields || {},
    t.editable,
    t.category as TemplateCategory | null,
    t.media_url || null,
    t.media_type || null
  );

export const templateTransformFromDtoToDomain = (
  t: TemplateDTO
): TemplateDomain =>
  new TemplateDomain(
    t.id,
    t.name,
    t.title,
    t.text,
    t.custom_fields || {},
    t.favourite,
    t.shortcut,
    t.channels,
    t.subject,
    t.editable,
    t.category as TemplateCategory | null,
    t.media_url || null,
    t.media_type || null,
    t.buttons?.map(messageButtonTransformFromDtoToDomain) || []
  );
