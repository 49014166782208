import { CheckIcon } from "@chakra-ui/icons";
import {
  Badge,
  Box,
  Flex,
  IconButton,
  Text,
  Tooltip,
  useColorMode,
} from "@chakra-ui/react";
import { format, isSameWeek, isToday, isYesterday, parseISO } from "date-fns";
import NotificationDomain from "entities/domain/notifications/domain";
import useNotificationsStore from "hooks/use-notifications-store";
import React from "react";
import { useAppSelector } from "redux/hooks";

interface NotificationItemProps {
  notification: NotificationDomain;
}

export default function NotificationItem({
  notification,
}: NotificationItemProps) {
  const { colorMode } = useColorMode();
  const { colorScheme } = useAppSelector((state) => state.theme);
  const { markNotificationAsRead } = useNotificationsStore();

  const getDisplayDate = (n: NotificationDomain): string => {
    const createdDate = parseISO(
      n.createdAt.includes("Z") ? n.createdAt : `${n.createdAt}Z`
    );

    if (isToday(createdDate)) {
      return format(createdDate, "'Today at' h:mm a");
    } else if (isYesterday(createdDate)) {
      return format(createdDate, "'Yesterday at' h:mm a");
    } else if (isSameWeek(createdDate, new Date())) {
      return format(createdDate, "EEEE 'at' h:mm a"); // Day of the week at time
    } else {
      return format(createdDate, "dd/MM/yyyy 'at' HH:mm");
    }
  };

  const handleBoxClick = () => {
    if (notification.deepLink) {
      markNotificationAsRead(notification.id);
      window.location.href = notification.deepLink;
    }
  };

  return (
    <Box
      key={notification.id}
      p={4}
      mb={4}
      borderRadius="md"
      border="1px solid"
      borderColor={colorMode === "dark" ? "gray.600" : "gray.300"}
      bg={
        colorMode === "dark"
          ? notification.read
            ? "gray.800"
            : "gray.700"
          : notification.read
          ? "gray.100"
          : "gray.50"
      }
      _hover={{ bg: colorMode === "dark" ? "gray.600" : "gray.200" }}
      boxShadow="sm"
      onClick={handleBoxClick}
      cursor={notification.deepLink ? "pointer" : "default"}
      position="relative"
    >
      <Flex justifyContent="space-between" alignItems="center">
        <Box flex="1" mr={4}>
          <Flex alignItems="center" mb={2}>
            <Text fontWeight={notification.read ? "normal" : "bold"}>
              {notification.title}
            </Text>
          </Flex>
          <Text>{notification.message}</Text>
          <Text fontSize="xs" color="gray.500" fontStyle="italic">
            {getDisplayDate(notification)}
          </Text>
        </Box>
        <Flex alignItems="center">
          {!notification.read && (
            <Badge
              colorScheme={colorScheme}
              borderRadius="full"
              px={2}
              py={1}
              mr={2}
            >
              NEW
            </Badge>
          )}
          {!notification.read && (
            <Tooltip label="Mark as Read" aria-label="Mark as Read">
              <IconButton
                icon={<CheckIcon />}
                size="sm"
                colorScheme="green"
                onClick={(e) => {
                  e.stopPropagation();
                  markNotificationAsRead(notification.id);
                }}
                aria-label="Mark as Read"
                borderRadius="full"
                bg="transparent"
                _hover={{ bg: colorMode === "dark" ? "gray.500" : "gray.300" }}
              />
            </Tooltip>
          )}
        </Flex>
      </Flex>
    </Box>
  );
}
