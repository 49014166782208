import { WorkingHoursDayDomain } from "entities/domain/working-hours";
import { WorkingHoursDTO } from "entities/dto/WorkingHoursDTO";

export const workingHoursTransformFromDtoToHoursDayDomain = (
  data: WorkingHoursDTO
): Array<WorkingHoursDayDomain | null> => {
  return [
    data.sunday
      ? new WorkingHoursDayDomain(data.sunday.start_time, data.sunday.end_time)
      : null,
    data.monday
      ? new WorkingHoursDayDomain(data.monday.start_time, data.monday.end_time)
      : null,
    data.tuesday
      ? new WorkingHoursDayDomain(
          data.tuesday.start_time,
          data.tuesday.end_time
        )
      : null,
    data.wednesday
      ? new WorkingHoursDayDomain(
          data.wednesday.start_time,
          data.wednesday.end_time
        )
      : null,
    data.thursday
      ? new WorkingHoursDayDomain(
          data.thursday.start_time,
          data.thursday.end_time
        )
      : null,
    data.friday
      ? new WorkingHoursDayDomain(data.friday.start_time, data.friday.end_time)
      : null,
    data.saturday
      ? new WorkingHoursDayDomain(
          data.saturday.start_time,
          data.saturday.end_time
        )
      : null,
  ];
};
