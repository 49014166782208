import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  VStack,
  useBreakpointValue,
} from "@chakra-ui/react";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import React, { useEffect, useState } from "react";
import { AgentMerchantDomain } from "entities/domain/agents/new-agent-domain";
import DesktopSwitchButton from "components/merchant-switch/desktop-button";
import { setIsSelectorOpened } from "redux/features/merchant";

interface SwitchAccountProps {
  merchants: AgentMerchantDomain[];
  isRequired?: boolean;
}

const SwitchAccount = ({
  isRequired = false,
  merchants,
}: SwitchAccountProps) => {
  const dispatch = useAppDispatch();
  const { unreadCounts } = useAppSelector((state) => state.conversations);
  const { merchant: currentMerchant, isSelectorOpened } = useAppSelector(
    (state) => state.merchant
  );
  const isBaseSize = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );
  const { currentAgent } = useAppSelector((state) => state.agents);

  const [unreadCountByMerchant, setUnreadCountByMerchant] = useState<{
    [key: number]: number;
  }>({});

  useEffect(() => {
    const newUnreadCountByMerchant = unreadCounts.reduce(
      (acc, uc) => ({
        ...acc,
        [uc.merchant_id]: uc.shared,
      }),
      {}
    );

    setUnreadCountByMerchant(newUnreadCountByMerchant);
  }, [unreadCounts]);

  if (!currentAgent || !currentMerchant) {
    return null;
  }

  return (
    <Modal
      {...(isRequired
        ? {
            closeOnEsc: false,
            closeOnOverlayClick: false,
          }
        : {})}
      isOpen={isSelectorOpened}
      size={isBaseSize ? "full" : "md"}
      onClose={() => {
        dispatch(setIsSelectorOpened(false));
      }}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader textAlign="center">Select Account</ModalHeader>
        {isRequired ? null : <ModalCloseButton />}
        <ModalBody>
          <VStack w={isBaseSize ? "100%" : "80%"} mx="auto" mb={4}>
            {merchants
              .sort(AgentMerchantDomain.sortMerchantsAlphabetically)
              .map((merchant, index) => (
                <DesktopSwitchButton
                  key={merchant.id}
                  merchantOption={merchant}
                  index={index}
                  size="md"
                  unreadCount={unreadCountByMerchant[merchant.id]}
                />
              ))}
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default SwitchAccount;
