import { parseISO } from "date-fns";
import KeyloopRepairOrder from "entities/domain/keyloop-repair-order";
import { KeyloopRepairOrderDTO } from "entities/dto/KeyloopRepairOrderDTO";

export const keyloopRepairOrderTransformFromDtoToDomain = (
  order: KeyloopRepairOrderDTO
): KeyloopRepairOrder =>
  new KeyloopRepairOrder(
    order.id,
    order.reference,
    order.status,
    order.type,
    {
      name: order.customer.name,
      email: order.customer.email,
      phone: order.customer.phone,
    },
    {
      dueInDateTime: parseISO(order.appointment.due_in_date_time),
      dueOutDateTime: parseISO(order.appointment.due_out_date_time),
    },
    {
      checkInDateTime: order.details.check_in_date_time,
      checkInMileage: {
        unit: order.details.check_in_mileage.unit,
        value: order.details.check_in_mileage.value,
      },
      checkOutDateTime: order.details.check_out_date_time,
      checkOutMileage: {
        unit: order.details.check_out_mileage.unit,
        value: order.details.check_out_mileage.value,
      },
      closedDateTime: order.details.closed_date_time,
      completedDateTime: order.details.completed_date_time,
      serviceTypes: order.details.service_types,
    },
    {
      id: order.advisor.id,
      name: order.advisor.name,
      userId: order.advisor.user_id,
    },
    {
      vin: order.vehicle.vin,
      licensePlate: order.vehicle.license_plate,
      description: order.vehicle.description,
    }
  );
