export default class AttachmentDomain {
  id: string;

  messageId: number;

  conversationId: number;

  url: string;

  type: string;

  createdAt: string;

  constructor(
    id: string,
    messageId: number,
    conversationId: number,
    url: string,
    type: string,
    createdAt: string
  ) {
    this.id = id;
    this.messageId = messageId;
    this.conversationId = conversationId;
    this.url = url;
    this.type = type;
    this.createdAt = createdAt;
  }
}
