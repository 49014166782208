import { Flex, Icon, Input, Text, useColorMode } from "@chakra-ui/react";
import { ReactComponent as EditIcon } from "assets/icons/edit-ds.svg";
import React, { ChangeEventHandler, memo, useEffect, useState } from "react";
import ConfirmationDialog from "components/shared/ConfirmationDialog";
import { Template } from "./RichTextArea";

interface SubjectLineProps {
  handleTextChange: ChangeEventHandler<HTMLInputElement>;
  text: string;
  template: Template | null;
  isDisabled: boolean;
}

const SubjectLine = ({
  text,
  handleTextChange,
  template,
  isDisabled,
}: SubjectLineProps) => {
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [isEditingSubject, setIsEditingSubject] = useState(false);
  const { colorMode } = useColorMode();

  useEffect(() => {
    const subject = template?.subject || "";

    handleTextChange({ target: { value: subject } } as any);
  }, [template]);

  return (
    <>
      <Flex
        position="relative"
        mb={2}
        borderBottomWidth="1px"
        borderBottomStyle="solid"
        borderBottomColor={colorMode === "dark" ? "gray.600" : "gray.100"}
      >
        <Input
          value={text}
          zIndex={0}
          onBlur={() => setIsEditingSubject(false)}
          onChange={handleTextChange}
          placeholder="Enter subject"
          py={2}
          variant="unstyled"
          borderRadius={0}
          isDisabled={isDisabled}
        />
        {!isEditingSubject && (
          <Flex
            position="absolute"
            zIndex={1}
            cursor={isDisabled ? "not-allowed" : "pointer"}
            onClick={() => {
              if (isDisabled) {
                return;
              }

              if (!text) {
                setIsEditingSubject(true);

                return;
              }

              setShowConfirmationModal(true);
            }}
            backgroundColor={colorMode === "dark" ? "gray.700" : "white"}
            width="100%"
            height="100%"
            justifyContent="flex-start"
            alignItems="center"
            color={colorMode === "dark" ? "gray.600" : "gray.200"}
            gridGap={2}
          >
            <Text overflow="hidden" whiteSpace="nowrap" textOverflow="ellipsis">
              Subject: {text || "No subject"}
            </Text>
            <Icon
              as={EditIcon}
              boxSize="1rem"
              __css={{
                path: {
                  fill: colorMode === "dark" ? "gray.600" : "gray.200",
                },
              }}
            />
          </Flex>
        )}
      </Flex>
      <ConfirmationDialog
        headerText="Change Subject Line?"
        messageText="Changing the subject line will initiate a new e-mail thread with the recipient. Would you like to continue?"
        buttonText="Continue"
        isOpen={showConfirmationModal}
        setIsOpen={setShowConfirmationModal}
        confirmationCallback={() => {
          setIsEditingSubject(true);
          setShowConfirmationModal(false);
        }}
      />
    </>
  );
};

export default memo(SubjectLine);
