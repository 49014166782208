export enum PromptType {
  REPLY = "reply",
}

export enum PromptAction {
  REPLY = "reply",
  CLOSE_CONVERSATION = "close_conversation",
}

export default class PromptDomain {
  id: string;

  conversationId: number;

  body: string;

  type: PromptType;

  actions: PromptAction[];

  lastUpdatedAt: string;

  payload: any;

  public hasReplyAction(): boolean {
    return this.hasAction(PromptAction.REPLY);
  }

  public hasCloseConversationAction(): boolean {
    return this.hasAction(PromptAction.CLOSE_CONVERSATION);
  }

  public hasReplyAndCloseConversationActions(): boolean {
    return this.hasReplyAction() && this.hasCloseConversationAction();
  }

  private hasAction(action: PromptAction): boolean {
    return this.actions.includes(action);
  }

  constructor(
    id: string,
    conversationId: number,
    body: string,
    type: PromptType,
    actions: PromptAction[],
    lastUpdatedAt: string,
    payload: any
  ) {
    this.id = id;
    this.conversationId = conversationId;
    this.body = body;
    this.type = type;
    this.actions = actions;
    this.lastUpdatedAt = lastUpdatedAt;
    this.payload = payload;
  }
}
