import { useAuth0 } from "@auth0/auth0-react";
import {
  Button,
  Box,
  FormControl,
  FormLabel,
  Stack,
  useToast,
  Input,
} from "@chakra-ui/react";
import { useAppSelector } from "redux/hooks";
import React, { useState } from "react";

import AdminService from "services/admin";
import AdminMerchantDomain from "entities/domain/admin/merchants/admin-merchant-domain";
import SelectMerchant from "./select-merchant";

const AddMerchantAssistantForm = () => {
  const auth0 = useAuth0();
  const toast = useToast();
  const { colorScheme } = useAppSelector((state) => state.theme);

  const [selectedMerchant, setSelectedMerchant] =
    useState<AdminMerchantDomain | null>(null);
  const [assistantExternalId, setAssistantExternalId] = useState<string>("");

  const resetValues = () => {
    setSelectedMerchant(null);
    setAssistantExternalId("");
  };

  const saveAssistant = async () => {
    try {
      await AdminService.addMerchantAssistant(auth0, {
        merchantId: selectedMerchant!.id,
        externalId: assistantExternalId,
      })
        .then(() => {
          toast({
            title: "Assistant added successfully",
            status: "success",
          });
        })
        .catch((error) => {
          toast({
            title: error.message,
            status: "error",
          });
        });
    } finally {
      resetValues();
    }
  };

  return (
    <Box>
      <Stack spacing={3}>
        <FormControl isRequired>
          <FormLabel>Assign Merchant</FormLabel>
          <SelectMerchant
            selectedMerchant={selectedMerchant}
            setSelectedMerchant={setSelectedMerchant}
          />
          <FormLabel mb="0.5rem">Assistant Id</FormLabel>
          <Input
            value={assistantExternalId}
            onChange={(e) => {
              setAssistantExternalId(e.target.value);
            }}
            placeholder="asst_123131"
          />
        </FormControl>
      </Stack>
      <Button
        colorScheme={colorScheme}
        onClick={saveAssistant}
        isDisabled={!selectedMerchant}
        mt={8}
        mb={8}
        size="lg"
      >
        Add Assistant
      </Button>
    </Box>
  );
};

export default AddMerchantAssistantForm;
