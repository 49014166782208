import { useAuth0 } from "@auth0/auth0-react";
import {
  Radio,
  RadioGroup,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  VStack,
} from "@chakra-ui/react";
import useMerchantStore from "hooks/use-merchant-store";
import React, { useEffect, useState } from "react";
import ReviewsService from "services/reviews";
import { GoogleLocationDTO } from "entities/dto/ReviewsDTO";
import { useAppSelector } from "redux/hooks";

function GoogleLocationsOptions() {
  const reviewsRedirectUri = `https://${window.location.hostname}/reviews`;
  const reviewsLocalRedirectUri = reviewsRedirectUri;

  const redirectToReviews = () => {
    window.location.assign(reviewsLocalRedirectUri);
  };

  const auth0Context = useAuth0();
  const { merchant } = useAppSelector((state) => state.merchant);
  const { colorScheme } = useAppSelector((state) => state.theme);
  const [selectedOption, setSelectedOption] = useState<string>("");

  const selectOption = (option: string) => {
    setSelectedOption(option);
  };

  const [locations, setLocations] = useState<GoogleLocationDTO[]>([]);

  useEffect(() => {
    ReviewsService.getGoogleLocations(auth0Context, merchant.id).then(
      (response) => {
        setLocations(response);
      }
    );
  }, [merchant.id]);

  const onContinue = async () => {
    await ReviewsService.selectGoogleLocation(
      auth0Context,
      merchant.id,
      selectedOption
    );

    return redirectToReviews();
  };

  return (
    <Modal isOpen={true} closeOnEsc={false} onClose={() => {}}>
      <ModalOverlay />
      <ModalContent>
        {locations.length ? (
          <>
            <ModalHeader>Choose a Google Location</ModalHeader>
            <ModalBody>
              <RadioGroup
                display="flex"
                defaultValue={selectedOption}
                onChange={selectOption}
              >
                <VStack
                  gap={4}
                  mx="auto"
                  justifyContent="start"
                  alignItems="start"
                >
                  {locations.map((location) => (
                    <Radio key={location.id} value={location.place_id}>
                      {location.name} ({location.address})
                    </Radio>
                  ))}
                </VStack>
              </RadioGroup>
            </ModalBody>

            <ModalFooter>
              <Button colorScheme={colorScheme} onClick={onContinue}>
                Submit
              </Button>
            </ModalFooter>
          </>
        ) : (
          <ModalHeader>
            We could not find any Google locations connected to your account
          </ModalHeader>
        )}
      </ModalContent>
    </Modal>
  );
}

export default GoogleLocationsOptions;
