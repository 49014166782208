export default class YapilyBankMediaDomain {
  type: string;

  source: string;

  constructor(type: string, source: string) {
    this.type = type;

    this.source = source;
  }
}
