import TeamDomain from "entities/domain/team";
import { TeamDTO } from "entities/dto/TeamDTO";

export const teamTransformFromDtoToDomain = (data: TeamDTO): TeamDomain => {
  return new TeamDomain(
    data.id,
    data.merchant_id,
    data.name,
    data.purpose,
    data.agent_ids,
    data.created_at,
    data.updated_at
  );
};
