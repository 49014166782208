import { ArrowDownIcon, ArrowUpIcon, CheckIcon } from "@chakra-ui/icons";
import {
  Button,
  Flex,
  Icon,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Text,
  useBreakpointValue,
  useColorMode,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { ContactsSorting } from "util/ContactsFilter";
import { ReactComponent as SortingIcon } from "assets/icons/arrow-down-a-z-svgrepo-com.svg";
import { useAppSelector } from "redux/hooks";

interface SortingProps {
  setSorting: (sorting: ContactsSorting | undefined) => void;
}

const convertFieldToText = (field: string) => {
  const fieldText = field.split("_").join(" ");
  return fieldText.charAt(0).toUpperCase() + fieldText.slice(1);
};

const Sorting = ({ setSorting }: SortingProps) => {
  const { colorScheme } = useAppSelector((state) => state.theme);
  const isBaseSize = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );
  const { colorMode } = useColorMode();

  const [lastSorting, setLastSorting] = useState<{
    field: string;
    direction: string;
  }>({ field: "", direction: "" });

  const handleSortingSelected = (
    field: "name" | "customer_last_contact",
    direction: "asc" | "desc"
  ) => {
    if (lastSorting.field === field && lastSorting.direction === direction) {
      setLastSorting({ field: "", direction: "" });
      setSorting(undefined);
      return;
    }

    setLastSorting({ field, direction });
    setSorting({ field, direction });
  };

  return (
    <Popover placement={isBaseSize ? "bottom" : "right-end"}>
      <PopoverTrigger>
        <Button
          w={isBaseSize ? "auto" : "100%"}
          gridGap={2}
          colorScheme={colorScheme}
          pl={isBaseSize ? 0 : 8}
          ml={isBaseSize ? 0 : 2}
          justifyContent={isBaseSize ? "center" : "start"}
          variant={lastSorting.field ? "solid" : "ghost"}
          isActive={!!lastSorting.field && isBaseSize}
          {...(isBaseSize
            ? {
                borderRadius: "full",
                p: 0,
              }
            : {})}
        >
          {lastSorting.direction === "asc" && !isBaseSize && (
            <Icon
              as={ArrowUpIcon}
              width={isBaseSize ? "1rem" : "1.25rem"}
              height={isBaseSize ? "1rem" : "1.25rem"}
            />
          )}
          {lastSorting.direction === "desc" && !isBaseSize && (
            <Icon
              as={ArrowDownIcon}
              width={isBaseSize ? "1rem" : "1.25rem"}
              height={isBaseSize ? "1rem" : "1.25rem"}
            />
          )}
          {(!lastSorting.direction || isBaseSize) && (
            <Icon
              as={SortingIcon}
              width={isBaseSize ? "1rem" : "1.25rem"}
              height={isBaseSize ? "1rem" : "1.25rem"}
              __css={{
                path: {
                  stroke:
                    lastSorting.field && !isBaseSize
                      ? "white"
                      : colorMode === "dark"
                      ? `${colorScheme}.200`
                      : `${colorScheme}.500`,
                },
              }}
            />
          )}
          {isBaseSize ? null : (
            <Text overflow="hidden" textOverflow="ellipsis">
              {lastSorting.field
                ? `By ${convertFieldToText(lastSorting.field)}`
                : "Sort"}
            </Text>
          )}
        </Button>
      </PopoverTrigger>
      <PopoverContent
        _focus={{ outline: "none" }}
        boxShadow="0px 3px 33px rgba(137, 156, 197, 0.393111)"
        w="189px"
        borderRadius="lg"
        overflow="hidden"
        style={{
          transition: "all 0.1s",
          outline: "none",
        }}
      >
        <PopoverArrow />
        <PopoverBody p={0}>
          <Flex
            justify="space-between"
            align="center"
            bg="transparent"
            w="100%"
            p={4}
            cursor="pointer"
            onClick={() => handleSortingSelected("name", "asc")}
            _hover={{
              background: "gray.200",
            }}
          >
            <Flex
              w="100%"
              gridGap={1}
              alignItems="center"
              justifyContent="start"
            >
              <Icon as={ArrowUpIcon} />
              <Text>First Name</Text>
            </Flex>
            {lastSorting.field === "name" &&
              lastSorting.direction === "asc" && (
                <CheckIcon color={`${colorScheme}.400`} ml={3} />
              )}
          </Flex>
          <Flex
            justify="space-between"
            align="center"
            bg="transparent"
            w="100%"
            p={4}
            cursor="pointer"
            onClick={() => handleSortingSelected("name", "desc")}
            _hover={{
              background: "gray.200",
            }}
          >
            <Flex
              w="100%"
              gridGap={1}
              alignItems="center"
              justifyContent="start"
            >
              <Icon as={ArrowDownIcon} />
              <Text>First Name</Text>
            </Flex>
            {lastSorting.field === "name" &&
              lastSorting.direction === "desc" && (
                <CheckIcon color={`${colorScheme}.400`} ml={3} />
              )}
          </Flex>
          <Flex
            justify="space-between"
            align="center"
            bg="transparent"
            w="100%"
            p={4}
            cursor="pointer"
            onClick={() =>
              handleSortingSelected("customer_last_contact", "asc")
            }
            _hover={{
              background: "gray.200",
            }}
          >
            <Flex
              w="100%"
              gridGap={1}
              alignItems="center"
              justifyContent="start"
            >
              <Icon as={ArrowUpIcon} />
              <Text>Date last contacted</Text>
            </Flex>
            {lastSorting.field === "customer_last_contact" &&
              lastSorting.direction === "asc" && (
                <CheckIcon color={`${colorScheme}.400`} ml={3} />
              )}
          </Flex>
          <Flex
            justify="space-between"
            align="center"
            bg="transparent"
            w="100%"
            p={4}
            cursor="pointer"
            onClick={() =>
              handleSortingSelected("customer_last_contact", "desc")
            }
            _hover={{
              background: "gray.200",
            }}
          >
            <Flex
              w="100%"
              gridGap={1}
              alignItems="center"
              justifyContent="start"
            >
              <Icon as={ArrowDownIcon} />
              <Text>Date last contacted</Text>
            </Flex>
            {lastSorting.field === "customer_last_contact" &&
              lastSorting.direction === "desc" && (
                <CheckIcon color={`${colorScheme}.400`} ml={3} />
              )}
          </Flex>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};

export default Sorting;
