import { useAuth0 } from "@auth0/auth0-react";
import React, {
  Button,
  ButtonGroup,
  Card,
  CardBody,
  CardFooter,
  HStack,
  Icon,
  Text,
  Tooltip,
  useBreakpointValue,
  useColorMode,
  useToast,
} from "@chakra-ui/react";
import { ReactComponent as CrossIcon } from "assets/icons/Close-ds.svg";
import { ReactComponent as SendIcon } from "assets/icons/Send-arrow-ds.svg";
import { ReactComponent as EditIcon } from "assets/icons/edit-ds.svg";
import { ReactComponent as CloseIcon } from "assets/icons/close-lock.svg";
import PromptDomain from "entities/domain/prompt";
import useAnalytics from "hooks/use-analytics";
import { useMemo, useState, useCallback } from "react";
import { setAutoReplySuggestion } from "redux/features/messages";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import InboxService from "services/inbox";
import { calculateTypingTime } from "util/methods";
import useConversationsStore from "hooks/use-conversations-store";
import { createSearchParams, useLocation, useNavigate } from "react-router-dom";

const Prompt = ({ loadedPrompt }: { loadedPrompt: PromptDomain }) => {
  const auth0Context = useAuth0();
  const { colorMode } = useColorMode();
  const isBaseSize = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );
  const { colorScheme } = useAppSelector((state) => state.theme);
  const { currentAgent } = useAppSelector((state) => state.agents);
  const { merchant } = useAppSelector((state) => state.merchant);
  const { activeConversation, activeConversationId } = useAppSelector(
    (state) => state.conversations
  );
  const navigate = useNavigate();
  const { search } = useLocation();
  const { closeOrOpenConversation } = useConversationsStore();

  const { track } = useAnalytics();
  const toast = useToast();

  const [draftActionPressed, setDraftActionPressed] = useState<boolean>(false);

  const dispatch = useAppDispatch();

  const deleteDraft = useCallback(async () => {
    if (!activeConversationId) {
      return;
    }

    track("delete_draft_message", {
      conversation_id: activeConversation?.id,
    });
    setDraftActionPressed(true);
    try {
      await InboxService.deletePrompt(
        auth0Context,
        merchant.id,
        activeConversationId,
        loadedPrompt.id
      );
    } finally {
      setDraftActionPressed(false);
    }
  }, [
    activeConversationId,
    loadedPrompt,
    track,
    auth0Context,
    merchant.id,
    activeConversation,
  ]);

  const editDraft = useCallback(async () => {
    if (!activeConversationId) {
      return;
    }

    track("edit_draft_message", {
      conversation_id: activeConversation?.id,
    });
    setDraftActionPressed(true);
    dispatch(setAutoReplySuggestion(loadedPrompt.body));
    try {
      await InboxService.deletePrompt(
        auth0Context,
        merchant.id,
        activeConversationId,
        loadedPrompt.id
      );
    } finally {
      setDraftActionPressed(false);
    }
  }, [
    activeConversationId,
    loadedPrompt,
    track,
    auth0Context,
    merchant.id,
    activeConversation,
    dispatch,
  ]);

  const closeConversation = useCallback(async () => {
    if (!activeConversationId) {
      return;
    }

    track("close_conversation", {
      conversation_id: activeConversation?.id,
    });
    setDraftActionPressed(true);
    try {
      await closeOrOpenConversation(
        activeConversationId,
        false,
        currentAgent!.id
      );
      await InboxService.deletePrompt(
        auth0Context,
        merchant.id,
        activeConversationId,
        loadedPrompt.id
      );
      navigate({
        pathname: `/${merchant.id}/inbox`,
        search: createSearchParams(search).toString(),
      });
    } finally {
      setDraftActionPressed(false);
    }
  }, [
    activeConversationId,
    loadedPrompt,
    track,
    auth0Context,
    merchant.id,
    activeConversation,
    currentAgent,
    closeOrOpenConversation,
    navigate,
    search,
  ]);

  const sendMessageAndCloseConversation = useCallback(async () => {
    if (!activeConversationId) {
      return;
    }

    track("send_draft_message_and_close_conversation", {
      conversation_id: activeConversation?.id,
    });
    setDraftActionPressed(true);
    try {
      await InboxService.sendMessage(
        auth0Context,
        {
          conversation_id: activeConversationId,
          body: loadedPrompt.body,
          reply_to_message_id: loadedPrompt.payload.reply_to_message_id,
        },
        merchant.id,
        activeConversationId
      );
      toast({
        status: "success",
        title: `You've saved at least ${calculateTypingTime(
          loadedPrompt.body
        )}!`,
      });
      await InboxService.deletePrompt(
        auth0Context,
        merchant.id,
        activeConversationId,
        loadedPrompt.id
      );
      setTimeout(async () => {
        await closeOrOpenConversation(
          activeConversationId,
          false,
          currentAgent!.id
        );
        navigate({
          pathname: `/${merchant.id}/inbox`,
          search: createSearchParams(search).toString(),
        });
      }, 2000);
    } finally {
      setDraftActionPressed(false);
    }
  }, [
    activeConversationId,
    loadedPrompt,
    track,
    auth0Context,
    merchant.id,
    activeConversation,
    currentAgent,
    closeOrOpenConversation,
    navigate,
    search,
    toast,
  ]);

  const sendDraft = useCallback(async () => {
    if (!activeConversationId) {
      return;
    }

    track("send_draft_message", {
      conversation_id: activeConversation?.id,
    });
    setDraftActionPressed(true);
    try {
      await InboxService.sendMessage(
        auth0Context,
        {
          conversation_id: activeConversationId,
          body: loadedPrompt.body,
          reply_to_message_id: loadedPrompt.payload.reply_to_message_id,
        },
        merchant.id,
        activeConversationId
      );
      await InboxService.deletePrompt(
        auth0Context,
        merchant.id,
        activeConversationId,
        loadedPrompt.id
      );
      toast({
        status: "success",
        title: `You've saved at least ${calculateTypingTime(
          loadedPrompt.body
        )}!`,
      });
    } finally {
      setDraftActionPressed(false);
    }
  }, [
    activeConversationId,
    loadedPrompt,
    track,
    auth0Context,
    merchant.id,
    activeConversation,
    toast,
  ]);

  const deleteDraftButton = useMemo(
    () => (
      <Tooltip label="Delete" aria-label="Delete" placement="top">
        <Button
          size="xs"
          colorScheme={colorScheme}
          variant="ghost"
          isDisabled={draftActionPressed}
          onClick={deleteDraft}
        >
          <Icon
            as={CrossIcon}
            __css={{
              rect: {
                fill: "inherit",
              },
              path: {
                fill:
                  colorMode === "dark"
                    ? `${colorScheme}.200`
                    : `${colorScheme}.500`,
              },
            }}
          />
        </Button>
      </Tooltip>
    ),
    [draftActionPressed, colorMode, colorScheme, deleteDraft]
  );

  const editDraftButton = useMemo(
    () => (
      <Tooltip label="Edit message" aria-label="Edit message" placement="top">
        <Button
          size="xs"
          colorScheme={colorScheme}
          variant="ghost"
          isDisabled={draftActionPressed}
          onClick={editDraft}
        >
          <Icon
            as={EditIcon}
            __css={{
              rect: {
                fill: "inherit",
              },
              path: {
                fill:
                  colorMode === "dark"
                    ? `${colorScheme}.200`
                    : `${colorScheme}.500`,
              },
            }}
          />
        </Button>
      </Tooltip>
    ),
    [draftActionPressed, colorMode, colorScheme, editDraft]
  );

  const sendDraftButton = useMemo(
    () => (
      <Tooltip label="Send message" aria-label="Send message" placement="top">
        <Button
          flexGrow={1}
          borderRadius={0}
          borderBottomRightRadius="lg"
          borderWidth={0}
          colorScheme={colorScheme}
          bgColor={
            colorMode === "dark" ? `${colorScheme}.800` : `${colorScheme}.200`
          }
          py={3}
          height="auto"
          isDisabled={draftActionPressed}
          onClick={sendDraft}
        >
          <Icon
            as={SendIcon}
            __css={{
              rect: {
                fill: "inherit",
              },
              path: {
                fill:
                  colorMode === "dark"
                    ? `${colorScheme}.200`
                    : `${colorScheme}.500`,
              },
            }}
          />
        </Button>
      </Tooltip>
    ),
    [draftActionPressed, colorMode, colorScheme, sendDraft]
  );

  const closeConversationButton = useMemo(
    () => (
      <Tooltip
        label="Close conversation"
        aria-label="Close conversation"
        placement="top"
      >
        <Button
          flexGrow={1}
          borderRadius={0}
          borderBottomRightRadius="lg"
          borderWidth={0}
          colorScheme={colorScheme}
          bgColor={
            colorMode === "dark" ? `${colorScheme}.900` : `${colorScheme}.100`
          }
          py={3}
          height="auto"
          isDisabled={draftActionPressed}
          onClick={closeConversation}
        >
          <Icon
            as={CloseIcon}
            __css={{
              rect: {
                fill: "inherit",
              },
              path: {
                fill:
                  colorMode === "dark"
                    ? `${colorScheme}.200`
                    : `${colorScheme}.500`,
              },
            }}
          />
        </Button>
      </Tooltip>
    ),
    [draftActionPressed, colorMode, colorScheme, closeConversation]
  );

  const sendMessageAndCloseConversationButton = useMemo(
    () => (
      <Tooltip
        label="Send message and close conversation"
        aria-label="Send message and close conversation"
        placement="top"
      >
        <Button
          flexGrow={1}
          borderRadius={0}
          borderBottomRightRadius="lg"
          borderWidth={0}
          colorScheme={colorScheme}
          bgColor={
            colorMode === "dark" ? `${colorScheme}.700` : `${colorScheme}.300`
          }
          py={3}
          height="auto"
          isDisabled={draftActionPressed}
          onClick={sendMessageAndCloseConversation}
          display="flex"
          alignItems="center"
          justifyContent="center"
          gap="2"
        >
          <Icon
            as={SendIcon}
            __css={{
              rect: {
                fill: "inherit",
              },
              path: {
                fill:
                  colorMode === "dark"
                    ? `${colorScheme}.200`
                    : `${colorScheme}.500`,
              },
            }}
          />
          <Icon
            as={CloseIcon}
            __css={{
              rect: {
                fill: "inherit",
              },
              path: {
                fill:
                  colorMode === "dark"
                    ? `${colorScheme}.200`
                    : `${colorScheme}.500`,
              },
            }}
          />
        </Button>
      </Tooltip>
    ),
    [
      draftActionPressed,
      colorMode,
      colorScheme,
      sendMessageAndCloseConversation,
    ]
  );

  return (
    <Card
      maxWidth="md"
      mx="auto"
      mb={4}
      backgroundColor={
        currentAgent!.stylePreferences?.chatBackground
          ? `${colorMode === "dark" ? "blackAlpha" : "whiteAlpha"}.800`
          : `${colorMode === "dark" ? "gray.600" : "white"}`
      }
    >
      <CardBody
        display="flex"
        justifyContent="space-between"
        alignItems="flex-start"
        padding={isBaseSize ? 1 : 3}
      >
        <Text flexGrow={1}>{loadedPrompt.body}</Text>
        <HStack pt={1}>
          {editDraftButton}
          {deleteDraftButton}
        </HStack>
      </CardBody>
      <CardFooter padding={0}>
        <ButtonGroup
          size="sm"
          isAttached
          variant="outline"
          w="100%"
          colorScheme={colorScheme}
        >
          {loadedPrompt.hasCloseConversationAction() && (
            <>{closeConversationButton}</>
          )}
          {loadedPrompt.hasReplyAction() && <>{sendDraftButton}</>}
          {loadedPrompt.hasReplyAndCloseConversationActions() && (
            <>{sendMessageAndCloseConversationButton}</>
          )}
        </ButtonGroup>
      </CardFooter>
    </Card>
  );
};

export default Prompt;
