import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Flex,
  Text,
  VStack,
} from "@chakra-ui/react";
import MessageDomain from "entities/domain/conversations/message-domain";
import React from "react";
import { humanizeCamelCase } from "util/methods";

interface MessageAttributesProps {
  message: MessageDomain;
  isOpened?: boolean;
  rejectAttributes?: string[];
}

const MessageAttributes: React.FC<MessageAttributesProps> = ({
  message,
  isOpened = false,
  rejectAttributes = [],
}) => {
  if (!message.metadata || message.metadata.attributes === undefined) {
    return null;
  }

  const rejectEmptyAttributes = (attributeKey: string) => {
    const attributeValue = message.metadata!.attributes![attributeKey];

    if (!attributeValue) {
      return false;
    }

    return true;
  };

  const rejectSpecificAttributes = (attributeKey: string) => {
    return !rejectAttributes.includes(attributeKey);
  };

  return (
    <Accordion
      allowToggle={true}
      allowMultiple={true}
      defaultIndex={isOpened ? [0] : []}
    >
      <AccordionItem>
        <AccordionButton>
          <Box as="span" flex="1" textAlign="left">
            All Details
          </Box>
          <AccordionIcon />
        </AccordionButton>
        <AccordionPanel w="inherit" pb={4}>
          <VStack spacing={4} alignItems="flex-start">
            {Object.keys(message.metadata.attributes)
              .filter(rejectEmptyAttributes)
              .filter(rejectSpecificAttributes)
              .map((key) => (
                <Flex
                  key={key}
                  alignItems="center"
                  justifyContent="space-between"
                  w="100%"
                >
                  <Text fontWeight="bold">{humanizeCamelCase(key)}</Text>
                  <Text>{message.metadata!.attributes![key]}</Text>
                </Flex>
              ))}
          </VStack>
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  );
};

export default MessageAttributes;
