import React from "react";
import MessageDomain from "entities/domain/conversations/message-domain";
import {
  Flex,
  Heading,
  Text,
  useBreakpointValue,
  useColorMode,
} from "@chakra-ui/react";

import MessageWrapper from "./MessageWrapper";
import MessageAttributes from "./MessageAttributes";

interface InfoMessageProps {
  message: MessageDomain;
}

const InfoMessage: React.FC<InfoMessageProps> = ({ message }) => {
  const isBaseSize = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );
  const { colorMode } = useColorMode();

  return (
    <Flex
      mx="auto"
      my={2}
      borderRadius="xl"
      direction="column"
      gridGap={4}
      boxSize={isBaseSize ? "xs" : "md"}
      height="fit-content"
      bgColor={colorMode === "dark" ? "black" : "white"}
      pb={8}
    >
      <Heading
        as="h1"
        my={4}
        fontSize="lg"
        fontWeight="bold"
        textTransform="uppercase"
        textAlign="center"
      >
        {message.title}
      </Heading>
      <MessageAttributes
        message={message}
        isOpened={true}
        rejectAttributes={["termsAndConditions"]}
      />
    </Flex>
  );
};

export default InfoMessage;
