import * as yup from "yup";
import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useBreakpointValue,
  useColorMode,
  useDisclosure,
} from "@chakra-ui/react";
import PasswordChangeConfirmation from "components/user-settings/AccountOverview/PasswordChange";
import React, { useEffect, useState } from "react";
import { useAppSelector } from "redux/hooks";

interface PasswordChangeModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const PasswordChangeModal = ({ isOpen, onClose }: PasswordChangeModalProps) => {
  const { colorScheme } = useAppSelector((state) => state.theme);
  const isBaseSize = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );
  const [newPassword, setNewPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const passwordChangeConfirmationDrawer = useDisclosure();

  const getValidationError = async (password: string): Promise<string> => {
    const schema = yup
      .string()
      .required("Required")
      .min(12, "Must be at least 12 characters")
      .matches(/^(?=.*[a-z])/, "Must contain one lowercase character")
      .matches(/^(?=.*[A-Z])/, "Must contain one uppercase character")
      .matches(/^(?=.*[0-9])/, "Must contain one numeric character");

    try {
      await schema.validate(password);
    } catch (err: unknown) {
      if (err instanceof yup.ValidationError) {
        if (err.errors[0]) {
          return err.errors[0];
        }
      }
    }
    return "";
  };

  useEffect(() => {
    setPasswordError("");
    setNewPassword("");
  }, [isOpen]);

  useEffect(() => {
    if (!newPassword) {
      setPasswordError("");
    } else {
      getValidationError(newPassword).then((error) => {
        setPasswordError(error);
      });
    }
  }, [newPassword]);

  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        size={isBaseSize ? "full" : "md"}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Change Password</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl isInvalid={!!passwordError}>
              <FormLabel>New Password</FormLabel>
              <Input
                type="password"
                value={newPassword}
                placeholder="***********"
                isDisabled={passwordChangeConfirmationDrawer.isOpen}
                onChange={(e) => {
                  setNewPassword(e.target.value);
                }}
              />
              <FormErrorMessage>{passwordError}</FormErrorMessage>
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button
              colorScheme={colorScheme}
              variant="ghost"
              onClick={onClose}
              isDisabled={passwordChangeConfirmationDrawer.isOpen}
            >
              Cancel
            </Button>
            <Button
              isDisabled={
                !!passwordError ||
                !newPassword ||
                passwordChangeConfirmationDrawer.isOpen
              }
              colorScheme={colorScheme}
              onClick={() => passwordChangeConfirmationDrawer.onOpen()}
            >
              Change
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <PasswordChangeConfirmation
        newPassword={newPassword}
        isOpen={passwordChangeConfirmationDrawer.isOpen}
        onClose={() => {
          passwordChangeConfirmationDrawer.onClose();
          onClose();
        }}
      />
    </>
  );
};

export default PasswordChangeModal;
