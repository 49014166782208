import { useAuth0 } from "@auth0/auth0-react";
import { HStack, Radio, RadioGroup } from "@chakra-ui/react";
import { VehicleCondition } from "entities/domain/inventory-vehicle";
import React, { useEffect, useState } from "react";
import { setFilterByCondition } from "redux/features/inventory";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import InventoryService from "services/inventory";

interface ConditionsFilterProps {}

export const DEFAULT_VEHICLE_CONDITION = VehicleCondition.NEW;

const ConditionsFilter = (_props: ConditionsFilterProps) => {
  const dispatch = useAppDispatch();
  const auth0Context = useAuth0();
  const { merchant } = useAppSelector((state) => state.merchant);
  const { colorScheme } = useAppSelector((state) => state.theme);
  const {
    filterOptions,
    filterByCondition,
    filterByModel,
    filterByPriceMax,
    filterByPriceMin,
    filterByYearMax,
    filterByYearMin,
    filterByMake,
    filterByAvailability,
  } = useAppSelector((state) => state.inventory);
  const [selectedCondition, setSelectedCondition] = useState<VehicleCondition>(
    DEFAULT_VEHICLE_CONDITION
  );

  const [availableConditions, setAvailableConditions] = useState<
    VehicleCondition[]
  >(filterOptions.allConditions);

  useEffect(() => {
    if (
      !filterByModel &&
      !filterByYearMin &&
      !filterByYearMax &&
      !filterByPriceMin &&
      !filterByPriceMax &&
      !filterByMake &&
      !filterByAvailability
    ) {
      setAvailableConditions(filterOptions.allConditions);
      return;
    }

    InventoryService.getVehicleFilterOptions(
      auth0Context,
      merchant.id,
      filterByModel,
      filterByMake,
      filterByYearMin,
      filterByYearMax,
      filterByPriceMin,
      filterByPriceMax,
      null,
      filterByAvailability
    ).then((newFilterOptions) => {
      setAvailableConditions(newFilterOptions.allConditions);
    });
  }, [
    filterOptions,
    filterByMake,
    filterByModel,
    filterByPriceMax,
    filterByPriceMin,
    filterByYearMax,
    filterByYearMin,
  ]);

  useEffect(() => {
    if (selectedCondition !== filterByCondition) {
      dispatch(setFilterByCondition(selectedCondition));
    }
  }, [selectedCondition]);

  return availableConditions.length > 1 ? (
    <RadioGroup
      value={selectedCondition}
      onChange={(newCondition) => {
        setSelectedCondition(newCondition as VehicleCondition);
      }}
    >
      <HStack spacing={2}>
        {availableConditions.map((condition) => (
          <Radio colorScheme={colorScheme} value={condition}>
            {condition[0].toLocaleUpperCase() +
              condition.slice(1).toLowerCase()}
          </Radio>
        ))}
      </HStack>
    </RadioGroup>
  ) : null;
};

export default ConditionsFilter;
