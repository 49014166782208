import { Text } from "@chakra-ui/react";
import React from "react";

interface WarningTextComponentProps {
  isValid: boolean;
  text: string;
  id?: string;
}

export type PossibleWarningKey =
  | "invalid_payment_amount"
  | "invalid_description"
  | "invalid_phone_number"
  | "invalid_email"
  | "invalid_bank_acct"
  | "invalid_sort_code"
  | "invalid_iban"
  | "invalid_bic"
  | "invalid_name_format"
  | "invalid_customer_type"
  | "invalid_address"
  | "invalid_channel"
  | "style_preferences"
  | "channel_already_assigned_to_another_customer"
  | "maximum_tags";

export type MultipleWarnings = {
  [key in PossibleWarningKey]?: string;
};

export interface WarningTextProps {
  key: PossibleWarningKey;
  description: string;
}

const WarningTextComponent = ({
  isValid,
  text,
  id = "",
}: WarningTextComponentProps) => {
  return (
    <Text
      fontSize="xs"
      mb={3}
      display={isValid ? "none" : "initial"}
      color="red.400"
      id={id}
    >
      {text}
    </Text>
  );
};

export default WarningTextComponent;
