import { Button, ButtonProps } from "@chakra-ui/react";
import React from "react";
import { useAppSelector } from "redux/hooks";

interface DefaultBrowseButtonProps extends ButtonProps {
  size: "sm" | "md" | "lg";
}

const DefaultBrowseButton = ({ size, onClick }: DefaultBrowseButtonProps) => {
  return (
    <Button
      size={size}
      width="fit-content"
      colorScheme="gray"
      onClick={onClick}
    >
      Browse
    </Button>
  );
};

export default DefaultBrowseButton;
