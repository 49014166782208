import { useAuth0 } from "@auth0/auth0-react";
import {
  fetchNotifications,
  fetchNotificationsFail,
  fetchNotificationsSuccess,
  setAllAsRead,
  setAsRead,
} from "redux/features/notifications";
import { useCallback } from "react";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import NotificationsService from "services/notifications";

export default function useNotificationsStore() {
  const dispatch = useAppDispatch();
  const auth0Context = useAuth0();
  const { merchant } = useAppSelector((state) => state.merchant);

  const fetchNotificationsWaterfall = () => async () => {
    try {
      dispatch(fetchNotifications());

      const response = await NotificationsService.getNotifications(
        auth0Context,
        merchant.id
      );

      dispatch(fetchNotificationsSuccess(response));
    } catch (err) {
      dispatch(fetchNotificationsFail(["Oops. Please try again."]));
    }
  };

  const markNotificationAsReadWaterfall =
    (notificationId: string) => async (): Promise<void> => {
      try {
        await NotificationsService.markNotificationAsRead(
          auth0Context,
          merchant.id,
          notificationId
        );

        dispatch(setAsRead(notificationId));
      } catch (err) {
        console.error(err);
      }
    };

  const markAllNotificationsAsReadWaterfall = () => async () => {
    try {
      await NotificationsService.markAllNotificationsAsRead(
        auth0Context,
        merchant.id
      );

      dispatch(setAllAsRead());
    } catch (err) {
      console.error(err);
    }
  };

  const fetchNotificationsAsync = useCallback(
    () => dispatch(fetchNotificationsWaterfall()),
    [dispatch, merchant]
  );

  const markNotificationAsReadAsync = useCallback(
    (notificationId: string) =>
      dispatch(markNotificationAsReadWaterfall(notificationId)),
    [dispatch, merchant]
  );

  const markAllNotificationsAsReadAsync = useCallback(
    () => dispatch(markAllNotificationsAsReadWaterfall()),
    [dispatch, merchant]
  );

  return {
    fetchNotifications: fetchNotificationsAsync,
    markNotificationAsRead: markNotificationAsReadAsync,
    markAllNotificationsAsRead: markAllNotificationsAsReadAsync,
  };
}
