export default class ChannelDomain {
  id: string;

  channelName: string;

  identifier: string;

  countryCode?: string;

  customWebhook?: string;

  getDisplayChannelName(): string {
    switch (this.channelName) {
      case "sms":
        return "SMS";
      case "whatsapp":
        return "WhatsApp";
      case "instagram":
        return "Instagram";
      case "facebook":
        return "Facebook";
      case "zapier_facebook":
        return "Zapier Facebook";
      case "email":
        return "Email";
      default:
        return "";
    }
  }

  static getChannelName(channelName: string): string {
    switch (channelName) {
      case "sms":
        return "SMS";
      case "whatsapp":
        return "WhatsApp";
      case "instagram":
        return "Instagram";
      case "facebook":
        return "Facebook";
      case "zapier_facebook":
        return "Zapier Facebook";
      case "email":
        return "Email";
      default:
        return "";
    }
  }

  constructor(
    id: string,
    channelName: string,
    identifier: string,
    countryCode?: string,
    customWebhook?: string
  ) {
    this.id = id;
    this.channelName = channelName;
    this.identifier = identifier;
    this.countryCode = countryCode;
    this.customWebhook = customWebhook;
  }
}
