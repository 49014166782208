export default class ReviewReplyOwnerDomain {
  public isCustomer: boolean;

  public name: string | null;

  public avatar: string | null;

  public agentId: number | null;

  public customerId: number | null;

  constructor(
    isCustomer: boolean,
    name: string | null,
    avatar: string | null,
    agentId: number | null,
    customerId: number | null
  ) {
    this.isCustomer = isCustomer;
    this.name = name;
    this.avatar = avatar;
    this.agentId = agentId;
    this.customerId = customerId;
  }
}
