import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { LexicalEditor } from "lexical";
import { MutableRefObject, useEffect } from "react";

// Lexical React plugins are React components, which makes them
// highly composable. Furthermore, you can lazy load plugins if
// desired, so you don't pay the cost for plugins until you
// actually use them.
interface RefPluginProps {
  reference?: MutableRefObject<LexicalEditor | undefined>;
}

export default function RefPlugin({ reference = undefined }: RefPluginProps) {
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    if (reference) {
      reference.current = editor;
    }
  }, [editor]);

  return null;
}
