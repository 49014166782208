import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useBreakpointValue,
  useColorMode,
} from "@chakra-ui/react";
import React, { useCallback, useState } from "react";
import FileDomain from "entities/domain/file";
import { useAppSelector } from "redux/hooks";
import FileSearch from "./FileSearch";
import QuickSelect from "./QuickSelect";

interface FileSelectorProps {
  isOpen: boolean;
  fileTypeAccept?: string;
  enableUrlShortening?: boolean;
  onClose: () => void;
  onFileSelect: (selectedFile: FileDomain | null) => void;
}

const FileSelector: React.FC<FileSelectorProps> = ({
  fileTypeAccept,
  isOpen,
  enableUrlShortening = false,
  onClose,
  onFileSelect,
}) => {
  const isBaseSize = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );
  const { colorMode } = useColorMode();
  const { colorScheme } = useAppSelector((state) => state.theme);
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [selectedFile, setSelectedFile] = useState<FileDomain | null>(null);

  const openSearch = useCallback(() => {
    setIsSearchOpen(true);
  }, []);

  const selectFile = useCallback((newSelectedFile: FileDomain | null) => {
    setSelectedFile(newSelectedFile);
  }, []);

  const reset = useCallback(() => {
    setSelectedFile(null);
    setIsSearchOpen(false);
  }, []);

  const closeAndReset = useCallback(() => {
    reset();
    onClose();
  }, []);

  return (
    <Modal
      isOpen={isOpen}
      onClose={closeAndReset}
      size={isBaseSize ? "full" : isSearchOpen ? "5xl" : "lg"}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Select File</ModalHeader>
        <ModalCloseButton />
        <ModalBody w="100%">
          {isSearchOpen ? (
            <FileSearch selectedFile={selectedFile} selectFile={selectFile} />
          ) : (
            <QuickSelect
              fileTypeAccept={fileTypeAccept}
              selectedFile={selectedFile}
              enableUrlShortening={enableUrlShortening}
              openSearch={openSearch}
              selectFile={selectFile}
            />
          )}
        </ModalBody>

        <ModalFooter
          {...(isBaseSize
            ? {
                position: "fixed",
                width: "100%",
                bottom: 0,
                bgColor: colorMode === "dark" ? "gray.800" : "white",
              }
            : {})}
          display="flex"
          justifyContent="space-between"
        >
          <Button
            colorScheme={colorScheme}
            variant="ghost"
            onClick={isSearchOpen ? reset : closeAndReset}
          >
            {isSearchOpen ? "Back" : "Close"}
          </Button>
          <Button
            isDisabled={selectedFile === null}
            colorScheme={colorScheme}
            onClick={() => {
              onFileSelect(selectedFile);
              closeAndReset();
            }}
          >
            Confirm Selection
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default FileSelector;
