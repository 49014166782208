import {
  Box,
  Button,
  Icon as ChakraIcon,
  Text,
  useBreakpointValue,
  Image,
  useColorMode,
  VStack,
  Divider,
} from "@chakra-ui/react";
import bankIcon from "assets/icons/bank.svg";
import FuzeyLogo from "assets/icons/Fuzey.svg";
import { ReactComponent as BackArrow } from "assets/icons/left-arrow.svg";
import Spinner from "components/spinner";
import { PaymentStatus } from "entities/domain/payments/payment-domain";
import YapilyBankDomain from "entities/domain/public/payments/yapily/yapilyBank";
import usePublicPaymentsStore from "hooks/use-public-payments-store";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useAppSelector } from "redux/hooks";
import BankChoice from "./bankChoice/bankChoice";
import BankListItem from "./bankChoice/bankListItem";
import ConsentScreen from "./consent/consent";
import FinalPaymentPage from "./finalPage/finalPage";
import PaymentRequestArea from "./paymentRequest/paymentRequest";

export enum PaymentProvider {
  STRIPE = "stripe",
  YAPILY = "yapily",
}

const ExternalPayment = () => {
  const { fetchPublicPayment, fetchPublicPaymentUrl, fetchYapilyBankList } =
    usePublicPaymentsStore();
  const { publicPayment, loading, paymentUrl, popularBankList, otherBankList } =
    useAppSelector((state) => state.publicPayments);
  const { colorMode } = useColorMode();

  const [step, setStep] = useState<number>(1);
  const [showOtherBanks, setShowOtherBanks] = useState<boolean>(true);
  const [paymentMethod, setPaymentMethod] = useState<
    PaymentProvider | undefined
  >(undefined);
  const [bankList, setBankList] =
    useState<Array<YapilyBankDomain>>(popularBankList);
  const [selectedBank, setSelectedBank] = useState<
    YapilyBankDomain | undefined
  >(undefined);
  const [isRedirecting, setIsRedirecting] = useState<boolean>(false);

  const { payment_id } = useParams<"payment_id">();

  const isBaseSize = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );

  useEffect(() => {
    fetchPublicPayment(payment_id!);
    setIsRedirecting(false);
  }, []);

  const setOtherBanks = () => {
    setBankList(otherBankList);
    setShowOtherBanks(false);
  };

  const setPopularBanks = () => {
    setBankList(popularBankList);
    setShowOtherBanks(true);
  };

  useEffect(() => {
    return popularBankList.length > 0 ? setPopularBanks() : setOtherBanks();
  }, [popularBankList]);

  interface GeneratePaymentTypes {
    providerVal: PaymentProvider;
    bankId?: string | undefined;
  }

  const generateLink = async ({
    providerVal,
    bankId,
  }: GeneratePaymentTypes) => {
    fetchPublicPaymentUrl({
      payment_id: payment_id!,
      provider: providerVal,
      bank_id: bankId || undefined,
    });
  };

  const onCardPayment = async () => {
    await generateLink({ providerVal: PaymentProvider.STRIPE });
    setStep(3);
    setPaymentMethod(PaymentProvider.STRIPE);
  };

  const continueToBank = () => {
    fetchYapilyBankList(publicPayment?.bankAccountDetails!.country || "");
    setStep(2);
  };

  const onBankPayment = async (bankId: string) => {
    await generateLink({
      providerVal: PaymentProvider.YAPILY,
      bankId,
    });
    setStep(3);
    setPaymentMethod(PaymentProvider.YAPILY);
  };

  const continueToPayment = () => {
    window.location.assign(paymentUrl!.url);
  };

  const showRequestOrStatus = () => {
    if (publicPayment?.status === PaymentStatus.OUTSTANDING) {
      return (
        <PaymentRequestArea
          onClickBank={continueToBank}
          merchantName={publicPayment?.merchantName}
          amount={publicPayment?.amount}
          currency={publicPayment?.currency.toUpperCase()}
          invoiceUrl={publicPayment?.invoice?.url}
          description={publicPayment?.description}
          hasInvoice={publicPayment?.invoice !== null}
          displayBankButton={publicPayment?.acceptsBankTransfer()}
          displayCardButton={publicPayment?.acceptsCardPayment()}
          paymentId={publicPayment.id}
          setIsRedirecting={setIsRedirecting}
        />
      );
    }

    return (
      <FinalPaymentPage
        onClick={() => window.close()}
        amount={publicPayment?.amount}
        currency={publicPayment?.currency.toUpperCase()}
        status={publicPayment?.status}
        merchantName={publicPayment?.merchantName}
        invoiceUrl={publicPayment?.invoice?.url}
      />
    );
  };

  const renderStep = () => {
    switch (step) {
      case 1:
        return <>{showRequestOrStatus()}</>;
      case 2:
        return (
          <>
            <Text fontWeight={700} fontSize="20px" align="center" mb="33px">
              Select a bank
            </Text>
            <VStack divider={<Divider />} w="100%">
              {bankList.map((bank) => {
                return (
                  <BankChoice
                    onClick={() => {
                      onBankPayment(bank.bank_id);
                      setSelectedBank(bank);
                    }}
                    icon={bank.bank_media[1].source}
                    iconDesc={bank.bank_media[1].type}
                    bankName={bank.bank_full_name}
                  />
                );
              })}
              {showOtherBanks && (
                <BankListItem
                  icon={bankIcon}
                  imgAlt="other banks"
                  bankName="All other banks"
                  onClick={setOtherBanks}
                />
              )}
            </VStack>
          </>
        );
      case 3:
        return (
          <ConsentScreen
            onContinue={continueToPayment}
            paymentMethod={paymentMethod}
            amount={publicPayment?.amount}
            currency={publicPayment?.currency.toUpperCase()}
            accountHolderName={
              paymentMethod === PaymentProvider.YAPILY
                ? publicPayment?.bankAccountDetails?.accountHolderName
                : publicPayment?.merchantName
            }
            selectedBank={selectedBank?.bank_name}
            invoiceUrl={publicPayment?.invoice?.url}
            hasInvoice={publicPayment?.invoice !== null}
          />
        );
      case 4:
        return <>{showRequestOrStatus()}</>;

      default:
        return null;
    }
  };

  if (loading || isRedirecting) {
    return <Spinner />;
  }

  return (
    <Box
      height="100%"
      width="100%"
      backgroundColor={colorMode === "dark" ? "gray.900" : "gray.50"}
      py={isBaseSize ? 0 : 4}
      overflowY="auto"
    >
      <Box
        minHeight="660px"
        width="792px"
        mx="auto"
        borderRadius="3xl"
        backgroundColor={colorMode === "dark" ? "gray.900" : "white"}
        p={4}
        __css={{
          "@media screen and (max-width: 1030px)": {
            height: "100%",
            width: "100%",
            margin: "0",
            borderRadius: "0",
          },
        }}
      >
        {step !== 1 && (
          <Button
            justifyContent="center"
            aria-label="back"
            variant="unstyled"
            onClick={() => setStep(step - 1)}
          >
            <ChakraIcon as={BackArrow} />
          </Button>
        )}

        <Box mb="3rem">
          {publicPayment?.logo ? (
            <Image
              margin="auto"
              src={publicPayment.logo}
              alt="Logo"
              maxHeight={isBaseSize ? "6rem" : "10rem"}
              maxWidth={isBaseSize ? "6rem" : "10rem"}
              borderRadius="md"
            />
          ) : (
            <Image
              margin="auto"
              src={FuzeyLogo}
              alt="Logo"
              maxHeight={isBaseSize ? "6rem" : "10rem"}
              maxWidth={isBaseSize ? "6rem" : "10rem"}
              borderRadius="md"
            />
          )}
        </Box>
        {renderStep()}
      </Box>
    </Box>
  );
};

export default ExternalPayment;
