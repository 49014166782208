import React from "react";
import { Button, Flex, Link, Text } from "@chakra-ui/react";

export default function PoweredByFooter() {
  return (
    <Flex justify="center">
      <Text>
        Powered by{" "}
        <Link
          href={process.env.REACT_APP_FUZEY_WEBSITE}
          fontWeight="bold"
          isExternal
        >
          Fuzey
        </Link>
      </Text>
    </Flex>
  );
}
