import { useCallback } from "react";
import { ConversationChannel } from "entities/domain/conversations/conversation-domain";
import mixpanel from "mixpanel-browser";
import { useAppSelector } from "redux/hooks";

export interface TrackAnalyticsPayload {
  campaign_action?: string;
  campaign_id?: string | null;
  conversation_id?: number | null;
  channel?: ConversationChannel;
  unread_count?: number;
  contains_files?: boolean;
  customer_id?: number;
  template_id?: string;
  audience_id?: string | null;
  assigned_agent_id?: number | null;
  assigned_team_id?: string | null;
  automation_id?: string;
}

export default function useAnalytics() {
  const { currentAgent } = useAppSelector((state) => state.agents);
  const { merchant } = useAppSelector((state) => state.merchant);

  const init = () =>
    mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN as string, {
      debug: true,
    });

  const track = useCallback(
    (eventName: string, payload?: TrackAnalyticsPayload) => {
      if (!currentAgent || !merchant) {
        return;
      }

      mixpanel.track(eventName, {
        "user-agent": navigator.userAgent,
        merchant_id: merchant.id,
        agent_id: currentAgent.id,
        distinct_id: `merchant:${merchant.id}`,
        motive: window.location?.pathname
          ? window.location.pathname
              .split("/")
              .filter((part) => part && /[a-z]+/.test(part.toLowerCase()))
              .join("_")
          : "n/a",
        ...(payload || {}),
      });
    },
    [currentAgent, merchant]
  );

  return { track, init };
}
