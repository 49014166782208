import React from "react";
import { useAppSelector } from "redux/hooks";
import { VStack } from "@chakra-ui/layout";
import ConversationListTopArea from "../shared/top-area";
import MobileBulkActions from "./MobileBulkActions";
import MobileTabsArea from "./MobileTabsArea";

interface MobileConversationsListAreaProps {}

const MobileConversationsListArea = (
  _props: MobileConversationsListAreaProps
) => {
  const { activeConversationId, selectedConversationIds } = useAppSelector(
    (state) => state.conversations
  );

  return activeConversationId ? null : (
    <VStack spacing={0} h="100%" w="100%" overflow="hidden">
      {!selectedConversationIds.length ? <ConversationListTopArea /> : null}
      {selectedConversationIds.length ? <MobileBulkActions /> : null}
      <MobileTabsArea />
    </VStack>
  );
};

export default MobileConversationsListArea;
