/* eslint-disable import/prefer-default-export */

import PaymentDomain, {
  PaymentMethod,
  PaymentStatus,
} from "entities/domain/payments/payment-domain";
import { PaymentDto } from "entities/dto/PaymentDto";

export const paymentTransformFromDtoToDomain = (
  payment: PaymentDto
): PaymentDomain =>
  new PaymentDomain(
    payment.id,
    payment.amount,
    payment.currency,
    payment.customer_id,
    payment.customer_name,
    payment.created_at,
    payment.paid_at,
    payment.due_at,
    payment.status as PaymentStatus,
    payment.description,
    payment.payment_method as PaymentMethod,
    payment.invoice_url
  );
