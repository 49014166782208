import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useBreakpointValue,
  VStack,
} from "@chakra-ui/react";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import AttributeService from "services/attributes";
import { useAuth0 } from "@auth0/auth0-react";
import { updateAttributeSuccess } from "redux/features/attributes";
import React, { useCallback, useState } from "react";
import { AxiosError } from "axios";
import AttributeTypeSelect from "./attribute-type-select";

interface EditAttributeProps {}

const EditContactAttribute = (_props: EditAttributeProps) => {
  const auth0Context = useAuth0();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const isBaseSize = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );
  const { colorScheme } = useAppSelector((state) => state.theme);

  const { merchant } = useAppSelector((state) => state.merchant);

  const { attributeId: attributeIdParam } = useParams<{
    attributeId: string;
  }>();
  const { attributes } = useAppSelector((state) => state.attributes);
  const attributeToShow = attributes.find((a) => a.id === attributeIdParam);

  const [attributeName, setAttributeName] = useState(
    attributeToShow?.name || ""
  );

  const [nameErrorMessage, setNameErrorMessage] = useState<string>("");

  const updateAttribute = useCallback(async () => {
    if (attributeToShow && attributeName) {
      const updatedAttribute = await AttributeService.updateAttribute(
        auth0Context,
        merchant.groupId,
        attributeToShow.id,
        {
          name: attributeName,
        }
      );
      dispatch(updateAttributeSuccess(updatedAttribute));
    }
  }, [attributeToShow?.id, merchant.groupId, attributeName]);

  const onClose = useCallback(() => {
    navigate({
      pathname: `/${merchant.id}/settings/attributes`,
    });
  }, []);

  const onClick = useCallback(async () => {
    const isNameValid = attributeName !== "";

    if (!isNameValid) {
      setNameErrorMessage(isNameValid ? "Name is required." : "");

      return;
    }

    try {
      await updateAttribute();

      setNameErrorMessage("");

      onClose();
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        if (error.response?.status === 409) {
          setNameErrorMessage(error.response.data.message);
        } else if (error.response?.status === 404) {
          setNameErrorMessage(
            "The attribute you are trying to update is not found in our records. Please contact support."
          );
        }
      } else {
        setNameErrorMessage(
          "An error occured. Could not create the attribute."
        );
      }
    }
  }, [attributeName, updateAttribute]);

  return (
    <Modal size={isBaseSize ? "full" : "2xl"} isOpen={true} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Edit Attribute</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack width="100%" spacing={4}>
            <FormControl isInvalid={nameErrorMessage !== ""}>
              <FormLabel>Name</FormLabel>
              <Input
                colorScheme={colorScheme}
                placeholder="Enter new attribute name"
                value={attributeName}
                onChange={(e) => setAttributeName(e.target.value)}
              />
              <FormErrorMessage>{nameErrorMessage}</FormErrorMessage>
            </FormControl>

            <FormControl>
              <FormLabel>Data Type</FormLabel>
              <AttributeTypeSelect
                attributeTypeToShow={attributeToShow?.type}
                isDisabled={true}
              />
            </FormControl>
          </VStack>
        </ModalBody>
        <ModalFooter>
          <Button onClick={onClick}>Save</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default EditContactAttribute;
