import InventoryVehicleDomain, {
  AvailabilityStatus,
  VehicleCondition,
} from "entities/domain/inventory-vehicle";
import { InventoryVehicleDTO } from "entities/dto/InventoryVehicleDTO";

export const inventoryVehicleTransformFromDtoToDomain = (
  vehicle: InventoryVehicleDTO
): InventoryVehicleDomain =>
  new InventoryVehicleDomain(
    vehicle.id,
    vehicle.availability_status as AvailabilityStatus,
    vehicle.condition as VehicleCondition,
    vehicle.vin,
    vehicle.license_plate,
    vehicle.chassis,
    vehicle.engine_number,
    vehicle.class,
    vehicle.make,
    vehicle.model,
    vehicle.variant,
    vehicle.year,
    vehicle.gross_price,
    vehicle.discount,
    vehicle.currency_code,
    vehicle.description,
    vehicle.interior_color,
    vehicle.exterior_color
  );
