import { useAuth0 } from "@auth0/auth0-react";
import { useCallback } from "react";
import AgentsService, { EditAgentPayload } from "services/agents";
import {
  fetchAgents,
  fetchAgentsFail,
  fetchAgentsSuccess,
  updateAgent,
} from "redux/features/agents";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import AgentDomain from "entities/domain/agents/agent-domain";

export default function useAgentsStore() {
  const dispatch = useAppDispatch();
  const auth0Context = useAuth0();
  const { merchant } = useAppSelector((state) => state.merchant);

  const fetchAgentsWaterfall = () => async () => {
    try {
      dispatch(fetchAgents());

      const agentsResponse = await AgentsService.getAgents(
        auth0Context,
        merchant.id
      );

      dispatch(fetchAgentsSuccess(agentsResponse));
    } catch (err) {
      dispatch(
        fetchAgentsFail(["Oops. We're not able to get agents from our server"])
      );
    }
  };

  const updateAgentWaterfall =
    (payload: EditAgentPayload) =>
    async (): Promise<AgentDomain | undefined> => {
      try {
        const agent = await AgentsService.updateAgent(
          auth0Context,
          payload,
          merchant.id
        );

        dispatch(updateAgent(agent));

        return agent;
      } catch (err: any) {
        return undefined;
      }
    };

  const updateAgentAsync = useCallback(
    (payload: EditAgentPayload) => updateAgentWaterfall(payload)(),
    [dispatch, merchant]
  );

  const fetchAgentsAsync = useCallback(
    () => dispatch(fetchAgentsWaterfall()),
    [dispatch, merchant.id]
  );

  return {
    fetchAgents: fetchAgentsAsync,
    updateAgent: updateAgentAsync,
  };
}
