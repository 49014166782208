import { Flex, Text } from "@chakra-ui/react";
import {
  Color,
  Icon,
  IconType,
} from "theme/old-design-system/styled-components";
import React from "react";

interface FileAttachmentProps {
  file?: File;
  url?: string;
  handleRemove?: () => void;
}

const FileAttachment = ({ file, url, handleRemove }: FileAttachmentProps) => {
  if (!file && !url) {
    return null;
  }

  return (
    <Flex
      borderRadius="1rem"
      border={`1px solid ${Color.LIGHT_GREY.value}`}
      paddingLeft="0.5rem"
      paddingRight="0.5rem"
      display="inline-flex"
      width="fit-content"
      mb={2}
    >
      <Text>{file?.name || url}</Text>
      {typeof handleRemove !== "undefined" && (
        <button type="button" onClick={handleRemove}>
          <Icon
            type={IconType.CLOSE}
            pathFill={Color.SMOKY_GREY}
            ml={5}
            height={12}
            width={12}
          />
        </button>
      )}
    </Flex>
  );
};

export default FileAttachment;
