import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import PaymentDomain from "entities/domain/payments/payment-domain";
import { RootState } from "redux/store";

export interface UpdatePaymentPayload {
  id: any;
  status: string | undefined;
  payment_method: string | undefined;
}

interface PaymentState {
  loading: boolean;
  errors: any[];
  payments: PaymentDomain[];
  selectedPaymentId: string | undefined;
}

const initialState: PaymentState = {
  loading: true,
  errors: [],
  payments: [],
  selectedPaymentId: undefined,
};

const paymentsSlice = createSlice({
  name: "payments",
  initialState,
  reducers: {
    fetchPayments(state) {
      state.loading = true;
    },
    fetchPaymentsSuccess(state, action: PayloadAction<PaymentDomain[]>) {
      state.loading = false;
      state.payments = action.payload;
      state.errors = [];
    },
    fetchPaymentsFail(state, action: PayloadAction<string[]>) {
      state.loading = false;
      state.errors = action.payload;
    },
    setSelectedPayment(state, action: PayloadAction<string | undefined>) {
      state.selectedPaymentId = action.payload;
    },
    updatePayment(state) {
      state.loading = true;
    },
    updatePaymentSuccess(state, action: PayloadAction<PaymentDomain>) {
      state.loading = false;
      state.payments = state.payments.map((payment) =>
        payment.id === action.payload.id ? action.payload : payment
      );
      state.errors = [];
    },
    updatePaymentFail(state, action: PayloadAction<string[]>) {
      state.loading = false;
      state.errors = action.payload;
    },
    resetLoadingState(state) {
      state.loading = true;
    },
    resetStore(state) {
      state.loading = true;
      state.errors = [];
      state.payments = [];
      state.selectedPaymentId = undefined;
    },
  },
});

export const paymentsSelector = (state: RootState) => state.payments;

export const selectPayment = (
  paymentId: string | undefined,
  payments: PaymentDomain[]
): PaymentDomain | undefined => {
  if (!paymentId || !payments) return undefined;
  return payments.find((pay) => pay.id === paymentId);
};

export const {
  fetchPayments,
  fetchPaymentsSuccess,
  fetchPaymentsFail,
  setSelectedPayment,
  updatePayment,
  updatePaymentSuccess,
  updatePaymentFail,
  resetLoadingState,
  resetStore,
} = paymentsSlice.actions;

export default paymentsSlice.reducer;
