import { useColorMode } from "@chakra-ui/color-mode";
import { Box } from "@chakra-ui/layout";
import { ChakraProps } from "@chakra-ui/system";
import FileDomain from "entities/domain/file";
import React, { useEffect, useState } from "react";
import { useAppSelector } from "redux/hooks";

interface SelectableFileProps {
  children: React.ReactNode;
  file: FileDomain | null;
  isSelected: boolean;
  select?: (file: FileDomain | null) => void;
}

const DEFAULT_BORDER_STYLES = {
  border: "4px solid",
  borderColor: "transparent",
  borderRadius: "xl",
};

const SelectableFile: React.FC<SelectableFileProps> = ({
  children,
  isSelected,
  file,
  select,
}) => {
  const { colorMode } = useColorMode();
  const { colorScheme } = useAppSelector((state) => state.theme);

  const [borderStyles, setBorderStyles] = useState<ChakraProps>(
    DEFAULT_BORDER_STYLES
  );

  useEffect(() => {
    const newBorderStyles = { ...DEFAULT_BORDER_STYLES };

    if (isSelected) {
      newBorderStyles.borderColor =
        colorMode === "dark" ? `${colorScheme}.200` : `${colorScheme}.500`;
    }

    setBorderStyles(newBorderStyles);
  }, [isSelected, colorMode, colorScheme]);

  return (
    <Box
      flexShrink={0}
      onClick={() => {
        if (typeof select === "undefined") {
          return;
        }

        if (isSelected) {
          select(null);
          return;
        }

        select(file);
      }}
      cursor="pointer"
      {...borderStyles}
    >
      {children}
    </Box>
  );
};

export default SelectableFile;
