import { VStack, Flex, HStack } from "@chakra-ui/layout";
import { useColorMode } from "@chakra-ui/react";
import React from "react";
import ConversationListTopArea from "../shared/top-area";
import Onboarding from "../shared/onboarding";
import DesktopTabsArea from "./DesktopTabsArea";

interface DesktopChatProps {}

const DesktopChat: React.FC<DesktopChatProps> = () => {
  const { colorMode } = useColorMode();

  return (
    <VStack
      spacing={0}
      w="100%"
      h="100%"
      bgColor={colorMode === "dark" ? "gray.700" : "gray.100"}
    >
      <HStack alignItems="center" justifyContent="start" w="100%">
        <ConversationListTopArea />
      </HStack>
      <Flex w="100%" h="100%" overflow="hidden" pr={4}>
        <DesktopTabsArea />
      </Flex>
      <Onboarding />
    </VStack>
  );
};

export default DesktopChat;
