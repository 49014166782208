import { Flex, Spinner as ChakraSpinner } from "@chakra-ui/react";
import React from "react";

interface SpinnerProps {
  color?: string;
  size?: number;
}

const Spinner = ({ color = "#000", size = 60 }: SpinnerProps) => (
  <Flex
    data-testid="spinner"
    alignItems="center"
    justifyContent="center"
    margin="auto"
    height="100%"
  >
    <ChakraSpinner />
  </Flex>
);

export default Spinner;
