import { format } from "date-fns";
import { WorkingHoursTimeDTO } from "entities/dto/WorkingHoursDTO";

export class WorkingHoursDayDomain {
  open: WorkingHoursTimeDTO;
  close: WorkingHoursTimeDTO;

  constructor(open: WorkingHoursTimeDTO, close: WorkingHoursTimeDTO) {
    this.open = open;
    this.close = close;
  }

  getDate(workingHoursTime: WorkingHoursTimeDTO): Date {
    const date = new Date();

    date.setHours(workingHoursTime.hour);
    date.setMinutes(workingHoursTime.minute);

    return date;
  }

  getOpenTime(): string {
    return format(this.getDate(this.open), "HH:mm");
  }

  getCloseTime(): string {
    return format(this.getDate(this.close), "HH:mm");
  }
}
