/* eslint-disable import/prefer-default-export */
import YapilyBankDomain from "entities/domain/public/payments/yapily/yapilyBank";
import YapilyBankMediaDomain from "entities/domain/public/payments/yapily/yapilyBankMedia";
import { BankDTO, BankMediaDTO } from "entities/dto/YapilyBankDTO";

const bankMediaTransformFromDtoToDomain = (
  dto: BankMediaDTO
): YapilyBankMediaDomain => new YapilyBankMediaDomain(dto.type, dto.source);

export const bankTransformFromDtoToDomain = (
  data: BankDTO
): YapilyBankDomain => {
  const bankMedia = data.bank_media.map((media) =>
    bankMediaTransformFromDtoToDomain(media)
  );

  return new YapilyBankDomain(
    data.bank_id,
    data.bank_name,
    data.bank_full_name,
    bankMedia,
    data.popular
  );
};
