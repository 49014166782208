import { Button, Heading, Icon, Link, Text, useToast } from "@chakra-ui/react";
import Integration from "components/user-settings/shared/Integration";
import IntegrationDescription from "components/user-settings/shared/IntegrationDescription";
import React, { useEffect, useState } from "react";
import { FaPlus } from "react-icons/fa";
import { ReactComponent as GmailIcon } from "assets/icons/gmail.svg";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { useAuth0 } from "@auth0/auth0-react";
import IntegrationService from "services/integrations";
import { updateMerchantSuccess } from "redux/features/merchant";

import { MerchantUserGuides } from "entities/domain/admin/merchants/merchant-user-guides";
import DisconnectIntegrationButton from "components/user-settings/shared/DisconnectIntegrationButton";
import { openGmailIntegration } from "components/google";
import { isMobileApp } from "util/methods";
import { IntegrationName } from "entities/domain/admin/merchants/merchant-integrations";

interface GmailIntegrationItemProps {}

const GmailIntegrationItem = (_props: GmailIntegrationItemProps) => {
  const dispatch = useAppDispatch();
  const auth0Context = useAuth0();
  const merchant = useAppSelector((state) => state.merchant.merchant);
  const toast = useToast();
  const [isDisabled, setIsDisabled] = useState<boolean>(false);
  const { colorScheme } = useAppSelector((state) => state.theme);

  const isConnected = merchant.isIntegrationEnabled(IntegrationName.GMAIL);

  const onDisconnectGmail = async () => {
    try {
      await IntegrationService.removeGmailIntegration(
        auth0Context,
        merchant.id
      );

      merchant.integrations[IntegrationName.GMAIL].connected = false;
      dispatch(updateMerchantSuccess(merchant));
    } catch (_error) {
      toast({
        status: "error",
        title:
          "Uh Oh! We could not disconnect Gmail at this time. Please try again",
      });
    }
  };

  useEffect(() => {
    if (merchant.userGuides.pending.includes(MerchantUserGuides.ONBOARDING)) {
      setIsDisabled(true);

      return;
    }

    setIsDisabled(false);
  }, [merchant.userGuides.pending]);

  return (
    <Integration
      icon={<Icon as={GmailIcon} height="3rem" width="3rem" />}
      isConnected={isConnected}
      secondaryButton={
        isConnected && !isDisabled ? (
          <DisconnectIntegrationButton onClick={onDisconnectGmail} />
        ) : null
      }
      primaryButton={
        isConnected || isDisabled ? null : (
          <Button
            leftIcon={<Icon as={FaPlus} />}
            colorScheme={colorScheme}
            onClick={() => {
              if (isMobileApp()) {
                window.natively.openExternalURL(
                  openGmailIntegration(merchant.id)
                );
              } else {
                window.open(openGmailIntegration(merchant.id), "_blank");
              }
            }}
          >
            Connect
          </Button>
        )
      }
    >
      <Heading as="h3" size="lg">
        Gmail
      </Heading>
      <IntegrationDescription
        text={
          isConnected ? (
            <Text>
              Fuzey’s use and transfer of information received from Google APIs
              to any other app will adhere to{" "}
              <Link
                href="https://developers.google.com/terms/api-services-user-data-policy#additional_requirements_for_specific_api_scopes"
                target="_blank"
              >
                Google API Services User Data Policy
              </Link>
              , including the Limited Use requirements.
            </Text>
          ) : (
            "Simplify email handling and improve workflow with direct access and management."
          )
        }
      />
    </Integration>
  );
};

export default GmailIntegrationItem;
