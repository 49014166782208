import React, { useEffect, useState } from "react";
import {
  Button,
  Checkbox,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { WidgetCustomChannelDomain } from "entities/domain/widget";
import ChannelDomain from "entities/domain/contacts/contact-domain";

type CustomChannelsEditProps = {
  isOpen: boolean;
  onClose: () => void;
  onSave: (customChannels: WidgetCustomChannelDomain[]) => void;
  widgetCustomChannels?: WidgetCustomChannelDomain[];
  channelsAvailable?: ChannelDomain[];
};

const CustomChannelsEdit = ({
  isOpen,
  onClose,
  onSave,
  widgetCustomChannels,
  channelsAvailable,
}: CustomChannelsEditProps) => {
  const [editingCustomChannels, setEditingCustomChannels] = useState(
    new Map<string, WidgetCustomChannelDomain>()
  );
  const [availableChannelsByType, setAvailableChannelsByType] = useState(
    new Map<string, ChannelDomain[]>()
  );

  useEffect(() => {
    if (channelsAvailable) {
      const availableChannelsMapping = channelsAvailable.reduce(
        (acc, channel) => {
          if (acc.get(channel.channelName)) {
            acc.set(channel.channelName, [
              ...acc.get(channel.channelName)!,
              channel,
            ]);
          } else {
            acc.set(channel.channelName, [channel]);
          }
          return acc;
        },
        new Map<string, ChannelDomain[]>()
      );

      const existingConfigByType =
        widgetCustomChannels?.reduce((acc, channel) => {
          if (!acc.get(channel.type)) {
            acc.set(channel.type, { ...channel });
          }
          return acc;
        }, new Map<string, WidgetCustomChannelDomain>()) ||
        new Map<string, WidgetCustomChannelDomain>();

      availableChannelsMapping.forEach((channel, type) => {
        if (!existingConfigByType?.get(type)) {
          existingConfigByType.set(type, { type, disabled: false });
        }
      });

      setEditingCustomChannels(existingConfigByType);
      setAvailableChannelsByType(availableChannelsMapping);
    }
  }, [channelsAvailable, widgetCustomChannels]);

  const handleChannelStatusChange = (channelName: string, checked: boolean) => {
    if (editingCustomChannels.get(channelName)) {
      const updatedMap = new Map(editingCustomChannels);

      updatedMap.set(channelName, {
        type: channelName,
        disabled: checked,
        handle: undefined,
      });

      setEditingCustomChannels(updatedMap);
    }
  };

  const handleChannelHandleChange = (
    channelName: string,
    handle: string,
    checked: boolean
  ) => {
    if (editingCustomChannels.get(channelName)) {
      const updatedMap = new Map(editingCustomChannels);

      updatedMap.set(channelName, {
        type: channelName,
        disabled: false,
        handle: checked ? handle : undefined,
      });
      setEditingCustomChannels(updatedMap);
    }
  };

  const handleSave = () => {
    const filteredCustomChannel = Array.from(
      editingCustomChannels.values()
    ).filter((channel) => channel.disabled || channel.handle);

    onSave(filteredCustomChannel);
    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="lg">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Widget Custom Channels</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {availableChannelsByType && editingCustomChannels && (
            <Table variant="striped">
              <Thead>
                <Tr>
                  <Th>Channel</Th>
                  <Th>Disabled</Th>
                  <Th>Handle / Default</Th>
                </Tr>
              </Thead>
              <Tbody>
                {Array.from(availableChannelsByType).map(([key, channels]) => (
                  <Tr key={key}>
                    <Td>{key}</Td>
                    <Td>
                      <Checkbox
                        value="true"
                        defaultChecked={
                          editingCustomChannels.get(key)?.disabled === true
                        }
                        isChecked={
                          editingCustomChannels.get(key)?.disabled === true
                        }
                        onChange={(e) =>
                          handleChannelStatusChange(key, e.target.checked)
                        }
                      />
                    </Td>
                    <Td>
                      {channels.map((channel) => (
                        <HStack justifyContent="space-between" key={channel.id}>
                          <Text isTruncated maxWidth="200px">
                            {channel.identifier}
                          </Text>
                          <Checkbox
                            value="true"
                            defaultChecked={
                              editingCustomChannels.get(key)?.handle ===
                              channel.identifier
                            }
                            disabled={editingCustomChannels.get(key)?.disabled}
                            isChecked={
                              editingCustomChannels.get(key)?.handle ===
                              channel.identifier
                            }
                            onChange={(e) =>
                              handleChannelHandleChange(
                                key,
                                channel.identifier,
                                e.target.checked
                              )
                            }
                          />
                        </HStack>
                      ))}
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          )}
        </ModalBody>

        <ModalFooter>
          <Button colorScheme="blue" mr={3} onClick={() => handleSave()}>
            Save
          </Button>
          <Button variant="ghost" onClick={onClose}>
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default CustomChannelsEdit;
