import useContactsStore from "hooks/use-contacts-store";
import React, { useEffect, useState } from "react";
import {
  createSearchParams,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import ContactDomain from "entities/domain/customers/contact-domain";
import { useAppSelector } from "redux/hooks";
import ContactsService from "services/contacts";
import { useAuth0 } from "@auth0/auth0-react";
import CustomerChannelDomain from "entities/domain/customers/contact-channel-domain";
import ContactCard from ".";

const EditContactPage = () => {
  const navigate = useNavigate();
  const auth0Context = useAuth0();
  const { merchant } = useAppSelector((state) => state.merchant);
  const { contactId } = useParams<{ contactId: string }>();
  const { getContactTags, editContact } = useContactsStore();
  const { search } = useLocation();

  const [contactToEdit, setContactToEdit] = useState<
    ContactDomain | undefined
  >();

  const [isSaving, setIsSaving] = useState<boolean>(false);

  useEffect(() => {
    if (!contactId) {
      return;
    }

    ContactsService.getContact(
      auth0Context,
      Number(contactId),
      merchant.groupId
    ).then((contact) => {
      if (!contact) {
        return;
      }

      setContactToEdit(contact);
    });
  }, [contactId, merchant.groupId]);

  const handleSave = async (editedContact: ContactDomain) => {
    if (isSaving) {
      return;
    }

    setIsSaving(true);

    const editContactResponse = await editContact({
      id: Number(editedContact.id),
      name: editedContact.name || undefined,
      surname: editedContact.surname || undefined,
      tags: getContactTags(editedContact).map((tag) => tag.tag),
      tagIds: editedContact.tagIds,
      address: editedContact.address
        ? {
            first_line: editedContact.address.firstLine,
            second_line: editedContact.address.secondLine,
            city: editedContact.address.city,
            country: editedContact.address.country,
            state: editedContact.address.state,
            postcode: editedContact.address.postcode,
          }
        : undefined,
      channels: editedContact.channels.map(
        (channel: CustomerChannelDomain) => ({
          id: channel.id,
          type: channel.type,
          handle: channel.handle,
          is_active: channel.isActive,
          is_subscribed: channel.isSubscribed,
        })
      ),
      notes: editedContact.notes,
    });

    setIsSaving(false);

    if (editContactResponse) {
      navigate({
        pathname: `/${merchant.id}/contacts/${editContactResponse.id}`,
        search: createSearchParams(search).toString(),
      });
    }
  };

  useEffect(() => {
    if (!contactToEdit) {
      return;
    }

    if (contactToEdit.isArchived) {
      navigate(`/${merchant.id}/contacts/${contactToEdit.id}`);
    }
  }, [contactToEdit]);

  return (
    <ContactCard
      title="Edit Contact"
      contact={contactToEdit}
      handleSave={handleSave}
      isSaving={isSaving}
    />
  );
};

export default EditContactPage;
