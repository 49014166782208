import {
  Box,
  Button,
  Icon,
  Stack,
  useBreakpointValue,
  useColorMode,
} from "@chakra-ui/react";
import { format, subMonths } from "date-fns";
import AgentDomain from "entities/domain/agents/agent-domain";
import ReportPerAgentDomain from "entities/domain/report-per-agent";
import React, { useEffect } from "react";
import ReactDatePicker from "react-datepicker";
import { FaFileExport } from "react-icons/fa";
import { useAppSelector } from "redux/hooks";
import { convertSecondsToHoursMinutesSeconds } from "util/methods";

interface DateFilterProps {
  isLoading: boolean;
  fromDate: Date | null;
  reports: ReportPerAgentDomain[];
  setFromDate: (date: Date | null) => void;
  toDate: Date | null;
  setToDate: (date: Date | null) => void;
}

function convertReportsToCSVString(
  reportsPerAgents: Array<{
    name: string;
    uniqueConversations: number | null;
    messagesSent: number | null;
    avgTimeToReplyInSeconds: string | null;
  }>
): string {
  const header = [
    "name",
    "conversations",
    "messages",
    "time to reply (hh:mm:ss)",
  ];
  const csvRows = [header.join(",")];

  for (const report of reportsPerAgents) {
    const row = [
      report.name,
      report.uniqueConversations !== null ? report.uniqueConversations : "",
      report.messagesSent !== null ? report.messagesSent : "",
      report.avgTimeToReplyInSeconds !== null
        ? report.avgTimeToReplyInSeconds
        : "",
    ];
    csvRows.push(row.join(","));
  }

  return csvRows.join("\n");
}

function createCSVBlob(csvString: string): Blob {
  return new Blob([csvString], { type: "text/csv" });
}

function createDownloadLink(blob: Blob, fileName: string) {
  const url = URL.createObjectURL(blob);
  const link = document.createElement("a");

  link.href = url;
  link.download = fileName;

  return link;
}

function exportCSV(
  reports: ReportPerAgentDomain[],
  agents: AgentDomain[],
  fromDate: Date,
  toDate: Date
) {
  const mappedReports = reports.map((r) => ({
    name: agents.find((agent) => agent.id === r.agentId)!.getFullName(),
    uniqueConversations: r.uniqueConversations,
    messagesSent: r.messagesSent,
    avgTimeToReplyInSeconds: r.avgTimeToReplyInSeconds
      ? convertSecondsToHoursMinutesSeconds(r.avgTimeToReplyInSeconds)
      : null,
  }));
  const csvString = convertReportsToCSVString(mappedReports);
  const blob = createCSVBlob(csvString);
  const link = createDownloadLink(
    blob,
    `fuzey_report_${format(fromDate, "yyyy-MM-dd")}_${format(
      toDate,
      "yyyy-MM-dd"
    )}.csv`
  );

  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

const DateFilter = ({
  isLoading,
  fromDate,
  toDate,
  reports,
  setFromDate,
  setToDate,
}: DateFilterProps) => {
  const { colorMode } = useColorMode();
  const isBaseSize = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );
  const { agents } = useAppSelector((state) => state.agents);

  return (
    <Stack
      py={isBaseSize ? 2 : 4}
      px={isBaseSize ? 4 : 0}
      direction={isBaseSize ? "column" : "row"}
      width={isBaseSize ? "100%" : "auto"}
    >
      <Box
        rounded="full"
        border="none"
        textAlign="left"
        bgColor={
          colorMode === "dark" ? "black" : isBaseSize ? "gray.50" : "white"
        }
        px={3}
        css={{
          input: {
            height: isBaseSize ? "2rem" : "2.5rem",
            width: "100%",
            backgroundColor: "inherit",
          },
          ".react-datepicker-wrapper": {
            width: "100%",
          },
        }}
      >
        <ReactDatePicker
          id="analytics-from-date"
          selected={fromDate}
          placeholderText="From"
          onChange={(newDate) => {
            setFromDate(newDate);
          }}
          maxDate={toDate || new Date()}
          minDate={subMonths(toDate || new Date(), 3)}
          showTimeSelect={false}
          dateFormat="yyyy-MM-dd"
        />
      </Box>
      <Box
        rounded="full"
        border="none"
        bgColor={
          colorMode === "dark" ? "black" : isBaseSize ? "gray.50" : "white"
        }
        textAlign="left"
        px={3}
        css={{
          input: {
            height: isBaseSize ? "2rem" : "2.5rem",
            width: "100%",
            backgroundColor: "inherit",
          },
          ".react-datepicker-wrapper": {
            width: "100%",
          },
        }}
      >
        <ReactDatePicker
          id="analytics-to-date"
          selected={toDate}
          placeholderText="Until"
          onChange={(newDate) => {
            setToDate(newDate);
          }}
          maxDate={new Date()}
          minDate={fromDate || subMonths(new Date(), 3)}
          showTimeSelect={false}
          dateFormat="yyyy-MM-dd"
        />
      </Box>
      {reports.length === 0 ? null : (
        <Button
          size={isBaseSize ? "sm" : "md"}
          isDisabled={!fromDate || !toDate || isLoading}
          leftIcon={<Icon as={FaFileExport} />}
          onClick={() => exportCSV(reports, agents, fromDate!, toDate!)}
          colorScheme="gray"
        >
          Download CSV
        </Button>
      )}
    </Stack>
  );
};

export default DateFilter;
