import { useBreakpointValue } from "@chakra-ui/react";
import React, { memo } from "react";
import { isAndroid, isIOSTablet, isMobileApp } from "util/methods";
import DekstopBrowserNavigationBar from "./DesktopBrowser";
import MobileApplicationNavigationBar from "./MobileApplication";

const NavigationBar = () => {
  const isBaseSize = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );
  const isApplication = isMobileApp();
  const isTablet = isIOSTablet() || (isAndroid() && !isBaseSize);

  if (isApplication && !isTablet) {
    return <MobileApplicationNavigationBar />;
  }

  return isBaseSize ? null : <DekstopBrowserNavigationBar />;
};

export default memo(NavigationBar);
