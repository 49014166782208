import { AddIcon, MinusIcon } from "@chakra-ui/icons";
import {
  AccordionButton,
  AccordionPanel,
  Flex,
  Spacer,
} from "@chakra-ui/react";
import React, { ReactNode } from "react";

interface ContactCardAccordionContentProps {
  sectionHeader: ReactNode;
  sectionContent: ReactNode;
  isExpanded: boolean;
}

const ContactCardAccordionContent = ({
  sectionHeader,
  sectionContent,
  isExpanded,
}: ContactCardAccordionContentProps) => {
  return (
    <>
      <Flex pt={4} alignItems="center" justifyContent="center">
        <AccordionButton
          _hover={{ backgroundColor: "transparent", opacity: 0.5 }}
        >
          <Spacer />
          {sectionHeader}
          <Spacer />
          {isExpanded ? (
            <MinusIcon fontSize="12px" />
          ) : (
            <AddIcon fontSize="12px" />
          )}
        </AccordionButton>
      </Flex>

      <AccordionPanel mb={6}>{sectionContent}</AccordionPanel>
    </>
  );
};

export default ContactCardAccordionContent;
