import { Box, useColorMode } from "@chakra-ui/react";
import { FieldComponentProps } from "components/shared/QueryBuilder/QueryCondition";
import { format } from "date-fns";
import React, { useEffect, useState } from "react";
import ReactDatePicker from "react-datepicker";
import { useAppSelector } from "redux/hooks";

const YearFieldInput = ({ value, id, onChange }: FieldComponentProps) => {
  const { colorScheme } = useAppSelector((state) => state.theme);
  const { colorMode } = useColorMode();

  const [selectedDate, setSelectedDate] = useState<Date | null>(
    value ? new Date(value) : null
  );

  useEffect(() => {
    onChange(selectedDate ? format(selectedDate, "yyyy") : "");
  }, [selectedDate]);

  return (
    <Box
      borderBottomWidth="1px"
      borderBottomColor={colorMode === "dark" ? "gray.500" : "gray.200"}
      _focus={{
        borderBottomColor:
          colorMode === "dark" ? `${colorScheme}.200` : `${colorScheme}.500`,
      }}
      textAlign="left"
      css={{
        input: {
          width: "100%",
          "::placeholder": {
            fontStyle: "italic",
          },
          backgroundColor: "inherit",
        },
        ".react-datepicker-wrapper": {
          width: "100%",
        },
      }}
    >
      <ReactDatePicker
        id={id}
        selected={selectedDate}
        placeholderText="Choose date"
        onChange={(newDate) => setSelectedDate(newDate)}
        showYearPicker={true}
        dateFormat="yyyy"
      />
    </Box>
  );
};

export default YearFieldInput;
