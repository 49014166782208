import { Text } from "@chakra-ui/react";
import { PreviewComponentProps } from "components/shared/QueryBuilder/QueryCondition";
import React from "react";
import { useAppSelector } from "redux/hooks";

const CampaignFieldPreview = ({ value }: PreviewComponentProps) => {
  const { campaigns } = useAppSelector((state) => state.campaigns);

  const campaignName = campaigns.find((c) => c.id === value)?.name;

  return <Text fontWeight="bold">{campaignName}</Text>;
};

export default CampaignFieldPreview;
