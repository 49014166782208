import {
  Flex,
  useBreakpointValue,
  useColorMode,
  useToast,
} from "@chakra-ui/react";
import Topbar from "components/shared/topbar/TopBar";
import React, { useEffect, useState } from "react";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import ReviewDomain from "entities/domain/reviews/review-domain";
import ReviewsService from "services/reviews";
import { useAuth0 } from "@auth0/auth0-react";
import { useWebSocket } from "hooks/use-socket";
import {
  ReviewDeletedMessage,
  ReviewUpdatedMessage,
} from "entities/ISocketArgs";
import { useAppSelector } from "redux/hooks";
import { reviewTransformDtoToDomain } from "entities/transformers/reviewTransformer";
import ReviewsList from "./ReviewsList";

const ReviewsPage = () => {
  const auth0Context = useAuth0();
  const { merchant } = useAppSelector((state) => state.merchant);
  const { reviewId } = useParams();
  const toast = useToast();
  const isBaseSize = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );
  const navigate = useNavigate();
  const { colorMode } = useColorMode();
  const { addEventHandler, removeEventHandler } = useWebSocket();

  const [selectedReview, setSelectedReview] = useState<
    ReviewDomain | undefined
  >();
  const [isReviewModalOpen, setIsReviewModalOpen] = useState<boolean>(false);
  const [reviewUpdate, setReviewUpdate] = useState<ReviewUpdatedMessage>();
  const [reviewDelete, setReviewDelete] = useState<ReviewDeletedMessage>();

  const sendReply = async (content: string, replyToReviewId: string) => {
    try {
      await ReviewsService.createReviewReply(
        auth0Context,
        {
          content,
          reviewId: replyToReviewId,
        },
        merchant.id
      );

      toast({ status: "success", title: "Reply sent successfully" });

      setSelectedReview(undefined);
      navigate(`/${merchant.id}/reviews`);
    } catch (err: unknown) {
      // eslint-disable-next-line
      console.error("Failed to send reply", err);
      toast({
        status: "error",
        title: "Couldn't send reply! Try again later...",
      });
    }
  };

  useEffect(() => {
    if (selectedReview) {
      setIsReviewModalOpen(true);
    }
  }, [selectedReview]);

  useEffect(() => {
    if (reviewId) {
      ReviewsService.getReview(auth0Context, merchant.id, reviewId)
        .then((review) => {
          setSelectedReview(review);
        })
        .catch((err: unknown) => {
          // eslint-disable-next-line
          console.error("Failed to fetch review", err);
          navigate(`/${merchant.id}/reviews`);
        });
    }
  }, [reviewId]);

  useEffect(() => {
    return () => {
      setSelectedReview(undefined);
      setIsReviewModalOpen(false);
    };
  }, [merchant.id]);

  const handleReviewUpdated = (args: ReviewUpdatedMessage) => {
    if (args.review.merchant_id !== merchant.id) {
      return;
    }

    setReviewUpdate(args);
  };

  const handleReviewDeleted = (args: ReviewDeletedMessage) => {
    if (args.review.merchant_id !== merchant.id) {
      return;
    }

    setReviewDelete(args);
  };

  useEffect(() => {
    addEventHandler("review_updated", handleReviewUpdated);
    addEventHandler("review_deleted", handleReviewDeleted);

    return () => {
      removeEventHandler("review_updated", handleReviewUpdated);
      removeEventHandler("review_deleted", handleReviewDeleted);
    };
  }, [addEventHandler, removeEventHandler]);

  useEffect(() => {
    if (reviewUpdate && Object.keys(reviewUpdate).length !== 0) {
      if (reviewUpdate.review.id !== selectedReview?.id) {
        return;
      }

      setSelectedReview(reviewTransformDtoToDomain(reviewUpdate.review));
    }
  }, [reviewUpdate]);

  useEffect(() => {
    if (!reviewDelete || reviewDelete.review.id !== selectedReview?.id) {
      return;
    }

    setSelectedReview(undefined);
    setIsReviewModalOpen(false);
    navigate(`/${merchant.id}/reviews`);
  }, [reviewDelete]);

  return (
    <Flex
      direction="column"
      position="relative"
      flex={1}
      width="100%"
      height="100%"
      {...(isBaseSize
        ? {}
        : {
            bgColor: colorMode === "dark" ? "gray.700" : "gray.100",
            pl: "2rem",
            pt: "2rem",
            pr: "1rem",
          })}
    >
      {isBaseSize ? <Topbar title="Reviews" /> : null}
      <Flex
        borderTopRadius={isBaseSize ? 0 : "3xl"}
        bgColor={colorMode === "dark" ? "gray.800" : "white"}
        w="100%"
        h="100%"
        overflowY="hidden"
        position="relative"
      >
        <ReviewsList />
        <Outlet
          context={{
            review: selectedReview,
            isOpen: isReviewModalOpen,
            onClose: () => {
              setIsReviewModalOpen(false);
              setSelectedReview(undefined);
              navigate(`/${merchant.id}/reviews`);
            },
            sendReply,
          }}
        />
      </Flex>
    </Flex>
  );
};

export default ReviewsPage;
