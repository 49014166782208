import {
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spacer,
  Switch,
  Text,
  useBreakpointValue,
  useColorMode,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useAppSelector } from "redux/hooks";
import NotificationDomain from "entities/domain/notifications/domain";
import useNotificationsStore from "hooks/use-notifications-store";
import NotificationItem from "./NotificationItem";

interface NotificationsModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const NotificationsModal = ({ isOpen, onClose }: NotificationsModalProps) => {
  const { colorScheme } = useAppSelector((state) => state.theme);
  const { loading: isLoadingMerchant } = useAppSelector(
    (state) => state.merchant
  );
  const { loading: isLoadingNotifications, notifications } = useAppSelector(
    (state) => state.notifications
  );
  const isBaseSize = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );
  const { colorMode } = useColorMode();
  const { markAllNotificationsAsRead } = useNotificationsStore();

  const [isUnreadOnly, setIsUnreadOnly] = useState<boolean>(false);
  const [filterNotifications, setFilterNotifications] = useState<
    NotificationDomain[]
  >([]);

  const handleMarkAllAsRead = () => {
    markAllNotificationsAsRead();
  };

  useEffect(() => {
    if (isUnreadOnly) {
      setFilterNotifications(
        notifications.filter((notification) => !notification.read)
      );
    } else {
      setFilterNotifications(notifications);
    }
  }, [notifications, isUnreadOnly]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} size={isBaseSize ? "full" : "md"}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Flex justifyContent="space-between" alignItems="center" width="100%">
            <Text fontSize="lg" fontWeight="bold">
              Notifications
            </Text>
            <Spacer />
            <Text fontSize="sm" mr={1}>
              Unreads
            </Text>
            <Switch
              size="sm"
              isChecked={isUnreadOnly}
              colorScheme={colorScheme}
              onChange={(e) => setIsUnreadOnly(e.target.checked)}
            />
          </Flex>
        </ModalHeader>
        <ModalBody maxH="60vh" overflowY="auto">
          {filterNotifications.length === 0 ? (
            <Text>No new notifications</Text>
          ) : (
            filterNotifications.map((notification) => (
              <NotificationItem
                key={notification.id}
                notification={notification}
              />
            ))
          )}
        </ModalBody>
        <ModalFooter display="flex" w="100%" justifyContent="space-between">
          <Button colorScheme={colorScheme} variant="ghost" onClick={onClose}>
            Close
          </Button>
          <Button
            mr={1}
            colorScheme={colorScheme}
            onClick={handleMarkAllAsRead}
          >
            Mark all as read
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default NotificationsModal;
