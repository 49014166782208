import { Modal, ModalContent, ModalOverlay } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { ConversationChannel } from "entities/domain/conversations/conversation-domain";
import { useAppSelector } from "redux/hooks";
import UploadViaCsvOrIcloud from "./UploadViaFile";
import { ExtendedImportOptions } from "./ExtendedImportContactsTypes";

interface ImportContactsProps {
  isOpen: boolean;
  onClose: () => void;
}

const ImportContacts = ({ isOpen, onClose }: ImportContactsProps) => {
  const { merchant } = useAppSelector((state) => state.merchant);
  const [defaultChannels, setDefaultChannels] = useState<ConversationChannel[]>(
    [ConversationChannel.SMS]
  );

  useEffect(() => {
    if (merchant.isMerchantChannelEnabled(ConversationChannel.WHATSAPP)) {
      setDefaultChannels([
        ConversationChannel.SMS,
        ConversationChannel.WHATSAPP,
      ]);
    } else {
      setDefaultChannels([ConversationChannel.SMS]);
    }
  }, [merchant]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="lg">
      <ModalOverlay />
      <ModalContent p={3}>
        <UploadViaCsvOrIcloud
          defaultChannels={defaultChannels}
          uploadMethod={ExtendedImportOptions.CSV}
          onClose={() => {
            onClose();
            window.location.reload();
          }}
        />
      </ModalContent>
    </Modal>
  );
};

export default ImportContacts;
