import { useAuth0 } from "@auth0/auth0-react";
import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useBreakpointValue,
  VStack,
} from "@chakra-ui/react";
import { AttributeType } from "entities/domain/attributes/attribute-domain";
import React, { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { createAttributeSuccess } from "redux/features/attributes";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import AttributeService from "services/attributes";
import { AxiosError } from "axios";
import AttributeTypeSelect from "./attribute-type-select";

interface CreateAttributeProps {}

const CreateContactAttribute = (_props: CreateAttributeProps) => {
  const auth0Context = useAuth0();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const isBaseSize = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );
  const { colorScheme } = useAppSelector((state) => state.theme);

  const { merchant } = useAppSelector((state) => state.merchant);

  const [attributeName, setAttributeName] = useState<string>("");
  const [attributeType, setAttributeType] = useState<AttributeType | null>(
    null
  );

  const [typeSelectionErrorMessage, setTypeSelectionErrorMessage] =
    useState<string>("");
  const [nameErrorMessage, setNameErrorMessage] = useState<string>("");

  const createAttribute = useCallback(async () => {
    const createdAttribute = await AttributeService.createAttribute(
      auth0Context,
      merchant.groupId,
      {
        name: attributeName,
        type: attributeType!,
      }
    );
    dispatch(createAttributeSuccess(createdAttribute));
  }, [attributeName, attributeType, merchant.groupId]);

  const onClose = useCallback(() => {
    navigate({
      pathname: `/${merchant.id}/settings/attributes`,
    });
  }, []);

  const onClick = useCallback(async () => {
    const isNameValid = attributeName !== "";
    const isTypeValid = attributeType !== null;

    if (!isNameValid || !isTypeValid) {
      setNameErrorMessage(isNameValid ? "" : "Name is required.");
      setTypeSelectionErrorMessage(isTypeValid ? "" : "Type is required.");

      return;
    }

    try {
      await createAttribute();

      setNameErrorMessage("");
      setTypeSelectionErrorMessage("");

      onClose();
    } catch (error: unknown) {
      if (error instanceof AxiosError && error.response?.status === 409) {
        setNameErrorMessage(error.response.data.message);
      } else {
        setNameErrorMessage(
          "An error occured. Could not create the attribute."
        );
      }
    }
  }, [attributeName, attributeType, createAttribute]);

  return (
    <Modal size={isBaseSize ? "full" : "2xl"} isOpen={true} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Create Attribute</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack width="100%" spacing={4}>
            <FormControl isInvalid={nameErrorMessage !== ""}>
              <FormLabel>Name</FormLabel>
              <Input
                colorScheme={colorScheme}
                placeholder="Enter attribute name"
                value={attributeName}
                onChange={(e) => setAttributeName(e.target.value)}
              />
              <FormErrorMessage>{nameErrorMessage}</FormErrorMessage>
            </FormControl>

            <FormControl isInvalid={typeSelectionErrorMessage !== ""}>
              <FormLabel>Data Type</FormLabel>
              <AttributeTypeSelect
                attributeTypeToShow={attributeType}
                isDisabled={false}
                hasError={typeSelectionErrorMessage !== ""}
                onChange={(selectedOption: AttributeType | null) =>
                  setAttributeType(selectedOption)
                }
              />
              <FormErrorMessage>Type is required.</FormErrorMessage>
            </FormControl>
          </VStack>
        </ModalBody>
        <ModalFooter>
          <Button onClick={onClick}>Save</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default CreateContactAttribute;
