import { ChevronDownIcon } from "@chakra-ui/icons";
import { IconButton } from "@chakra-ui/react";
import { useAppSelector } from "redux/hooks";
import React, { forwardRef, ForwardRefRenderFunction } from "react";

interface UnreadProps {
  showGoToRecent?: boolean;
  goToRecent?: () => void;
}

const Unread: ForwardRefRenderFunction<HTMLButtonElement, UnreadProps> = (
  { showGoToRecent = false, goToRecent },
  ref
) => {
  const { colorScheme } = useAppSelector((state) => state.theme);

  return (
    <IconButton
      ref={ref}
      aria-label="Jump to recent messages"
      borderRadius="full"
      colorScheme={colorScheme}
      position="absolute"
      display={showGoToRecent ? "initial" : "none"}
      top={0}
      right={0}
      size="lg"
      zIndex={5}
      icon={<ChevronDownIcon />}
      onClick={(e) => {
        e.stopPropagation();
        goToRecent?.();
      }}
    />
  );
};

export default forwardRef(Unread);
