import {
  Flex,
  Text,
  Icon,
  Image,
  useColorMode,
  VStack,
  useBreakpointValue,
} from "@chakra-ui/react";
import React, { forwardRef, ForwardRefRenderFunction } from "react";
import MessageDomain, {
  MessageSubType,
} from "entities/domain/conversations/message-domain";
import { ReactComponent as EmailIcon } from "assets/icons/email.svg";
import { ReactComponent as NewLeadIcon } from "assets/icons/new-lead.svg";
import { ReactComponent as ExistingLeadAdditionalContactIcon } from "assets/icons/existing-lead-additional-contact.svg";
import { ReactComponent as BullhornOutlineIcon } from "assets/icons/bullhorn-outline.svg";
import { ReactComponent as DollarIcon } from "assets/icons/dollar-bills.svg";
import { ReactComponent as AccountRemove } from "assets/icons/account-remove.svg";
import { ReactComponent as AccountCheck } from "assets/icons/personal-conversations.svg";
import { useAppSelector } from "redux/hooks";

interface NotificationProps {
  message: MessageDomain;
}

const getIconByType = (messageSubType: MessageSubType) => {
  switch (messageSubType) {
    case MessageSubType.NEW_LEAD:
      return NewLeadIcon;
    case MessageSubType.EXISTING_LEAD_EXTRA_CHANNEL:
      return ExistingLeadAdditionalContactIcon;
    case MessageSubType.CAMPAIGN_MESSAGE:
      return BullhornOutlineIcon;
    case MessageSubType.PAYMENT_RECEIVED:
      return DollarIcon;
    case MessageSubType.NEW_SUBJECT_LINE:
    case MessageSubType.REGULAR:
    case MessageSubType.WEBSITE_ENQUIRY:
    case MessageSubType.FINISHED_CALL:
    case MessageSubType.MISSED_CALL:
    case MessageSubType.PAYMENT_REQUEST:
    case MessageSubType.REVIEW_REQUEST:
    case MessageSubType.NEW_AGENT:
    default:
      return EmailIcon;
  }
};

const Notification: ForwardRefRenderFunction<
  HTMLDivElement,
  NotificationProps
> = ({ message }, ref) => {
  const { agents, currentAgent } = useAppSelector((state) => state.agents);
  const isBaseSize = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );
  const { colorMode } = useColorMode();

  const icon = getIconByType(message.subType);

  const currentAgentBackground =
    currentAgent!.getChatBackgroundStyles(colorMode);
  let shouldGuaranteeReadability = false;

  if (
    currentAgentBackground &&
    currentAgentBackground.background &&
    typeof currentAgentBackground.background === "string" &&
    !currentAgentBackground.background.includes("gray")
  ) {
    shouldGuaranteeReadability = true;
  }

  return (
    <Flex
      direction="column"
      alignItems="center"
      ref={ref}
      width={isBaseSize ? "80%" : "30%"}
      mx="auto"
      my={4}
    >
      <Flex
        {...(shouldGuaranteeReadability
          ? {
              mb: "-0.75rem",
              zIndex: 1,
              p: 1,
              borderRadius: "full",
              backgroundColor: colorMode === "dark" ? "gray.700" : "gray.50",
            }
          : {})}
      >
        {message.subType === MessageSubType.NEW_AGENT ? (
          <Image
            src={
              message.agentId
                ? undefined
                : agents.find((a) => a.id === message.agentId)?.getPicture()
            }
            fallback={
              <Icon
                as={message.agentId ? AccountCheck : AccountRemove}
                fill={colorMode === "dark" ? "gray.50" : "gray.700"}
                boxSize="1.5rem"
              />
            }
          />
        ) : (
          <Icon
            as={icon}
            fill={colorMode === "dark" ? "gray.50" : "gray.700"}
            boxSize="1.5rem"
          />
        )}
      </Flex>
      <VStack textAlign="center" spacing={0}>
        <Text
          fontWeight={700}
          as="pre"
          fontFamily="inherit"
          whiteSpace="pre-wrap"
          color={colorMode === "dark" ? "gray.50" : "gray.700"}
          {...(shouldGuaranteeReadability
            ? {
                mb: -2,
                p: 2,
                borderRadius: "xl",
                backgroundColor: colorMode === "dark" ? "gray.700" : "gray.50",
                width: "fit-content",
              }
            : {})}
        >
          {message.title}
        </Text>
        <Text
          as="pre"
          fontFamily="inherit"
          whiteSpace="pre-wrap"
          fontSize="sm"
          color={colorMode === "dark" ? "gray.50" : "gray.700"}
          {...(shouldGuaranteeReadability
            ? {
                mb: -2,
                p: 2,
                borderRadius: "xl",
                backgroundColor: colorMode === "dark" ? "gray.700" : "gray.50",
                width: "fit-content",
              }
            : {})}
        >
          {message.body}
        </Text>
        <Text
          as="pre"
          fontFamily="inherit"
          whiteSpace="pre-wrap"
          color={colorMode === "dark" ? "gray.400" : "gray.500"}
          fontSize="xs"
          {...(shouldGuaranteeReadability
            ? {
                p: 2,
                borderRadius: "xl",
                backgroundColor: colorMode === "dark" ? "gray.700" : "gray.50",
                width: "fit-content",
              }
            : {})}
        >
          {message.getTimeStamp()}
        </Text>
      </VStack>
    </Flex>
  );
};

export default forwardRef(Notification);
