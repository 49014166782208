import {
  Drawer,
  DrawerContent,
  DrawerOverlay,
  Icon,
  Button,
  useBreakpointValue,
} from "@chakra-ui/react";
import QuickAddContactForm from "components/modals/QuickAddContactForm";
import ContactDomain from "entities/domain/customers/contact-domain";
import React, { useState } from "react";
import { ReactSVG } from "react-svg";
import { useAppSelector } from "redux/hooks";

interface AddNewContactProps {
  prefilledName?: string;
  setRecentlyCreatedContact: (contact: ContactDomain | undefined) => void;
}

const AddNewContact = ({
  prefilledName,
  setRecentlyCreatedContact,
}: AddNewContactProps) => {
  const isBaseSize = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );
  const { colorScheme } = useAppSelector((state) => state.theme);
  const [openNewContactForm, setOpenNewContactForm] = useState<boolean>(false);

  return (
    <>
      <Button
        aria-label="Add new contact"
        variant={isBaseSize ? "unstyled" : "solid"}
        colorScheme={colorScheme}
        display="flex"
        gridGap={2}
        size="md"
        onClick={() => setOpenNewContactForm(true)}
        _focus={{ outline: "none" }}
      >
        <Icon
          as={ReactSVG}
          src="/user-plus-regular.svg"
          fontSize={20}
          fill={isBaseSize ? `${colorScheme}.400` : "white"}
          _hover={{ fill: "gray.400" }}
        />
        {!isBaseSize && "Create Contact"}
      </Button>
      {isBaseSize ? (
        <Drawer
          isOpen={openNewContactForm}
          onClose={() => setOpenNewContactForm(false)}
          placement="top"
          isFullHeight={true}
          allowPinchZoom={true}
          autoFocus={false}
        >
          <DrawerOverlay />
          <DrawerContent overflowY="scroll" borderRadius="0 !important">
            <QuickAddContactForm
              searchString={prefilledName}
              isOpen={openNewContactForm}
              setRecentlyCreatedContact={setRecentlyCreatedContact}
              onClose={() => setOpenNewContactForm(false)}
              shouldRedirectToDetails={true}
            />
          </DrawerContent>
        </Drawer>
      ) : (
        <QuickAddContactForm
          searchString={prefilledName}
          isOpen={openNewContactForm}
          onClose={() => setOpenNewContactForm(false)}
          setRecentlyCreatedContact={setRecentlyCreatedContact}
          shouldRedirectToDetails={true}
        />
      )}
    </>
  );
};

export default AddNewContact;
