import { useAuth0 } from "@auth0/auth0-react";
import debounce from "debounce-promise";
import { Avatar, Flex, Text, useColorMode } from "@chakra-ui/react";
import { useAppSelector } from "redux/hooks";
import React, { memo, useEffect, useRef, useState } from "react";
import {
  GroupBase,
  MultiValue,
  OptionProps,
  SingleValue,
  components,
  Props as SelectProps,
} from "react-select";
import MerchantGroup from "entities/domain/admin/merchants/merchant-group";
import { AsyncPaginate, LoadOptions } from "react-select-async-paginate";
import AdminService from "services/admin";
import { numberOfAllMerchantsPerLoad } from "util/constants";
import { getReactSelectStyles, getUniqueListBy } from "util/methods";

interface SelectMerchantGroupPropsBase {
  filterCallback?: (id: string) => boolean;
}

type SetSelectedMerchantGroup = React.Dispatch<
  React.SetStateAction<MerchantGroup | null>
>;

interface SelectMerchantGroupProps extends SelectMerchantGroupPropsBase {
  isMulti?: false;
  selectedMerchantGroup: MerchantGroup | null;
  setSelectedMerchantGroup: SetSelectedMerchantGroup;
}

type SetSelectedMerchantGroups = React.Dispatch<
  React.SetStateAction<MerchantGroup[]>
>;

interface SelectMerchantGroupsProps extends SelectMerchantGroupPropsBase {
  isMulti: true;
  selectedMerchantGroups: MerchantGroup[];

  setSelectedMerchantGroups: SetSelectedMerchantGroups;
  filterCallback?: (id: string) => boolean;
}

type SelectMerchantGroupType =
  | (SelectMerchantGroupProps & SelectProps<MerchantGroupOptionTypes>)
  | (SelectMerchantGroupsProps & SelectProps<MerchantGroupOptionTypes>);

interface MerchantGroupOptionTypes {
  value: string;
  label: string;
}

const MerchantGroupColumn = ({ item }: { item: MerchantGroupOptionTypes }) => {
  return (
    <Flex
      justifyContent="start"
      alignItems="center"
      gridGap={4}
      px={8}
      overflow="hidden"
      whiteSpace="nowrap"
      textOverflow="ellipsis"
    >
      <Text>{item.label}</Text>
    </Flex>
  );
};

const MerchantGroupOption = function (
  props: OptionProps<MerchantGroupOptionTypes>
) {
  return (
    <components.Option {...props}>
      <MerchantGroupColumn item={props.data} />
    </components.Option>
  );
};

const transformMerchantGroupToOption = (
  merchantGroup: MerchantGroup
): MerchantGroupOptionTypes => ({
  value: merchantGroup.id,
  label: merchantGroup.name,
});

const SelectMerchantGroup = ({
  filterCallback,
  ...props
}: SelectMerchantGroupType) => {
  let selectedMerchantGroups: MerchantGroup[] | null = null;
  let setSelectedMerchantGroups: SetSelectedMerchantGroups | null = null;
  let selectedMerchantGroup: MerchantGroup | null = null;
  let setSelectedMerchantGroup: SetSelectedMerchantGroup | null = null;

  if (props.isMulti === true) {
    selectedMerchantGroups = props.selectedMerchantGroups;
    setSelectedMerchantGroups = props.setSelectedMerchantGroups;
  } else {
    selectedMerchantGroup = props.selectedMerchantGroup;
    setSelectedMerchantGroup = props.setSelectedMerchantGroup;
  }

  const auth0Context = useAuth0();
  const { colorMode } = useColorMode();
  const { colorScheme } = useAppSelector((state) => state.theme);

  const [cache, clearCache] = useState<object>({});
  const [previousSearchText, setPreviousSearchText] = useState<string>("");
  const [merchantGroupOptions, setMerchantGroupOptions] = useState<
    MerchantGroupOptionTypes[]
  >([]);
  const [merchantGroups, setMerchantGroups] = useState<MerchantGroup[]>([]);
  const lastRequest = useRef<object>({});

  const hasMoreMerchantGroups = (
    merchantGroupsAmount: number,
    currentPageAmount: number
  ): boolean => {
    if (merchantGroupsAmount < numberOfAllMerchantsPerLoad) {
      return false;
    }

    if (!currentPageAmount || currentPageAmount < numberOfAllMerchantsPerLoad) {
      return false;
    }

    return true;
  };

  useEffect(() => {
    clearCache({});
    setMerchantGroupOptions([]);
    setMerchantGroups([]);
  }, [filterCallback]);

  const fetchMoreMerchantGroups: LoadOptions<
    MerchantGroupOptionTypes,
    GroupBase<MerchantGroupOptionTypes>,
    unknown
  > = async (searchText = "") => {
    lastRequest.current = {};
    const currentRequest = lastRequest.current;

    let offset = merchantGroupOptions?.length || 0;

    if (searchText !== previousSearchText) {
      offset = 0;
    }

    setPreviousSearchText(searchText);

    const newMerchantGroups = await AdminService.getMerchantGroups(
      auth0Context,
      searchText,
      offset
    );

    if (currentRequest !== lastRequest.current) {
      return {
        options: merchantGroupOptions,
        hasMore: hasMoreMerchantGroups(merchantGroupOptions.length, 0),
      };
    }

    const merchantGroupIds = merchantGroups.map((m) => m.id);
    const updatedMerchantGroups: MerchantGroup[] = merchantGroups;
    for (const m of newMerchantGroups) {
      if (!merchantGroupIds.includes(m.id)) {
        updatedMerchantGroups.push(m);
      }
    }
    setMerchantGroups(updatedMerchantGroups);

    const nextOptions = newMerchantGroups.map<MerchantGroupOptionTypes>(
      transformMerchantGroupToOption
    );

    const newMerchantGroupOptions: MerchantGroupOptionTypes[] =
      merchantGroupOptions?.length
        ? merchantGroupOptions.concat(nextOptions)
        : nextOptions;

    setMerchantGroupOptions(newMerchantGroupOptions);

    return {
      options:
        typeof filterCallback !== "undefined"
          ? nextOptions.filter((m) => filterCallback(m.value))
          : nextOptions,
      hasMore: hasMoreMerchantGroups(
        newMerchantGroupOptions.length,
        newMerchantGroups?.length || 0
      ),
    };
  };

  const debouncedFetchMoreMerchantGroups = debounce(
    fetchMoreMerchantGroups,
    300
  ) as LoadOptions<
    MerchantGroupOptionTypes,
    GroupBase<MerchantGroupOptionTypes>,
    unknown
  >;

  return (
    <AsyncPaginate
      loadOptions={debouncedFetchMoreMerchantGroups}
      cacheUniqs={[cache, filterCallback]}
      placeholder="Select MerchantGroup"
      onChange={(
        m:
          | SingleValue<MerchantGroupOptionTypes>
          | MultiValue<MerchantGroupOptionTypes>
      ) => {
        if (setSelectedMerchantGroups) {
          const merchantGroupIds =
            (m as MerchantGroupOptionTypes[])?.map((o) => o.value) || [];

          setSelectedMerchantGroups((prev: MerchantGroup[]) => {
            const previouslySelectedAndAllCurrentlySearchedMerchantGroups = [
              ...prev, // because it may be so that previously selected merchantGroup doesn't exist in current merchantGroups array anymore
              ...merchantGroups,
            ];

            const merchantGroupsWithoutDuplicates =
              getUniqueListBy<MerchantGroup>(
                previouslySelectedAndAllCurrentlySearchedMerchantGroups,
                "id"
              );

            return (
              merchantGroupsWithoutDuplicates.filter((merchantGroup) =>
                merchantGroupIds.includes(merchantGroup.id)
              ) || null
            );
          });
        } else if (setSelectedMerchantGroup) {
          setSelectedMerchantGroup(
            merchantGroups.find(
              (merchantGroup) =>
                (m as MerchantGroupOptionTypes)?.value === merchantGroup.id
            ) || null
          );
        }
      }}
      onMenuClose={() => {
        clearCache({});
        setMerchantGroupOptions([]);
        setMerchantGroups([]);
        setPreviousSearchText("");
      }}
      value={
        selectedMerchantGroups
          ? selectedMerchantGroups.map(transformMerchantGroupToOption)
          : selectedMerchantGroup
          ? transformMerchantGroupToOption(selectedMerchantGroup)
          : undefined
      }
      components={{
        Option: MerchantGroupOption,
      }}
      styles={getReactSelectStyles(colorMode, colorScheme)}
      {...props}
    />
  );
};

export default SelectMerchantGroup;
