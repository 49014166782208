import {
  Icon,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  useBreakpointValue,
  useToast,
} from "@chakra-ui/react";
import { useAppSelector } from "redux/hooks";
import { ReactComponent as SelectSearchIcon } from "assets/icons/select-search.svg";
import { ReactComponent as UploadIcon } from "assets/icons/upload-csv.svg";
import { ReactComponent as CreateNewAudienceIcon } from "assets/icons/account-multiple-plus-outline.svg";
import React, { useEffect, useState } from "react";
import { CampaignStatus } from "entities/domain/campaign";
import useAudiencesStore from "hooks/use-audiences-store";

import AudienceDomain, { AudienceCriteria } from "entities/domain/audience";
import { stringifyCriteria } from "entities/domain/criteria";
import { ConversationChannel } from "entities/domain/conversations/conversation-domain";
import NewAudienceTab from "./NewAdienceTab";
import ExistingAudienceTab from "./ExistingAudienceTab";
import UploadAudienceTab from "./UploadAudienceTab";

interface AudienceProps {
  campaignId: string | null;
  campaignAudienceId: string | null;
  campaignStatus: CampaignStatus;
  campaignChannel: ConversationChannel;
  setCampaignAudienceId: (id: string | null) => void;
  onValidationSuccess: () => void;
  onValidationFailure: () => void;
}

const Audience = ({
  campaignId,
  campaignAudienceId,
  campaignStatus,
  campaignChannel,
  setCampaignAudienceId,
  onValidationFailure,
  onValidationSuccess,
}: AudienceProps) => {
  const { colorScheme } = useAppSelector((state) => state.theme);
  const isBaseSize = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );
  const toast = useToast();
  const { merchant } = useAppSelector((state) => state.merchant);
  const { fetchAudiences, editAudience, createAudience } = useAudiencesStore();
  const { audiences } = useAppSelector((state) => state.audiences);
  const [activeTab, setActiveTab] = useState<number>(
    campaignAudienceId ? (isBaseSize ? 1 : 2) : 0
  );

  const [audience, setAudience] = useState<AudienceDomain | null>(null);
  const [audienceName, setAudienceName] = useState<string | null>("");
  const [audienceCriteria, setAudienceCriteria] =
    useState<AudienceCriteria | null>(null);
  const [isSavingAudience, setIsSavingAudience] = useState<boolean>(false);
  const [isInitialLoadCompleted, setIsInitialLoadCompleted] =
    useState<boolean>(false);
  const isUpdate = !!campaignId;

  useEffect(() => {
    if (!audiences.length) {
      fetchAudiences();
    }

    setIsInitialLoadCompleted(true);
  }, []);

  const handleAudienceSave = async (
    next: (newAudience?: AudienceDomain) => void,
    audienceDraft?: AudienceDomain,
    uploadTagId?: string
  ) => {
    if (!audience && !audienceDraft) {
      next();

      return;
    }

    setIsSavingAudience(true);

    try {
      const saveResponse =
        isUpdate && audience
          ? await editAudience({
              id: audience.id as string,
              name: audience.name.trim(),
              criteria: stringifyCriteria(audience.criteria),
              merchantId: audience.merchantId,
            })
          : await createAudience({
              name: audience
                ? audience.name.trim()
                : audienceDraft!.name.trim(),
              criteria: audience
                ? stringifyCriteria(audience.criteria)
                : stringifyCriteria(audienceDraft!.criteria),
              merchantId: audience
                ? audience.merchantId
                : audienceDraft!.merchantId,
            });

      if (!saveResponse) {
        toast({
          status: "error",
          title: "An error occurred while creating an audience.",
          description: "Try uploading a CSV instead!",
        });

        return;
      }

      setAudience(null);

      next(saveResponse);

      return;
    } catch (err) {
      /* eslint-disable no-console */
      console.error("Failed to save an Audience: ", err);
      /* eslint-enable no-console */
      toast({
        status: "error",
        title: "An error occurred while creating an audience.",
        description: "Try uploading a CSV instead!",
      });

      return;
    } finally {
      setIsSavingAudience(false);
    }
  };

  useEffect(() => {
    const audienceWasCreated = !!audience && audienceName && audienceCriteria;

    if (audienceWasCreated && activeTab !== 0) {
      setAudience(null);
    }
  }, [activeTab]);

  useEffect(() => {
    const hasCsvFile = activeTab === 1 && !isBaseSize;
    const audienceWasSelected = !!campaignAudienceId;
    const audienceWasCreated =
      !!audience && audienceName && audienceCriteria && !hasCsvFile;

    if (audienceWasSelected || audienceWasCreated) {
      onValidationSuccess();

      if (audienceWasSelected && activeTab === 1 && hasCsvFile) {
        setActiveTab(2);
      } else if (
        audienceWasCreated &&
        audienceWasSelected &&
        activeTab === 0 &&
        !hasCsvFile
      ) {
        setActiveTab(isBaseSize ? 1 : 2);
      }
    } else {
      onValidationFailure();
    }
  }, [campaignAudienceId, audience, audienceCriteria, audienceName]);

  return (
    <Tabs
      colorScheme={colorScheme}
      display="flex"
      flexDirection="column"
      overflow="hidden"
      height="100%"
      index={activeTab}
      onChange={(index) => {
        setActiveTab(index);
      }}
      isFitted
    >
      <TabList pt={isBaseSize ? 0 : 4}>
        <Tab isDisabled={campaignStatus === CampaignStatus.DONE}>
          <Icon
            as={CreateNewAudienceIcon}
            mr={1}
            __css={{
              height: "2rem",
              width: "2rem",
            }}
          />
          Create
        </Tab>
        {isBaseSize ? null : (
          <Tab isDisabled={campaignStatus === CampaignStatus.DONE}>
            <Icon
              as={UploadIcon}
              mr={1}
              __css={{
                height: "1.5rem",
                width: "1.5rem",
              }}
            />
            Upload
          </Tab>
        )}
        <Tab>
          <Icon
            as={SelectSearchIcon}
            mr={1}
            __css={{
              height: "2rem",
              width: "2rem",
            }}
          />
          Select
        </Tab>
      </TabList>
      <TabPanels
        h="100%"
        display="flex"
        overflow="hidden"
        px={isBaseSize ? 2 : 0}
      >
        <TabPanel
          display="flex"
          flexDirection="column"
          w="100%"
          mx="auto"
          h="100%"
          overflowY="auto"
          my={0}
        >
          <NewAudienceTab
            isSavingAudience={isSavingAudience}
            audienceName={audienceName}
            campaignChannel={campaignChannel}
            audienceCriteria={audienceCriteria || null}
            onAudienceSelect={(createdAudience: AudienceDomain) => {
              setCampaignAudienceId(createdAudience.id);
              setAudience(createdAudience);
            }}
            setAudienceCriteria={(newAudienceCriteria: AudienceCriteria) => {
              setAudienceCriteria(newAudienceCriteria);
            }}
            setAudienceName={setAudienceName}
          />
        </TabPanel>
        {isBaseSize ? null : (
          <TabPanel
            display="flex"
            flexDirection="column"
            w={isBaseSize ? "100%" : "60%"}
            mx="auto"
            h="100%"
            overflowY="auto"
            my={0}
          >
            <UploadAudienceTab
              isSavingAudience={isSavingAudience}
              campaignId={campaignId}
              setCampaignAudienceId={setCampaignAudienceId}
              handleAudienceSave={handleAudienceSave}
            />
          </TabPanel>
        )}
        <TabPanel
          display="flex"
          flexDirection="column"
          w={isBaseSize ? "100%" : "60%"}
          mx="auto"
          my={0}
          h="100%"
          overflowY="hidden"
        >
          <ExistingAudienceTab
            audienceId={campaignAudienceId}
            campaignStatus={campaignStatus}
            setAudienceId={setCampaignAudienceId}
          />
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
};

export default Audience;
