import { useAuth0 } from "@auth0/auth0-react";
import { HStack, Radio, RadioGroup } from "@chakra-ui/react";
import {
  AvailabilityStatus,
  VehicleCondition,
} from "entities/domain/inventory-vehicle";
import React, { useEffect, useState } from "react";
import {
  setFilterByAvailability,
  setFilterByCondition,
} from "redux/features/inventory";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import InventoryService from "services/inventory";

interface AvailabilityFilterProps {}

const DEFAULT_VEHICLE_AVAILABILITY = AvailabilityStatus.AVAILABLE;

const AvailabilityFilter = (_props: AvailabilityFilterProps) => {
  const dispatch = useAppDispatch();
  const auth0Context = useAuth0();
  const { merchant } = useAppSelector((state) => state.merchant);
  const { colorScheme } = useAppSelector((state) => state.theme);
  const {
    filterOptions,
    filterByAvailability,
    filterByCondition,
    filterByModel,
    filterByPriceMax,
    filterByPriceMin,
    filterByYearMax,
    filterByYearMin,
    filterByMake,
  } = useAppSelector((state) => state.inventory);
  const [selectedAvailability, setSelectedAvailability] =
    useState<AvailabilityStatus>(
      filterByAvailability || DEFAULT_VEHICLE_AVAILABILITY
    );

  const [availabilityOptions, setAvailabilityOptions] = useState<
    AvailabilityStatus[]
  >(filterOptions.allAvailabilities);

  useEffect(() => {
    if (
      !filterByModel &&
      !filterByYearMin &&
      !filterByYearMax &&
      !filterByPriceMin &&
      !filterByPriceMax &&
      !filterByMake &&
      !filterByCondition
    ) {
      setAvailabilityOptions(filterOptions.allAvailabilities);
      return;
    }

    InventoryService.getVehicleFilterOptions(
      auth0Context,
      merchant.id,
      filterByModel,
      filterByMake,
      filterByYearMin,
      filterByYearMax,
      filterByPriceMin,
      filterByPriceMax,
      filterByCondition,
      null
    ).then((newFilterOptions) => {
      setAvailabilityOptions(newFilterOptions.allAvailabilities);
    });
  }, [
    filterOptions,
    filterByMake,
    filterByModel,
    filterByPriceMax,
    filterByPriceMin,
    filterByYearMax,
    filterByYearMin,
    filterByCondition,
  ]);

  useEffect(() => {
    if (selectedAvailability !== filterByAvailability) {
      dispatch(setFilterByAvailability(selectedAvailability));
    }
  }, [selectedAvailability]);

  return availabilityOptions.length > 1 ? (
    <RadioGroup
      value={selectedAvailability}
      onChange={(newAvailability) => {
        setSelectedAvailability(newAvailability as AvailabilityStatus);
      }}
    >
      <HStack spacing={2}>
        {availabilityOptions.map((availability) => (
          <Radio colorScheme={colorScheme} value={availability}>
            {availability[0].toLocaleUpperCase() +
              availability.slice(1).toLowerCase()}
          </Radio>
        ))}
      </HStack>
    </RadioGroup>
  ) : null;
};

export default AvailabilityFilter;
