import { useAuth0 } from "@auth0/auth0-react";
import { useColorMode } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import Select, { SingleValue, MultiValue } from "react-select";
import { setFilterByMake } from "redux/features/inventory";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import InventoryService from "services/inventory";
import { getReactSelectStyles } from "util/methods";

interface MakeFilterProps {}

interface AvailableMakeOption {
  value: string;
  label: string;
}

const MakeFilter = (_props: MakeFilterProps) => {
  const dispatch = useAppDispatch();
  const { colorScheme } = useAppSelector((state) => state.theme);
  const auth0Context = useAuth0();
  const { merchant } = useAppSelector((state) => state.merchant);
  const {
    filterByMake,
    filterOptions,
    filterByCondition,
    filterByModel,
    filterByPriceMax,
    filterByPriceMin,
    filterByYearMax,
    filterByYearMin,
    filterByAvailability,
  } = useAppSelector((state) => state.inventory);
  const { colorMode } = useColorMode();

  const [availableMakeOptions, setAvailableMakeOptions] = useState<
    MultiValue<AvailableMakeOption>
  >(
    filterOptions.allMakes.map((make) => ({
      value: make,
      label: make,
    }))
  );

  useEffect(() => {
    if (
      !filterByModel &&
      !filterByYearMin &&
      !filterByYearMax &&
      !filterByPriceMin &&
      !filterByPriceMax &&
      !filterByCondition &&
      !filterByAvailability
    ) {
      setAvailableMakeOptions(
        filterOptions.allMakes.map((make) => ({
          value: make,
          label: make,
        }))
      );
      return;
    }

    InventoryService.getVehicleFilterOptions(
      auth0Context,
      merchant.id,
      filterByModel,
      null,
      filterByYearMin,
      filterByYearMax,
      filterByPriceMin,
      filterByPriceMax,
      filterByCondition,
      filterByAvailability
    ).then((newFilterOptions) => {
      setAvailableMakeOptions(
        newFilterOptions.allMakes.map((make) => ({
          value: make,
          label: make,
        }))
      );
    });
  }, [
    filterOptions,
    filterByCondition,
    filterByModel,
    filterByPriceMax,
    filterByPriceMin,
    filterByYearMax,
    filterByYearMin,
    filterByAvailability,
  ]);

  const [selectedMakeOptionValue, setSelectedMakeOptionValue] = useState<
    SingleValue<AvailableMakeOption>
  >(availableMakeOptions.find((mo) => mo.value === filterByMake) || null);

  useEffect(() => {
    setSelectedMakeOptionValue(
      availableMakeOptions.find((mo) => mo.value === filterByMake) || null
    );
  }, [filterByMake]);

  return availableMakeOptions.length <= 1 ? null : (
    <Select
      id="search-by-make-select"
      isMulti={false}
      placeholder="Search by Make"
      isClearable={true}
      isSearchable={true}
      value={selectedMakeOptionValue}
      onChange={(newValue) => {
        dispatch(setFilterByMake(newValue?.value || null));
      }}
      options={availableMakeOptions}
      styles={{
        ...{
          ...getReactSelectStyles(colorMode, colorScheme),
          container: (provided: any) => ({
            ...provided,
            width: "100%",
          }),
        },
      }}
    />
  );
};

export default MakeFilter;
