import {
  Button,
  Flex,
  HStack,
  Icon,
  Spinner,
  Text,
  VStack,
  useColorMode,
  useToast,
} from "@chakra-ui/react";
import { ReactComponent as SelectAllIcon } from "assets/icons/select-all-svgrepo-com.svg";
import { ReactComponent as DeselectAllIcon } from "assets/icons/select-all-off-svgrepo-com.svg";
import { ReactComponent as AssignAgentIcon } from "assets/icons/personal-conversations.svg";
import { ReactComponent as OpenLockIcon } from "assets/icons/open-lock.svg";
import { ReactComponent as CloseLockIcon } from "assets/icons/close-lock.svg";
import { ReactComponent as EyeIcon } from "assets/icons/eye.svg";
import { ReactComponent as EyeOffIcon } from "assets/icons/eye-off.svg";
import { ReactComponent as TagAddIcon } from "assets/icons/tag-plus-outline.svg";
import { ReactComponent as TagRemoveIcon } from "assets/icons/tag-minus-outline.svg";
import React, { useState } from "react";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { batch } from "react-redux";
import {
  OpenClosedFilter,
  clearSelectedConversations,
  disableBulkActionsToolbar,
  enableBulkActionsToolbar,
  startLoadingBulkActionsToolbar,
  stopLoadingBulkActionsToolbar,
  updateConversationSelection,
} from "redux/features/conversations";
import { CloseIcon } from "@chakra-ui/icons";
import InboxService from "services/inbox";
import { useAuth0 } from "@auth0/auth0-react";
import ConfirmationDialog from "components/shared/ConfirmationDialog";
import {
  MAX_ALLOWED_BULK_SELECTION,
  MAX_SAFE_SELECTION_AMOUNT,
} from "util/constants";

interface BulkActionsProps {
  openAssignModal: () => void;
  openUpdateTagsModal: (isRemoval: boolean) => void;
}

const BulkActions = ({
  openAssignModal,
  openUpdateTagsModal,
}: BulkActionsProps) => {
  const dispatch = useAppDispatch();
  const auth0Context = useAuth0();
  const {
    selectedConversationIds,
    conversations,
    isOpenOrClosed,
    bulkActionsToolbarEnabled,
    bulkActionsToolbarLoading,
  } = useAppSelector((state) => state.conversations);
  const { colorScheme } = useAppSelector((state) => state.theme);
  const toast = useToast();
  const { colorMode } = useColorMode();
  const { merchant } = useAppSelector((state) => state.merchant);
  const [shouldShowConfirmation, setShouldShowConfirmation] =
    useState<boolean>(false);
  const [confirmationAction, setConfirmationAction] = useState<() => void>(
    () => () => {}
  );

  const markAllAsReadOrUnread = async (shouldMarkRead: boolean) => {
    try {
      const updatedConversationIds = await InboxService.bulkMarkReadOrUnread(
        auth0Context,
        merchant.id,
        selectedConversationIds,
        shouldMarkRead
      );

      if (updatedConversationIds.length === 0) {
        toast({
          status: "error",
          title: "Failed to perform bulk action.",
          description: "Please try again later.",
        });

        return;
      }

      if (updatedConversationIds.length === selectedConversationIds.length) {
        dispatch(clearSelectedConversations());
      }
    } catch (_error: unknown) {
      toast({
        status: "error",
        title: "Failed to perform bulk action.",
        description: "Please try again later.",
      });
    }
  };

  const openOrCloseAll = async () => {
    try {
      const updatedConversationIds =
        await InboxService.bulkOpenOrCloseConversations(
          auth0Context,
          merchant.id,
          selectedConversationIds,
          isOpenOrClosed === OpenClosedFilter.Closed
        );

      if (updatedConversationIds.length === 0) {
        toast({
          status: "error",
          title: "Failed to perform bulk action.",
          description: "Please try again later.",
        });

        return;
      }

      if (updatedConversationIds.length === selectedConversationIds.length) {
        dispatch(clearSelectedConversations());
      }
    } catch (_error: unknown) {
      toast({
        status: "error",
        title: "Failed to perform bulk action.",
        description: "Please try again later.",
      });
    }
  };

  const handleBulkReadOrUnread = (shouldMarkRead: boolean) => {
    dispatch(disableBulkActionsToolbar());
    dispatch(startLoadingBulkActionsToolbar());

    markAllAsReadOrUnread(shouldMarkRead).finally(() => {
      dispatch(enableBulkActionsToolbar());
      dispatch(stopLoadingBulkActionsToolbar());
    });
  };

  const handleBulkOpenOrClose = () => {
    dispatch(disableBulkActionsToolbar());
    dispatch(startLoadingBulkActionsToolbar());

    openOrCloseAll().finally(() => {
      dispatch(enableBulkActionsToolbar());
      dispatch(stopLoadingBulkActionsToolbar());
    });
  };

  return (
    <HStack
      alignItems="center"
      justifyContent="center"
      bgColor={
        colorMode === "dark" ? `${colorScheme}.200` : `${colorScheme}.500`
      }
      borderRadius="xl"
      overflow="hidden"
      spacing={0}
      height="80px"
      color={colorMode === "dark" ? "gray.800" : "white"}
      position="relative"
    >
      {!bulkActionsToolbarLoading ? null : (
        <Flex
          alignItems="center"
          justifyContent="center"
          position="absolute"
          left={0}
          top={0}
          width="100%"
          height="100%"
          zIndex={2}
          bgColor={colorMode === "dark" ? "blackAlpha.600" : "whiteAlpha.600"}
        >
          <Spinner />
        </Flex>
      )}
      <VStack
        height="100%"
        spacing={0}
        p={2}
        justifyContent="center"
        bgColor={
          colorMode === "dark" ? `${colorScheme}.500` : `${colorScheme}.600`
        }
      >
        <Text fontSize="xl" color="white">
          {selectedConversationIds.length}
        </Text>
        <Text fontSize="md" color="white">
          Selected
        </Text>
      </VStack>
      <Button
        height="100%"
        borderRadius={0}
        display="flex"
        colorScheme={colorScheme}
        flexDirection="column"
        justifyContent="space-between"
        isDisabled={!bulkActionsToolbarEnabled}
        gridGap={2}
        onClick={() => {
          if (
            selectedConversationIds.length ===
            conversations.slice(0, MAX_ALLOWED_BULK_SELECTION).length
          ) {
            dispatch(clearSelectedConversations());

            return;
          }

          batch(() => {
            conversations.slice(0, 100).forEach((c) => {
              dispatch(
                updateConversationSelection({
                  conversationId: c.id,
                  isSelected: true,
                })
              );
            });
          });
        }}
      >
        <Icon
          as={
            selectedConversationIds.length ===
            conversations.slice(0, MAX_ALLOWED_BULK_SELECTION).length
              ? DeselectAllIcon
              : SelectAllIcon
          }
          w={6}
          h={6}
          __css={{
            g: {
              fill: colorMode === "dark" ? "gray.800" : "white",
            },
          }}
        />
        {selectedConversationIds.length ===
        conversations.slice(0, MAX_ALLOWED_BULK_SELECTION).length
          ? "Deselect All"
          : "Select All"}
      </Button>
      <Button
        height="100%"
        borderRadius={0}
        colorScheme={colorScheme}
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        gridGap={2}
        isDisabled={!bulkActionsToolbarEnabled}
        onClick={() => {
          if (selectedConversationIds.length > MAX_SAFE_SELECTION_AMOUNT) {
            setConfirmationAction(() => () => {
              dispatch(disableBulkActionsToolbar());
              openUpdateTagsModal(false);
            });
            setShouldShowConfirmation(true);

            return;
          }

          dispatch(disableBulkActionsToolbar());
          openUpdateTagsModal(false);
        }}
      >
        <Icon
          as={TagAddIcon}
          w={6}
          h={6}
          fill={colorMode === "dark" ? "gray.800" : "white"}
        />
        Add Tag
      </Button>
      <Button
        height="100%"
        borderRadius={0}
        display="flex"
        colorScheme={colorScheme}
        flexDirection="column"
        justifyContent="space-between"
        gridGap={2}
        isDisabled={!bulkActionsToolbarEnabled}
        onClick={() => {
          if (selectedConversationIds.length > MAX_SAFE_SELECTION_AMOUNT) {
            setConfirmationAction(() => () => {
              dispatch(disableBulkActionsToolbar());
              openUpdateTagsModal(true);
            });
            setShouldShowConfirmation(true);

            return;
          }

          dispatch(disableBulkActionsToolbar());
          openUpdateTagsModal(true);
        }}
      >
        <Icon
          as={TagRemoveIcon}
          w={6}
          h={6}
          fill={colorMode === "dark" ? "gray.800" : "white"}
        />
        Remove Tag
      </Button>
      <Button
        height="100%"
        borderRadius={0}
        display="flex"
        colorScheme={colorScheme}
        flexDirection="column"
        justifyContent="space-between"
        gridGap={2}
        isDisabled={!bulkActionsToolbarEnabled}
        onClick={() => {
          if (selectedConversationIds.length > MAX_SAFE_SELECTION_AMOUNT) {
            setConfirmationAction(() => () => {
              dispatch(disableBulkActionsToolbar());
              openAssignModal();
            });
            setShouldShowConfirmation(true);

            return;
          }

          dispatch(disableBulkActionsToolbar());
          openAssignModal();
        }}
      >
        <Icon
          as={AssignAgentIcon}
          w={6}
          h={6}
          __css={{
            path: {
              stroke: colorMode === "dark" ? "gray.800" : "white",
            },
          }}
        />
        Assign to
      </Button>
      <Button
        height="100%"
        borderRadius={0}
        colorScheme={colorScheme}
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        gridGap={2}
        isDisabled={!bulkActionsToolbarEnabled}
        onClick={() => {
          if (selectedConversationIds.length > MAX_SAFE_SELECTION_AMOUNT) {
            setConfirmationAction(() => () => handleBulkOpenOrClose());
            setShouldShowConfirmation(true);

            return;
          }

          handleBulkOpenOrClose();
        }}
      >
        <Icon
          as={
            isOpenOrClosed === OpenClosedFilter.Open
              ? CloseLockIcon
              : OpenLockIcon
          }
          w={6}
          h={6}
          __css={{
            path: {
              fill: colorMode === "dark" ? "gray.800" : "white",
            },
          }}
        />
        {isOpenOrClosed === OpenClosedFilter.Open ? "Close" : "Open"}
      </Button>
      <Button
        height="100%"
        borderRadius={0}
        display="flex"
        colorScheme={colorScheme}
        flexDirection="column"
        justifyContent="space-between"
        gridGap={2}
        isDisabled={!bulkActionsToolbarEnabled}
        onClick={() => {
          if (selectedConversationIds.length > MAX_SAFE_SELECTION_AMOUNT) {
            setConfirmationAction(() => () => handleBulkReadOrUnread(true));
            setShouldShowConfirmation(true);

            return;
          }

          handleBulkReadOrUnread(true);
        }}
      >
        <Icon
          as={EyeIcon}
          w={6}
          h={6}
          fill={colorMode === "dark" ? "gray.800" : "white"}
        />
        Read
      </Button>
      <Button
        height="100%"
        borderRadius={0}
        display="flex"
        colorScheme={colorScheme}
        flexDirection="column"
        justifyContent="space-between"
        gridGap={2}
        isDisabled={!bulkActionsToolbarEnabled}
        onClick={() => {
          if (selectedConversationIds.length > MAX_SAFE_SELECTION_AMOUNT) {
            setConfirmationAction(() => () => handleBulkReadOrUnread(false));
            setShouldShowConfirmation(true);

            return;
          }

          handleBulkReadOrUnread(false);
        }}
      >
        <Icon
          as={EyeOffIcon}
          w={6}
          h={6}
          fill={colorMode === "dark" ? "gray.800" : "white"}
        />
        Unread
      </Button>
      <Button
        borderLeftWidth="1px"
        borderLeftStyle="solid"
        borderLeftColor={colorMode === "dark" ? "gray.800" : "white"}
        height="100%"
        borderRadius={0}
        colorScheme={colorScheme}
        display="flex"
        flexDirection="column"
        justifyContent="center"
        gridGap={2}
        isDisabled={!bulkActionsToolbarEnabled}
        onClick={() => {
          dispatch(clearSelectedConversations());
        }}
      >
        <Icon as={CloseIcon} w={4} h={4} mx={2} />
      </Button>
      <ConfirmationDialog
        isOpen={shouldShowConfirmation}
        setIsOpen={setShouldShowConfirmation}
        headerText="Are you sure about this?"
        messageText={`You're about to do a bulk action over ${selectedConversationIds.length} conversations.`}
        buttonText="Yes"
        confirmationCallback={() => {
          confirmationAction();
          setShouldShowConfirmation(false);
          setConfirmationAction(() => () => {});
        }}
      />
    </HStack>
  );
};

export default BulkActions;
