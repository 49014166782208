import { useAuth0 } from "@auth0/auth0-react";
import {
  createAudience,
  createAudienceFail,
  CreateAudiencePayload,
  createAudienceSuccess,
  deleteAudience,
  deleteAudienceFail,
  DeleteAudiencePayload,
  deleteAudienceSuccess,
  editAudience,
  editAudienceFail,
  EditAudiencePayload,
  editAudienceSuccess,
  fetchAudiences,
  fetchAudiencesFail,
  fetchAudiencesSuccess,
} from "redux/features/audiences";
import AudienceDomain from "entities/domain/audience";
import { useCallback } from "react";
import { getErrorDescriptionOrDefault } from "services/errorCodeConverter";
import AudiencesService from "services/audiences";
import { useAppDispatch, useAppSelector } from "redux/hooks";

export default function useAudiencesStore() {
  const dispatch = useAppDispatch();
  const { merchant } = useAppSelector((state) => state.merchant);
  const auth0Context = useAuth0();

  const fetchAudiencesWaterfall =
    () => async (): Promise<AudienceDomain[] | undefined> => {
      try {
        dispatch(fetchAudiences());

        const audiencesResponse = await AudiencesService.getAudiences(
          auth0Context,
          merchant.id
        );
        dispatch(fetchAudiencesSuccess(audiencesResponse));
        return audiencesResponse;
      } catch (err: any) {
        const errorMessage = getErrorDescriptionOrDefault(
          err.response?.data?.code,
          "Oops. We couldn't load audiences. Please try again!"
        );
        dispatch(fetchAudiencesFail([errorMessage]));
        return undefined;
      }
    };

  const createAudienceWaterfall =
    (payload: CreateAudiencePayload) =>
    async (): Promise<AudienceDomain | undefined> => {
      try {
        dispatch(createAudience());

        const audienceResponse = await AudiencesService.createAudience(
          auth0Context,
          payload
        );

        dispatch(createAudienceSuccess(audienceResponse));
        return audienceResponse;
      } catch (err: any) {
        const errorMessage = getErrorDescriptionOrDefault(
          err.response?.data?.code,
          "Oops. We couldn't create this audience. Please try again!"
        );
        dispatch(createAudienceFail([errorMessage]));
        return undefined;
      }
    };

  const editAudienceWaterfall =
    (payload: EditAudiencePayload) =>
    async (): Promise<AudienceDomain | undefined> => {
      try {
        dispatch(editAudience());

        const audienceResponse = await AudiencesService.editAudience(
          auth0Context,
          payload
        );

        dispatch(editAudienceSuccess(audienceResponse));
        return audienceResponse;
      } catch (err: any) {
        const errorMessage = getErrorDescriptionOrDefault(
          err.response?.data?.code,
          "Oops. We couldn't update this audience. Please try again!"
        );
        dispatch(editAudienceFail([errorMessage]));
        return undefined;
      }
    };

  const deleteAudienceWaterfall =
    (payload: DeleteAudiencePayload) => async () => {
      try {
        dispatch(deleteAudience());

        const audienceResponse = await AudiencesService.deleteAudience(
          auth0Context,
          merchant.id,
          payload
        );

        dispatch(deleteAudienceSuccess(audienceResponse));
      } catch (err: any) {
        const errorMessage = getErrorDescriptionOrDefault(
          err.response?.data?.code,
          "Oops. We couldn't delete this audience. Please try again!"
        );
        dispatch(deleteAudienceFail([errorMessage]));
      }
    };

  const fetchAudiencesAsync = useCallback(
    () => fetchAudiencesWaterfall()(),
    [dispatch, merchant]
  );

  const createAudienceAsync = useCallback(
    (payload: CreateAudiencePayload) => createAudienceWaterfall(payload)(),
    [dispatch]
  );

  const editAudienceAsync = useCallback(
    (payload: EditAudiencePayload) => editAudienceWaterfall(payload)(),
    [dispatch]
  );

  const deleteAudienceAsync = useCallback(
    (payload: DeleteAudiencePayload) => deleteAudienceWaterfall(payload)(),
    [dispatch, merchant]
  );

  return {
    fetchAudiences: fetchAudiencesAsync,
    createAudience: createAudienceAsync,
    editAudience: editAudienceAsync,
    deleteAudience: deleteAudienceAsync,
  };
}
