import { Auth0ContextInterface } from "@auth0/auth0-react";
import NotificationDomain from "entities/domain/notifications/domain";
import { NotificationDto } from "entities/dto/NotificationDTO";
import { putRequest, request } from "util/methods";
import { RequestType } from "./request-type";

export const fromDtoToDomain = (
  notificationDto: NotificationDto
): NotificationDomain => {
  return new NotificationDomain(
    notificationDto.id,
    notificationDto.title,
    notificationDto.message,
    notificationDto.deep_link,
    notificationDto.read,
    notificationDto.created_at
  );
};

export default class NotificationsService {
  public static async getNotifications(
    { getAccessTokenSilently }: Auth0ContextInterface,
    merchantId: number
  ): Promise<NotificationDomain[]> {
    const accessToken = await getAccessTokenSilently();

    const response = await request<NotificationDto[]>(
      RequestType.GET,
      accessToken,
      `/merchants/${merchantId}/in-app-notifications`
    );

    const notifications = response.data;

    return notifications.map(fromDtoToDomain);
  }

  public static async markNotificationAsRead(
    { getAccessTokenSilently }: Auth0ContextInterface,
    merchantId: number,
    notificationId: string
  ): Promise<void> {
    const accessToken = await getAccessTokenSilently();

    await putRequest(
      RequestType.PUT,
      accessToken,
      `/merchants/${merchantId}/in-app-notifications/${notificationId}`,
      {}
    );
  }

  public static async markAllNotificationsAsRead(
    { getAccessTokenSilently }: Auth0ContextInterface,
    merchantId: number
  ): Promise<void> {
    const accessToken = await getAccessTokenSilently();

    await putRequest(
      RequestType.PUT,
      accessToken,
      `/merchants/${merchantId}/in-app-notifications/read-all`,
      {}
    );
  }
}
