import { useAuth0 } from "@auth0/auth0-react";
import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  Flex,
  Heading,
  Icon,
  Image,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Step,
  StepIcon,
  StepIndicator,
  StepSeparator,
  StepStatus,
  Stepper,
  Text,
  useBreakpointValue,
  useColorMode,
  useSteps,
  useToast,
} from "@chakra-ui/react";
import PartyPopperImage from "assets/icons/party-popper.png";
import { ReactComponent as BankIcon } from "assets/icons/bank_google.svg";
import { ReactComponent as PhoneIcon } from "assets/icons/phone.svg";
import { ReactComponent as ShareIcon } from "assets/icons/share-android.svg";
import { MerchantUserGuides } from "entities/domain/admin/merchants/merchant-user-guides";
import UserGuideActionsDomain from "entities/domain/userGuides/user-guide-actions";
import { UserGuideStatus } from "entities/domain/userGuides/user-guides";
import useMerchantStore from "hooks/use-merchant-store";
import React, { useEffect, useState } from "react";
import {
  OnboardingStatus,
  setCurrentStep,
  setOnboardingStatus,
} from "redux/features/onboarding";
import UserGuidesService from "services/userGuides";
import { isMobileApp } from "util/methods";

import { completeUserGuide } from "redux/features/merchant";
import { useAppSelector, useAppDispatch } from "redux/hooks";
import StepOne from "./StepOne";
import StepThree from "./StepThree";
import StepTwo from "./StepTwo";

const Onboarding = () => {
  const auth0Context = useAuth0();
  const { colorScheme } = useAppSelector((state) => state.theme);
  const { colorMode } = useColorMode();
  const { merchant } = useAppSelector((state) => state.merchant);
  const toast = useToast();
  const dispatch = useAppDispatch();
  const { status, currentStep, progress } = useAppSelector(
    (state) => state.onboarding
  );

  const lastStepIndex = parseInt(
    Object.keys(progress).sort().pop() as string,
    10
  );
  const areAllStepsCompleted = currentStep > lastStepIndex;
  const isLastStep = currentStep === lastStepIndex;
  const isCurrentStepCompleted = progress[currentStep]?.isCompleted;

  const [mandatoryActions, setMandatoryActions] = useState<
    Array<UserGuideActionsDomain>
  >([]);
  const [socialsActions, setSocialsActions] = useState<
    Array<UserGuideActionsDomain>
  >([]);
  const [bankingActions, setBankingActions] = useState<
    Array<UserGuideActionsDomain>
  >([]);
  const steps = [
    {
      key: "step-1",
      component: <StepOne />,
      icon: PhoneIcon,
    },
    {
      key: "step-2",
      component: <StepTwo actions={socialsActions} />,
      icon: ShareIcon,
    },
    {
      key: "step-3",
      component: <StepThree actions={bankingActions} />,
      icon: BankIcon,
    },
  ];

  const { activeStep, setActiveStep } = useSteps({
    index: currentStep || 0,
    count: steps.length,
  });

  const handleCompleteOnboarding = (lastStepSkipped: boolean = false) => {
    UserGuidesService.completeGuide(
      auth0Context,
      merchant.id,
      MerchantUserGuides.ONBOARDING
    )
      .then((res) => {
        if (res) {
          dispatch(setOnboardingStatus(OnboardingStatus.COMPLETED));
          dispatch(completeUserGuide(MerchantUserGuides.ONBOARDING));
          dispatch(
            setCurrentStep({
              newCurrentStep: currentStep + 1,
              isPreviousStepSkipped: lastStepSkipped,
              isPreviousStepCompleted: !lastStepSkipped,
            })
          );
        }
      })
      .catch((err) => {
        setOnboardingStatus(OnboardingStatus.PAUSED);
        toast({
          status: "error",
          title: "Onboarding couldn't be completed.",
          description: "Please try again later.",
        });
      });
  };

  const [onboardingEnabled, setOnboardingEnabled] = useState<
    boolean | undefined
  >();

  useEffect(() => {
    if (status !== OnboardingStatus.IN_PROGRESS) {
      return;
    }

    if (merchant.userGuides.pending.includes(MerchantUserGuides.ONBOARDING)) {
      if (typeof onboardingEnabled === "undefined") {
        setOnboardingEnabled(true);
      }

      UserGuidesService.getGuides(
        auth0Context,
        merchant.id,
        MerchantUserGuides.ONBOARDING
      ).then((res) => {
        setMandatoryActions(res.actions.filter((r) => r.group === "mandatory"));
        setSocialsActions(res.actions.filter((r) => r.group === "socials"));
        setBankingActions(res.actions.filter((r) => r.group === "banking"));
      });
    }
  }, [status]);

  useEffect(() => {
    if (
      mandatoryActions.filter(
        (action: UserGuideActionsDomain) =>
          action.status === UserGuideStatus.PENDING
      ).length
    ) {
      setActiveStep(0);
      dispatch(
        setCurrentStep({
          newCurrentStep: 0,
          isPreviousStepSkipped: false,
          isPreviousStepCompleted: false,
        })
      );
    } else if (
      socialsActions.filter(
        (action: UserGuideActionsDomain) =>
          action.status === UserGuideStatus.PENDING
      ).length > 0
    ) {
      setActiveStep(1);
      dispatch(
        setCurrentStep({
          newCurrentStep: 1,
          isPreviousStepSkipped: false,
          isPreviousStepCompleted: true,
        })
      );
    } else if (
      bankingActions.filter(
        (action: UserGuideActionsDomain) =>
          action.status === UserGuideStatus.PENDING
      ).length > 0
    ) {
      setActiveStep(2);
      dispatch(
        setCurrentStep({
          newCurrentStep: 2,
          isPreviousStepSkipped: false,
          isPreviousStepCompleted: true,
        })
      );
    }
  }, [mandatoryActions, socialsActions, bankingActions]);

  const isBaseSize = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );

  let actionButtonText = "Continue";

  if (isLastStep) {
    actionButtonText = "Complete";
  }

  if (areAllStepsCompleted) {
    actionButtonText = "Close";
  }

  const [personalizedHelpTimeout, setPersonalizedHelpTimeout] = useState<
    number | undefined
  >();
  const [showPersonalizedHelp, setShowPersonalizedHelp] =
    useState<boolean>(false);

  useEffect(() => {
    if (personalizedHelpTimeout) {
      return;
    }

    const timeout = window.setTimeout(() => {
      setShowPersonalizedHelp(true);
    }, 1000 * 60 * 2);

    setPersonalizedHelpTimeout(timeout);

    return () => {
      clearTimeout(timeout);
    };
  }, [personalizedHelpTimeout]);

  useEffect(() => {
    if (areAllStepsCompleted || !personalizedHelpTimeout) {
      return;
    }

    clearTimeout(personalizedHelpTimeout);
    setPersonalizedHelpTimeout(undefined);
    setShowPersonalizedHelp(false);
  }, [currentStep]);

  return (
    <Modal
      size={isBaseSize ? "full" : "xl"}
      closeOnEsc={areAllStepsCompleted}
      closeOnOverlayClick={areAllStepsCompleted}
      isOpen={!!onboardingEnabled}
      onClose={() => {
        if (
          merchant.userGuides.pending.includes(MerchantUserGuides.ONBOARDING) &&
          status === OnboardingStatus.IN_PROGRESS
        ) {
          setOnboardingStatus(OnboardingStatus.PAUSED);
        } else {
          setOnboardingEnabled(false);
        }
      }}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalBody>
          {showPersonalizedHelp && (
            <Alert borderRadius="lg" mt={4}>
              <AlertIcon />
              <Flex direction="column">
                <AlertTitle>Want Need help?</AlertTitle>
                <AlertDescription>
                  <Button
                    colorScheme={colorScheme}
                    fontWeight={800}
                    variant="link"
                    textTransform="none"
                    onClick={() => {
                      if (isMobileApp()) {
                        window.natively.openExternalURL(
                          "https://calendly.com/hector-105/30min"
                        );
                      } else {
                        window.open(
                          "https://calendly.com/hector-105/30min",
                          "_blank"
                        );
                      }
                    }}
                  >
                    Schedule a call
                  </Button>{" "}
                  with one of our onboarding specialists and we&apos;ll get you
                  set up!
                </AlertDescription>
              </Flex>
            </Alert>
          )}
          <Stepper index={activeStep} colorScheme={colorScheme} my={6}>
            {steps.map((step, index) => (
              <Step
                key={index}
                onClick={() => {
                  if (index === activeStep) {
                    return;
                  }

                  if (progress[index].isCompleted && activeStep !== 0) {
                    return;
                  }

                  setActiveStep(index);
                  dispatch(
                    setCurrentStep({
                      newCurrentStep: index,
                      isPreviousStepSkipped: true,
                      isPreviousStepCompleted: false,
                    })
                  );
                }}
                style={{
                  cursor: "pointer",
                }}
              >
                <StepIndicator>
                  <StepStatus
                    complete={<StepIcon />}
                    incomplete={
                      <Icon
                        as={step.icon}
                        __css={{
                          ...(index === steps.length - 1
                            ? {
                                path: {
                                  fill:
                                    colorMode === "dark"
                                      ? `${colorScheme}.200`
                                      : `${colorScheme}.500`,
                                },
                              }
                            : {
                                stroke:
                                  colorMode === "dark"
                                    ? `${colorScheme}.200`
                                    : `${colorScheme}.500`,
                              }),
                        }}
                      />
                    }
                    active={
                      <Icon
                        as={step.icon}
                        __css={{
                          ...(index === steps.length - 1
                            ? {
                                path: {
                                  fill:
                                    colorMode === "dark"
                                      ? `${colorScheme}.200`
                                      : `${colorScheme}.500`,
                                },
                              }
                            : {
                                stroke:
                                  colorMode === "dark"
                                    ? `${colorScheme}.200`
                                    : `${colorScheme}.500`,
                              }),
                        }}
                      />
                    }
                  />
                </StepIndicator>

                <StepSeparator />
              </Step>
            ))}
          </Stepper>
          {steps[activeStep] && steps[activeStep].component}
          {areAllStepsCompleted ? (
            <Flex
              justifyContent="center"
              alignItems="center"
              direction="column"
            >
              <Image src={PartyPopperImage} />
              <Heading>Hooray!</Heading>
              <Text>You&apos;re all set up!</Text>
            </Flex>
          ) : null}
        </ModalBody>

        <ModalFooter
          justifyContent={
            activeStep === 0 || areAllStepsCompleted ? "end" : "space-between"
          }
        >
          {activeStep !== 0 && !areAllStepsCompleted && (
            <Button
              colorScheme={colorScheme}
              variant="ghost"
              onClick={() => {
                setOnboardingEnabled(undefined);
                setOnboardingStatus(OnboardingStatus.PAUSED);
              }}
            >
              Set up later
            </Button>
          )}
          <Box>
            <Button
              colorScheme={colorScheme}
              variant="outline"
              mr={3}
              onClick={() => {
                if (isLastStep) {
                  handleCompleteOnboarding(true);
                } else {
                  dispatch(
                    setCurrentStep({
                      newCurrentStep: currentStep + 1,
                      isPreviousStepSkipped: true,
                      isPreviousStepCompleted: false,
                    })
                  );
                }

                setActiveStep(activeStep + 1);
              }}
            >
              Skip
            </Button>
            <Button
              colorScheme={colorScheme}
              isDisabled={!isCurrentStepCompleted && !areAllStepsCompleted}
              onClick={() => {
                if (areAllStepsCompleted) {
                  setOnboardingEnabled(false);
                }

                if (!isCurrentStepCompleted) {
                  return;
                }

                if (isLastStep) {
                  handleCompleteOnboarding();
                } else {
                  dispatch(
                    setCurrentStep({
                      newCurrentStep: currentStep + 1,
                      isPreviousStepSkipped: false,
                      isPreviousStepCompleted: true,
                    })
                  );
                }

                setActiveStep(activeStep + 1);
              }}
            >
              {actionButtonText}
            </Button>
          </Box>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default Onboarding;
