import React, { useEffect, useState } from "react";
import { FormControl, FormLabel, useColorMode } from "@chakra-ui/react";
import WarningTextComponent, {
  WarningTextProps,
} from "components/shared/WarningTextComponent";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import Select, { SingleValue } from "react-select";
import { getReactSelectStyles } from "util/methods";
import { useAppSelector } from "redux/hooks";
import { ChannelHandleType } from ".";

type PhoneBasedChannelProps = {
  channelValue: string;
  setHandle: (value: ChannelHandleType | null) => void;
};

interface CountryCodeOptionTypes {
  value: string;
  label: string;
}

const countryCodeOptions: CountryCodeOptionTypes[] = [
  { value: "GB", label: "United Kingdom" },
  { value: "US", label: "United States of America" },
  { value: "CA", label: "Canada" },
];

const PhoneBasedChannel = ({
  channelValue,
  setHandle,
}: PhoneBasedChannelProps) => {
  const [countryCode, setCountryCode] = useState<string>("");
  const [identifierValue, setIdentifierValue] = useState<string>("");
  const [selectedCountryCodeValue, setSelectedCountryCodeValue] =
    useState<SingleValue<CountryCodeOptionTypes>>(null);
  const [warning, setWarning] = useState<WarningTextProps | undefined>(
    undefined
  );
  const { colorMode } = useColorMode();
  const { colorScheme } = useAppSelector((state) => state.theme);

  useEffect(() => {
    setWarning(undefined);
  }, []);

  const isValidPhone = () => {
    return identifierValue &&
      identifierValue !== "" &&
      !isValidPhoneNumber(identifierValue)
      ? setWarning({
          key: "invalid_phone_number",
          description: "* Invalid phone number format",
        })
      : setWarning(undefined);
  };

  const handleChangeCountryCode = (e: SingleValue<CountryCodeOptionTypes>) => {
    setCountryCode(e!.value);
    setSelectedCountryCodeValue(e);
    setIdentifierValue("");
    setWarning(undefined);
    setHandle({ country_code: e!.value });
  };

  const handleChangeIdentifier = (value: string) => {
    setIdentifierValue(value);
    setCountryCode("");
    setSelectedCountryCodeValue(null);
    if (value && isValidPhoneNumber(value)) {
      setHandle({ identifier: value });
    } else {
      setHandle(null);
    }
  };

  return (
    <>
      <FormControl
        sx={{
          ".PhoneInputInput": {
            backgroundColor: "inherit",
            color: "inherit",
          },
        }}
        isRequired
      >
        <FormLabel>Phone Number </FormLabel>
        <small> You can manually enter phone number here </small>
        {warning && warning.key === "invalid_phone_number" && (
          <WarningTextComponent isValid={false} text={warning.description} />
        )}
        <PhoneInput
          id="phoneInput"
          style={{ borderRadius: "18px" }}
          placeholder="Enter phone number"
          onChange={handleChangeIdentifier}
          value={identifierValue}
          autoFocus={true}
          onBlur={isValidPhone}
        />
      </FormControl>

      <FormControl
        isRequired
        style={{ display: channelValue === "sms" ? "" : "none" }}
      >
        <span
          style={{
            fontWeight: "bold",
            textAlign: "center",
            display: "flex",
            justifyContent: "center",
            paddingTop: "10px",
          }}
        >
          OR
        </span>

        <FormLabel> Country Code </FormLabel>
        <small> You can buy a phone number below</small>
        <Select
          placeholder="Select Country Code"
          onChange={handleChangeCountryCode}
          options={countryCodeOptions}
          value={selectedCountryCodeValue}
          styles={getReactSelectStyles(colorMode, colorScheme)}
        />
      </FormControl>
    </>
  );
};

export default PhoneBasedChannel;
