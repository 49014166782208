import CalendarBookingDomain from "entities/domain/calendar/calendar-domain";
import { CalendarBookingDTO } from "entities/dto/CalendarDTO";

const calendarTransformFromDtoToDomain = (
  event: CalendarBookingDTO
): CalendarBookingDomain =>
  new CalendarBookingDomain(
    event.booking_id,
    event.start_at,
    event.end_at,
    event.agent_ids,
    event.customer_id,
    event.title,
    event.description,
    event.notify_channel_selected
  );

export default calendarTransformFromDtoToDomain;
