/* eslint-disable import/prefer-default-export */
import AudienceDomain from "entities/domain/audience";
import { AudienceDTO } from "entities/dto/AudienceDTO";

export const audienceTransformFromDtoToDomain = (
  a: AudienceDTO
): AudienceDomain =>
  new AudienceDomain(
    a.id,
    a.name,
    a.merchant_id,
    a.count,
    a.structured_criteria
  );
