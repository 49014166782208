import { AspectRatio, Icon, Image, useColorMode } from "@chakra-ui/react";
import React from "react";
import { useAppSelector } from "redux/hooks";
import { ReactComponent as PDFFile } from "assets/icons/file-pdf-box.svg";
import { ReactComponent as File } from "assets/icons/file.svg";
import { SUPPORTED_MEDIA_TYPES } from "../FilePreview";

interface SmallMediaTypeProps {
  type: string;
  url: string;
  id: string;
  index: number;
  startIndex: number;
  brokenMedia: string[];
  setBrokenMedia: React.Dispatch<React.SetStateAction<string[]>>;
}

const SmallMediaType = ({
  type,
  url,
  id,
  index,
  startIndex,
  brokenMedia,
  setBrokenMedia,
}: SmallMediaTypeProps) => {
  const { colorMode } = useColorMode();
  const { colorScheme } = useAppSelector((state) => state.theme);

  return (
    <>
      {type.includes("image") && SUPPORTED_MEDIA_TYPES.includes(type) && (
        <Image
          src={url}
          alt={`item-${id}`}
          boxSize="100%"
          objectFit="cover"
          borderRadius="lg"
          {...(index === startIndex
            ? {
                borderWidth: "3px",
                borderStyle: "solid",
                borderColor:
                  colorMode === "dark"
                    ? `${colorScheme}.200`
                    : `${colorScheme}.600`,
              }
            : {})}
          width="10rem"
          height="10rem"
          onError={() => {
            setBrokenMedia([...brokenMedia, id]);
          }}
        />
      )}
      {type.includes("video") && SUPPORTED_MEDIA_TYPES.includes(type) && (
        <AspectRatio
          borderRadius="lg"
          maxWidth="10rem"
          width="10rem"
          height="10rem"
          maxHeight="10rem"
          {...(index === startIndex
            ? {
                borderWidth: "3px",
                borderStyle: "solid",
                borderColor:
                  colorMode === "dark"
                    ? `${colorScheme}.200`
                    : `${colorScheme}.600`,
              }
            : {})}
        >
          <video
            controls
            style={{
              borderRadius: "0.5rem",
            }}
            onError={() => {
              setBrokenMedia([...brokenMedia, id]);
            }}
          >
            <source src={url} type={type} />
          </video>
        </AspectRatio>
      )}
      {type.includes("audio") && SUPPORTED_MEDIA_TYPES.includes(type) && (
        <AspectRatio
          borderRadius="lg"
          maxWidth="10rem"
          width="10rem"
          height="10rem"
          maxHeight="10rem"
          {...(index === startIndex
            ? {
                borderWidth: "3px",
                borderStyle: "solid",
                borderColor:
                  colorMode === "dark"
                    ? `${colorScheme}.200`
                    : `${colorScheme}.600`,
              }
            : {
                borderWidth: "1px",
                borderStyle: "solid",
                borderColor: "gray.50",
              })}
        >
          <audio
            controls
            style={{
              borderRadius: "0.5rem",
            }}
            onError={() => {
              setBrokenMedia([...brokenMedia, id]);
            }}
          >
            <source src={url} type={type} />
          </audio>
        </AspectRatio>
      )}
      {type.includes("pdf") && SUPPORTED_MEDIA_TYPES.includes(type) && (
        <Icon
          as={PDFFile}
          width="10rem"
          height="10rem"
          borderRadius="lg"
          {...(index === startIndex
            ? {
                borderWidth: "3px",
                borderStyle: "solid",
                borderColor:
                  colorMode === "dark"
                    ? `${colorScheme}.200`
                    : `${colorScheme}.600`,
              }
            : {
                borderWidth: "1px",
                borderStyle: "solid",
                borderColor: "gray.50",
              })}
          fill={
            colorMode === "dark" ? `${colorScheme}.200` : `${colorScheme}.500`
          }
        />
      )}
      {!SUPPORTED_MEDIA_TYPES.includes(type) && (
        <Icon
          as={File}
          width="10rem"
          height="10rem"
          {...(index === startIndex
            ? {
                borderWidth: "3px",
                borderStyle: "solid",
                borderColor:
                  colorMode === "dark"
                    ? `${colorScheme}.200`
                    : `${colorScheme}.600`,
              }
            : {
                borderWidth: "1px",
                borderStyle: "solid",
                borderColor: "gray.50",
              })}
          borderRadius="lg"
          fill={
            colorMode === "dark" ? `${colorScheme}.200` : `${colorScheme}.500`
          }
        />
      )}
    </>
  );
};

export default SmallMediaType;
