export default class MerchantIntegration {
  name: string;

  connected: boolean;

  accountInfoEnabled: boolean;

  constructor(
    name: string,
    connected: boolean,
    accountInfoEnabled: boolean = false
  ) {
    this.name = name;
    this.connected = connected;
    this.accountInfoEnabled = accountInfoEnabled;
  }

  isReviewIntegration() {
    return this.name === "google";
  }
}

export enum IntegrationName {
  GOOGLE_REVIEWS = "google",
  FACEBOOK_REVIEWS = "facebook",
  ZAPIER = "zapier",
  MESSENGER = "messenger",
  WHATSAPP = "whatsapp",
  STRIPE = "stripe",
  QUICKBOOKS = "quickbooks",
  XERO = "xero",
  INSTAGRAM = "instagram",
  GMAIL = "gmail",
  OUTLOOK = "outlook",
  KEYLOOP = "keyloop",
}

export type MerchantIntegrations = {
  [key in IntegrationName]: MerchantIntegration;
};
