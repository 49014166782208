export default class TeamDomain {
  id: string;

  merchantId: number;

  name: string;

  purpose: string;

  agentIds: number[];

  createdAt: string;

  updatedAt: string;

  constructor(
    id: string,
    merchantId: number,
    name: string,
    purpose: string,
    agentIds: number[],
    createdAt: string,
    updatedAt: string
  ) {
    this.id = id;
    this.merchantId = merchantId;
    this.name = name;
    this.purpose = purpose;
    this.agentIds = agentIds;
    this.createdAt = createdAt;
    this.updatedAt = updatedAt;
  }
}
