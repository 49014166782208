import MessageDomain from "entities/domain/conversations/message-domain";
import { motion } from "framer-motion";
import React, { forwardRef, ForwardRefRenderFunction, memo } from "react";
import { Flex } from "@chakra-ui/react";
import AudioMessage from "./AudioMessage";
import FileMessage from "./FileMessage";
import PhoneCallMessage from "./PhoneCallMessage";
import VideoMessage from "./VideoMessage";
import ImageMessage from "./ImageMessage";
import UrlMessage from "./UrlMessage";
import TextMessage from "./TextMessage";
import Microphone from "./Microphone";
import PhoneCallWithAudio from "./PhoneCallWithAudio";
import MultipleAttachmentsMessage from "./MultipleAttachmentsMessage";
import InfoMessage from "./InfoMessage";
import VehicleLead from "./VehicleLead";
import ForwardedEmailMessage from "./ForwardedEmailMessage";

interface MessageProps {
  value: MessageDomain;
  fadeIn?: boolean;
  showTail: boolean;
}

const Message: ForwardRefRenderFunction<HTMLDivElement, MessageProps> = (
  { value: message, showTail, fadeIn = false },
  ref
) => {
  return (
    <motion.div
      initial={{ opacity: fadeIn ? 0 : 1 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0, height: 0 }}
      transition={{ duration: 1 }}
      key={message.id}
      id="message-component"
      ref={ref}
    >
      <Flex
        direction={message.isIncoming ? "row" : "row-reverse"}
        margin="5px 0"
      >
        {message.isPhoneCallWithoutRecording() && (
          <PhoneCallMessage message={message} />
        )}
        {message.isPhoneCallRecording() && (
          <PhoneCallWithAudio message={message} />
        )}
        {message.isVehicleInfo() && <VehicleLead message={message} />}
        {message.isAudioMessage() && (
          <>
            <Microphone message={message} />
            <AudioMessage message={message} showTail={showTail} />
          </>
        )}
        {message.hasMultipleAttachments() && (
          <MultipleAttachmentsMessage message={message} showTail={showTail} />
        )}
        {message.isFileMessage() && (
          <FileMessage message={message} showTail={showTail} />
        )}
        {message.isVideoMessage() && (
          <VideoMessage message={message} showTail={showTail} />
        )}
        {message.isImageMessage() && (
          <ImageMessage message={message} showTail={showTail} />
        )}
        {message.isURL() && (
          <UrlMessage message={message} showTail={showTail} />
        )}
        {message.isTextMessage() && (
          <TextMessage message={message} showTail={showTail} />
        )}
        {message.isForwardedEmail() && (
          <ForwardedEmailMessage message={message} showTail={showTail} />
        )}
        {message.isInfoMessage() && <InfoMessage message={message} />}
      </Flex>
    </motion.div>
  );
};

export default memo(forwardRef(Message));
