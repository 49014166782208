export interface WhatsappButtonDTO {
  type: string;
  text: string;
  url?: string;
  phone_number?: string;
}

export interface WhatsappTemplateExampleDTO {
  body_text?: string[][];
  header_handle?: string[];
  header_text?: string[];
}

export interface WhatsappTemplateParametersDTO {
  type: string;
  location: {
    longitude: string;
    latitude: string;
    name: string;
    address: string;
  };
}

export interface WhatsappTemplateComponentDTO {
  type: string;
  format: string;
  text: string;
  buttons: WhatsappButtonDTO[] | null;
  example?: WhatsappTemplateExampleDTO;
  parameters?: WhatsappTemplateParametersDTO;
}

export interface WhatsappTemplateDTO {
  id: string;
  name: string;
  merchant_id: number;
  category: string;
  status: string;
  rejected_reason: string;
  components: WhatsappTemplateComponentDTO[];
  examples: { [key: string]: string } | null;
  namespace: string;
}

export function isWhatsappTemplateDTO(obj: any): obj is WhatsappTemplateDTO {
  return (
    obj &&
    typeof obj.id === "string" &&
    typeof obj.name === "string" &&
    typeof obj.merchant_id === "number" &&
    typeof obj.category === "string" &&
    typeof obj.status === "string" &&
    (typeof obj.rejected_reason === "string" || obj.rejected_reason === null) &&
    Array.isArray(obj.components) &&
    ((typeof obj.examples === "object" && !Array.isArray(obj.examples)) ||
      obj.examples === null) &&
    typeof obj.namespace === "string"
  );
}
