import { useAuth0 } from "@auth0/auth0-react";
import { EmailIcon } from "@chakra-ui/icons";
import React, {
  Box,
  Button,
  Flex,
  Icon,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Spinner,
  useBreakpointValue,
  useToast,
} from "@chakra-ui/react";
import { useAppSelector } from "redux/hooks";
import useMerchantStore from "hooks/use-merchant-store";
import { memo, useEffect, useState } from "react";

import InboxService from "services/inbox";
import {
  Heading,
  HeadingSize,
} from "theme/old-design-system/styled-components";

interface ExportToEmailModalProps {
  isOpen: boolean;
  conversationId: number;
  onClose: () => void;
}

const ExportToEmailModal = memo(
  ({ onClose, conversationId, isOpen }: ExportToEmailModalProps) => {
    const isBaseSize = useBreakpointValue(
      { base: true, md: false },
      { ssr: false }
    );
    const toast = useToast();
    const auth0Context = useAuth0();
    const { merchant } = useAppSelector((state) => state.merchant);
    const { colorScheme } = useAppSelector((state) => state.theme);

    const [email, setEmail] = useState<string>("");
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

    const resetValues = () => {
      setIsSubmitting(false);
      setEmail("");
    };

    const submitExportAndClose = () => {
      setIsSubmitting(true);
      InboxService.exportConversation(
        auth0Context,
        conversationId,
        email,
        merchant.id
      )
        .then(() => {
          toast({ status: "success", title: "Exported Successfully" });
          setIsSubmitting(false);
        })
        .catch(() =>
          toast({ status: "error", title: "Failed to export conversation" })
        )
        .finally(() => {
          resetValues();
          onClose();
        });
    };

    useEffect(() => {
      if (!isOpen) {
        resetValues();
      }
    }, [isOpen]);

    return (
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        size={isBaseSize ? "full" : "xl"}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton _focus={{ outline: "none" }} />

          <Flex alignItems="center" padding="28px 40px">
            <Icon as={EmailIcon} height="22.4px" marginRight="12px" />
            <Heading size={HeadingSize.FOUR}>Export to Email</Heading>
          </Flex>
          {isSubmitting ? (
            <Box m={10}>
              <Spinner />
            </Box>
          ) : (
            <ModalBody>
              <Input
                value={email}
                onChange={(event) => setEmail(event.target.value)}
                placeholder="e.g. leads@enquirymax.com"
              />
              <Button
                colorScheme={colorScheme}
                isDisabled={email === ""}
                onClick={submitExportAndClose}
                width="100%"
                mt={8}
                mb={4}
                size="md"
              >
                Confirm
              </Button>
            </ModalBody>
          )}
        </ModalContent>
      </Modal>
    );
  }
);

export default ExportToEmailModal;
