import { Button } from "@chakra-ui/button";
import { useColorMode } from "@chakra-ui/color-mode";
import { Icon } from "@chakra-ui/icon";
import { Flex, HStack, Text, VStack } from "@chakra-ui/layout";
import { Spinner } from "@chakra-ui/spinner";
import { ReactComponent as SelectAllIcon } from "assets/icons/select-all-svgrepo-com.svg";
import { ReactComponent as DeselectAllIcon } from "assets/icons/select-all-off-svgrepo-com.svg";
import React from "react";
import { IoPeopleOutline } from "react-icons/io5";
import { batch } from "react-redux";
import {
  clearSelectedAgents,
  disableTeamCreationTool,
  updateAgentSelection,
} from "redux/features/agents";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { CloseIcon } from "@chakra-ui/icons";
import { FaMinus, FaPlus } from "react-icons/fa";

interface TeamCreationToolProps {
  openAddToTeamModal: () => void;
  openRemoveFromTeamModal: () => void;
}

const TeamCreationTool = ({
  openAddToTeamModal,
  openRemoveFromTeamModal,
}: TeamCreationToolProps) => {
  const { colorMode } = useColorMode();
  const dispatch = useAppDispatch();
  const { colorScheme } = useAppSelector((state) => state.theme);
  const {
    isTeamCreationToolLoading,
    selectedAgentIds,
    isTeamCreationToolEnabled,
    agents,
  } = useAppSelector((state) => state.agents);

  return (
    <>
      <VStack
        transition="all 2s ease-out"
        w="100%"
        bgColor={
          colorMode === "dark" ? `${colorScheme}.200` : `${colorScheme}.500`
        }
        borderRadius="xl"
        overflow="hidden"
        color={colorMode === "dark" ? "gray.800" : "white"}
      >
        <HStack
          alignItems="center"
          justifyContent="center"
          spacing={0}
          height="80px"
          position="relative"
        >
          {!isTeamCreationToolLoading ? null : (
            <Flex
              alignItems="center"
              justifyContent="center"
              position="absolute"
              left={0}
              top={0}
              width="100%"
              height="100%"
              zIndex={2}
              bgColor={
                colorMode === "dark" ? "blackAlpha.600" : "whiteAlpha.600"
              }
            >
              <Spinner />
            </Flex>
          )}
          <VStack
            height="100%"
            spacing={0}
            p={2}
            justifyContent="center"
            bgColor={
              colorMode === "dark" ? `${colorScheme}.500` : `${colorScheme}.600`
            }
          >
            <Text fontSize="xl" color="white">
              {selectedAgentIds.length}
            </Text>
            <Text fontSize="md" color="white">
              Selected
            </Text>
          </VStack>
          <Button
            height="100%"
            borderRadius={0}
            colorScheme={colorScheme}
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            isDisabled={!isTeamCreationToolEnabled}
            gridGap={2}
            onClick={() => {
              if (selectedAgentIds.length === agents.length) {
                dispatch(clearSelectedAgents());

                return;
              }

              batch(() => {
                agents.forEach((c) => {
                  dispatch(
                    updateAgentSelection({
                      agentId: c.id,
                      isSelected: true,
                    })
                  );
                });
              });
            }}
          >
            <Icon
              as={
                selectedAgentIds.length === agents.length
                  ? DeselectAllIcon
                  : SelectAllIcon
              }
              w={6}
              h={6}
              __css={{
                g: {
                  fill: colorMode === "dark" ? "gray.800" : "white",
                },
              }}
            />
            {selectedAgentIds.length === agents.length
              ? "Deselect All"
              : "Select All"}
          </Button>
          <Button
            height="100%"
            borderRadius={0}
            display="flex"
            colorScheme={colorScheme}
            flexDirection="column"
            justifyContent="space-between"
            gridGap={2}
            isDisabled={!isTeamCreationToolEnabled}
            onClick={() => {
              dispatch(disableTeamCreationTool());
              openAddToTeamModal();
            }}
          >
            <VStack position="relative" justifyContent="start">
              <Icon
                as={IoPeopleOutline}
                w={6}
                h={6}
                mr="0.3rem"
                __css={{
                  path: {
                    fill: colorMode === "dark" ? "gray.800" : "white",
                  },
                }}
              />
              <Icon
                as={FaPlus}
                w={3}
                h={3}
                position="absolute"
                top="-0.25rem"
                right="-0.25rem"
              />
            </VStack>
            Add to Team
          </Button>
          <Button
            height="100%"
            borderRadius={0}
            display="flex"
            colorScheme={colorScheme}
            flexDirection="column"
            justifyContent="space-between"
            gridGap={2}
            isDisabled={!isTeamCreationToolEnabled}
            onClick={() => {
              dispatch(disableTeamCreationTool());
              openRemoveFromTeamModal();
            }}
          >
            <VStack position="relative" justifyContent="start">
              <Icon
                as={IoPeopleOutline}
                w={6}
                h={6}
                mr="0.3rem"
                __css={{
                  path: {
                    fill: colorMode === "dark" ? "gray.800" : "white",
                  },
                }}
              />
              <Icon
                as={FaMinus}
                w={3}
                h={3}
                position="absolute"
                top="-0.25rem"
                right="-0.25rem"
              />
            </VStack>
            Remove from Team
          </Button>
          <Button
            borderLeftWidth="1px"
            borderLeftStyle="solid"
            colorScheme={colorScheme}
            borderLeftColor={colorMode === "dark" ? "gray.800" : "white"}
            height="100%"
            borderRadius={0}
            display="flex"
            flexDirection="column"
            justifyContent="center"
            gridGap={2}
            isDisabled={!isTeamCreationToolEnabled}
            onClick={() => {
              dispatch(clearSelectedAgents());
            }}
          >
            <Icon as={CloseIcon} w={4} h={4} mx={2} />
          </Button>
        </HStack>
      </VStack>
    </>
  );
};

export default TeamCreationTool;
