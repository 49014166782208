export default class PublicInvoiceDomain {
  id: string;

  url: string;

  constructor(id: string, url: string) {
    this.id = id;
    this.url = url;
  }
}
