import { Button } from "@chakra-ui/button";
import { Icon } from "@chakra-ui/icon";
import Integration from "components/user-settings/shared/Integration";
import IntegrationDescription from "components/user-settings/shared/IntegrationDescription";
import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { ReactComponent as StripeIcon } from "assets/icons/stripe.svg";
import { FaPlus } from "react-icons/fa";
import PaymentsService from "services/payments";
import { useAuth0 } from "@auth0/auth0-react";

import IntegrationService from "services/integrations";
import { updateMerchantSuccess } from "redux/features/merchant";
import DisconnectIntegrationButton from "components/user-settings/shared/DisconnectIntegrationButton";
import { MerchantUserGuides } from "entities/domain/admin/merchants/merchant-user-guides";
import { IntegrationName } from "entities/domain/admin/merchants/merchant-integrations";
import { useToast } from "@chakra-ui/react";

interface StripeIntegrationItemProps {}

const StripeIntegrationItem = (_props: StripeIntegrationItemProps) => {
  const dispatch = useAppDispatch();
  const auth0Context = useAuth0();
  const merchant = useAppSelector((state) => state.merchant.merchant);
  const toast = useToast();
  const { colorScheme } = useAppSelector((state) => state.theme);

  const isConnected = merchant.isIntegrationEnabled(IntegrationName.STRIPE);
  const [isDisabled, setIsDisabled] = useState<boolean>(false);
  const [isRedirecting, setIsRedirecting] = useState<boolean>(false);

  const generateStripeLink = async () => {
    try {
      setIsRedirecting(true);

      await PaymentsService.createStripeAccountLink(
        auth0Context,
        merchant.id
      ).then((response) => {
        window.open(response.url, "_blank");
      });
    } catch (err) {
      toast({ status: "error", title: "An Error Occurred" });
    } finally {
      setIsRedirecting(false);
    }
  };

  const onDisconnectStripe = async () => {
    try {
      await IntegrationService.removeStripeIntegration(
        auth0Context,
        merchant.id
      );

      merchant.integrations[IntegrationName.STRIPE].connected = false;
      dispatch(updateMerchantSuccess(merchant));
    } catch (_error) {
      toast({
        status: "error",
        title:
          "Uh Oh! We could not disconnect Stripe at this time. Please try again",
      });
    }
  };

  useEffect(() => {
    if (merchant.userGuides.pending.includes(MerchantUserGuides.ONBOARDING)) {
      setIsDisabled(true);

      return;
    }

    if (isRedirecting) {
      setIsDisabled(true);

      return;
    }

    setIsDisabled(false);
  }, [isRedirecting, merchant.userGuides.pending]);

  return (
    <Integration
      isConnected={isConnected}
      icon={<Icon as={StripeIcon} height="3rem" width="8rem" />}
      secondaryButton={
        isConnected && !isDisabled ? (
          <DisconnectIntegrationButton onClick={onDisconnectStripe} />
        ) : null
      }
      primaryButton={
        isConnected || isDisabled ? null : (
          <Button
            colorScheme={colorScheme}
            leftIcon={<Icon as={FaPlus} />}
            onClick={() => {
              generateStripeLink();
            }}
          >
            Connect
          </Button>
        )
      }
    >
      <IntegrationDescription text="Install Card Payments in 5 minutes" />
    </Integration>
  );
};

export default StripeIntegrationItem;
