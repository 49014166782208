import { useColorMode } from "@chakra-ui/color-mode";
import { Flex } from "@chakra-ui/layout";
import { StyleProps } from "@chakra-ui/styled-system";
import React, { CSSProperties, useEffect, useRef, useState } from "react";
import { createSearchParams, useLocation, useNavigate } from "react-router-dom";

import { Color } from "theme/old-design-system/styled-components";
import { canManageTeamInbox } from "util/permissions";
import { useAppSelector } from "redux/hooks";
import { Spinner, useToast } from "@chakra-ui/react";
import useAnalytics from "hooks/use-analytics";
import MessageList from "./message-list";
import NoChat from "./no-chat-selected/NoChat";
import TopArea from "./top-area";

interface MessagesAreaProps {
  styles?: StyleProps;
}
const MessagesArea = ({ styles }: MessagesAreaProps) => {
  const { search } = useLocation();
  const toast = useToast();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isFirstLoad, setIsFirstLoad] = useState<boolean>(true);

  const {
    activeConversationId,
    activeConversation,
    isLoadingActiveConversation,
  } = useAppSelector((state) => state.conversations);
  const { currentAgent } = useAppSelector((state) => state.agents);
  const { merchant } = useAppSelector((state) => state.merchant);
  const navigate = useNavigate();
  const { track } = useAnalytics();
  const { colorMode } = useColorMode();

  const [currentChatBackground, setCurrentChatBackground] = useState<
    StyleProps | undefined
  >(currentAgent!.getChatBackgroundStyles(colorMode));

  useEffect(() => {
    const newChatBackground = currentAgent!.getChatBackgroundStyles(colorMode);

    setCurrentChatBackground(newChatBackground);
  }, [currentAgent]);

  const onBackgroundChange = (backgroundStyles: StyleProps) => {
    setCurrentChatBackground(backgroundStyles);
  };

  useEffect(() => {
    if (!activeConversationId) {
      return;
    }

    if (activeConversation && !isLoadingActiveConversation) {
      track("conversation_opened", {
        conversation_id: activeConversationId,
        channel: activeConversation?.channel,
        unread_count: activeConversation?.unreadCount,
      });

      setIsFirstLoad(false);
    } else if (!activeConversation && !isLoadingActiveConversation) {
      navigate({
        pathname: `/${merchant.id}/inbox/`,
        search: createSearchParams(search).toString(),
      });
    }
  }, [isLoadingActiveConversation]);

  useEffect(() => {
    if (
      activeConversation &&
      activeConversation.assignedAgentId != null &&
      !canManageTeamInbox(merchant.id, currentAgent!) &&
      activeConversation.assignedAgentId !== currentAgent!.id
    ) {
      setIsLoading(true);
      toast({
        status: "error",
        title: "You are not authorized to view this conversation",
      });

      const redirectToInbox = setTimeout(() => {
        navigate({
          pathname: `/${merchant.id}/inbox/`,
          search: createSearchParams(search).toString(),
        });
      }, 1000);

      return () => {
        clearTimeout(redirectToInbox);
      };
    }
  }, [activeConversationId]);

  const topBarRef = useRef<HTMLDivElement | null>(null);

  if (!activeConversation) {
    return <NoChat />;
  }

  return (
    <Flex w="100%" h="100%">
      <Flex
        position="relative"
        flex="1 1 1px"
        style={styles as CSSProperties | undefined}
        direction="column"
        pt={`${topBarRef?.current?.clientHeight || 0}px`}
        {...currentChatBackground}
        width="100%"
        height="100%"
      >
        {isLoading ? (
          <Flex
            w="100%"
            h="100%"
            background={colorMode === "dark" ? "gray.900" : "gray.50"}
            justifyContent="center"
            alignItems="center"
          >
            <Spinner size="xl" />
          </Flex>
        ) : (
          <>
            <TopArea ref={topBarRef} />
            <MessageList
              isFirstLoad={isFirstLoad}
              onBackgroundChange={onBackgroundChange}
            />
          </>
        )}
      </Flex>
    </Flex>
  );
};

export default MessagesArea;
