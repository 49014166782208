import { Flex, Icon, useColorMode, IconButton } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import ReviewsSearch from "components/reviews/ReviewsSearch";
import { ReactComponent as SortAscIcon } from "assets/icons/arrow-down-a-z-svgrepo-com.svg";
import { ReactComponent as SortDescIcon } from "assets/icons/arrow-down-z-a-svgrepo-com.svg";
import { useAppSelector } from "redux/hooks";
import useDebounce from "hooks/use-debounce";
import { CampaignCategory } from "redux/features/campaigns";

interface FilterSortingAreaProps {
  direction: string;
  searchText: string;
  setDirection: (newDir: string) => void;
  setSearchText: (newText: string) => void;
}

const FilterSortingArea = ({
  direction,
  searchText,
  setDirection,
  setSearchText,
}: FilterSortingAreaProps) => {
  const { colorMode } = useColorMode();
  const { colorScheme } = useAppSelector((state) => state.theme);
  const { activeCampaignCategory } = useAppSelector((state) => state.campaigns);

  const [localSearchText, setLocalSearchText] = useState<string>(
    searchText || ""
  );

  const debouncedSearchText = useDebounce<string>(localSearchText, 300);

  useEffect(() => {
    if (searchText === debouncedSearchText) {
      return;
    }
    setSearchText(debouncedSearchText);
  }, [debouncedSearchText]);

  return (
    <Flex flexGrow={1} justifyContent="start" alignItems="center" gridGap={1}>
      <ReviewsSearch
        placeholder="Search by name"
        value={localSearchText}
        onChange={(e) => setLocalSearchText(e.target.value)}
      />
      {activeCampaignCategory === CampaignCategory.Done && (
        <IconButton
          aria-label="Sort"
          variant="ghost"
          onClick={() => {
            const newDirection = direction === "desc" ? "asc" : "desc";

            setDirection(newDirection);
          }}
          icon={
            <Icon
              as={direction === "asc" ? SortAscIcon : SortDescIcon}
              __css={{
                width: "1.5rem",
                height: "1.5rem",
                path: {
                  stroke:
                    colorMode === "dark"
                      ? `${colorScheme}.200`
                      : `${colorScheme}.500`,
                },
              }}
            />
          }
        />
      )}
    </Flex>
  );
};

export default FilterSortingArea;
