export default class ReportPerAgentDomain {
  agentId: number;

  uniqueConversations: number | null;

  messagesSent: number | null;

  avgTimeToReplyInSeconds: number | null;

  constructor(
    agentId: number,
    uniqueConversations: number | null,
    messagesSent: number | null,
    avgTimeToReplyInSeconds: number | null
  ) {
    this.agentId = agentId;
    this.uniqueConversations = uniqueConversations;
    this.messagesSent = messagesSent;
    this.avgTimeToReplyInSeconds = avgTimeToReplyInSeconds;
  }
}
