import { Flex, Image, useColorMode, Text, Icon } from "@chakra-ui/react";
import { ReactComponent as ForwardIcon } from "assets/icons/forward-arrow.svg";
import { useAppSelector } from "redux/hooks";
import React from "react";

interface BankItemProps {
  icon: string;
  imgAlt: string;
  bankName: string;
  onClick: () => void;
}
const BankListItem = ({ icon, imgAlt, bankName, onClick }: BankItemProps) => {
  const { colorScheme } = useAppSelector((state) => state.theme);
  const { colorMode } = useColorMode();

  return (
    <Flex
      justifyContent="space-between"
      borderRadius="md"
      w="100%"
      cursor="pointer"
      alignItems="center"
      px={2}
      py={4}
      _hover={{
        bg: colorMode === "light" ? "gray.50" : "gray.700",
      }}
      onClick={onClick}
    >
      <Flex alignItems="center" gridGap={4}>
        <Image alt={imgAlt} src={icon} width={6} />
        <Text>{bankName}</Text>
      </Flex>
      <Icon
        as={ForwardIcon}
        fill={
          colorMode === "light" ? `${colorScheme}.400` : `${colorScheme}.200`
        }
      />
    </Flex>
  );
};

export default BankListItem;
