import { Icon, useColorMode } from "@chakra-ui/react";
import { ReactSVG } from "react-svg";
import React from "react";
import { useAppSelector } from "redux/hooks";

const BackIconButton = ({
  onBackIconClick,
  displayBackIcon,
}: {
  onBackIconClick?: () => void;
  displayBackIcon?: boolean;
}) => {
  const { colorMode } = useColorMode();
  const { colorScheme } = useAppSelector((state) => state.theme);

  return (
    <>
      <Icon
        cursor="pointer"
        as={ReactSVG}
        display={displayBackIcon ? "initial" : "none"}
        src="/left-arrow.svg"
        __css={{
          svg: {
            height: "15px",
            width: "20px",
            fill:
              colorMode === "dark"
                ? `${colorScheme}.200`
                : `${colorScheme}.500`,
          },
        }}
        onClick={onBackIconClick}
      />
    </>
  );
};

export default BackIconButton;
