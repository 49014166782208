import { format, isSameDay, parseISO } from "date-fns";
import { updateFormatAccordingToCountry } from "util/methods";

export default class FileDomain {
  id: string;

  name: string;

  url: string;

  type: string;

  createdAt: string;

  isReusable: boolean;

  shortUrl: boolean;

  merchantId: number;

  constructor(
    id: string,
    name: string,
    url: string,
    type: string,
    isReusable: boolean,
    shortUrl: boolean,
    createdAt: string,
    merchantId: number
  ) {
    this.id = id;
    this.name = name;
    this.url = url;
    this.type = type;
    this.isReusable = isReusable;
    this.shortUrl = shortUrl;
    this.createdAt = createdAt;
    this.merchantId = merchantId;
  }

  getDisplayCreatedDate(): string {
    if (!this.createdAt) {
      return "";
    }

    const dateCreated = parseISO(
      this.createdAt.includes("Z") ? this.createdAt : `${this.createdAt}Z`
    );

    return isSameDay(dateCreated, new Date())
      ? `Today at ${format(dateCreated, "h:mm a")}`
      : format(dateCreated, updateFormatAccordingToCountry("dd/MM/yyyy HH:mm"));
  }
}
