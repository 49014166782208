export enum TimeOperation {
  BEFORE = "before",
  AFTER = "after",
}

export enum TimeUnit {
  SECOND = "second",
  MINUTE = "minute",
  HOUR = "hour",
  DAY = "day",
  WEEK = "week",
}

export default class AutomationDomain {
  id: string;

  name: string;

  description: string;

  merchantId: number;

  triggerType: string;

  time: {
    operation: TimeOperation;
    unit: TimeUnit;
    amount: number;
  } | null;

  frequency: {
    unit: TimeUnit;
    amount: number;
  } | null;

  task: string;

  templateId: string;

  enabled: boolean;

  constructor(
    id: string,
    name: string,
    description: string,
    merchantId: number,
    triggerType: string,
    time: {
      operation: TimeOperation;
      unit: TimeUnit;
      amount: number;
    } | null,
    frequency: {
      unit: TimeUnit;
      amount: number;
    } | null,
    task: string,
    templateId: string,
    enabled: boolean
  ) {
    this.id = id;
    this.name = name;
    this.description = description;
    this.merchantId = merchantId;
    this.triggerType = triggerType;
    this.time = time;
    this.frequency = frequency;
    this.task = task;
    this.templateId = templateId;
    this.enabled = enabled;
  }

  canRepeat() {
    return ![
      "create_auto_reply_message",
      "create_booking_reminder_message",
    ].includes(this.task);
  }

  getAllowedTimeOperations(): TimeOperation[] {
    switch (this.task) {
      case "create_auto_reply_message":
        return [TimeOperation.AFTER];
      case "create_booking_reminder_message":
        return [TimeOperation.BEFORE];
      case "create_payment_reminder_message":
        return [TimeOperation.AFTER];
      default:
        return [TimeOperation.AFTER, TimeOperation.BEFORE];
    }
  }

  getAllowedTimeUnits(): TimeUnit[] {
    switch (this.task) {
      case "create_auto_reply_message":
        return [TimeUnit.SECOND, TimeUnit.MINUTE, TimeUnit.DAY];
      case "create_booking_reminder_message":
        return [TimeUnit.MINUTE, TimeUnit.HOUR, TimeUnit.DAY, TimeUnit.WEEK];
      case "create_payment_reminder_message":
        return [TimeUnit.DAY, TimeUnit.WEEK];
      default:
        return [TimeUnit.MINUTE, TimeUnit.DAY, TimeUnit.WEEK];
    }
  }
}
