import { useAuth0 } from "@auth0/auth0-react";
import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  Flex,
  Heading,
  Image,
  StyleProps,
  VStack,
  useBreakpointValue,
  useColorMode,
} from "@chakra-ui/react";
import FileDragAndDrop from "components/shared/input-drag-drop/FileDragAndDrop";
import { useAppSelector } from "redux/hooks";
import React, { useEffect, useState } from "react";
import FilesService from "services/files";

interface ChatBackgroundModalProps {
  isOpen: boolean;
  onClose: () => void;
  onChatBackgroundChange: (chatBackground: StyleProps) => void;
  onChatBackgroundChangeCompleted: (chatBackground: string) => void;
}

const ChatBackgroundModal: React.FC<ChatBackgroundModalProps> = ({
  isOpen,
  onClose,
  onChatBackgroundChange,
  onChatBackgroundChangeCompleted,
}) => {
  const auth0Context = useAuth0();
  const { colorScheme } = useAppSelector((state) => state.theme);
  const { colorMode } = useColorMode();
  const { merchant } = useAppSelector((state) => state.merchant);
  const isBaseSize = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );
  const colors = [
    "red",
    "green",
    "blue",
    "purple",
    "gray",
    "orange",
    "yellow",
    "teal",
    "cyan",
    "pink",
    "",
  ];

  const { currentAgent } = useAppSelector((state) => state.agents);

  const [currentChatBackground, setCurrentChatBackground] = useState<
    string | undefined
  >(currentAgent!.stylePreferences?.chatBackground || undefined);

  useEffect(() => {
    const newChatBackground = currentAgent!.stylePreferences?.chatBackground;

    if (newChatBackground) {
      setCurrentChatBackground(newChatBackground);
    }
  }, [currentAgent]);

  const [chatBackgroundValue, setChatBackgroundValue] = useState<
    string | undefined
  >(currentChatBackground || undefined);

  useEffect(() => {
    if (!isOpen) {
      return;
    }

    setChatBackgroundValue(currentChatBackground || undefined);
  }, [isOpen]);

  useEffect(() => {
    if (isOpen) {
      return;
    }

    onChatBackgroundChange(
      currentAgent!.getChatBackgroundStyles(colorMode) || {}
    );
  }, [isOpen, currentAgent, colorMode]);

  useEffect(() => {
    const newChatBackgrounStyles: StyleProps = {};

    if (!chatBackgroundValue) {
      onChatBackgroundChange(
        currentAgent!.getChatBackgroundStyles(colorMode) || {}
      );

      return;
    }

    const isURL = chatBackgroundValue.startsWith("http");

    if (isURL) {
      newChatBackgrounStyles.background = `no-repeat url("${chatBackgroundValue}")`;
      newChatBackgrounStyles.backgroundSize = "cover";
    } else {
      newChatBackgrounStyles.background =
        colorMode === "dark"
          ? `${chatBackgroundValue}.700`
          : `${chatBackgroundValue}.300`;
    }

    onChatBackgroundChange(newChatBackgrounStyles);
  }, [chatBackgroundValue]);

  return (
    <Drawer
      size={isBaseSize ? "full" : "sm"}
      isOpen={isOpen}
      placement="left"
      onClose={onClose}
    >
      <DrawerContent
        borderRadius="0 !important"
        onClick={(e) => {
          if (
            e.target instanceof HTMLElement &&
            !e.target.id.includes("color") &&
            chatBackgroundValue &&
            colors.includes(chatBackgroundValue) &&
            currentChatBackground !== chatBackgroundValue
          ) {
            setChatBackgroundValue(currentChatBackground || undefined);
          }
        }}
      >
        <DrawerCloseButton />
        <DrawerBody
          display="flex"
          flexDirection="column"
          alignItems="center"
          gridGap={8}
          overflowX="hidden"
          py={8}
        >
          <VStack w="100%" mt={4} alignItems="center">
            <Heading size="md" mb={4}>
              Upload a new background
            </Heading>
            <Box flexGrow={1}>
              <FileDragAndDrop
                useStorage={false}
                showClearButton={true}
                accept="png, jpg, jpeg, gif"
                onClose={() => {}}
                setIsLoading={() => {}}
                errorMessage="Could not upload chat background"
                onSubmit={async (files) => {
                  const url = (
                    await FilesService.uploadFile(
                      auth0Context,
                      {
                        file: files[0],
                        isReusable: false,
                        needsShortUrl: false,
                      },
                      merchant.id
                    )
                  ).url;

                  setChatBackgroundValue(url || "");

                  return "Image was successfully uploaded";
                }}
              />
            </Box>
          </VStack>
          <Box>
            <Heading size="md" mb={4}>
              Or pick a color
            </Heading>
            <Flex justifyContent="center" wrap="wrap" gridGap={3}>
              {colors.map((color) => (
                <Box
                  id={`${color}-color`}
                  height="4rem"
                  cursor="pointer"
                  width="4rem"
                  borderRadius="lg"
                  backgroundColor={color ? `${color}.500` : "inherit"}
                  borderStyle="solid"
                  borderWidth={
                    chatBackgroundValue === color
                      ? "4px"
                      : !color
                      ? "2px"
                      : "0px"
                  }
                  borderColor={color ? `${color}.800` : "black"}
                  onClick={() => setChatBackgroundValue(color)}
                />
              ))}
            </Flex>
          </Box>
        </DrawerBody>

        <DrawerFooter justifyContent="space-between">
          <Button colorScheme={colorScheme} variant="ghost" onClick={onClose}>
            Close
          </Button>
          <Button
            colorScheme={colorScheme}
            onClick={() => {
              onChatBackgroundChangeCompleted(chatBackgroundValue || "");
              onClose();
            }}
          >
            Change
          </Button>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
};

export default ChatBackgroundModal;
