import { useAuth0 } from "@auth0/auth0-react";
import {
  Box,
  Icon,
  useColorMode,
  Grid,
  GridItem,
  Image,
  useDisclosure,
  Button,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import ContactDomain from "entities/domain/customers/contact-domain";
import { ReactComponent as MediaIcon } from "assets/icons/folder-multiple-image.svg";
import { ReactComponent as AudioIcon } from "assets/icons/volume-high.svg";
import { ReactComponent as VideoIcon } from "assets/icons/video-box.svg";
import { ReactComponent as PDFFile } from "assets/icons/file-pdf-box.svg";
import { ReactComponent as File } from "assets/icons/file.svg";
import { useAppSelector } from "redux/hooks";
import AttachmentDomain from "entities/domain/attachments/attachment-domain";
import AttachmentsService from "services/attachments";
import { numberOfAttachmentsPerLoad } from "util/constants";
import ContactCardAccordionContent from "./ContactCardAccordionContent";
import Gallery from "../Gallery";
import { SUPPORTED_MEDIA_TYPES } from "../FilePreview";

interface AllAttachmentsProps {
  contactToDisplay: ContactDomain;
  isExpanded: boolean;
  onFirstLoad: (attachments: AttachmentDomain[]) => void;
}

const AllAttachments = ({
  contactToDisplay,
  isExpanded,
  onFirstLoad,
}: AllAttachmentsProps) => {
  const { colorMode } = useColorMode();
  const { merchant } = useAppSelector((state) => state.merchant);
  const { colorScheme } = useAppSelector((state) => state.theme);
  const [startIndex, setStartIndex] = useState<number>(0);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [brokenMedia, setBrokenMedia] = useState<string[]>([]);
  const [attachments, setAttachments] = useState<AttachmentDomain[]>([]);
  const [hasNextPage, setHasNextPage] = useState<boolean>(true);
  const [isLoadingNextPage, setIsLoadingNextPage] = useState<boolean>(false);

  const auth0Context = useAuth0();

  const hasMoreAttachments = (
    attachmentsAmount: number,
    currentPageAmount: number
  ): boolean => {
    if (attachmentsAmount < numberOfAttachmentsPerLoad) {
      return false;
    }

    if (!currentPageAmount || currentPageAmount < numberOfAttachmentsPerLoad) {
      return false;
    }

    return true;
  };

  const fetchAttachments = async () => {
    setIsLoadingNextPage(true);
    const nextPage = await AttachmentsService.getAttachments(
      auth0Context,
      contactToDisplay.id!,
      attachments.length,
      numberOfAttachmentsPerLoad,
      merchant.id
    );

    if (nextPage.length && !attachments.length) {
      onFirstLoad(nextPage);
    }

    const newAttachments: AttachmentDomain[] = attachments.length
      ? attachments.concat(nextPage)
      : nextPage;

    setAttachments(newAttachments);
    setHasNextPage(hasMoreAttachments(newAttachments.length, nextPage.length));
    setIsLoadingNextPage(false);
  };

  useEffect(() => {
    setBrokenMedia([]);
    fetchAttachments();
  }, []);

  return (
    <Box borderBottom="1px" borderColor="gray.50" pb={2}>
      <ContactCardAccordionContent
        isExpanded={isExpanded}
        sectionHeader={
          <Icon
            as={MediaIcon}
            boxSize={6}
            fill={
              colorMode === "dark" ? `${colorScheme}.200` : `${colorScheme}.900`
            }
            _hover={{ fill: colorMode === "dark" ? "gray.600" : "gray.300" }}
          />
        }
        sectionContent={
          <>
            <Grid
              justifyContent="center"
              alignItems="center"
              templateColumns="repeat(3, minmax(0, 1fr))"
              gap={3}
              overflowX="hidden"
            >
              {attachments
                .slice(0, 9)
                .filter((attachment) => !brokenMedia.includes(attachment.id))
                .map((attachment, index) => (
                  <GridItem
                    key={attachment.id}
                    _hover={{
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setStartIndex(index);
                      onOpen();
                    }}
                  >
                    {attachment.type.includes("image") &&
                      SUPPORTED_MEDIA_TYPES.includes(attachment.type) && (
                        <Image
                          src={attachment.url}
                          alt={`attachment-${attachment.id}`}
                          boxSize="100%"
                          objectFit="cover"
                          borderRadius="lg"
                          width="5rem"
                          height="5rem"
                          loading="lazy"
                          onError={() => {
                            setBrokenMedia([...brokenMedia, attachment.id]);
                          }}
                        />
                      )}
                    {attachment.type.includes("video") &&
                      SUPPORTED_MEDIA_TYPES.includes(attachment.type) && (
                        <Icon
                          as={VideoIcon}
                          width="5rem"
                          height="5rem"
                          borderWidth="1px"
                          borderStyle="solid"
                          borderColor="gray.50"
                          borderRadius="lg"
                          fill={
                            colorMode === "dark"
                              ? `${colorScheme}.200`
                              : `${colorScheme}.500`
                          }
                        />
                      )}
                    {attachment.type.includes("audio") &&
                      SUPPORTED_MEDIA_TYPES.includes(attachment.type) && (
                        <Icon
                          as={AudioIcon}
                          width="5rem"
                          height="5rem"
                          borderWidth="1px"
                          borderStyle="solid"
                          borderColor="gray.50"
                          borderRadius="lg"
                          fill={
                            colorMode === "dark"
                              ? `${colorScheme}.200`
                              : `${colorScheme}.500`
                          }
                        />
                      )}
                    {attachment.type.includes("pdf") &&
                      SUPPORTED_MEDIA_TYPES.includes(attachment.type) && (
                        <Icon
                          as={PDFFile}
                          width="5rem"
                          height="5rem"
                          borderWidth="1px"
                          borderStyle="solid"
                          borderColor="gray.50"
                          borderRadius="lg"
                          fill={
                            colorMode === "dark"
                              ? `${colorScheme}.200`
                              : `${colorScheme}.500`
                          }
                        />
                      )}
                    {!SUPPORTED_MEDIA_TYPES.includes(attachment.type) && (
                      <Icon
                        as={File}
                        width="5rem"
                        height="5rem"
                        borderWidth="1px"
                        borderStyle="solid"
                        borderColor="gray.50"
                        borderRadius="lg"
                        fill={
                          colorMode === "dark"
                            ? `${colorScheme}.200`
                            : `${colorScheme}.500`
                        }
                      />
                    )}
                  </GridItem>
                ))}
            </Grid>
            {attachments.length !== 0 && (
              <Button
                mt={2}
                colorScheme={colorScheme}
                w="100%"
                onClick={() => {
                  setStartIndex(0);
                  onOpen();
                }}
              >
                Show all
              </Button>
            )}
          </>
        }
      />
      <Gallery
        isOpen={isOpen}
        onClose={onClose}
        startIndex={startIndex}
        setStartIndex={(index) => setStartIndex(index)}
        media={attachments}
        hasMore={hasNextPage}
        fetchMore={fetchAttachments}
        isLoadingMore={isLoadingNextPage}
      />
    </Box>
  );
};

export default AllAttachments;
