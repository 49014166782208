import { StyleConfig } from "@chakra-ui/theme-tools";
import chroma from "chroma-js";

const LinkStyles = {
  baseStyle: {
    color: "blue.400",
    _dark: {
      color: "blue.200",
    },
  },
} as StyleConfig;

export default LinkStyles;
