import { Box } from "@chakra-ui/layout";
import React from "react";

interface ClickableMediaProps {
  onClick: () => void;
  children: React.ReactNode;
}

const ClickableMedia: React.FC<ClickableMediaProps> = ({
  onClick,
  children,
}) => {
  return (
    <Box
      flexShrink={0}
      borderRadius="lg"
      p={0}
      _hover={{
        cursor: "pointer",
      }}
      onClick={onClick}
    >
      {children}
    </Box>
  );
};

export default ClickableMedia;
