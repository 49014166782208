import KeyloopAccount, {
  KeyloopMetadata,
} from "entities/domain/keyloop-account";
import {
  KeyloopAccountDTO,
  KeyloopAccountMetadataDTO,
} from "entities/dto/KeyloopAccountDTO";

export const keyloopAccountMetadataTransformFromDtoToDomain = (
  kam: KeyloopAccountMetadataDTO
): KeyloopMetadata =>
  new KeyloopMetadata(kam.privacy_statement_id, kam.channels, kam.user_id);

export const keyloopAccountTransformFromDtoToDomain = (
  ka: KeyloopAccountDTO
): KeyloopAccount =>
  new KeyloopAccount(
    ka.enterprise_id,
    ka.store_id,
    keyloopAccountMetadataTransformFromDtoToDomain(ka.metadata),
    ka.last_inventory_sync,
    ka.account_name
  );
