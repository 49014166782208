import { useAuth0 } from "@auth0/auth0-react";
import React, { useEffect, useState } from "react";
import { FallbackProps } from "react-error-boundary";

function ErrorFallback({ error, resetErrorBoundary }: FallbackProps) {
  const { logout } = useAuth0();

  const [showError, setShowError] = useState<boolean>(false);
  const [isCopied, setIsCopied] = useState<boolean>(false);

  useEffect(() => {
    if (!isCopied) {
      return;
    }

    const timeout = setTimeout(() => {
      setIsCopied(false);
    }, 3000);

    return () => {
      clearTimeout(timeout);
    };
  }, [isCopied]);

  return (
    <div
      style={{
        marginLeft: "auto",
        marginRight: "auto",
        marginTop: "2rem",
        display: "flex",
        padding: "2rem",
        gridGap: "1rem",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
        borderRadius: "2rem",
        backgroundColor: "#ffaeae",
        width: "80%",
      }}
    >
      <h1
        style={{
          fontSize: "2rem",
          color: "black",
        }}
      >
        Something went wrong! 😢
      </h1>
      {error ? (
        <button
          style={{
            textDecoration: "underline",
            backgroundColor: "transparent",
            border: "none",
            color: "#716161",
            cursor: "pointer",
          }}
          onClick={() => {
            setShowError(!showError);
          }}
        >
          Click to {showError ? "hide" : "show"} error details
        </button>
      ) : null}
      {showError && error ? (
        <div
          style={{
            padding: "1rem",
            borderRadius: "1rem",
            backgroundColor: "#af2e2e",
            width: "fit-content",
            whiteSpace: "pre-wrap",
            textAlign: "left",
            color: "white",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <h3
              style={{
                textAlign: "center",
              }}
            >
              🙏 Send us a text of the error and we will make sure it never
              happens again! 🫡
            </h3>
            <button
              style={{
                paddingTop: "1rem",
                paddingBottom: "1rem",
                paddingLeft: "2rem",
                paddingRight: "2rem",
                borderRadius: 9999,
                borderWidth: 0,
                backgroundColor: "black",
                color: "white",
                fontSize: "1rem",
                fontWeight: "bold",
                cursor: "pointer",
              }}
              onClick={() => {
                setIsCopied(true);
                navigator.clipboard.writeText(error.stack);
              }}
            >
              {isCopied ? "Copied ✅" : "Copy error to clipboard"}
            </button>
          </div>
          <p
            style={{
              maxHeight: "100px",
              overflow: "hidden",
              maskImage:
                "linear-gradient(to bottom, black 50%, transparent 100%)",
            }}
          >
            {error.stack}
          </p>
        </div>
      ) : null}
      <h2
        style={{
          fontSize: "1.5rem",
          color: "black",
          fontWeight: "normal",
        }}
      >
        Please, try refreshing the page. If the problem persists, consider
        logging out and logging back in.
      </h2>
      <button
        style={{
          paddingTop: "1rem",
          paddingBottom: "1rem",
          paddingLeft: "2rem",
          paddingRight: "2rem",
          borderRadius: 9999,
          borderWidth: 0,
          backgroundColor: "black",
          color: "white",
          fontSize: "1rem",
          fontWeight: "bold",
          cursor: "pointer",
        }}
        onClick={() => {
          logout();
        }}
      >
        Log out
      </button>
    </div>
  );
}

export default ErrorFallback;
