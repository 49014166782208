/* eslint-disable import/prefer-default-export */
import { MerchantUserGuides } from "entities/domain/admin/merchants/merchant-user-guides";
import UserGuidesDomain from "entities/domain/userGuides/user-guides";
import { UserGuidesDto } from "entities/dto/UserGuidesDTO";

export const userGuideTransformFromDtoToDomain = (
  dto: UserGuidesDto
): UserGuidesDomain =>
  new UserGuidesDomain(
    dto.status,
    dto.actions.map((action) => ({
      name: action.name as MerchantUserGuides,
      status: action.status,
      group: action.group,
    }))
  );
