import { convertToChannelGroup } from "util/constants";

export default class CustomerChannelDomain {
  id?: string;

  type: string;

  handle: string;

  displayHandle?: string;

  isActive: boolean;

  isSubscribed: boolean;

  getChannelGroup(): string {
    return convertToChannelGroup(this.type);
  }

  getDisplayChannelName(): string {
    switch (this.type) {
      case "sms":
        return "SMS";
      case "whatsapp":
        return "WhatsApp";
      case "facebook":
        return "Facebook messenger";
      case "instagram":
        return "Instagram";
      case "email":
        return "Email";
      default:
        return "";
    }
  }

  getMarketingStatusDisplay(): string {
    switch (this.isSubscribed) {
      case true:
        return "Subscribed";
      case false:
        return "Unsubscribed";
      default:
        return "-";
    }
  }

  constructor(
    id: string | undefined,
    type: string,
    handle: string,
    displayHandle: string | undefined,
    isActive: boolean,
    isSubscribed: boolean
  ) {
    this.id = id;
    this.type = type;
    this.handle = handle;
    this.displayHandle = displayHandle;
    this.isActive = isActive;
    this.isSubscribed = isSubscribed;
  }
}
