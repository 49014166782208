import {
  Divider,
  Flex,
  useBreakpointValue,
  useColorMode,
} from "@chakra-ui/react";
import React, { useLayoutEffect, useRef, useState } from "react";
import { Resizable } from "re-resizable";
import { Outlet } from "react-router-dom";
import { useAppSelector } from "redux/hooks";
import ConversationSnippetList from "../shared/conversation-snippet-list";
import OpenClosedPills from "./OpenClosedPills";

interface ConversationsAreaProps {}

const ConversationsArea = (_props: ConversationsAreaProps) => {
  const isBaseSize = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );
  const { colorMode } = useColorMode();

  const conversationContainer = useRef<any>({});
  const conversationList = useRef<HTMLDivElement>({} as HTMLDivElement);

  const [openConversationContainer, setOpenConversationContainer] =
    useState(false);
  const { activeConversationId } = useAppSelector(
    (state) => state.conversations
  );

  useLayoutEffect(() => {
    if (
      !conversationContainer.current ||
      !conversationContainer.current.resizable ||
      !conversationList.current
    ) {
      return;
    }

    if (!activeConversationId && openConversationContainer) {
      conversationContainer.current.resizable.style.width = "0%";
      conversationContainer.current.resizable.style.opacity = "0";
      conversationContainer.current.resizable.style.minWidth = "0%";
      conversationList.current.style.maxWidth = "100%";

      setOpenConversationContainer(false);
    } else if (activeConversationId && !openConversationContainer) {
      conversationContainer.current.resizable.style.width = "75%";
      conversationContainer.current.resizable.style.opacity = "1";

      const addLimitsToWidthsTimeout = setTimeout(() => {
        conversationContainer.current.resizable.style.minWidth = "25%";
        conversationList.current.style.width = "25%";
        conversationList.current.style.maxWidth = "75%";
      }, 2000);

      setOpenConversationContainer(true);

      return () => {
        clearTimeout(addLimitsToWidthsTimeout);
      };
    }
  }, [activeConversationId]);

  return (
    <Flex
      w="100%"
      minW="100%"
      h="100%"
      maxH="100%"
      overflow="hidden"
      backgroundColor={colorMode === "dark" ? "gray.800" : "white"}
    >
      <Flex
        ref={conversationList}
        direction="column"
        w="auto"
        minW="25%"
        h="100%"
        maxW="100%"
        overflow="hidden"
        flexGrow={1}
      >
        <OpenClosedPills />
        <ConversationSnippetList />
      </Flex>
      <Resizable
        ref={conversationContainer}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          opacity: 0,
        }}
        maxWidth="75%"
        minHeight="100%"
        maxHeight="100%"
        defaultSize={{
          width: "0px",
          height: "100%",
        }}
        enable={{
          top: false,
          right: false,
          bottom: false,
          left: openConversationContainer,
          topRight: false,
          bottomRight: false,
          bottomLeft: false,
          topLeft: false,
        }}
      >
        <Flex w="100%" h="100%" overflow="hidden">
          <Divider
            orientation="vertical"
            bgColor={colorMode === "dark" ? "gray.900" : "gray.100"}
          />
          <Outlet />
        </Flex>
      </Resizable>
    </Flex>
  );
};

export default ConversationsArea;
