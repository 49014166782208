import { Auth0ContextInterface } from "@auth0/auth0-react/src/auth0-context";
import FileDomain from "entities/domain/file";
import { FileDTO } from "entities/dto/FileDTO";
import { fileTransformFromDtoToDomain } from "entities/transformers/file-transformer";
import { numberOfFilesPerLoad } from "util/constants";
import { RequestType } from "./request-type";
import { deleteRequest, mutationRequest, request } from "../util/methods";

export interface UploadFilePayload {
  file: File;
  isReusable: boolean;
  needsShortUrl: boolean;
}

class FilesService {
  public static async uploadFile(
    { getAccessTokenSilently }: Auth0ContextInterface,
    payload: UploadFilePayload,
    merchantId: number
  ): Promise<FileDomain> {
    const accessToken = await getAccessTokenSilently();
    const formData = new FormData();

    formData.append("file", payload.file);

    if (payload.isReusable) {
      formData.append("reusable", "true");
    }

    if (payload.needsShortUrl) {
      formData.append("needs_short_url", "true");
    }

    const uploadResponse = (
      await mutationRequest<FileDTO>(
        RequestType.POST,
        accessToken,
        `/merchants/${merchantId}/files`,
        formData,
        "multipart/form-data"
      )
    ).data;

    return fileTransformFromDtoToDomain(uploadResponse);
  }

  public static async deleteFile(
    { getAccessTokenSilently }: Auth0ContextInterface,
    fileId: string,
    merchantId: number
  ): Promise<void> {
    const accessToken = await getAccessTokenSilently();

    await deleteRequest(
      RequestType.DELETE,
      accessToken,
      `/merchants/${merchantId}/files/${fileId}`
    );
  }

  public static async bulkDeleteFiles(
    { getAccessTokenSilently }: Auth0ContextInterface,
    fileIds: string[],
    merchantId: number
  ): Promise<void> {
    const accessToken = await getAccessTokenSilently();

    await deleteRequest(
      RequestType.DELETE,
      accessToken,
      `/merchants/${merchantId}/files/bulk`,
      { fileIds }
    );
  }

  public static async getFiles(
    { getAccessTokenSilently }: Auth0ContextInterface,
    merchantId: number,
    searchText: string = "",
    offset: number = 0,
    isReusable: boolean = true
  ): Promise<FileDomain[]> {
    const accessToken = await getAccessTokenSilently();

    const queryParams = new URLSearchParams();

    queryParams.append("offset", `${offset}`);
    queryParams.append("maxPageSize", `${numberOfFilesPerLoad}`);

    if (searchText) {
      queryParams.append("search", searchText);
    }

    if (isReusable) {
      queryParams.append("reusable", "true");
    }

    const queryString = queryParams.toString();

    const data = (
      await request<FileDTO[]>(
        RequestType.GET,
        accessToken,
        `/merchants/${merchantId}/files?${queryString}`
      )
    ).data;

    return data.map(fileTransformFromDtoToDomain);
  }

  public static async getFileById(
    { getAccessTokenSilently }: Auth0ContextInterface,
    merchantId: number,
    fileId: string
  ): Promise<FileDomain> {
    const accessToken = await getAccessTokenSilently();

    const data = (
      await request<FileDTO>(
        RequestType.GET,
        accessToken,
        `/merchants/${merchantId}/files/${fileId}`
      )
    ).data;

    return fileTransformFromDtoToDomain(data);
  }
}

export default FilesService;
