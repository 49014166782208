import {
  Box,
  Button,
  Divider,
  Flex,
  HStack,
  Icon,
  Input,
  useColorMode,
  useToast,
  VStack,
} from "@chakra-ui/react";
import EditorUltra, { EditorUltraPlugin } from "components/editor-ultra";
import UpdateTags from "components/modals/tags/UpdateTags";
import BackIconButton from "components/shared/BackButton";
import Topbar from "components/shared/topbar/TopBar";
import { MultipleWarnings } from "components/shared/WarningTextComponent";
import Tag from "components/tags";
import FormItem from "components/user-settings/TeamMemberSettings/FormItem";
import { ConversationChannel } from "entities/domain/conversations/conversation-domain";
import { useAppSelector, useAppDispatch } from "redux/hooks";
import React, { useEffect, useMemo, useState } from "react";
import { isValidPhoneNumber } from "react-phone-number-input";
import {
  createSearchParams,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { ReactSVG } from "react-svg";

import useContactsStore from "hooks/use-contacts-store";
import { matchTagColorToMerchantTagColor } from "util/constants";
import * as yup from "yup";
import ContactDomain from "entities/domain/customers/contact-domain";
import CustomerAddressDomain from "entities/domain/customers/customer-address-domain";
import CustomerChannelDomain from "entities/domain/customers/contact-channel-domain";
import { cleanContactsToastMessages } from "redux/features/contacts";
import ChannelsArea from "./channelsArea";
import AddressForm from "./addressForm";

interface YupValidationError {
  message: string;
}

interface ContactCardProps {
  title: string;
  contact?: ContactDomain;
  handleSave: (editedContact: ContactDomain) => void;
  isSaving: boolean;
}

const ContactCard = ({
  title,
  contact,
  handleSave,
  isSaving,
}: ContactCardProps) => {
  const { merchant } = useAppSelector((state) => state.merchant);
  const { tags } = useAppSelector((state) => state.tags);
  const { colorMode } = useColorMode();
  const { colorScheme } = useAppSelector((state) => state.theme);
  const { contactId } = useParams<{ contactId: string }>();
  const navigate = useNavigate();
  const { toastMessage } = useAppSelector((state) => state.contacts);
  const toast = useToast();
  const { getContactTags } = useContactsStore();
  const dispatch = useAppDispatch();
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const name = query.get("name");
  const surname = query.get("last");
  // TBD
  // const channels = query.get("channels");
  // const phone = query.get("phone");
  // const email = query.get("email");

  const draftAddress = new CustomerAddressDomain("", "", "", "", "", "");

  const draftContact = new ContactDomain(
    undefined,
    undefined,
    name || "",
    surname || "",
    draftAddress,
    [],
    "",
    "",
    "",
    "",
    [],
    "",
    false
  );

  const [contactToEdit, setContactToEdit] = useState<ContactDomain>(
    contact || draftContact
  );
  const [addressToEdit, setAddressToEdit] = useState<CustomerAddressDomain>(
    contact?.address || draftAddress
  );

  useEffect(() => {
    if (!contact) {
      setContactToEdit(draftContact);

      return;
    }

    setContactToEdit(contact);
    setAddressToEdit(contact.address || draftAddress);
  }, [contact]);

  const [warnings, setWarnings] = useState<MultipleWarnings | undefined>(
    undefined
  );

  const [openEditTags, setOpenEditTags] = useState<boolean>(false);

  const deleteTag = (tagIdToDelete: string) => {
    setContactToEdit(
      Object.setPrototypeOf(
        {
          ...contactToEdit,
          tagIds: contactToEdit.tagIds.filter(
            (tagId) => tagId !== tagIdToDelete
          ),
        },
        ContactDomain.prototype
      )
    );
  };

  const addTagsToContact = (selectedTagIds: string[]) => {
    const selectedContactId = contactId ? Number(contactId) : undefined;

    if (selectedContactId === contactToEdit.id) {
      setContactToEdit(
        Object.setPrototypeOf(
          {
            ...contactToEdit,
            tagIds: selectedTagIds,
          },
          ContactDomain.prototype
        )
      );
    }

    setOpenEditTags(false);
  };

  useEffect(() => {
    const newWarnings: MultipleWarnings = {
      ...warnings,
    };

    if (!contactToEdit.name || !contactToEdit.surname) {
      newWarnings.invalid_name_format = "* Please enter a name and surname";
    } else {
      delete newWarnings.invalid_name_format;
    }

    const arePhonesValid = contactToEdit.channels
      .filter(
        (c) =>
          c.type === ConversationChannel.SMS ||
          c.type === ConversationChannel.WHATSAPP
      )
      .every((c) => {
        const phoneNumber = c.handle;

        if (
          phoneNumber &&
          phoneNumber !== "" &&
          !isValidPhoneNumber(phoneNumber)
        ) {
          return false;
        }

        return true;
      });

    if (!arePhonesValid) {
      newWarnings.invalid_phone_number = "* Invalid phone number format";
    } else {
      delete newWarnings.invalid_phone_number;
    }

    const areEmailsValid = contactToEdit.channels
      .filter((c) => c.type === ConversationChannel.EMAIL)
      .every((c) => {
        const contactEmail = c.handle;

        let emailError;

        try {
          yup.string().email().validateSync(contactEmail);
        } catch (error: any) {
          const { message } = error as YupValidationError;
          emailError = message[0].toUpperCase() + message.slice(1);
        }
        if (emailError) {
          return false;
        }

        return true;
      });

    if (!areEmailsValid) {
      newWarnings.invalid_email = "* Invalid email format";
    } else {
      delete newWarnings.invalid_email;
    }

    setWarnings(newWarnings);
  }, [contactToEdit]);

  useEffect(() => {
    const newWarnings: MultipleWarnings = {
      ...warnings,
    };

    if (
      addressToEdit.country &&
      ((addressToEdit.country === "US" && !addressToEdit.state) ||
        !addressToEdit.firstLine ||
        !addressToEdit.secondLine ||
        !addressToEdit.city ||
        !addressToEdit.postcode)
    ) {
      newWarnings.invalid_address = "* Please fill all of the fields";
    } else {
      delete newWarnings.invalid_address;
    }

    setWarnings(newWarnings);
  }, [addressToEdit]);

  const isFullNameValid = (): boolean =>
    !!contactToEdit.name && !!contactToEdit.surname;

  const hasWarnings = warnings && !!Object.keys(warnings).length;

  const isDisabled = isSaving || hasWarnings || !isFullNameValid();

  const [defaultNotesValue, setDefaultNotesValue] = useState<{
    value: string;
  }>({
    value: contactToEdit.notes,
  });

  useEffect(() => {
    if (!defaultNotesValue.value && contact?.notes) {
      setDefaultNotesValue({
        value: contact.notes,
      });
    }
  }, [contact?.notes]);

  // remove this shit yo??
  useEffect(() => {
    if (toastMessage.new) {
      if (toastMessage.success) {
        toast({ status: "success", title: toastMessage.success });
      } else if (toastMessage.errors) {
        toast({ status: "error", title: toastMessage.errors[0] });
      }
      dispatch(cleanContactsToastMessages());
    }
  }, [toastMessage]);

  const contactTags = useMemo(() => {
    return getContactTags(contactToEdit);
  }, [contactToEdit.tagIds]);

  return (
    <>
      <Flex flexDirection="column" w="100%" h="100%">
        <Topbar
          title={title}
          isFlex={true}
          leftChildrenMobile={
            <BackIconButton
              onBackIconClick={() =>
                navigate({
                  pathname: `/${merchant.id}/contacts`,
                  search: createSearchParams(search).toString(),
                })
              }
              displayBackIcon={true}
            />
          }
          leftChildren={
            <BackIconButton
              onBackIconClick={() =>
                navigate({
                  pathname: `/${merchant.id}/contacts`,
                  search: createSearchParams(search).toString(),
                })
              }
              displayBackIcon={true}
            />
          }
        />
        <Box w="100%" overflowY="scroll" h="100%" id="contact-form">
          <Box w={{ base: "90%", xl: "60%" }} mx="auto">
            <FormItem.Standard
              title="First name and last name"
              subtitle=""
              warningText={warnings?.invalid_name_format}
            >
              <VStack>
                <Input
                  colorScheme={colorScheme}
                  data-testid="contact-page-name-input"
                  borderRadius="full"
                  placeholder="First name"
                  value={contactToEdit.name}
                  borderColor={
                    warnings?.invalid_name_format && !contactToEdit.name
                      ? colorMode === "dark"
                        ? "pink.200"
                        : "pink.500"
                      : colorMode === "dark"
                      ? `${colorScheme}.200`
                      : `${colorScheme}.500`
                  }
                  onChange={(e) => {
                    setContactToEdit(
                      Object.setPrototypeOf(
                        {
                          ...contactToEdit,
                          name: e.target.value,
                        },
                        ContactDomain.prototype
                      )
                    );
                  }}
                />

                <Input
                  colorScheme={colorScheme}
                  data-testid="contact-page-surname-input"
                  borderRadius="full"
                  placeholder="Last name"
                  value={contactToEdit.surname}
                  borderColor={
                    warnings?.invalid_name_format && !contactToEdit.surname
                      ? colorMode === "dark"
                        ? "pink.200"
                        : "pink.500"
                      : colorMode === "dark"
                      ? `${colorScheme}.200`
                      : `${colorScheme}.500`
                  }
                  onChange={(e) => {
                    setContactToEdit(
                      Object.setPrototypeOf(
                        {
                          ...contactToEdit,
                          surname: e.target.value,
                        },
                        ContactDomain.prototype
                      )
                    );
                  }}
                />
              </VStack>
            </FormItem.Standard>

            <FormItem.Standard
              title="Channels"
              subtitle=""
              warningText={
                warnings?.invalid_phone_number ||
                warnings?.invalid_channel ||
                warnings?.invalid_email ||
                warnings?.channel_already_assigned_to_another_customer
              }
            >
              <ChannelsArea
                warnings={warnings}
                channels={contactToEdit.channels}
                isCreatingContact={!contactToEdit.id}
                setChannels={(channels: CustomerChannelDomain[]) => {
                  setContactToEdit(
                    Object.setPrototypeOf(
                      {
                        ...contactToEdit,
                        channels,
                      },
                      ContactDomain.prototype
                    )
                  );
                }}
              />
            </FormItem.Standard>

            <FormItem.Standard
              title="Address"
              subtitle=""
              warningText={warnings?.invalid_address}
            >
              <AddressForm
                address={addressToEdit}
                setAddress={setAddressToEdit}
              />
            </FormItem.Standard>

            <FormItem.Standard title="Tags" subtitle="">
              <HStack mt="15px" spacing={2} wrap="wrap" justifyContent="center">
                {contactTags.map((tag) => (
                  <Box key={tag.id}>
                    <Tag
                      label={tag.tag}
                      color={matchTagColorToMerchantTagColor(tags, tag)}
                      my={1}
                      onCloseTag={() => deleteTag(tag.id)}
                    />
                  </Box>
                ))}
                <Icon
                  as={ReactSVG}
                  src="/gradient-plus.svg"
                  mx={5}
                  cursor="pointer"
                  __css={{
                    height: "15px",
                    width: "15px",
                  }}
                  _hover={{ opacity: 0.5 }}
                  onClick={() => setOpenEditTags(true)}
                />
              </HStack>
            </FormItem.Standard>
            <FormItem.Standard title="Notes" subtitle="">
              <Box
                p={3}
                bgColor={colorMode === "dark" ? "gray.700" : "white"}
                borderColor={`${colorScheme}.400`}
                borderWidth="1px"
                borderStyle="solid"
                borderRadius="md"
              >
                <EditorUltra
                  isDisabled={false}
                  defaultText={defaultNotesValue}
                  setText={(currentText: string) => {
                    setContactToEdit(
                      Object.setPrototypeOf(
                        {
                          ...contactToEdit,
                          notes: currentText,
                        },
                        ContactDomain.prototype
                      )
                    );
                  }}
                  enabledPlugins={[
                    EditorUltraPlugin.AUTOFOCUS,
                    EditorUltraPlugin.RICHTEXT,
                  ]}
                />
              </Box>
            </FormItem.Standard>
          </Box>
        </Box>
        <Divider />
        <Flex
          w={{ base: "90%", xl: "60%" }}
          mx="auto"
          justifyContent="end"
          py={6}
        >
          <Button
            data-testid="contact-page-save-button"
            colorScheme={colorScheme}
            size="md"
            onClick={() => {
              handleSave(
                Object.setPrototypeOf(
                  {
                    ...contactToEdit,
                    address: addressToEdit.country ? addressToEdit : null,
                  },
                  ContactDomain.prototype
                )
              );
            }}
            borderRadius="full"
            isDisabled={isDisabled}
            width={{ base: "full", md: "fit-content" }}
          >
            Save
          </Button>
        </Flex>
      </Flex>

      <UpdateTags
        isOpen={openEditTags}
        onClose={() => setOpenEditTags(false)}
        tagIds={contactToEdit.tagIds}
        onSubmit={addTagsToContact}
      />
    </>
  );
};

export default ContactCard;
