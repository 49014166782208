import {
  Box,
  Flex,
  Text,
  Divider,
  useColorMode,
  useBreakpointValue,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import Spinner from "components/spinner";
import AutomationDomain from "entities/domain/automation";
import { useAuth0 } from "@auth0/auth0-react";
import AutomationsService from "services/automations";
import { useAppSelector } from "redux/hooks";
import useAnalytics from "hooks/use-analytics";
import AutomationForm from "./Form";
import AutomationCard from "./AutomationCard";

const AutomationSettings = () => {
  const { colorMode } = useColorMode();
  const auth0Context = useAuth0();
  const { merchant } = useAppSelector((state) => state.merchant);
  const { track } = useAnalytics();
  const isBaseSize = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isFormOpen, setIsFormOpen] = useState<boolean>(false);
  const [automations, setAutomations] = useState<AutomationDomain[]>([]);
  const [selectedAutomation, setSelectedAutomation] =
    useState<AutomationDomain | null>(null);

  const onAutomationUpdate = (ad: AutomationDomain): void => {
    setAutomations(
      [...automations.filter((au) => au.id !== ad.id), ad].sort((a, b) =>
        a.name !== b.name
          ? a.name.localeCompare(b.name)
          : a.id.localeCompare(b.id)
      )
    );
  };

  useEffect(() => {
    (async () => {
      setIsLoading(true);

      try {
        const fetchedAutomations = await AutomationsService.getAutomations(
          auth0Context,
          merchant.id
        );

        setAutomations(
          fetchedAutomations.sort((a, b) =>
            a.name !== b.name
              ? a.name.localeCompare(b.name)
              : a.id.localeCompare(b.id)
          )
        );
      } catch (err) {
        /* eslint-disable no-console */
        console.error("Failed to fetch Automations: ", err);
        /* eslint-enable no-console */
      } finally {
        setIsLoading(false);
      }
    })();
  }, [merchant.id]);

  return (
    <Flex
      w="100%"
      h="100%"
      borderTopRadius={isBaseSize ? 0 : "3xl"}
      mt={isBaseSize ? 0 : "4.5rem"}
      bgColor={colorMode === "dark" ? "gray.800" : "white"}
      justifyContent="center"
      alignItems="start"
      overflow="hidden"
    >
      <Box px={isBaseSize ? 4 : 16} w="100%" h="100%" overflowY="auto">
        <Text fontWeight={700} fontSize="lg" mt={12} mb={4}>
          Use automations to execute recurring tasks with zero manual effort
        </Text>
        <Divider />
        <Flex mt={12} wrap="wrap" justifyContent="space-between">
          {isLoading ? (
            <Spinner />
          ) : (
            automations.map((automation) => (
              <AutomationCard
                key={`${automation.name}-${automation.id}`}
                automation={automation}
                onSelect={() => {
                  setIsFormOpen(true);
                  setSelectedAutomation(automation);
                  track("open_automation_form", {
                    automation_id: automation.id,
                  });
                }}
              />
            ))
          )}
        </Flex>
      </Box>
      {selectedAutomation && (
        <AutomationForm
          isOpen={isFormOpen}
          onClose={() => {
            setIsFormOpen(false);
            setSelectedAutomation(null);
          }}
          automation={selectedAutomation}
          handleAutomationUpdate={onAutomationUpdate}
        />
      )}
    </Flex>
  );
};

export default AutomationSettings;
