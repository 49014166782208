import React, { useEffect, useState } from "react";

import { useAppDispatch, useAppSelector } from "redux/hooks";
import { getSelectStylesForQueryBuilder } from "util/methods";
import { useColorMode } from "@chakra-ui/color-mode";
import Select, { SingleValue, MultiValue } from "react-select";
import { FieldComponentProps } from "components/shared/QueryBuilder/QueryCondition";
import MerchantService from "services/merchant";
import MerchantBranchDomain from "entities/domain/branch";
import { useAuth0 } from "@auth0/auth0-react";
import { setMerchantBranches } from "redux/features/merchant";

interface AvailableBranchOption {
  value: string;
  label: string;
}

const transformFromBranchToAvailableBranchOption = (
  branch: MerchantBranchDomain
): AvailableBranchOption => ({
  value: branch.id,
  label: branch.description,
});

const BranchFieldInput = ({ value, id, onChange }: FieldComponentProps) => {
  const auth0Context = useAuth0();
  const dispatch = useAppDispatch();
  const { colorMode } = useColorMode();
  const { merchant, branches } = useAppSelector((state) => state.merchant);
  const { colorScheme } = useAppSelector((state) => state.theme);
  const [isLoadingBranches, setIsLoadingBranches] = useState(!branches.length);

  useEffect(() => {
    if (!isLoadingBranches || branches.length) {
      return;
    }

    MerchantService.getMerchantBranches(auth0Context, merchant.id)
      .then((b) => {
        dispatch(setMerchantBranches(b));
      })
      .finally(() => {
        setIsLoadingBranches(false);
      });
  }, []);

  const [availableBranchOptions, setAvailableBranchOptions] = useState<
    MultiValue<AvailableBranchOption>
  >(branches.map(transformFromBranchToAvailableBranchOption));

  const [selectedBranchOptionsValue, setSelectedBranchOptionsValue] = useState<
    SingleValue<AvailableBranchOption>
  >(
    value && branches.length
      ? transformFromBranchToAvailableBranchOption(
          branches.find((b) => b.id === value)!
        )
      : null
  );

  useEffect(() => {
    if (!value) {
      setSelectedBranchOptionsValue(null);

      return;
    }

    const selectedBranch = branches.find((b) => b.id === value);

    if (!selectedBranch) {
      return;
    }

    setSelectedBranchOptionsValue(
      transformFromBranchToAvailableBranchOption(selectedBranch)
    );
  }, [value]);

  useEffect(() => {
    setAvailableBranchOptions(
      branches.map(transformFromBranchToAvailableBranchOption)
    );
  }, [branches]);

  return (
    <Select
      id={id}
      isMulti={false}
      placeholder="select branch"
      isClearable={false}
      isSearchable={true}
      value={selectedBranchOptionsValue}
      onChange={(newValue) => {
        if (newValue && !("value" in newValue)) {
          return;
        }

        onChange(newValue?.value || "");
      }}
      options={availableBranchOptions}
      styles={getSelectStylesForQueryBuilder(colorScheme, colorMode)}
    />
  );
};

export default BranchFieldInput;
