import VehicleDomain, {
  GovInspectionDomain,
  VehicleBranchDomain,
  VehicleMakeAndModel,
  VehicleRegistrationDomain,
} from "entities/domain/vehicle";
import { VehicleMakeAndModelDTO } from "entities/dto/VehicleMakeAndModelDTO";
import {
  GovInspectionDTO,
  VehicleBranchDTO,
  VehicleDTO,
  VehicleRegistrationDTO,
} from "entities/dto/VehicleDTO";

const vehicleRegistrationTransformFromDtoToDomain = (
  registration: VehicleRegistrationDTO
): VehicleRegistrationDomain =>
  new VehicleRegistrationDomain(
    registration.first_registration_date,
    registration.registration_date
  );

const vehicleBranchTransformFromDtoToDomain = (
  branch: VehicleBranchDTO
): VehicleBranchDomain => new VehicleBranchDomain(branch.id, branch.department);

const govInspectionTransformFromDtoToDomain = (
  govInspection: GovInspectionDTO
): GovInspectionDomain =>
  new GovInspectionDomain(govInspection.last_date, govInspection.due_date);

export const vehicleMakeAndModelTransformFromDtoToDomain = (
  makeAndModel: VehicleMakeAndModelDTO
): VehicleMakeAndModel =>
  new VehicleMakeAndModel(makeAndModel.name, makeAndModel.models);

export const vehicleTransformFromDtoToDomain = (
  vehicle: VehicleDTO
): VehicleDomain =>
  new VehicleDomain(
    vehicle.id,
    vehicle.merchant_id,
    vehicle.customer_ids,
    vehicle.vin || "",
    vehicle.make || "",
    vehicle.model || "",
    vehicle.class || "",
    vehicle.year,
    vehicle.license_plate || "",
    vehicle.description || "",
    vehicle.variant || "",
    vehicle.interior_color || "",
    vehicle.exterior_color || "",
    vehicle.created_at || "",
    vehicle.updated_at || "",
    vehicle.registration
      ? vehicleRegistrationTransformFromDtoToDomain(vehicle.registration)
      : null,
    vehicle.gov_inspection
      ? govInspectionTransformFromDtoToDomain(vehicle.gov_inspection)
      : null,
    vehicle.branches.map(vehicleBranchTransformFromDtoToDomain)
  );
