import Spinner from "components/spinner";
import React, { useEffect, useState } from "react";
import ChannelService from "services/channel";
import { useAuth0 } from "@auth0/auth0-react";
import { SocketMessage } from "entities/ISocketArgs";
import { useWebSocket } from "hooks/use-socket";
import { useToast } from "@chakra-ui/react";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { setCurrentStep } from "redux/features/onboarding";
import GetPhoneNumber from "./GetPhoneNumber";
import DisplayPhoneNumber from "./DisplayPhoneNumber";
import OnboardingMessagePreview from "./OnboardingMessagePreview";

const StepOne = () => {
  const auth0Context = useAuth0();
  const [phoneNumber, setPhoneNumber] = useState<string | undefined>(undefined);
  const { addEventHandler, removeEventHandler } = useWebSocket();
  const toast = useToast();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [newMessage, setNewMessage] = useState<string | undefined>();
  const [newMessageEvent, setNewMessageEvent] = useState<
    SocketMessage | undefined
  >();
  const [autoSuggestionReply, setAutoSuggestionReply] = useState<
    string | undefined
  >(undefined);
  const { currentStep } = useAppSelector((state) => state.onboarding);
  const { merchant } = useAppSelector((state) => state.merchant);
  const dispatch = useAppDispatch();

  const handleInboundMessage = (args: SocketMessage) => {
    if (args.merchant_id !== merchant.id) {
      return;
    }

    if (args.message) {
      setNewMessageEvent(args);
    } else {
      /* eslint-disable no-console */
      console.error("socket error:", args);
      /* eslint-enable no-console */
      toast({
        status: "error",
        title: "Couldn't receive a message.",
        description: "Feel free to skip this step for now then.",
      });
    }
  };

  useEffect(() => {
    addEventHandler("inbound_message", handleInboundMessage);

    return () => {
      removeEventHandler("inbound_message", handleInboundMessage);
    };
  }, [addEventHandler, removeEventHandler, autoSuggestionReply]);

  const handleWSEvent = (newMessageEv: SocketMessage) => {
    if (newMessageEv.message) {
      if (newMessageEv.message.is_incoming && !newMessage) {
        setNewMessage(newMessageEv.message.body);
      } else if (!newMessageEv.message.is_incoming && !autoSuggestionReply) {
        setAutoSuggestionReply(newMessageEv.message.body);
      }
    }
  };

  useEffect(() => {
    if (newMessageEvent) handleWSEvent(newMessageEvent);
  }, [newMessageEvent]);

  const getPhoneNumber = () => {
    setIsLoading(true);

    ChannelService.assignSmsChannel(auth0Context, merchant.id)
      .then((res) => {
        setPhoneNumber(res.identifier);
        dispatch(
          setCurrentStep({
            newCurrentStep: currentStep,
            isPreviousStepSkipped: false,
            isPreviousStepCompleted: true,
          })
        );
      })
      .catch((err) => {
        /* eslint-disable no-console */
        console.error(err);
        /* eslint-enable no-console */
        toast({
          status: "error",
          title: "Failed to get a phone number.",
          description:
            "Please try again later or if the problem persists - contact us!",
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <>
      {isLoading && <Spinner />}
      {!phoneNumber && !isLoading && (
        <GetPhoneNumber getPhoneNumber={getPhoneNumber} />
      )}

      {phoneNumber && !isLoading && !newMessage && (
        <DisplayPhoneNumber phoneNumber={phoneNumber} />
      )}

      {newMessage && !isLoading && (
        <OnboardingMessagePreview
          newMessage={newMessage}
          AutoSuggestionReply={autoSuggestionReply}
        />
      )}
    </>
  );
};

export default StepOne;
