import { Box, Button, Flex, Heading, Text } from "@chakra-ui/react";
import { useAppSelector } from "redux/hooks";
import React from "react";

interface GetPhoneNumberProps {
  getPhoneNumber: () => void;
}

const GetPhoneNumber = ({ getPhoneNumber }: GetPhoneNumberProps) => {
  const { colorScheme } = useAppSelector((state) => state.theme);

  return (
    <>
      <Heading> Get your phone number!</Heading>

      <Box my={4} textAlign="center">
        <Text> This number is our gift to you for signing up to Fuzey.</Text>
        <Text> It will help you unlock Fuzey&apos;s powerful features.</Text>
      </Box>

      <Flex justifyContent="center">
        <Button
          colorScheme={colorScheme}
          my={4}
          size="md"
          onClick={getPhoneNumber}
        >
          {" "}
          Get your phone number now
        </Button>
      </Flex>
    </>
  );
};

export default GetPhoneNumber;
