export default {
  fontFamilies: {
    main: "Lato",
  },
  fontSizes: {
    desktopHeader1: "30px",
    desktopHeader2: "20px",
    desktopHeader3: "16px",
    desktopHeader4: "14px",
    mobileHeader1: "24px",
    body2: "12px",
    desktopBodyMicro: "11px",
    mobileBodyMicro: "10px",
  },
  fontWeights: {
    bold: "700",
    semiBold: "600",
    regular: "400",
  },
  colors: {
    typography_one: "#20262d",
    typography_two: "#2b2e54",
    typography_three: "#9496a9",
    typography_four: "#ffffff",
    typography_five: "#516375",
    light_grey: "#e7edf4",
    dark_grey: "#cdcdcd",
    primary_light_blue: "#36b2fa",
    primary_dark_blue: "#0a1546",
    primary_neutral_one: "#eff5fd",
    primary_neutral_two: "#f2f6fe",
    primary_neutral_three: "#ffffff",
    secondary_yellow: "#f7b500",
    secondary_pink_red: "#fb3f6c",
    secondary_deep_blue: "#3560FF",
    secondary_purple: "#895cff",
    secondary_orange: "#efa15e",
    secondary_tango_pink: "#d86677",
    secondary_blue_midnight: "#090c9b",
    secodary_aubergine: "#392b58",
    secondary_green: "#5dbb94",
    secondary_emerald_green: "#34d1b5",
    black: "#000000",
    red: "red",
  },
  lineHeights: {
    desktopHeader1: "38px",
    desktopHeader2: "26px",
    desktopHeader3: "20px",
    mobileHeader1: "32px",
    body2: "18px",
    desktopBodyMicro: "16px",
    mobileBodyMicro: "14px",
  },
  letterSpacings: {
    heading: "0px",
    body: "0px",
  },
};
