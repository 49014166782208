import { StyleConfig } from "@chakra-ui/theme-tools";
import { isMobile, isMobileApp } from "util/methods";

const ToolTipStyles = {
  baseStyle: {
    display: isMobileApp() || isMobile() ? "none" : "initial",
  },
} as StyleConfig;

export default ToolTipStyles;
