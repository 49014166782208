import {
  WhatsappButtonDTO,
  WhatsappTemplateComponentDTO,
  WhatsappTemplateDTO,
  WhatsappTemplateExampleDTO,
} from "../dto/WhatsappTemplateDTO";
import WhatsappTemplate, {
  AnyWhatsappTemplateComponent,
  HeaderContent,
  WabaTemplateCategory,
  WabaTemplateStatus,
  WhatsappButton,
  WhatsappButtonType,
  WhatsappTemplateBodyExample,
  WhatsappTemplateComponent,
  WhatsappTemplateComponentFormat,
  WhatsappTemplateComponentType,
  WhatsappTemplateExample,
  WhatsappTemplateHeaderExample,
} from "../domain/whatsapp-template";

export const whatsappButtonTransformFromDtoToDomain = (
  button: WhatsappButtonDTO
): WhatsappButton =>
  new WhatsappButton(
    button.type as WhatsappButtonType,
    button.text,
    button.url,
    button.phone_number
  );

export const whatsappTemplateExampleTransformFromDtoToDomain = <
  T extends WhatsappTemplateComponentType,
  F extends WhatsappTemplateComponentFormat
>(
  templateComponentType: T,
  templateComponentFormat: F,
  templateExample: WhatsappTemplateExampleDTO
): WhatsappTemplateExample<T, F> => {
  if (templateComponentType === WhatsappTemplateComponentType.HEADER) {
    if (
      [
        WhatsappTemplateComponentFormat.DOCUMENT,
        WhatsappTemplateComponentFormat.IMAGE,
        WhatsappTemplateComponentFormat.VIDEO,
      ].includes(templateComponentFormat)
    ) {
      return new WhatsappTemplateHeaderExample<F>({
        header_handle: templateExample.header_handle || [""],
      } as HeaderContent<F>) as WhatsappTemplateExample<T, F>;
    } else {
      return new WhatsappTemplateHeaderExample<F>({
        header_text: templateExample.header_text || [""],
      } as HeaderContent<F>) as WhatsappTemplateExample<T, F>;
    }
  } else {
    return new WhatsappTemplateBodyExample(
      templateExample.body_text || [[""]]
    ) as WhatsappTemplateExample<T, F>;
  }
};

export const whatsappTemplateExampleTransformFromDomainToDto = <
  T extends WhatsappTemplateComponentType,
  F extends WhatsappTemplateComponentFormat
>(
  templateExample: WhatsappTemplateExample<T, F>
): WhatsappTemplateExampleDTO => {
  if (templateExample instanceof WhatsappTemplateHeaderExample) {
    return {
      header_handle: templateExample.header_handle,
      header_text: templateExample.header_text,
    };
  } else {
    return {
      body_text: templateExample.body_text,
    };
  }
};

export const whatsappButtonTransformFromDomainToDto = (
  button: WhatsappButton
): WhatsappButtonDTO => ({
  type: button.type,
  text: button.text,
  url: button.url,
  phone_number: button.phoneNumber,
});

export const whatsappTemplateComponentTransformFromDomainToDto = (
  templateComponent: AnyWhatsappTemplateComponent
): WhatsappTemplateComponentDTO => {
  const example = templateComponent.example
    ? whatsappTemplateExampleTransformFromDomainToDto(templateComponent.example)
    : null;

  const parameters = templateComponent.parameters
    ? {
        type: templateComponent.parameters.type,
        location: {
          longitude: templateComponent.parameters.location.longitude,
          latitude: templateComponent.parameters.location.latitude,
          name: templateComponent.parameters.location.name,
          address: templateComponent.parameters.location.address,
        },
      }
    : null;

  return {
    type: templateComponent.type,
    format: templateComponent.format,
    text: templateComponent.text,
    example: example || undefined,
    parameters: parameters || undefined,
    buttons:
      templateComponent.buttons?.map(whatsappButtonTransformFromDomainToDto) ||
      null,
  };
};

export const whatsappTemplateTransformFromDomainToDto = (
  template: WhatsappTemplate
): WhatsappTemplateDTO => ({
  id: template.id,
  name: template.name,
  merchant_id: template.merchantId,
  category: template.category,
  status: template.status,
  rejected_reason: template.rejectedReason,
  components: template.components.map(
    whatsappTemplateComponentTransformFromDomainToDto
  ),
  examples: template.customFields,
  namespace: template.namespace,
});

export const whatsappTemplateComponentTransformFromDtoToDomain = (
  templateComponent: WhatsappTemplateComponentDTO
): AnyWhatsappTemplateComponent => {
  const type = templateComponent.type as WhatsappTemplateComponentType;
  const format = templateComponent.format as WhatsappTemplateComponentFormat;

  const example = templateComponent.example
    ? whatsappTemplateExampleTransformFromDtoToDomain(
        type,
        format,
        templateComponent.example
      )
    : null;

  const parameters = templateComponent.parameters
    ? {
        type: templateComponent.parameters.type,
        location: {
          longitude: templateComponent.parameters.location.longitude,
          latitude: templateComponent.parameters.location.latitude,
          name: templateComponent.parameters.location.name,
          address: templateComponent.parameters.location.address,
        },
      }
    : null;

  return new WhatsappTemplateComponent(
    type,
    format,
    templateComponent.text,
    example,
    parameters,
    templateComponent.buttons?.map(whatsappButtonTransformFromDtoToDomain) ||
      null
  ) as AnyWhatsappTemplateComponent;
};

export const whatsappTemplateTransformFromDtoToDomain = (
  template: WhatsappTemplateDTO
): WhatsappTemplate =>
  new WhatsappTemplate(
    template.id,
    template.name,
    template.merchant_id,
    template.category as WabaTemplateCategory,
    template.status as WabaTemplateStatus,
    template.rejected_reason,
    template.components.map(whatsappTemplateComponentTransformFromDtoToDomain),
    template.examples,
    template.namespace
  );
