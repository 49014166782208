import {
  Box,
  Flex,
  HStack,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  VStack,
  useBreakpointValue,
  useColorMode,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { ReactComponent as SearchIcon } from "assets/icons/search-ds.svg";
import { useAppSelector } from "redux/hooks";
import useDebounce from "hooks/use-debounce";
import WhatsappIntegrationItem from "./items/WhatsappIntegrationItem";
import MessengerIntegrationItem from "./items/MessengerIntegrationItem";
import InstagramIntegrationItem from "./items/InstagramIntegrationItem";
import GmailIntegrationItem from "./items/GmailIntegrationItem";
import OutlookIntegrationItem from "./items/OutlookIntegrationItem";

const Channels: React.FC = () => {
  const { colorScheme } = useAppSelector((state) => state.theme);
  const { colorMode } = useColorMode();
  const isBaseSize = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );

  const integrations = [
    {
      name: "whatsapp",
      element: <WhatsappIntegrationItem />,
    },
    {
      name: "messenger",
      element: <MessengerIntegrationItem />,
    },
    {
      name: "instagram",
      element: <InstagramIntegrationItem />,
    },
    {
      name: "gmail",
      element: <GmailIntegrationItem />,
    },
    {
      name: "outlook",
      element: <OutlookIntegrationItem />,
    },
  ];

  const [searchText, setSearchText] = useState<string>("");
  const debouncedSearchedText = useDebounce(searchText, 500);

  return (
    <VStack w="100%" spacing={0} overflow="hidden">
      <Flex
        w="100%"
        alignItems="center"
        justifyContent="start"
        py={4}
        px={isBaseSize ? 4 : 0}
        {...(isBaseSize
          ? {
              borderBottomWidth: "1px",
              borderBottomStyle: "solid",
              borderBottomColor: colorMode === "dark" ? "gray.600" : "gray.200",
            }
          : {})}
      >
        <InputGroup maxWidth="500px">
          <InputLeftElement pointerEvents="none">
            <Icon as={SearchIcon} />
          </InputLeftElement>
          <Input
            colorScheme={colorScheme}
            id="search-bar"
            placeholder="Search"
            onChange={(event) => {
              setSearchText(event.target.value);
            }}
          />
        </InputGroup>
      </Flex>
      <Box
        w="100%"
        h="100%"
        overflow="hidden"
        borderTopRadius={isBaseSize ? 0 : "3xl"}
      >
        <HStack
          w="100%"
          h="100%"
          borderTopRadius={isBaseSize ? 0 : "3xl"}
          bgColor={colorMode === "dark" ? "gray.800" : "white"}
          justifyContent="start"
          alignItems="start"
          overflowY="auto"
          flexWrap="wrap"
          spacing={6}
          p={8}
        >
          {integrations
            .filter((i) => i.name.includes(debouncedSearchedText))
            .map((i) => (
              <React.Fragment key={i.name}>{i.element}</React.Fragment>
            ))}
        </HStack>
      </Box>
    </VStack>
  );
};

export default Channels;
