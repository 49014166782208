import { Flex, Grid, useBreakpointValue } from "@chakra-ui/react";
import React, { ReactNode } from "react";

const AdminGrid = ({ children }: { children: ReactNode }) => {
  const isBaseSize = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );

  return isBaseSize ? (
    <Flex
      flexFlow="column nowrap"
      width="100%"
      height="100%"
      padding="20px 24px"
    >
      {children}
    </Flex>
  ) : (
    <Grid
      width="100%"
      height="100%"
      padding="20px 24px"
      gridTemplateColumns="repeat(3, 1fr)"
      gridTemplateRows="repeat(3, 1fr)"
      gridColumnGap="24px"
      gridRowGap="24px"
    >
      {children}
    </Grid>
  );
};

export default AdminGrid;
