import { Box, Text, useColorMode } from "@chakra-ui/react";
import React from "react";
import { RegularOrWhatsappTemplate } from "redux/features/templates";

const TemplatePreviewColumn = ({
  item,
}: {
  item: RegularOrWhatsappTemplate;
}) => {
  const { colorMode } = useColorMode();

  return (
    <Box
      my={2}
      px={2.5}
      py={3.5}
      bg={colorMode === "dark" ? "gray.600" : "gray.50"}
      borderRadius="xl"
      _focus={{ outline: "none" }}
      width="100%"
    >
      <Text
        noOfLines={2}
        textOverflow="ellipsis"
        fontSize="12px"
        fontWeight={400}
        textAlign="left"
      >
        {item.getWholeText()}
      </Text>
    </Box>
  );
};

export default TemplatePreviewColumn;
