import { useAuth0 } from "@auth0/auth0-react";
import {
  FormControl,
  FormLabel,
  HStack,
  Stack,
  useToast,
} from "@chakra-ui/react";
import {
  Button,
  ButtonSize,
  ButtonType,
} from "theme/old-design-system/styled-components";
import React, { useState } from "react";

import AuthorizationService from "services/authorization";
import AdminMerchantDomain from "entities/domain/admin/merchants/admin-merchant-domain";
import SelectMerchant from "../select-merchant";

const ResyncPermissions = () => {
  const auth0 = useAuth0();
  const toast = useToast();

  const [selectedMerchant, setSelectedMerchant] =
    useState<AdminMerchantDomain | null>(null);

  const resync = async () => {
    try {
      if (selectedMerchant?.id) {
        await AuthorizationService.resync(auth0, selectedMerchant.id);
        toast({
          status: "success",
          title: "Resync triggered successfully.",
        });
      }
    } catch (err) {
      toast({
        status: "error",
        title: "An error occurred",
      });
    }
  };

  const handleResync = () => {
    resync();
  };

  return (
    <Stack spacing={3}>
      <FormControl isRequired>
        <FormLabel>Select Merchant</FormLabel>
        <SelectMerchant
          selectedMerchant={selectedMerchant}
          setSelectedMerchant={setSelectedMerchant}
        />
      </FormControl>
      <HStack justify="center">
        <Button
          type={ButtonType.PRIMARY}
          isDisabled={!selectedMerchant}
          mt={30}
          mb={30}
          size={ButtonSize.LARGE}
          onClick={handleResync}
        >
          Resync
        </Button>
      </HStack>
    </Stack>
  );
};

export default ResyncPermissions;
