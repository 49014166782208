import { PaymentStatus } from "entities/domain/payments/payment-domain";
import BankAccountDetailsDomain from "./bankAccountDetails";
import PublicInvoiceDomain from "./invoice";

export enum PaymentMethods {
  BANK_TRANSFER = "bank_transfer",
  CARD_PAYMENT = "card_payment",
}
export default class PublicPaymentDomain {
  id: string;

  amount: number;

  currency: string;

  status: PaymentStatus;

  description: string;

  merchantName: string;

  invoice: PublicInvoiceDomain | null;

  paymentMethods: PaymentMethods[];

  bankAccountDetails: BankAccountDetailsDomain | null;

  logo: string;

  acceptsBankTransfer(): boolean {
    return this.paymentMethods.includes(PaymentMethods.BANK_TRANSFER);
  }

  acceptsCardPayment(): boolean {
    return this.paymentMethods.includes(PaymentMethods.CARD_PAYMENT);
  }

  constructor(
    id: string,
    amount: number,
    currency: string,
    status: PaymentStatus,
    description: string,
    merchantName: string,
    invoice: PublicInvoiceDomain | null,
    paymentMethods: PaymentMethods[],
    bankAccountDetails: BankAccountDetailsDomain | null,
    logo: string
  ) {
    this.id = id;
    this.currency = currency;
    this.status = status;
    this.amount = amount;
    this.description = description;
    this.merchantName = merchantName;
    this.invoice = invoice;
    this.paymentMethods = paymentMethods;
    this.bankAccountDetails = bankAccountDetails;
    this.logo = logo;
  }
}
