import {
  Box,
  FormControl,
  FormLabel,
  useColorMode,
  useToast,
} from "@chakra-ui/react";
import EditorUltra, { EditorUltraPlugin } from "components/editor-ultra";
import TemplateAttachment from "components/user-settings/shared/TemplateAttachment";
import { CampaignStatus } from "entities/domain/campaign";
import FullTemplateDomain from "entities/domain/templates/full_template";
import React, { useCallback, useState } from "react";
import { ConversationChannel } from "entities/domain/conversations/conversation-domain";
import InboxService from "services/inbox";
import { useAuth0 } from "@auth0/auth0-react";
import { useAppSelector } from "redux/hooks";
import { RegularOrWhatsappTemplate } from "redux/features/templates";
import WhatsappTemplate from "entities/domain/whatsapp-template";
import TestMessageSender from "../TestMessageSender";
import ChooseTemplate from "./ChooseTemplate";

interface WhatsappMessageTabPanelProps {
  campaignTemplateName: string | null;
  campaignStatus: CampaignStatus;
  setCampaignTemplateName: (tid: string | null) => void;
}

const WhatsappMessageTabPanel = ({
  campaignTemplateName,
  campaignStatus,
  setCampaignTemplateName,
}: WhatsappMessageTabPanelProps) => {
  const auth0Context = useAuth0();
  const { colorMode } = useColorMode();
  const toast = useToast();
  const { merchant } = useAppSelector((state) => state.merchant);
  const { templates } = useAppSelector((state) => state.templates);
  const [editorText, setEditorText] = useState<{
    value: string;
  }>({
    value: "",
  });
  const [chosenTemplate, setChosenTemplate] = useState<WhatsappTemplate | null>(
    null
  );
  const [isSendingTestMessage, setIsSendingTestMessage] =
    useState<boolean>(false);

  const onSendTestMessage = useCallback(
    async (_messageBody: string, handle: string) => {
      if (!chosenTemplate) {
        return;
      }

      setIsSendingTestMessage(true);

      try {
        await InboxService.sendTestMessage(
          auth0Context,
          {
            channel: "whatsapp",
            handle,
            templateName: chosenTemplate.name,
          },
          merchant.id
        );

        toast({
          status: "success",
          title: `Test message has been sent to ${handle}`,
        });
      } catch (e) {
        toast({
          status: "error",
          title: `Could not send a test message to ${handle}`,
        });
      }
      setIsSendingTestMessage(false);
    },
    [chosenTemplate?.id]
  );

  return (
    <>
      <ChooseTemplate
        onTemplateSelect={(
          newSelectedTemplate: RegularOrWhatsappTemplate | null
        ) => {
          if (newSelectedTemplate instanceof FullTemplateDomain) {
            return;
          }

          setCampaignTemplateName(newSelectedTemplate?.name || null);
          setChosenTemplate(newSelectedTemplate);
          setEditorText({
            value: newSelectedTemplate?.getWholeText() || "",
          });
        }}
        channel={ConversationChannel.WHATSAPP}
        selectedTemplateId={
          templates.find((t) => t.name === campaignTemplateName)?.id || null
        }
      />
      <Box
        bgColor={colorMode === "dark" ? "gray.700" : "gray.50"}
        borderRadius="1rem"
        borderWidth="1px"
        py="1rem"
        px="1.5rem"
        minHeight="100px"
        overflowY="auto"
        mt={8}
      >
        <EditorUltra
          isEditable={false}
          highlightUnknownCustomFields={false}
          defaultText={editorText}
          placeholder="No template selected"
          channels={[ConversationChannel.WHATSAPP]}
          enabledPlugins={[EditorUltraPlugin.RICHTEXT]}
        />
        <TemplateAttachment
          mediaType={chosenTemplate?.getMedia()?.type || null}
          mediaUrl={chosenTemplate?.getMedia()?.url || null}
        />
      </Box>
      {campaignStatus !== CampaignStatus.DONE && chosenTemplate && (
        <FormControl mt={6} pb={6}>
          <FormLabel fontWeight={700}>Send test message</FormLabel>
          <TestMessageSender
            campaignMessage={null}
            campaignTemplateName={chosenTemplate.name}
            onSendTestMessage={onSendTestMessage}
            isSendingTestMessage={isSendingTestMessage}
          />
        </FormControl>
      )}
    </>
  );
};

export default WhatsappMessageTabPanel;
