import { StyleConfig, mode } from "@chakra-ui/theme-tools";

const TabsStyles = {
  baseStyle: {
    tab: {
      transition: "background-color 0.5s ease-out",
      bg: "transparent",
      _active: {
        bg: "transparent",
      },
    },
  },

  sizes: {},

  variants: {
    line: ({ colorScheme, colorMode }) => ({
      tab: {
        borderBottom: "2px solid",
        borderBottomColor: colorMode === "dark" ? "gray.600" : "gray.100",
        color: colorMode === "dark" ? `gray.600` : `gray.400`,
        svg: {
          path: {
            fill: colorMode === "dark" ? `gray.700` : `gray.400`,
          },
        },
        _hover: {
          borderBottom: "2px solid",
          color:
            colorMode === "dark" ? `${colorScheme}.400` : `${colorScheme}.400`,
          svg: {
            path: {
              fill:
                colorMode === "dark"
                  ? `${colorScheme}.400`
                  : `${colorScheme}.400`,
            },
          },
        },
        _active: {
          bg: "transparent",
        },
        _selected: {
          color:
            colorMode === "dark" ? `${colorScheme}.200` : `${colorScheme}.500`,
          svg: {
            path: {
              fill:
                colorMode === "dark"
                  ? `${colorScheme}.200`
                  : `${colorScheme}.500`,
            },
          },
        },
      },
      tablist: {
        borderBottom: "none",
      },
      tabpanel: {
        p: 0,
        marginTop: "2px",
      },
    }),
  },

  defaultProps: {
    variant: "line",
    colorScheme: "blue",
  },
} as StyleConfig;

export default TabsStyles;
