import ReviewDomain, {
  ReviewProvider,
  ReviewStatus,
} from "entities/domain/reviews/review-domain";
import ReviewReplyDomain from "entities/domain/reviews/review-reply-domain";
import {
  FetchReviewsSuccessPayload,
  FetchReviewsSuccessResponseDTO,
} from "services/reviews";
import { formatDistanceToNow, parseISO } from "date-fns";
import ReviewReplyOwnerDomain from "entities/domain/reviews/review-reply-owner-domain";
import ReviewerDomain from "entities/domain/reviews/reviewer-domain";
import {
  ReviewDTO,
  ReviewReplyDTO,
  ReviewReplyOwnerDTO,
  ReviewerDTO,
} from "../dto/ReviewsDTO";

export const reviewerTransfromDtoToDomain = (
  reviewer: ReviewerDTO
): ReviewerDomain =>
  new ReviewerDomain(
    reviewer.customer_id,
    reviewer.conversation_id,
    reviewer.avatar,
    reviewer.name
  );

export const reviewReplyOwnerTransfromDtoToDomain = (
  replyOwner: ReviewReplyOwnerDTO
): ReviewReplyOwnerDomain =>
  new ReviewReplyOwnerDomain(
    replyOwner.is_customer,
    replyOwner.name,
    replyOwner.avatar,
    replyOwner.agent_id,
    replyOwner.customer_id
  );

export const reviewReplyTransfromDtoToDomain = (
  reply: ReviewReplyDTO
): ReviewReplyDomain =>
  new ReviewReplyDomain(
    reviewReplyOwnerTransfromDtoToDomain(reply.owner),
    reply.content,
    formatDistanceToNow(parseISO(reply.created_at), { addSuffix: true })
  );

export const reviewTransformDtoToDomain = (review: ReviewDTO): ReviewDomain => {
  const replies = review.replies.map((reply) =>
    reviewReplyTransfromDtoToDomain(reply)
  );

  return new ReviewDomain(
    review.id,
    review.provider as ReviewProvider,
    review.content,
    review.display_date,
    review.created_at,
    review.updated_at ? review.updated_at : null,
    review.submitted_at ? review.submitted_at : null,
    review.last_sent_at ? review.last_sent_at : null,
    review.status as ReviewStatus,
    reviewerTransfromDtoToDomain(review.reviewer),
    review.rating,
    replies
  );
};

export const fetchReviewsSuccessResponseDTOtransformToReviewsFetchSuccessPayload =
  (response: FetchReviewsSuccessResponseDTO): FetchReviewsSuccessPayload => ({
    averageRating: response.average_rating,
    reviews: response.reviews.map((review: ReviewDTO) =>
      reviewTransformDtoToDomain(review)
    ),
    totalCount: response.total_count,
  });
