import { useBreakpointValue } from "@chakra-ui/react";
import React, { createContext, useState, useContext } from "react";

interface SettingsContextData {
  actionButton: React.ReactNode | null;
  setActionButton: React.Dispatch<React.SetStateAction<React.ReactNode | null>>;
  shouldDisplayBackButton: boolean;
  setShouldDisplayBackButton: React.Dispatch<React.SetStateAction<boolean>>;
}

const SettingsContext = createContext<SettingsContextData | undefined>(
  undefined
);

export const useSettingsContext = () => {
  const context = useContext(SettingsContext);

  if (!context) {
    throw new Error(
      "useSettingsContext must be used within a SettingsContextProvider"
    );
  }

  return context;
};

export const SettingsContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const isBaseSize = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );
  const [actionButton, setActionButton] = useState<React.ReactNode | null>(
    null
  );
  const [shouldDisplayBackButton, setShouldDisplayBackButton] =
    useState<boolean>(!!isBaseSize);

  return (
    <SettingsContext.Provider
      value={{
        actionButton,
        setActionButton,
        shouldDisplayBackButton,
        setShouldDisplayBackButton,
      }}
    >
      {children}
    </SettingsContext.Provider>
  );
};
