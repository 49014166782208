import React, { useEffect, useState } from "react";
import { Flex, Spacer, Text, useColorMode } from "@chakra-ui/react";
import AgentDomain from "entities/domain/agents/agent-domain";
import ConversationDomain from "entities/domain/conversations/conversation-domain";
import MessageDomain from "entities/domain/conversations/message-domain";
import useMessagesStore from "hooks/use-messages-store";
import FuzeyPopover, { PopoverAction } from "components/shared/FuzeyPopover";
import { ReactComponent as EyeIcon } from "assets/icons/eye.svg";
import { ReactComponent as EyeOffIcon } from "assets/icons/eye-off.svg";
import { useAppSelector } from "redux/hooks";
import MessageFooter from "./MessageFooter";

export default ({
  children,
  agent,
  activeConversation,
  message,
}: React.PropsWithChildren<{
  message: MessageDomain;
  agent?: AgentDomain;
  activeConversation?: ConversationDomain;
}>) => {
  const { markConversationAsRead, markConversationAsUnread } =
    useMessagesStore();
  const { currentAgent } = useAppSelector((state) => state.agents);

  const [popoverActions, setPopoverActions] = useState<PopoverAction[]>([]);

  useEffect(() => {
    const newPopoverActions: PopoverAction[] = [];

    if (!activeConversation || !message) {
      setPopoverActions(newPopoverActions);

      return;
    }

    newPopoverActions.push(
      message.isRead
        ? {
            icon: EyeOffIcon,
            name: "Mark Unread",
            callback: () => {
              markConversationAsUnread(
                activeConversation.id,
                message.id,
                currentAgent!.id
              );
            },
          }
        : {
            icon: EyeIcon,
            name: "Mark Read",
            callback: () => {
              markConversationAsRead(
                activeConversation.id,
                message.id,
                currentAgent!.id
              );
            },
          }
    );

    setPopoverActions(newPopoverActions);
  }, [activeConversation, currentAgent, message]);

  const { colorMode } = useColorMode();
  const { colorScheme } = useAppSelector((state) => state.theme);

  return (
    <Flex
      w="100%"
      direction="column"
      color={colorMode === "dark" ? "gray.50" : "gray.900"}
      fontSize="smaller"
    >
      {!message.isIncoming && agent?.getFullName() && (
        <Flex alignItems="center">
          <Text fontWeight={700} fontSize="sm" color={`${colorScheme}.300`}>
            {agent.getFullName()}
          </Text>
          <Spacer />
          {message.isIncoming && <FuzeyPopover actions={popoverActions} />}
        </Flex>
      )}
      {children}
      <MessageFooter message={message} />
    </Flex>
  );
};
