import { WebSocketProvider } from "hooks/use-socket";
import React from "react";
import { Outlet } from "react-router-dom";

const PrivateRoot = () => {
  return (
    <WebSocketProvider>
      <Outlet />
    </WebSocketProvider>
  );
};

export default PrivateRoot;
