import {
  FormControl,
  FormLabel,
  Input,
  Stack,
  useColorMode,
  useToast,
} from "@chakra-ui/react";
import {
  Button,
  ButtonSize,
  ButtonType,
} from "theme/old-design-system/styled-components";
import { useAppSelector, useAppDispatch } from "redux/hooks";
import React, {
  ChangeEvent,
  ChangeEventHandler,
  useEffect,
  useRef,
  useState,
} from "react";
import Select, { SingleValue } from "react-select";

import { getReactSelectStyles } from "util/methods";
import AdminService, { UpdateMerchantPayload } from "services/admin";
import { useAuth0 } from "@auth0/auth0-react";
import AdminMerchantDomain from "entities/domain/admin/merchants/admin-merchant-domain";
import {
  addFileAttachment,
  addImageAttachment,
  clearAttachments,
} from "redux/features/attachments";
import {
  MerchantCountryCodeOptionTypes,
  merchantCountryCodeOptions,
} from "./merchant-form";
import SelectMerchant from "./select-merchant";

const reader = new FileReader();

const AddMerchantForm = () => {
  const auth0Context = useAuth0();
  const dispatch = useAppDispatch();
  const [merchantName, setMerchantName] = useState<string>("");
  const [countryCodeValue, setCountryCodeValue] = useState<string>("");
  const [selectedCountryCodeValue, setSelectedCountryCodeValue] =
    useState<SingleValue<MerchantCountryCodeOptionTypes>>(null);
  const [selectedMerchant, setSelectedMerchant] =
    useState<AdminMerchantDomain | null>(null);
  const [googlePlaceIdValue, setGooglePlaceIdValue] = useState<string>("");
  const [googleAccountIdValue, setGoogleAccountIdValue] = useState<string>("");
  const [googleLocationIdValue, setGoogleLocationIdValue] =
    useState<string>("");
  const [stripeIdValue, setStripeIdValue] = useState<string>("");
  const [keyloopEnterpriseId, setKeyloopEnterpriseId] = useState<string>("");
  const [keyloopStoreId, setKeyloopStoreId] = useState<string>("");
  const [registeredCompanyIdValue, setRegisteredCompanyIdValue] =
    useState<string>("");
  const [googleReviewLink, setGoogleReviewLink] = useState<string>("");
  const [fbBusinessId, setFbBusinessId] = useState<string>("");

  const { colorMode } = useColorMode();
  const toast = useToast();
  const { colorScheme } = useAppSelector((state) => state.theme);
  const fileInput = useRef<HTMLInputElement>(null);

  const { files, images } = useAppSelector((state) => state.attachments);

  const handleChangeStripeId = (event: ChangeEvent<HTMLInputElement>) =>
    setStripeIdValue(event.target.value);

  const handleChangeGoogleAccountId = (event: ChangeEvent<HTMLInputElement>) =>
    setGoogleAccountIdValue(event.target.value);

  const handleChangeGoogleLocationId = (event: ChangeEvent<HTMLInputElement>) =>
    setGoogleLocationIdValue(event.target.value);

  const handleChangePlaceId = (event: ChangeEvent<HTMLInputElement>) =>
    setGooglePlaceIdValue(event.target.value);

  const handleChangeCompanyId = (event: ChangeEvent<HTMLInputElement>) =>
    setRegisteredCompanyIdValue(event.target.value);

  const handleChangeGoogleReviewLink = (event: ChangeEvent<HTMLInputElement>) =>
    setGoogleReviewLink(event.target.value);

  const handleChangFbBusinessIdValue = (event: ChangeEvent<HTMLInputElement>) =>
    setFbBusinessId(event.target.value);

  const handleChangeMerchantName = (event: ChangeEvent<HTMLInputElement>) => {
    setMerchantName(event.target.value);
  };

  const handleChangeKeyloopEnterpriseId = (
    event: ChangeEvent<HTMLInputElement>
  ) => setKeyloopEnterpriseId(event.target.value);

  const handleChangeKeyloopStoreId = (event: ChangeEvent<HTMLInputElement>) =>
    setKeyloopStoreId(event.target.value);

  const handleChangeCountryCode = (
    e: SingleValue<MerchantCountryCodeOptionTypes>
  ) => {
    setCountryCodeValue(e!.value);
    setSelectedCountryCodeValue(e);
  };

  const resetValues = () => {
    setGooglePlaceIdValue("");
    setGoogleAccountIdValue("");
    setStripeIdValue("");
    setRegisteredCompanyIdValue("");
    setGoogleReviewLink("");
    setFbBusinessId("");
    setMerchantName("");
    setSelectedMerchant(null);
    dispatch(clearAttachments());
    setCountryCodeValue("");
    setSelectedCountryCodeValue(null);
    setKeyloopEnterpriseId("");
    setKeyloopStoreId("");
  };

  useEffect(() => {
    if (!selectedMerchant) {
      resetValues();

      return;
    }

    setGooglePlaceIdValue(selectedMerchant.externalMetadata.googlePlaceId);
    setGoogleAccountIdValue(selectedMerchant.externalMetadata.googleAccountId);
    setGoogleLocationIdValue(
      selectedMerchant.externalMetadata.googleLocationId
    );
    setStripeIdValue(selectedMerchant.externalMetadata.stripeAccountId);
    setRegisteredCompanyIdValue(selectedMerchant.registeredCompanyId);
    setGoogleReviewLink(selectedMerchant.externalMetadata.googleReviewLink);
    setFbBusinessId(selectedMerchant.externalMetadata.facebookBusinessId);
    setMerchantName(selectedMerchant.name);
    setCountryCodeValue(selectedMerchant.country);
    setSelectedCountryCodeValue(
      merchantCountryCodeOptions.find(
        (option) => option.value === selectedMerchant.country
      )!
    );
    setKeyloopEnterpriseId(
      selectedMerchant.externalMetadata.keyloop?.enterpriseId || ""
    );
    setKeyloopStoreId(selectedMerchant.externalMetadata.keyloop?.storeId || "");
  }, [selectedMerchant]);

  const onFileChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    if (!e.target.files) return;

    const file = e.target.files[0];

    if (file) {
      dispatch(addFileAttachment(file));

      reader.readAsDataURL(file);
    }

    reader.onload = () => {
      dispatch(addImageAttachment(reader.result as string));
    };

    reader.onerror = () => {
      toast({
        status: "error",
        title: "An error occurred",
      });
    };
  };

  const isDisabled = (): boolean => !selectedMerchant;

  const updateMerchant = async () => {
    const updateMerchantObj: UpdateMerchantPayload = {
      merchantId: selectedMerchant!.id,
    };
    if (merchantName) {
      updateMerchantObj.name = merchantName;
    }

    if (countryCodeValue) {
      updateMerchantObj.country = countryCodeValue;
    }

    if (googlePlaceIdValue) {
      updateMerchantObj.google_place_id = googlePlaceIdValue;
    }
    if (googleAccountIdValue) {
      updateMerchantObj.google_account_id = googleAccountIdValue;
    }
    if (googleLocationIdValue) {
      updateMerchantObj.google_location_id = googleLocationIdValue;
    }
    if (stripeIdValue) {
      updateMerchantObj.stripe_account_id = stripeIdValue;
    }
    if (registeredCompanyIdValue) {
      updateMerchantObj.registered_company_id = registeredCompanyIdValue;
    }
    if (googleReviewLink) {
      updateMerchantObj.google_review_link = googleReviewLink;
    }
    if (fbBusinessId) {
      updateMerchantObj.facebook_business_id = fbBusinessId;
    }

    if (keyloopEnterpriseId && keyloopStoreId) {
      updateMerchantObj.keyloop = {
        enterpriseId: keyloopEnterpriseId,
        storeId: keyloopStoreId,
      };
    }

    if (images.length > 0) {
      const file = files[0];
      updateMerchantObj.logo = file;
    }

    await AdminService.updateMerchant(auth0Context, updateMerchantObj);
  };

  const handleSaveMerchant = async () => {
    try {
      await updateMerchant();

      toast({
        status: "success",
        title: "Merchant Updated Successfully",
      });
    } catch (_error: unknown) {
      toast({
        status: "error",
        title: "Error updating merchant",
      });
    } finally {
      resetValues();
    }
  };

  return (
    <>
      <div id="merchant-form-input">
        <Stack spacing={3}>
          <FormControl isRequired>
            <FormLabel mb="0.5rem">Select Merchant</FormLabel>
            <SelectMerchant
              selectedMerchant={selectedMerchant}
              setSelectedMerchant={setSelectedMerchant}
            />
          </FormControl>
          <FormControl>
            <FormLabel mb="0.5rem">Name</FormLabel>
            <Input
              value={merchantName}
              onChange={handleChangeMerchantName}
              placeholder="Fuzey LTD"
            />
          </FormControl>
          <FormControl>
            <FormLabel mb="0.5rem">Country</FormLabel>
            <Select
              placeholder="Select Country Code"
              onChange={handleChangeCountryCode}
              options={merchantCountryCodeOptions}
              value={selectedCountryCodeValue}
              styles={getReactSelectStyles(colorMode, colorScheme)}
            />
          </FormControl>
          <FormControl>
            <FormLabel mb="0.5rem">Registered Company ID</FormLabel>
            <Input
              value={registeredCompanyIdValue}
              onChange={handleChangeCompanyId}
              placeholder="0000000000"
            />
          </FormControl>
          <FormControl>
            <FormLabel mb="0.5rem">Google Review Link</FormLabel>
            <Input
              value={googleReviewLink}
              onChange={handleChangeGoogleReviewLink}
              placeholder="google.com/0000"
            />
          </FormControl>
          <FormControl>
            <FormLabel mb="0.5rem">Facebook Business ID</FormLabel>
            <Input
              value={fbBusinessId}
              onChange={handleChangFbBusinessIdValue}
              placeholder="0000"
            />
          </FormControl>
          <FormControl>
            <FormLabel mb="0.5rem">Google Place ID</FormLabel>
            <Input
              value={googlePlaceIdValue}
              onChange={handleChangePlaceId}
              placeholder="0000"
            />
          </FormControl>
          <FormControl>
            <FormLabel mb="0.5rem">Google Account ID</FormLabel>
            <Input
              value={googleAccountIdValue}
              onChange={handleChangeGoogleAccountId}
              placeholder="0000"
            />
          </FormControl>
          <FormControl>
            <FormLabel mb="0.5rem">Google Location ID</FormLabel>
            <Input
              value={googleLocationIdValue}
              onChange={handleChangeGoogleLocationId}
              placeholder="0000"
            />
          </FormControl>
          <FormControl>
            <FormLabel mb="0.5rem">Stripe Account ID</FormLabel>
            <Input
              value={stripeIdValue}
              onChange={handleChangeStripeId}
              placeholder="0000"
            />
          </FormControl>
          <FormControl>
            <FormLabel mb="0.5rem">Keyloop Enterprise ID</FormLabel>
            <Input
              value={keyloopEnterpriseId}
              onChange={handleChangeKeyloopEnterpriseId}
              placeholder="0000"
            />
          </FormControl>
          <FormControl>
            <FormLabel mb="0.5rem">Keyloop Store ID</FormLabel>
            <Input
              value={keyloopStoreId}
              onChange={handleChangeKeyloopStoreId}
              placeholder="0000"
            />
          </FormControl>
          <FormControl>
            <FormLabel mb="0.5rem">Merchant Logo</FormLabel>
            <input
              ref={fileInput}
              type="file"
              onChange={onFileChange}
              accept={"image/*"}
            />
          </FormControl>
        </Stack>
        <Button
          type={ButtonType.PRIMARY}
          onClick={handleSaveMerchant}
          isDisabled={isDisabled()}
          mt={30}
          mb={30}
          size={ButtonSize.LARGE}
        >
          Update Merchant
        </Button>
      </div>
    </>
  );
};

export default AddMerchantForm;
