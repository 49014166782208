import { CloseIcon, Icon as ChakraIcon } from "@chakra-ui/icons";
import {
  Input,
  InputGroup,
  InputLeftElement,
  Flex,
  useBreakpointValue,
  Image,
  List,
  Text,
  Heading,
  Box,
  useColorMode,
  useToast,
} from "@chakra-ui/react";
import starIcon from "assets/icons/star.svg";
import {
  Color,
  Icon,
  IconType,
} from "theme/old-design-system/styled-components";
import TemplateDomain from "entities/domain/templates";
import useTemplatesStore from "hooks/use-templates-store";
import React, { memo, useCallback, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";

import { messageTemplateInsertion$ } from "util/constants";
import { ReactSVG } from "react-svg";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { propagateTemplateUpdate } from "redux/features/conversations";

interface TemplateProps {
  id: string;
  title: string;
  text: string;
  isFavourite: boolean;
  onClick: (templateId: string) => void;
  onMakeFavourite: (templateId: string) => void;
  onMakeGeneral: (templateId: string) => void;
}

const Template = memo(
  ({
    id,
    title,
    text,
    isFavourite,
    onClick,
    onMakeFavourite,
    onMakeGeneral,
  }: TemplateProps) => {
    const { colorMode } = useColorMode();

    return (
      <Flex
        justifyContent="flex-start"
        alignItems="center"
        padding="16px 12px"
        borderRadius="8px"
        transition="all 0.1s ease-in-out"
        overflow="hidden"
        _hover={{
          cursor: "pointer",
          background: colorMode === "dark" ? "gray.900" : "gray.50",
        }}
        onClick={(e) => {
          const target = e.target as HTMLImageElement;
          const isTemplateChosen = target.alt !== "star-icon";

          if (isTemplateChosen) {
            onClick(id);
          }
        }}
      >
        {isFavourite ? (
          <Image
            width="1rem"
            height="1rem"
            mr="1rem"
            _hover={{
              transform: "scale(1.5)",
            }}
            src={starIcon}
            alt="star-icon"
            onClick={() => {
              onMakeGeneral(id);
            }}
          />
        ) : (
          <Image
            width="1rem"
            height="1rem"
            mr="1rem"
            filter="grayscale(100%)"
            _hover={{
              transform: "scale(1.5)",
            }}
            src={starIcon}
            alt="star-icon"
            onClick={() => {
              onMakeFavourite(id);
            }}
          />
        )}
        <Flex direction="column">
          <Heading as="h6" size="xs" mb="4px" textAlign="left">
            {title}
          </Heading>
          <Text
            fontSize="xs"
            fontWeight="light"
            whiteSpace="nowrap"
            textOverflow="ellipsis"
          >
            {text}
          </Text>
        </Flex>
      </Flex>
    );
  }
);

interface TemplatesPopoverProps {
  onClose: () => void;
  id?: string;
}

const TemplatesPopover = ({ onClose, ...rest }: TemplatesPopoverProps) => {
  const dispatch = useAppDispatch();
  const { merchant } = useAppSelector((state) => state.merchant);
  const [searchValue, setSearchValue] = useState<string>("");
  const { markTemplateAsFavouriteOrGeneral } = useTemplatesStore();
  const { errors } = useAppSelector((state) => state.templates);
  const { colorScheme } = useAppSelector((state) => state.theme);
  const toast = useToast();

  const useOutsideAlerterRef = useRef<HTMLDivElement>(null);
  const { templates: conversationTemplates } = useAppSelector(
    (state) => state.conversations
  );
  const [filteredTemplates, setFilteredTemplates] = useState<TemplateDomain[]>(
    (conversationTemplates || []).filter((t: TemplateDomain) =>
      t.title.toLowerCase().includes(searchValue.toLowerCase())
    )
  );

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (
        useOutsideAlerterRef?.current &&
        !useOutsideAlerterRef?.current.contains(event.target)
      ) {
        onClose();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [useOutsideAlerterRef]);

  useEffect(() => {
    if (errors.length) {
      toast({ status: "error", title: errors[0] });
    }
  }, [errors]);

  useEffect(() => {
    setFilteredTemplates(
      conversationTemplates.filter((t: TemplateDomain) =>
        t.title.toLowerCase().includes(searchValue.toLowerCase())
      )
    );
  }, [conversationTemplates, searchValue]);

  const findTemplateById = (templateId: string) => {
    return conversationTemplates.find(
      (t: TemplateDomain) => t.id === templateId
    );
  };

  const onTemplateClick = (templateId: string) => {
    const template = findTemplateById(templateId);

    onClose();

    if (!template) return;

    messageTemplateInsertion$.next({
      id: template.id,
      name: template.name,
      title: template.title,
      text: template.text,
      customFields: template.customFields,
      shortcut: template.shortcut,
      subject: template.subject,
      favourite: template.favourite,
      channels: template.channels,
      mediaType: template.mediaType,
      mediaUrl: template.mediaUrl,
      buttons: template.buttons,
    });
  };

  const onMakeFavouriteClick = useCallback(
    async (templateId: string) => {
      const updatedTemplate = await markTemplateAsFavouriteOrGeneral(
        conversationTemplates.find((t) => t.id === templateId),
        true
      );

      if (!updatedTemplate) return;

      dispatch(propagateTemplateUpdate(updatedTemplate as TemplateDomain));
    },
    [conversationTemplates, markTemplateAsFavouriteOrGeneral]
  );

  const onMakeGeneralClick = useCallback(
    async (templateId: string) => {
      const updatedTemplate = await markTemplateAsFavouriteOrGeneral(
        conversationTemplates.find((t) => t.id === templateId),
        false
      );

      if (!updatedTemplate) return;

      dispatch(propagateTemplateUpdate(updatedTemplate as TemplateDomain));
    },
    [conversationTemplates, markTemplateAsFavouriteOrGeneral]
  );

  const isBaseSize = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );
  const { colorMode } = useColorMode();

  return (
    <Box
      padding="21px 25px 12px"
      boxShadow="0px 3px 33px rgba(137, 156, 197, 0.393111)"
      borderRadius="md"
      maxHeight="50vh"
      overflowY="auto"
      sx={{
        "#templates-search-input": {
          borderRadius: "18px",
          backgroundColor: colorMode === "dark" ? "gray.900" : "white",
        },
      }}
      ref={useOutsideAlerterRef}
      {...rest}
    >
      <Flex alignItems="center" justifyContent="space-between" mb="1rem">
        <Heading as="h4" size="sm">
          Templates
        </Heading>
        <Flex gridGap={3} alignItems="center" justifyContent="end">
          {!isBaseSize && (
            <Link to={`/${merchant.id}/settings/templates`}>
              <ChakraIcon
                as={ReactSVG}
                src="/fi-rr-settings.svg"
                css={{
                  svg: {
                    width: "1.25rem",
                    height: "1.25rem",
                    g: {
                      path: {
                        fill: `${Color.DARK_GREY.value}`,
                      },
                    },
                  },
                }}
              />
            </Link>
          )}
          <CloseIcon
            color={Color.LIGHT_GREY.value}
            onClick={() => onClose()}
            _hover={{
              cursor: "pointer",
            }}
          />
        </Flex>
      </Flex>

      <Flex alignItems="center" justifyContent="space-between" mb="1.5rem">
        <InputGroup>
          <InputLeftElement pointerEvents="none">
            <Icon
              type={IconType.SEARCH}
              pathFill={Color.DARK_GREY}
              height={17}
              width={17}
            />
          </InputLeftElement>
          <Input
            colorScheme={colorScheme}
            id="templates-search-input"
            background="gray.50"
            // @ts-ignore
            onChange={(e) => setSearchValue(e.target.value)}
            placeholder="Search"
          />
        </InputGroup>
      </Flex>

      <List display="flex" flexFlow="column nowrap" listStyleType="none">
        {filteredTemplates.map(
          ({ id, title, text, favourite }: TemplateDomain, i) => (
            <Template
              id={id}
              isFavourite={favourite}
              title={title}
              text={text}
              key={i.toString()}
              onClick={onTemplateClick}
              onMakeFavourite={onMakeFavouriteClick}
              onMakeGeneral={onMakeGeneralClick}
            />
          )
        )}
      </List>
    </Box>
  );
};

export default TemplatesPopover;
