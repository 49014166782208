import { useAuth0 } from "@auth0/auth0-react";
import { Flex, Heading, useBreakpointValue } from "@chakra-ui/react";
import StatisticsDomain from "entities/domain/campaign/statistics";
import { useAppSelector } from "redux/hooks";
import React, { useEffect, useState } from "react";
import CampaignsService from "services/campaigns";
import CampaignStat from "./CampaignStat";

const StatsSection = () => {
  const auth0Context = useAuth0();
  const { merchant } = useAppSelector((state) => state.merchant);
  const isBaseSize = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );

  const [statistics, setStatistics] = useState<StatisticsDomain[] | null>(null);

  const fetchStatistics = async (): Promise<StatisticsDomain[] | undefined> => {
    try {
      const stats = await CampaignsService.getStatistics(
        auth0Context,
        merchant.id
      );

      return stats;
    } catch (e) {
      // eslint-disable-next-line
      console.error(
        `Failed to get campaign statistics for merchant ${merchant.id}`,
        e
      );
    }
  };

  useEffect(() => {
    if (!merchant) return;

    fetchStatistics().then((stats) => {
      if (stats) {
        setStatistics(
          stats.filter((stat) => {
            return StatisticsDomain.getAllEnabled(isBaseSize).includes(
              stat.name
            );
          })
        );
      }
    });
  }, []);

  return !statistics ? null : (
    <>
      <Heading mt={12} as="h2" fontWeight="bold" size="lg">
        Monthly Performance
      </Heading>
      <Flex
        pt={8}
        px={10}
        gridGap={8}
        justifyContent="space-evenly"
        flexWrap="wrap"
      >
        {statistics.map((stat) => (
          <CampaignStat
            value={stat.value}
            isTrendingUp={stat.isTrendingUp()}
            isTrendingSideways={stat.isTrendingSideways()}
            isGoodTrend={stat.isGood()}
            header={stat.getHeader()}
            description={stat.getDescription()}
          />
        ))}
      </Flex>
    </>
  );
};

export default StatsSection;
