import {
  Box,
  Divider,
  SkeletonText,
  VStack,
  useBreakpointValue,
  useColorMode,
} from "@chakra-ui/react";
import React from "react";

const SkeletonOverlay = () => {
  const { colorMode } = useColorMode();
  const isBaseSize = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );

  return (
    <VStack
      h="100%"
      w="100%"
      position="absolute"
      top={0}
      left={0}
      overflow="hidden"
      zIndex={50}
      bgColor={colorMode === "dark" ? "gray.800" : "white"}
    >
      {[...Array(5).keys()].map((i) => (
        <Box
          key={`skeleton-file-${i}`}
          w="100%"
          h="fit-content"
          gridGap={4}
          alignItems="center"
        >
          {i === 0 ? null : <Divider />}
          <SkeletonText
            py={isBaseSize ? 4 : 6}
            px={isBaseSize ? 2 : 12}
            noOfLines={1}
            spacing={4}
            skeletonHeight={2}
            flexShrink={1}
            flexGrow={1}
          />
        </Box>
      ))}
    </VStack>
  );
};

export default SkeletonOverlay;
