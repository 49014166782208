import { Button } from "@chakra-ui/button";
import { Icon } from "@chakra-ui/icon";
import Integration from "components/user-settings/shared/Integration";
import IntegrationDescription from "components/user-settings/shared/IntegrationDescription";
import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { ReactComponent as XeroIcon } from "assets/icons/xero.svg";
import { FaPlus } from "react-icons/fa";
import { useAuth0 } from "@auth0/auth0-react";

import IntegrationService from "services/integrations";
import { updateMerchantSuccess } from "redux/features/merchant";
import DisconnectIntegrationButton from "components/user-settings/shared/DisconnectIntegrationButton";
import { MerchantUserGuides } from "entities/domain/admin/merchants/merchant-user-guides";
import { getXeroOauth2Endpoint } from "components/user-settings/constants";
import { useDisclosure } from "@chakra-ui/hooks";
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/modal";
import { useBreakpointValue } from "@chakra-ui/media-query";
import { IntegrationName } from "entities/domain/admin/merchants/merchant-integrations";
import { useToast } from "@chakra-ui/react";

interface XeroIntegrationItemProps {}

const XeroIntegrationItem = (_props: XeroIntegrationItemProps) => {
  const { colorScheme } = useAppSelector((state) => state.theme);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const dispatch = useAppDispatch();
  const auth0Context = useAuth0();
  const merchant = useAppSelector((state) => state.merchant.merchant);
  const toast = useToast();
  const isBaseSize = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );

  const isConnected = merchant.isIntegrationEnabled(IntegrationName.XERO);
  const [isDisabled, setIsDisabled] = useState<boolean>(false);

  const onDisconnectXero = async () => {
    try {
      await IntegrationService.removeXeroIntegration(auth0Context, merchant.id);

      merchant.integrations[IntegrationName.XERO].connected = false;
      dispatch(updateMerchantSuccess(merchant));
    } catch (_error) {
      toast({
        status: "error",
        title:
          "Uh Oh! We could not disconnect Xero at this time. Please try again",
      });
    }
  };

  useEffect(() => {
    if (merchant.userGuides.pending.includes(MerchantUserGuides.ONBOARDING)) {
      setIsDisabled(true);

      return;
    }

    setIsDisabled(false);
  }, [merchant.userGuides.pending]);

  return (
    <Integration
      isConnected={isConnected}
      icon={<Icon as={XeroIcon} height="3rem" width="8rem" />}
      secondaryButton={
        isConnected && !isDisabled ? (
          <DisconnectIntegrationButton onClick={onDisconnectXero} />
        ) : null
      }
      primaryButton={
        isConnected || isDisabled ? null : (
          <>
            <Button
              colorScheme={colorScheme}
              leftIcon={<Icon as={FaPlus} />}
              onClick={() => {
                if (merchant.isIntegrationEnabled(IntegrationName.QUICKBOOKS)) {
                  onOpen();
                  return;
                }

                window.location.assign(getXeroOauth2Endpoint(merchant.id));
              }}
            >
              Connect
            </Button>
            <Modal
              isOpen={isOpen}
              onClose={onClose}
              size={isBaseSize ? "full" : "md"}
            >
              <ModalOverlay />
              <ModalContent>
                <ModalHeader>
                  Please disconnect your Quickbooks integration
                </ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                  Your account is already connected to an invoicing software.
                  Please contact your Customer Success Manager to disconnect
                  from your account from Quickbooks before connecting with Xero.
                </ModalBody>
                <ModalFooter>
                  <Button colorScheme={colorScheme} onClick={onClose}>
                    Close
                  </Button>
                </ModalFooter>
              </ModalContent>
            </Modal>
          </>
        )
      }
    >
      <IntegrationDescription text="Install Invoicing in 2 minutes" />
    </Integration>
  );
};

export default XeroIntegrationItem;
