/* eslint-disable import/prefer-default-export */
import CampaignDomain, {
  CampaignStatus,
  MessagesAnalytics,
} from "entities/domain/campaign";
import CampaignStatisticsDomain, {
  TrendOption,
} from "entities/domain/campaign/statistics";
import { CampaignDTO } from "entities/dto/CampaignDTO";
import { CampaignStatisticsDTO } from "entities/dto/CampaignStatisticsDTO";
import { ConversationChannel } from "entities/domain/conversations/conversation-domain";

export const campaignTransformFromDtoToDomain = (
  c: CampaignDTO
): CampaignDomain =>
  new CampaignDomain(
    c.id,
    c.name,
    c.merchant_id,
    c.status as CampaignStatus,
    c.audience_id,
    c.template_id,
    c.message_body,
    c.sent_at ? (c.sent_at.includes("Z") ? c.sent_at : `${c.sent_at}Z`) : null,
    c.scheduled_at
      ? c.scheduled_at.includes("Z")
        ? c.scheduled_at
        : `${c.scheduled_at}Z`
      : null,
    c.scheduled_for
      ? c.scheduled_for.includes("Z")
        ? c.scheduled_for
        : `${c.scheduled_for}Z`
      : null,
    c.created_at
      ? c.created_at.includes("Z")
        ? c.created_at
        : `${c.created_at}Z`
      : null,
    c.updated_at
      ? c.updated_at.includes("Z")
        ? c.updated_at
        : `${c.updated_at}Z`
      : null,
    {
      total: c.messages_analytics?.total,
      totalReplied: c.messages_analytics?.total_replied,
      totalDelivered: c.messages_analytics?.total_delivered,
      totalRead: c.messages_analytics?.total_read,
      totalFailed: c.messages_analytics?.total_failed,
      totalSegments: c.messages_analytics?.total_segments,
    } as MessagesAnalytics | null,
    (c.channel as ConversationChannel) || ConversationChannel.SMS,
    c.media_url || null,
    c.media_type || null,
    null
  );

export const campaignStatisticsTransformFromDtoToDomain = (
  c: CampaignStatisticsDTO
): CampaignStatisticsDomain =>
  new CampaignStatisticsDomain(
    c.name,
    c.value,
    c.trend as TrendOption,
    c.definition_of_good as TrendOption
  );
