import React from "react";
import FormGroup from "../form-group";
import AddMerchantAssistantForm from "../forms/AddMerchantAssistantForm";

const AI = () => {
  return (
    <>
      <FormGroup title=" Add Assistant">
        <AddMerchantAssistantForm />
      </FormGroup>
    </>
  );
};

export default AI;
