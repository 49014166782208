export enum TrendOption {
  UP = "up",
  DOWN = "down",
  SIDEWAYS = "sideways",
}

const STATISTICS_TEXTS: {
  [key: string]: {
    header: string;
    description: string;
  };
} = {
  campaigns_completed: {
    header: "Campaigns Delivered",
    description: "The number of campaigns that have been delivered.",
  },
  messages_delivered: {
    header: "Messages Delivered",
    description:
      "The number of messages that have been successfully delivered.",
  },
  messages_failed: {
    header: "Messages Failed",
    description: "The number of messages that have failed to be delivered.",
  },
  average_reply_rate: {
    header: "Average Reply Rate",
    description: "The average rate at which recipients reply to campaigns.",
  },
  campaigns_drafted: {
    header: "Campaigns Drafted",
    description: "The number of campaigns that have been drafted.",
  },
  campaigns_scheduled: {
    header: "Campaigns Scheduled",
    description:
      "The number of campaigns that have been scheduled for future delivery.",
  },
  unsubscriptions: {
    header: "Unsubscriptions",
    description:
      "The number of recipients who have unsubscribed from receiving messages.",
  },
};

export default class StatisticsDomain {
  name: string;

  value: number;

  trend: TrendOption;

  definitionOfGood: TrendOption;

  constructor(
    name: string,
    value: number,
    trend: TrendOption,
    definitionOfGood: TrendOption
  ) {
    this.name = name;
    this.value = value;
    this.trend = trend;
    this.definitionOfGood = definitionOfGood;
  }

  isGood(): boolean {
    return this.trend === this.definitionOfGood;
  }

  isTrendingUp(): boolean {
    return this.trend === TrendOption.UP;
  }

  isTrendingSideways(): boolean {
    return this.trend === TrendOption.SIDEWAYS;
  }

  getHeader(): string {
    return STATISTICS_TEXTS[this.name]?.header || this.name;
  }

  getDescription(): string {
    return STATISTICS_TEXTS[this.name]?.description || this.name;
  }

  static getAllEnabled(isBaseSize: boolean | undefined): string[] {
    if (isBaseSize) {
      return ["campaigns_completed", "average_reply_rate"];
    } else {
      return [
        "campaigns_completed",
        "messages_delivered",
        "messages_failed",
        "average_reply_rate",
        "unsubscriptions",
      ];
    }
  }
}
