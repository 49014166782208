import { Button } from "@chakra-ui/button";
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/modal";
import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import Select, { SingleValue } from "react-select";
import { Text, VStack } from "@chakra-ui/layout";
import { FormControl, FormLabel } from "@chakra-ui/form-control";
import { getReactSelectStyles } from "util/methods";
import { useColorMode } from "@chakra-ui/color-mode";
import AgentsService from "services/agents";
import { useAuth0 } from "@auth0/auth0-react";
import {
  clearSelectedAgents,
  propagateTeamUpdate,
} from "redux/features/agents";
import { batch } from "react-redux";
import { useBreakpointValue } from "@chakra-ui/media-query";
import { useToast } from "@chakra-ui/toast";
import TeamDomain from "entities/domain/team";

interface RemoveFromTeamModalProps {
  isOpen: boolean;
  onClose: () => void;
}

interface TeamSelectOption {
  value: string;
  label: string;
}

const RemoveFromTeamModal = ({ isOpen, onClose }: RemoveFromTeamModalProps) => {
  const isBaseSize = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );
  const auth0Context = useAuth0();
  const { colorMode } = useColorMode();
  const { colorScheme } = useAppSelector((state) => state.theme);
  const { teams, selectedAgentIds, agents } = useAppSelector(
    (state) => state.agents
  );
  const { merchant } = useAppSelector((state) => state.merchant);
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [teamsOfSelectedAgents, setTeamsOfSelectedAgents] = useState<
    TeamDomain[]
  >(
    teams.filter((t) =>
      t.agentIds.some((aid) => selectedAgentIds.includes(aid))
    )
  );

  const [teamOptions, setTeamOptions] = useState<TeamSelectOption[]>(
    teamsOfSelectedAgents.map((team) => ({
      label: team.name,
      value: team.id,
    }))
  );

  useEffect(() => {
    const newTeamsOfSelectedAgents = teams.filter((t) =>
      t.agentIds.some((aid) => selectedAgentIds.includes(aid))
    );

    setTeamsOfSelectedAgents(newTeamsOfSelectedAgents);
    setTeamOptions(
      newTeamsOfSelectedAgents.map((team) => ({
        label: team.name,
        value: team.id,
      }))
    );
  }, [teams, selectedAgentIds]);

  const [selectedTeamValue, setSelectedTeamValue] =
    useState<SingleValue<TeamSelectOption>>(null);
  const toast = useToast();

  const updateTeam = async () => {
    setIsLoading(true);

    const selectedTeam = teams.find(
      (team) => team.id === selectedTeamValue?.value
    );

    if (!selectedTeam) {
      toast({
        title: "Please select a team and then try again!",
        status: "error",
      });

      return;
    }

    try {
      const updatedTeam = await AgentsService.updateTeam(auth0Context, {
        merchant_id: merchant.id,
        id: selectedTeam.id,
        agent_ids: selectedTeam.agentIds.filter(
          (a) => !selectedAgentIds.includes(a)
        ),
      });

      batch(() => {
        dispatch(propagateTeamUpdate(updatedTeam));
        dispatch(clearSelectedAgents());
      });
      onClose();
      toast({
        title: "Team updated successfully!",
        status: "success",
      });
    } catch (_e: unknown) {
      toast({
        title: "Error updating team, try again later or contact support!",
        status: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setSelectedTeamValue(null);
  }, [isOpen]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} size={isBaseSize ? "full" : "md"}>
      <ModalOverlay />
      <ModalContent
        containerProps={{
          style: {
            zIndex: 9999,
          },
        }}
      >
        <ModalHeader>Remove from a Team</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack>
            <FormControl>
              <FormLabel>Select a team</FormLabel>
              <Select
                placeholder="Select team"
                onChange={(newTeam) => setSelectedTeamValue(newTeam)}
                isClearable={true}
                options={teamOptions}
                value={selectedTeamValue}
                styles={getReactSelectStyles(colorMode, colorScheme)}
              />
            </FormControl>
            {selectedTeamValue ? (
              <Text
                color={colorMode === "dark" ? "gray.600" : "gray.400"}
                fontWeight="light"
              >
                By proceeding with this action you confirm removing{" "}
                {selectedAgentIds.length} agents from {selectedTeamValue.label}.
                Affected agents are:{" "}
                {selectedAgentIds
                  .map(
                    (aid) =>
                      agents.find((a) => a.id === aid)?.getFullName() || ""
                  )
                  .join(", ")}
                .
              </Text>
            ) : null}
          </VStack>
        </ModalBody>
        <ModalFooter>
          {selectedTeamValue ? (
            <Button
              onClick={updateTeam}
              colorScheme="red"
              isDisabled={isLoading}
            >
              Remove
            </Button>
          ) : null}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default RemoveFromTeamModal;
