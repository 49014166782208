import { Auth0ContextInterface } from "@auth0/auth0-react/src/auth0-context";
import InventoryVehicleDomain, {
  AvailabilityStatus,
  VehicleCondition,
} from "entities/domain/inventory-vehicle";
import { InventoryVehicleDTO } from "entities/dto/InventoryVehicleDTO";
import { inventoryVehicleTransformFromDtoToDomain } from "entities/transformers/inventory-vehicle-transformer";
import { numberOfInventoryVehiclesPerLoad } from "util/constants";
import { RequestType } from "./request-type";
import { request } from "../util/methods";

export interface InventoryVehicleFilterOptions {
  allMakes: string[];
  allModels: string[];
  allMakeModels: Array<{
    make: string;
    models: string[];
  }>;
  minYear: number;
  maxYear: number;
  minPrice: number;
  maxPrice: number;
  allConditions: VehicleCondition[];
  allAvailabilities: AvailabilityStatus[];
}

class InventoryService {
  public static async getVehicles(
    { getAccessTokenSilently }: Auth0ContextInterface,
    merchantId: number,
    offset: number,
    direction: string,
    model: string | null,
    make: string | null,
    yearMin: number | null,
    yearMax: number | null,
    grossPriceMin: number | null,
    grossPriceMax: number | null,
    condition: VehicleCondition | null,
    availabilityStatus: AvailabilityStatus | null
  ): Promise<InventoryVehicleDomain[]> {
    const accessToken = await getAccessTokenSilently();

    const queryParams = new URLSearchParams();

    queryParams.append("offset", `${offset}`);
    queryParams.append("maxPageSize", `${numberOfInventoryVehiclesPerLoad}`);
    queryParams.append("sorting_direction", direction);

    if (model) queryParams.append("model", model);
    if (make) queryParams.append("make", make);
    if (yearMin) queryParams.append("year_min", `${yearMin}`);
    if (yearMax) queryParams.append("year_max", `${yearMax}`);
    if (grossPriceMin) queryParams.append("price_min", `${grossPriceMin}`);
    if (grossPriceMax) queryParams.append("price_max", `${grossPriceMax}`);
    if (condition) queryParams.append("condition", condition);
    if (availabilityStatus)
      queryParams.append("availability", availabilityStatus);

    const queryString = queryParams.toString();

    const vehiclesResponse = (
      await request<InventoryVehicleDTO[]>(
        RequestType.GET,
        accessToken,
        `/merchants/${merchantId}/inventory-vehicles?${queryString}`
      )
    ).data;

    return vehiclesResponse.map((v) =>
      inventoryVehicleTransformFromDtoToDomain(v)
    );
  }

  public static async getVehicleFilterOptions(
    { getAccessTokenSilently }: Auth0ContextInterface,
    merchantId: number,
    model: string | null,
    make: string | null,
    yearMin: number | null,
    yearMax: number | null,
    grossPriceMin: number | null,
    grossPriceMax: number | null,
    condition: VehicleCondition | null,
    availabilityStatus: AvailabilityStatus | null
  ): Promise<InventoryVehicleFilterOptions> {
    const accessToken = await getAccessTokenSilently();

    const queryParams = new URLSearchParams();

    if (model) queryParams.append("model", model);
    if (make) queryParams.append("make", make);
    if (yearMin) queryParams.append("year_min", `${yearMin}`);
    if (yearMax) queryParams.append("year_max", `${yearMax}`);
    if (grossPriceMin) queryParams.append("price_min", `${grossPriceMin}`);
    if (grossPriceMax) queryParams.append("price_max", `${grossPriceMax}`);
    if (condition) queryParams.append("condition", condition);
    if (availabilityStatus)
      queryParams.append("availability", availabilityStatus);

    const queryString = queryParams.toString();

    const filterOptions = (
      await request<{
        all_makes_models: Array<{
          make: string;
          models: string[];
        }>;
        min_year: number;
        max_year: number;
        min_price: number;
        max_price: number;
        all_conditions: string[];
        all_availabilities?: string[];
      }>(
        RequestType.GET,
        accessToken,
        `/merchants/${merchantId}/inventory-vehicles/filters?${queryString}`
      )
    ).data;

    return {
      allMakes: filterOptions.all_makes_models.map(
        (makeModel) => makeModel.make
      ),
      allModels: [
        ...new Set(
          filterOptions.all_makes_models.reduce((acc, makeModel) => {
            acc.push(...makeModel.models);
            return acc;
          }, [] as string[])
        ),
      ],
      allMakeModels: filterOptions.all_makes_models,
      minYear: filterOptions.min_year,
      maxYear: filterOptions.max_year,
      minPrice: filterOptions.min_price,
      maxPrice: filterOptions.max_price,
      allConditions: filterOptions.all_conditions as VehicleCondition[],
      allAvailabilities: (filterOptions.all_availabilities ||
        []) as AvailabilityStatus[],
    };
  }
}

export default InventoryService;
