import {
  Box,
  Text,
  Button as ChakraButton,
  VStack,
  useBreakpointValue,
  Icon,
  Link,
} from "@chakra-ui/react";
import { ReactComponent as DownloadIcon } from "assets/icons/download.svg";
import { ReactComponent as PaymentIcon } from "assets/icons/credit-card-ds.svg";
import getSymbolFromCurrency from "currency-symbol-map";
import {
  BodySize,
  BodyText,
  Color,
  FSize,
  FWeight,
} from "theme/old-design-system/styled-components";
import usePublicPaymentsStore from "hooks/use-public-payments-store";
import React from "react";
import { RiBankLine } from "react-icons/ri";
import { PaymentProvider } from "..";
import RequestLineItem from "./requestLineItem";

interface PaymentRequestProps {
  onClickBank: () => void;
  merchantName: string | undefined;
  amount: number | undefined;
  currency: string | undefined;
  invoiceUrl: string | undefined;
  description: string | undefined;
  hasInvoice?: boolean;
  displayBankButton: boolean;
  displayCardButton: boolean;
  paymentId: string;
  setIsRedirecting: (val: boolean) => void;
}

const PaymentRequestArea = ({
  onClickBank,
  merchantName,
  amount,
  currency,
  invoiceUrl,
  description,
  hasInvoice,
  displayBankButton,
  displayCardButton,
  paymentId,
  setIsRedirecting,
}: PaymentRequestProps) => {
  const isBaseSize = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );

  const { fetchPublicPaymentUrl } = usePublicPaymentsStore();

  const onCardPayment = async (payment_id: string) => {
    fetchPublicPaymentUrl({
      payment_id,
      provider: PaymentProvider.STRIPE,
    }).then((res) => {
      setIsRedirecting(true);
      if (res?.url) window.location.assign(res?.url);
    });
  };
  return (
    <>
      <Box mx={5}>
        <Text
          fontWeight={700}
          fontSize="18px"
          align="center"
          mt="18px"
          mb="11px"
        >
          {merchantName} sent you a payment request
        </Text>
      </Box>

      <Text
        bgGradient="linear(to-t, #3559FF 10.6%, #36B2FA 88.1%)"
        bgClip="text"
        fontSize="18px"
        fontWeight="extrabold"
        align="center"
        mb="20px"
      >
        {getSymbolFromCurrency(currency!)}
        {amount?.toFixed(2)}
      </Text>
      <Box
        borderY={`1px solid ${Color.LIGHT_GREY.value}`}
        w={isBaseSize ? "75vw" : "25vw"}
        mx="auto"
        pb="11px"
      >
        <RequestLineItem
          flexDirection={
            isBaseSize && description && description?.length > 30
              ? "column"
              : "row"
          }
          lineItem="Description"
          lineItemTwo={description}
        />
      </Box>

      <Box w={isBaseSize ? "75vw" : "25vw"} mx="auto">
        <Link
          href={invoiceUrl}
          download
          display={hasInvoice ? "flex" : "none"}
          justifyContent="flex-end"
          pt="1rem"
          __css={{
            "@media (max-width: 760px)": {
              pl: "50px",
            },
          }}
        >
          <BodyText size={BodySize.THREE} colorName={Color.SMOKY_GREY}>
            Download invoice
          </BodyText>
          <Icon as={DownloadIcon} ml="12px" />
        </Link>
      </Box>

      <Text fontWeight={700} fontSize="16px" align="center" mt="24px" mb="11px">
        Payment Methods
      </Text>
      <VStack mx={10}>
        <VStack display={displayBankButton ? "" : "none"}>
          <Text
            align="center"
            color={`${Color.SMOKY_GREY.value}`}
            fontSize={`${FSize.SEVEN.value}`}
          >
            Recommended
          </Text>
          <ChakraButton
            w="289px"
            color="#fff"
            bgGradient="linear(306.44deg, #3559FF 10.6%, #36B2FA 88.1%)"
            borderRadius="75px"
            _hover={{ opacity: 0.5 }}
            _focus={{ outline: "none" }}
            onClick={onClickBank}
          >
            <Icon
              as={RiBankLine}
              mr="10px"
              cursor="pointer"
              width="20px"
              height="20px"
            />
            <BodyText
              size={BodySize.THREE}
              colorName={Color.WHITE}
              fweight={FWeight.ONE}
            >
              Pay with Bank
            </BodyText>
          </ChakraButton>
        </VStack>
        <ChakraButton
          display={displayCardButton ? "flex" : "none"}
          w="289px"
          color="#fff"
          bgColor={displayBankButton ? "#BDC1C4" : ""}
          bgGradient={
            !displayBankButton
              ? "linear(306.44deg, #3559FF 10.6%, #36B2FA 88.1%)"
              : ""
          }
          borderRadius="75px"
          _hover={{ opacity: 0.5 }}
          _focus={{ outline: "none" }}
          onClick={() => onCardPayment(paymentId)}
        >
          <Icon
            __css={{
              g: {
                opacity: 1,
              },
              path: {
                fill: "white",
              },
            }}
            as={PaymentIcon}
            mr={10}
          />
          <BodyText
            size={BodySize.THREE}
            colorName={Color.WHITE}
            fweight={FWeight.ONE}
          >
            Pay with Card
          </BodyText>
        </ChakraButton>
      </VStack>
    </>
  );
};

export default PaymentRequestArea;
