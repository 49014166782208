import React from "react";
import {
  Box,
  Flex,
  Heading,
  Icon,
  ScaleFade,
  Stack,
  Text,
} from "@chakra-ui/react";
import { FaRegCheckCircle } from "react-icons/fa";
import { useLocation } from "react-router-dom";
import {
  ContactFormConfigProps,
  parseContactFormConfig,
} from "./ContactFormConfig";

export default function ContactFormSuccess() {
  const query_params = new URLSearchParams(useLocation().search);
  let formConfig;
  try {
    [formConfig] =
      parseContactFormConfig(query_params) ?? ({} as ContactFormConfigProps);
  } catch (e) {
    formConfig = {} as ContactFormConfigProps;
  }

  return (
    <Box w="100vw">
      <ScaleFade initialScale={0.3} in={true}>
        <Flex
          flexDir={{ base: "column", sm: "row" }}
          w="100%"
          maxW="1000px"
          mx="auto"
          minH={formConfig?.compactVersion ? "250px" : "400px"}
          p={{ base: "1", md: "40" }}
          color={formConfig?.textColor || "#2b2e54"}
          bg={formConfig?.bgColor || "gray.50"}
          borderRadius={8}
          justify="center"
          align="center"
        >
          <Icon as={FaRegCheckCircle} fontSize="60" color="green.700" />
          <Box ml={{ base: "1", sm: "8" }} mt={{ base: "8", sm: "0" }}>
            <Heading size="md">Thank you for your enquiry.</Heading>
            <Text>
              A member of our team will be in touch with you as soon as
              possible!
            </Text>
          </Box>
        </Flex>
      </ScaleFade>
    </Box>
  );
}
