import { Auth0ContextInterface } from "@auth0/auth0-react";
import { putRequest, request } from "util/methods";
import { TagDTO } from "entities/dto/TagDTO";
import { tagTransformFromDtoToDomain } from "entities/transformers/tags-transformer";
import TagsDomain from "entities/domain/tags/tags-domain";
import { RequestType } from "./request-type";

export default class TagsService {
  public static async getTags(
    { getAccessTokenSilently }: Auth0ContextInterface,
    groupId: string
  ): Promise<TagsDomain[]> {
    const accessToken = await getAccessTokenSilently();

    const tagsResponse = await request<TagDTO[]>(
      RequestType.GET,
      accessToken,
      `/merchant-groups/${groupId}/tags`
    );

    const tags = tagsResponse.data;

    return tags.map(tagTransformFromDtoToDomain);
  }

  public static async addTags(
    { getAccessTokenSilently }: Auth0ContextInterface,
    groupId: string,
    tags: string[]
  ): Promise<TagsDomain[]> {
    const accessToken = await getAccessTokenSilently();

    const tagResponse = await putRequest(
      RequestType.PUT,
      accessToken,
      `/merchant-groups/${groupId}/tags`,
      {
        tags,
      }
    );

    return tagResponse.data.map(tagTransformFromDtoToDomain);
  }
}
