import { Flex, Icon, Text, useColorMode } from "@chakra-ui/react";
import React, { ReactNode, useEffect, useRef } from "react";
import { ReactComponent as PhoneRotatePortraitIcon } from "assets/icons/phone-rotate-portrait.svg";
import { isMobile, isMobileApp } from "util/methods";

const supportsOrientationApi = !!window.screen?.orientation?.type;

const isLandscapeOrientation = () => {
  if (supportsOrientationApi) {
    return window.screen.orientation.type.includes("landscape");
  }

  return window.innerHeight < window.innerWidth;
};

const ScreenSettingsProvider = ({ children }: { children: ReactNode }) => {
  const { colorMode } = useColorMode();

  const overlayOrientation = useRef<HTMLImageElement>(null);

  const orientationChangeListener = () => {
    if (!isMobile() || !overlayOrientation.current) {
      return;
    }

    if (isLandscapeOrientation()) {
      overlayOrientation.current.style.visibility = "visible";
      overlayOrientation.current.style.opacity = "1";
    } else {
      overlayOrientation.current.style.visibility = "hidden";
      overlayOrientation.current.style.opacity = "0";
    }
  };

  useEffect(() => {
    if (!isMobile()) {
      return;
    }

    if (isMobileApp() && window.natively) {
      // window.natively.setAppPullToRefresh(true);
      window.natively.setAppOrientation("PORTRAIT");

      return;
    }

    if (supportsOrientationApi) {
      window.screen.orientation.addEventListener(
        "change",
        orientationChangeListener
      );
    } else {
      window.addEventListener("resize", orientationChangeListener);
    }

    orientationChangeListener();

    return () => {
      if (supportsOrientationApi) {
        window.screen.orientation.removeEventListener(
          "change",
          orientationChangeListener
        );
      } else {
        window.removeEventListener("resize", orientationChangeListener);
      }
    };
  }, []);

  useEffect(() => {
    if (isMobile()) {
      document
        .getElementById("viewport")!
        .setAttribute(
          "content",
          "width=device-width, initial-scale=1, maximum-scale=1, user-scalable=0"
        );
    } else {
      document
        .getElementById("viewport")!
        .setAttribute("content", "width=device-width, initial-scale=1");
    }
  }, []);

  return (
    <>
      {children}
      <Flex
        ref={overlayOrientation}
        display={!isMobile() ? "none" : "flex"}
        position="absolute"
        overflow="hidden"
        visibility="hidden"
        opacity="0"
        width="100%"
        height="100%"
        zIndex="100"
        left={0}
        top={0}
        bgColor={colorMode === "dark" ? "gray.800" : "white"}
        direction="column"
        alignItems="center"
        justifyContent="center"
        gridGap={4}
      >
        <Text fontWeight="bold">Please, use Portrait Mode</Text>
        <Icon
          as={PhoneRotatePortraitIcon}
          fill={colorMode === "dark" ? "white" : "gray.900"}
          boxSize="6rem"
        />
      </Flex>
    </>
  );
};

export default ScreenSettingsProvider;
