import { Auth0ContextInterface } from "@auth0/auth0-react/src/auth0-context";
import AgentDomain from "entities/domain/agents/agent-domain";
import {
  agentArrayTransformFromDtoToDomain,
  agentTransformFromDtoToDomain,
} from "entities/transformers/agent-transformer";
import { AgentDTO, StylePreferencesDTO } from "entities/dto/AgentDTO";
import NewAgentDomain from "entities/domain/agents/new-agent-domain";
import { newAgentTransformFromDtoToDomain } from "entities/transformers/new-agent-transformer";
import ReportPerAgentDomain from "entities/domain/report-per-agent";
import { ReportPerAgentDTO } from "entities/dto/ReportPerAgentDTO";
import { reportPerAgentTransformFromDtoToDomain } from "entities/transformers/report-per-agent-transformer";
import { NewAgentDTO } from "entities/dto/NewAgentDTO";
import { TeamDTO } from "entities/dto/TeamDTO";
import { teamTransformFromDtoToDomain } from "entities/transformers/team-transformer";
import TeamDomain from "entities/domain/team";
import { deleteRequest, mutationRequest, request } from "../util/methods";
import { RequestType } from "./request-type";

export interface EditAgentPayload {
  id: number;
  name: string;
  surname: string;
  email: string;
  notification_handle: string;
  notification_channel: string;
  notification_preference: string;
  notification_timezone: string;
  role_id: number;
  active: boolean;
  rotation_enabled?: boolean;
  profile_picture_file?: File | null;
  profile_picture_url: string;
  player_ids: string[] | null;
  style_preferences: StylePreferencesDTO | null;
}

export interface CreateTeamPayload {
  name: string;
  purpose: string;
  merchant_id: number;
  agent_ids: number[];
}

export interface UpdateTeamPayload {
  merchant_id: number;
  id: string;
  agent_ids?: number[];
  name?: string;
  purpose?: string;
}

class AgentsService {
  public static async getCurrentAgent({
    getAccessTokenSilently,
  }: Auth0ContextInterface): Promise<NewAgentDomain> {
    const accessToken = await getAccessTokenSilently();

    const data = await request(RequestType.GET, accessToken, "/agents/me");

    const agent: NewAgentDTO = data.data;

    return newAgentTransformFromDtoToDomain(agent);
  }

  public static async getAgents(
    { getAccessTokenSilently }: Auth0ContextInterface,
    merchantId: number
  ): Promise<AgentDomain[]> {
    const accessToken = await getAccessTokenSilently();

    const data = await request(
      RequestType.GET,
      accessToken,
      `/merchants/${merchantId}/agents`
    );

    const agents: AgentDTO[] = data.data;

    return agentArrayTransformFromDtoToDomain(agents);
  }

  public static async getTeams(
    { getAccessTokenSilently }: Auth0ContextInterface,
    merchantId: number
  ): Promise<TeamDomain[]> {
    const accessToken = await getAccessTokenSilently();

    const data = (
      await request<TeamDTO[]>(
        RequestType.GET,
        accessToken,
        `/merchants/${merchantId}/teams`
      )
    ).data;

    return data.map(teamTransformFromDtoToDomain);
  }

  public static async createTeam(
    { getAccessTokenSilently }: Auth0ContextInterface,
    createTeam: CreateTeamPayload
  ): Promise<TeamDomain> {
    const accessToken = await getAccessTokenSilently();

    const { name, merchant_id, agent_ids, purpose } = createTeam;

    const team = (
      await mutationRequest<TeamDTO>(
        RequestType.POST,
        accessToken,
        `/merchants/${merchant_id}/teams`,
        {
          name,
          agent_ids,
          purpose,
        }
      )
    ).data;

    return teamTransformFromDtoToDomain(team);
  }

  public static async updateTeam(
    { getAccessTokenSilently }: Auth0ContextInterface,
    updateTeam: UpdateTeamPayload
  ): Promise<TeamDomain> {
    const accessToken = await getAccessTokenSilently();

    const { id, agent_ids, merchant_id, name, purpose } = updateTeam;

    const payload: { [key: string]: string | number | number[] } = {};

    if (name) {
      payload.name = name;
    }

    if (purpose) {
      payload.purpose = purpose;
    }

    if (agent_ids) {
      payload.agent_ids = agent_ids;
    }

    const team = (
      await mutationRequest<TeamDTO>(
        RequestType.PATCH,
        accessToken,
        `/merchants/${merchant_id}/teams/${id}`,
        payload
      )
    ).data;

    return teamTransformFromDtoToDomain(team);
  }

  public static async deleteTeam(
    { getAccessTokenSilently }: Auth0ContextInterface,
    merchantId: number,
    teamId: string
  ): Promise<string> {
    const accessToken = await getAccessTokenSilently();

    await deleteRequest(
      RequestType.DELETE,
      accessToken,
      `/merchants/${merchantId}/teams/${teamId}`
    );

    return teamId;
  }

  public static async getAnalyticsReports(
    { getAccessTokenSilently }: Auth0ContextInterface,
    merchantId: number,
    dateFrom: string,
    dateTo: string
  ): Promise<ReportPerAgentDomain[]> {
    const accessToken = await getAccessTokenSilently();

    const queryParams = new URLSearchParams();

    queryParams.append("date_from", dateFrom);
    queryParams.append("date_to", dateTo);

    const queryString = queryParams.toString();

    const data = (
      await request<ReportPerAgentDTO[]>(
        RequestType.GET,
        accessToken,
        `/merchants/${merchantId}/analytics/agents?${queryString}`
      )
    ).data;

    return data.map(reportPerAgentTransformFromDtoToDomain);
  }

  public static async updateAgent(
    { getAccessTokenSilently }: Auth0ContextInterface,
    payload: EditAgentPayload,
    merchantId: number
  ): Promise<AgentDomain> {
    const accessToken = await getAccessTokenSilently();

    const formData = new FormData();

    formData.append("name", payload.name);
    formData.append("surname", payload.surname);
    formData.append("email", payload.email);
    formData.append("notification_handle", payload.notification_handle);
    formData.append("notification_channel", payload.notification_channel);
    formData.append("notification_preference", payload.notification_preference);
    formData.append("notification_timezone", payload.notification_timezone);
    formData.append("role_id", payload.role_id.toString());
    formData.append("active", payload.active.toString());
    if (payload.rotation_enabled !== undefined) {
      formData.append("rotation_enabled", payload.rotation_enabled.toString());
    }
    if (payload.style_preferences) {
      formData.append(
        "style_preferences",
        JSON.stringify(payload.style_preferences)
      );
    }
    if (payload.player_ids) {
      formData.append("player_ids", payload.player_ids.join(","));
    }
    if (payload.profile_picture_file)
      formData.append("profile_picture", payload.profile_picture_file);
    if (!payload.profile_picture_file)
      formData.append("profile_picture_url", payload.profile_picture_url);

    const agentResponse = await mutationRequest<AgentDTO>(
      RequestType.PUT,
      accessToken,
      `/merchants/${merchantId}/agents/${encodeURIComponent(payload.id)}`,
      formData,
      "multipart/form-data"
    );

    const agent: AgentDTO = agentResponse.data;

    return agentTransformFromDtoToDomain(agent);
  }

  public static async getAgentById(
    { getAccessTokenSilently }: Auth0ContextInterface,
    agentPayload: {
      agentId: string;
    },
    merchantId: number
  ): Promise<AgentDomain> {
    const accessToken = await getAccessTokenSilently();
    const { agentId } = agentPayload;

    const data = await request(
      RequestType.GET,
      accessToken,
      `/merchants/${merchantId}/agents/${agentId}`
    );

    const agent: AgentDTO = data.data;

    return agentTransformFromDtoToDomain(agent);
  }
}

export default AgentsService;
