import {
  Button,
  Icon,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Portal,
  useColorMode,
} from "@chakra-ui/react";
import React, { memo } from "react";
import { ReactComponent as TemplatesButtonIcon } from "assets/icons/templates-2.svg";
import { useAppSelector } from "redux/hooks";
import TemplatesPopover from "../templates-popover";

interface TemplatesShortcutProps {
  showTemplatesPopover: boolean;
  isDisabled: boolean;
  setShowTemplatesPopover: (show: boolean) => void;
}

const TemplatesShortcut = ({
  showTemplatesPopover,
  setShowTemplatesPopover,
  isDisabled,
}: TemplatesShortcutProps) => {
  const { colorMode } = useColorMode();
  const { colorScheme } = useAppSelector((state) => state.theme);

  return (
    <Popover
      closeOnBlur={true}
      placement="top"
      flip={false}
      isOpen={showTemplatesPopover}
      onClose={() => setShowTemplatesPopover(false)}
    >
      <Portal>
        <PopoverContent>
          <TemplatesPopover
            id="templates-popover"
            onClose={() => setShowTemplatesPopover(false)}
          />
        </PopoverContent>
      </Portal>
      <PopoverTrigger>
        <Button
          variant="ghost"
          size="xs"
          colorScheme={colorScheme}
          p={0}
          isDisabled={isDisabled}
          onClick={() => {
            setShowTemplatesPopover(!showTemplatesPopover);
          }}
        >
          <Icon
            as={TemplatesButtonIcon}
            width="1rem"
            height="1rem"
            __css={{
              path: {
                fill:
                  colorMode === "light"
                    ? `${colorScheme}.500`
                    : `${colorScheme}.200`,
              },
            }}
          />
        </Button>
      </PopoverTrigger>
    </Popover>
  );
};

export default memo(TemplatesShortcut);
