import { useAuth0 } from "@auth0/auth0-react";
import { Text } from "@chakra-ui/react";
import { PreviewComponentProps } from "components/shared/QueryBuilder/QueryCondition";
import MerchantBranchDomain from "entities/domain/branch";
import React, { useEffect, useState } from "react";
import { useAppSelector } from "redux/hooks";
import MerchantService from "services/merchant";

const BranchFieldPreview = ({ value }: PreviewComponentProps) => {
  const auth0Context = useAuth0();
  const { merchant } = useAppSelector((state) => state.merchant);
  const [branches, setBranches] = useState<MerchantBranchDomain[]>([]);
  const [selectedBranch, setSelectedBranch] =
    useState<MerchantBranchDomain | null>(null);

  useEffect(() => {
    MerchantService.getMerchantBranches(auth0Context, merchant.id).then((b) => {
      setBranches(b);
    });
  }, []);

  useEffect(() => {
    if (branches.length === 0) {
      return;
    }

    setSelectedBranch(branches.find((b) => b.id === value) || null);
  }, [branches]);

  return (
    <Text fontWeight="bold">
      {selectedBranch ? selectedBranch.description : value}
    </Text>
  );
};

export default BranchFieldPreview;
