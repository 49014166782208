import {
  Box,
  Button,
  Flex,
  HStack,
  Icon,
  useColorMode,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import Tag from "components/tags";
import { ReactSVG } from "react-svg";
import useContactsStore from "hooks/use-contacts-store";
import TagsDomain from "entities/domain/tags/tags-domain";
import { matchTagColorToMerchantTagColor } from "util/constants";
import { ReactComponent as TagsIcon } from "assets/icons/tags-regular.svg";
import { useAppSelector } from "redux/hooks";
import ContactCardAccordionContent from "./ContactCardAccordionContent";

interface CustomerTagsProps {
  contactTagIds: string[];
  contactId: number;
  onCreateNewTag: () => void;
  setTagIdToBeDeleted: (tag: string) => void;
  isExpanded: boolean;
  isArchived: boolean;
}

const ContactTagsArea = ({
  isArchived,
  contactTagIds,
  contactId,
  onCreateNewTag,
  setTagIdToBeDeleted,
  isExpanded,
}: CustomerTagsProps) => {
  const { colorMode } = useColorMode();
  const { colorScheme } = useAppSelector((state) => state.theme);
  const { deleteContactTag } = useContactsStore();
  const [tagsToDisplay, setTagsToDisplay] = useState<Array<TagsDomain>>([]);
  const { tags } = useAppSelector((state) => state.tags);

  useEffect(() => {
    setTagsToDisplay(tags.filter((tag) => contactTagIds.includes(tag.id)));
  }, [contactTagIds]);

  const deleteTag = (tagToDelete: TagsDomain) =>
    deleteContactTag(tagToDelete, contactId).then(() => {
      setTagsToDisplay(
        tagsToDisplay.filter((tag) => tag.id !== tagToDelete.id)
      );
      setTagIdToBeDeleted(tagToDelete.id);
    });

  return tagsToDisplay.length === 0 && isArchived ? null : (
    <Box borderBottom="1px" borderColor="gray.50" pb={2}>
      <ContactCardAccordionContent
        isExpanded={isExpanded}
        sectionHeader={
          <Icon
            as={TagsIcon}
            boxSize={6}
            fill={
              colorMode === "dark" ? `${colorScheme}.200` : `${colorScheme}.900`
            }
            _hover={{ fill: colorMode === "dark" ? "gray.600" : "gray.300" }}
          />
        }
        sectionContent={
          <>
            {tagsToDisplay.length === 0 ? (
              <Flex justifyContent="center">
                <Button
                  colorScheme={colorScheme}
                  variant="ghost"
                  color="gray.400"
                  size="sm"
                  onClick={onCreateNewTag}
                >
                  {" "}
                  Click here to add tags
                </Button>
              </Flex>
            ) : (
              <HStack
                mt={3.5}
                mx={1.5}
                spacing={2}
                wrap="wrap"
                justifyContent="center"
              >
                {tagsToDisplay.map((tag) => (
                  <Box key={tag.tag}>
                    <Tag
                      label={tag.tag}
                      color={matchTagColorToMerchantTagColor(tags, tag)}
                      my={1}
                      onCloseTag={isArchived ? undefined : () => deleteTag(tag)}
                    />
                  </Box>
                ))}
                {isArchived ? null : (
                  <Icon
                    as={ReactSVG}
                    src="/gradient-plus.svg"
                    mx={5}
                    cursor="pointer"
                    __css={{
                      height: "1rem",
                      width: "1rem",
                    }}
                    _hover={{ opacity: 0.5 }}
                    onClick={onCreateNewTag}
                  />
                )}
              </HStack>
            )}
          </>
        }
      />
    </Box>
  );
};

export default ContactTagsArea;
