import { useAuth0 } from "@auth0/auth0-react";
import {
  Box,
  Icon,
  useColorMode,
  useDisclosure,
  useBreakpointValue,
  Flex,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import ContactDomain from "entities/domain/customers/contact-domain";
import { ReactComponent as VehicleIcon } from "assets/icons/vehicle-car-svgrepo-com.svg";
import ContactsService from "services/contacts";
import VehicleDomain from "entities/domain/vehicle";
import { useAppSelector } from "redux/hooks";
import ContactCardAccordionContent from "./ContactCardAccordionContent";
import Gallery from "../Gallery";
import CarThumbnailContent from "./CarThumbnailContent";
import VehicleInfo from "./VehicleInfo";

interface VehicleInfoProps {
  contactToDisplay: ContactDomain;
  isExpanded: boolean;
  onFirstLoad: (vehicles: VehicleDomain[]) => void;
}

const VehicleInfoArea = ({
  contactToDisplay,
  isExpanded,
  onFirstLoad,
}: VehicleInfoProps) => {
  const isBaseSize = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );
  const { colorMode } = useColorMode();
  const { merchant } = useAppSelector((state) => state.merchant);
  const [startIndex, setStartIndex] = useState<number>(0);
  const { colorScheme } = useAppSelector((state) => state.theme);
  const auth0Context = useAuth0();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [vehicles, setVehicles] = useState<VehicleDomain[]>([]);

  const fetchVehicles = async () => {
    const newVehicles = await ContactsService.getVehicles(
      auth0Context,
      contactToDisplay.id!,
      merchant.groupId
    );

    setVehicles(newVehicles);
    onFirstLoad(newVehicles);
  };

  useEffect(() => {
    fetchVehicles();
  }, []);

  return (
    <Box borderBottom="1px" borderColor="gray.50" pb={2}>
      <ContactCardAccordionContent
        isExpanded={isExpanded}
        sectionHeader={
          <Icon
            as={VehicleIcon}
            boxSize={7}
            __css={{
              path: {
                fill:
                  colorMode === "dark"
                    ? `${colorScheme}.200`
                    : `${colorScheme}.900`,
              },
            }}
            _hover={{
              fill: colorMode === "dark" ? "gray.600" : "gray.300",
            }}
          />
        }
        sectionContent={
          <>
            <Flex
              justifyContent="start"
              alignItems="start"
              gridGap={3}
              flexWrap="wrap"
            >
              {vehicles.map((vehicle, index) => (
                <Box
                  borderWidth="1px"
                  borderRadius="full"
                  borderStyle="solid"
                  borderColor={colorMode === "dark" ? "gray.200" : "gray.50"}
                  px={2}
                  py={1}
                  key={vehicle.id}
                  _hover={{
                    cursor: "pointer",
                    bgColor: colorMode === "dark" ? "gray.900" : "gray.50",
                  }}
                  onClick={() => {
                    onOpen();
                    setStartIndex(index);
                  }}
                >
                  <CarThumbnailContent vehicle={vehicle} />
                </Box>
              ))}
            </Flex>
          </>
        }
      />
      <Gallery
        isOpen={isOpen}
        onClose={onClose}
        navigationOrientation={isBaseSize ? "horizontal" : "vertical"}
        startIndex={startIndex}
        setStartIndex={(index) => setStartIndex(index)}
        media={vehicles.map((vehicle) => ({
          id: vehicle.id,
          element: <VehicleInfo vehicle={vehicle} />,
          smallElement: (
            <Box
              borderWidth="1px"
              borderRadius="2xl"
              borderStyle="solid"
              borderColor={colorMode === "dark" ? "gray.700" : "gray.50"}
              p={4}
              height="6rem"
              width="6rem"
              color="white"
              key={vehicle.id}
              bgColor={
                startIndex === vehicles.indexOf(vehicle)
                  ? "gray.700"
                  : "transparent"
              }
              _hover={{
                cursor: "pointer",
                bgColor: colorMode === "dark" ? "gray.700" : "gray.800",
              }}
            >
              <CarThumbnailContent
                vehicle={vehicle}
                colorMode="dark"
                showOpenIcon={false}
                orientation="vertical"
              />
            </Box>
          ),
        }))}
      />
    </Box>
  );
};

export default VehicleInfoArea;
