import { CloseIcon } from "@chakra-ui/icons";
import {
  Flex,
  Heading,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Spinner,
  useBreakpointValue,
  Button as ChakraButton,
  useColorMode,
} from "@chakra-ui/react";
import { OptionTypes } from "components/shared/filter";
import { Color } from "theme/old-design-system/styled-components";
import { useAppSelector } from "redux/hooks";
import React, { useEffect, useRef, useState } from "react";
import { tagToTagOption } from "entities/transformers/tags-transformer";
import Tag from "components/tags";
import useMerchantStore from "hooks/use-merchant-store";
import Select, {
  IndicatorSeparatorProps,
  MultiValue,
  OptionProps,
  components,
} from "react-select";
import { getReactSelectStyles } from "util/methods";

interface UpdateTagsInterface {
  isOpen: boolean;
  onClose: () => void;
  tagIds: string[];
  onSubmit: (selectedTagIds: string[]) => void;
  isRemoval?: boolean;
}

const indicatorSeparatorStyle = {
  alignSelf: "stretch",
  marginBottom: 8,
  marginTop: 8,
  width: 1,
};

const IndicatorSeparator = ({
  innerProps,
}: IndicatorSeparatorProps<OptionTypes, true>) => {
  return <span style={indicatorSeparatorStyle} {...innerProps} />;
};

export const TagSelectOption: React.FC<OptionProps<OptionTypes, false>> = (
  props
) => {
  return (
    <components.Option {...props}>
      <Tag label={props.label} color={props.data.color} my={1} />
    </components.Option>
  );
};

export const TagMultiSelectOption: React.FC<OptionProps<OptionTypes, true>> = (
  props
) => {
  return (
    <components.Option {...props}>
      <Tag label={props.label} color={props.data.color} my={1} />
    </components.Option>
  );
};

const sortTagOptions = (a: OptionTypes, b: OptionTypes) => {
  return a.label.toLowerCase().localeCompare(b.label.toLowerCase());
};

const UpdateTags = ({
  isOpen,
  onClose,
  tagIds,
  onSubmit,
  isRemoval = false,
}: UpdateTagsInterface) => {
  const isBaseSize = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );
  const { colorScheme } = useAppSelector((state) => state.theme);
  const { colorMode } = useColorMode();
  const { tags } = useAppSelector((state) => state.tags);
  const { addMerchantTags } = useMerchantStore();

  const [newTag, setNewTag] = useState<OptionTypes | undefined>(undefined);
  const [availableTagOptions, setAvailableTagOptions] = useState<OptionTypes[]>(
    []
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selectedTagOptions, setSelectedTagOptions] = useState<OptionTypes[]>(
    []
  );

  const reset = () => {
    setIsLoading(false);
    setNewTag(undefined);
    setSelectedTagOptions(
      tags.filter((t) => tagIds.includes(t.id)).map(tagToTagOption)
    );
    setAvailableTagOptions(tags.map(tagToTagOption).sort(sortTagOptions));
  };

  const handleNewTagSubmit = (tagToAdd: string) => {
    addMerchantTags([tagToAdd]).then((res) => {
      const newTagOptionType = tagToTagOption(res![0]);
      setSelectedTagOptions([...selectedTagOptions, newTagOptionType]);
      setNewTag(undefined);
    });
  };

  const handleSelectChange = (selectedOptions: MultiValue<OptionTypes>) => {
    if (
      newTag &&
      selectedOptions.filter((o) => o.value === newTag.value).length > 0
    ) {
      handleNewTagSubmit(newTag.value);
    } else {
      setSelectedTagOptions(selectedOptions as OptionTypes[]);
    }
  };

  const handleInputChange = (tagValue: string) => {
    if (isRemoval) {
      return;
    }

    if (tagValue.length > 0) {
      setNewTag({
        label: `Add ${tagValue}`,
        value: tagValue.replace("  ", " ").toLocaleLowerCase(),
        color: "gray",
        icon: "plus",
      } as OptionTypes);
    } else {
      setNewTag(undefined);
    }
  };

  useEffect(() => {
    if (isOpen) {
      reset();
    }
  }, [isOpen]);

  useEffect(() => {
    if (newTag && tags.filter((t) => t.tag === newTag.value).length === 0) {
      setAvailableTagOptions(
        [...tags.map(tagToTagOption), newTag].sort(sortTagOptions)
      );
    } else {
      setAvailableTagOptions(tags.map(tagToTagOption).sort(sortTagOptions));
    }
  }, [newTag, tags]);

  const btnRef = useRef<HTMLButtonElement>({} as HTMLButtonElement);

  return (
    <Modal isOpen={isOpen} onClose={onClose} size={isBaseSize ? "full" : "xl"}>
      <ModalOverlay />
      <ModalContent
        containerProps={{
          zIndex: 99999,
        }}
      >
        <Flex p="16px 24px" justify="space-between">
          <Heading fontSize="1.25rem">Update Tags</Heading>
          <CloseIcon
            onClick={() => onClose()}
            fontSize="12px"
            cursor="pointer"
          />
        </Flex>

        <ModalBody>
          <Select
            isMulti
            isClearable
            isSearchable
            components={{ IndicatorSeparator, Option: TagMultiSelectOption }}
            options={availableTagOptions}
            value={selectedTagOptions}
            onChange={handleSelectChange}
            onInputChange={handleInputChange}
            styles={getReactSelectStyles(colorMode, colorScheme)}
            classNamePrefix="react-select"
          />
        </ModalBody>

        <ModalFooter>
          <ChakraButton
            w="100%"
            bgColor={`${colorScheme}.400`}
            color={`${Color.LIGHT_GREYISH_WHITE.value}`}
            borderRadius="full"
            _focus={{ outline: "none" }}
            _hover={{
              bgColor: "#4fbeff",
            }}
            ref={btnRef}
            onClick={() => {
              setIsLoading(true);
              onSubmit(selectedTagOptions.map((t) => t.value));
            }}
          >
            {(isLoading && <Spinner />) || "Save"}
          </ChakraButton>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default UpdateTags;
