import {
  Button,
  Flex,
  Icon,
  IconButton,
  Text,
  useBreakpointValue,
  useColorMode,
  VStack,
  useToast,
} from "@chakra-ui/react";
import EditorUltra, { EditorUltraPlugin } from "components/editor-ultra";
import { ReactComponent as TemplatesButtonIcon } from "assets/icons/templates-2.svg";
import ConversationDomain, {
  ConversationChannel,
} from "entities/domain/conversations/conversation-domain";
import { ReactComponent as BinButtonIcon } from "assets/icons/bin-ds.svg";
import {
  LexicalEditor,
  $getSelection,
  $isRangeSelection,
  $getRoot,
} from "lexical";
import React, {
  ChangeEventHandler,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useAppSelector } from "redux/hooks";
import { CustomFields } from "entities/domain/templates";
import { MessageSubType } from "entities/domain/conversations/message-domain";
import MerchantDomainBase from "entities/domain/admin/merchants/merchant-domain";
import { isMobileApp } from "util/methods";
import { messagesSelector } from "redux/features/messages";
import TemplateAttachment from "components/user-settings/shared/TemplateAttachment";
import InboxService from "services/inbox";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router";
import ContactsService from "services/contacts";
import CustomerChannelDomain from "entities/domain/customers/contact-channel-domain";
import FileAttachment from "../new-message-input-box/FileAttachment";
import SubjectLine from "../new-message-input-box/SubjectLine";
import TypingZoneOverlay from "./TypingZoneOverlay";
import { Template } from "../new-message-input-box/RichTextArea";
import InputActions from "./InputActions";
import SendButton from "./SendButton";
import MessageButtonsPreview from "../message/MessageButtonsPreview";

interface TypingZoneProps {
  updateEditorText: (m: string) => void;
  defaultText: {
    value: string;
  };
  template: Template | null;
  editorReference: React.MutableRefObject<LexicalEditor | undefined>;
  customFields?: CustomFields | null;
  subject: string | undefined;
  textToSend: string;
  fileUrl: string | null;
  isSendPossible: boolean;
  clearInput: () => void;
  setFile: (url: string | null, type: string | null, id: string | null) => void;
  setSubject: (subject: string | undefined) => void;
  addOrReplaceCustomField?: (key: string, value: string) => void;
  onTextChange: (text: string) => void;
  sendMessage: () => Promise<void>;
}

const isInputDisabled = (
  activeConversation: ConversationDomain | undefined,
  merchant: MerchantDomainBase,
  isLoadingActiveConversation: boolean,
  template: Template | null
): boolean => {
  return (
    !!activeConversation?.isTemplatesOnly() ||
    !!activeConversation?.isChannelDisconnected(merchant) ||
    (activeConversation?.channel === ConversationChannel.WHATSAPP &&
      !!template) ||
    isLoadingActiveConversation
  );
};

const getNewMessagePlaceholder = (
  conversation: ConversationDomain | undefined,
  merchant: MerchantDomainBase,
  openSmsConversation: () => Promise<void>,
  openTemplates: () => void
): ReactNode => {
  if (!conversation) {
    return "";
  }
  if (conversation.isChannelDisconnected(merchant)) {
    if (
      conversation.channel === ConversationChannel.WHATSAPP &&
      merchant.channels.some((c) => c.channelName === ConversationChannel.SMS)
    ) {
      return (
        <VStack spacing={2} py={2}>
          <Text cursor="default">
            You can’t send messages to this customer on Whatsapp.
          </Text>
          <Button onClick={openSmsConversation}>Try SMS instead</Button>
        </VStack>
      );
    }

    return `Please reconnect your ${conversation.channel} account to send message`;
  }

  if (
    conversation.channel === ConversationChannel.WHATSAPP &&
    conversation.isTemplatesOnly()
  ) {
    return (
      <Text cursor="default">
        It’s been 24 hours since this contact last sent you a message. To reopen
        the conversation, please send an approved WhatsApp template ({" "}
        <Icon
          onClick={() => openTemplates()}
          cursor="pointer"
          as={TemplatesButtonIcon}
        />{" "}
        ) to which they can respond.
      </Text>
    );
  }

  return "Type here to send a message";
};

const TypingZone = ({
  editorReference,
  updateEditorText,
  defaultText,
  customFields,
  template,
  isSendPossible,
  textToSend,
  fileUrl,
  subject,
  setFile,
  setSubject,
  addOrReplaceCustomField,
  clearInput,
  onTextChange,
  sendMessage,
}: TypingZoneProps) => {
  const isBaseSize = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );
  const { colorMode } = useColorMode();
  const { colorScheme } = useAppSelector((state) => state.theme);
  const {
    activeConversation,
    activeConversationId,
    isLoadingActiveConversation,
  } = useAppSelector((state) => state.conversations);
  const conversationMessages = useAppSelector(messagesSelector);
  const { merchant } = useAppSelector((state) => state.merchant);
  const auth0Context = useAuth0();
  const navigate = useNavigate();
  const toast = useToast();

  const [isLoadingSuggestion, setIsLoadingSuggestion] =
    useState<boolean>(false);
  const [showTemplatesPopover, setShowTemplatesPopover] =
    useState<boolean>(false);

  const handleCreate = async (chanId: string) => {
    try {
      const newConversation =
        await InboxService.createConversationWithChannelId(
          auth0Context,
          chanId,
          merchant.id
        );

      navigate(`/${merchant.id}/inbox/${newConversation.id}`);
    } catch (_error: unknown) {
      toast({
        status: "error",
        title: "Couldn't create SMS conversation with the customer.",
      });

      return;
    }
  };

  const messagePlaceholder = useMemo(() => {
    return getNewMessagePlaceholder(
      activeConversation,
      merchant,
      async () => {
        if (!activeConversation) {
          toast({
            status: "error",
            title: "Couldn't open SMS conversation",
          });

          return;
        }

        let smsChannel: CustomerChannelDomain | undefined;

        try {
          const customer = await ContactsService.getContact(
            auth0Context,
            activeConversation.customerId,
            merchant.groupId
          );
          smsChannel = customer.channels.find(
            (c) => c.type === ConversationChannel.SMS
          );
        } catch (_error: unknown) {
          toast({
            status: "error",
            title: "Couldn't find customer",
          });

          return;
        }

        if (!smsChannel) {
          toast({
            status: "error",
            title: "Couldn't find customer's SMS channel",
          });

          return;
        }

        await handleCreate(smsChannel.id!);
      },
      () => setShowTemplatesPopover(true)
    );
  }, [
    activeConversationId,
    conversationMessages.length,
    activeConversation?.allowedMessagesType,
    merchant.channels,
    handleCreate,
    activeConversation?.customerId,
    merchant.groupId,
  ]);
  const initialText = useMemo(() => defaultText, [defaultText.value]);
  const isDisabled = useMemo(() => {
    return isInputDisabled(
      activeConversation,
      merchant,
      isLoadingActiveConversation,
      template
    );
  }, [
    isLoadingActiveConversation,
    activeConversationId,
    activeConversation?.allowedMessagesType,
    merchant.channels,
    template,
  ]);

  const customFieldsMemoed = useMemo(
    () => customFields,
    [template?.customFields]
  );
  const enabledPlugins = useMemo(
    () => [
      EditorUltraPlugin.MAXLENGTH,
      ...(activeConversation?.channel === ConversationChannel.WHATSAPP &&
      !!template
        ? [EditorUltraPlugin.RICHTEXT]
        : []),
    ],
    [activeConversation?.channel, template]
  );

  const insertText = (textToInsert: string) => {
    if (!editorReference || !editorReference.current) {
      return;
    }

    editorReference.current.update(() => {
      const selection = $getSelection();

      if (!$isRangeSelection(selection)) {
        return;
      }

      selection.insertText(` ${textToInsert} `);

      onTextChange($getRoot().getTextContent());
    });

    editorReference.current?.focus(() => {}, {
      defaultSelection: "rootEnd",
    });
  };

  useEffect(() => {
    if (!conversationMessages || conversationMessages.length === 0) {
      return;
    }

    if (activeConversation?.isEmailChannel()) {
      const currentSubject =
        conversationMessages
          .slice()
          .reverse()
          .find((element) => element.subType === MessageSubType.REGULAR)
          ?.title || "";
      setSubject(currentSubject);
    }
  }, [conversationMessages]);

  const handleSubjectLineChange: ChangeEventHandler<HTMLInputElement> =
    useCallback((event) => {
      setSubject(event.target.value);
    }, []);

  const isMobileApplication = useMemo(() => isMobileApp(), []);

  const editorChannels = useMemo(
    () => (activeConversation?.channel ? [activeConversation.channel] : []),
    [activeConversation?.channel]
  );

  return (
    <Flex
      position="relative"
      mx="auto"
      backgroundColor={colorMode === "dark" ? "gray.700" : "white"}
      minHeight="6rem"
      borderRadius={{ base: 0, lg: "xl" }}
      px={4}
      pt={2}
      border="1px solid"
      alignSelf="center"
      width="100%"
      borderColor="transparent"
      justifyContent="space-between"
      pb={isMobileApplication ? 6 : 2}
      direction="column"
    >
      <TypingZoneOverlay isShown={isLoadingSuggestion} borderRadius="xl" />
      {activeConversation?.isEmailChannel() && (
        <SubjectLine
          handleTextChange={handleSubjectLineChange}
          text={subject || ""}
          template={template || null}
          isDisabled={isDisabled}
        />
      )}
      {activeConversation?.channel === ConversationChannel.WHATSAPP &&
      !!template ? (
        <Text color={colorMode === "dark" ? "gray.400" : "gray.200"} mb={2}>
          Preview for the template: {template.title}
        </Text>
      ) : null}
      <Flex
        direction="column"
        justifyContent="space-between"
        maxHeight={isBaseSize ? "300px" : "600px"}
        {...(activeConversation?.channel === ConversationChannel.WHATSAPP &&
        !!template
          ? {
              borderWidth: "1px",
              borderColor: colorMode === "dark" ? "white" : "gray.200",
              borderRadius: "xl",
              borderStyle: "dotted",
              sx: {
                ".template-attachment-wrapper": {
                  mb: 0,
                  mx: 2,
                  mt: 2,
                },
              },
            }
          : {})}
      >
        {activeConversation?.channel === ConversationChannel.WHATSAPP &&
        !!template &&
        template.mediaType &&
        template.mediaUrl ? (
          <TemplateAttachment
            mediaType={template.mediaType}
            mediaUrl={template.mediaUrl}
          />
        ) : null}
        <Flex
          {...(activeConversation?.channel === ConversationChannel.WHATSAPP &&
          !!template
            ? {
                pt: 4,
                px: 4,
                pb: 2,
              }
            : {})}
        >
          <EditorUltra
            maxHeight="250px"
            defaultText={initialText}
            placeholder={messagePlaceholder}
            isDisabled={isDisabled}
            isEditable={!isDisabled}
            editorReference={editorReference}
            setText={onTextChange}
            customFields={customFieldsMemoed}
            addOrReplaceCustomField={addOrReplaceCustomField}
            enabledPlugins={enabledPlugins}
            channels={editorChannels}
          />
        </Flex>
        {fileUrl ||
        (template &&
          template.mediaType &&
          template.mediaUrl &&
          activeConversation?.channel !== ConversationChannel.WHATSAPP) ? (
          <FileAttachment
            url={template?.mediaUrl || fileUrl || undefined}
            handleRemove={
              template ? undefined : () => setFile(null, null, null)
            }
          />
        ) : null}
        {activeConversation?.channel === ConversationChannel.WHATSAPP &&
        template &&
        template.buttons ? (
          <MessageButtonsPreview buttons={template.buttons} />
        ) : null}
      </Flex>
      <Flex justifyContent="space-between" alignItems="end">
        <Flex>
          <InputActions
            insertText={insertText}
            setFile={setFile}
            message={template ? template.text : textToSend}
            updateEditorText={updateEditorText}
            setIsLoadingSuggestion={setIsLoadingSuggestion}
            isCollapsed={false}
            showTemplatesPopover={showTemplatesPopover}
            setShowTemplatesPopover={setShowTemplatesPopover}
            isDisabled={!!activeConversation?.isChannelDisconnected(merchant)}
          />
        </Flex>
        <Flex gridGap={2} alignSelf="end">
          {activeConversation?.isTemplatesOnly() ||
          (activeConversation?.channel === ConversationChannel.WHATSAPP &&
            !!template) ? (
            <IconButton
              aria-label="Delete message"
              as={BinButtonIcon}
              variant="ghost"
              colorScheme={colorScheme}
              onClick={() => clearInput()}
              alignSelf="end"
              mb={1}
              isDisabled={!isSendPossible}
              _hover={{
                cursor: isSendPossible ? "pointer" : "not-allowed",
              }}
              __css={{
                width: "1rem",
                height: "1rem",
                path: {
                  fill: isSendPossible
                    ? colorMode === "dark"
                      ? `${colorScheme}.200`
                      : `${colorScheme}.400`
                    : colorMode === "dark"
                    ? `${colorScheme}.700`
                    : "gray.200",
                },
              }}
            />
          ) : null}
          <SendButton
            isVisible={true}
            onSend={sendMessage}
            isDisabled={!isSendPossible}
          />
        </Flex>
      </Flex>
    </Flex>
  );
};

export default TypingZone;
