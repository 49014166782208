import {
  Box,
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Stack,
  Text,
  useBreakpointValue,
  useColorMode,
  useToast,
} from "@chakra-ui/react";
import { addDays, endOfDay, format, startOfDay } from "date-fns";
import Select from "react-select";
import { v5 as uuidv5 } from "uuid";
import React, { memo, useCallback, useEffect, useRef, useState } from "react";
import SmartList, {
  SmartListIndividualAction,
} from "components/shared/SmartList";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { resetLoadingState } from "redux/features/payments";
import KeyloopRepairOrder from "entities/domain/keyloop-repair-order";
import IntegrationService from "services/integrations";
import { useAuth0 } from "@auth0/auth0-react";
import KeyloopAccount from "entities/domain/keyloop-account";
import ReactDatePicker from "react-datepicker";
import { getReactSelectStyles } from "util/methods";
import useContactsStore from "hooks/use-contacts-store";
import InboxService from "services/inbox";
import { useNavigate } from "react-router-dom";
import ContactDomain from "entities/domain/customers/contact-domain";
import SelectedContactChannels from "components/chat/shared/start-conversation-area/selectedContactChannels";

const ReferenceColumn = ({ item }: { item: KeyloopRepairOrder }) => {
  return (
    <Flex alignItems="center">
      <Text>{item.reference}</Text>
    </Flex>
  );
};

const CustomerColumn = ({ item }: { item: KeyloopRepairOrder }) => {
  return (
    <Flex alignItems="center" gridGap={4}>
      <Flex
        flexDirection="column"
        alignItems="start"
        justifyContent="center"
        textAlign="left"
        gridGap={1}
      >
        <Text>{item.getDisplayCustomer()}</Text>
      </Flex>
    </Flex>
  );
};

const AdvisorColumn = ({ item }: { item: KeyloopRepairOrder }) => {
  return (
    <Flex alignItems="center" gridGap={4}>
      <Flex
        flexDirection="column"
        alignItems="start"
        justifyContent="center"
        textAlign="left"
        gridGap={1}
      >
        <Text>{item.advisor.name}</Text>
      </Flex>
    </Flex>
  );
};

const ServiceTypesColumn = ({ item }: { item: KeyloopRepairOrder }) => {
  return (
    <Flex alignItems="center" gridGap={4}>
      <Flex
        flexDirection="column"
        alignItems="start"
        justifyContent="center"
        textAlign="left"
        gridGap={1}
      >
        {item.details.serviceTypes.map((service, index) => (
          <Text key={index}>{service}</Text>
        ))}
      </Flex>
    </Flex>
  );
};

const VehicleColumn = ({ item }: { item: KeyloopRepairOrder }) => {
  return (
    <Flex alignItems="center" gridGap={4}>
      <Flex
        flexDirection="column"
        alignItems="start"
        justifyContent="center"
        textAlign="left"
        gridGap={1}
      >
        <Text>{item.getDisplayVehicle()}</Text>
      </Flex>
    </Flex>
  );
};

const AppointmentColumn = ({ item }: { item: KeyloopRepairOrder }) => {
  return (
    <Flex alignItems="center" gridGap={4}>
      <Flex
        flexDirection="column"
        alignItems="start"
        justifyContent="center"
        textAlign="left"
        gridGap={1}
      >
        <Text>{item.getDisplayAppointment()}</Text>
      </Flex>
    </Flex>
  );
};

const ChannelSelectionModal = memo(
  ({
    isOpen,
    onClose,
    selectedContact,
    selectedChannelId,
    setSelectedChannelId,
    onStartConversation,
    colorScheme,
  }: {
    isOpen: boolean;
    onClose: () => void;
    selectedContact: ContactDomain | null;
    selectedChannelId: string | undefined;
    setSelectedChannelId: (id: string) => void;
    onStartConversation: () => void;
    colorScheme: string;
  }) => {
    const isBaseSize = useBreakpointValue(
      { base: true, md: false },
      { ssr: false }
    );

    return (
      <Modal
        size={isBaseSize ? "full" : "md"}
        isOpen={isOpen}
        onClose={onClose}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Choose a channel </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {selectedContact && (
              <SelectedContactChannels
                selectedContact={selectedContact}
                setChannelId={setSelectedChannelId}
                channelId={selectedChannelId}
              />
            )}
          </ModalBody>
          <ModalFooter>
            <Button variant="ghost" mr={3} onClick={onClose}>
              Cancel
            </Button>
            <Button
              colorScheme={colorScheme}
              isDisabled={!selectedChannelId}
              onClick={onStartConversation}
            >
              Message
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    );
  }
);

interface RepairOrderDetailsModalProps {
  isOpen: boolean;
  onClose: () => void;
  order: KeyloopRepairOrder | null;
  colorScheme: string;
}

const RepairOrderDetailsModal = memo(
  ({ isOpen, onClose, order, colorScheme }: RepairOrderDetailsModalProps) => {
    const isBaseSize = useBreakpointValue(
      { base: true, md: false },
      { ssr: false }
    );

    if (!order) return null;

    return (
      <Modal
        size={isBaseSize ? "full" : "2xl"}
        isOpen={isOpen}
        onClose={onClose}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Repair Order Details</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack spacing={4}>
              <Box>
                <Text fontWeight="bold">Order Information</Text>
                <Stack pl={4}>
                  <Text>ID: {order.id}</Text>
                  <Text>Reference: {order.reference}</Text>
                  <Text>Status: {order.status}</Text>
                  <Text>Type: {order.type}</Text>
                </Stack>
              </Box>

              <Box>
                <Text fontWeight="bold">Customer Information</Text>
                <Stack pl={4}>
                  <Text>Name: {order.customer.name}</Text>
                  <Text>Email: {order.customer.email}</Text>
                  <Text>Phone: {order.customer.phone}</Text>
                </Stack>
              </Box>

              <Box>
                <Text fontWeight="bold">Vehicle Information</Text>
                <Stack pl={4}>
                  <Text>Description: {order.vehicle.description}</Text>
                  <Text>VIN: {order.vehicle.vin}</Text>
                  <Text>License Plate: {order.vehicle.licensePlate}</Text>
                </Stack>
              </Box>

              <Box>
                <Text fontWeight="bold">Appointment</Text>
                <Stack pl={4}>
                  <Text>Schedule: {order.getDisplayAppointment()}</Text>
                </Stack>
              </Box>

              <Box>
                <Text fontWeight="bold">Service Details</Text>
                <Stack pl={4}>
                  {order.details.serviceTypes &&
                    order.details.serviceTypes.length > 0 && (
                      <Box>
                        <Text fontWeight="semibold">Service Types:</Text>
                        <Stack pl={4}>
                          {order.details.serviceTypes.map((service, index) => (
                            <Text key={index}>{service}</Text>
                          ))}
                        </Stack>
                      </Box>
                    )}

                  <Box>
                    <Text fontWeight="semibold">Check In:</Text>
                    <Stack pl={4}>
                      {order.details.checkInDateTime && (
                        <Text>
                          Date/Time:{" "}
                          {format(
                            new Date(order.details.checkInDateTime),
                            "PPpp"
                          )}
                        </Text>
                      )}
                      {order.details.checkInMileage && (
                        <Text>
                          Mileage: {order.details.checkInMileage.value}{" "}
                          {order.details.checkInMileage.unit}
                        </Text>
                      )}
                    </Stack>
                  </Box>

                  <Box>
                    <Text fontWeight="semibold">Check Out:</Text>
                    <Stack pl={4}>
                      {order.details.checkOutDateTime && (
                        <Text>
                          Date/Time:{" "}
                          {format(
                            new Date(order.details.checkOutDateTime),
                            "PPpp"
                          )}
                        </Text>
                      )}
                      {order.details.checkOutMileage && (
                        <Text>
                          Mileage: {order.details.checkOutMileage.value}{" "}
                          {order.details.checkOutMileage.unit}
                        </Text>
                      )}
                    </Stack>
                  </Box>

                  {order.details.completedDateTime && (
                    <Box>
                      <Text fontWeight="semibold">Completion:</Text>
                      <Text pl={4}>
                        {format(
                          new Date(order.details.completedDateTime),
                          "PPpp"
                        )}
                      </Text>
                    </Box>
                  )}

                  {order.details.closedDateTime && (
                    <Box>
                      <Text fontWeight="semibold">Closed:</Text>
                      <Text pl={4}>
                        {format(new Date(order.details.closedDateTime), "PPpp")}
                      </Text>
                    </Box>
                  )}
                </Stack>
              </Box>
            </Stack>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme={colorScheme} onClick={onClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    );
  }
);

const KeyloopRepairOrdersPage = () => {
  const { colorMode } = useColorMode();
  const { colorScheme } = useAppSelector((state) => state.theme);
  const { createContact } = useContactsStore();
  const dispatch = useAppDispatch();
  const isBaseSize = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );
  const navigate = useNavigate();

  const [fromDate, setFromDate] = useState<Date | null>(startOfDay(new Date()));
  const [toDate, setToDate] = useState<Date | null>(
    endOfDay(addDays(new Date(), 1))
  );

  useEffect(() => {
    return () => {
      dispatch(resetLoadingState());
    };
  }, []);
  const listContainerRef = useRef<HTMLDivElement>(null);
  const { merchant } = useAppSelector((state) => state.merchant);
  const auth0Context = useAuth0();
  const [keyloopAccounts, setKeyloopAccounts] = useState<KeyloopAccount[]>([]);
  const [selectedKeyloopAccount, setSelectedKeyloopAccount] =
    useState<KeyloopAccount | null>(null);
  const NAMESPACE = "6ba7b810-9dad-11d1-80b4-00c04fd430c8"; // Using the DNS namespace

  const statusOptions = [
    { label: "Created", value: "CREATED" },
    { label: "On Hold", value: "ONHOLD" },
    { label: "Checked In", value: "CHECKEDIN" },
    { label: "Work Started", value: "WORKSTARTED" },
    { label: "Work Completed", value: "WORKCOMPLETED" },
    { label: "Checked Out", value: "CHECKEDOUT" },
    { label: "Closed", value: "CLOSED" },
    { label: "Credited", value: "CREDITED" },
  ];
  const [selectedStatus, setSelectedStatus] = useState(statusOptions[0]);

  const toast = useToast();

  const [loading, setLoading] = useState(false);
  const [orders, setOrders] = useState<KeyloopRepairOrder[]>([]);

  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [pageOffset, setPageOffset] = useState(0);
  const [hasMoreOrders, setHasMoreOrders] = useState(true);
  const numberOfOrdersToLoad = 100;

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState<KeyloopRepairOrder | null>(
    null
  );
  const [isChannelModalOpen, setIsChannelModalOpen] = useState(false);
  const [selectedContact, setSelectedContact] = useState<ContactDomain | null>(
    null
  );
  const [selectedChannelId, setSelectedChannelId] = useState<string>();

  const handleModalClose = useCallback(() => {
    setIsChannelModalOpen(false);
    setSelectedContact(null);
    setSelectedChannelId(undefined);
  }, []);

  const handleStartConversation = useCallback(() => {
    if (selectedChannelId) {
      InboxService.createConversationWithChannelId(
        auth0Context,
        selectedChannelId,
        merchant.id
      ).then((conv) => {
        navigate({
          pathname: `/${merchant.id}/inbox/${conv.id}`,
        });
        handleModalClose();
      });
    }
  }, [selectedChannelId, merchant.id, handleModalClose]);

  const openConversation = useCallback((order: KeyloopRepairOrder) => {
    const channels = [];
    if (order.getCustomerEmailAddress()) {
      channels.push({
        type: "email",
        handle: order.getCustomerEmailAddress()!,
        is_active: true,
        is_subscribed: true,
      });
    }

    if (order.getCustomerPhoneNumber()) {
      channels.push({
        type: "sms",
        handle: order.getCustomerPhoneNumber()!,
        is_active: true,
        is_subscribed: true,
      });

      channels.push({
        type: "whatsapp",
        handle: order.getCustomerPhoneNumber()!,
        is_active: true,
        is_subscribed: true,
      });
    }

    createContact(
      {
        name: order.customer.name.split(" ")[0],
        surname: order.customer.name.split(" ")[1],
        tags: [],
        tagIds: [],
        channels,
        notes: null,
      },
      uuidv5(order.id, NAMESPACE)
    )
      .then((contact) => {
        if (contact) {
          setSelectedContact(contact);
          setIsChannelModalOpen(true);
        }
      })
      .catch((e) => {
        toast({
          status: "error",
          description: "Could not open a conversation with this customer",
        });
      });
  }, []);

  const getIndividualActions = useCallback(
    (
      orderItem: KeyloopRepairOrder
    ): SmartListIndividualAction<KeyloopRepairOrder>[] | undefined => {
      if (!orderItem) {
        return;
      }

      const individualActions: SmartListIndividualAction<KeyloopRepairOrder>[] =
        [];

      individualActions.push({
        label: "View",
        execute: () => {
          setSelectedOrder(orderItem);
          setIsModalOpen(true);
        },
      });

      individualActions.push({
        label: "Message",
        execute: () => {
          openConversation(orderItem);
        },
      });

      return individualActions;
    },
    []
  );

  const fetchKeyloopAccounts = async () => {
    setLoading(true);

    try {
      const fetchedKeyloopAccounts =
        await IntegrationService.getKeyloopAccounts(auth0Context, merchant.id);

      setKeyloopAccounts(fetchedKeyloopAccounts);
    } catch (error: unknown) {
      toast({
        status: "error",
        title:
          "Uh Oh! We could not fetch Keyloop accounts at this time. Please try again later or contact our customer support!",
      });
    } finally {
      setLoading(false);
    }
  };

  const addOrdersWithoutDuplicates = useCallback(
    (newOrders: KeyloopRepairOrder[], existingOrders: KeyloopRepairOrder[]) => {
      const existingOrderIds = new Set(existingOrders.map((order) => order.id));
      const uniqueNewOrders = newOrders.filter(
        (order) => !existingOrderIds.has(order.id)
      );
      return [...existingOrders, ...uniqueNewOrders];
    },
    []
  );

  const fetchKeyloopRepairOrders = async (
    offset: number
  ): Promise<KeyloopRepairOrder[]> => {
    if (selectedKeyloopAccount) {
      try {
        const repairOrders = await IntegrationService.getKeyloopRepairOrders(
          auth0Context,
          merchant.id,
          selectedKeyloopAccount.enterpriseId,
          selectedKeyloopAccount.storeId,
          selectedStatus.value,
          fromDate?.toISOString(),
          toDate?.toISOString(),
          offset + 1,
          numberOfOrdersToLoad
        );

        if (repairOrders.length < numberOfOrdersToLoad) {
          setHasMoreOrders(false);
        } else {
          setPageOffset(offset + 1);
        }
        return repairOrders;
      } catch (error) {
        toast({
          status: "error",
          title: "Failed to fetch repair orders",
          description:
            "Please try again or contact support if the problem persists.",
        });
        setHasMoreOrders(false);
        return [];
      }
    }
    return [];
  };

  useEffect(() => {
    fetchKeyloopAccounts();
  }, []);

  useEffect(() => {
    setSelectedKeyloopAccount(keyloopAccounts[0] || null);
  }, [keyloopAccounts]);

  useEffect(() => {
    setPageOffset(0);
    setHasMoreOrders(true);
    if (selectedKeyloopAccount) {
      fetchKeyloopRepairOrders(0).then((o) => {
        setOrders(o);
      });
    }
  }, [selectedKeyloopAccount, fromDate, toDate, selectedStatus]);

  if (loading) {
    return (
      <Flex alignItems="center" justifyContent="center" h="100%" w="100%">
        <Spinner size="xl" />
      </Flex>
    );
  }

  return (
    <Flex
      direction="column"
      position="relative"
      flex={1}
      width="100%"
      height="100%"
      {...(isBaseSize
        ? {}
        : {
            bgColor: colorMode === "dark" ? "gray.700" : "gray.100",
            pl: "2rem",
            pr: "1rem",
          })}
    >
      <Stack
        py={isBaseSize ? 2 : 4}
        px={isBaseSize ? 4 : 0}
        direction={isBaseSize ? "column" : "row"}
        width={isBaseSize ? "100%" : "auto"}
      >
        <Select
          value={{
            value: selectedKeyloopAccount?.storeId,
            label: selectedKeyloopAccount?.accountName,
          }}
          onChange={(e) => {
            const account = keyloopAccounts.find(
              (acc) => acc.storeId === e?.value
            );
            setSelectedKeyloopAccount(account || null);
          }}
          options={keyloopAccounts.map((account) => ({
            value: account.storeId,
            label: account.accountName || account.storeId,
          }))}
          placeholder="Select Keyloop Account"
          styles={getReactSelectStyles(colorMode, colorScheme)}
        />
        <Select
          value={selectedStatus}
          onChange={(e) => {
            setSelectedStatus(e || statusOptions[0]);
          }}
          options={statusOptions}
          placeholder="Select Status"
          styles={getReactSelectStyles(colorMode, colorScheme)}
        />
        <Box
          rounded="full"
          border="none"
          textAlign="left"
          bgColor={
            colorMode === "dark" ? "black" : isBaseSize ? "gray.50" : "white"
          }
          px={3}
          css={{
            input: {
              height: isBaseSize ? "2rem" : "2.5rem",
              width: "100%",
              backgroundColor: "inherit",
            },
            ".react-datepicker-wrapper": {
              width: "100%",
            },
          }}
        >
          <ReactDatePicker
            id="analytics-from-date"
            selected={fromDate}
            placeholderText="From"
            onChange={(newDate) => {
              setFromDate(newDate);
            }}
            showTimeSelect={false}
            dateFormat="yyyy-MM-dd"
          />
        </Box>
        <Box
          rounded="full"
          border="none"
          bgColor={
            colorMode === "dark" ? "black" : isBaseSize ? "gray.50" : "white"
          }
          textAlign="left"
          px={3}
          css={{
            input: {
              height: isBaseSize ? "2rem" : "2.5rem",
              width: "100%",
              backgroundColor: "inherit",
            },
            ".react-datepicker-wrapper": {
              width: "100%",
            },
          }}
        >
          <ReactDatePicker
            id="analytics-to-date"
            selected={toDate}
            placeholderText="Until"
            onChange={(newDate) => {
              setToDate(newDate);
            }}
            minDate={fromDate}
            showTimeSelect={false}
            dateFormat="yyyy-MM-dd"
          />
        </Box>
      </Stack>
      <Box
        borderTopRadius={isBaseSize ? 0 : "3xl"}
        bgColor={colorMode === "dark" ? "gray.800" : "white"}
        w="100%"
        h="100%"
        overflowY="hidden"
        position="relative"
      >
        <Box
          h="100%"
          w="100%"
          overflowY="auto"
          mx="auto"
          ref={listContainerRef}
        >
          <SmartList
            containerRef={listContainerRef}
            columns={[
              {
                label: "Date",
                component: AppointmentColumn,
              },
              {
                label: "Customer",
                component: CustomerColumn,
              },
              {
                label: "Vehicle",
                component: VehicleColumn,
              },
              {
                label: "Services",
                component: ServiceTypesColumn,
              },
              {
                label: "Advisor",
                component: AdvisorColumn,
              },
              {
                label: "Reference",
                component: ReferenceColumn,
              },
            ]}
            items={orders}
            itemIdentifier="id"
            getDifferentiator={(order: KeyloopRepairOrder | undefined) =>
              order?.getDailyKey() || ""
            }
            initialScroll={true}
            hasNextPage={hasMoreOrders}
            canFetchMore={hasMoreOrders && !isLoadingMore}
            fetchMore={async () => {
              setIsLoadingMore(true);
              try {
                const newOrders = await fetchKeyloopRepairOrders(pageOffset);
                setOrders((prevOrders) =>
                  addOrdersWithoutDuplicates(newOrders, prevOrders)
                );
              } finally {
                setIsLoadingMore(false);
              }
            }}
            getIndividualActions={getIndividualActions}
          />
        </Box>
      </Box>
      <RepairOrderDetailsModal
        isOpen={isModalOpen}
        onClose={() => {
          setIsModalOpen(false);
          setSelectedOrder(null);
        }}
        order={selectedOrder}
        colorScheme={colorScheme}
      />
      <ChannelSelectionModal
        isOpen={isChannelModalOpen}
        onClose={handleModalClose}
        selectedContact={selectedContact}
        selectedChannelId={selectedChannelId}
        setSelectedChannelId={setSelectedChannelId}
        onStartConversation={handleStartConversation}
        colorScheme={colorScheme}
      />
    </Flex>
  );
};

export default KeyloopRepairOrdersPage;
