import { Checkbox } from "@chakra-ui/checkbox";
import { useColorMode } from "@chakra-ui/color-mode";
import { Flex, Text } from "@chakra-ui/layout";
import React from "react";

interface UnsubscribeSuffixProps {
  isIncluded: boolean;
  isDisabled?: boolean;
  setIsIncluded: (newIsIncluded: boolean) => void;
}

export const UnsubscribeSuffix: React.FC<UnsubscribeSuffixProps> = ({
  isIncluded,
  isDisabled = false,
  setIsIncluded,
}) => {
  const { colorMode } = useColorMode();

  return (
    <Flex
      alignItems="center"
      justifyContent="start"
      gridGap={2}
      borderRadius="md"
      borderColor={colorMode === "dark" ? "gray.900" : "gray.50"}
      borderStyle="solid"
      borderWidth="1px"
      width="fit-content"
      p={2}
      mb={2}
    >
      <Checkbox
        isDisabled={isDisabled}
        colorScheme="green"
        isChecked={isIncluded}
        onChange={(e) => setIsIncluded(e.target.checked)}
      >
        <Text
          textColor={
            colorMode === "dark"
              ? isIncluded
                ? "gray.100"
                : "gray.600"
              : isIncluded
              ? "gray.600"
              : "gray.100"
          }
        >
          Reply UNSUB to unsubscribe
        </Text>
      </Checkbox>
    </Flex>
  );
};
