import { CloseIcon } from "@chakra-ui/icons";
import { Button, Flex, Icon, Image } from "@chakra-ui/react";
import { useAppSelector } from "redux/hooks";
import React, { useEffect, useState } from "react";
import { SUPPORTED_MEDIA_TYPES } from "components/shared/FilePreview";

interface TemplateAttachmentProps {
  mediaUrl: string | null;
  mediaType: string | null;
  file?: File | null;
  onAttachmentRemove?: () => void;
}

const TemplateAttachmentWrapper = ({
  children,
  onRemove,
}: {
  children: React.ReactNode;
  onRemove?: () => void;
}) => {
  const { colorScheme } = useAppSelector((state) => state.theme);

  return (
    <Flex
      borderRadius="lg"
      pr={typeof onRemove === "undefined" ? 0 : 8}
      pt={typeof onRemove === "undefined" ? 0 : 8}
      position="relative"
      width="fit-content"
      mb={8}
      overflowY="auto"
      maxWidth="600px"
      className="template-attachment-wrapper"
    >
      {typeof onRemove !== "undefined" && (
        <Button
          position="absolute"
          size="xs"
          top={0}
          right={0}
          mt={2}
          mr={2}
          variant="ghost"
          colorScheme={colorScheme}
          onClick={() => {
            if (typeof onRemove === "undefined") {
              return;
            }

            onRemove();
          }}
        >
          <Icon as={CloseIcon} />
        </Button>
      )}
      {children}
    </Flex>
  );
};

const TemplateAttachment = ({
  mediaUrl,
  mediaType,
  file,
  onAttachmentRemove,
}: TemplateAttachmentProps) => {
  if (!mediaUrl && !file) {
    return null;
  }

  if (mediaUrl && mediaType && !SUPPORTED_MEDIA_TYPES.includes(mediaType)) {
    return null;
  }

  if (file && !SUPPORTED_MEDIA_TYPES.includes(file.type)) {
    return null;
  }

  const actualMediaType = file ? file.type : mediaType;

  const [actualMediaUrl, setActualMediaUrl] = useState<string | null>(mediaUrl);

  useEffect(() => {
    let fileReader: FileReader | null = null;
    let isCancel = false;

    if (file) {
      fileReader = new FileReader();
      fileReader.onload = () => {
        if (fileReader!.result && !isCancel) {
          setActualMediaUrl(fileReader!.result as string);
        }
      };
      fileReader.readAsDataURL(file);
    } else {
      setActualMediaUrl(mediaUrl);
    }

    return () => {
      isCancel = true;

      if (fileReader && fileReader.readyState === 1) {
        fileReader.abort();
      }
    };
  }, [file, mediaUrl, mediaType]);

  if (actualMediaType!.includes("image")) {
    return (
      <TemplateAttachmentWrapper onRemove={onAttachmentRemove}>
        <Image
          boxSize="sm"
          objectFit="cover"
          src={actualMediaUrl!}
          className="template-attachment"
          alt="Template attachment"
          style={{ maxWidth: "100%", maxHeight: "300px" }}
          borderRadius="lg"
        />
      </TemplateAttachmentWrapper>
    );
  }

  if (actualMediaType!.includes("video")) {
    return (
      <TemplateAttachmentWrapper onRemove={onAttachmentRemove}>
        <video
          src={actualMediaUrl!}
          className="template-attachment"
          controls
          style={{
            maxWidth: "500px",
            maxHeight: "300px",
            borderRadius: "0.5rem",
          }}
        />
      </TemplateAttachmentWrapper>
    );
  }

  if (actualMediaType!.includes("audio")) {
    return (
      <TemplateAttachmentWrapper onRemove={onAttachmentRemove}>
        <audio
          className="template-attachment"
          src={actualMediaUrl!}
          controls
          style={{ maxWidth: "500px", borderRadius: "0.5rem" }}
        >
          Your browser does not support the
          <code>audio</code> element.
        </audio>
      </TemplateAttachmentWrapper>
    );
  }

  if (actualMediaType!.includes("pdf")) {
    return (
      <TemplateAttachmentWrapper onRemove={onAttachmentRemove}>
        <a
          href={actualMediaUrl!}
          className="template-attachment"
          target="_blank"
          rel="noopener noreferrer"
          download={true}
          style={{
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
          }}
        >
          {file ? file.name : "PDF Attachment"}
        </a>
      </TemplateAttachmentWrapper>
    );
  }

  return null;
};

export default TemplateAttachment;
