import { Heading, VStack } from "@chakra-ui/react";
import React, {
  memo,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { AgentMerchantDomain } from "entities/domain/agents/new-agent-domain";
import { useAppSelector } from "redux/hooks";
import DesktopSwitchButton from "./desktop-button";

interface MerchantSwitchProps {}

const MerchantSwitch = (_props: MerchantSwitchProps) => {
  const { isSelectorOpened } = useAppSelector((state) => state.merchant);
  const { unreadCounts } = useAppSelector((state) => state.conversations);
  const { currentAgent } = useAppSelector((state) => state.agents);
  const { colorScheme } = useAppSelector((state) => state.theme);

  const listRef = useRef<HTMLDivElement>(null);

  const [initialWidth, setInitialWidth] = useState<number>(0);
  const [unreadCountByMerchant, setUnreadCountByMerchant] = useState<{
    [key: number]: number;
  }>({});

  useEffect(() => {
    const newUnreadCountByMerchant = unreadCounts.reduce(
      (acc, uc) => ({
        ...acc,
        [uc.merchant_id]: uc.shared,
      }),
      {}
    );

    setUnreadCountByMerchant(newUnreadCountByMerchant);
  }, [unreadCounts]);

  useLayoutEffect(() => {
    if (!listRef?.current || initialWidth) {
      return;
    }

    setInitialWidth(listRef.current.clientWidth);
  }, [listRef?.current]);

  return currentAgent && currentAgent.merchants.length > 1 ? (
    <VStack
      h="100%"
      ref={listRef}
      maxWidth={
        initialWidth ? (isSelectorOpened ? `${initialWidth}px` : 0) : "auto"
      }
      p={isSelectorOpened ? 4 : 0}
      zIndex={isSelectorOpened ? 1 : -1}
      transition="all .3s ease-in-out"
      overflowY="auto"
      bgColor={`${colorScheme}.700`}
      justifyContent="start"
      alignItems="start"
    >
      <Heading
        as="h2"
        fontWeight="bold"
        color="white"
        textAlign="center"
        mb={4}
      >
        {currentAgent.getGroupName()
          ? `${currentAgent.getGroupName()} Accounts`
          : "Accounts"}
      </Heading>
      {[...currentAgent.merchants]
        .sort(AgentMerchantDomain.sortMerchantsAlphabetically)
        .map((m, index) => (
          <DesktopSwitchButton
            overwrightColorMode="light"
            merchantOption={m}
            index={index}
            key={m.id}
            buttonColorScheme="whiteAlpha"
            unreadCount={unreadCountByMerchant[m.id] || 0}
          />
        ))}
    </VStack>
  ) : null;
};

export default memo(MerchantSwitch);
