import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  CloseButton,
  Link,
  Text,
  VStack,
  useBreakpointValue,
} from "@chakra-ui/react";
import React from "react";

interface WhatsappDocumentsModalProps {
  isShown: boolean;
  onClose: () => void;
}

const WhatsappDocumentsModal = ({
  isShown,
  onClose,
}: WhatsappDocumentsModalProps) => {
  const isBaseSize = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );

  return !isShown ? null : (
    <Alert
      py={4}
      px={8}
      status="info"
      variant="subtle"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      textAlign="center"
      height="200px"
      position="relative"
    >
      <CloseButton
        position="absolute"
        right={isBaseSize ? 0 : 4}
        top={isBaseSize ? 0 : 4}
        onClick={onClose}
      />
      <AlertIcon boxSize={isBaseSize ? 4 : 8} mr={0} />
      <AlertTitle mt={4} mb={1} fontSize={isBaseSize ? "md" : "lg"}>
        Make sure you are aware of WhatsApp Messaging best practices
      </AlertTitle>
      <AlertDescription maxWidth={isBaseSize ? "lg" : "sm"}>
        <VStack alignItems="start" justifyContent="start" spacing={1}>
          <Text fontSize="sm">
            <Link href="https://business.whatsapp.com/policy" isExternal>
              📄💼 WhatsApp business messaging policy
            </Link>
          </Text>
          <Text fontSize="sm">
            <Link
              href="https://business.whatsapp.com/policy#how_we_enforce_our_policies"
              isExternal
            >
              🔍⚖️ How WhatsApp enforces their policy
            </Link>
          </Text>
          <Text fontSize="sm">
            <Link
              href="https://business.whatsapp.com/policy#best_practices"
              isExternal
            >
              🌟📊 Best practices
            </Link>
          </Text>
        </VStack>
      </AlertDescription>
    </Alert>
  );
};

export default WhatsappDocumentsModal;
