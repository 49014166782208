import TenantDomain from "entities/domain/xero/tenant-domain";
import XeroDomain from "entities/domain/xero/xero-domain";
import { TenantDTO, XeroDTO } from "entities/dto/XeroDTO";

export const tenantTransformFromDtoToDomain = (
  tenant: TenantDTO
): TenantDomain => new TenantDomain(tenant.id, tenant.name, tenant.type);

export const xeroTransformFromDtoToDomain = (data: XeroDTO): XeroDomain =>
  new XeroDomain(tenantTransformFromDtoToDomain(data.tenant_id));
