import AttributeDomain from "entities/domain/attributes/attribute-domain";
import { AttributeDTO } from "entities/dto/AttributeDTO";

export const attributeTransformFromDtoToDomain = (
  attribute: AttributeDTO
): AttributeDomain =>
  new AttributeDomain(
    attribute.id,
    attribute.name,
    attribute.type,
    attribute.group_id
  );

export const attributeArrayTransformFromDtoToDomain = (
  attributes: AttributeDTO[]
) =>
  attributes.map((attribute) => attributeTransformFromDtoToDomain(attribute));
