import ContactDomain from "./contact-domain";

export default class ContactListDomain {
  contacts: ContactDomain[];

  totalCount: number;

  constructor(contacts: ContactDomain[], totalCount: number) {
    this.contacts = contacts;
    this.totalCount = totalCount;
  }
}
