import { isFuzeyMerchant } from "util/methods";
import { ColorName } from "theme/old-design-system/styled-components";
import { StyleProps } from "@chakra-ui/react";
import { UserPermissionsKey } from "util/permissions";
import AgentNotificationConfigDomain from "./notification-config-domain";

export interface StylePreferences {
  chatBackground: string | null;
  colorScheme: string | null;
}

export class AgentMerchantRoleDomain {
  id: number;
  name: string;
  permissions: string[];

  constructor(id: number, name: string, permissions: string[]) {
    this.id = id;
    this.name = name;
    this.permissions = permissions;
  }
}

export class AgentMerchantDomain {
  id: number;

  name: string;

  logo: string;

  role: AgentMerchantRoleDomain;

  constructor(
    id: number,
    name: string,
    logo: string,
    role: AgentMerchantRoleDomain
  ) {
    this.id = id;
    this.name = name;
    this.logo = logo;
    this.role = role;
  }

  public static sortMerchantsAlphabetically(
    a: AgentMerchantDomain,
    b: AgentMerchantDomain
  ) {
    return a.name.localeCompare(b.name);
  }

  getGroupName(): string {
    if (!this.name.includes("|")) {
      return "";
    }

    return this.name.split("|")[0].trim();
  }

  getNameWithoutGroup(): string {
    return this.name.split("|").slice(1).join("|").trim();
  }
}

export default class NewAgentDomain {
  id: number;

  name: string;

  surname: string;

  email: string;

  preferredContactChannel: string;

  notificationConfig: AgentNotificationConfigDomain | null;

  merchants: AgentMerchantDomain[];

  userId: string;

  profilePic: string;

  profilePicFile?: File | null;

  isActive: boolean;

  color: ColorName;

  playerIds: string[] | null;

  stylePreferences: StylePreferences | null;

  constructor(
    id: number,
    name: string,
    surname: string,
    email: string,
    preferredContactChannel: string,
    notificationConfig: AgentNotificationConfigDomain | null,
    merchants: AgentMerchantDomain[],
    userId: string,
    profilePic: string,
    isActive: boolean,
    color: ColorName,
    playerIds: string[] | null,
    stylePreferences: StylePreferences | null
  ) {
    this.id = id;
    this.name = name;
    this.surname = surname;
    this.email = email;
    this.preferredContactChannel = preferredContactChannel;
    this.notificationConfig = notificationConfig;
    this.merchants = merchants;
    this.userId = userId;
    this.profilePic = profilePic;
    this.isActive = isActive;
    this.color = color;
    this.playerIds = playerIds;
    this.stylePreferences = stylePreferences;
  }

  public getGroupName(): string {
    const firstMerchantsGroupName = this.merchants[0].getGroupName();

    if (
      !firstMerchantsGroupName ||
      this.merchants.some((m) => m.getGroupName() !== firstMerchantsGroupName)
    ) {
      return "";
    }

    return firstMerchantsGroupName;
  }

  public getFullName(): string {
    return `${this.name} ${this.surname}`;
  }

  public getPermissions(merchantId: number): UserPermissionsKey[] {
    return this.getRole(merchantId).permissions.map((p) => {
      return p as UserPermissionsKey;
    });
  }

  public getPicture() {
    if (this.profilePic && this.profilePic !== "null") {
      return this.profilePic;
    }

    return "";
  }

  public getRole(merchantId: number) {
    return this.merchants.filter((m) => m.id === merchantId)[0].role;
  }

  public hasAccessToFuzeyMerchant(): boolean {
    return this.merchants.filter((m) => isFuzeyMerchant(m.name)).length > 0;
  }

  public getChatBackgroundStyles(colorMode: "dark" | "light"): StyleProps {
    if (!this.stylePreferences?.chatBackground) {
      return {
        background: colorMode === "dark" ? "gray.900" : "gray.50",
      };
    }

    const isURL = this.stylePreferences.chatBackground.startsWith("http");

    if (isURL) {
      return {
        background: `no-repeat url("${this.stylePreferences.chatBackground}")`,
        backgroundSize: "cover",
      };
    }

    return {
      background:
        colorMode === "dark"
          ? `${this.stylePreferences.chatBackground}.700`
          : `${this.stylePreferences.chatBackground}.300`,
    };
  }
}
