import { useAuth0 } from "@auth0/auth0-react";
import {
  Box,
  Checkbox,
  FormControl,
  FormLabel,
  HStack,
  Image,
  Input,
  Stack,
  Text,
  useColorMode,
  Button as ChakraButton,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  useClipboard,
  InputRightElement,
  InputGroup,
  useToast,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  UnorderedList,
  ListItem,
  VStack,
  Icon,
} from "@chakra-ui/react";
import {
  Button,
  ButtonSize,
  ButtonType,
} from "theme/old-design-system/styled-components";
import { WidgetConfigDomain } from "entities/domain/widget";
import React, { ChangeEventHandler, useEffect, useRef, useState } from "react";
import Select, { SingleValue } from "react-select";

import WidgetService from "services/widget";
import { getReactSelectStyles } from "util/methods";
import { ArrowRightIcon, EditIcon } from "@chakra-ui/icons";
import { MdContentCopy } from "react-icons/md";
import { RiCheckFill, RiJavascriptLine } from "react-icons/ri";
import AdminMerchantDomain from "entities/domain/admin/merchants/admin-merchant-domain";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import {
  addFileAttachment,
  addImageAttachment,
  clearAttachments,
} from "redux/features/attachments";
import CustomChannelsEdit from "./custom-channels-edit";
import SelectMerchant from "../select-merchant";

type WidgetOptionTypes = {
  value: string;
  id: string;
  label: string;
};

type WidgetConfigEdit = {
  removeLogo?: boolean;
  removeButtonImage?: boolean;
} & WidgetConfigDomain;

const createWidgetScript = (widgetId: string | undefined): string => {
  if (widgetId) {
    return `<script src="https://widget.fuzey.io/static/js/widget.js" id="fuzey-widget" widget-id="${widgetId}"></script>`;
  }
  return "";
};

const WidgetConfig = () => {
  const reader = new FileReader();

  const [selectedMerchant, setSelectedMerchant] =
    useState<AdminMerchantDomain | null>(null);

  const [merchantWidgets, setMerchantWidgets] = useState<
    WidgetConfigDomain[] | null
  >(null);

  const [widgetOptions, setWidgetOptions] = useState<
    WidgetOptionTypes[] | null
  >(null);

  const [selectedWidget, setSelectedWidget] =
    useState<SingleValue<WidgetOptionTypes>>(null);

  const [currentWidgetConfig, setCurrentWidgetConfig] =
    useState<WidgetConfigEdit | null>(null);

  const [customChannelsEditOpen, setCustomChannelsEditOpen] = useState(false);

  const { colorMode } = useColorMode();
  const { colorScheme } = useAppSelector((state) => state.theme);
  const toast = useToast();
  const dispatch = useAppDispatch();

  const { files, images } = useAppSelector((state) => state.attachments);
  const auth0Context = useAuth0();
  const fileInput = useRef<HTMLInputElement>(null);
  const buttonImageInput = useRef<HTMLInputElement>(null);

  const { hasCopied: hasCopiedWidgetId, onCopy: onCopyWidgetId } = useClipboard(
    currentWidgetConfig?.id || ""
  );
  const { hasCopied: hasCopiedWidgetScript, onCopy: onCopyWidgetScript } =
    useClipboard(createWidgetScript(currentWidgetConfig?.id));

  const loadWidgets = async () => {
    setSelectedWidget(null);

    if (selectedMerchant) {
      const returnedWidgets = await WidgetService.fetchWidgets(
        selectedMerchant.id,
        auth0Context
      );
      setMerchantWidgets(returnedWidgets);

      const widgets =
        returnedWidgets?.map((widget) => ({
          value: widget.id,
          id: widget.id,
          label: widget.alias,
        })) || [];

      setWidgetOptions([
        {
          value: "new",
          id: "new",
          label: "+ Create new widget",
        },
        ...widgets,
      ]);
    } else {
      setWidgetOptions(null);
    }
  };

  const removePreview = () => {
    document.getElementById("fuzey-widget")?.remove();
    document.getElementById("fuzey-widget-root")?.remove();
    if (selectedWidget) {
      localStorage.removeItem(selectedWidget.id);
    }
  };

  const resetFiles = () => {
    dispatch(clearAttachments());
    if (fileInput.current) {
      fileInput.current.value = "";
    }
  };

  useEffect(() => {
    resetFiles();
    removePreview();
    loadWidgets();
  }, [selectedMerchant]);

  useEffect(() => {
    if (selectedWidget?.id === "new") {
      setCurrentWidgetConfig({ id: "new" } as WidgetConfigEdit);
    } else if (selectedWidget) {
      const selectedWidgetConfig = merchantWidgets?.find(
        (widget) => widget.id === selectedWidget.id
      );

      if (selectedWidgetConfig) {
        setCurrentWidgetConfig({
          ...selectedWidgetConfig,
          removeLogo: false,
          removeButtonImage: false,
        });
      }
    } else {
      setCurrentWidgetConfig(null);
    }
  }, [selectedWidget]);

  const handleWidgetIdChange = (e: SingleValue<WidgetOptionTypes>) => {
    setSelectedWidget(e);
  };

  const onFileChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    if (!e.target.files) return;

    const file = e.target.files[0];

    if (file) {
      dispatch(addFileAttachment(file));

      reader.readAsDataURL(file);
    }

    reader.onload = () => {
      dispatch(addImageAttachment(reader.result as string));
    };

    reader.onerror = () => {
      toast({
        status: "error",
        title: "An error occurred",
      });
    };
  };

  const onButtonImageChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    if (!e.target.files) return;

    const file = e.target.files[0];

    if (file) {
      dispatch(addFileAttachment(file));

      reader.readAsDataURL(file);
    }

    reader.onload = () => {
      dispatch(addImageAttachment(reader.result as string));
    };

    reader.onerror = () => {
      toast({
        status: "error",
        title: "An error occurred",
      });
    };
  };

  const saveConfig = async () => {
    try {
      const isLogoSelected = fileInput.current?.files?.length === 1;
      const isButtonImageSelected =
        buttonImageInput.current?.files?.length === 1;

      if (selectedMerchant && currentWidgetConfig) {
        const command = {
          alias: currentWidgetConfig?.alias,
          primary_colour: currentWidgetConfig?.primaryColour,
          secondary_colour: currentWidgetConfig?.secondaryColour,
          delete_logo: currentWidgetConfig?.removeLogo,
          delete_button_image: currentWidgetConfig?.removeButtonImage,
          ...(files.length === 2
            ? {
                logo: files[0],
                button_image: files[1],
              }
            : {
                ...(isLogoSelected
                  ? {
                      logo: files[0],
                    }
                  : {}),
                ...(isButtonImageSelected
                  ? {
                      button_image: files[0],
                    }
                  : {}),
              }),
          contact_form_url: currentWidgetConfig?.externalContactFormUrl,
          hide_powered_by: currentWidgetConfig?.hidePoweredBy,
          hide_contact_form: currentWidgetConfig?.hideContactForm,
          prevent_auto_open: currentWidgetConfig?.preventAutoOpen,
          auto_open_delay: currentWidgetConfig?.autoOpenDelay,
          location_name: currentWidgetConfig?.locationName,
          button_title: currentWidgetConfig?.buttonTitle,
          default_text: currentWidgetConfig?.defaultText,
          success_title: currentWidgetConfig?.successTitle,
          success_subtitle: currentWidgetConfig?.successSubtitle,
          custom_channels: currentWidgetConfig?.customChannels
            ? JSON.stringify(
                currentWidgetConfig?.customChannels?.map((channel) => ({
                  type: channel.type,
                  handle: channel.handle,
                  disabled: channel.disabled,
                }))
              )
            : null,
        };

        if (currentWidgetConfig.id === "new") {
          await WidgetService.createWidgetConfig(
            selectedMerchant.id,
            command,
            auth0Context
          );
        } else {
          await WidgetService.updateWidgetConfig(
            selectedMerchant.id,
            currentWidgetConfig.id,
            command,
            auth0Context
          );
        }

        resetFiles();
        removePreview();
        loadWidgets();

        toast({
          status: "success",
          title: "Widget Config has been saved successfully.",
        });
      }
    } catch (err) {
      toast({
        status: "error",
        title: "An error occurred",
      });
    }
  };

  const handleSaveConfig = () => {
    saveConfig();
  };

  const handlePreview = () => {
    removePreview();

    const script = document.createElement("script");
    script.setAttribute(
      "src",
      `${process.env.REACT_APP_WIDGET_URL}/static/js/widget.js`
    );
    script.setAttribute("id", "fuzey-widget");
    script.setAttribute("widget-id", selectedWidget!.id);

    if (currentWidgetConfig) {
      const {
        primaryColour,
        secondaryColour,
        externalContactFormUrl,
        buttonTitle,
      } = currentWidgetConfig;

      if (buttonTitle) {
        script.setAttribute("button-title", buttonTitle);
      }

      if (primaryColour) {
        script.setAttribute("primary-colour", primaryColour);
      }

      if (secondaryColour) {
        script.setAttribute("secondary-colour", secondaryColour);
      }

      if (externalContactFormUrl) {
        script.setAttribute("external-contact-form", externalContactFormUrl);
      }
    }

    if (images && images.length) {
      if (images.length === 2) {
        script.setAttribute("logo-base64", images[0]);
        script.setAttribute("button-image-url", images[1]);
      } else if (images.length === 1) {
        const isLogoSelected = fileInput.current?.files?.length === 1;
        const isButtonImageSelected =
          buttonImageInput.current?.files?.length === 1;

        if (isLogoSelected) {
          script.setAttribute("logo-base64", images[0]);
        } else if (isButtonImageSelected) {
          script.setAttribute("button-image-url", images[0]);
        }
      }
    }

    document.head.appendChild(script);
  };

  return (
    <Stack spacing={3}>
      <FormControl isRequired>
        <FormLabel>Select Merchant</FormLabel>
        <SelectMerchant
          selectedMerchant={selectedMerchant}
          setSelectedMerchant={setSelectedMerchant}
        />
      </FormControl>
      {widgetOptions && (
        <FormControl>
          <FormLabel>Select Widget</FormLabel>
          <Select
            placeholder="Select Widget"
            value={selectedWidget}
            onChange={handleWidgetIdChange}
            options={widgetOptions}
            styles={{
              ...getReactSelectStyles(colorMode, colorScheme),
              menu: (provided: any) => ({
                ...getReactSelectStyles(colorMode, colorScheme).menu(provided),
                position: "relative",
              }),
            }}
          />
        </FormControl>
      )}
      {currentWidgetConfig && (
        <>
          <FormControl>
            <FormLabel>
              <Text>ID - Used on the widget script</Text>
            </FormLabel>
            <InputGroup>
              <Input
                value={
                  currentWidgetConfig.id !== "new" ? currentWidgetConfig.id : ""
                }
                disabled={true}
              />
              {currentWidgetConfig.id !== "new" && (
                <InputRightElement w="150px" pr="2" justifyContent="flex-end">
                  <ChakraButton
                    variant="unstyled"
                    onClick={onCopyWidgetId}
                    display="flex"
                    justifyItems="center"
                    _focus={{ border: "none" }}
                  >
                    <MdContentCopy size="1.2rem" />
                    {hasCopiedWidgetId && (
                      <Box pos="absolute" top="0" right="0">
                        <RiCheckFill fill="green" />
                      </Box>
                    )}
                  </ChakraButton>
                  <ChakraButton
                    variant="unstyled"
                    onClick={onCopyWidgetScript}
                    display="flex"
                    justifyItems="center"
                    _focus={{ border: "none" }}
                  >
                    <RiJavascriptLine size="1.5rem" />
                    {hasCopiedWidgetScript && (
                      <Box pos="absolute" top="0" right="0">
                        <RiCheckFill fill="green" />
                      </Box>
                    )}
                  </ChakraButton>
                </InputRightElement>
              )}
            </InputGroup>
          </FormControl>
          <FormControl>
            <FormLabel>Alias</FormLabel>
            <Input
              value={currentWidgetConfig.alias || "Home Page"}
              onChange={(e) =>
                setCurrentWidgetConfig({
                  ...currentWidgetConfig,
                  alias: e.target.value,
                })
              }
            />
          </FormControl>
          <FormControl>
            <Alert status="info" borderRadius="lg">
              <AlertIcon />
              <Box w="100%" h="100%">
                <AlertTitle>
                  Locations can be nested within departments!
                </AlertTitle>
                <AlertDescription w="100%" h="100%">
                  <HStack w="100%" h="100%" alignItems="start" mt={4}>
                    <UnorderedList
                      w="100%"
                      p={4}
                      pl={6}
                      ml={0}
                      h="100%"
                      borderRadius="lg"
                      bgColor={colorMode === "dark" ? "gray.900" : "gray.100"}
                    >
                      <ListItem>Sales / Kia / New Cars</ListItem>
                      <ListItem>Sales / Kia / Used Cars</ListItem>
                      <ListItem>Sales / Honda / New Cars</ListItem>
                      <ListItem>Sales / Honda / Used Cars</ListItem>
                    </UnorderedList>
                    <Icon alignSelf="center" as={ArrowRightIcon} boxSize={10} />
                    <HStack
                      w="100%"
                      position="relative"
                      h="100%"
                      justifyContent="space-between"
                      p={4}
                      borderRadius="lg"
                      bgColor={colorMode === "dark" ? "gray.900" : "gray.100"}
                    >
                      <Text
                        _after={{
                          content: '""',
                          display: "block",
                          width: "10%",
                          height: "1px",
                          backgroundColor:
                            colorMode === "dark" ? "white" : "black",
                          position: "absolute",
                          top: "40%",
                          left: "30%",
                          transform: "translate(-50%, -50%) rotate(140deg)",
                        }}
                        _before={{
                          content: '""',
                          display: "block",
                          width: "10%",
                          height: "1px",
                          backgroundColor:
                            colorMode === "dark" ? "white" : "black",
                          position: "absolute",
                          top: "60%",
                          left: "30%",
                          transform: "translate(-50%, -50%) rotate(40deg)",
                        }}
                      >
                        Sales
                      </Text>
                      <VStack
                        w="100"
                        h="100%"
                        spacing={2}
                        justifyContent="space-evenly"
                      >
                        <HStack
                          w="100%"
                          h="100%"
                          justifyContent="space-between"
                          position="relative"
                        >
                          <Text
                            _after={{
                              content: '""',
                              display: "block",
                              width: "30%",
                              height: "1px",
                              backgroundColor:
                                colorMode === "dark" ? "white" : "black",
                              position: "absolute",
                              top: "30%",
                              left: "30%",
                              transform: "translate(-50%, -50%) rotate(165deg)",
                            }}
                            _before={{
                              content: '""',
                              display: "block",
                              width: "20%",
                              height: "1px",
                              backgroundColor:
                                colorMode === "dark" ? "white" : "black",
                              position: "absolute",
                              top: "70%",
                              left: "30%",
                              transform: "translate(-50%, -50%) rotate(15deg)",
                            }}
                          >
                            Kia
                          </Text>
                          <VStack w="100%" h="100%" alignItems="end">
                            <Text>New Cars</Text>
                            <Text>Used Cars</Text>
                          </VStack>
                        </HStack>
                        <HStack
                          w="100%"
                          h="100%"
                          justifyContent="space-between"
                          position="relative"
                        >
                          <Text
                            _after={{
                              content: '""',
                              display: "block",
                              width: "30%",
                              height: "1px",
                              backgroundColor:
                                colorMode === "dark" ? "white" : "black",
                              position: "absolute",
                              top: "30%",
                              left: "30%",
                              transform: "translate(-50%, -50%) rotate(165deg)",
                            }}
                            _before={{
                              content: '""',
                              display: "block",
                              width: "20%",
                              height: "1px",
                              backgroundColor:
                                colorMode === "dark" ? "white" : "black",
                              position: "absolute",
                              top: "75%",
                              left: "30%",
                              transform: "translate(-50%, -50%) rotate(15deg)",
                            }}
                          >
                            Honda
                          </Text>
                          <VStack w="100%" h="100%" alignItems="end">
                            <Text>New Cars</Text>
                            <Text>Used Cars</Text>
                          </VStack>
                        </HStack>
                      </VStack>
                    </HStack>
                  </HStack>
                </AlertDescription>
              </Box>
            </Alert>
            <FormLabel>Location Name</FormLabel>
            <Input
              value={currentWidgetConfig.locationName || ""}
              onChange={(e) =>
                setCurrentWidgetConfig({
                  ...currentWidgetConfig,
                  locationName: e.target.value,
                })
              }
            />
          </FormControl>
          <FormControl>
            <FormLabel>Primary Colour</FormLabel>
            <Input
              value={currentWidgetConfig.primaryColour || ""}
              onChange={(e) =>
                setCurrentWidgetConfig({
                  ...currentWidgetConfig,
                  primaryColour: e.target.value,
                })
              }
            />
          </FormControl>
          <FormControl>
            <FormLabel>Secondary Colour</FormLabel>
            <Input
              value={currentWidgetConfig.secondaryColour || ""}
              onChange={(e) =>
                setCurrentWidgetConfig({
                  ...currentWidgetConfig,
                  secondaryColour: e.target.value,
                })
              }
            />
          </FormControl>
          <FormControl>
            <FormLabel>External Contact Form URL</FormLabel>
            <Input
              value={currentWidgetConfig.externalContactFormUrl || ""}
              onChange={(e) =>
                setCurrentWidgetConfig({
                  ...currentWidgetConfig,
                  externalContactFormUrl: e.target.value,
                })
              }
            />
          </FormControl>
          <FormControl>
            <FormLabel>Button Title</FormLabel>
            <Input
              value={currentWidgetConfig.buttonTitle || ""}
              onChange={(e) =>
                setCurrentWidgetConfig({
                  ...currentWidgetConfig,
                  buttonTitle: e.target.value,
                })
              }
            />
          </FormControl>
          <FormControl>
            <FormLabel>Default Text</FormLabel>
            <Input
              value={currentWidgetConfig.defaultText || ""}
              onChange={(e) =>
                setCurrentWidgetConfig({
                  ...currentWidgetConfig,
                  defaultText: e.target.value,
                })
              }
            />
          </FormControl>
          <FormControl>
            <FormLabel>Successful Enquiry Title</FormLabel>
            <Input
              value={currentWidgetConfig.successTitle || ""}
              onChange={(e) =>
                setCurrentWidgetConfig({
                  ...currentWidgetConfig,
                  successTitle: e.target.value,
                })
              }
            />
          </FormControl>
          <FormControl>
            <FormLabel>Successful Enquiry Subtitle</FormLabel>
            <Input
              value={currentWidgetConfig.successSubtitle || ""}
              onChange={(e) =>
                setCurrentWidgetConfig({
                  ...currentWidgetConfig,
                  successSubtitle: e.target.value,
                })
              }
            />
          </FormControl>
          <FormControl>
            <FormLabel>Hide Powered By</FormLabel>
            <Checkbox
              value="true"
              defaultChecked={currentWidgetConfig.hidePoweredBy === true}
              isChecked={currentWidgetConfig.hidePoweredBy === true}
              onChange={(e) =>
                setCurrentWidgetConfig({
                  ...currentWidgetConfig,
                  hidePoweredBy: e.target.checked,
                })
              }
            />
          </FormControl>
          <FormControl>
            <FormLabel>Hide Contact Form</FormLabel>
            <Checkbox
              value="true"
              defaultChecked={currentWidgetConfig.hideContactForm === true}
              isChecked={currentWidgetConfig.hideContactForm === true}
              onChange={(e) =>
                setCurrentWidgetConfig({
                  ...currentWidgetConfig,
                  hideContactForm: e.target.checked,
                })
              }
            />
          </FormControl>
          <FormControl>
            <FormLabel>Prevent Auto Open</FormLabel>
            <Checkbox
              value="true"
              defaultChecked={currentWidgetConfig.preventAutoOpen === true}
              isChecked={currentWidgetConfig.preventAutoOpen === true}
              onChange={(e) =>
                setCurrentWidgetConfig({
                  ...currentWidgetConfig,
                  preventAutoOpen: e.target.checked,
                })
              }
            />
          </FormControl>
          <FormControl>
            <FormLabel>Auto Open Delay</FormLabel>
            <Input
              type="number"
              value={currentWidgetConfig.autoOpenDelay || ""}
              onChange={(e) =>
                setCurrentWidgetConfig({
                  ...currentWidgetConfig,
                  autoOpenDelay: +e.target.value,
                })
              }
            />
          </FormControl>
          <FormControl>
            <FormLabel>
              Custom Channels{" "}
              <ChakraButton
                variant="unstyled"
                onClick={() => setCustomChannelsEditOpen(true)}
              >
                <EditIcon />
              </ChakraButton>
            </FormLabel>
            {currentWidgetConfig.customChannels && (
              <Table variant="striped">
                <Thead>
                  <Tr>
                    <Th>Channel</Th>
                    <Th>Handle</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {currentWidgetConfig.customChannels?.map((customChannel) => (
                    <Tr>
                      <Td>{customChannel.type}</Td>
                      <Td>
                        {customChannel.disabled
                          ? "Disabled"
                          : customChannel.handle}
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            )}
            {!currentWidgetConfig.customChannels && (
              <Text>No custom channels</Text>
            )}
          </FormControl>
          <FormControl>
            <FormLabel>Logo</FormLabel>
            {currentWidgetConfig.logoUrl && (
              <>
                <Box
                  backgroundColor={
                    currentWidgetConfig.primaryColour || "initial"
                  }
                >
                  <Image src={currentWidgetConfig.logoUrl} maxH="100px" />
                </Box>
                <HStack spacing={3}>
                  <Checkbox
                    value="true"
                    onChange={(e) =>
                      setCurrentWidgetConfig({
                        ...currentWidgetConfig,
                        removeLogo: e.target.checked,
                      })
                    }
                  />
                  <Text as="span">Remove</Text>
                </HStack>
              </>
            )}
            <Box pt={5}>
              <input
                ref={fileInput}
                type="file"
                accept={"image/*"}
                onChange={onFileChange}
              />
            </Box>
          </FormControl>

          <FormControl>
            <FormLabel>Button Image</FormLabel>
            {currentWidgetConfig.buttonImageUrl && (
              <>
                <Box
                  backgroundColor={
                    currentWidgetConfig.primaryColour || "initial"
                  }
                >
                  <Image src={currentWidgetConfig.buttonImageUrl} maxH="50px" />
                </Box>
                <HStack spacing={3}>
                  <Checkbox
                    value="true"
                    onChange={(e) =>
                      setCurrentWidgetConfig({
                        ...currentWidgetConfig,
                        removeButtonImage: e.target.checked,
                      })
                    }
                  />
                  <Text as="span">Remove</Text>
                </HStack>
              </>
            )}
            <Box pt={5}>
              <input
                ref={buttonImageInput}
                type="file"
                accept={"image/*"}
                onChange={onButtonImageChange}
              />
            </Box>
          </FormControl>

          <HStack justify="center">
            <Button
              type={ButtonType.PRIMARY}
              onClick={handlePreview}
              isDisabled={!selectedMerchant}
              mt={30}
              mb={30}
              size={ButtonSize.LARGE}
            >
              Preview
            </Button>

            <Button
              type={ButtonType.PRIMARY}
              onClick={handleSaveConfig}
              isDisabled={!selectedMerchant}
              mt={30}
              mb={30}
              size={ButtonSize.LARGE}
            >
              {selectedWidget?.id === "new" ? "Create" : "Save"}
            </Button>
          </HStack>
        </>
      )}
      <CustomChannelsEdit
        isOpen={customChannelsEditOpen}
        onClose={() => {
          setCustomChannelsEditOpen(false);
        }}
        onSave={(customChannels) => {
          setCurrentWidgetConfig({
            ...currentWidgetConfig,
            customChannels,
          } as WidgetConfigDomain);
        }}
        widgetCustomChannels={currentWidgetConfig?.customChannels}
        channelsAvailable={selectedMerchant?.channels || []}
      />
    </Stack>
  );
};

export default WidgetConfig;
