/* eslint-disable import/prefer-default-export */
import { OptionTypes } from "components/shared/filter";
import { Color } from "theme/old-design-system/styled-components";
import TagsDomain from "entities/domain/tags/tags-domain";
import { colorList } from "util/constants";
import { TagDTO } from "entities/dto/TagDTO";

export const newTagTransformFromToOptions = (tag: string): OptionTypes => ({
  value: tag,
  label: tag,
  color: Color.LIGHT_GREY.value,
});

export const tagToTagOption = (tag: TagsDomain): OptionTypes => ({
  value: tag.id,
  label: tag.tag,
  color: tag.color,
});

export const tagTransformFromDtoToDomain = (
  tagDto: TagDTO,
  index: number
): TagsDomain => {
  const color = colorList[index % colorList.length].value;

  return new TagsDomain(tagDto.name, color, tagDto.id);
};
