import UserGuideActionsDomain from "./user-guide-actions";

export enum UserGuideStatus {
  PENDING = "pending",
  COMPLETED = "completed",
}

export default class UserGuidesDomain {
  status: UserGuideStatus;

  actions: UserGuideActionsDomain[];

  constructor(status: UserGuideStatus, actions: UserGuideActionsDomain[]) {
    this.status = status;
    this.actions = actions;
  }
}
