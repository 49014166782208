import { Box, Button, Flex, Text, useBreakpointValue } from "@chakra-ui/react";
import MessageDomain, {
  MessageStatus,
} from "entities/domain/conversations/message-domain";
import Linkify from "linkify-react";
import React, { memo, useLayoutEffect, useRef, useState } from "react";

interface ExpandableTextProps {
  message: MessageDomain;
  shouldLinkify?: boolean;
}

const ExpandableText: React.FC<ExpandableTextProps> = ({
  message,
  shouldLinkify = false,
}) => {
  const isBaseSize = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );
  const textRef = useRef<HTMLPreElement>(null);

  const [isCollapsed, setIsCollapsed] = useState(true);
  const [isLongText, setIsLongText] = useState(false);

  useLayoutEffect(() => {
    const { current } = textRef;

    if (!current) {
      setIsLongText(false);
      return;
    }

    const trigger = () => {
      const hasOverflow = current.scrollHeight > current.clientHeight;

      setIsLongText(hasOverflow);
    };

    if ("ResizeObserver" in window) {
      new ResizeObserver(trigger).observe(current);
    }

    trigger();
  }, [textRef]);

  return (
    <Box position="relative">
      <Flex
        direction="column"
        alignItems="start"
        justifyContent="center"
        __css={{
          ...(isCollapsed && isLongText
            ? {
                maskImage:
                  "linear-gradient(to bottom, black 50%, transparent 100%)",
              }
            : {}),
        }}
      >
        <Text
          ref={textRef}
          sx={{
            ".message-channel-link": {
              textDecoration: "underline",
              wordBreak: "break-all",
            },
          }}
          fontWeight={400}
          color={message.getFontColor()}
          fontSize="sm"
          as="pre"
          fontFamily="inherit"
          whiteSpace="pre-wrap"
          overflowY="hidden"
          maxHeight={isCollapsed ? "150px" : "initial"}
        >
          {shouldLinkify ? (
            <Linkify
              options={{
                target: "_blank",
                rel: "noreferrer",
                className: "message-channel-link",
              }}
            >
              {message?.body}
            </Linkify>
          ) : (
            message?.body
          )}
        </Text>
      </Flex>
      {isLongText && isCollapsed && (
        <Flex
          justifyContent="center"
          alignItems="center"
          w="100%"
          position="absolute"
          bottom={0}
        >
          <Button
            size="xs"
            colorScheme={message.isIncoming ? "blackAlpha" : "whiteAlpha"}
            w={isBaseSize ? "100%" : "auto"}
            onClick={() => setIsCollapsed(false)}
          >
            Show more
          </Button>
        </Flex>
      )}
    </Box>
  );
};

export default memo(ExpandableText);
