import { Auth0ContextInterface } from "@auth0/auth0-react";
import KeyloopAccount from "entities/domain/keyloop-account";
import KeyloopPrivacyStatement from "entities/domain/keyloop-privacy-statement";
import KeyloopRepairOrder from "entities/domain/keyloop-repair-order";
import KeyloopUser from "entities/domain/keyloop-user";
import WhatsappProfile, {
  WhatsappBusinessVertical,
} from "entities/domain/whatsapp";
import { KeyloopAccountDTO } from "entities/dto/KeyloopAccountDTO";
import { KeyloopPrivacyStatementDTO } from "entities/dto/KeyloopPrivacyStatementDTO";
import { KeyloopRepairOrderDTO } from "entities/dto/KeyloopRepairOrderDTO";
import { KeyloopUserDTO } from "entities/dto/KeyloopUserDTO";
import { WhatsappProfileDTO } from "entities/dto/WhatsappProfileDTO";
import { keyloopAccountTransformFromDtoToDomain } from "entities/transformers/keyloop-account-transformer";
import { keyloopPrivacyStatementTransformFromDtoToDomain } from "entities/transformers/keyloop-privacy-statement-transformer";
import { keyloopRepairOrderTransformFromDtoToDomain } from "entities/transformers/keyloop-repair-order-transformer";
import { keyloopUserTransformFromDtoToDomain } from "entities/transformers/keyloop-user-transformer";
import { whatsappProfileTransformFromDtoToDomain } from "entities/transformers/whatsappProfileTransformer";
import { RequestType } from "services/request-type";
import {
  deleteRequest,
  mutationRequest,
  patchRequest,
  putRequest,
  request,
} from "util/methods";

export interface UpdateWhatsappInfoPayload {
  email: string;
  vertical?: WhatsappBusinessVertical;
  profilePicture?: File;
  aboutText: string;
  address: string;
  description: string;
  websites: string[];
}

export default class IntegrationService {
  public static async removeStripeIntegration(
    { getAccessTokenSilently }: Auth0ContextInterface,
    merchantId: number
  ) {
    const accessToken = await getAccessTokenSilently();

    await deleteRequest(
      RequestType.DELETE,
      accessToken,
      `/merchants/${merchantId}/stripe/account`
    );
  }

  public static async removeGoogleIntegration(
    { getAccessTokenSilently }: Auth0ContextInterface,
    merchantId: number
  ) {
    const accessToken = await getAccessTokenSilently();

    await deleteRequest(
      RequestType.DELETE,
      accessToken,
      `/merchants/${merchantId}/google/authorize`
    );
  }

  public static async removeGmailIntegration(
    { getAccessTokenSilently }: Auth0ContextInterface,
    merchantId: number
  ) {
    const accessToken = await getAccessTokenSilently();

    await deleteRequest(
      RequestType.DELETE,
      accessToken,
      `merchants/${merchantId}/google/authorize/gmail`
    );
  }

  public static async removeMicrosoftOutlookIntegration(
    { getAccessTokenSilently }: Auth0ContextInterface,
    merchantId: number
  ) {
    const accessToken = await getAccessTokenSilently();

    await deleteRequest(
      RequestType.DELETE,
      accessToken,
      `/merchants/${merchantId}/microsoft/authorize/outlook`
    );
  }

  public static async removeXeroIntegration(
    { getAccessTokenSilently }: Auth0ContextInterface,
    merchantId: number
  ) {
    const accessToken = await getAccessTokenSilently();

    await deleteRequest(
      RequestType.DELETE,
      accessToken,
      `/merchants/${merchantId}/xero/authorize`
    );
  }

  public static async removeQuickbooksIntegration(
    { getAccessTokenSilently }: Auth0ContextInterface,
    merchantId: number
  ) {
    const accessToken = await getAccessTokenSilently();

    await deleteRequest(
      RequestType.DELETE,
      accessToken,
      `/merchants/${merchantId}/quickbooks/authorize`
    );
  }

  public static async getKeyloopAccounts(
    { getAccessTokenSilently }: Auth0ContextInterface,
    merchantId: number
  ): Promise<KeyloopAccount[]> {
    const accessToken = await getAccessTokenSilently();

    const response = (
      await request<KeyloopAccountDTO[]>(
        RequestType.GET,
        accessToken,
        `/merchants/${merchantId}/keyloop/accounts`
      )
    ).data;

    return response.map(keyloopAccountTransformFromDtoToDomain);
  }

  public static async disconnectKeyloopAccount(
    { getAccessTokenSilently }: Auth0ContextInterface,
    merchantId: number,
    enterpriseId: string,
    storeId: string
  ): Promise<void> {
    const accessToken = await getAccessTokenSilently();

    await deleteRequest(
      RequestType.DELETE,
      accessToken,
      `/merchants/${merchantId}/keyloop/accounts/${enterpriseId}/${storeId}`
    );
  }

  public static async getKeyloopPrivacyStatements(
    { getAccessTokenSilently }: Auth0ContextInterface,
    merchantId: number,
    enterpriseId: string,
    storeId: string
  ): Promise<KeyloopPrivacyStatement[]> {
    const accessToken = await getAccessTokenSilently();

    const response = (
      await request<KeyloopPrivacyStatementDTO[]>(
        RequestType.GET,
        accessToken,
        `/merchants/${merchantId}/keyloop/accounts/${enterpriseId}/${storeId}/privacy-statements`
      )
    ).data;

    return response.map(keyloopPrivacyStatementTransformFromDtoToDomain);
  }

  public static async getWhatsappInfo(
    { getAccessTokenSilently }: Auth0ContextInterface,
    merchantId: number
  ): Promise<WhatsappProfile> {
    const accessToken = await getAccessTokenSilently();

    const response = (
      await request<WhatsappProfileDTO>(
        RequestType.GET,
        accessToken,
        `/merchants/${merchantId}/whatsapp-profile`
      )
    ).data;

    return whatsappProfileTransformFromDtoToDomain(response);
  }

  public static async updateWhatsappInfo(
    { getAccessTokenSilently }: Auth0ContextInterface,
    merchantId: number,
    payload: UpdateWhatsappInfoPayload
  ): Promise<void> {
    const accessToken = await getAccessTokenSilently();
    const {
      email,
      vertical,
      profilePicture,
      aboutText,
      address,
      description,
      websites,
    } = payload;

    const formData = new FormData();

    formData.append("email", email);

    if (vertical) {
      formData.append("vertical", vertical);
    }

    formData.append("about_text", aboutText);
    formData.append("address", address);
    formData.append("description", description);
    formData.append("websites", JSON.stringify(websites));

    if (profilePicture) {
      formData.append("profile_picture", profilePicture);
    }

    await mutationRequest(
      RequestType.PUT,
      accessToken,
      `/merchants/${merchantId}/whatsapp-profile`,
      formData,
      "multipart/form-data"
    );
  }

  public static async updateKeyloopAccount(
    { getAccessTokenSilently }: Auth0ContextInterface,
    keyloopPrivacyStatementId: string,
    merchantId: number,
    enterpriseId: string,
    storeId: string,
    userId: string
  ): Promise<void> {
    const accessToken = await getAccessTokenSilently();

    await patchRequest(
      RequestType.PATCH,
      accessToken,
      `/merchants/${merchantId}/keyloop/accounts/${enterpriseId}/${storeId}`,
      {
        privacy_statement_id: keyloopPrivacyStatementId,
        user_id: userId,
      }
    );
  }

  public static async getKeyloopUsers(
    { getAccessTokenSilently }: Auth0ContextInterface,
    merchantId: number,
    enterpriseId: string,
    storeId: string
  ): Promise<KeyloopUser[]> {
    const accessToken = await getAccessTokenSilently();

    const users = (
      await request<KeyloopUserDTO[]>(
        RequestType.GET,
        accessToken,
        `/merchants/${merchantId}/keyloop/accounts/${enterpriseId}/${storeId}/users`
      )
    ).data;

    return users.map(keyloopUserTransformFromDtoToDomain);
  }

  public static async getKeyloopRepairOrders(
    { getAccessTokenSilently }: Auth0ContextInterface,
    merchantId: number,
    enterpriseId: string,
    storeId: string,
    status?: string,
    dateFrom?: string | undefined,
    dateTo?: string | undefined,
    page?: number | undefined,
    limit?: number | undefined
  ): Promise<KeyloopRepairOrder[]> {
    const accessToken = await getAccessTokenSilently();

    const queryParams = new URLSearchParams();
    if (status) {
      queryParams.append("status", status);
    }
    if (dateFrom) {
      queryParams.append("due_in_date_from", dateFrom);
    }
    if (dateTo) {
      queryParams.append("due_in_date_to", dateTo);
    }
    if (page) {
      queryParams.append("page", page.toString());
    }
    if (limit) {
      queryParams.append("page_size", limit.toString());
    }

    const queryString = queryParams.toString();

    const orders = (
      await request<KeyloopRepairOrderDTO[]>(
        RequestType.GET,
        accessToken,
        `/merchants/${merchantId}/keyloop/accounts/${enterpriseId}/${storeId}/repair-orders?${queryString}`
      )
    ).data;

    return orders.map(keyloopRepairOrderTransformFromDtoToDomain);
  }
}
