import { StyleConfig } from "@chakra-ui/theme-tools";

const PopoverStyles = {
  baseStyle: {
    popper: {
      zIndex: 9999,
    },
  },
} as StyleConfig;

export default PopoverStyles;
