import { useColorMode } from "@chakra-ui/color-mode";
import { FormHelperText } from "@chakra-ui/react";
import { FormControl, FormLabel } from "@chakra-ui/form-control";
import { Input, InputGroup, InputRightAddon } from "@chakra-ui/input";
import { Popover, PopoverContent, PopoverTrigger } from "@chakra-ui/popover";
import React, { Dispatch, SetStateAction, memo, useState } from "react";
import data from "@emoji-mart/data";
import Picker from "@emoji-mart/react";
import { ReactComponent as EmojiButtonIcon } from "assets/icons/fi-rr-smile.svg";
import { useAppSelector } from "redux/hooks";
import { Portal } from "@chakra-ui/portal";
import { IconButton } from "@chakra-ui/button";
import { Icon } from "@chakra-ui/icon";
import { Textarea } from "@chakra-ui/textarea";

interface TeamFormProps {
  teamName: string;
  setTeamName: Dispatch<SetStateAction<string>>;
  teamPurpose: string;
  setTeamPurpose: Dispatch<SetStateAction<string>>;
  isLoading: boolean;
}

const TeamForm = ({
  teamName,
  setTeamName,
  teamPurpose,
  setTeamPurpose,
  isLoading,
}: TeamFormProps) => {
  const { colorMode } = useColorMode();
  const { colorScheme } = useAppSelector((state) => state.theme);

  const [openEmojiPopover, setOpenEmojiPopover] = useState<boolean>(false);

  return (
    <>
      <FormControl>
        <FormLabel>Name</FormLabel>
        <InputGroup>
          <Input
            type="text"
            value={teamName}
            placeholder="Sales 👨‍💼"
            onChange={(e) => setTeamName(e.target.value)}
            isDisabled={isLoading}
          />
          <InputRightAddon
            px={2}
            py={0}
            borderEndRadius="full"
            bgColor={colorMode === "dark" ? "gray.900" : "gray.50"}
            borderWidth={0}
          >
            <Popover
              closeOnBlur={true}
              isOpen={openEmojiPopover}
              onClose={() => {
                setOpenEmojiPopover(false);
              }}
              isLazy={true}
              lazyBehavior="unmount"
            >
              <Portal>
                <PopoverContent
                  width="100%"
                  onWheel={(e) => {
                    e.stopPropagation();
                  }}
                >
                  <Picker
                    data={data}
                    theme={colorMode}
                    onEmojiSelect={({ native }: { native: string }) => {
                      setTeamName((prev: string) => {
                        if (prev.length === 0) {
                          return native;
                        }

                        return prev + native;
                      });
                    }}
                  />
                </PopoverContent>
              </Portal>

              <PopoverTrigger>
                <IconButton
                  size="xs"
                  variant="unstyled"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  colorScheme={colorScheme}
                  isDisabled={isLoading}
                  onClick={() => {
                    setOpenEmojiPopover(!openEmojiPopover);
                  }}
                  aria-label="Pick emoji"
                  icon={
                    <Icon
                      as={EmojiButtonIcon}
                      __css={{
                        height: "1.25rem",
                        width: "1.25rem",
                        g: {
                          path: {
                            fill:
                              colorMode === "dark"
                                ? "yellow.200"
                                : "yellow.500",
                          },
                        },
                      }}
                    />
                  }
                />
              </PopoverTrigger>
            </Popover>
          </InputRightAddon>
        </InputGroup>
      </FormControl>
      <FormControl mt={2}>
        <FormLabel>Purpose</FormLabel>
        <FormHelperText mb={2}>
          Explain to us what kind of enquiries this team wants to handle
        </FormHelperText>
        <Textarea
          bgColor={colorMode === "dark" ? "gray.900" : "gray.50"}
          value={teamPurpose}
          onChange={(e) => setTeamPurpose(e.target.value)}
          size="sm"
          borderRadius="lg"
          borderWidth={0}
          isDisabled={isLoading}
        />
      </FormControl>
    </>
  );
};

export default memo(TeamForm);
