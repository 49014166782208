import { Button, Icon, Tooltip, VStack, useColorMode } from "@chakra-ui/react";
import React from "react";
import { ReactComponent as ReplyIcon } from "assets/icons/reply-svgrepo-com.svg";
import { ExternalLinkIcon, PhoneIcon } from "@chakra-ui/icons";
import {
  WhatsappButton,
  WhatsappButtonType,
} from "entities/domain/whatsapp-template";

interface ButtonsPreviewProps {
  buttons: WhatsappButton[];
  forceColorMode?: "light" | "dark";
}

const ButtonsPreview = ({ buttons, forceColorMode }: ButtonsPreviewProps) => {
  const { colorMode } = useColorMode();

  return buttons.length === 0 ? null : (
    <VStack width="100%" spacing={0} flexGrow={0}>
      {buttons.map((button, index) => (
        <Tooltip
          key={index}
          label={
            button.type === WhatsappButtonType.PHONE_NUMBER
              ? button.phoneNumber
              : button.type === WhatsappButtonType.URL
              ? button.url
              : ""
          }
          isDisabled={button.type === WhatsappButtonType.QUICK_REPLY}
        >
          <Button
            zIndex={5} // To prevent the button from being covered by the tail
            width="100%"
            variant="ghost"
            display="flex"
            textTransform="none"
            alignItems="center"
            colorScheme="gray" // Adequately changes color text
            borderTopRadius={0}
            borderBottomRadius={index === buttons.length - 1 ? "xl" : 0}
            borderColor={
              (forceColorMode || colorMode) === "dark" ? "white" : "gray.200"
            }
            borderTopWidth={index === 0 ? "1px" : 0}
            borderBottomWidth={index === buttons.length - 1 ? 0 : "1px"}
            leftIcon={
              <Icon
                as={
                  button.type === WhatsappButtonType.QUICK_REPLY
                    ? ReplyIcon
                    : button.type === WhatsappButtonType.PHONE_NUMBER
                    ? PhoneIcon
                    : ExternalLinkIcon
                }
                __css={
                  button.type === WhatsappButtonType.QUICK_REPLY
                    ? {
                        path: {
                          fill:
                            (forceColorMode || colorMode) === "dark"
                              ? "white"
                              : "black",
                        },
                      }
                    : {}
                }
              />
            }
          >
            {button.text}
          </Button>
        </Tooltip>
      ))}
    </VStack>
  );
};

export default ButtonsPreview;
