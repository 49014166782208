import AttachmentDomain from "entities/domain/attachments/attachment-domain";
import { AttachmentDto } from "entities/dto/AttachmentDTO";

export const attachmentTransformFromDtoToDomain = (
  attachment: AttachmentDto
): AttachmentDomain =>
  new AttachmentDomain(
    attachment.id,
    attachment.message_id,
    attachment.conversation_id,
    attachment.url,
    attachment.type,
    attachment.created_at
  );
