export default class MerchantTierLimitsDomain {
  monthlyOutgoingSmsMessages: number | undefined;
  monthlyCampaignMessages: number | undefined;

  constructor(
    monthlyOutgoingSmsMessages: number,
    monthlyCampaignMessages: number
  ) {
    this.monthlyCampaignMessages = monthlyCampaignMessages;
    this.monthlyOutgoingSmsMessages = monthlyOutgoingSmsMessages;
  }
}
