import {
  Box,
  Button,
  Flex,
  FormLabel,
  Icon,
  IconButton,
  Input,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Portal,
  useBreakpointValue,
  useColorMode,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import CustomField from "components/user-settings/shared/CustomField";
import { ReactComponent as PaperClipButtonIcon } from "assets/icons/paperclip-light.svg";
import { ReactComponent as EmojiButtonIcon } from "assets/icons/fi-rr-smile.svg";
import React, { memo, useRef, useState } from "react";
import data from "@emoji-mart/data";
import Picker from "@emoji-mart/react";
import { useAppSelector, useAppDispatch } from "redux/hooks";
import { clearAttachments } from "redux/features/attachments";
import { maxAllowedSize } from "util/methods";
import FileDomain from "entities/domain/file";
import FileSelector from "components/shared/FileSelector";

interface MessageInputToolbarProps {
  insertIntoText: (text: string) => void;
  insertEmoji: (emoji: { native: string }) => void;
  customFields?: CustomFieldDefinition[];
  addAttachment?: (file: FileDomain) => Promise<void>;
}

export interface CustomFieldDefinition {
  key: string;
  value: string;
  description: string;
}

const MessageInputToolbar = ({
  insertIntoText,
  insertEmoji,
  customFields,
  addAttachment,
}: MessageInputToolbarProps) => {
  const { colorMode } = useColorMode();
  const toast = useToast();
  const { colorScheme } = useAppSelector((state) => state.theme);
  const dispatch = useAppDispatch();
  const isBaseSize = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );
  const [openEmojiPopover, setOpenEmojiPopover] = useState<boolean>(false);
  const [isCustomFieldsPopoverOpen, setIsCustomFieldsPopoverOpen] =
    useState<boolean>(false);

  const fileSelector = useDisclosure();

  return (
    <Flex
      justifyContent="end"
      alignItems="center"
      w="100%"
      gridGap={4}
      sx={{
        "> button": {
          display: "flex",
        },
      }}
    >
      <Popover
        closeOnBlur={true}
        isOpen={isCustomFieldsPopoverOpen}
        onClose={() => {
          setIsCustomFieldsPopoverOpen(false);
        }}
      >
        <Portal>
          <PopoverContent>
            <Box p={6}>
              {customFields ? (
                customFields.map((cf) => (
                  <CustomField
                    key={cf.key}
                    onClick={() => {
                      insertIntoText(`${cf.value}`);
                    }}
                    fieldValue={cf.description}
                    fieldKey={cf.key}
                  />
                ))
              ) : (
                <CustomField
                  key="customer_first_name"
                  onClick={() => {
                    insertIntoText("{customer_first_name}");
                  }}
                  fieldValue="Customer First Name"
                  fieldKey="customer_first_name"
                />
              )}
            </Box>
          </PopoverContent>
        </Portal>

        <PopoverTrigger>
          <Button
            variant="unstyled"
            minWidth="unset"
            minHeight="unset"
            backgroundColor={colorMode === "dark" ? "gray.700" : "white"}
            borderStyle="solid"
            borderWidth="2px"
            borderColor={colorMode === "dark" ? "green.300" : "green.600"}
            color={colorMode === "dark" ? "green.300" : "green.600"}
            borderRadius="full"
            height="1.75rem"
            width="1.75rem"
            textAlign="center"
            fontWeight={800}
            onClick={() => {
              setIsCustomFieldsPopoverOpen(true);
            }}
          >
            {"{}"}
          </Button>
        </PopoverTrigger>
      </Popover>
      {isBaseSize ? null : (
        <Popover
          closeOnBlur={true}
          isOpen={openEmojiPopover}
          onClose={() => {
            setOpenEmojiPopover(false);
          }}
        >
          <Portal>
            <PopoverContent>
              <Picker
                data={data}
                theme={colorMode}
                onEmojiSelect={insertEmoji}
              />
            </PopoverContent>
          </Portal>

          <PopoverTrigger>
            <Button
              variant="unstyled"
              colorScheme={colorScheme}
              onClick={() => {
                setOpenEmojiPopover(!openEmojiPopover);
              }}
            >
              <Icon
                as={EmojiButtonIcon}
                __css={{
                  height: "1.75rem",
                  width: "1.75rem",
                  g: {
                    path: {
                      fill: "yellow.600",
                    },
                  },
                }}
              />
            </Button>
          </PopoverTrigger>
        </Popover>
      )}
      {typeof addAttachment !== "undefined" ? (
        <FormLabel
          htmlFor="file-input-message-toolbar"
          _hover={{
            cursor: "pointer",
          }}
          m={0}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <IconButton
            icon={
              <Icon
                as={PaperClipButtonIcon}
                __css={{
                  height: "1.25rem",
                  width: "1.25rem",
                  path: {
                    fill: colorMode === "dark" ? "gray.300" : "gray.600",
                    fillOpacity: 1,
                  },
                  g: {
                    opacity: 1,
                  },
                }}
              />
            }
            aria-label="Attach File"
            variant="ghost"
            colorScheme={colorScheme}
            onClick={fileSelector.onOpen}
          />
          <FileSelector
            isOpen={fileSelector.isOpen}
            onClose={fileSelector.onClose}
            onFileSelect={async (selectedFile) => {
              if (selectedFile) {
                await addAttachment(selectedFile);
                fileSelector.onClose();
              }
            }}
          />
        </FormLabel>
      ) : null}
    </Flex>
  );
};

export default memo(MessageInputToolbar);
