import React, { ReactNode } from "react";
import { Box, Heading } from "@chakra-ui/react";

interface FormGroupProps {
  title: string;
  children: ReactNode;
}

const FormGroup = ({ title, children }: FormGroupProps) => (
  <Box padding="32px 24px" backdropFilter="blur(50px)" borderRadius="15px">
    <Heading as="h3" size="md" pb={4}>
      {title}
    </Heading>
    <div>{children}</div>
  </Box>
);

export default FormGroup;
