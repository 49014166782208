import AgentPermissionsDomain from "./agent-permissions-domain";

export enum AgentRole {
  ADMIN = "admin",
  MANAGER = "manager",
  SUPPORT = "support",
  FUZEY = "fuzey",
}

export default class AgentRolesDomain {
  id: number;

  name: AgentRole;

  description: string;

  permissions: AgentPermissionsDomain[];

  constructor(
    id: number,
    name: AgentRole,
    description: string,
    permissions: AgentPermissionsDomain[]
  ) {
    this.id = id;
    this.name = name;
    this.description = description;
    this.permissions = permissions;
  }

  public isPermissionEnabled(permissionId: number): boolean {
    return this.permissions.map((perm) => perm.id).includes(permissionId);
  }

  public assignedPermissions(permission: AgentPermissionsDomain) {
    this.permissions.push(permission);
  }

  public unassignedPermissions(permission: AgentPermissionsDomain) {
    this.permissions = this.permissions.filter(
      (perm) => perm.id !== permission.id
    );
  }

  public getRoleDescription(): string {
    switch (this.name) {
      case AgentRole.ADMIN:
        return "Carry out anything in the platform";
      case AgentRole.MANAGER:
        return "Carry out most actions in the platform";
      case AgentRole.SUPPORT:
        return "Carry out limited actions in the platform";
      case AgentRole.FUZEY:
        return "Fuzey super user";
      default:
        return "";
    }
  }
}
