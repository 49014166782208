import { Button, Icon } from "@chakra-ui/react";
import Integration from "components/user-settings/shared/Integration";
import { SettingsIcon } from "@chakra-ui/icons";
import { ReactComponent as KeyloopIcon } from "assets/icons/Keyloop-Logo-RGB-Carbon.svg";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "redux/hooks";
import IntegrationDescription from "components/user-settings/shared/IntegrationDescription";
import { IntegrationName } from "entities/domain/admin/merchants/merchant-integrations";

interface KeyloopIntegrationItemProps {}

const KeyloopIntegrationItem = (_props: KeyloopIntegrationItemProps) => {
  const merchant = useAppSelector((state) => state.merchant.merchant);
  const navigate = useNavigate();
  const { colorScheme } = useAppSelector((state) => state.theme);

  return (
    <>
      <Integration
        icon={<Icon as={KeyloopIcon} height="3rem" width="8rem" />}
        primaryButton={
          merchant.isIntegrationEnabled(IntegrationName.KEYLOOP) ? (
            <Button
              colorScheme={colorScheme}
              leftIcon={<Icon as={SettingsIcon} />}
              onClick={() => {
                navigate(`/${merchant.id}/settings/integrations/keyloop`);
              }}
            >
              Set up
            </Button>
          ) : (
            <Button isDisabled={true} colorScheme={colorScheme}>
              Coming soon
            </Button>
          )
        }
      >
        <IntegrationDescription text="Seamlessly connect with clients via the worlds most popular messaging app." />
      </Integration>
    </>
  );
};

export default KeyloopIntegrationItem;
