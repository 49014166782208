export enum LogicalOperationType {
  AND = "and",
  OR = "or",
}

export enum ConditionType {
  CONTAINS = "contains",
  NOT_CONTAINS = "not_contains",
  GREATER_THAN = "greater_than",
  LESS_THAN = "less_than",
  EQUALS = "equals",
  NOT_EQUALS = "not_equals",
}

export interface Condition {
  type: ConditionType;
  key: string;
  value: string;
}

export function getConditionLabel(c: ConditionType) {
  switch (c) {
    case ConditionType.CONTAINS:
      return "has";
    case ConditionType.EQUALS:
      return "is";
    case ConditionType.GREATER_THAN:
      return "after";
    case ConditionType.LESS_THAN:
      return "before";
    case ConditionType.NOT_CONTAINS:
      return "doesn't have";
    case ConditionType.NOT_EQUALS:
      return "isn't";
    default:
      return c;
  }
}

export interface LogicalOperation {
  operation: LogicalOperationType;
  conditions: Array<Condition | LogicalOperation>;
}

export type Criteria =
  | LogicalOperation
  | Condition
  | Array<Condition | LogicalOperation>;

export function stringifyCriteria(criteria: Criteria | null): string {
  if (!criteria) {
    return "";
  }

  if ("operation" in criteria) {
    const conditionsString = criteria.conditions
      .filter((condition) => {
        if ("operation" in condition) {
          if ((condition as LogicalOperation).conditions.length === 0) {
            return false;
          }
        }

        return true;
      })
      .map((condition) => stringifyCriteria(condition))
      .join(", ");
    return `${criteria.operation}(${conditionsString})`;
  }

  if ("type" in criteria) {
    return `${criteria.type}(${criteria.key}, "${criteria.value}")`;
  }

  return "";
}

export const hasEmptyConditions = (criteria: Criteria | null): boolean => {
  if (!criteria) {
    return true;
  }

  if ("operation" in criteria) {
    return (
      criteria.conditions.length === 0 ||
      criteria.conditions.some((condition) => hasEmptyConditions(condition))
    );
  }

  if ("type" in criteria) {
    return !criteria.value;
  }

  return false;
};
