import React, { useEffect, useState } from "react";
import {
  Button,
  HStack,
  Icon,
  Text,
  VStack,
  useColorMode,
} from "@chakra-ui/react";
import MessageDomain, {
  MessageDirection,
} from "entities/domain/conversations/message-domain";
import AgentDomain from "entities/domain/agents/agent-domain";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { ReactComponent as ForwardedEmailIcon } from "assets/icons/forward-email-svgrepo-com.svg";
import { openEmailMessage } from "redux/features/messages";
import MessageWrapper from "./MessageWrapper";

export default ({
  message,
  showTail,
}: {
  message: MessageDomain;
  showTail: boolean;
}) => {
  const dispatch = useAppDispatch();
  const { colorMode } = useColorMode();
  const { agents } = useAppSelector((state) => state.agents);
  const { activeConversation } = useAppSelector((state) => state.conversations);
  const { colorScheme } = useAppSelector((state) => state.theme);
  const [agent, setAgent] = useState<AgentDomain | undefined>(
    agents.find((a: AgentDomain) => a.id === message.agentId)
  );

  useEffect(() => {
    setAgent(agents.find((a: AgentDomain) => a.id === message.agentId));
  }, [agents]);

  return (
    <MessageWrapper
      message={message}
      showTail={showTail}
      activeConversation={activeConversation}
      agent={agent}
    >
      <VStack py={2}>
        <HStack spacing={2} alignItems="center" justifyContent="center" p={2}>
          <Icon
            as={ForwardedEmailIcon}
            __css={{
              path: {
                stroke:
                  message.direction === MessageDirection.INCOMING &&
                  colorMode === "light"
                    ? "black"
                    : "white",
              },
            }}
          />
          <Text
            fontWeight="bold"
            color={
              message.direction === MessageDirection.INCOMING &&
              colorMode === "light"
                ? "black"
                : "white"
            }
          >
            Forwarded message
          </Text>
        </HStack>
        <Button
          w="100%"
          colorScheme={
            message.direction === MessageDirection.OUTGOING
              ? "whiteAlpha"
              : colorScheme
          }
          onClick={() => {
            dispatch(openEmailMessage(message));
          }}
        >
          Open
        </Button>
      </VStack>
    </MessageWrapper>
  );
};
