import { useAuth0 } from "@auth0/auth0-react";
import {
  appendMessages,
  setMessagesReadStatusAction,
  setIsLoadingMessagesAction,
  messagesSelector,
} from "redux/features/messages";
import { useCallback } from "react";
import { batch } from "react-redux";
import InboxService from "services/inbox";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import MessageDomain from "entities/domain/conversations/message-domain";
import { appendOrReplaceConversation } from "redux/features/conversations";

export default function useMessagesStore() {
  const dispatch = useAppDispatch();
  const auth0Context = useAuth0();
  const { merchant } = useAppSelector((state) => state.merchant);
  const { activeConversation } = useAppSelector((state) => state.conversations);

  const conversationMessages: MessageDomain[] =
    useAppSelector(messagesSelector);

  const updateMessagesReadStatus = async (
    conversationId: number,
    offsetMessageId: number,
    currentAgentId: number,
    isRead: boolean
  ) => {
    try {
      const updatedConversation = await InboxService.updateMessagesReadStatus(
        auth0Context,
        conversationId,
        offsetMessageId,
        isRead,
        merchant.id
      );

      batch(() => {
        dispatch(
          setMessagesReadStatusAction({
            conversationId: updatedConversation.id,
            offsetMessageId,
            isRead,
          })
        );
        dispatch(
          appendOrReplaceConversation({
            conversation: updatedConversation,
            currentAgentId,
          })
        );
      });
    } catch (error) {
      // eslint-disable-next-line
      console.error("Failed to mark message as unread", error);
    }
  };

  const markMessagesAsUnreadWaterfall =
    (conversationId: number, offsetMessageId: number, currentAgentId: number) =>
    async (): Promise<void> => {
      updateMessagesReadStatus(
        conversationId,
        offsetMessageId,
        currentAgentId,
        false
      );
    };

  const markMessagesAsReadWaterfall =
    (conversationId: number, offsetMessageId: number, currentAgentId: number) =>
    async (): Promise<void> => {
      updateMessagesReadStatus(
        conversationId,
        offsetMessageId,
        currentAgentId,
        true
      );
    };

  const markConversationAsUnread = useCallback(
    (conversationId: number, lastMessageId: number, currentAgentId: number) => {
      return markMessagesAsUnreadWaterfall(
        conversationId,
        lastMessageId,
        currentAgentId
      )();
    },
    [dispatch, merchant]
  );

  const markConversationAsRead = useCallback(
    (conversationId: number, lastMessageId: number, currentAgentId: number) => {
      return markMessagesAsReadWaterfall(
        conversationId,
        lastMessageId,
        currentAgentId
      )();
    },
    [dispatch, merchant]
  );

  const fetchMoreMessagesWaterfall =
    (conversationId: number, offset: number, merchantId: number) =>
    async (): Promise<void> => {
      dispatch(setIsLoadingMessagesAction(true));

      const newMessages = await InboxService.getMessagesInConversation(
        auth0Context,
        merchantId,
        conversationId,
        offset
      );

      batch(() => {
        dispatch(
          appendMessages({
            conversationId,
            list: newMessages,
          })
        );
        dispatch(setIsLoadingMessagesAction(false));
      });
    };

  const fetchMoreMessages = useCallback(
    (conversationId: number, offset: number, merchantId: number) =>
      fetchMoreMessagesWaterfall(conversationId, offset, merchantId)(),
    [dispatch, activeConversation]
  );

  return {
    markConversationAsUnread,
    markConversationAsRead,
    fetchMoreMessages,
  };
}
