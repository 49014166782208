/* eslint-disable import/prefer-default-export */
import { ChannelsDto } from "entities/dto/ChannelsDTO";
import ChannelDomain from "entities/domain/contacts/contact-domain";

export const adminContactChannelTransformerFromDtoToDomain = (
  channel: ChannelsDto
): ChannelDomain =>
  new ChannelDomain(
    channel.id,
    channel.channel_name,
    channel.identifier,
    channel.country_code,
    channel.custom_webhook_url
  );
