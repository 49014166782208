import {
  Button,
  Drawer,
  DrawerContent,
  DrawerOverlay,
  Flex,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useBreakpointValue,
  useColorMode,
  useToast,
} from "@chakra-ui/react";
import QuickAddContactForm from "components/modals/QuickAddContactForm";
import ContactDomain from "entities/domain/customers/contact-domain";
import useAnalytics from "hooks/use-analytics";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import React, { useState } from "react";
import { ReactSVG } from "react-svg";
import { batch } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";
import InboxService from "services/inbox";
import {
  OpenClosedFilter,
  setActiveConversationOpenClosedFilter,
  setFilterAgents,
  setFilterChannels,
  setFilterCustomerTagIds,
} from "redux/features/conversations";
import { useNavigate } from "react-router-dom";

import ContactListContent from "./contactListContent";
import SelectedContactChannels from "./selectedContactChannels";

interface NewConversationModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const NewConversationModal = ({
  isOpen,
  onClose,
}: NewConversationModalProps) => {
  const auth0Context = useAuth0();
  const { activeConversationId, activeConversation } = useAppSelector(
    (state) => state.conversations
  );
  const { merchant } = useAppSelector((state) => state.merchant);
  const { colorScheme } = useAppSelector((state) => state.theme);
  const { colorMode } = useColorMode();
  const dispatch = useAppDispatch();
  const { filterChannels, filterAgents, filterCustomerTagIds } = useAppSelector(
    (state) => state.conversations
  );
  const { track } = useAnalytics();
  const navigate = useNavigate();
  const toast = useToast();

  const [openNewContactForm, setOpenNewContactForm] = useState<boolean>(false);
  const [selectedContactToMessage, setSelectedContactToMessage] = useState<
    ContactDomain | undefined
  >();
  const [currentSearchText, setCurrentSearchText] = useState<string>("");
  const [step, setStep] = useState<number>(1);
  const [channelId, setChannelId] = useState<string | undefined>();
  const [prefilledName, setPrefilledName] = useState<string>("");
  const isBaseSize = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );

  const onCloseAndReset = () => {
    setOpenNewContactForm(false);
    setSelectedContactToMessage(undefined);
    setStep(1);
    setChannelId(undefined);
    onClose();
  };

  const setRecentlyCreatedContctAsSelectedAndProceed = (
    created: ContactDomain
  ) => {
    setSelectedContactToMessage(created);
    setStep(2);
  };

  const handleCreate = async (
    chanId: string,
    selectedContact: ContactDomain
  ) => {
    try {
      const newConversation =
        await InboxService.createConversationWithChannelId(
          auth0Context,
          chanId,
          merchant.id
        );

      track("new_conversation_attempt_succeeded", {
        conversation_id: newConversation.id,
        customer_id: selectedContact.id,
        channel: newConversation.channel,
      });

      batch(async () => {
        const channelsFilterStillWorks = filterChannels.includes(
          newConversation.channel
        );
        const agentsFilterStillWorks = filterAgents.includes(
          `${newConversation.assignedAgentId || "unassigned"}`
        );
        const customerTagsFilterStillWorks = filterCustomerTagIds.reduce(
          (containsTag, filterTagId) => {
            if (selectedContact.tagIds.includes(filterTagId)) {
              return true;
            }

            return containsTag;
          },
          false
        );

        if (!channelsFilterStillWorks) {
          dispatch(setFilterChannels([]));
        }
        if (!agentsFilterStillWorks) {
          dispatch(setFilterAgents([]));
        }
        if (!customerTagsFilterStillWorks) {
          dispatch(setFilterCustomerTagIds([]));
        }
        dispatch(setActiveConversationOpenClosedFilter(OpenClosedFilter.Open));
      });
      onCloseAndReset();
      navigate(`/${merchant.id}/inbox/${newConversation.id}`);
    } catch (err) {
      toast({ status: "error", title: "An Error Occurred" });
    }
  };

  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={onCloseAndReset}
        size={isBaseSize ? "full" : "lg"}
      >
        <ModalOverlay />
        <ModalContent>
          <Flex
            alignItems="center"
            justifyContent={step === 2 ? "space-between" : "end"}
          >
            {step === 2 ? (
              <Button
                m={2}
                variant="ghost"
                colorScheme={colorScheme}
                onClick={() => {
                  setStep(1);
                  setChannelId(undefined);
                  setSelectedContactToMessage(undefined);
                }}
                leftIcon={
                  <Icon
                    as={ReactSVG}
                    src="/left-arrow-icon.svg"
                    __css={{
                      path: {
                        fill:
                          colorMode === "dark"
                            ? `${colorScheme}.200`
                            : `${colorScheme}.500`,
                      },
                    }}
                  />
                }
              >
                Back
              </Button>
            ) : null}
            <ModalCloseButton />
          </Flex>
          <ModalHeader textAlign="center">
            {step === 1 ? "Let's start a conversation" : "Choose a channel"}
          </ModalHeader>
          <ModalBody m={0} p={0}>
            {step === 1 ? (
              <ContactListContent
                setSelectedContactToMessage={(
                  contact: ContactDomain | undefined
                ) => {
                  if (contact) {
                    track("existing_customer_selected", {
                      customer_id: contact.id,
                      conversation_id: activeConversationId || null,
                    });
                  }
                  setSelectedContactToMessage(contact);
                }}
                selectedContactToMessage={selectedContactToMessage}
                setStep={setStep}
                currentSearchText={currentSearchText}
                setCurrentSearchText={setCurrentSearchText}
              />
            ) : null}
            {step === 2 && selectedContactToMessage ? (
              <SelectedContactChannels
                selectedContact={selectedContactToMessage}
                setChannelId={setChannelId}
                channelId={channelId}
              />
            ) : null}
          </ModalBody>
          <ModalFooter
            display="flex"
            alignItems="center"
            justifyContent={
              selectedContactToMessage && step === 2
                ? "end"
                : selectedContactToMessage
                ? "space-between"
                : "start"
            }
            gridGap={2}
          >
            {step === 1 ? (
              <Button
                colorScheme={colorScheme}
                variant="ghost"
                aria-label="Add new contact"
                size="md"
                onClick={() => {
                  track("new_customer_attempt", {
                    conversation_id: activeConversation?.id || null,
                    customer_id: activeConversation?.customerId,
                    channel: activeConversation?.channel,
                  });
                  setPrefilledName(currentSearchText);
                  setOpenNewContactForm(true);
                  setSelectedContactToMessage(undefined);
                }}
                leftIcon={
                  <Icon
                    as={ReactSVG}
                    src="/user-plus-regular.svg"
                    fontSize={20}
                    fill={
                      colorMode === "dark"
                        ? `${colorScheme}.200`
                        : `${colorScheme}.600`
                    }
                  />
                }
              >
                Add contact
              </Button>
            ) : null}

            {selectedContactToMessage && step === 1 && (
              <Button
                colorScheme={colorScheme}
                isDisabled={!selectedContactToMessage}
                onClick={() => setStep(2)}
                size="md"
                justifySelf="end"
              >
                Next
              </Button>
            )}
            {step === 2 && selectedContactToMessage && (
              <Button
                colorScheme={colorScheme}
                isDisabled={!channelId}
                justifySelf="end"
                onClick={() => {
                  if (!channelId) {
                    return;
                  }

                  handleCreate(channelId, selectedContactToMessage);
                }}
                size="md"
                id="select-contact-channel-next-button"
              >
                Next
              </Button>
            )}
          </ModalFooter>
        </ModalContent>
      </Modal>

      {isBaseSize ? (
        <Drawer
          isOpen={openNewContactForm}
          onClose={() => setOpenNewContactForm(false)}
          placement="top"
          size="full"
          autoFocus={false}
        >
          <DrawerOverlay />
          <DrawerContent overflowY="scroll" borderRadius="0 !important">
            <QuickAddContactForm
              searchString={prefilledName}
              isOpen={openNewContactForm}
              onClose={() => {
                setOpenNewContactForm(false);
              }}
              setRecentlyCreatedContact={
                setRecentlyCreatedContctAsSelectedAndProceed
              }
            />
          </DrawerContent>
        </Drawer>
      ) : (
        <QuickAddContactForm
          searchString={prefilledName}
          isOpen={openNewContactForm}
          onClose={() => {
            setOpenNewContactForm(false);
          }}
          setRecentlyCreatedContact={
            setRecentlyCreatedContctAsSelectedAndProceed
          }
        />
      )}
    </>
  );
};

export default NewConversationModal;
