import React, { memo, useEffect, useMemo, useState } from "react";
import {
  Avatar,
  Checkbox,
  Flex,
  HStack,
  Tooltip,
  useBreakpointValue,
  useColorMode,
} from "@chakra-ui/react";
import ProfileAvatar from "components/profile/profile-avatar";
import { ConversationChannel } from "entities/domain/conversations/conversation-domain";
import { createSearchParams, useLocation, useNavigate } from "react-router-dom";
import { getChannelIcon } from "util/constants";
import AgentDomain from "entities/domain/agents/agent-domain";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import {
  ConversationTab,
  updateConversationSelection,
} from "redux/features/conversations";
import SnippetHeader from "./SnippetHeader";
import SnippetBody from "./SnippetBody";

const getConversationBackground = (
  isHighlighted: boolean,
  isUnread: boolean,
  colorMode: "dark" | "light",
  colorScheme: string
): string => {
  if (isHighlighted) {
    return colorMode === "dark" ? `${colorScheme}.800` : `${colorScheme}.100`;
  }

  if (isUnread) {
    return colorMode === "dark" ? `${colorScheme}.700` : `${colorScheme}.50`;
  }

  return "initial";
};

const ConversationSnippet = memo(
  ({
    elementId,
    conversationId,
    assignedAgentId,
    assignedTeamId,
    conversationChannel,
    customerName,
    customerId,
    customerChannelId,
    conversationDate,
    unreadCount,
    lastMessageId,
    previewText,
    isConversationOpen,
    isAnyUnread,
    isLastMessageUndelivered,
    isSubscribed,
    customerPicture,
    tagIds,
    onAssignOpen,
    onUpdateTagsOpen,
  }: {
    elementId: string;
    conversationId: number;
    assignedAgentId: number | null;
    assignedTeamId: string | null;
    conversationChannel: ConversationChannel;
    unreadCount: number;
    isSubscribed: boolean;
    customerId: number;
    customerChannelId: string;
    customerPicture: string;
    customerName: string;
    conversationDate: string;
    lastMessageId: number | null;
    previewText: string;
    isConversationOpen: boolean;
    isAnyUnread: boolean;
    isLastMessageUndelivered: boolean;
    tagIds: string[];
    onAssignOpen: (conversationId: number) => void;
    onUpdateTagsOpen: (conversationId: number) => void;
  }) => {
    const isBaseSize = useBreakpointValue(
      { base: true, md: false },
      { ssr: false }
    );
    const navigate = useNavigate();
    const { search } = useLocation();

    const dispatch = useAppDispatch();
    const { activeConversationId, selectedConversationIds, activeTab } =
      useAppSelector((state) => state.conversations);
    const { merchant } = useAppSelector((state) => state.merchant);
    const { agents } = useAppSelector((state) => state.agents);
    const { colorMode } = useColorMode();
    const { colorScheme } = useAppSelector((state) => state.theme);

    const conversationBackground = useMemo(
      () =>
        getConversationBackground(
          activeConversationId === conversationId,
          isAnyUnread,
          colorMode,
          colorScheme
        ),
      [activeConversationId, colorMode, colorScheme, isAnyUnread]
    );

    const avatarStyles = useMemo(
      () => ({
        flexShrink: 0,
        flexGrow: 0,
        flexBasis: "auto",
        size: "md",
      }),
      []
    );

    const [showSelectCheckbox, setShowSelectCheckbox] = useState<boolean>(
      isBaseSize
        ? !!selectedConversationIds.length
        : selectedConversationIds.includes(conversationId)
    );

    useEffect(() => {
      setShowSelectCheckbox(!!selectedConversationIds.length);
    }, [selectedConversationIds]);

    const [assignedAgent, setAssignedAgent] = useState<AgentDomain | null>(
      null
    );

    useEffect(() => {
      if (assignedAgentId === null) {
        setAssignedAgent(null);
        return;
      }

      const agent = agents.find((a) => a.id === assignedAgentId);
      setAssignedAgent(agent || null);
    }, [activeTab, assignedAgentId, agents]);

    return (
      <>
        <Flex
          id={elementId}
          data-testid="conversation"
          height="100px" // For better virtualization
          px={4}
          display="flex"
          width="100%"
          position="relative"
          alignItems="center"
          gridGap={6}
          cursor="pointer"
          transition="filter 0.5s ease-in-out 0.5s;"
          _hover={{
            background:
              colorMode === "dark"
                ? `${colorScheme}.800`
                : `${colorScheme}.100`,
          }}
          _active={{
            filter: `drop-shadow(
              0 0 1rem "steelblue"
            )`,
            transition: "filter 0s",
          }}
          background={conversationBackground}
          onMouseEnter={() => {
            if (selectedConversationIds.length || isBaseSize) {
              return;
            }

            setShowSelectCheckbox(true);
          }}
          onMouseLeave={() => {
            if (selectedConversationIds.length || isBaseSize) {
              return;
            }

            setShowSelectCheckbox(false);
          }}
        >
          <HStack
            alignItems="center"
            justifyContent="start"
            spacing={4}
            height="100%"
            py={4}
          >
            <Checkbox
              id={`checkbox-${conversationId}`}
              display={!showSelectCheckbox && isBaseSize ? "none" : "flex"}
              opacity={showSelectCheckbox ? 1 : 0}
              zIndex={isBaseSize ? 0 : 9999}
              isChecked={selectedConversationIds.includes(conversationId)}
              pointerEvents={selectedConversationIds.length ? "none" : "auto"}
              onChange={(e) => {
                if (activeConversationId) {
                  navigate({
                    pathname: `/${merchant.id}/inbox/`,
                    search: createSearchParams(search).toString(),
                  });
                }

                dispatch(
                  updateConversationSelection({
                    conversationId,
                    isSelected: e.target.checked,
                  })
                );
              }}
            />
            <ProfileAvatar
              channelIcon={getChannelIcon(conversationChannel)}
              channelPlacement="top-end"
              profilePicture={customerPicture}
              name={customerName}
              avatarStyle={avatarStyles}
              assignedPerson={
                assignedAgent && activeTab === ConversationTab.Team ? (
                  <Tooltip
                    label={`Assigned to ${assignedAgent.getFullName()}`}
                    aria-label="Agent Name"
                  >
                    <Avatar
                      size="xs"
                      width="1.25rem"
                      height="1.25rem"
                      border={colorMode === "dark" ? "none" : "1px solid white"}
                      src={assignedAgent!.getPicture() || ""}
                      name={assignedAgent!.getFullName()}
                    />
                  </Tooltip>
                ) : null
              }
            />
          </HStack>
          <Flex
            height="100%"
            flexGrow={1}
            flexShrink={1}
            flexBasis="auto"
            direction="column"
            justifyContent="space-between"
            overflow="hidden"
            pt={2}
          >
            <SnippetHeader
              isSubscribed={isSubscribed}
              customerName={customerName}
              date={conversationDate}
              tagIds={tagIds}
              assignedTeamId={assignedTeamId}
            />
            <SnippetBody
              conversationChannel={conversationChannel}
              lastMessageId={lastMessageId}
              unreadCount={unreadCount}
              conversationId={conversationId}
              customerId={customerId}
              customerChannelId={customerChannelId}
              isConversationOpen={isConversationOpen}
              assignedAgentId={assignedAgentId}
              previewText={previewText}
              isLastMessageUndelivered={isLastMessageUndelivered}
              isAnyUnread={isAnyUnread}
              isSubscribed={isSubscribed}
              onAssignOpen={onAssignOpen}
              onUpdateTagsOpen={onUpdateTagsOpen}
            />
          </Flex>
        </Flex>
      </>
    );
  }
);

export default ConversationSnippet;
