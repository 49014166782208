import React, {
  Tag as ChakraTag,
  HStack,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import Tag, { MAX_TAG_WIDTH_IN_PIXELS } from "components/tags";
import { ReactNode, memo, useEffect, useMemo, useRef, useState } from "react";
import { useAppSelector } from "redux/hooks";
import { matchTagColorToMerchantTagColor } from "util/constants";

interface TagsListProps {
  tagIds: string[];
  defaultMaxVisibleTags?: number;
  defaultTag?: ReactNode;
}

const TagsList = memo(
  ({ tagIds, defaultMaxVisibleTags, defaultTag }: TagsListProps) => {
    const tagsContainerRef = useRef<HTMLDivElement>(null);
    const { tags: allTags } = useAppSelector((state) => state.tags);
    const [maxVisibleTags, setMaxVisibleTags] = useState<number>(
      defaultMaxVisibleTags || 0
    );

    const tags = useMemo(() => {
      return allTags.filter((tag) => tagIds.includes(tag.id));
    }, [tagIds, allTags]);

    const visibleTags = useMemo(() => {
      return tags.slice(0, maxVisibleTags);
    }, [maxVisibleTags, tags]);

    const hiddenTags = useMemo(() => {
      return tags.slice(maxVisibleTags);
    }, [maxVisibleTags, tags]);

    useEffect(() => {
      const { current } = tagsContainerRef;

      if (tags.length === 0 || !current) {
        return;
      }

      const updateTagsDisplay = () => {
        const availableWidth = tagsContainerRef.current?.offsetWidth || 0;
        const averageCharWidth = 6; // half the size of small font-size
        const tagPadding = 10; // Total horizontal padding and margins for a tag in pixels

        const maxTagWidth = tags.reduce((max, tag) => {
          const approximateWidth =
            tag.tag.length * averageCharWidth + tagPadding;

          if (approximateWidth > MAX_TAG_WIDTH_IN_PIXELS) {
            return MAX_TAG_WIDTH_IN_PIXELS > max
              ? MAX_TAG_WIDTH_IN_PIXELS
              : max;
          }

          return approximateWidth > max ? approximateWidth : max;
        }, 0);

        setMaxVisibleTags(Math.floor(availableWidth / maxTagWidth));
      };

      if ("ResizeObserver" in window) {
        new ResizeObserver(updateTagsDisplay).observe(current);
      }
    }, [tagsContainerRef, tags]);

    return (
      <HStack
        ref={tagsContainerRef}
        spacing={1}
        overflow="hidden"
        w="100%"
        sx={{
          "> span": {
            display: "inline-flex",
            alignItems: "center",
          },
        }}
      >
        {tags.length === 0 ? (
          defaultTag
        ) : (
          <>
            {visibleTags.map((tag, index) => (
              <Tag
                key={index}
                label={tag.tag}
                color={matchTagColorToMerchantTagColor(allTags, tag)}
                fontSize="xs"
                _hover={{ opacity: 0.5 }}
              />
            ))}
            {hiddenTags.length > 0 && (
              <Tooltip
                label={hiddenTags.map((tag) => tag.tag).join(", ")}
                placement="top"
                shouldWrapChildren
                bgColor="#9496A9"
                fontSize="sm"
              >
                <ChakraTag
                  color="#9496A9"
                  size="sm"
                  fontSize="xs"
                  _hover={{ opacity: 0.5 }}
                >
                  <Text noOfLines={1}>{`+${hiddenTags.length}`}</Text>
                </ChakraTag>
              </Tooltip>
            )}
          </>
        )}
      </HStack>
    );
  }
);

export default TagsList;
