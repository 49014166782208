import { Auth0ContextInterface } from "@auth0/auth0-react/src/auth0-context";
import AgentPermissionsDomain from "entities/domain/agents/agent-permissions-domain";
import AgentRolesDomain from "entities/domain/agents/agent-roles-domain";
import {
  agentPermissionsTransformFromDtoToDomain,
  agentRolesTransformFromDtoToDomain,
} from "entities/transformers/agent-transformer";
import { AgentPermissionsDTO, AgentRoleDTO } from "entities/dto/AgentDTO";
import { RequestType } from "./request-type";
import { request } from "../util/methods";

class PermissionsService {
  public static async getPermissions({
    getAccessTokenSilently,
  }: Auth0ContextInterface): Promise<AgentPermissionsDomain[]> {
    const accessToken = await getAccessTokenSilently();

    const data = await request(RequestType.GET, accessToken, `permissions`);

    const permissions: AgentPermissionsDTO[] = data.data;

    return permissions.map((p) => agentPermissionsTransformFromDtoToDomain(p));
  }
}

export default PermissionsService;
