import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  CloseButton,
  useBreakpointValue,
} from "@chakra-ui/react";
import useMerchantStore from "hooks/use-merchant-store";
import React, { useEffect, useState } from "react";
import {
  OnboardingStatus,
  setOnboardingStatus,
} from "redux/features/onboarding";
import { MerchantUserGuides } from "entities/domain/admin/merchants/merchant-user-guides";
import { useNavigate } from "react-router-dom";
import { useAppSelector, useAppDispatch } from "redux/hooks";

interface PageBannerProps {}

const PageBanner = (_props: PageBannerProps) => {
  const dispatch = useAppDispatch();
  const { merchant } = useAppSelector((state) => state.merchant);
  const { colorScheme } = useAppSelector((state) => state.theme);
  const { status } = useAppSelector((state) => state.onboarding);
  const isBaseSize = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );
  const navigate = useNavigate();

  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const isOnboardingPending = merchant.userGuides.pending.includes(
      MerchantUserGuides.ONBOARDING
    );
    const isOnboardingPaused = status === OnboardingStatus.PAUSED;

    if (
      (isOnboardingPending && isOnboardingPaused) ||
      (isOnboardingPending && !window.location.pathname.includes("inbox"))
    ) {
      setIsOpen(true);
    } else {
      setIsOpen(false);
    }
  }, [status, merchant.userGuides.pending, window.location.pathname]);

  return !isOpen ? null : (
    <Alert
      status="warning"
      display={isOpen ? "flex" : "none"}
      variant="top-accent"
      position="absolute"
      top={0}
      zIndex={9999}
    >
      <AlertIcon />
      <Box
        display={isBaseSize ? "initial" : "flex"}
        w="100%"
        alignItems="center"
      >
        <AlertTitle mr={2} fontSize="sm">
          You haven&apos;t completed your onboarding yet!
        </AlertTitle>
        <AlertDescription>
          <Button
            colorScheme={colorScheme}
            fontSize="sm"
            variant="link"
            onClick={() => {
              navigate(`/${merchant.id}/inbox`);
              dispatch(setOnboardingStatus(OnboardingStatus.IN_PROGRESS));
            }}
          >
            Please click here to continue.
          </Button>
        </AlertDescription>
      </Box>
      <CloseButton
        onClick={() => {
          setIsOpen(false);
        }}
      />
    </Alert>
  );
};

export default PageBanner;
