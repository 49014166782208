class ReviewerDomain {
  public customerId: number | null;

  public conversationId: number | null;

  public avatar: string | null;

  public name: string | null;

  constructor(
    customerId: number | null,
    conversationId: number | null,
    avatar: string | null,
    name: string | null
  ) {
    this.customerId = customerId;
    this.conversationId = conversationId;
    this.avatar = avatar;
    this.name = name;
  }
}

export default ReviewerDomain;
