import { v4 as uuidv4 } from "uuid";

const reviewsClientId = process.env.REACT_APP_GOOGLE_OAUTH2_CLIENT_ID;
const gmailClientId = process.env.REACT_APP_GOOGLE_GMAIL_OAUTH2_CLIENT_ID;

const base_params = {
  response_type: "code",
  include_granted_scopes: "true",
  access_type: "offline",
  prompt: "consent",
};

const oauth2Endpoint =
  "https://accounts.google.com/o/oauth2/v2/auth/oauthchooseaccount";

export const openGmailIntegration = (merchantId: number) => {
  const gmail_params: {
    [key: string]: string | undefined;
  } = {
    ...base_params,
    client_id: gmailClientId,
    state: merchantId.toString(),
    redirect_uri: `https://${window.location.hostname}/google/gmail/callback`,
    scope:
      "https://www.googleapis.com/auth/gmail.readonly https://www.googleapis.com/auth/gmail.send",
  };

  localStorage.setItem("0auth2.0State", JSON.stringify(gmail_params.state));

  // @ts-ignore
  const url = `${oauth2Endpoint}?${Object.keys(gmail_params)
    .map((name) => `${name}=${gmail_params[name]}`)
    .join("&")}`;

  return url;
};

export const openReviewsIntegration = (merchantId: number) => {
  const reviews_params: {
    [key: string]: string | undefined;
  } = {
    ...base_params,
    client_id: reviewsClientId,
    state: merchantId.toString(),
    redirect_uri: `https://${window.location.hostname}/reviews/google/callback`,
    scope: "https://www.googleapis.com/auth/business.manage",
  };

  localStorage.setItem("0auth2.0State", JSON.stringify(reviews_params.state));

  // @ts-ignore
  const url = `${oauth2Endpoint}?${Object.keys(reviews_params)
    .map((name) => `${name}=${reviews_params[name]}`)
    .join("&")}`;

  window.open(url, "_self");
};
