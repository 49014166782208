import {
  Box,
  Input,
  FormLabel,
  FormControl,
  useBreakpointValue,
  VStack,
  Button,
  useColorMode,
} from "@chakra-ui/react";
import { useAppSelector } from "redux/hooks";
import React, { useEffect, useState } from "react";
import QueryBuilder from "components/shared/QueryBuilder";
import AudienceDomain, { AudienceCriteria } from "entities/domain/audience";
import { ConversationChannel } from "entities/domain/conversations/conversation-domain";
import { IntegrationName } from "entities/domain/admin/merchants/merchant-integrations";
import {
  hasEmptyConditions,
  stringifyCriteria,
} from "entities/domain/criteria";
import { GroupConfig } from "components/shared/QueryBuilder/QueryCondition";
import QueryConfirmationModal from "./QueryConfirmationModal";
import { customerGroup, vehicleGroup } from "./QueryFields";

interface NewAudienceProps {
  isSavingAudience: boolean;
  audienceName: string | null;
  audienceCriteria: AudienceCriteria | null;
  campaignChannel: ConversationChannel;
  setAudienceCriteria: (criteria: AudienceCriteria) => void;
  setAudienceName: (audienceName: string | null) => void;
  onAudienceSelect: (newAudience: AudienceDomain) => void;
}

const NewAudienceTab = ({
  isSavingAudience,
  audienceName,
  campaignChannel,
  audienceCriteria,
  onAudienceSelect,
  setAudienceCriteria,
  setAudienceName,
}: NewAudienceProps) => {
  const isBaseSize = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );
  const { colorMode } = useColorMode();
  const { colorScheme } = useAppSelector((state) => state.theme);
  const { merchant } = useAppSelector((state) => state.merchant);

  const [isQueryBuilderDisabled, setIsQueryBuilderDisabled] =
    useState<boolean>(false);
  const [isCriteriaConfirmationOpen, setIsCriteriaConfirmationOpen] =
    useState<boolean>(false);
  const [enabledGroups, setEnabledGroups] = useState<GroupConfig[]>([
    customerGroup,
  ]);
  const [isCriteriaFormed, setIsCriteriaFormed] = useState<boolean>(false);

  useEffect(() => {
    if (merchant.isIntegrationEnabled(IntegrationName.KEYLOOP)) {
      setEnabledGroups([customerGroup, vehicleGroup]);
    } else {
      setEnabledGroups([customerGroup]);
    }
  }, [merchant]);

  useEffect(() => {
    if (audienceCriteria && !hasEmptyConditions(audienceCriteria)) {
      setIsCriteriaFormed(true);
    } else {
      setIsCriteriaFormed(false);
    }
  }, [audienceCriteria]);

  return (
    <VStack width={isBaseSize ? "100%" : "60%"} mx="auto">
      <FormControl mt={6} isRequired={true}>
        <FormLabel fontWeight={700}>Audience Name</FormLabel>
        <Input
          colorScheme={colorScheme}
          id="campaign-audience-name-input"
          rounded="full"
          placeholder="My prospects"
          isDisabled={isSavingAudience}
          value={audienceName || ""}
          onChange={(e) => {
            setAudienceName(e.target.value);
          }}
        />
      </FormControl>
      <FormControl
        my={6}
        isRequired={true}
        display="flex"
        flexDirection="column"
        alignItems="start"
      >
        <FormLabel fontWeight={700}>Criteria</FormLabel>
        <Box
          w="100%"
          {...(isBaseSize
            ? {
                padding: 2,
              }
            : {
                padding: 6,
                borderRadius: "xl",
                borderWidth: "1px",
                borderStyle: "solid",
                borderColor: colorMode === "dark" ? "gray.500" : "gray.100",
              })}
        >
          <QueryBuilder
            entityNamePlural="customers"
            groups={enabledGroups}
            criteria={audienceCriteria}
            setCriteria={setAudienceCriteria}
            isDisabled={isQueryBuilderDisabled}
            disabledFields={["channel_type"]}
          />
        </Box>
      </FormControl>
      <Button
        colorScheme="green"
        id="campaign-test-criteria-button"
        onClick={() => {
          setIsCriteriaConfirmationOpen(true);
          setIsQueryBuilderDisabled(true);
        }}
        isDisabled={!audienceName || !isCriteriaFormed}
      >
        Test Criteria
      </Button>
      <QueryConfirmationModal
        groups={enabledGroups}
        criteria={audienceCriteria}
        campaignChannel={campaignChannel}
        audienceName={audienceName || null}
        isOpen={isCriteriaConfirmationOpen}
        onConfirm={(createdAudience) => {
          onAudienceSelect(createdAudience);
          setIsQueryBuilderDisabled(true);
        }}
        onClose={() => {
          setIsCriteriaConfirmationOpen(false);
          setIsQueryBuilderDisabled(false);
        }}
      />
    </VStack>
  );
};

export default NewAudienceTab;
