import { useAuth0 } from "@auth0/auth0-react";
import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  VStack,
  useBreakpointValue,
  useColorMode,
  useToast,
} from "@chakra-ui/react";
import TeamForm from "components/user-settings/TeamMemberSettings/TeamForm";
import React, { useCallback, useEffect, useState } from "react";
import { addTeam } from "redux/features/agents";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import Select, { MultiValue } from "react-select";
import AgentsService from "services/agents";
import AgentOption, {
  AvailableAgentOption,
  sortOptions,
} from "components/shared/AgentOption";
import { getReactSelectStyles } from "util/methods";

interface AddTeamModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const AddTeamModal: React.FC<AddTeamModalProps> = ({ isOpen, onClose }) => {
  const isBaseSize = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );
  const dispatch = useAppDispatch();
  const auth0Context = useAuth0();
  const toast = useToast();
  const { colorMode } = useColorMode();
  const { colorScheme } = useAppSelector((state) => state.theme);
  const { merchant } = useAppSelector((state) => state.merchant);
  const { agents } = useAppSelector((state) => state.agents);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [newTeamName, setNewTeamName] = useState<string>("");
  const [newTeamPurpose, setNewTeamPurpose] = useState<string>("");
  const setNewTeamNameMemoized = useCallback(setNewTeamName, []);
  const setNewTeamPurposeMemoized = useCallback(setNewTeamPurpose, []);

  const agentOptions = sortOptions(
    agents.map((a) => ({
      value: a.id.toString(),
      label: a.getFullName(),
      avatarUrl: a.getPicture() || undefined,
    }))
  ) as AvailableAgentOption[];

  const [selectedAgentOptionsValue, setSelectedAgentOptionsValue] = useState<
    MultiValue<AvailableAgentOption>
  >([] as MultiValue<AvailableAgentOption>);

  const handleAgentsChange = (e: MultiValue<AvailableAgentOption>) => {
    setSelectedAgentOptionsValue(e);
  };

  useEffect(() => {
    setNewTeamName("");
    setNewTeamPurpose("");
    setSelectedAgentOptionsValue([] as MultiValue<AvailableAgentOption>);
  }, [isOpen]);

  const createTeam = async () => {
    setIsLoading(true);

    try {
      const newTeam = await AgentsService.createTeam(auth0Context, {
        name: newTeamName,
        purpose: newTeamPurpose,
        merchant_id: merchant.id,
        agent_ids: selectedAgentOptionsValue.map((a) => parseInt(a.value, 10)),
      });

      dispatch(addTeam(newTeam));
      onClose();
      toast({
        title: "Team created successfully!",
        status: "success",
      });
    } catch (_e: unknown) {
      toast({
        title: "Error creating team, try again later or contact support!",
        status: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size={isBaseSize ? "full" : "md"}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Create team</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack>
            <TeamForm
              teamName={newTeamName}
              setTeamName={setNewTeamNameMemoized}
              teamPurpose={newTeamPurpose}
              setTeamPurpose={setNewTeamPurposeMemoized}
              isLoading={isLoading}
            />
            <FormControl
              mt={2}
              isInvalid={selectedAgentOptionsValue.length === 0}
            >
              <FormLabel>Agents</FormLabel>
              <Select
                isMulti
                placeholder="Select agents"
                value={selectedAgentOptionsValue}
                onChange={handleAgentsChange}
                components={{
                  Option: AgentOption,
                }}
                options={agentOptions}
                closeMenuOnSelect={false}
                styles={{
                  ...{
                    ...getReactSelectStyles(colorMode, colorScheme),
                    container: (provided: any) => ({
                      ...provided,
                      width: "100%",
                    }),
                  },
                }}
              />
              <FormErrorMessage>
                Please select at least one agent
              </FormErrorMessage>
            </FormControl>
          </VStack>
        </ModalBody>
        <ModalFooter display="flex" justifyContent="end" alignItems="end">
          <Button
            onClick={createTeam}
            colorScheme={colorScheme}
            isDisabled={
              isLoading ||
              selectedAgentOptionsValue.length === 0 ||
              !newTeamName ||
              !newTeamPurpose
            }
          >
            Create
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default AddTeamModal;
