import { useAuth0 } from "@auth0/auth0-react";
import {
  FormControl,
  FormLabel,
  Input,
  Stack,
  useToast,
} from "@chakra-ui/react";
import {
  Button,
  ButtonSize,
  ButtonType,
} from "theme/old-design-system/styled-components";
import {
  BUTTON_COLOUR_SCHEME_VALUES,
  ContactFormDomain,
} from "entities/domain/contact-form";
import React, { useEffect, useState } from "react";
import AdminMerchantDomain from "entities/domain/admin/merchants/admin-merchant-domain";
import Select from "react-select";

import ContactFormService from "services/contactForm";
import SelectMerchant from "../select-merchant";

const colourSchemeOptions = BUTTON_COLOUR_SCHEME_VALUES.map((colourScheme) => {
  return { value: colourScheme, label: colourScheme };
});

const ContactFormConfig = () => {
  const toast = useToast();
  const [selectedMerchant, setSelectedMerchant] =
    useState<AdminMerchantDomain | null>(null);
  const [selectedMerchantPublicId, setSelectedMerchantPublicId] = useState<
    string | null
  >(null);
  const [contactFormConfig, setContactFormConfig] =
    useState<ContactFormDomain | null>(null);
  const auth0Context = useAuth0();

  const loadContactFormConfig = async () => {
    if (selectedMerchantPublicId) {
      setContactFormConfig(
        await ContactFormService.fetchConfigs(selectedMerchantPublicId)
      );
    }
  };

  useEffect(() => {
    setSelectedMerchantPublicId(selectedMerchant?.public_id || null);
  }, [selectedMerchant]);

  useEffect(() => {
    loadContactFormConfig();
  }, [selectedMerchantPublicId]);

  const saveConfig = async () => {
    try {
      if (selectedMerchant?.id) {
        await ContactFormService.saveConfigs(
          selectedMerchant.id,
          contactFormConfig || {},
          auth0Context
        );

        toast({
          status: "success",
          title: "Contact Form Config has been saved successfully.",
        });
      }
    } catch (err) {
      toast({
        status: "error",
        title: "An error occurred",
      });
    }
  };

  const handleSaveConfig = () => {
    saveConfig();
  };

  return (
    <Stack spacing={3}>
      <FormControl isRequired>
        <FormLabel>Select Merchant</FormLabel>
        <SelectMerchant
          selectedMerchant={selectedMerchant}
          setSelectedMerchant={setSelectedMerchant}
        />
      </FormControl>
      {contactFormConfig && (
        <>
          <FormControl>
            <FormLabel>Background Colour</FormLabel>
            <Input
              value={contactFormConfig.bgColour || ""}
              onChange={(e) =>
                setContactFormConfig({
                  ...contactFormConfig,
                  bgColour: e.target.value,
                })
              }
            />
          </FormControl>
          <FormControl>
            <FormLabel>Font Colour</FormLabel>
            <Input
              value={contactFormConfig.fontColour || ""}
              onChange={(e) =>
                setContactFormConfig({
                  ...contactFormConfig,
                  fontColour: e.target.value,
                })
              }
            />
          </FormControl>
          <FormControl>
            <FormLabel>Border Colour</FormLabel>
            <Input
              value={contactFormConfig.borderColour || ""}
              onChange={(e) =>
                setContactFormConfig({
                  ...contactFormConfig,
                  borderColour: e.target.value,
                })
              }
            />
          </FormControl>
          <FormControl>
            <FormLabel>Title</FormLabel>
            <Input
              value={contactFormConfig.title || ""}
              onChange={(e) =>
                setContactFormConfig({
                  ...contactFormConfig,
                  title: e.target.value,
                })
              }
            />
          </FormControl>
          <FormControl>
            <FormLabel>Subtitle</FormLabel>
            <Input
              value={contactFormConfig.subtitle || ""}
              onChange={(e) =>
                setContactFormConfig({
                  ...contactFormConfig,
                  subtitle: e.target.value,
                })
              }
            />
          </FormControl>
          <FormControl>
            <FormLabel>Button Colour Scheme</FormLabel>
            <Select
              options={colourSchemeOptions}
              value={colourSchemeOptions.filter(
                (option) =>
                  option.value === contactFormConfig.buttonColourScheme
              )}
              onChange={(e) =>
                setContactFormConfig({
                  ...contactFormConfig,
                  buttonColourScheme: e?.value,
                })
              }
              isClearable={true}
              menuPlacement="auto"
            />
          </FormControl>
          <Button
            type={ButtonType.PRIMARY}
            onClick={handleSaveConfig}
            isDisabled={!selectedMerchant}
            mt={30}
            mb={30}
            size={ButtonSize.LARGE}
          >
            Save
          </Button>
        </>
      )}
    </Stack>
  );
};

export default ContactFormConfig;
