export const isAccordionItemExpanded = (
  index: number,
  expandedIndices: number | number[]
) => {
  if (Array.isArray(expandedIndices)) {
    return expandedIndices.includes(index);
  }

  return expandedIndices === index;
};

export const collapseAccordionItem = (
  index: number,
  expandedIndices: number | number[],
  setExpandedIndices: (expandedIndices: number | number[]) => void
) => {
  if (Array.isArray(expandedIndices)) {
    const newExpandedIndices = expandedIndices.filter((i) => i !== index);
    setExpandedIndices(newExpandedIndices);
  } else {
    setExpandedIndices([]);
  }
};
