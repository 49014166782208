import { useAuth0 } from "@auth0/auth0-react";
import {
  Flex,
  Spacer,
  useBreakpointValue,
  Heading,
  Icon,
  IconButton,
} from "@chakra-ui/react";
import { ReactComponent as LeftArrowIcon } from "assets/icons/left-arrow.svg";
import { WarningTextProps } from "components/shared/WarningTextComponent";
import MessageDomain from "entities/domain/conversations/message-domain";
import React, { useEffect, useRef, useState } from "react";
import { useAppSelector, useAppDispatch } from "redux/hooks";
import PaymentsService, { SendMessagePayload } from "services/payments";
import { appendMessage } from "redux/features/messages";
import PaymentsStepOne from "./PaymentsStepOne";
import PaymentsStepTwo from "./PaymentsStepTwo";
import { PAYMENTS_DONT_SHOW_AGAIN_TOKEN } from "./shared";

interface CurrencyInputOnChangeValues {
  float: number | null;
  formatted: string;
  value: string;
}

export type AmountType = string | number;

interface PaymentsPopoverProps {
  onClose: () => void;
  id?: string;
}

const PaymentsPopover = ({ onClose, id = "" }: PaymentsPopoverProps) => {
  const dispatch = useAppDispatch();
  const regexp = new RegExp("^[0-9a-zA-Z./&-\\s]*$");
  const [step, setStep] = useState<number>(1);
  const [amount, setAmount] = useState<string>("");
  const [numberAmount, setNumberAmount] = useState<number>(0);
  const [warning, setWarning] = useState<WarningTextProps | undefined>(
    undefined
  );
  const [servicesRendered, setServicesRendered] = useState<string>("");
  const useOutsideAlerterRef = useRef<HTMLDivElement>(null);
  const [proceedWithoutPreviewActive, setProceedWithoutPreviewActive] =
    useState<boolean>(false);

  const { activeConversationId, activeConversation } = useAppSelector(
    (state) => state.conversations
  );
  const { merchant } = useAppSelector((state) => state.merchant);
  const { colorScheme } = useAppSelector((state) => state.theme);
  const auth0Context = useAuth0();
  const [proceedWithInvoice, setProceedWithInvoice] = useState<boolean>(
    merchant.hasInvoiceAccount
  );
  const [isAmountAndServicesDirty, setIsAmountAndServicesDirty] = useState<
    Object | undefined
  >();
  const isBaseSize = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );

  const handleOnAmountChange = (
    value: string | undefined,
    name?: string,
    values?: CurrencyInputOnChangeValues
  ): void => {
    if (!value) {
      setAmount("");
      setNumberAmount(0);
      return;
    }
    setNumberAmount(values?.float || 0);
    setAmount(value);
  };

  const reset = () => {
    setStep(1);
    setAmount("");
    setNumberAmount(0);
    setServicesRendered("");
    setWarning(undefined);
    setProceedWithInvoice(merchant.hasInvoiceAccount);
  };

  useEffect(() => {
    const val = localStorage.getItem(PAYMENTS_DONT_SHOW_AGAIN_TOKEN);

    setProceedWithoutPreviewActive(val === "true");
  }, []);

  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    const handleClickOutside = (event: any) => {
      if (
        useOutsideAlerterRef?.current &&
        !useOutsideAlerterRef?.current.contains(event.target)
      ) {
        onClose();
        reset();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [useOutsideAlerterRef]);

  useEffect(() => {
    if (!isAmountAndServicesDirty) {
      return;
    }

    if (numberAmount < 0.3) {
      setWarning({
        key: "invalid_payment_amount",
        description: "* must be at least £0.30",
      });
    } else if (servicesRendered.length < 3) {
      setWarning({
        key: "invalid_description",
        description: "* must be at least 3 characters",
      });
    } else if (servicesRendered.length > 255) {
      setWarning({
        key: "invalid_description",
        description: "* must be less than 255 characters",
      });
    } else if (!regexp.test(servicesRendered)) {
      setWarning({
        key: "invalid_description",
        description: "Characters allowed: A to Z, a to z, 0 to 9, &, -, ., /",
      });
    } else {
      setWarning(undefined);
    }
  }, [isAmountAndServicesDirty]);

  useEffect(() => {}, [servicesRendered]);

  const sendPaymentMessage = async () => {
    try {
      const payload: SendMessagePayload = {
        conversationId: activeConversationId!,
        reason: servicesRendered,
        amount: `${amount}`,
        currency: merchant.getCurrency(),
        withInvoice: proceedWithInvoice,
      };

      if (activeConversation?.isEmailChannel()) {
        payload.title = activeConversation?.lastMessageTitle;
        payload.replyToMessageId = activeConversation?.messageId;
      }

      await PaymentsService.sendPaymentMessage(
        auth0Context,
        payload,
        merchant.id,
        payload.conversationId
      ).then((message: MessageDomain) => {
        dispatch(
          appendMessage({ message, conversationId: message.conversationId })
        );
      });
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error("[sendPaymentMessage] err: ", err);
    }
  };

  const submit = () => {
    sendPaymentMessage();
    reset();
    onClose();
  };

  const stepOneProceed = () => {
    if (!isAmountAndServicesDirty) {
      setIsAmountAndServicesDirty({});
      return;
    }

    if (proceedWithoutPreviewActive) {
      submit();
    } else {
      setStep(step + 1);
    }
  };

  const formatAmount = (am: AmountType): string =>
    `${merchant.getCurrencySymbol()}${Number(am).toFixed(2)}`;

  const renderStep = () => {
    switch (step) {
      case 1:
        return (
          <PaymentsStepOne
            amount={amount}
            currencySymbol={merchant.getCurrencySymbol()!}
            warning={warning}
            handleOnAmountChange={(newAmount, name, values) => {
              setIsAmountAndServicesDirty({});
              handleOnAmountChange(newAmount, name, values);
            }}
            servicesRendered={servicesRendered}
            setServicesRendered={(newServicesRendered: string) => {
              setIsAmountAndServicesDirty({});
              setServicesRendered(newServicesRendered);
            }}
            proceed={stepOneProceed}
            setProceedWithInvoice={setProceedWithInvoice}
            proceedWithInvoice={proceedWithInvoice}
            showInvoice={merchant.hasInvoiceAccount}
          />
        );
      case 2:
        return (
          <PaymentsStepTwo
            amount={formatAmount(amount)}
            servicesRendered={servicesRendered}
            proceed={submit}
            proceedWithInvoice={proceedWithInvoice}
            showInvoice={merchant.hasInvoiceAccount}
          />
        );
      default:
        return null;
    }
  };

  const getStepTitle = (): string => {
    switch (step) {
      case 1:
        return "Request payment";
      case 2:
        return "Request overview";
      default:
        return "";
    }
  };

  return (
    <Flex
      ref={useOutsideAlerterRef}
      id={id}
      flexFlow="column nowrap"
      justifyContent="center"
      alignItems="center"
      px={6}
      py={4}
      borderRadius="md"
      maxHeight="50vh"
    >
      <Flex justifyContent="space-between" width="100%" mb="12px">
        {step === 2 && (
          <IconButton
            aria-label="Go back"
            colorScheme={colorScheme}
            onClick={() => setStep(1)}
            variant="ghost"
          >
            <Icon
              as={LeftArrowIcon}
              __css={{
                svg: {
                  width: "1rem",
                  height: "1rem",
                },
              }}
            />
          </IconButton>
        )}

        <Spacer />

        <Heading as="h6" size="md" fontWeight="semibold" display="block">
          {getStepTitle()}
        </Heading>

        <Spacer />

        {step === 2 && <Spacer style={{ width: "16px" }} />}
      </Flex>

      {renderStep()}
    </Flex>
  );
};

export default PaymentsPopover;
