export default class MerchantBranchDomain {
  id: string;

  merchantId: number;

  description: string;

  reference: string;

  constructor(
    id: string,
    merchantId: number,
    description: string,
    reference: string
  ) {
    this.id = id;
    this.merchantId = merchantId;
    this.description = description;
    this.reference = reference;
  }
}
