import { useAuth0 } from "@auth0/auth0-react";
import {
  Button,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  useClipboard,
  VStack,
} from "@chakra-ui/react";
import ConfirmationDialog from "components/shared/ConfirmationDialog";
import Spinner from "components/spinner";
import { useAppSelector } from "redux/hooks";
import React, { useEffect, useState } from "react";
import { MdContentCopy } from "react-icons/md";
import { RiCheckFill } from "react-icons/ri";
import MerchantService from "services/merchant";

const ApiKey = () => {
  const auth0Context = useAuth0();
  const { colorScheme } = useAppSelector((state) => state.theme);

  const [isLoading, setIsLoading] = useState(true);
  const [isGenerating, setIsGenerating] = useState(false);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [apiKey, setApiKey] = useState("");
  const { merchant } = useAppSelector((state) => state.merchant);

  const { hasCopied, onCopy } = useClipboard(apiKey);

  const loadApiKey = async () => {
    const currentApiKey = await MerchantService.getApiKey(
      auth0Context,
      merchant.id
    );
    if (currentApiKey) {
      setApiKey(currentApiKey);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    loadApiKey();
  }, []);

  const generateApiKey = async () => {
    setIsGenerating(true);
    const generatedApiKey = await MerchantService.generateApiKey(
      auth0Context,
      merchant.id
    );
    if (generatedApiKey) {
      setApiKey(generatedApiKey);
    }
    setIsGenerating(false);
  };

  return (
    <VStack spacing={4} w="100%">
      {isLoading ? (
        <Spinner />
      ) : (
        <InputGroup>
          <Input
            colorScheme={colorScheme}
            isDisabled={true}
            value={
              apiKey
                ? `${apiKey.substring(0, 4)}*****${apiKey.substring(
                    apiKey.length - 4,
                    apiKey.length
                  )}`
                : ""
            }
            placeholder="Generate your API Key"
            readOnly
          />
          <InputRightElement w="100px" pr="1" justifyContent="flex-end">
            {apiKey && (
              <IconButton
                mr={1}
                size="xs"
                aria-label="Copy API Key"
                variant="ghost"
                onClick={onCopy}
                icon={
                  hasCopied ? <RiCheckFill fill="green" /> : <MdContentCopy />
                }
              />
            )}
            <Button
              colorScheme={apiKey ? "green" : colorScheme}
              onClick={apiKey ? () => setIsRefreshing(true) : generateApiKey}
              size="xs"
              isLoading={isGenerating || isRefreshing}
            >
              {apiKey ? "Refresh" : "Generate"}
            </Button>
          </InputRightElement>
        </InputGroup>
      )}
      <ConfirmationDialog
        isOpen={isRefreshing}
        setIsOpen={setIsRefreshing}
        headerText="Do you really want to refresh your current API Key?"
        messageText="If you refresh your API key, associated Zaps won't work until updated with a new API key in the Zapier console. Do you really want to refresh your current API Key?"
        buttonText="Refresh"
        confirmationCallback={generateApiKey}
      />
    </VStack>
  );
};

export default ApiKey;
