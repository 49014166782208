import { Button, Heading, Icon, useToast } from "@chakra-ui/react";
import Integration from "components/user-settings/shared/Integration";
import IntegrationDescription from "components/user-settings/shared/IntegrationDescription";
import React, { useEffect, useState } from "react";
import { FaPlus } from "react-icons/fa";
import { ReactComponent as OutlookIcon } from "assets/icons/outlook.svg";
import { MerchantUserGuides } from "entities/domain/admin/merchants/merchant-user-guides";

import { updateMerchantSuccess } from "redux/features/merchant";
import IntegrationService from "services/integrations";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { useAuth0 } from "@auth0/auth0-react";
import DisconnectIntegrationButton from "components/user-settings/shared/DisconnectIntegrationButton";
import { isMobileApp } from "util/methods";
import { openOutlookIntegration } from "components/microsoft";
import { IntegrationName } from "entities/domain/admin/merchants/merchant-integrations";

interface OutlookIntegrationItemProps {}

const OutlookIntegrationItem = (_props: OutlookIntegrationItemProps) => {
  const dispatch = useAppDispatch();
  const auth0Context = useAuth0();
  const merchant = useAppSelector((state) => state.merchant.merchant);
  const toast = useToast();
  const { colorScheme } = useAppSelector((state) => state.theme);
  const [isDisabled, setIsDisabled] = useState<boolean>(false);

  const isConnected = merchant.isIntegrationEnabled(IntegrationName.OUTLOOK);

  const onDisconnectOutlook = async () => {
    try {
      await IntegrationService.removeMicrosoftOutlookIntegration(
        auth0Context,
        merchant.id
      );

      merchant.integrations[IntegrationName.OUTLOOK].connected = false;
      dispatch(updateMerchantSuccess(merchant));
    } catch (_error) {
      toast({
        status: "error",
        title:
          "Uh Oh! We could not disconnect Microsoft Outlook at this time. Please try again",
      });
    }
  };

  useEffect(() => {
    if (merchant.userGuides.pending.includes(MerchantUserGuides.ONBOARDING)) {
      setIsDisabled(true);

      return;
    }

    setIsDisabled(false);
  }, [merchant.userGuides.pending]);

  return (
    <Integration
      icon={<Icon as={OutlookIcon} height="3rem" width="3rem" />}
      isConnected={isConnected}
      secondaryButton={
        isConnected && !isDisabled ? (
          <DisconnectIntegrationButton onClick={onDisconnectOutlook} />
        ) : null
      }
      primaryButton={
        isConnected || isDisabled ? null : (
          <Button
            colorScheme={colorScheme}
            leftIcon={<Icon as={FaPlus} />}
            onClick={() => {
              if (isMobileApp()) {
                window.natively.openExternalURL(
                  openOutlookIntegration(merchant.id)
                );
              } else {
                window.open(openOutlookIntegration(merchant.id), "_blank");
              }
            }}
          >
            Connect
          </Button>
        )
      }
    >
      <Heading as="h3" size="lg">
        Outlook
      </Heading>
      <IntegrationDescription text="Streamline email, calendar, and contacts in one place for enhanced productivity." />
    </Integration>
  );
};

export default OutlookIntegrationItem;
