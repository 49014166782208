import MerchantBranchDomain from "entities/domain/branch";
import { MerchantBranchDTO } from "entities/dto/MerchantBranchDTO";

export const merchantBranchTransformFromDtoToDomain = (
  branch: MerchantBranchDTO
): MerchantBranchDomain =>
  new MerchantBranchDomain(
    branch.id,
    branch.merchant_id,
    branch.description,
    branch.reference
  );
