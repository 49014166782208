export default class BankAccountDetailsDomain {
  accountHolderName: string;

  accountNumber: string;

  sortCode: string;

  country: string;

  iban: string;

  bic: string;

  isBritishAccount(): boolean {
    return this.country === "GB";
  }

  constructor(
    accountHolderName: string,
    accountNumber: string,
    sortCode: string,
    country: string,
    iban: string,
    bic: string
  ) {
    this.accountHolderName = accountHolderName;
    this.accountNumber = accountNumber;
    this.sortCode = sortCode;
    this.country = country;
    this.iban = iban;
    this.bic = bic;
  }
}
