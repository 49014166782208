import { useBreakpointValue } from "@chakra-ui/react";
import ContactDomain from "entities/domain/customers/contact-domain";
import React from "react";
import { ContactsSorting } from "util/ContactsFilter";
import MobileTopbar from "./mobile";
import DesktopTopbar from "./desktop";

interface ContactsTopbarProps {
  setSearchText: (text: string) => void;
  searchText: string;
  setSorting: (sorting: ContactsSorting | undefined) => void;
  displayBackIcon: boolean;
  onBackIconClick?: () => void;
  displayMoreIcon: boolean;
  contactToDisplay: ContactDomain | undefined;
  setRecentlyCreatedContact: (contact: ContactDomain | undefined) => void;
  setRecentlyUpdatedContact: (contact: ContactDomain | undefined) => void;
  prefilledName: string;
  hideFilters?: boolean;
}

const ContactsTopbar = ({
  hideFilters = false,
  setSearchText,
  searchText,
  setSorting,
  displayBackIcon,
  onBackIconClick,
  displayMoreIcon,
  contactToDisplay,
  setRecentlyCreatedContact,
  setRecentlyUpdatedContact,
  prefilledName,
}: ContactsTopbarProps) => {
  const isBaseSize = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );

  return (
    <>
      {isBaseSize ? (
        <MobileTopbar
          setSorting={setSorting}
          displayBackIcon={displayBackIcon}
          displayMoreIcon={displayMoreIcon}
          onBackIconClick={onBackIconClick}
          contactToDisplay={contactToDisplay}
          setRecentlyUpdatedContact={setRecentlyUpdatedContact}
          setRecentlyCreatedContact={setRecentlyCreatedContact}
          prefilledName={prefilledName}
        />
      ) : (
        <DesktopTopbar
          hideFilters={hideFilters}
          setSearchText={setSearchText}
          prefilledName={prefilledName}
          searchText={searchText}
          setRecentlyCreatedContact={setRecentlyCreatedContact}
        />
      )}
    </>
  );
};

export default ContactsTopbar;
