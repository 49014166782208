import React from "react";
import { Flex, Button, useColorMode, Icon } from "@chakra-ui/react";
import { ReactComponent as OpenLockIcon } from "assets/icons/open-lock.svg";
import { ReactComponent as ClosedLockIcon } from "assets/icons/close-lock.svg";
import {
  OpenClosedFilter,
  setActiveConversationOpenClosedFilter,
} from "redux/features/conversations";
import { useAppDispatch, useAppSelector } from "redux/hooks";

const OpenClosedPills = () => {
  const { colorMode } = useColorMode();
  const dispatch = useAppDispatch();
  const isOpenOrClosed = useAppSelector(
    (state) => state.conversations.isOpenOrClosed
  );
  const { colorScheme } = useAppSelector((state) => state.theme);

  return (
    <Flex
      gridGap={2}
      justifyContent="start"
      alignItems="center"
      p={4}
      borderBottomWidth="1px"
      borderBottomStyle="solid"
      borderBottomColor={colorMode === "dark" ? "gray.700" : "gray.200"}
    >
      <Button
        isActive={isOpenOrClosed === OpenClosedFilter.Open}
        colorScheme={colorScheme}
        variant="ghost"
        leftIcon={
          <Icon
            as={OpenLockIcon}
            __css={{
              path: {
                fill:
                  colorMode === "dark"
                    ? `${colorScheme}.200`
                    : `${colorScheme}.600`,
              },
            }}
          />
        }
        onClick={() => {
          dispatch(
            setActiveConversationOpenClosedFilter(OpenClosedFilter.Open)
          );
        }}
      >
        Open
      </Button>
      <Button
        isActive={isOpenOrClosed === OpenClosedFilter.Closed}
        variant="ghost"
        colorScheme={colorScheme}
        leftIcon={
          <Icon
            as={ClosedLockIcon}
            __css={{
              path: {
                fill:
                  colorMode === "dark"
                    ? `${colorScheme}.200`
                    : `${colorScheme}.600`,
              },
            }}
          />
        }
        onClick={() => {
          dispatch(
            setActiveConversationOpenClosedFilter(OpenClosedFilter.Closed)
          );
        }}
      >
        Closed
      </Button>
    </Flex>
  );
};

export default OpenClosedPills;
