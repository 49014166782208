import { createSlice, Draft, PayloadAction } from "@reduxjs/toolkit";
import AudienceDomain from "entities/domain/audience";

export interface CreateAudiencePayload {
  name: string;
  merchantId: number;
  criteria: string;
}

export interface EditAudiencePayload {
  id: string;
  name: string;
  merchantId: number;
  criteria: string;
}

export interface DeleteAudiencePayload {
  id: string;
}

interface AudiencesState {
  loading: boolean;
  modalLoading: boolean;
  errors: string[];
  audiences: AudienceDomain[];
  toastMessage: {
    new: boolean;
    success: string;
    errors: string[];
  };
}

const initialState: AudiencesState = {
  audiences: [],
  loading: false,
  modalLoading: false,
  errors: [],
  toastMessage: {
    new: false,
    success: "",
    errors: [],
  },
};

export const insertAudience = (
  audience: AudienceDomain,
  audiences: Draft<AudienceDomain>[]
): Draft<AudienceDomain>[] => {
  if (audiences.filter((a) => a.id === audience.id).length !== 0) {
    return audiences.map((a) => (audience?.id === a.id ? audience : a));
  }

  const newAudiences = [...audiences];
  newAudiences.unshift(audience);
  return newAudiences;
};

const audiencesSlice = createSlice({
  name: "audiences",
  initialState,
  reducers: {
    fetchAudiences: (state) => {
      state.loading = true;
    },
    fetchAudiencesSuccess: (state, action: PayloadAction<AudienceDomain[]>) => {
      state.loading = false;
      action.payload.forEach((newAudience) => {
        state.audiences = insertAudience(newAudience, state.audiences);
      });
      state.errors = [];
      state.toastMessage = {
        new: false,
        success: "",
        errors: [],
      };
    },
    fetchAudiencesFail: (state, action: PayloadAction<string[]>) => {
      state.errors = action.payload;
      state.loading = false;
      state.toastMessage = {
        success: "",
        errors: action.payload,
        new: true,
      };
    },
    createAudience: (state) => {
      state.modalLoading = true;
    },
    createAudienceSuccess: (state, action: PayloadAction<AudienceDomain>) => {
      state.audiences = [...state.audiences, action.payload];
      state.modalLoading = false;
      state.toastMessage = {
        success: "Audience created successfully!",
        errors: [],
        new: true,
      };
    },
    createAudienceFail: (state, action: PayloadAction<string[]>) => {
      state.modalLoading = false;
      state.errors = action.payload;
      state.toastMessage = {
        success: "",
        errors: action.payload,
        new: true,
      };
    },
    editAudience: (state) => {
      state.modalLoading = true;
    },
    editAudienceSuccess: (state, action: PayloadAction<AudienceDomain>) => {
      state.audiences = state.audiences.map((audience) =>
        audience.id === action.payload.id ? action.payload : audience
      );
      state.modalLoading = false;
      state.toastMessage = {
        success: "Audience updated successfully!",
        errors: [],
        new: true,
      };
    },
    editAudienceFail: (state, action: PayloadAction<string[]>) => {
      state.modalLoading = false;
      state.errors = action.payload;
      state.toastMessage = {
        success: "",
        errors: action.payload,
        new: true,
      };
    },
    deleteAudience: (state) => {
      state.loading = true;
    },
    deleteAudienceSuccess: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.audiences = state.audiences.filter(
        (audience) => audience.id !== action.payload
      );
      state.toastMessage = {
        success: "Audience deleted successfully!",
        errors: [],
        new: true,
      };
    },
    deleteAudienceFail: (state, action: PayloadAction<string[]>) => {
      state.loading = false;
      state.errors = action.payload;
      state.toastMessage = {
        success: "",
        errors: action.payload,
        new: true,
      };
    },
    propagateAudienceUpdate: (state, action: PayloadAction<AudienceDomain>) => {
      state.audiences = insertAudience(action.payload, state.audiences);
    },
    resetStore: (state) => {
      state.audiences = [];
      state.loading = false;
      state.modalLoading = false;
      state.errors = [];
      state.toastMessage = {
        new: false,
        success: "",
        errors: [],
      };
    },
  },
});

export const {
  fetchAudiences,
  fetchAudiencesSuccess,
  fetchAudiencesFail,
  createAudience,
  createAudienceSuccess,
  createAudienceFail,
  editAudience,
  editAudienceSuccess,
  editAudienceFail,
  deleteAudience,
  deleteAudienceSuccess,
  deleteAudienceFail,
  propagateAudienceUpdate,
  resetStore,
} = audiencesSlice.actions;

export default audiencesSlice.reducer;
