import { Box, Text } from "@chakra-ui/react";
import { useAppSelector } from "redux/hooks";
import React from "react";

interface AlertBannerProps {
  content: string;
  bgColor?: string;
  borderColor?: string;
}

const AlertBanner = ({ content, bgColor, borderColor }: AlertBannerProps) => {
  const { colorScheme } = useAppSelector((state) => state.theme);

  return (
    <Box
      bg={bgColor || "rgba(255, 0, 0, 0.08)"}
      border={
        borderColor
          ? `1px solid ${borderColor}`
          : "1px solid rgba(255, 0, 0, 0.13)"
      }
      borderRadius="md"
      py="9px"
      px="9px"
    >
      <Text
        textAlign="center"
        fontSize="15px"
        lineHeight="20px"
        fontWeight="400"
        color={`${colorScheme}.600`}
      >
        {content}
      </Text>
    </Box>
  );
};

export default AlertBanner;
