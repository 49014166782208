import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AudienceCriteria } from "entities/domain/audience";
import ContactDomain from "entities/domain/customers/contact-domain";
import TagsDomain from "entities/domain/tags/tags-domain";
import { RootState } from "redux/store";

export interface ContactsState {
  loading: boolean;
  modalLoading: boolean;
  shouldUseCriteriaForSearch: boolean;
  advancedFilter: AudienceCriteria | null;
  toastMessage: {
    new: boolean;
    success: string;
    errors: string[];
  };
  contacts: ContactDomain[];
}

const initialState: ContactsState = {
  loading: false,
  modalLoading: false,
  shouldUseCriteriaForSearch: false,
  advancedFilter: null,
  toastMessage: {
    new: false,
    success: "",
    errors: [],
  },
  contacts: [],
};

const updateTags = (
  originalTags: TagsDomain[],
  updatedTags: TagsDomain[]
): TagsDomain[] => {
  const newTags = [...originalTags, ...updatedTags];

  return newTags;
};

export const contactsSelector = (state: RootState) => state.contacts;

export const selectContact = (
  contactId: number | undefined,
  contacts: ContactDomain[]
): ContactDomain | undefined => {
  if (!contactId || !contacts) return undefined;
  return contacts.find((contact) => contact.id === contactId);
};

const deleteTags = (
  originalTags: TagsDomain[],
  tagToDelete: string
): TagsDomain[] => {
  const newTags = originalTags.filter((tag) => tag.tag !== tagToDelete);

  return newTags;
};

const contactsSlice = createSlice({
  name: "contacts",
  initialState,
  reducers: {
    setContacts: (state, action: PayloadAction<ContactDomain[]>) => {
      state.contacts = action.payload;
    },
    updateContact: (state) => {
      state.modalLoading = true;
    },
    setAdvancedFilter: (
      state,
      action: PayloadAction<AudienceCriteria | null>
    ) => {
      state.advancedFilter = action.payload;
    },
    setShouldUseCriteriaForSearch: (state, action: PayloadAction<boolean>) => {
      state.shouldUseCriteriaForSearch = action.payload;
    },
    updateContactSuccess: (state, action: PayloadAction<ContactDomain>) => {
      state.contacts = state.contacts.map((contact) =>
        contact.id === action.payload.id ? action.payload : contact
      );
      state.modalLoading = false;
      state.toastMessage = {
        success: "Contact updated successfully!",
        errors: [],
        new: true,
      };
    },
    updateContactFail: (state, action: PayloadAction<string[]>) => {
      state.modalLoading = false;
      state.toastMessage = {
        success: "",
        errors: action.payload,
        new: true,
      };
    },
    mergeContacts: (state) => {
      state.modalLoading = true;
    },
    mergeContactsSuccess: (
      state,
      action: PayloadAction<{
        contact: ContactDomain;
        deletedContacts: number[];
      }>
    ) => {
      state.modalLoading = false;
      state.contacts = state.contacts
        .map((contact) =>
          contact.id === action.payload.contact.id
            ? action.payload.contact
            : contact
        )
        .filter(
          (contact) => !action.payload.deletedContacts.includes(contact.id!)
        );
      state.toastMessage = {
        success: "Contacts merged successfully!",
        errors: [],
        new: true,
      };
    },
    mergeContactsFail: (state, action: PayloadAction<string[]>) => {
      state.modalLoading = false;
      state.toastMessage = {
        success: "",
        errors: action.payload,
        new: true,
      };
    },
    createContact: (state) => {
      state.modalLoading = true;
    },
    createContactSuccess: (state, action: PayloadAction<ContactDomain>) => {
      state.contacts = [...state.contacts, action.payload];
      state.modalLoading = false;
      state.toastMessage = {
        success: "Contact successfully created!",
        errors: [],
        new: true,
      };
    },
    createContactFail: (state, action: PayloadAction<string[]>) => {
      state.modalLoading = false;
      state.toastMessage = {
        success: "",
        errors: action.payload,
        new: true,
      };
    },
    deleteContact: (state) => {
      state.modalLoading = true;
    },
    deleteContactSuccess: (state, action: PayloadAction<number>) => {
      state.contacts = state.contacts.filter((c) => c.id !== action.payload);
      state.modalLoading = false;
      state.toastMessage = {
        success: "Contact successfully deleted!",
        errors: [],
        new: true,
      };
    },
    deleteContactFail: (state, action: PayloadAction<string[]>) => {
      state.modalLoading = false;
      state.toastMessage = {
        success: "",
        errors: action.payload,
        new: true,
      };
    },
    cleanContactsToastMessages: (state) => {
      state.toastMessage = {
        success: "",
        errors: [],
        new: false,
      };
    },
    updateContactTags: (state) => {
      state.loading = true;
    },
    updateContactTagsSuccess: (
      state,
      action: PayloadAction<{ id: number; tagIds: string[] }>
    ) => {
      state.contacts = state.contacts.map((contact) => {
        if (contact.id === action.payload.id) {
          return Object.setPrototypeOf(
            {
              ...contact,
              tagIds: action.payload.tagIds,
            },
            ContactDomain.prototype
          );
        }

        return contact;
      });
      state.loading = false;
      state.toastMessage = {
        success: "Tags successfully updated!",
        errors: [],
        new: true,
      };
    },
    updateContactTagsFail: (state, action: PayloadAction<string[]>) => {
      state.loading = false;
      state.toastMessage = {
        success: "",
        errors: action.payload,
        new: true,
      };
    },
    deleteContactTags: (state) => {
      state.loading = true;
    },
    deleteContactTagsSuccess: (
      state,
      action: PayloadAction<{ id: number; tagIds: string[] }>
    ) => {
      state.contacts = state.contacts.map((contact) => {
        if (contact.id === action.payload.id) {
          return Object.setPrototypeOf(
            {
              ...contact,
              tagIds: action.payload.tagIds,
            },
            ContactDomain.prototype
          );
        }

        return contact;
      });
      state.loading = false;
      state.toastMessage = {
        success: "Tag successfully deleted!",
        errors: [],
        new: true,
      };
    },
    deleteContactTagsFail: (state, action: PayloadAction<string[]>) => {
      state.loading = false;
      state.toastMessage = {
        success: "",
        errors: action.payload,
        new: true,
      };
    },
    bulkUpdateContacts: (state, action: PayloadAction<ContactDomain[]>) => {
      state.contacts = state.contacts.map((contactFromState) => {
        const contactToUpdate = action.payload.find(
          (contact) => contact.id === contactFromState.id
        );

        if (contactToUpdate) {
          return contactToUpdate;
        }

        return contactFromState;
      });
    },
    resetStore: (state) => {
      state.loading = false;
      state.modalLoading = false;
      state.shouldUseCriteriaForSearch = false;
      state.advancedFilter = null;
      state.toastMessage = {
        new: false,
        success: "",
        errors: [],
      };
      state.contacts = [];
    },
  },
});

export const {
  setContacts,
  updateContact,
  updateContactSuccess,
  updateContactFail,
  setShouldUseCriteriaForSearch,
  mergeContacts,
  mergeContactsSuccess,
  mergeContactsFail,
  createContact,
  createContactSuccess,
  createContactFail,
  deleteContact,
  setAdvancedFilter,
  deleteContactSuccess,
  deleteContactFail,
  cleanContactsToastMessages,
  updateContactTags,
  updateContactTagsSuccess,
  updateContactTagsFail,
  deleteContactTags,
  deleteContactTagsSuccess,
  deleteContactTagsFail,
  bulkUpdateContacts,
  resetStore,
} = contactsSlice.actions;

export default contactsSlice.reducer;
