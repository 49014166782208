import { Box, Link, useColorMode } from "@chakra-ui/react";
import { useAppSelector } from "redux/hooks";
import React, { ReactNode } from "react";
import { NavLink } from "react-router-dom";

interface FooterNavLinkProps {
  children: ReactNode;
  hasNotifications?: boolean;
  to: string;
}

const FooterNavLink = ({
  children,
  to,
  hasNotifications = false,
}: FooterNavLinkProps) => {
  const { colorScheme } = useAppSelector((state) => state.theme);
  const { colorMode } = useColorMode();

  return (
    <Link
      as={NavLink}
      to={to}
      _activeLink={{
        svg: {
          path: {
            fill: `${colorScheme}.400`,
          },
        },
      }}
      display="flex"
      justifyContent="center"
      alignItems="center"
      position="relative"
    >
      {children}
      {hasNotifications ? (
        <Box
          position="absolute"
          top="-0.25rem"
          right="-0.25rem"
          zIndex="1"
          borderRadius="full"
          height="1rem"
          width="1rem"
          bgColor={colorMode === "dark" ? "red.200" : "red.500"}
        />
      ) : null}
    </Link>
  );
};

export default FooterNavLink;
