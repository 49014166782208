import { Icon } from "@chakra-ui/react";
import React from "react";
import { ReactComponent as CallOutIcon } from "assets/icons/call-out-svgrepo-com.svg";
import { ReactComponent as CallInIcon } from "assets/icons/call-in-svgrepo-com.svg";
import { ReactComponent as MissedCallIcon } from "assets/icons/call-miss-svgrepo-com.svg";
import MessageDomain, {
  MessageSubType,
} from "entities/domain/conversations/message-domain";

const PhoneIcon = ({
  message,
  colorMode,
}: {
  message: MessageDomain;
  colorMode: "dark" | "light";
}) => {
  if (message.subType === MessageSubType.MISSED_CALL) {
    return (
      <Icon
        as={MissedCallIcon}
        width="1.25rem"
        height="1.25rem"
        __css={{
          path: {
            fill: colorMode === "dark" ? "gray.50" : "gray.700",
            stroke: colorMode === "dark" ? "gray.50" : "gray.700",
          },
        }}
      />
    );
  }

  if (message.subType === MessageSubType.FINISHED_CALL) {
    return (
      <Icon
        as={message.isIncoming ? CallInIcon : CallOutIcon}
        width="1.25rem"
        height="1.25rem"
        __css={{
          path: {
            fill: colorMode === "dark" ? "gray.50" : "gray.700",
            stroke: colorMode === "dark" ? "gray.50" : "gray.700",
          },
        }}
      />
    );
  }

  return null;
};

export default PhoneIcon;
