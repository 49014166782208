import { MoonIcon, SunIcon } from "@chakra-ui/icons";
import {
  Button,
  Flex,
  HStack,
  Icon,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  VStack,
  useBreakpointValue,
  useColorMode,
  useToast,
} from "@chakra-ui/react";
import Select from "react-select";
import { useAppSelector, useAppDispatch } from "redux/hooks";
import React, { useEffect, useState } from "react";
import { setColorScheme } from "redux/features/theme";
import { getReactSelectStyles } from "util/methods";
import AgentsService from "services/agents";
import { useAuth0 } from "@auth0/auth0-react";

interface StylePreferencesProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}

interface OptionTypes {
  value: string;
  label: string;
}

const StylePreferences = ({ isOpen, setIsOpen }: StylePreferencesProps) => {
  const { colorScheme } = useAppSelector((state) => state.theme);
  const dispatch = useAppDispatch();
  const auth0Context = useAuth0();
  const { currentAgent } = useAppSelector((state) => state.agents);
  const { merchant } = useAppSelector((state) => state.merchant);
  const { colorMode, toggleColorMode } = useColorMode();
  const isBaseSize = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );
  const toast = useToast();

  const [colorSchemeValue, setColorSchemeValue] = useState<string>(
    currentAgent?.stylePreferences?.colorScheme || "blue"
  );

  const colorSchemeValueOptions: {
    value: string;
    label: string;
  }[] = [
    { value: "blue", label: "Fuzey Blue" },
    { value: "yellow", label: "Yellow" },
    { value: "green", label: "Green" },
    { value: "purple", label: "Purple" },
    { value: "pink", label: "Pink" },
    { value: "cyan", label: "Cyan" },
    { value: "teal", label: "Teal" },
  ];

  useEffect(() => {
    if (colorSchemeValue === colorScheme) {
      return;
    }

    dispatch(setColorScheme(colorSchemeValue));
  }, [colorSchemeValue]);

  useEffect(() => {
    if (!currentAgent) {
      return;
    }

    setColorSchemeValue(currentAgent.stylePreferences?.colorScheme || "blue");
  }, [currentAgent]);

  const [isSaving, setIsSaving] = useState<boolean>(false);

  const updateAgent = async () => {
    setIsSaving(true);

    try {
      await AgentsService.updateAgent(
        auth0Context,
        {
          id: currentAgent!.id,
          name: currentAgent!.name,
          surname: currentAgent!.surname,
          email: currentAgent!.email,
          notification_handle: currentAgent!.notificationConfig?.handle || "",
          notification_channel:
            currentAgent!.notificationConfig?.contactChannel || "",
          notification_preference:
            currentAgent!.notificationConfig?.preference || "",
          notification_timezone:
            currentAgent!.notificationConfig?.timezone || "",
          role_id: currentAgent!.getRole(merchant.id).id,
          active: currentAgent!.isActive,
          profile_picture_url: currentAgent!.getPicture(),
          player_ids: currentAgent!.playerIds || null,
          style_preferences: {
            chat_background:
              currentAgent!.stylePreferences?.chatBackground || null,
            color_scheme: colorSchemeValue,
          },
        },
        merchant.id
      );
      setIsOpen(false);
    } catch (e: unknown) {
      toast({
        title: "Error occured while saving style preferences",
        status: "error",
      });
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <Modal
      size={isBaseSize ? "full" : "lg"}
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Style Preferences</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack mb={4} w="100%">
            <HStack w="100%" mb={4}>
              <Select
                isDisabled={isSaving}
                placeholder="Select color scheme"
                isClearable={false}
                onChange={(v) => setColorSchemeValue(v!.value)}
                options={colorSchemeValueOptions}
                value={colorSchemeValueOptions.filter(
                  (option) => option.value === colorSchemeValue
                )}
                styles={{
                  ...{
                    ...getReactSelectStyles(colorMode, colorScheme),
                    container: (provided: any) => ({
                      ...provided,
                      width: "100%",
                    }),
                  },
                }}
              />

              <IconButton
                colorScheme="gray"
                size="md"
                aria-label="Toggle color mode"
                icon={
                  <Icon
                    as={colorMode === "dark" ? MoonIcon : SunIcon}
                    __css={{
                      fill: colorMode === "dark" ? "gray.900" : "white",
                    }}
                  />
                }
                onClick={toggleColorMode}
              />
            </HStack>
            <Button
              alignSelf="end"
              colorScheme={colorScheme}
              size="md"
              onClick={updateAgent}
              isDisabled={isSaving}
            >
              Save
            </Button>
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default StylePreferences;
