import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import CalendarBookingDomain from "entities/domain/calendar/calendar-domain";

interface CalendarState {
  events: CalendarBookingDomain[];
  loading: boolean;
  errors: string[];
  selectedAgentIds: number[];
}

const initialState: CalendarState = {
  events: [],
  loading: false,
  errors: [],
  selectedAgentIds: [],
};

export interface GetCalendarEventsPayload {
  startAt: string;
  endAt: string;
  timezone: string;
}

export interface CreateCalendarEventPayload {
  startAt: string;
  endAt: string;
  tz: string;
  title: string;
  description: string;
  agentIds: number[];
  customerId: number | null;
  notificationCustomerChannelId: string;
}

export interface UpdateCalendarEventPayload {
  bookingId: string;
  startAt: string;
  endAt: string;
  tz: string;
  title: string;
  description: string;
  agentIds: number[];
  customerId: number | null;
  notifyCustomer?: boolean;
  notificationCustomerChannelId?: string;
}

export interface DeleteCalendarEventPayload {
  bookingId: string;
}

const calendarSlice = createSlice({
  name: "calendar",
  initialState,
  reducers: {
    fetchAllCalendarEvents: (state) => {
      state.loading = true;
    },
    fetchAllCalendarEventsSuccess: (
      state,
      action: PayloadAction<CalendarBookingDomain[]>
    ) => {
      state.loading = false;
      state.events = action.payload;
      state.errors = [];
    },
    fetchAllCalendarEventsFail: (state, action: PayloadAction<string[]>) => {
      state.errors = action.payload;
      state.loading = false;
    },
    createCalendarEvent: (state) => {
      state.loading = true;
    },
    createCalendarEventSuccess: (
      state,
      action: PayloadAction<CalendarBookingDomain>
    ) => {
      state.loading = false;
      state.events = [...state.events, action.payload];
      state.errors = [];
    },
    createCalendarEventFail: (state, action: PayloadAction<string[]>) => {
      state.errors = action.payload;
      state.loading = false;
    },
    setSelectedCalendarAgents: (state, action: PayloadAction<number[]>) => {
      state.selectedAgentIds = action.payload;
    },
    deleteCalendarEvent: (state) => {
      state.loading = true;
    },
    deleteCalendarEventSuccess: (
      state,
      action: PayloadAction<{ bookingId: string }>
    ) => {
      state.loading = false;
      state.events = state.events.filter(
        (e) => e.id !== action.payload.bookingId
      );
      state.errors = [];
    },
    deleteCalendarEventFail: (state, action: PayloadAction<string[]>) => {
      state.errors = action.payload;
      state.loading = false;
    },
    updateCalendarEvent: (state) => {
      state.loading = true;
    },
    updateCalendarEventSuccess: (
      state,
      action: PayloadAction<CalendarBookingDomain>
    ) => {
      state.loading = false;
      state.events = state.events.map((event) =>
        event.id === action.payload.id ? action.payload : event
      );
      state.errors = [];
    },
    updateCalendarEventFail: (state, action: PayloadAction<string[]>) => {
      state.errors = action.payload;
      state.loading = false;
    },
    resetStore: (state) => {
      state.events = [];
      state.loading = false;
      state.errors = [];
      state.selectedAgentIds = [];
    },
  },
});

export const {
  fetchAllCalendarEvents,
  fetchAllCalendarEventsFail,
  fetchAllCalendarEventsSuccess,
  createCalendarEvent,
  createCalendarEventFail,
  createCalendarEventSuccess,
  setSelectedCalendarAgents,
  deleteCalendarEvent,
  deleteCalendarEventFail,
  deleteCalendarEventSuccess,
  updateCalendarEvent,
  updateCalendarEventFail,
  updateCalendarEventSuccess,
  resetStore,
} = calendarSlice.actions;
export default calendarSlice.reducer;
