import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input as ChakraInput,
  InputProps as ChakraInputProps,
} from "@chakra-ui/react";
import { useAppSelector } from "redux/hooks";
import React, { forwardRef, ForwardRefRenderFunction } from "react";
import { FieldError } from "react-hook-form";
import { ContactFormInputConfigProps } from "./ContactFormConfig";

export type InputProps = {
  label: string;
  id: string;
  borderColor?: string;
  error?: FieldError;
} & ChakraInputProps &
  ContactFormInputConfigProps;

const Input: ForwardRefRenderFunction<HTMLInputElement, InputProps> = (
  {
    label,
    id,
    borderColor,
    usePlaceholders,
    placeholderColor,
    error = undefined,
    ...otherProps
  }: InputProps,
  ref
) => {
  const { colorScheme } = useAppSelector((state) => state.theme);

  return (
    <FormControl isInvalid={!!error}>
      {!usePlaceholders && (
        <FormLabel htmlFor={id} fontWeight="normal">
          {label}
        </FormLabel>
      )}
      <ChakraInput
        name={id}
        id={id}
        label={label}
        borderRadius="full"
        borderColor={borderColor || `${colorScheme}.400`}
        _focus={{ borderColor: `${borderColor || "inherit"}` }}
        placeholder={usePlaceholders ? label : ""}
        _placeholder={
          placeholderColor
            ? {
                color: `${placeholderColor}`,
              }
            : {}
        }
        ref={ref}
        {...otherProps}
      />
      {error && <FormErrorMessage>{error.message}</FormErrorMessage>}
    </FormControl>
  );
};

export default forwardRef(Input);
