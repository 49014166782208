/* eslint-disable react/prop-types */
import {
  FormControl,
  FormLabel,
  Input,
  Stack,
  useColorMode,
  useToast,
} from "@chakra-ui/react";
import WarningTextComponent, {
  WarningTextProps,
} from "components/shared/WarningTextComponent";
import {
  Button,
  ButtonSize,
  ButtonType,
} from "theme/old-design-system/styled-components";
import { useAppSelector } from "redux/hooks";
import React, { ChangeEvent, useEffect, useState } from "react";
import Select, { SingleValue } from "react-select";

import { BankAccountType, BankDetailsType } from "entities/dto/MerchantDTO";
import { getReactSelectStyles } from "util/methods";
import { useAuth0 } from "@auth0/auth0-react";
import AdminMerchantDomain from "entities/domain/admin/merchants/admin-merchant-domain";
import AdminService, { UpdateMerchantBankDetailsPayload } from "services/admin";
import SelectMerchant from "./select-merchant";

interface CountryOptionTypes {
  value: string;
  label: string;
}

const BankDetails = () => {
  const auth0Context = useAuth0();
  const { colorMode } = useColorMode();
  const { colorScheme } = useAppSelector((state) => state.theme);
  const toast = useToast();

  const countryOptions: CountryOptionTypes[] = [
    { value: "GB", label: "United Kingdom" },
    { value: "IE", label: "Ireland" },
  ];

  const [selectedMerchant, setSelectedMerchant] =
    useState<AdminMerchantDomain | null>(null);
  const [countryValue, setCountryValue] = useState<string>(
    countryOptions[0].value
  );
  const [selectedCountryValue, setSelectedCountryValue] = useState<
    SingleValue<CountryOptionTypes>
  >(countryOptions[0]);
  const [beneficiaryNameValue, setBeneficiaryNameValue] = useState<string>("");
  const [bankAccountNumValue, setBankAccountNumValue] = useState<string>("");
  const [sortCodeValue, setSortCodeValue] = useState<string>("");
  const [ibanValue, setIbanValue] = useState<string>("");
  const [bicValue, setBicValue] = useState<string>("");
  const [warning, setWarning] = useState<WarningTextProps | undefined>(
    undefined
  );

  useEffect(() => {
    setWarning(undefined);
  }, []);

  const isValidBankDetails = () => {
    if (
      bankAccountNumValue !== "" &&
      (bankAccountNumValue.length < 7 || bankAccountNumValue.length > 8)
    ) {
      setWarning({
        key: "invalid_bank_acct",
        description: "* Bank Account Number should be between 7 and 8 digits",
      });
    } else if (sortCodeValue !== "" && sortCodeValue.length !== 6) {
      setWarning({
        key: "invalid_sort_code",
        description: "* Sort code should be 6 digits",
      });
    } else if (ibanValue !== "" && ibanValue.length >= 34) {
      setWarning({
        key: "invalid_iban",
        description: " * Iban should not be more than 34 characters",
      });
    } else if (bicValue !== "" && bicValue.length < 8) {
      setWarning({
        key: "invalid_bic",
        description: "* BIC should not be less than 8 characters",
      });
    } else if (bicValue.length > 11) {
      setWarning({
        key: "invalid_bic",
        description: "* BIC should not be more than 11 characters",
      });
    } else {
      setWarning(undefined);
    }
  };

  const handleOnCountryChange = (e: SingleValue<CountryOptionTypes>) => {
    setCountryValue(e!.value);
    setSelectedCountryValue(e);
  };

  const handleNameChange = (event: ChangeEvent<HTMLInputElement>) =>
    setBeneficiaryNameValue(event.target.value);

  const handleAcctNumChange = (event: ChangeEvent<HTMLInputElement>) =>
    setBankAccountNumValue(event.target.value);

  const handleSortCodeChange = (event: ChangeEvent<HTMLInputElement>) =>
    setSortCodeValue(event.target.value);

  const handleIbanChange = (event: ChangeEvent<HTMLInputElement>) =>
    setIbanValue(event.target.value);

  const handleBicChange = (event: ChangeEvent<HTMLInputElement>) =>
    setBicValue(event.target.value);

  const validBankAcctCheck =
    bankAccountNumValue.length === 7 || bankAccountNumValue.length === 8;

  const invalidBicCheck = bicValue.length >= 8 && bicValue.length <= 11;

  const isValidInfoGB = !validBankAcctCheck || sortCodeValue.length !== 6;

  const isValidInfoEU = !invalidBicCheck || !(ibanValue.length <= 34);

  const isValidBankInfo = countryValue === "GB" ? isValidInfoGB : isValidInfoEU;

  const isDisabled = (): boolean =>
    !selectedMerchant || !beneficiaryNameValue || isValidBankInfo || !!warning;

  const bankDetailsObj: BankDetailsType = {};

  const bankAcctObj: BankAccountType = {
    country: countryValue,
    beneficiary_name: beneficiaryNameValue,
    details: bankDetailsObj,
  };

  const updateMerchant = async () => {
    if (countryValue === "GB") {
      bankDetailsObj.account_number = bankAccountNumValue;
      bankDetailsObj.sort_code = sortCodeValue;
    }

    if (countryValue !== "GB") {
      bankDetailsObj.bic = bicValue;
      bankDetailsObj.iban = ibanValue;
    }

    const updateMerchantObj: UpdateMerchantBankDetailsPayload = {
      merchantId: selectedMerchant!.id,
      bank_account: bankAcctObj,
    };

    await AdminService.updateMerchantBankDetails(
      auth0Context,
      updateMerchantObj
    );
  };

  const resetValues = () => {
    setSelectedMerchant(null);
    setCountryValue(countryOptions[0].value);
    setBeneficiaryNameValue("");
    setBankAccountNumValue("");
    setSortCodeValue("");
  };

  const handleSaveMerchant = async () => {
    try {
      await updateMerchant();
      toast({
        status: "success",
        title: "Merchant Updated Successfully",
      });
    } catch (error: unknown) {
      // eslint-disable-next-line no-console
      console.error(error);
      toast({
        status: "error",
        title: "Error updating merchant",
      });
    } finally {
      resetValues();
    }
  };

  return (
    <div>
      <Stack spacing={3}>
        <FormControl isRequired>
          <FormLabel>Select Merchant</FormLabel>
          <SelectMerchant
            selectedMerchant={selectedMerchant}
            setSelectedMerchant={setSelectedMerchant}
          />
        </FormControl>
        <FormControl isRequired>
          <FormLabel> Country </FormLabel>
          <Select
            placeholder="Select Country"
            onChange={handleOnCountryChange}
            options={countryOptions}
            value={selectedCountryValue}
            styles={getReactSelectStyles(colorMode, colorScheme)}
          />
        </FormControl>
        <FormControl isRequired>
          <FormLabel mb="8px"> Beneficiary Name</FormLabel>
          <Input
            value={beneficiaryNameValue}
            onChange={handleNameChange}
            placeholder="John Doe"
          />
        </FormControl>
        <FormControl
          isRequired
          display={countryValue === "GB" ? "initial" : "none"}
        >
          <FormLabel mb="8px"> Bank Account Number</FormLabel>
          {warning && warning.key === "invalid_bank_acct" && (
            <WarningTextComponent isValid={false} text={warning.description} />
          )}
          <Input
            value={bankAccountNumValue}
            onChange={handleAcctNumChange}
            placeholder="xxxxxxxx"
            onBlur={isValidBankDetails}
          />
        </FormControl>
        <FormControl
          isRequired
          display={countryValue === "GB" ? "initial" : "none"}
        >
          <FormLabel mb="8px"> Bank Sort Code</FormLabel>
          {warning && warning.key === "invalid_sort_code" && (
            <WarningTextComponent isValid={false} text={warning.description} />
          )}
          <Input
            value={sortCodeValue}
            onChange={handleSortCodeChange}
            placeholder="xxxxxx"
            onBlur={isValidBankDetails}
          />
        </FormControl>
        <FormControl
          isRequired
          display={countryValue !== "GB" ? "initial" : "none"}
        >
          <FormLabel mb="8px"> IBAN </FormLabel>
          {warning && warning.key === "invalid_iban" && (
            <WarningTextComponent isValid={false} text={warning.description} />
          )}
          <Input
            value={ibanValue}
            onChange={handleIbanChange}
            placeholder="IExxxxxxxxxxxxxxxxxxxx"
            onBlur={isValidBankDetails}
          />
        </FormControl>
        <FormControl
          isRequired
          display={countryValue !== "GB" ? "initial" : "none"}
        >
          <FormLabel mb="8px"> Bank Identifier Code (BIC) </FormLabel>

          {warning && warning.key === "invalid_bic" && (
            <WarningTextComponent isValid={false} text={warning.description} />
          )}
          <Input
            value={bicValue}
            onChange={handleBicChange}
            placeholder="xxxxxx"
            onBlur={isValidBankDetails}
          />
        </FormControl>
      </Stack>
      <Button
        type={ButtonType.PRIMARY}
        onClick={handleSaveMerchant}
        isDisabled={isDisabled()}
        mb={30}
        mt={30}
        pl={10}
        pr={10}
        size={ButtonSize.LARGE}
      >
        Save Information
      </Button>
    </div>
  );
};

export default BankDetails;
