import { v4 as uuidv4 } from "uuid";

const outlookClientId = process.env.REACT_APP_OUTLOOK_OAUTH2_CLIENT_ID;

const base_params = {
  response_type: "code",
  response_mode: "query",
};

const oauth2Endpoint =
  "https://login.microsoftonline.com/common/oauth2/v2.0/authorize";

export const openOutlookIntegration = (merchantId: number) => {
  const outlook_params: {
    [key: string]: string | undefined;
  } = {
    ...base_params,
    client_id: outlookClientId,
    state: merchantId.toString(),
    redirect_uri: `https://${window.location.hostname}/microsoft/callback`,
    // redirect_uri: `http://localhost:8888/microsoft/callback`,
    scope:
      "openid offline_access email Mail.Read Mail.Read.Shared Mail.ReadBasic Mail.ReadBasic.Shared Mail.ReadWrite Mail.ReadWrite.Shared Mail.Send Mail.Send.Shared profile User.Read",
  };

  localStorage.setItem("0auth2.0State", JSON.stringify(outlook_params.state));

  // @ts-ignore
  const url = `${oauth2Endpoint}?${Object.keys(outlook_params)
    .map((name) => `${name}=${outlook_params[name]}`)
    .join("&")}`;

  return url;
};
