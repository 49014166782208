import { Auth0ContextInterface } from "@auth0/auth0-react";
import { SetUpXeroPayload } from "redux/features/xero";
import TenantDomain from "entities/domain/xero/tenant-domain";
import XeroDomain from "entities/domain/xero/xero-domain";
import { RequestType } from "services/request-type";
import {
  tenantTransformFromDtoToDomain,
  xeroTransformFromDtoToDomain,
} from "entities/transformers/xero-transformer";
import { TenantDTO } from "entities/dto/XeroDTO";
import { mutationRequest, request } from "util/methods";

export default class InvoiceService {
  // XERO
  public static async sendXeroCode(
    { getAccessTokenSilently }: Auth0ContextInterface,
    authorization_code: string | null,
    merchantId: number
  ) {
    const accessToken = await getAccessTokenSilently();

    const message = (
      await mutationRequest(
        RequestType.POST,
        accessToken,
        `/merchants/${merchantId}/xero/authorize`,
        {
          authorization_code,
        }
      )
    ).data;

    return undefined;
  }

  public static async getAllXeroTenants(
    { getAccessTokenSilently }: Auth0ContextInterface,
    merchantId: number
  ): Promise<TenantDomain[]> {
    const accessToken = await getAccessTokenSilently();
    const data = await request(
      RequestType.GET,
      accessToken,
      `/merchants/${merchantId}/xero/tenants`
    );

    const tenants: TenantDTO[] = data.data;
    return tenants.map(tenantTransformFromDtoToDomain);
  }

  public static async setUpXeroAccount(
    { getAccessTokenSilently }: Auth0ContextInterface,
    setUpXeroPayload: SetUpXeroPayload,
    merchantId: number
  ): Promise<XeroDomain> {
    const accessToken = await getAccessTokenSilently();
    const { tenant_id } = setUpXeroPayload;

    const xero = (
      await mutationRequest(
        RequestType.POST,
        accessToken,
        `/merchants/${merchantId}/xero/setup`,
        {
          tenant_id,
        }
      )
    ).data;

    return xeroTransformFromDtoToDomain(xero);
  }

  // QUICKBOOKS

  public static async sendQuickbooksCode(
    { getAccessTokenSilently }: Auth0ContextInterface,
    authorization_code: string | null,
    company_id: string | null,
    merchantId: number
  ) {
    const accessToken = await getAccessTokenSilently();

    const message = (
      await mutationRequest(
        RequestType.POST,
        accessToken,
        `/merchants/${merchantId}/quickbooks/authorize`,
        {
          authorization_code,
          company_id,
        }
      )
    ).data;

    return message;
  }
}
