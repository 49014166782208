import React, { memo } from "react";
import FilePreview from "components/shared/FilePreview";
import ThumbnailWrapper from "./ThumbnailWrapper";

interface VideoThumbnailProps {
  url: string;
  itemId: string;
  openGallery?: (startId: string) => void;
}

const VideoThumbnail = ({ url, itemId, openGallery }: VideoThumbnailProps) => {
  return (
    <ThumbnailWrapper itemId={itemId} openGallery={openGallery}>
      <FilePreview
        url={url}
        type="video/mp4"
        isInteractive={false}
        styles={{
          boxSize: "6rem",
        }}
      />
    </ThumbnailWrapper>
  );
};

export default memo(VideoThumbnail);
