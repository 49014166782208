import { useAuth0 } from "@auth0/auth0-react";
import {
  Button,
  Box,
  FormControl,
  FormLabel,
  Stack,
  useColorMode,
  useToast,
} from "@chakra-ui/react";
import { useAppSelector } from "redux/hooks";
import React, { useEffect, useState } from "react";
import Select from "react-select";
import AuthorizationService from "services/authorization";
import { getReactSelectStyles } from "util/methods";
import AdminService from "services/admin";
import NewAgentDomain from "entities/domain/agents/new-agent-domain";
import AdminMerchantDomain from "entities/domain/admin/merchants/admin-merchant-domain";
import SelectMerchant from "./select-merchant";
import SelectAgent from "./select-agent";

interface RoleOptionTypes {
  value: number;
  label: string;
}

const AssignToMerchantForm = ({
  cache,
  clearCache,
}: {
  cache: object;
  clearCache: () => void;
}) => {
  const auth0 = useAuth0();
  const { colorMode } = useColorMode();
  const toast = useToast();
  const { colorScheme } = useAppSelector((state) => state.theme);

  const [selectedAgents, setSelectedAgents] = useState<NewAgentDomain[]>([]);
  const [selectedMerchants, setSelectedMerchants] = useState<
    AdminMerchantDomain[]
  >([]);

  const [selectedRolesMap, setSelectedRolesMap] = useState<{
    [key: number]: number | null;
  }>({});

  const [roleOptionsMap, setRoleOptionsMap] = useState<{
    [key: number]: RoleOptionTypes[];
  }>({});

  const resetRolesAndOptions = () => {
    setSelectedRolesMap({});
    setRoleOptionsMap({});
  };

  const getUpdatedSelectedRolesMap = () => {
    const newSelectedRolesMap = { ...selectedRolesMap }; // doesn't need deep copy because of simple types

    Object.keys(newSelectedRolesMap).forEach((key) => {
      if (!selectedMerchants.some((m) => m.id === Number(key))) {
        delete newSelectedRolesMap[Number(key)];
      }
    });

    selectedMerchants.forEach(({ id: merchantId }) => {
      if (!newSelectedRolesMap[merchantId]) {
        newSelectedRolesMap[merchantId] = null;
      }
    });

    return newSelectedRolesMap;
  };

  useEffect(() => {
    if (!selectedMerchants.length) {
      resetRolesAndOptions();
      return;
    }

    setSelectedRolesMap(getUpdatedSelectedRolesMap());

    const updatedRoleOptionsMap = JSON.parse(JSON.stringify(roleOptionsMap)); // needs a deep copy because of objects inside

    const promises = selectedMerchants.map(({ id: merchantId }) => {
      return AuthorizationService.getRoles(auth0, merchantId).then(
        (rolesReturned) => {
          updatedRoleOptionsMap[merchantId] =
            rolesReturned.map<RoleOptionTypes>((role) => ({
              value: role.id,
              label: role.name,
            }));
        }
      );
    });

    Promise.all(promises).then(() => {
      setRoleOptionsMap(updatedRoleOptionsMap);
    });
  }, [selectedMerchants]);

  const resetValues = () => {
    setSelectedAgents([]);
    setSelectedMerchants([]);
    resetRolesAndOptions();
    clearCache();
  };

  const saveAgent = async () => {
    try {
      await AdminService.assignAgentToMerchant(auth0, {
        agentIds: selectedAgents.map((a) => a.id),
        merchantsRoles: selectedRolesMap as { [key: number]: number },
      });
      toast({
        status: "success",
        title: "Agent(s) was successfully assigned to merchant",
      });
    } catch (e: unknown) {
      // eslint-disable-next-line
      console.error("Couldn't assign agent(s) to merchant", e);
      toast({
        status: "error",
        title: "Couldn't assign agent(s) to merchant",
      });
    } finally {
      resetValues();
    }
  };

  return (
    <Box>
      <Stack spacing={3}>
        <FormControl isRequired>
          <FormLabel>Select Agent</FormLabel>
          <SelectAgent
            isMulti={true}
            selectedAgents={selectedAgents}
            setSelectedAgents={setSelectedAgents}
          />
        </FormControl>

        <FormControl isRequired>
          <FormLabel>Assign Merchant</FormLabel>
          <SelectMerchant
            isMulti={true}
            selectedMerchants={selectedMerchants}
            setSelectedMerchants={setSelectedMerchants}
            isDisabled={!selectedAgents.length}
            filterCallback={(merchantId) => {
              if (!selectedAgents.length) {
                return true;
              }

              return !selectedAgents.every((a) =>
                a.merchants.some((m) => m.id === merchantId)
              );
            }}
          />
        </FormControl>

        {selectedMerchants.map((merchant) => (
          <FormControl
            key={`select-role-for-merchant-${merchant.id}`}
            isRequired
          >
            <FormLabel>Role for {merchant.name}</FormLabel>
            <Select
              placeholder="Select Role"
              onChange={(r) =>
                setSelectedRolesMap((prev) => ({
                  ...prev,
                  [merchant.id]: r?.value || null,
                }))
              }
              options={roleOptionsMap[merchant.id] || []}
              value={
                roleOptionsMap[merchant.id]?.find(
                  (ro) => ro.value === selectedRolesMap[merchant.id]
                ) || null
              }
              styles={getReactSelectStyles(colorMode, colorScheme)}
              isDisabled={!selectedMerchants.length}
            />
          </FormControl>
        ))}
      </Stack>
      <Button
        colorScheme={colorScheme}
        onClick={saveAgent}
        isDisabled={
          !selectedMerchants.length ||
          Object.values(selectedRolesMap).some((v) => v === null) ||
          !selectedAgents.length
        }
        mt={8}
        mb={8}
        size="lg"
      >
        Assign
      </Button>
    </Box>
  );
};

export default AssignToMerchantForm;
