import { Flex } from "@chakra-ui/react";
import React from "react";
import { ReactComponent as MicIcon } from "assets/icons/mic.svg";
import MessageDomain, {
  AttachmentType,
} from "entities/domain/conversations/message-domain";

interface MicrophoneProps {
  message: MessageDomain;
}

const Microphone: React.FC<MicrophoneProps> = ({ message }) => {
  const shouldShowMicrophone =
    message.isAudioMessage() || message.isPhoneCallRecording();

  if (!shouldShowMicrophone) {
    return null;
  }

  return (
    <Flex
      height="2.25rem"
      width="2.25rem"
      borderRadius="50%"
      alignItems="center"
      justifyContent="center"
    >
      <MicIcon />
    </Flex>
  );
};

export default Microphone;
