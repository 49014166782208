import { useAuth0 } from "@auth0/auth0-react";
import {
  createCalendarEvent,
  createCalendarEventFail,
  CreateCalendarEventPayload,
  createCalendarEventSuccess,
  deleteCalendarEvent,
  deleteCalendarEventFail,
  DeleteCalendarEventPayload,
  deleteCalendarEventSuccess,
  fetchAllCalendarEvents,
  fetchAllCalendarEventsFail,
  fetchAllCalendarEventsSuccess,
  GetCalendarEventsPayload,
  updateCalendarEvent,
  updateCalendarEventFail,
  UpdateCalendarEventPayload,
  updateCalendarEventSuccess,
} from "redux/features/calendar";
import CalendarBookingDomain from "entities/domain/calendar/calendar-domain";
import { useCallback } from "react";
import CalendarsService from "services/calendar";
import { getErrorDescriptionOrDefault } from "services/errorCodeConverter";
import { useAppDispatch, useAppSelector } from "redux/hooks";

export default function useCalendarStore() {
  const dispatch = useAppDispatch();
  const auth0Context = useAuth0();
  const { merchant } = useAppSelector((state) => state.merchant);

  const fetchCalendarEventsWaterfall =
    (payload: GetCalendarEventsPayload) => async () => {
      try {
        dispatch(fetchAllCalendarEvents());

        const calendarEventsResponse = await CalendarsService.getCalendarEvents(
          auth0Context,
          payload,
          merchant.id
        );

        dispatch(fetchAllCalendarEventsSuccess(calendarEventsResponse));
      } catch (err) {
        dispatch(
          fetchAllCalendarEventsFail([
            "Oops. We're not able to get events from our server",
          ])
        );
      }
    };

  const createCalendarEventWaterfall =
    (payload: CreateCalendarEventPayload) =>
    async (): Promise<CalendarBookingDomain | undefined> => {
      try {
        dispatch(createCalendarEvent());

        const calendarResponse = await CalendarsService.createCalendarEvent(
          auth0Context,
          payload,
          merchant.id
        );

        dispatch(createCalendarEventSuccess(calendarResponse));
        return calendarResponse;
      } catch (err: any) {
        const errorMessage = getErrorDescriptionOrDefault(
          err.response?.data?.code,
          "Oops. We couldn't create this event. Please try again!"
        );
        dispatch(createCalendarEventFail([errorMessage]));
        return undefined;
      }
    };

  const updateCalendarEventWaterfall =
    (payload: UpdateCalendarEventPayload) =>
    async (): Promise<CalendarBookingDomain | undefined> => {
      try {
        dispatch(updateCalendarEvent());

        const calendarResponse = await CalendarsService.updateCalendarEvent(
          auth0Context,
          payload,
          merchant.id
        );

        dispatch(updateCalendarEventSuccess(calendarResponse));
        return calendarResponse;
      } catch (err: any) {
        const errorMessage = getErrorDescriptionOrDefault(
          err.response?.data?.code,
          "Oops. We couldn't create this event. Please try again!"
        );
        dispatch(updateCalendarEventFail([errorMessage]));
        return undefined;
      }
    };

  const deleteCalendarEventWaterfall =
    (payload: DeleteCalendarEventPayload) => async () => {
      try {
        dispatch(deleteCalendarEvent());

        await CalendarsService.deleteCalendarEvent(
          auth0Context,
          payload,
          merchant.id
        );

        dispatch(deleteCalendarEventSuccess(payload));
      } catch (err) {
        dispatch(
          deleteCalendarEventFail([
            "Oops. We couldn't create this event. Please try again!",
          ])
        );
      }
    };

  const fetchEventsAsync = useCallback(
    (payload: GetCalendarEventsPayload) =>
      dispatch(fetchCalendarEventsWaterfall(payload)),
    [dispatch, merchant.id]
  );

  const createEventAsync = useCallback(
    (payload: CreateCalendarEventPayload) =>
      createCalendarEventWaterfall(payload)(),
    [dispatch, merchant.id]
  );

  const updateEventAsync = useCallback(
    (payload: UpdateCalendarEventPayload) =>
      updateCalendarEventWaterfall(payload)(),
    [dispatch, merchant.id]
  );

  const deleteEventAsync = useCallback(
    (payload: DeleteCalendarEventPayload) =>
      dispatch(deleteCalendarEventWaterfall(payload)),
    [dispatch, merchant.id]
  );

  return {
    fetchEvents: fetchEventsAsync,
    createEvent: createEventAsync,
    updateEvent: updateEventAsync,
    deleteEvent: deleteEventAsync,
  };
}
