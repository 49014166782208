import { VStack, useToast } from "@chakra-ui/react";
import Spinner from "components/spinner";
import useTemplatesStore from "hooks/use-templates-store";
import React, { useEffect, useState } from "react";
import {
  createSearchParams,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import FullTemplateDomain from "entities/domain/templates/full_template";
import { useAppSelector } from "redux/hooks";
import Topbar from "components/shared/topbar/TopBar";
import { templateTransformFromFullDtoToDomain } from "entities/transformers/templateTransformers";
import { isFullTemplateDTO } from "entities/dto/TemplateDTO";
import { ConversationChannel } from "entities/domain/conversations/conversation-domain";
import BackIconButton from "components/shared/BackButton";
import TemplateForm from "../shared/TemplateForm";

const TemplateView = () => {
  const { templateId: templateIdParam } = useParams<{ templateId: string }>();
  const { fetchTemplates } = useTemplatesStore();
  const { merchant } = useAppSelector((state) => state.merchant);
  const { templates, isLoading } = useAppSelector((state) => state.templates);
  const navigate = useNavigate();
  const toast = useToast();
  const { search, state: locationState } = useLocation();

  const [templateToEdit, setTemplateToEdit] =
    useState<FullTemplateDomain | null>(
      (templates.find(
        (t) => t.id === templateIdParam
      ) as unknown as FullTemplateDomain | null) || null
    );

  useEffect(() => {
    fetchTemplates(undefined, true);
  }, [merchant.id]);

  useEffect(() => {
    if (!templates || !templates.length || templateToEdit) {
      return;
    }

    const template = templates.find((t) => t.id === templateIdParam);

    if (template) {
      if (template instanceof FullTemplateDomain) {
        setTemplateToEdit(template);
      } else {
        navigate(
          `/${merchant.id}/settings/templates/whatsapp/${templateIdParam}`
        );
      }
    } else {
      setTemplateToEdit(null);
    }
  }, [templates]);

  useEffect(() => {
    if (!locationState || !isFullTemplateDTO(locationState)) {
      return;
    }

    let duplicateTemplate: FullTemplateDomain | null = null;

    try {
      duplicateTemplate = templateTransformFromFullDtoToDomain({
        ...locationState,
        name: `${locationState.name}_copy`,
        title: `${locationState.title}_copy`,
        id: "",
        channels: [...new Set(merchant?.channels)]
          .map((chan) => chan.channelName)
          .filter((channelName) => channelName !== ConversationChannel.WHATSAPP)
          .filter((channelName) =>
            locationState.channels.includes(channelName)
          ),
      });

      setTemplateToEdit(duplicateTemplate);
    } catch (error: unknown) {
      // eslint-disable-next-line no-console
      console.error("Error parsing duplicate template", error);

      toast({
        status: "error",
        title: "Error parsing duplicate template! Please try again later.",
      });
    }
  }, [locationState]);

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <VStack spacing={0} w="100%" h="100%">
      <Topbar
        leftChildrenMobile={
          <BackIconButton
            displayBackIcon={true}
            onBackIconClick={() => {
              navigate({
                pathname: `/${merchant.id}/settings/templates`,
                search: createSearchParams(search).toString(),
              });
            }}
          />
        }
        leftChildren={
          <BackIconButton
            displayBackIcon={true}
            onBackIconClick={() => {
              navigate({
                pathname: `/${merchant.id}/settings/templates`,
                search: createSearchParams(search).toString(),
              });
            }}
          />
        }
      />
      <TemplateForm templateToEdit={templateToEdit} />
    </VStack>
  );
};

export default TemplateView;
