import { useEffect } from "react";
import {
  COMMAND_PRIORITY_CRITICAL,
  BLUR_COMMAND,
  FOCUS_COMMAND,
} from "lexical";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { isIOSPlatform } from "util/methods";
import {
  disablePageScroll,
  enablePageScroll,
  clearQueueScrollLocks,
} from "scroll-lock";

/**
 * This plugin fixes the issue with iOS
 * where the whole page becomes scrollable when keyboard is open
 * disregarding the CSS rules.
 */
export default function IosFixPlugin(_props: {}) {
  const [lexicalEditor] = useLexicalComposerContext();

  useEffect(() => {
    lexicalEditor.registerCommand(
      BLUR_COMMAND,
      (_event: FocusEvent) => {
        if (!isIOSPlatform()) {
          return false;
        }

        const messagesList = document.getElementById("messages-list");
        const templateForm = document.getElementById("template-form");
        const contactCardDrawer = document.getElementById(
          "contact-card-drawer"
        );
        const editorUltraContentEditable = document.getElementById(
          "editor-ultra-content-editable"
        );

        if (messagesList) {
          enablePageScroll(messagesList);
        }

        if (templateForm) {
          enablePageScroll(templateForm);
        }

        if (contactCardDrawer) {
          enablePageScroll(contactCardDrawer);
        }

        if (editorUltraContentEditable) {
          enablePageScroll(editorUltraContentEditable);
        }

        // just in case we have some scroll locks left
        // the thing is: we should run "enablePageScroll" for all the elements
        clearQueueScrollLocks();
        enablePageScroll();

        return true;
      },
      COMMAND_PRIORITY_CRITICAL
    );

    lexicalEditor.registerCommand(
      FOCUS_COMMAND,
      (_event: FocusEvent) => {
        if (!isIOSPlatform()) {
          return false;
        }

        const messagesList = document.getElementById("messages-list");
        const templateForm = document.getElementById("template-form");
        const contactCardDrawer = document.getElementById(
          "contact-card-drawer"
        );
        const editorUltraContentEditable = document.getElementById(
          "editor-ultra-content-editable"
        );

        if (messagesList) {
          disablePageScroll(messagesList);
        }

        if (templateForm) {
          disablePageScroll(templateForm);
        }

        if (contactCardDrawer) {
          disablePageScroll(contactCardDrawer);
        }

        if (editorUltraContentEditable) {
          disablePageScroll(editorUltraContentEditable);
        }

        return true;
      },
      COMMAND_PRIORITY_CRITICAL
    );
  }, [lexicalEditor]);

  return null;
}
