import {
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Icon,
  Spinner,
} from "@chakra-ui/react";
import { Color } from "theme/old-design-system/styled-components";
import useAnalytics from "hooks/use-analytics";
import useDebounce from "hooks/use-debounce";
import React, { ChangeEvent, useEffect, useState } from "react";
import { ReactComponent as SearchIcon } from "assets/icons/search-ds.svg";
import { ReactSVG } from "react-svg";
import { useAppSelector, useAppDispatch } from "redux/hooks";
import { setSearchText } from "redux/features/conversations";

interface SearchBarProps {
  showFilterArea?: boolean;
  setShowFilterArea?: (val: boolean) => void;
}
const SearchBar = ({ setShowFilterArea, showFilterArea }: SearchBarProps) => {
  const dispatch = useAppDispatch();
  const { activeConversationId, loading } = useAppSelector(
    (state) => state.conversations
  );
  const { merchant } = useAppSelector((state) => state.merchant);
  const { track } = useAnalytics();
  const { colorScheme } = useAppSelector((state) => state.theme);

  const [localSearchText, setLocalSearchText] = useState("");

  const debouncedSearchText = useDebounce<string>(localSearchText, 1000);

  useEffect(() => {
    dispatch(setSearchText(debouncedSearchText));
  }, [debouncedSearchText]);

  const handleSearchChange = (e: ChangeEvent<HTMLInputElement>) =>
    setLocalSearchText(e.target.value);

  return (
    <>
      <InputGroup maxWidth="500px">
        <InputLeftElement pointerEvents="none">
          {loading ? (
            <Spinner size="sm" color="gray.300" />
          ) : (
            <Icon as={SearchIcon} />
          )}
        </InputLeftElement>
        <Input
          colorScheme={colorScheme}
          id="search-bar"
          placeholder={`Search ${merchant.name}`}
          onClick={() => {
            track("conversations_search_bar_focused", {
              conversation_id: activeConversationId || null,
            });
          }}
          onChange={handleSearchChange}
        />
        <InputRightElement>
          <IconButton
            aria-label="filter conversations"
            variant="unstyled"
            display="flex"
            _focus={{ border: "none" }}
            mb={1}
            _hover={{ opacity: 0.5 }}
            onClick={() => {
              if (setShowFilterArea) setShowFilterArea(!showFilterArea);
            }}
          >
            <Icon
              as={ReactSVG}
              src="/filter-light.svg"
              _hover={{ fill: Color.SMOKY_GREY.value }}
              __css={
                showFilterArea
                  ? {
                      svg: {
                        height: "18px",
                        width: "18px",
                        path: {
                          fill: `${colorScheme}.400`,
                        },
                      },
                    }
                  : {
                      svg: {
                        height: "18px",
                        width: "18px",
                        path: {
                          fill: "#A0AEC0",
                        },
                      },
                    }
              }
            />
          </IconButton>
        </InputRightElement>
      </InputGroup>
    </>
  );
};

export default SearchBar;
