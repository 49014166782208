export default class BankAccountDetailsDomain {
  accountNumber?: string;

  sortCode?: string;

  bic?: string;

  iban?: string;

  constructor(
    accountNumber?: string,
    sortCode?: string,
    bic?: string,
    iban?: string
  ) {
    this.accountNumber = accountNumber;
    this.sortCode = sortCode;
    this.bic = bic;
    this.iban = iban;
  }
}
