export default class DomainError {
  code: string;
  message: string;
  errorMessage: string;
  payload: Object | null;

  constructor(
    code: string,
    message: string,
    erroMessage: string | null,
    payload: Object | null
  ) {
    this.code = code;
    this.message = message;
    this.errorMessage = erroMessage !== null ? erroMessage : message;
    this.payload = payload;
  }
}
