import { useAuth0 } from "@auth0/auth0-react";
import {
  FormControl,
  FormLabel,
  RangeSlider,
  RangeSliderFilledTrack,
  RangeSliderThumb,
  RangeSliderTrack,
  Text,
  useColorMode,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import {
  setFilterByYearMax,
  setFilterByYearMin,
} from "redux/features/inventory";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import InventoryService from "services/inventory";

interface YearFilterProps {}

const MIN_YEAR_REALISTICALLY = 1900;

const YearFilter = (_props: YearFilterProps) => {
  const auth0Context = useAuth0();
  const dispatch = useAppDispatch();
  const {
    filterOptions,
    filterByYearMin,
    filterByYearMax,
    filterByCondition,
    filterByModel,
    filterByMake,
    filterByPriceMax,
    filterByPriceMin,
    filterByAvailability,
  } = useAppSelector((state) => state.inventory);
  const { merchant } = useAppSelector((state) => state.merchant);
  const { colorScheme } = useAppSelector((state) => state.theme);
  const { colorMode } = useColorMode();

  const [rangeSliderValues, setRangeSliderValues] = useState<number[]>([
    filterByYearMin || filterOptions.minYear,
    filterByYearMax || filterOptions.maxYear,
  ]);

  useEffect(() => {
    if (
      !filterByModel &&
      !filterByMake &&
      !filterByPriceMin &&
      !filterByPriceMax &&
      !filterByCondition &&
      !filterByAvailability
    ) {
      setRangeSliderValues([
        filterByYearMin || filterOptions.minYear,
        filterByYearMax || filterOptions.maxYear,
      ]);
      return;
    }

    InventoryService.getVehicleFilterOptions(
      auth0Context,
      merchant.id,
      filterByModel,
      filterByMake,
      null,
      null,
      filterByPriceMin,
      filterByPriceMax,
      filterByCondition,
      filterByAvailability
    ).then((newFilterOptions) => {
      setRangeSliderValues([
        filterByYearMin || newFilterOptions.minYear,
        filterByYearMax || newFilterOptions.maxYear,
      ]);
    });
  }, [
    filterOptions,
    filterByCondition,
    filterByModel,
    filterByPriceMax,
    filterByPriceMin,
    filterByMake,
    filterByAvailability,
  ]);

  return filterOptions.minYear === filterOptions.maxYear ? null : (
    <FormControl textAlign="center">
      <FormLabel
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <Text>Year</Text>
        <Text>
          {(filterByYearMin || filterOptions.minYear) ===
          (filterByYearMax || filterOptions.maxYear)
            ? filterByYearMin || filterOptions.minYear
            : `${filterByYearMin || filterOptions.minYear} - ${
                filterByYearMax || filterOptions.maxYear
              }`}
        </Text>
      </FormLabel>
      <RangeSlider
        w="90%"
        min={filterOptions.minYear}
        max={filterOptions.maxYear}
        isDisabled={filterOptions.minYear === filterOptions.maxYear}
        value={rangeSliderValues}
        onChange={(newRangeSliderValues) => {
          setRangeSliderValues(newRangeSliderValues);
        }}
        colorScheme={colorScheme}
        onChangeEnd={(val) => {
          const newMinValue = val[0];

          if (newMinValue === filterOptions.minYear) {
            dispatch(setFilterByYearMin(null));
          } else {
            dispatch(setFilterByYearMin(newMinValue));
          }

          const newMaxValue = val[1];

          if (newMaxValue === filterOptions.maxYear) {
            dispatch(setFilterByYearMax(null));
          } else {
            dispatch(setFilterByYearMax(newMaxValue));
          }
        }}
      >
        <RangeSliderTrack>
          <RangeSliderFilledTrack />
        </RangeSliderTrack>
        <RangeSliderThumb
          index={0}
          bgColor={
            colorMode === "dark" ? `${colorScheme}.200` : `${colorScheme}.500`
          }
          _disabled={{
            bgColor:
              colorMode === "dark"
                ? `${colorScheme}.700`
                : `${colorScheme}.400`,
          }}
          p={3}
        >
          <Text fontSize="2xs" color="white">
            min
          </Text>
        </RangeSliderThumb>
        <RangeSliderThumb
          index={1}
          bgColor={
            colorMode === "dark" ? `${colorScheme}.200` : `${colorScheme}.500`
          }
          _disabled={{
            bgColor:
              colorMode === "dark"
                ? `${colorScheme}.700`
                : `${colorScheme}.400`,
          }}
          p={3}
        >
          <Text fontSize="2xs" color="white">
            max
          </Text>
        </RangeSliderThumb>
      </RangeSlider>
    </FormControl>
  );
};

export default YearFilter;
