import { Auth0ContextInterface } from "@auth0/auth0-react/src/auth0-context";
import { RequestType } from "./request-type";
import { mutationRequest } from "../util/methods";

class VoiceCallsService {
  public static async initiateCall(
    { getAccessTokenSilently }: Auth0ContextInterface,
    conversation_id: number,
    merchantId: number
  ) {
    const accessToken = await getAccessTokenSilently();

    const callResponse = (
      await mutationRequest(
        RequestType.POST,
        accessToken,
        `/merchants/${merchantId}/calls/outbound`,
        {
          conversation_id,
        }
      )
    ).data;

    return callResponse;
  }
}

export default VoiceCallsService;
