import { Text } from "@chakra-ui/react";
import { PreviewComponentProps } from "components/shared/QueryBuilder/QueryCondition";
import React from "react";
import { useAppSelector } from "redux/hooks";

const TagFieldPreview = ({ value }: PreviewComponentProps) => {
  const { tags } = useAppSelector((state) => state.tags);

  const tag = tags.find((t) => t.id === value)?.tag;

  return <Text fontWeight="bold">{tag || value}</Text>;
};

export default TagFieldPreview;
