import {
  Box,
  Flex,
  HStack,
  Popover,
  PopoverArrow,
  PopoverContent,
  PopoverHeader,
  useBreakpointValue,
  useColorMode,
  useToast,
} from "@chakra-ui/react";
import ProfileAvatar from "components/profile/profile-avatar";
import {
  BodySize,
  BodyText,
  Color,
  FWeight,
  Heading,
  HeadingSize,
  Icon,
  IconType,
} from "theme/old-design-system/styled-components";
import CalendarBookingDomain from "entities/domain/calendar/calendar-domain";
import useCalendarStore from "hooks/use-calendar-store";
import { useAppSelector } from "redux/hooks";
import React, { useEffect, useState } from "react";

import ContactDomain from "entities/domain/customers/contact-domain";
import ContactsService from "services/contacts";
import useMerchantStore from "hooks/use-merchant-store";
import { useAuth0 } from "@auth0/auth0-react";

interface CalendarEventInfoProps {
  currentEvent: CalendarBookingDomain;
  closeEventPopover: () => void;
  onEditClick: () => void;
  assignedAgentId: number | null;
}

const EventInfoPopover = ({
  currentEvent,
  closeEventPopover,
  onEditClick,
  assignedAgentId,
}: CalendarEventInfoProps) => {
  const { colorMode } = useColorMode();
  const { colorScheme } = useAppSelector((state) => state.theme);
  const toast = useToast();
  const { agents } = useAppSelector((state) => state.agents);
  const { merchant } = useAppSelector((state) => state.merchant);
  const auth0Context = useAuth0();
  const { deleteEvent } = useCalendarStore();
  const { errors } = useAppSelector((state) => state.calendar);

  const [customer, setCustomer] = useState<ContactDomain | null>(null);
  useEffect(() => {
    if (currentEvent.customerId) {
      ContactsService.getContact(
        auth0Context,
        currentEvent.customerId,
        merchant.groupId
      ).then((contact) => {
        setCustomer(contact || null);
      });
    }
  }, [currentEvent]);

  const EventInfoTopBar = (
    <Flex alignItems="center" justifyContent="space-between" w="100%">
      <Flex alignItems="center" justifyContent="start">
        <Icon
          width={20}
          height={20}
          mr={4}
          type={IconType.TRASH}
          pathFill={Color.SMOKY_GREY}
          onClick={() => {
            deleteEvent({ bookingId: currentEvent.id });
            closeEventPopover();
            if (errors.length) {
              toast({ status: "error", title: errors[0] });
            } else {
              toast({
                status: "success",
                title: "Event deleted successfully!",
              });
            }
          }}
        />
        <Icon
          id="edit-event-button"
          type={IconType.EDIT}
          pathFill={Color.SMOKY_GREY}
          onClick={onEditClick}
          width={20}
          height={20}
        />
      </Flex>
      <Icon
        type={IconType.CLOSE}
        onClick={() => closeEventPopover()}
        pathFill={Color.SMOKY_GREY}
        width={20}
        height={20}
      />
    </Flex>
  );

  const EventContent = (
    <>
      <Box padding="8px 12px">
        <Box
          borderLeft={`"3px solid ${
            assignedAgentId
              ? agents.find((a) => a.id === assignedAgentId)!.color.value
              : `${colorScheme}.400`
          }`}
          pl="1rem"
          mb="1rem"
        >
          <Heading size={HeadingSize.TWO}> {currentEvent?.title} </Heading>

          <BodyText size={BodySize.THREE} fweight={FWeight.THREE}>
            {currentEvent.getFormatDate()}
          </BodyText>
        </Box>
      </Box>

      {currentEvent.description && (
        <Box ml="15px" mb="10px">
          <BodyText size={BodySize.THREE} fweight={FWeight.THREE}>
            {currentEvent.description}
          </BodyText>
        </Box>
      )}

      {customer && (
        <Box ml="15px" mb="10px">
          <BodyText size={BodySize.THREE} fweight={FWeight.ONE}>
            Customer
          </BodyText>
          <Flex mt="15px" alignItems="center">
            <Box
              display="inline-block"
              width="10%"
              verticalAlign="middle"
              mr="8px"
            >
              <ProfileAvatar
                profilePicture={customer.getPicture()}
                name={customer.getDisplayName()}
              />
            </Box>
            <div>
              <BodyText size={BodySize.THREE} fweight={FWeight.THREE}>
                {customer.getDisplayName()}
              </BodyText>
              <BodyText size={BodySize.TWO} fweight={FWeight.THREE}>
                {customer.getPhoneNum()
                  ? customer.getPhoneNum()
                  : customer.getEmailAddress()}
              </BodyText>
            </div>
          </Flex>
        </Box>
      )}

      {currentEvent.agentIds.length !== 0 && (
        <Box ml="15px" mb="10px">
          <BodyText size={BodySize.THREE} fweight={FWeight.ONE}>
            {currentEvent.agentIds.length === 1 ? "Agent" : "Agents"}
          </BodyText>

          {currentEvent.agentIds.map((agent) => (
            <div key={agent}>
              <Flex mt="15px" alignItems="center">
                <Box
                  background={agents.find((a) => a.id === agent)!.color.value}
                  borderRadius="50%"
                  width="12px"
                  height="12px"
                  mr="10px"
                  ml="10px"
                />
                <BodyText size={BodySize.THREE} fweight={FWeight.THREE}>
                  {" "}
                  {agents.find((a) => a.id === agent)!.getFullName()}{" "}
                </BodyText>
              </Flex>
            </div>
          ))}
        </Box>
      )}
    </>
  );

  const MobileInfo = (
    <>
      <Flex
        flexFlow="column nowrap"
        width="100%"
        bgColor={colorMode === "dark" ? "gray.800" : "gray.50"}
        borderRadius="lg"
      >
        <Flex
          flexFlow="column nowrap"
          border="none"
          width="100%"
          maxWidth="100%!important"
          marginTop="0!important"
          height="100%"
          padding="0 16px"
          borderRightWidth="1px"
          borderRightStyle="solid"
          borderRightColor="gray.50"
        >
          <HStack
            w="100%"
            justify="flex-end"
            align="center"
            pt="16px"
            mb="24px"
          >
            {EventInfoTopBar}
          </HStack>

          {EventContent}
        </Flex>
      </Flex>
    </>
  );

  const DesktopInfo = (
    <>
      <Popover
        closeOnBlur={true}
        closeOnEsc={true}
        isOpen={!!currentEvent}
        onClose={closeEventPopover}
      >
        <PopoverContent>
          <PopoverArrow />

          <PopoverHeader display="flex" justifyContent="flex-end" border="none">
            {EventInfoTopBar}
          </PopoverHeader>

          {EventContent}
        </PopoverContent>
      </Popover>
    </>
  );

  const isBaseSize = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );

  return isBaseSize ? MobileInfo : DesktopInfo;
};

export default EventInfoPopover;
