import { AxiosError } from "axios";
import DomainError from "./domain";

export const axiosErrorDataToDomainError = (
  err: unknown
): DomainError | null => {
  if (!(err instanceof AxiosError)) {
    return null;
  }

  if (err && err.code === "insufficient_limit") {
    return new DomainError(
      err.code,
      err.message,
      "Monthly message limit has been reached. Please upgrade to continue messaging",
      {}
    );
  }

  return new DomainError(
    err.code || "default_error",
    err.message !== null ? err.message : "An Error Occurred",
    null,
    {}
  );
};
