import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { isWindows } from "util/methods";
import { useToast } from "@chakra-ui/toast";
import useDebounce from "./use-debounce";

const getKeysFromIndex = (index: number): string[] => {
  return (index + 1).toString().split("");
};

const addTextNodeToTopRightCorner = (text: string, id: string) => {
  const newNode = document.createElement("div");

  newNode.id = `shortcut-${id}`;
  newNode.style.position = "fixed";
  newNode.style.fontSize = "4rem";
  newNode.style.fontWeight = "bold";
  newNode.style.top = "1rem";
  newNode.style.right = "1rem";
  newNode.innerText = text;
  document.body.appendChild(newNode);
};

const addCurrentlyPressedKeysNode = (
  shortcutPrefix: string,
  pressedKeys: string[],
  currentlyPressedKey: string,
  id: string
) => {
  addTextNodeToTopRightCorner(
    `${shortcutPrefix} + ${
      pressedKeys.length ? `${pressedKeys.join(" + ")} +` : ""
    } ${currentlyPressedKey}`,
    id
  );
};

const removeCurrentlyPressedKeysNode = (id: string) => {
  if (!id) {
    const shortcutNodes = document.querySelectorAll("[id^=shortcut]");

    shortcutNodes.forEach((node) => {
      node.remove();
    });

    return;
  }

  const shortcutNode = document.getElementById(`shortcut-${id}`);

  if (shortcutNode) {
    shortcutNode.remove();
  }
};

const areKeysPressed = (keys: string[], pressedKeys: string[]): boolean => {
  return keys.join("") === pressedKeys.join(""); // Notice that we don't sort the arrays, we want to check the order too
};

const useShortcut = (
  index: number,
  merchantName: string,
  callback: () => void,
  amountOfMerchants: number,
  node = null
): string => {
  const toast = useToast();
  const shortcutPrefix = isWindows() ? "Ctrl" : "⌘";
  const [keys, setKeys] = useState<string[]>(getKeysFromIndex(index));
  const [pressedKeys, setPressedKeys] = useState<string[]>([]);
  const shouldShowShortcut = amountOfMerchants >= 10;
  const debouncedPressedKeys = useDebounce(pressedKeys, 1500);

  const callbackRef = useRef(callback);

  useEffect(() => {
    setKeys(getKeysFromIndex(index));
  }, [index]);

  useLayoutEffect(() => {
    callbackRef.current = callback;
  }, [callback]);

  const handleKeyPress = useCallback(
    (event: KeyboardEvent) => {
      if (
        !keys.length ||
        !(event.ctrlKey || event.metaKey) ||
        Number.isNaN(Number(event.key))
      ) {
        return;
      }

      event.preventDefault();
      event.stopPropagation();

      setPressedKeys((prev) => {
        const potentialNewPressedKeys = [...prev, event.key];

        if (shouldShowShortcut) {
          const matchesShortcut =
            keys.join("") === potentialNewPressedKeys.join("");

          removeCurrentlyPressedKeysNode(keys.join(""));

          if (matchesShortcut) {
            addCurrentlyPressedKeysNode(
              shortcutPrefix,
              prev,
              event.key,
              keys.join("")
            );
          }
        }

        return potentialNewPressedKeys;
      });
    },
    [keys, shouldShowShortcut]
  );

  useEffect(() => {
    if (!shouldShowShortcut) {
      return;
    }

    const allKeysPressed = areKeysPressed(keys, debouncedPressedKeys);

    removeCurrentlyPressedKeysNode(keys.join(""));
    setPressedKeys([]);

    if (allKeysPressed) {
      callbackRef.current();
      toast({
        status: "success",
        title: `Switching to ${merchantName}`,
        duration: 2000,
      });
    }
  }, [keys, debouncedPressedKeys, shouldShowShortcut]);

  useEffect(() => {
    if (shouldShowShortcut) {
      return;
    }

    const allKeysPressed = areKeysPressed(keys, pressedKeys);

    setPressedKeys([]);

    if (allKeysPressed) {
      callbackRef.current();
      toast({
        status: "success",
        title: `Switching to ${merchantName}`,
        duration: 2000,
      });
    }
  }, [keys, pressedKeys, shouldShowShortcut]);

  useEffect(() => {
    const targetNode = node ?? document;

    if (targetNode) {
      targetNode.addEventListener("keydown", handleKeyPress);
    }

    return () => {
      if (targetNode) {
        targetNode.removeEventListener("keydown", handleKeyPress);
      }
    };
  }, [handleKeyPress, node]);

  return `${shortcutPrefix}${keys.join("")}`;
};

export default useShortcut;
