import { format } from "date-fns";

interface Customer {
  name: string;
  email: string;
  phone: string;
}

interface Appointment {
  dueInDateTime: Date;
  dueOutDateTime: Date;
}

interface Mileage {
  unit: string;
  value: string;
}

interface Details {
  checkInDateTime: string;
  checkInMileage: Mileage;
  checkOutDateTime: string;
  checkOutMileage: Mileage;
  closedDateTime: string;
  completedDateTime: string;
  serviceTypes: string[];
}

export interface Vehicle {
  vin: string;
  licensePlate: string;
  description: string;
}

export interface Advisor {
  id: string;
  name: string;
  userId: string;
}

export default class KeyloopRepairOrder {
  id: string;
  reference: string;
  status: string;
  type: string;
  customer: Customer;
  appointment: Appointment;
  details: Details;
  advisor: Advisor;
  vehicle: Vehicle;

  constructor(
    id: string,
    reference: string,
    status: string,
    type: string,
    customer: Customer,
    appointment: Appointment,
    details: Details,
    advisor: Advisor,
    vehicle: Vehicle
  ) {
    this.id = id;
    this.reference = reference;
    this.status = status;
    this.type = type;
    this.customer = customer;
    this.appointment = appointment;
    this.details = details;
    this.advisor = advisor;
    this.vehicle = vehicle;
  }

  getDisplayCustomer() {
    return [this.customer.name, this.customer.phone].join(" ");
  }

  getDisplayVehicle() {
    return [this.vehicle.licensePlate, this.vehicle.description].join(" ");
  }

  getDisplayAppointment() {
    const startTime = format(this.appointment.dueInDateTime, "hh:mm a");
    const endTime = format(this.appointment.dueOutDateTime, "hh:mm a");
    return `${startTime} - ${endTime}`;
  }

  getDailyKey(): string {
    return format(this.appointment.dueInDateTime, "dd MMMM yyyy");
  }

  getCustomerEmailAddress(): string | null {
    return this.customer.email || null;
  }

  getCustomerPhoneNumber(): string | null {
    return this.customer.phone.replace(/\D/g, "") || null;
  }
}
