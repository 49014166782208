import React, { memo, useCallback, useState } from "react";
import { Flex, HStack, useBreakpointValue } from "@chakra-ui/react";
import { useAppSelector } from "redux/hooks";
import NewConversationModal from "components/chat/shared/start-conversation-area";
import SearchBar from "./SearchBar";
import OpenClosedToggle from "./OpenClosedToggle";
import StartConversationButton from "./StartConversationButton";
import ConversationFilters from "./ConversationFilters";
import Inventory from "./inventory";

interface ConversationListTopAreaProps {}

const ConversationListTopArea = (_props: ConversationListTopAreaProps) => {
  const isBaseSize = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );
  const { agents } = useAppSelector((state) => state.agents);

  const [showFilterArea, setShowFilterArea] = useState<boolean>(false);

  const [isNewConversationModalOpen, setIsNewConversationModalOpen] =
    useState<boolean>(false);

  const onStartConversationButtonClick = useCallback(
    () => setIsNewConversationModalOpen(true),
    []
  );
  const onCloseNewConversationModal = useCallback(
    () => setIsNewConversationModalOpen(false),
    []
  );

  return (
    <>
      <Flex
        {...(isBaseSize
          ? {
              py: 2,
              px: 4,
              justifyContent: "space-between",
              gridGap: 2,
              direction: "row-reverse",
            }
          : {
              py: 4,
              pl: agents.length > 1 ? "12rem" : 4,
              pr: 4,
              justifyContent: "start",
              flexWrap: "wrap",
              gridGap: 4,
            })}
        width="100%"
        alignItems="center"
      >
        {isBaseSize ? (
          <>
            <Inventory />
            <OpenClosedToggle />
          </>
        ) : (
          <>
            <StartConversationButton onClick={onStartConversationButtonClick} />
            <NewConversationModal
              isOpen={isNewConversationModalOpen}
              onClose={onCloseNewConversationModal}
            />
          </>
        )}
        <HStack flexGrow={1} justifyContent="space-between">
          <SearchBar
            showFilterArea={showFilterArea}
            setShowFilterArea={setShowFilterArea}
          />
          {isBaseSize ? null : (
            <>
              <ConversationFilters showFilters={showFilterArea} />
              <Inventory />
            </>
          )}
        </HStack>
      </Flex>
      {isBaseSize ? <ConversationFilters showFilters={showFilterArea} /> : null}
    </>
  );
};

export default memo(ConversationListTopArea);
