import {
  FormControl,
  FormLabel,
  Stack,
  useColorMode,
  useToast,
} from "@chakra-ui/react";
import {
  Button,
  ButtonSize,
  ButtonType,
} from "theme/old-design-system/styled-components";
import { useAppSelector } from "redux/hooks";
import React, { useState } from "react";
import Select, { SingleValue } from "react-select";

import { getReactSelectStyles } from "util/methods";
import AdminMerchantDomain from "entities/domain/admin/merchants/admin-merchant-domain";
import AdminService, { CreateMerchantContactPayload } from "services/admin";
import { useAuth0 } from "@auth0/auth0-react";
import EmailChannel from "./email-channel";
import PhoneBasedChannel from "./phone-based-channel";
import SelectMerchant from "../select-merchant";

interface ChannelCodeOptionTypes {
  value: string;
  label: string;
}

interface PhoneProviderOptionTypes {
  value: string;
  label: string;
}

export interface ChannelHandleType {
  country_code?: string;
  identifier?: string | undefined;
}

const channelOptions: ChannelCodeOptionTypes[] = [
  { value: "sms", label: "SMS" },
  { value: "whatsapp", label: "WhatsApp" },
  { value: "email", label: "Email" },
];

const phoneProviderOptions: PhoneProviderOptionTypes[] = [
  { value: "TWILIO", label: "Twilio" },
  { value: "TELNYX", label: "Telnyx" },
];

const AssignChannelForm = () => {
  const auth0Context = useAuth0();
  const { colorMode } = useColorMode();
  const { colorScheme } = useAppSelector((state) => state.theme);
  const toast = useToast();

  const [selectedMerchant, setSelectedMerchant] =
    useState<AdminMerchantDomain | null>(null);
  const [channelValue, setChannelValue] = useState<string>("");
  const [selectedChannelValue, setSelectedChannelValue] =
    useState<SingleValue<ChannelCodeOptionTypes>>(null);
  const [selectedPhoneProviderValue, setSelectedPhoneProviderValue] =
    useState<SingleValue<PhoneProviderOptionTypes>>(null);
  const [channelHandle, setChannelHandle] = useState<ChannelHandleType | null>(
    null
  );

  const handleOnChannelChange = (e: SingleValue<ChannelCodeOptionTypes>) => {
    setChannelValue(e!.value);
    setSelectedChannelValue(e);
    setChannelHandle(null);
  };

  const createMerchantContact = async () => {
    const merchantContactObj: CreateMerchantContactPayload = {
      merchant_id: selectedMerchant!.id,
      channel: channelValue,
      provider: selectedPhoneProviderValue?.value,
      ...channelHandle,
    };

    await AdminService.createContact(auth0Context, merchantContactObj);
  };

  const resetValues = () => {
    setSelectedMerchant(null);
    setChannelValue("");
    setSelectedChannelValue(null);
  };

  const buyPhoneNumber = async () => {
    try {
      await createMerchantContact();
      toast({
        status: "success",
        title: "Contact Channel Created Successfully",
      });
    } catch (_error: unknown) {
      toast({
        status: "error",
        title: "Error creating contact channel",
      });
    } finally {
      resetValues();
    }
  };

  return (
    <>
      <div id="phone-form-input">
        <Stack spacing={3}>
          <FormControl isRequired>
            <FormLabel>Select Merchant</FormLabel>
            <SelectMerchant
              selectedMerchant={selectedMerchant}
              setSelectedMerchant={setSelectedMerchant}
            />
          </FormControl>
          <FormControl isRequired>
            <FormLabel> Channels </FormLabel>
            <Select
              placeholder="Select Channel"
              onChange={handleOnChannelChange}
              options={channelOptions}
              value={selectedChannelValue}
              styles={getReactSelectStyles(colorMode, colorScheme)}
            />
          </FormControl>
          {selectedChannelValue?.value === "sms" && (
            <FormControl>
              <FormLabel> Providers </FormLabel>
              <Select
                placeholder="Select Provider"
                onChange={(e) => {
                  setSelectedPhoneProviderValue(e);
                }}
                options={phoneProviderOptions}
                value={selectedPhoneProviderValue}
                styles={getReactSelectStyles(colorMode, colorScheme)}
              />
            </FormControl>
          )}
          {selectedChannelValue?.value === "email" ? (
            <EmailChannel setHandle={setChannelHandle} />
          ) : (
            <PhoneBasedChannel
              channelValue={channelValue}
              setHandle={setChannelHandle}
            />
          )}
        </Stack>
        <Button
          type={ButtonType.PRIMARY}
          onClick={buyPhoneNumber}
          mb={30}
          mt={30}
          size={ButtonSize.LARGE}
          isDisabled={!selectedMerchant || !channelValue || !channelHandle}
        >
          Add Channel
        </Button>
      </div>
    </>
  );
};

export default AssignChannelForm;
