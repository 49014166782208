import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Stack,
  useColorMode,
  useToast,
} from "@chakra-ui/react";
import EditorUltra, { EditorUltraPlugin } from "components/editor-ultra";
import { useAppSelector } from "redux/hooks";

import React, { useEffect, useState } from "react";
import AdminMerchantDomain from "entities/domain/admin/merchants/admin-merchant-domain";
import AdminService from "services/admin";
import { useAuth0 } from "@auth0/auth0-react";
import SelectMerchant from "./select-merchant";

const BusinessDescription = () => {
  const auth0Context = useAuth0();
  const { colorMode } = useColorMode();
  const { colorScheme } = useAppSelector((state) => state.theme);
  const toast = useToast();

  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [selectedMerchant, setSelectedMerchant] =
    useState<AdminMerchantDomain | null>(null);
  const [defaultDescriptionValue, setDefaultDescriptionValue] = useState<{
    value: string;
  }>({
    value: selectedMerchant?.businessDescription || "",
  });
  const [newDescriptionValue, setNewDescriptionValue] = useState<string>(
    defaultDescriptionValue.value
  );

  useEffect(() => {
    const newDefaultDescription = selectedMerchant?.businessDescription || "";

    setDefaultDescriptionValue({
      value: selectedMerchant?.businessDescription || "",
    });
    setNewDescriptionValue(newDefaultDescription);
  }, [selectedMerchant]);

  const updateMerchantDescription = async () => {
    if (!selectedMerchant) {
      return;
    }

    try {
      setIsSaving(true);

      await AdminService.updateMerchant(auth0Context, {
        merchantId: selectedMerchant.id,
        business_description: newDescriptionValue,
      });

      toast({
        status: "success",
        title: "Merchant Description Updated Successfully",
      });
    } catch (_error: unknown) {
      toast({
        status: "error",
        title: "Failed to update Merchant Description",
      });
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <Stack spacing={3} alignItems="center">
      <FormControl isRequired>
        <FormLabel>Select Merchant</FormLabel>
        <SelectMerchant
          selectedMerchant={selectedMerchant}
          setSelectedMerchant={setSelectedMerchant}
        />
      </FormControl>
      <FormControl isRequired>
        <FormLabel>Describe Merchant&apos;s Business</FormLabel>
        <Box
          p={3}
          bgColor={colorMode === "dark" ? "gray.700" : "white"}
          borderColor={`${colorScheme}.400`}
          borderWidth="1px"
          borderStyle="solid"
          borderRadius="md"
        >
          <EditorUltra
            isDisabled={isSaving || !selectedMerchant}
            defaultText={defaultDescriptionValue}
            setText={(currentText: string) => {
              setNewDescriptionValue(currentText);
            }}
            enabledPlugins={[
              EditorUltraPlugin.AUTOFOCUS,
              EditorUltraPlugin.RICHTEXT,
            ]}
          />
        </Box>
      </FormControl>
      <Button
        w="fit-content"
        size="lg"
        onClick={updateMerchantDescription}
        isDisabled={isSaving || !selectedMerchant}
      >
        Save Description
      </Button>
    </Stack>
  );
};

export default BusinessDescription;
