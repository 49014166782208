export enum AttributeType {
  STRING = "string",
  INTEGER = "integer",
  FLOAT = "float",
  BOOLEAN = "boolean",
  DATETIME = "datetime",
}

export const AttributeTypeDisplayNames: Record<AttributeType, string> = {
  [AttributeType.STRING]: "🔡 Text",
  [AttributeType.INTEGER]: "#️⃣ Integer",
  [AttributeType.FLOAT]: "📏 Decimal",
  [AttributeType.BOOLEAN]: "👍👎 True/False",
  [AttributeType.DATETIME]: "🗓️ Date & Time",
};

export default class AttributeDomain {
  id: string;

  name: string;

  type: AttributeType;

  group_id: string;

  constructor(id: string, name: string, type: AttributeType, group_id: string) {
    this.id = id;
    this.name = name;
    this.type = type;
    this.group_id = group_id;
  }
}
