import { Flex, Spacer, Text } from "@chakra-ui/react";
import React from "react";

interface LineItemProps {
  lineItem: string | undefined;
  lineItemTwo: string | undefined;
  flexDirection?: any;
}

const RequestLineItem = ({
  lineItem,
  lineItemTwo,
  flexDirection = "row",
}: LineItemProps) => {
  return (
    <>
      <Flex pt={22} justify="space-between" direction={flexDirection}>
        <Text maxWidth="250px" mb="10px" fontSize="sm">
          {lineItem}
        </Text>
        <Spacer />
        <Text maxWidth="250px" mb="10px" fontSize="sm">
          {lineItemTwo}
        </Text>
      </Flex>
    </>
  );
};

export default RequestLineItem;
