import { format, isSameDay, parseISO } from "date-fns";

export default class CalendarBookingDomain {
  id: string;

  startAt: string;

  endAt: string;

  agentIds: number[];

  customerId: number;

  title: string;

  description: string;

  notifyChannelSelected: boolean;

  formatTitle(): string {
    return this.title === "" ? "(No title)" : this.title;
  }

  public getAgentKey(): string {
    return this.agentIds.map((ag) => ag).toString();
  }

  isSameDay(): boolean {
    return isSameDay(parseISO(this.startAt), parseISO(this.endAt));
  }

  public getFormatDate(): string {
    return this.isSameDay()
      ? `${format(parseISO(this.startAt), "EEEE d MMMM, h:mm a")} - ${format(
          parseISO(this.endAt),
          "h:mm a"
        )}`
      : `${format(parseISO(this.startAt), "EEEE d MMMM, h:mm a")} - ${format(
          parseISO(this.endAt),
          "EEEE d MMMM, h:mm a"
        )}`;
  }

  constructor(
    id: string,
    startAt: string,
    endAt: string,
    agentIds: number[],
    customerId: number,
    title: string,
    description: string,
    notifyChannelSelected: boolean
  ) {
    this.id = id;
    this.startAt = startAt;
    this.endAt = endAt;
    this.agentIds = agentIds;
    this.customerId = customerId;
    this.title = title;
    this.description = description;
    this.notifyChannelSelected = notifyChannelSelected;
  }
}
