import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface AttachmentsState {
  images: string[];
  files: File[];
}
const initialState: AttachmentsState = {
  images: [],
  files: [],
};

const attachmentsSlice = createSlice({
  name: "attachments",
  initialState,
  reducers: {
    addImageAttachment(state, action: PayloadAction<string>) {
      state.images.push(action.payload);
    },
    addFileAttachment(state, action: PayloadAction<File>) {
      state.files.push(action.payload);
    },
    clearAttachments(state) {
      state.images = [];
      state.files = [];
    },
  },
});

export const { addImageAttachment, addFileAttachment, clearAttachments } =
  attachmentsSlice.actions;
export default attachmentsSlice.reducer;
