import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  useBreakpointValue,
  Heading,
  Button,
  Flex,
} from "@chakra-ui/react";
import { BodySize, BodyText } from "theme/old-design-system/styled-components";
import { useAppSelector } from "redux/hooks";
import React from "react";

interface CancelPaymentProps {
  isCancelPaymentsOpen: boolean;
  onClose: () => void;
  onCancelPayment: () => void;
}

const CancelPaymentModal = ({
  isCancelPaymentsOpen,
  onClose,
  onCancelPayment,
}: CancelPaymentProps) => {
  const isBaseSize = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );
  const { colorScheme } = useAppSelector((state) => state.theme);

  return (
    <>
      <Modal
        isOpen={isCancelPaymentsOpen}
        onClose={onClose}
        size={isBaseSize ? "full" : "xs"}
      >
        <ModalOverlay />
        <ModalContent>
          <Heading as="h5" size="md" margin="20px 40px 8px">
            {" "}
            Cancel payment
          </Heading>
          <ModalCloseButton />
          <ModalBody>
            <BodyText size={BodySize.THREE}>
              {" "}
              Are you sure you want to cancel this payment?
            </BodyText>
          </ModalBody>
          <Flex
            margin="24px 32px"
            justifyContent="space-between"
            alignItems="center"
          >
            <Button onClick={() => onClose()} colorScheme="gray">
              No, keep it
            </Button>
            <Button colorScheme={colorScheme} onClick={onCancelPayment}>
              Yes, cancel it
            </Button>
          </Flex>
        </ModalContent>
      </Modal>
    </>
  );
};

export default CancelPaymentModal;
