import { UsageUnit } from "entities/dto/MerchantDTO";

export class MerchantUsageDomain {
  monthly_outgoing_sms_messages: UsageUnit;
  monthly_campaign_messages: UsageUnit;

  constructor(
    monthly_outgoing_sms_messages: UsageUnit,
    monthly_campaign_messages: UsageUnit
  ) {
    this.monthly_outgoing_sms_messages = monthly_outgoing_sms_messages;
    this.monthly_campaign_messages = monthly_campaign_messages;
  }

  getLabelsWithValues(): { label: string; value: string; progress: number }[] {
    return [
      {
        label: "Monthly Outgoing SMS Messages",
        value:
          this.monthly_outgoing_sms_messages.limit !== -1
            ? `${this.monthly_outgoing_sms_messages.current}/${this.monthly_outgoing_sms_messages.limit}`
            : `${this.monthly_outgoing_sms_messages.current}/∞`,
        progress:
          this.monthly_outgoing_sms_messages.limit !== -1
            ? (this.monthly_outgoing_sms_messages.current /
                this.monthly_outgoing_sms_messages.limit) *
              100
            : 0,
      },
      {
        label: "Monthly Campaign Messages",
        value:
          this.monthly_campaign_messages.limit !== -1
            ? `${this.monthly_campaign_messages.current}/${this.monthly_campaign_messages.limit}`
            : `${this.monthly_campaign_messages.current}/∞`,
        progress:
          this.monthly_campaign_messages.limit !== -1
            ? (this.monthly_campaign_messages.current /
                this.monthly_campaign_messages.limit) *
              100
            : 0,
      },
    ];
  }
}
