import { Auth0ContextInterface } from "@auth0/auth0-react";
import { WidgetConfigDomain } from "entities/domain/widget";
import { RequestType } from "services/request-type";
import { mutationRequest, publicRequest, request } from "util/methods";

type WidgetConfigDTO = {
  id: string;
  merchant_id: number;
  alias: string;
  primary_colour?: string;
  secondary_colour?: string;
  contact_form_url?: string;
  logo?: string;
  button_image_url?: string;
  button_title?: string;
  default_text?: string;
  location_name?: string;
  auto_open_delay?: number;
  hide_powered_by?: boolean;
  prevent_auto_open?: boolean;
  custom_channels?: [
    {
      type: string;
      handle: string;
      disabled: boolean;
    }
  ];
  hide_contact_form?: boolean;
  success_title?: string;
  success_subtitle?: string;
};

type WidgetConfigUpdateCommand = {
  alias: string;
  primary_colour?: string;
  secondary_colour?: string;
  delete_logo?: boolean;
  logo?: File;
  contact_form_url?: string;
  hide_powered_by?: boolean;
  prevent_auto_open?: boolean;
  auto_open_delay?: number;
  location_name?: string;
  button_title?: string;
  success_title?: string;
  success_subtitle?: string;
  hide_contact_form?: boolean;
};

class WidgetService {
  private static transformResponse(
    config: WidgetConfigDTO
  ): WidgetConfigDomain {
    return {
      id: config.id,
      alias: config.alias,
      primaryColour: config.primary_colour,
      secondaryColour: config.secondary_colour,
      externalContactFormUrl: config.contact_form_url,
      logoUrl: config.logo,
      buttonImageUrl: config.button_image_url,
      buttonTitle: config.button_title,
      defaultText: config.default_text,
      locationName: config.location_name,
      hidePoweredBy: config.hide_powered_by,
      preventAutoOpen: config.prevent_auto_open,
      autoOpenDelay: config.auto_open_delay,
      customChannels: config.custom_channels?.map((channel) => ({
        type: channel.type,
        handle: channel.handle,
        disabled: channel.disabled,
      })),
      hideContactForm: config.hide_contact_form,
      successTitle: config.success_title,
      successSubtitle: config.success_subtitle,
    };
  }

  public static async fetchWidgets(
    merchantId: number,
    { getAccessTokenSilently }: Auth0ContextInterface
  ): Promise<WidgetConfigDomain[] | null> {
    const accessToken = await getAccessTokenSilently();

    const response = await request<WidgetConfigDTO[]>(
      RequestType.GET,
      accessToken,
      `/merchants/${merchantId}/widget`
    );
    if (response.data) {
      const widget = response.data;
      return widget.map((item) => this.transformResponse(item));
    }
    return null;
  }

  public static async createWidgetConfig(
    merchant_id: number,
    command: WidgetConfigUpdateCommand,
    { getAccessTokenSilently }: Auth0ContextInterface
  ) {
    const accessToken = await getAccessTokenSilently();

    const response = await mutationRequest<WidgetConfigDTO>(
      RequestType.POST,
      accessToken,
      `/merchants/${merchant_id}/widget`,
      command,
      "multipart/form-data"
    );

    if (response.data) {
      const widget = response.data;
      return this.transformResponse(widget);
    }
  }

  public static async updateWidgetConfig(
    merchant_id: number,
    widget_id: string,
    command: WidgetConfigUpdateCommand,
    { getAccessTokenSilently }: Auth0ContextInterface
  ) {
    const accessToken = await getAccessTokenSilently();

    const response = await mutationRequest<WidgetConfigDTO>(
      RequestType.PUT,
      accessToken,
      `/merchants/${merchant_id}/widget/${widget_id}`,
      command,
      "multipart/form-data"
    );

    if (response.data) {
      const widget = response.data;
      return this.transformResponse(widget);
    }
  }
}

export default WidgetService;
