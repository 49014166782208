export class KeyloopMetadata {
  privacyStatementId: string | null;
  channels: string[];
  userId: string | null;

  constructor(
    privacyStatementId: string | null,
    channels: string[],
    userId: string | null
  ) {
    this.privacyStatementId = privacyStatementId;
    this.channels = channels;
    this.userId = userId;
  }
}

export default class KeyloopAccount {
  enterpriseId: string;
  storeId: string;
  metadata: KeyloopMetadata;
  lastInventorySync: string | null;
  accountName: string | null;

  constructor(
    enterpriseId: string,
    storeId: string,
    metadata: KeyloopMetadata,
    lastInventorySync: string | null,
    accountName: string | null
  ) {
    this.enterpriseId = enterpriseId;
    this.storeId = storeId;
    this.metadata = metadata;
    this.lastInventorySync = lastInventorySync;
    this.accountName = accountName;
  }
}
