import { Text } from "@chakra-ui/react";
import FileDomain from "entities/domain/file";
import React, { memo } from "react";

const FileNameColumn = ({ item }: { item: FileDomain }) => {
  return (
    <Text
      textAlign="left"
      maxWidth="100%"
      overflow="hidden"
      textOverflow="ellipsis"
      whiteSpace="nowrap"
    >
      {item.name}
    </Text>
  );
};

export default memo(FileNameColumn);
