import React, { ChangeEvent, LegacyRef, useEffect, useRef } from "react";
import {
  Input,
  InputGroup,
  InputLeftElement,
  useBreakpointValue,
} from "@chakra-ui/react";
import {
  Color,
  Icon,
  IconType,
} from "theme/old-design-system/styled-components";
import useConversationsStore from "hooks/use-conversations-store";
import useAnalytics from "hooks/use-analytics";
import { useAppSelector } from "redux/hooks";

interface ContactsSearchProps {
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  value: string;
  isDisabled: boolean;
}

const ContactsSearch = ({
  onChange,
  value,
  isDisabled,
}: ContactsSearchProps) => {
  const { activeConversationId } = useAppSelector(
    (state) => state.conversations
  );
  const { colorScheme } = useAppSelector((state) => state.theme);
  const { track } = useAnalytics();
  const isBaseSize = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );
  const inputRef = useRef<HTMLInputElement>();

  useEffect(() => {
    if (!isDisabled && !isBaseSize) {
      inputRef?.current?.focus();
    }
  }, [isDisabled]);

  return (
    <InputGroup flex="0 0 1px">
      <InputLeftElement pointerEvents="none">
        <Icon
          type={IconType.SEARCH}
          pathFill={Color.DARK_GREY}
          height={17}
          width={17}
        />
      </InputLeftElement>
      <Input
        colorScheme={colorScheme}
        ref={inputRef as LegacyRef<HTMLInputElement> | undefined}
        isDisabled={isDisabled}
        placeholder="John Doe"
        onChange={onChange}
        onClick={() => {
          track("customers_search_bar_focused", {
            conversation_id: activeConversationId || null,
          });
        }}
        value={value}
        borderRadius="63px"
      />
    </InputGroup>
  );
};

export default ContactsSearch;
