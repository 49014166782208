import { useColorMode } from "@chakra-ui/color-mode";
import { Icon } from "@chakra-ui/icon";
import { Image } from "@chakra-ui/image";
import { AspectRatio, Flex, Link, Text } from "@chakra-ui/layout";
import { ChakraProps } from "@chakra-ui/system";
import React, { memo, useEffect, useMemo, useState } from "react";
import { useAppSelector } from "redux/hooks";
import { ReactComponent as PDFFile } from "assets/icons/file-pdf-box.svg";
import { ReactComponent as FileIcon } from "assets/icons/file.svg";
import { ReactComponent as FileBrokenIcon } from "assets/icons/file-unknown-svgrepo-com.svg";
import { DownloadIcon } from "@chakra-ui/icons";

interface FilePreviewProps {
  file?: File;
  url?: string;
  type?: string;
  styles?: ChakraProps;
  containerStyles?: ChakraProps;
  isInteractive?: boolean;
}

export enum SIMPLE_TYPE {
  IMAGE = "image",
  VIDEO = "video",
  AUDIO = "audio",
  PDF = "pdf",
  BROKEN = "broken",
  UNKNOWN = "unknown",
}

export const getSimpleType = (type?: string | null): SIMPLE_TYPE => {
  if (!type) {
    return SIMPLE_TYPE.BROKEN;
  }

  if (type.includes("image")) {
    return SIMPLE_TYPE.IMAGE;
  }

  if (type.includes("video")) {
    return SIMPLE_TYPE.VIDEO;
  }

  if (type.includes("audio")) {
    return SIMPLE_TYPE.AUDIO;
  }

  if (type.includes("pdf")) {
    return SIMPLE_TYPE.PDF;
  }

  return SIMPLE_TYPE.UNKNOWN;
};

export const SUPPORTED_MEDIA_TYPES = [
  "image/jpeg",
  "image/jpg",
  "image/png",
  "image/gif",
  "video/mp4",
  "audio/mpeg",
  "audio/mp3",
  "audio/mp4",
  "audio/ogg; codecs=opus",
  "application/pdf",
];

const FilePreview: React.FC<FilePreviewProps> = ({
  file,
  url,
  type,
  styles,
  containerStyles,
  isInteractive = true,
}) => {
  const { colorMode } = useColorMode();
  const { colorScheme } = useAppSelector((state) => state.theme);

  const [actualType, setActualType] = useState<string | null>(
    file?.type || type || null
  );
  const [actualUrl, setActualUrl] = useState<string | null>(
    (file ? URL.createObjectURL(file) : url) || null
  );

  useEffect(() => {
    setActualType(file?.type || type || null);
    setActualUrl((file ? URL.createObjectURL(file) : url) || null);
  }, [file, url, type]);

  const preview = useMemo<React.ReactNode>(() => {
    switch (getSimpleType(actualType)) {
      case SIMPLE_TYPE.IMAGE:
        return (
          <Image
            src={url}
            alt="Image Preview"
            objectFit="scale-down"
            borderRadius="lg"
            maxH="100%"
            maxW="100%"
            {...styles}
          />
        );
      case SIMPLE_TYPE.VIDEO:
        return (
          <AspectRatio
            borderRadius="lg"
            boxSize="10rem"
            maxH="100%"
            maxW="100%"
            {...styles}
          >
            <video
              {...(isInteractive ? { controls: true } : {})}
              style={{
                borderRadius: "0.5rem",
              }}
            >
              <source src={url} type={type} />
            </video>
          </AspectRatio>
        );
      case SIMPLE_TYPE.AUDIO:
        return (
          <AspectRatio borderRadius="lg" maxH="100%" maxW="100%" {...styles}>
            <audio
              {...(isInteractive ? { controls: true } : {})}
              style={{
                borderRadius: "0.5rem",
              }}
            >
              <source src={url} type={type} />
            </audio>
          </AspectRatio>
        );
      case SIMPLE_TYPE.PDF:
        return (
          <Flex
            maxH="100%"
            maxW="100%"
            borderWidth="2px"
            borderStyle="solid"
            borderColor={colorMode === "dark" ? "gray.200" : "gray.400"}
            borderRadius="xl"
            direction="column"
            alignItems="center"
            justifyContent="center"
            p={4}
            {...containerStyles}
          >
            <Icon
              as={PDFFile}
              boxSize="10rem"
              fill={colorMode === "dark" ? "gray.200" : "gray.400"}
              {...styles}
            />
            {isInteractive ? (
              <>
                <Link
                  href={url}
                  isExternal={true}
                  download={true}
                  display="inline-flex"
                  gridGap={2}
                  alignItems="center"
                >
                  <Text>Download</Text>
                  <Icon
                    as={DownloadIcon}
                    fill={
                      colorMode === "dark"
                        ? `${colorScheme}.200`
                        : `${colorScheme}.500`
                    }
                  />
                </Link>
              </>
            ) : null}
          </Flex>
        );
      case SIMPLE_TYPE.BROKEN:
        return (
          <Flex
            maxH="100%"
            maxW="100%"
            borderWidth="1px"
            borderStyle="solid"
            borderColor={colorMode === "dark" ? "gray.200" : "gray.400"}
            borderRadius="xl"
            direction="column"
            alignItems="center"
            justifyContent="center"
            p={4}
            {...containerStyles}
          >
            <Icon
              as={FileBrokenIcon}
              boxSize="10rem"
              borderRadius="lg"
              fill={colorMode === "dark" ? "gray.200" : "gray.400"}
              {...styles}
            />
          </Flex>
        );
      case SIMPLE_TYPE.UNKNOWN:
      default:
        return (
          <Flex
            maxH="100%"
            maxW="100%"
            borderWidth="1px"
            borderStyle="solid"
            borderColor={colorMode === "dark" ? "gray.200" : "gray.400"}
            borderRadius="xl"
            direction="column"
            alignItems="center"
            justifyContent="center"
            p={4}
            {...containerStyles}
          >
            <Icon
              as={FileIcon}
              boxSize="10rem"
              borderRadius="lg"
              fill={colorMode === "dark" ? "gray.200" : "gray.400"}
              {...styles}
            />
            {isInteractive ? (
              <>
                <Link
                  href={url}
                  isExternal={true}
                  download={true}
                  display="inline-flex"
                  gridGap={2}
                  alignItems="center"
                >
                  <Text>Download</Text>
                  <Icon
                    as={DownloadIcon}
                    fill={
                      colorMode === "dark"
                        ? `${colorScheme}.200`
                        : `${colorScheme}.500`
                    }
                  />
                </Link>
              </>
            ) : null}
          </Flex>
        );
    }
  }, [actualType, actualUrl, isInteractive, styles, containerStyles]);

  return preview;
};

export default memo(FilePreview);
