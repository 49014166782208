import { Auth0ContextInterface } from "@auth0/auth0-react";
import MerchantDomainBase from "entities/domain/admin/merchants/merchant-domain";
import { MerchantUsageDomain } from "entities/domain/admin/merchants/merchant-usage";
import { RequestType } from "services/request-type";
import {
  merchantTransformFromDtoToBaseDomain,
  merchantUsageTransformerDtoToDomain,
} from "entities/transformers/admin-merchant-transformer";
import { BankAccountType } from "entities/dto/MerchantDTO";
import {
  deleteRequest,
  getDayNameByIndex,
  patchRequest,
  postRequest,
  putRequest,
  request,
} from "util/methods";
import MerchantBranchDomain from "entities/domain/branch";
import { MerchantBranchDTO } from "entities/dto/MerchantBranchDTO";
import { merchantBranchTransformFromDtoToDomain } from "entities/transformers/merchant-branch-transformer";
import { VehicleMakeAndModelDTO } from "entities/dto/VehicleMakeAndModelDTO";
import { vehicleMakeAndModelTransformFromDtoToDomain } from "entities/transformers/vehicleTransformer";
import { VehicleMakeAndModel } from "entities/domain/vehicle";
import { WorkingHoursDayDomain } from "entities/domain/working-hours";
import { WorkingDay, WorkingHoursDTO } from "entities/dto/WorkingHoursDTO";
import { workingHoursTransformFromDtoToHoursDayDomain } from "entities/transformers/working-hours-transformer";

export interface UpdateMerchantBankDetailsPayload {
  bank_account: BankAccountType;
}

export interface UpdateMerchantPayload {
  googlePlaceId?: string;
  logoUrl?: string;
}

export default class MerchantService {
  public static async getMerchant(
    { getAccessTokenSilently, user }: Auth0ContextInterface,
    id: number
  ): Promise<MerchantDomainBase> {
    const accessToken = await getAccessTokenSilently();

    const merchant = (
      await request(RequestType.GET, accessToken, `/merchants/${id}`)
    ).data;

    return merchantTransformFromDtoToBaseDomain(merchant);
  }

  public static async getMerchantBranches(
    { getAccessTokenSilently, user }: Auth0ContextInterface,
    id: number
  ): Promise<MerchantBranchDomain[]> {
    const accessToken = await getAccessTokenSilently();

    const branches = (
      await request<MerchantBranchDTO[]>(
        RequestType.GET,
        accessToken,
        `/merchants/${id}/branches`
      )
    ).data;

    return branches.map(merchantBranchTransformFromDtoToDomain);
  }

  public static async getMerchantVehicleMakesAndModels(
    { getAccessTokenSilently }: Auth0ContextInterface,
    groupId: string
  ): Promise<VehicleMakeAndModel[]> {
    const accessToken = await getAccessTokenSilently();

    const makesAndModels = (
      await request<VehicleMakeAndModelDTO[]>(
        RequestType.GET,
        accessToken,
        `/merchant-groups/${groupId}/vehicles/makes`
      )
    ).data;

    return makesAndModels.map(vehicleMakeAndModelTransformFromDtoToDomain);
  }

  public static async updateWorkingHours(
    { getAccessTokenSilently }: Auth0ContextInterface,
    workingHours: Array<WorkingHoursDayDomain | null>,
    merchantId: number
  ): Promise<void> {
    const accessToken = await getAccessTokenSilently();

    const newWorkingHours = {} as WorkingHoursDTO;

    workingHours.forEach((workingHour, index) => {
      const dayName = getDayNameByIndex(index);

      if (workingHour) {
        newWorkingHours[dayName as WorkingDay] = {
          start_time: {
            hour: workingHour.open.hour,
            minute: workingHour.open.minute,
          },
          end_time: {
            hour: workingHour.close.hour,
            minute: workingHour.close.minute,
          },
        };
      }
    });

    await putRequest(
      RequestType.PUT,
      accessToken,
      `/merchants/${merchantId}/working-hours`,
      newWorkingHours
    );
  }

  public static async getWorkingHours(
    { getAccessTokenSilently }: Auth0ContextInterface,
    merchantId: number
  ): Promise<Array<WorkingHoursDayDomain | null>> {
    const accessToken = await getAccessTokenSilently();

    const workingHours = (
      await request<WorkingHoursDTO>(
        RequestType.GET,
        accessToken,
        `/merchants/${merchantId}/working-hours`
      )
    ).data;

    return workingHoursTransformFromDtoToHoursDayDomain(workingHours);
  }

  public static async updateMerchant(
    { getAccessTokenSilently }: Auth0ContextInterface,
    payload: UpdateMerchantPayload,
    merchantId: number
  ): Promise<MerchantDomainBase> {
    const accessToken = await getAccessTokenSilently();

    const merchant = (
      await patchRequest(
        RequestType.PATCH,
        accessToken,
        `/merchants/${merchantId}`,
        {
          google_place_id: payload.googlePlaceId,
          logo_url: payload.logoUrl,
        }
      )
    ).data;

    return merchantTransformFromDtoToBaseDomain(merchant);
  }

  public static async updateMerchantBankDetails(
    { getAccessTokenSilently }: Auth0ContextInterface,
    payload: UpdateMerchantBankDetailsPayload,
    merchantId: number
  ): Promise<void> {
    const accessToken = await getAccessTokenSilently();

    await putRequest(
      RequestType.PUT,
      accessToken,
      `/merchants/${merchantId}/bank-account`,
      payload.bank_account
    );
  }

  public static async getApiKey(
    { getAccessTokenSilently }: Auth0ContextInterface,
    merchantId: number
  ): Promise<string | null> {
    const accessToken = await getAccessTokenSilently();

    try {
      const response = await request<{ api_key: string }>(
        RequestType.GET,
        accessToken,
        `merchants/${merchantId}/api-key`
      );
      return response.data.api_key;
    } catch (err) {
      return null;
    }
  }

  public static async generateApiKey(
    { getAccessTokenSilently }: Auth0ContextInterface,
    merchantId: number
  ): Promise<string | null> {
    const accessToken = await getAccessTokenSilently();

    try {
      const response = await postRequest<{ api_key: string }>(
        accessToken,
        `merchants/${merchantId}/api-key`,
        {}
      );
      return response.data.api_key;
    } catch (err) {
      return null;
    }
  }

  public static async revokeApiKey(
    { getAccessTokenSilently }: Auth0ContextInterface,
    merchantId: number
  ): Promise<boolean> {
    const accessToken = await getAccessTokenSilently();

    try {
      const response = await deleteRequest<{ api_key: string }>(
        RequestType.DELETE,
        accessToken,
        `merchants/${merchantId}/api-key`,
        {}
      );
      return true;
    } catch (err) {
      return false;
    }
  }

  public static async getMerchantUsage(
    { getAccessTokenSilently }: Auth0ContextInterface,
    merchantId: number
  ): Promise<MerchantUsageDomain> {
    const accessToken = await getAccessTokenSilently();

    const merchantUsage = (
      await request(
        RequestType.GET,
        accessToken,
        `/merchants/${merchantId}/usage`
      )
    ).data;

    return merchantUsageTransformerDtoToDomain(merchantUsage);
  }
}
