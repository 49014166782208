import React from "react";
import {
  Box,
  Button as ChakraButton,
  Icon,
  Link,
  Text,
  useBreakpointValue,
  VStack,
} from "@chakra-ui/react";
import getSymbolFromCurrency from "currency-symbol-map";
import {
  BodySize,
  BodyText,
  Color,
  FWeight,
} from "theme/old-design-system/styled-components";
import { ReactComponent as DownloadIcon } from "assets/icons/download.svg";
import { PaymentProvider } from "../index";
import ConsentLineItem from "./consentLineItem";

interface ConsentScreenProps {
  onContinue: () => void;
  amount: number | undefined;
  currency: string | undefined;
  paymentMethod: PaymentProvider | undefined;
  accountHolderName: string | undefined;
  selectedBank: string | undefined;
  invoiceUrl?: string;
  hasInvoice?: boolean;
}
const ConsentScreen = ({
  onContinue,
  paymentMethod,
  amount,
  currency,
  accountHolderName,
  selectedBank,
  invoiceUrl,
  hasInvoice,
}: ConsentScreenProps) => {
  const isBaseSize = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );

  let consentText = "";

  switch (paymentMethod) {
    case PaymentProvider.YAPILY:
    default:
      consentText = `You will be directed to ${selectedBank} to complete this payment securely.`;
  }

  return (
    <>
      <Text fontWeight={700} fontSize="md" align="center" mb="20px">
        Continue with {selectedBank}
      </Text>
      <Text fontSize="14px" align="center" mb="20px" mx={5}>
        {consentText}
      </Text>

      <Box ml="48px" mr="62px" pb="11px" alignItems="center">
        <Text
          fontWeight={700}
          fontSize="sm"
          color={`${Color.SMOKY_GREY.value}`}
          w={isBaseSize ? "75vw" : "30vw"}
          mx="auto"
        >
          Transfer Details
        </Text>
        <Box
          borderY={`1px solid ${Color.LIGHT_GREY.value}`}
          w={isBaseSize ? "75vw" : "30vw"}
          mx="auto"
          py={2}
          mt={5}
          mb={5}
        >
          <ConsentLineItem
            title="Amount"
            text={`${getSymbolFromCurrency(currency!)}${amount?.toFixed(2)}`}
          />
          <ConsentLineItem title="Beneficiary" text={accountHolderName} />
        </Box>
      </Box>
      <VStack spacing={0}>
        <ChakraButton
          w="289px"
          color="#fff"
          bgGradient="linear(306.44deg, #3559FF 10.6%, #36B2FA 88.1%)"
          borderRadius="75px"
          _hover={{ opacity: 0.5 }}
          _focus={{ outline: "none" }}
          onClick={onContinue}
        >
          <BodyText
            size={BodySize.THREE}
            colorName={Color.WHITE}
            fweight={FWeight.ONE}
          >
            Continue
          </BodyText>
        </ChakraButton>
        <Link
          display={hasInvoice ? "flex" : "none"}
          justifyContent="center"
          pt="5px"
          href={invoiceUrl}
          download
        >
          <BodyText size={BodySize.THREE} colorName={Color.SMOKY_GREY}>
            Download invoice
          </BodyText>
          <Icon as={DownloadIcon} marginLeft="12px" />
        </Link>
      </VStack>
    </>
  );
};

export default ConsentScreen;
