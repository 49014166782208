import {
  Box,
  Flex,
  HStack,
  Icon,
  Input,
  Text,
  VStack,
  useBreakpointValue,
  useColorMode,
} from "@chakra-ui/react";
import FileDomain from "entities/domain/file";
import useDebounce from "hooks/use-debounce";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useAppSelector } from "redux/hooks";
import FileAvatarColumn from "components/user-settings/Files/columns/FileAvatarColumn";
import FileNameColumn from "components/user-settings/Files/columns/FileNameColumn";
import { ViewportListRef } from "react-viewport-list";
import FilesService from "services/files";
import { useAuth0 } from "@auth0/auth0-react";
import { numberOfFilesPerLoad } from "util/constants";
import { MdPreview } from "react-icons/md";
import SmartList from "../SmartList";
import FilePreview from "../FilePreview";

interface FileSearchProps {
  selectedFile: FileDomain | null;
  selectFile: (file: FileDomain | null) => void;
}

const hasMoreFiles = (
  filesAmount: number,
  currentPageAmount: number
): boolean => {
  if (filesAmount < numberOfFilesPerLoad) {
    return false;
  }

  if (!currentPageAmount) {
    return false;
  }

  return true;
};

const FileSearch: React.FC<FileSearchProps> = ({
  selectedFile,
  selectFile,
}) => {
  const isBaseSize = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );
  const auth0Context = useAuth0();
  const listRef = useRef<ViewportListRef | null>(null);
  const { merchant } = useAppSelector((state) => state.merchant);
  const { files } = useAppSelector((state) => state.files);
  const listContainerRef = useRef<HTMLDivElement>(null);
  const [searchText, setSearchText] = useState<string>("");
  const debouncedSearchText = useDebounce<string>(searchText, 500);
  const { colorMode } = useColorMode();
  const [localFiles, setLocalFiles] = useState<FileDomain[]>(files || []);
  const [hasNextPage, setHasNextPage] = useState<boolean>(true);

  const fetchMoreFiles = async (isReset: boolean = false) => {
    let nextPage: FileDomain[] = [];

    try {
      nextPage = await FilesService.getFiles(
        auth0Context,
        merchant.id,
        debouncedSearchText,
        isReset ? 0 : files.length
      );
    } catch (error: unknown) {
      // eslint-disable-next-line
      console.error("Error fetching files", error);
    }

    const newFiles: FileDomain[] = !isReset ? files.concat(nextPage) : nextPage;

    setLocalFiles(newFiles);
    setHasNextPage(hasMoreFiles(newFiles.length, nextPage?.length || 0));
  };

  useEffect(() => {
    fetchMoreFiles(true);
  }, [debouncedSearchText]);

  const fileColumns = useMemo(() => {
    return [
      {
        label: "Thumbnail",
        component: FileAvatarColumn,
      },
      {
        label: "Name",
        component: FileNameColumn,
      },
    ];
  }, [isBaseSize]);

  return (
    <VStack
      spacing={6}
      w="100%"
      justifyContent="start"
      tabIndex={0}
      outline="none"
      onKeyDown={(event) => {
        if (event.key === "ArrowDown") {
          const index = localFiles.findIndex(
            (file) => file.id === selectedFile?.id
          );
          if (index === -1) {
            selectFile(localFiles[0]);
            listRef?.current?.scrollToIndex({
              index: 0,
            });
          } else if (index < localFiles.length - 1) {
            selectFile(localFiles[index + 1]);
            listRef?.current?.scrollToIndex({
              index: index + 1,
            });
          } else if (index >= localFiles.length - 1) {
            selectFile(localFiles[0]);
            listRef?.current?.scrollToIndex({
              index: 0,
            });
          }
        } else if (event.key === "ArrowUp") {
          const index = localFiles.findIndex(
            (file) => file.id === selectedFile?.id
          );
          if (index === -1) {
            selectFile(localFiles[0]);
            listRef?.current?.scrollToIndex({
              index: 0,
            });
          } else if (index > 0) {
            selectFile(localFiles[index - 1]);
            listRef?.current?.scrollToIndex({
              index: index - 1,
            });
          } else if (index === 0) {
            selectFile(localFiles[localFiles.length - 1]);
            listRef?.current?.scrollToIndex({
              index: localFiles.length - 1,
            });
          }
        }
      }}
    >
      <Input
        placeholder="Search file"
        onChange={(event) => {
          setSearchText(event.target.value);
        }}
      />
      <HStack
        alignItems="stretch"
        w="100%"
        {...(isBaseSize
          ? {}
          : {
              minH: "60vh",
              h: "60vh",
              maxH: "60vh",
            })}
      >
        <Box
          ref={listContainerRef}
          height="100%"
          overflowY="auto" // doesn't work on mobile but doesn't need to
          flexGrow={1}
          flexShrink={1}
          flexBasis="auto"
          sx={{
            ".smart-list-item": {
              borderRadius: "lg",
            },
            ".active": {
              backgroundColor: colorMode === "dark" ? "gray.800" : "gray.100",
            },
          }}
        >
          <SmartList
            ref={listRef}
            items={localFiles}
            activeItem={selectedFile}
            itemIdentifier="id"
            containerRef={listContainerRef}
            canFetchMore={true}
            hasHeader={false}
            hasNextPage={hasNextPage}
            fetchMore={fetchMoreFiles}
            columns={fileColumns}
            onItemClick={selectFile}
          />
        </Box>
        {isBaseSize ? null : !selectedFile ? (
          <Flex
            flexGrow={1}
            flexShrink={0}
            flexBasis="auto"
            maxWidth="50%"
            minWidth="50%"
            borderRadius="lg"
            backgroundColor={colorMode === "dark" ? "gray.900" : "gray.50"}
            justifyContent="center"
            alignItems="center"
          >
            <Icon
              as={MdPreview}
              boxSize={16}
              color={colorMode === "dark" ? "gray.700" : "gray.200"}
            />
          </Flex>
        ) : (
          <VStack
            maxWidth="50%"
            minWidth="50%"
            height="100%"
            overflow="hidden"
            flexGrow={1}
            flexShrink={0}
            flexBasis="auto"
            borderRadius="lg"
            backgroundColor={colorMode === "dark" ? "gray.900" : "gray.50"}
            justifyContent="center"
            alignItems="center"
          >
            <FilePreview
              type={selectedFile.type}
              url={selectedFile.url}
              styles={{
                maxH: "69%",
              }}
            />
            <Text
              fontWeight="bold"
              color={colorMode === "dark" ? "gray.700" : "gray.200"}
              maxW="50%"
              textOverflow="ellipsis"
              overflow="hidden"
              whiteSpace="nowrap"
            >
              {selectedFile.name}
            </Text>
            <Text
              mb={6}
              color={colorMode === "dark" ? "gray.700" : "gray.200"}
              textOverflow="ellipsis"
              overflow="hidden"
              maxW="50%"
              whiteSpace="nowrap"
            >
              {selectedFile.createdAt}
            </Text>
          </VStack>
        )}
      </HStack>
    </VStack>
  );
};

export default FileSearch;
