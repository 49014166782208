import { Avatar, AvatarBadge, Icon, useColorMode } from "@chakra-ui/react";
import ReviewDomain, {
  ReviewProvider,
} from "entities/domain/reviews/review-domain";
import { ReactComponent as GoogleIcon } from "assets/icons/google.svg";
import { ReactComponent as FacebookIcon } from "assets/icons/facebook.svg";
import React, { useEffect } from "react";
import { useAppSelector } from "redux/hooks";

interface ReviewAvatarProps {
  review: ReviewDomain;
  isBig?: boolean;
}

const getIconByProvider = (
  provider: ReviewProvider,
  colorMode: "dark" | "light"
) => {
  switch (provider) {
    case ReviewProvider.GOOGLE:
      return (
        <Icon
          as={GoogleIcon}
          fill={colorMode === "dark" ? "gray.800" : "gray.600"}
        />
      );
    case ReviewProvider.FACEBOOK:
      return (
        <Icon
          as={FacebookIcon}
          fill={colorMode === "dark" ? "gray.800" : "gray.600"}
        />
      );
    default:
      return (
        <Icon
          as={GoogleIcon}
          fill={colorMode === "dark" ? "gray.800" : "gray.600"}
        />
      );
  }
};

const ReviewAvatar = ({ review, isBig = false }: ReviewAvatarProps) => {
  const { merchant } = useAppSelector((state) => state.merchant);
  const { colorMode } = useColorMode();

  const [shouldShowBadge, setShouldShowBadge] = React.useState<boolean>(false);

  useEffect(() => {
    if (!merchant) {
      return;
    }

    setShouldShowBadge(merchant.hasMoreThanOneReviewIntegration());
  }, [merchant]);

  return (
    <Avatar
      key={review.reviewer.customerId}
      size={isBig ? "xl" : "md"}
      src={review.reviewer.avatar || undefined}
      name={review.reviewer.name || undefined}
    >
      {shouldShowBadge ? (
        <AvatarBadge
          border="none"
          bgColor={colorMode === "light" ? "red.100" : "red.300"}
          p={isBig ? 2 : 1}
        >
          {getIconByProvider(review.provider, colorMode)}
        </AvatarBadge>
      ) : null}
    </Avatar>
  );
};

export default ReviewAvatar;
