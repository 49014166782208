import React from "react";
import {
  Flex,
  Heading,
  Icon,
  Image,
  Modal,
  ModalContent,
  ModalOverlay,
  useBreakpointValue,
  ModalBody,
  ModalCloseButton,
  Text,
} from "@chakra-ui/react";
import { ReactSVG } from "react-svg";
import { IntegrationOptionGroups } from "../../../Integrations/ConnectIntegrationTypes";
import StepsContent from "./StepsContent";

interface ConnectIntegrationModalProps {
  isOpen: boolean;
  onClose: () => void;
  imgWidth?: string;
  imgHeight?: string;
  displayError: boolean;
  integrationOption: IntegrationOptionGroups | undefined;
}

const InstagramSetUpSteps = ({
  isOpen,
  onClose,
  integrationOption,
  imgWidth,
  imgHeight,
  displayError,
}: ConnectIntegrationModalProps) => {
  const isBaseSize = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );

  const steps = integrationOption?.extendedConnect?.steps;

  return (
    <Modal isOpen={isOpen} onClose={onClose} size={isBaseSize ? "full" : "xl"}>
      <ModalOverlay />
      <ModalContent pb={5}>
        <ModalCloseButton />
        <ModalBody>
          <Flex
            alignItems="center"
            pt={isBaseSize ? "1rem" : "2.75rem"}
            px="2.5rem"
            direction={isBaseSize ? "column" : "row"}
          >
            <Image
              justifyContent="center"
              mb={isBaseSize ? "0.5rem" : 0}
              mr={isBaseSize ? 0 : "0.5rem"}
              as={ReactSVG}
              src={integrationOption?.mobileLogo}
              __css={{
                svg: {
                  height: "30px",
                  width: "30px",
                },
              }}
            />
            <Heading size="lg" as="h3">
              {integrationOption?.extendedConnect?.title}
            </Heading>
          </Flex>
          {displayError && (
            <Flex
              mt="35px"
              border="1px solid rgba(255, 0, 0, 0.13)"
              bgColor="rgba(255, 0, 0, 0.08)"
              alignItems="flex-start"
              py="0.75rem"
              px="0.5rem"
            >
              <Icon
                as={ReactSVG}
                src="/failed-status.svg"
                mr="30px"
                __css={{ svg: { height: "30px", width: "30px" } }}
              />
              <Text>{integrationOption?.extendedConnect?.warning}</Text>
            </Flex>
          )}
          <StepsContent
            steps={steps}
            imgHeight={imgHeight}
            imgWidth={imgWidth}
          />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default InstagramSetUpSteps;
