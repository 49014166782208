import LeadEmailHandlerDomain from "entities/domain/leads/domain";
import { LeadEmailHandlerDto } from "entities/dto/LeadEmailHandlerDTO";

export function leadEmailHandlerDtoToDomain(
  p: LeadEmailHandlerDto
): LeadEmailHandlerDomain {
  return new LeadEmailHandlerDomain(
    p.id,
    p.email,
    p.source,
    p.merchant_id,
    p.created_at
  );
}
