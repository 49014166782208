export enum WhatsappBusinessVertical {
  UNDEFINED = "UNDEFINED",
  OTHER = "OTHER",
  AUTO = "AUTO",
  BEAUTY = "BEAUTY",
  APPAREL = "APPAREL",
  EDU = "EDU",
  ENTERTAIN = "ENTERTAIN",
  EVENT_PLAN = "EVENT_PLAN",
  FINANCE = "FINANCE",
  GROCERY = "GROCERY",
  GOVT = "GOVT",
  HOTEL = "HOTEL",
  HEALTH = "HEALTH",
  NONPROFIT = "NONPROFIT",
  PROF_SERVICES = "PROF_SERVICES",
  RETAIL = "RETAIL",
  TRAVEL = "TRAVEL",
  RESTAURANT = "RESTAURANT",
  NOT_A_BIZ = "NOT_A_BIZ",
}

export enum MessagingLimit {
  TIER_50 = "TIER_50",
  TIER_250 = "TIER_250",
  TIER_1K = "TIER_1K",
  TIER_10K = "TIER_10K",
  TIER_100K = "TIER_100K",
  TIER_UNLIMITED = "TIER_UNLIMITED",
}

export default class WhatsappProfile {
  phoneNumber: string;

  email: string;

  vertical: WhatsappBusinessVertical;

  profilePictureUrl: string;

  aboutText: string;

  address: string;

  description: string;

  websites: string[];

  currentLimit: MessagingLimit;

  currentQualityRating: string;

  constructor(
    phoneNumber: string,
    email: string,
    vertical: WhatsappBusinessVertical,
    profilePictureUrl: string,
    aboutText: string,
    address: string,
    description: string,
    websites: string[],
    currentLimit: MessagingLimit,
    currentQualityRating: string
  ) {
    this.phoneNumber = phoneNumber;
    this.email = email;
    this.vertical = vertical;
    this.profilePictureUrl = profilePictureUrl;
    this.aboutText = aboutText;
    this.address = address;
    this.description = description;
    this.websites = websites;
    this.currentLimit = currentLimit;
    this.currentQualityRating = currentQualityRating;
  }

  public static getQualityRatingColorScheme(qualityRating: string): string {
    switch (qualityRating.toUpperCase()) {
      case "LOW":
        return "red";
      case "MEDIUM":
        return "yellow";
      case "HIGH":
        return "green";
      default:
        return "gray";
    }
  }

  public static getCurrentLimitProgress(currentLimit: MessagingLimit): number {
    switch (currentLimit) {
      case MessagingLimit.TIER_50:
        return 10;
      case MessagingLimit.TIER_250:
        return 30;
      case MessagingLimit.TIER_1K:
        return 50;
      case MessagingLimit.TIER_10K:
        return 70;
      case MessagingLimit.TIER_100K:
        return 90;
      case MessagingLimit.TIER_UNLIMITED:
        return 100;
      default:
        return 0;
    }
  }

  public static getCurrentLimitText(currentLimit: MessagingLimit): string {
    switch (currentLimit) {
      case MessagingLimit.TIER_250:
        return "Tier 250";
      case MessagingLimit.TIER_1K:
        return "Tier 1K";
      case MessagingLimit.TIER_10K:
        return "Tier 10K";
      case MessagingLimit.TIER_100K:
        return "Tier 100K";
      case MessagingLimit.TIER_UNLIMITED:
        return "Tier Unlimited";
      default:
        return "Tier 50";
    }
  }
}
