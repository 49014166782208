import BankAccountDetailsDomain from "./bank-account-details-domain";

export default class BankAccountDomain {
  country: string;

  beneficiaryName: string;

  details: BankAccountDetailsDomain;

  constructor(
    country: string,
    beneficiaryName: string,
    details: BankAccountDetailsDomain
  ) {
    this.country = country;
    this.beneficiaryName = beneficiaryName;
    this.details = details;
  }
}
