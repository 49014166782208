import { CheckIcon } from "@chakra-ui/icons";
import {
  Badge,
  Box,
  Button,
  Flex,
  HStack,
  VStack,
  useBreakpointValue,
  useColorMode,
} from "@chakra-ui/react";
import React, { useLayoutEffect, useRef, useState } from "react";

interface IntegrationProps {
  icon: React.ReactNode;
  primaryButton?: React.ReactNode;
  secondaryButton?: React.ReactNode;
  isConnected?: boolean;
  children: React.ReactNode;
}

const Integration = ({
  isConnected = false,
  primaryButton,
  secondaryButton,
  icon,
  children,
}: IntegrationProps) => {
  const { colorMode } = useColorMode();
  const isBaseSize = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );

  const integrationRef = useRef<HTMLDivElement>(null);

  const [isCollapsed, setIsCollapsed] = useState(true);
  const [isLongText, setIsLongText] = useState(false);

  useLayoutEffect(() => {
    const { current } = integrationRef;

    if (!current) {
      setIsLongText(false);
      return;
    }

    const trigger = () => {
      const hasOverflow = current.scrollHeight > current.clientHeight;

      setIsLongText(hasOverflow);
    };

    if ("ResizeObserver" in window) {
      new ResizeObserver(trigger).observe(current);
    }

    trigger();
  }, [integrationRef]);

  return (
    <VStack
      borderRadius="2xl"
      borderWidth="1px"
      borderColor={colorMode === "dark" ? "gray.600" : "gray.100"}
      p={4}
      alignItems="start"
      width="300px"
      minWidth="300px"
      maxWidth="300px"
      height={isCollapsed ? "300px" : "fit-content"}
      minHeight="300px"
      maxHeight={isCollapsed ? "300px" : "fit-content"}
      justifyContent="stretch"
      spacing={2}
      position="relative"
    >
      <HStack
        flexBasis="auto"
        flexShrink={0}
        flexGrow={0}
        w="100%"
        justifyContent={isConnected ? "space-between" : "start"}
      >
        {icon}
        {isConnected ? (
          <Badge colorScheme="green" py={1} px={2} borderRadius="full">
            Connected <CheckIcon ml={1} />
          </Badge>
        ) : null}
      </HStack>
      <Box
        flexBasis="auto"
        flexShrink={0}
        flexGrow={1}
        w="100%"
        maxHeight={isCollapsed ? "150px" : "fit-content"}
        overflow="hidden"
        whiteSpace="pre-wrap"
        ref={integrationRef}
        __css={{
          ...(isCollapsed && isLongText
            ? {
                maskImage:
                  "linear-gradient(to bottom, black 50%, transparent 100%)",
              }
            : {}),
        }}
      >
        {children}
      </Box>
      {isLongText && isCollapsed && (
        <Button
          position="absolute"
          top="60%"
          left="40%"
          size="xs"
          colorScheme={colorMode === "dark" ? "whiteAlpha" : "blackAlpha"}
          w="auto"
          onClick={() => setIsCollapsed(false)}
        >
          Expand
        </Button>
      )}
      <HStack
        flexBasis="auto"
        flexShrink={0}
        flexGrow={1}
        w="100%"
        alignItems="end"
        justifyContent={
          primaryButton && secondaryButton
            ? "space-between"
            : primaryButton
            ? "flex-end"
            : "flex-start"
        }
      >
        {secondaryButton || null}
        {primaryButton || null}
      </HStack>
    </VStack>
  );
};

export default Integration;
