import {
  Button,
  HStack,
  Heading,
  Icon,
  Text,
  VStack,
  useColorMode,
} from "@chakra-ui/react";
import { ConnectButton } from "360dialog-connect-button";
import Integration from "components/user-settings/shared/Integration";
import IntegrationDescription from "components/user-settings/shared/IntegrationDescription";
import React, { useEffect, useState } from "react";
import { FaPlus } from "react-icons/fa";
import { ReactComponent as WhatsappIcon } from "assets/icons/whatsapp-new.svg";
import { MerchantUserGuides } from "entities/domain/admin/merchants/merchant-user-guides";
import { useAppSelector } from "redux/hooks";
import { useAuth0 } from "@auth0/auth0-react";
import ChannelService from "services/channel";
import DisconnectIntegrationButton from "components/user-settings/shared/DisconnectIntegrationButton";
import { CheckIcon, ViewIcon } from "@chakra-ui/icons";
import { useNavigate } from "react-router-dom";

interface WhatsappIntegrationItemProps {}

const WhatsappIntegrationItem = (_props: WhatsappIntegrationItemProps) => {
  const { colorMode } = useColorMode();
  const { colorScheme } = useAppSelector((state) => state.theme);
  const auth0Context = useAuth0();
  const merchant = useAppSelector((state) => state.merchant.merchant);
  const navigate = useNavigate();

  const [isDisabled, setIsDisabled] = useState<boolean>(false);

  const isConnected = !!merchant.channels.find(
    (c) => c.channelName === "whatsapp"
  );

  useEffect(() => {
    if (merchant.userGuides.pending.includes(MerchantUserGuides.ONBOARDING)) {
      setIsDisabled(true);

      return;
    }

    setIsDisabled(false);
  }, [merchant.userGuides.pending]);

  const partnerId = process.env.REACT_APP_360_PARTNER_ID;
  let whatsappConnectButton = null;

  if (partnerId) {
    whatsappConnectButton = (
      <Button
        colorScheme={colorScheme}
        as={ConnectButton}
        partnerId={partnerId}
        callback={() => {}}
        label="Connect"
        queryParameters={{
          partner: merchant.public_id,
          name: merchant.name,
        }}
      />
    );
  } else {
    whatsappConnectButton = (
      <Button
        colorScheme={colorScheme}
        leftIcon={<Icon as={FaPlus} />}
        onClick={() => {
          window.open("https://calendly.com/hector-105/30min", "_blank");
        }}
      >
        Connect
      </Button>
    );
  }

  return (
    <Integration
      icon={<Icon as={WhatsappIcon} height="3rem" width="3rem" />}
      isConnected={isConnected}
      secondaryButton={
        isConnected && !isDisabled ? (
          <DisconnectIntegrationButton
            onClick={() => {
              const whatsappChannel = merchant.channels.find(
                (c) => c.channelName === "whatsapp"
              );

              if (whatsappChannel) {
                ChannelService.disableWhatsappChannel(
                  auth0Context,
                  whatsappChannel.identifier,
                  merchant.id
                );
              }

              window.open("https://calendly.com/hector-105/30min", "_blank");
            }}
          />
        ) : null
      }
      primaryButton={
        !isConnected && !isDisabled ? (
          whatsappConnectButton
        ) : merchant.isWhatsappInfoEnabled() ? (
          <Button
            colorScheme={colorScheme}
            leftIcon={<Icon as={ViewIcon} />}
            onClick={() => {
              navigate(`/${merchant.id}/settings/integrations/whatsapp`);
            }}
          >
            View
          </Button>
        ) : null
      }
    >
      <Heading as="h3" size="lg">
        WhatsApp
      </Heading>
      <IntegrationDescription
        text={
          isConnected ? (
            "Seamlessly connect with clients via the worlds most popular messaging app."
          ) : (
            <VStack spacing={4}>
              <Text color={colorMode === "dark" ? "gray.600" : "gray.400"}>
                WhatsApp is owned by Facebook and to get started, Facebook will
                need to approve your business. We’ve compiled a few best
                practices to provide you with the best chance of success during
                this process!
              </Text>
              <Text color={colorMode === "dark" ? "gray.600" : "gray.400"}>
                To install WhatsApp, Facebook will require the following:
              </Text>
              <VStack spacing={2} justifyContent="start" alignItems="start">
                <HStack spacing={2}>
                  <CheckIcon color={`${colorScheme}.400`} />
                  <Text color={colorMode === "dark" ? "gray.600" : "gray.400"}>
                    Legal company name and address (e.g. Ceritficate of
                    Incorporation)
                  </Text>
                </HStack>
                <HStack spacing={2}>
                  <CheckIcon color={`${colorScheme}.400`} />
                  <Text color={colorMode === "dark" ? "gray.600" : "gray.400"}>
                    Phone number for WhatsApp (e.g. your Fuzey number)
                  </Text>
                </HStack>
                <HStack spacing={2}>
                  <CheckIcon color={`${colorScheme}.400`} />
                  <Text color={colorMode === "dark" ? "gray.600" : "gray.400"}>
                    Facebook Business Manager
                  </Text>
                </HStack>
              </VStack>
            </VStack>
          )
        }
      />
    </Integration>
  );
};

export default WhatsappIntegrationItem;
