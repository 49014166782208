import React from "react";
import { HamburgerIcon } from "@chakra-ui/icons";
import {
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Box,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { useAppSelector } from "redux/hooks";

const TopbarMenuBtn = () => {
  const { merchant } = useAppSelector((state) => state.merchant);

  return (
    <Box w="25%">
      <Menu>
        <MenuButton
          as={IconButton}
          aria-label="Options"
          icon={<HamburgerIcon />}
          variant="outline"
        />
        <MenuList>
          <Link to={`/${merchant.id}/admin`}>
            <MenuItem>Admin Overview</MenuItem>
          </Link>
          <Link to={`/${merchant.id}/admin/merchants`}>
            <MenuItem>Merchants</MenuItem>
          </Link>
          <Link to={`/${merchant.id}/admin/agents`}>
            <MenuItem>Agents</MenuItem>
          </Link>
          <Link to={`/${merchant.id}/admin/ai`}>
            <MenuItem>AI</MenuItem>
          </Link>
        </MenuList>
      </Menu>
    </Box>
  );
};

export default TopbarMenuBtn;
