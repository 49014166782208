import { CloseIcon } from "@chakra-ui/icons";
import {
  Box,
  Flex,
  Heading,
  Modal,
  ModalContent,
  ModalOverlay,
  Spinner,
  useBreakpointValue,
} from "@chakra-ui/react";
import {
  Button,
  ButtonSize,
  ButtonType,
} from "theme/old-design-system/styled-components";
import ContactDomain from "entities/domain/customers/contact-domain";
import useContactsStore from "hooks/use-contacts-store";
import { useAppSelector } from "redux/hooks";
import React, { useState } from "react";
import ChooseContactToMerge from "./ChooseContactToMerge";
import MergeSelectedContact from "./MergeSelectedContact";

interface MergeContactProps {
  isOpen: boolean;
  contactToMerge: ContactDomain;
  onClose: (updatedContactResponse: ContactDomain | undefined) => void;
}

const MergeContact = ({
  contactToMerge,
  isOpen,
  onClose,
}: MergeContactProps) => {
  const [step, setStep] = useState<number>(1);
  const [contactToMergeInto, setContactToMergeInto] = useState<ContactDomain>();
  const [displayContinueMerge, setDisplayContinueMerge] =
    useState<boolean>(true);
  const { mergeContact } = useContactsStore();
  const { modalLoading } = useAppSelector((state) => state.contacts);

  const isBaseSize = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );

  const onMergeContacts = async () => {
    const result = await mergeContact({
      id: contactToMergeInto!.id!,
      contactIds: [contactToMerge.id!],
    });
    if (result) {
      onClose(result);
    }
  };

  const cleanUpBeforeClosing = () => {
    onClose(undefined);
  };

  const mergeContent = (
    <>
      <Flex p="16px 24px" justify="space-between">
        <Heading fontSize="1.25rem"> Merge Contact</Heading>
        <CloseIcon onClick={() => onClose(undefined)} fontSize="12px" />
      </Flex>

      {step === 1 ? (
        <ChooseContactToMerge
          contactToMergeFrom={contactToMerge}
          contactToMergeInto={contactToMergeInto}
          setContactToMergeInto={setContactToMergeInto}
          setDisplayContinueMerge={setDisplayContinueMerge}
        />
      ) : (
        <MergeSelectedContact
          contactToMergeFrom={contactToMerge}
          contactToMergeInto={contactToMergeInto!}
        />
      )}
      <Flex px="40px" pt="24px" pb="16px">
        {step === 2 && (
          <Button
            onClick={() => setStep(1)}
            type={ButtonType.DEFAULT}
            size={ButtonSize.LARGE}
            mr={10}
          >
            Back
          </Button>
        )}
        {displayContinueMerge && (
          <Button
            isDisabled={!contactToMergeInto}
            onClick={() => (step === 1 ? setStep(2) : onMergeContacts())}
            type={ButtonType.PRIMARY}
            size={ButtonSize.FULL}
          >
            {(modalLoading && <Spinner />) ||
              (step === 1 && "Continue") ||
              (step === 2 && "Merge")}
          </Button>
        )}
      </Flex>
    </>
  );

  const DesktopMergeModal = (
    <Modal
      isOpen={isOpen}
      onClose={cleanUpBeforeClosing}
      size={isBaseSize ? "full" : "md"}
    >
      <ModalOverlay />

      <ModalContent h="80%">{mergeContent}</ModalContent>
    </Modal>
  );

  return DesktopMergeModal;
};

export default MergeContact;
