import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  HStack,
  ListItem,
  Text,
  UnorderedList,
  VStack,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import {
  WabaTemplateCategory,
  WhatsappButton,
} from "entities/domain/whatsapp-template";
import { validateWhatsappTempalte } from "./utils";

interface ValidationErrorsProps {
  templateName: string;
  templateCategory: WabaTemplateCategory;
  headerText: string;
  bodyText: string;
  footerText: string;
  buttons: WhatsappButton[];
  file: File | null;
  onValidationComplete: (isValid: boolean) => void;
}

const ValidationErrors = ({
  templateName,
  templateCategory,
  headerText,
  bodyText,
  footerText,
  buttons,
  file,
  onValidationComplete,
}: ValidationErrorsProps) => {
  const [validationErrors, setValidationErrors] = useState<string[]>([]);

  useEffect(() => {
    const newValidationErrors = validateWhatsappTempalte(
      templateName,
      templateCategory,
      headerText,
      bodyText,
      file,
      footerText,
      buttons
    );

    setValidationErrors(newValidationErrors);
    onValidationComplete(newValidationErrors.length === 0);
  }, [
    templateName,
    templateCategory,
    headerText,
    bodyText,
    footerText,
    buttons,
    file,
  ]);

  return validationErrors.length > 0 ? (
    <Alert
      borderRadius="xl"
      status="warning"
      variant="subtle"
      flexDirection="column"
      alignItems="start"
      justifyContent="center"
      textAlign="left"
      w="100%"
      flexShrink={0}
    >
      <HStack spacing={4} w="100%">
        <AlertIcon boxSize="2rem" mr={0} />
        <VStack alignItems="start" w="100%">
          <AlertTitle my={1} fontSize="lg" w="100%">
            Make sure to address the following issues before trying to submit
            your template:
          </AlertTitle>
          <AlertDescription maxWidth="sm" w="100%">
            <UnorderedList w="100%">
              {validationErrors.map((error, index) => (
                <ListItem key={index} w="100%">
                  <Text>{error}</Text>
                </ListItem>
              ))}
            </UnorderedList>
          </AlertDescription>
        </VStack>
      </HStack>
    </Alert>
  ) : null;
};

export default ValidationErrors;
