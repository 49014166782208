import { createSlice, Draft, PayloadAction } from "@reduxjs/toolkit";
import AttributeDomain, {
  AttributeType,
} from "entities/domain/attributes/attribute-domain";

export interface CreateAttributePayload {
  name: string;
  type: AttributeType;
}

export interface UpdateAttributePayload {
  name: string;
}

interface AttributesState {
  loading: boolean;
  errors: any[];
  attributes: AttributeDomain[];
}

const initialState: AttributesState = {
  loading: false,
  errors: [],
  attributes: [],
};

const insertAttribute = (
  attribute: AttributeDomain,
  attributes: Draft<AttributeDomain>[]
): Draft<AttributeDomain>[] => {
  const attributeExists = !!attributes.find((a) => a.id === attribute.id);
  if (attributeExists) {
    return attributes.map((a) => (attribute?.id === a.id ? attribute : a));
  }

  const newAttributes = [...attributes];
  newAttributes.unshift(attribute);
  return newAttributes;
};

const attributesSlice = createSlice({
  name: "attributes",
  initialState,
  reducers: {
    fetchAttributes: (state) => {
      state.loading = true;
    },
    fetchAttributesSuccess: (
      state,
      action: PayloadAction<AttributeDomain[]>
    ) => {
      state.loading = false;
      action.payload.forEach((newAttribute) => {
        state.attributes = insertAttribute(newAttribute, state.attributes);
      });
      state.errors = [];
    },
    createAttributeSuccess: (state, action: PayloadAction<AttributeDomain>) => {
      state.loading = false;
      state.attributes = insertAttribute(action.payload, state.attributes);
      state.errors = [];
    },
    updateAttributeSuccess: (state, action: PayloadAction<AttributeDomain>) => {
      state.loading = false;
      state.attributes = insertAttribute(action.payload, state.attributes);
      state.errors = [];
    },
  },
});

export const {
  fetchAttributes,
  fetchAttributesSuccess,
  createAttributeSuccess,
  updateAttributeSuccess,
} = attributesSlice.actions;

export default attributesSlice.reducer;
