import { TrackAnalyticsPayload } from "hooks/use-analytics";

function playNotificationSound() {
  const audio = new Audio(
    "https://fuzey-inbox-attachments-dev.s3.eu-west-1.amazonaws.com/mixkit-correct-answer-tone-2870.wav"
  );
  audio.play().catch((error) => {
    console.error("Failed to play notification sound:", error);
  });
}

function openNewTab(url: string): void {
  const newTab = window.open(url, "_blank");

  if (newTab) {
    newTab.focus(); // Focus on the new tab
  } else {
    console.error("Failed to open a new tab.");
  }
}

export const sendBrowserNotification = (
  title: string,
  message: string,
  redirectUrl: string
) => {
  if (canSendBrowserNotification()) {
    const now = Date.now();
    const lastSent = localStorage.getItem("lastNotificationTime");
    const throttleDuration = 5000; // 5s

    if (lastSent && now - parseInt(lastSent, 10) < throttleDuration) {
      return;
    }

    localStorage.setItem("lastNotificationTime", now.toString());

    let fuzeyLogo = "/fuzey-target.svg";

    if (
      window.matchMedia &&
      window.matchMedia("(prefers-color-scheme: dark)").matches
    ) {
      fuzeyLogo = "/fuzey-target-light.svg";
    }

    const notification = new Notification(title, {
      icon: fuzeyLogo,
      body: message,
    });

    // comment it out for now until we find a better less annoying sound
    // playNotificationSound();

    notification.onclick = () => {
      openNewTab(redirectUrl);
      notification.close();
    };
  }
};

export function askForNotificationsPermission(
  track: (eventName: string, payload?: TrackAnalyticsPayload) => void
) {
  if (!("Notification" in window)) {
    // eslint-disable-next-line
    console.error("This browser doesn't support notifications");

    return;
  }

  if (
    Notification.permission !== "denied" &&
    Notification.permission !== "granted"
  ) {
    track("request_notification_permission");

    Notification.requestPermission();
  }
}

export function canSendBrowserNotification() {
  return (
    "Notification" in window &&
    Notification.permission === "granted" &&
    document.hidden
  );
}

function isBadgingSupported() {
  return "setAppBadge" in navigator || "clearAppBadge" in navigator;
}

export function updateBadge(unreadCount: number) {
  if (isBadgingSupported()) {
    if (unreadCount > 0) {
      navigator.setAppBadge(unreadCount).catch((error) => {
        console.error("Failed to set app badge:", error);
      });
    } else {
      navigator.clearAppBadge().catch((error) => {
        console.error("Failed to clear app badge:", error);
      });
    }
  }
}
