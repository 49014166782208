import { Media } from "components/shared/Gallery";
import FileDomain from "entities/domain/file";
import { FileDTO } from "entities/dto/FileDTO";

export const fileTransformFromDtoToDomain = (c: FileDTO): FileDomain =>
  new FileDomain(
    c.id,
    c.name,
    c.url,
    c.content_type,
    c.reusable,
    c.short_url,
    c.created_at,
    c.merchant_id
  );

export const transformFileIntoMedia = (c: FileDomain): Media => ({
  id: c.id,
  type: c.type,
  url: c.url,
  // element?: React.ReactNode;
  // smallElement?: React.ReactNode;
});
