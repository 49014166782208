import { HamburgerIcon, QuestionOutlineIcon } from "@chakra-ui/icons";
import {
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  useColorMode,
} from "@chakra-ui/react";
import { ReactComponent as AccountSwitchIcon } from "assets/icons/account-switch-outline.svg";
import { ReactComponent as WeatherSunny } from "assets/icons/white-balance-sunny.svg";
import { ReactComponent as WeatherNight } from "assets/icons/weather-night.svg";
import { ReactComponent as Brush } from "assets/icons/brush.svg";
import React, { memo, useEffect, useState } from "react";
import {
  canManageCampaigns,
  canViewAgentsAnalytics,
  canViewPayments,
} from "util/permissions";
import { isLocalEnvironment, isMobileApp } from "util/methods";
import { FiLogOut } from "react-icons/fi";
import { Auth0ContextInterface, useAuth0, User } from "@auth0/auth0-react";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { setColorScheme } from "redux/features/theme";
import { setIsSelectorOpened } from "redux/features/merchant";
import {
  AnalyticsLink,
  CalendarLink,
  CampaignsLink,
  ContactsLink,
  InboxLink,
  PaymentsLink,
  ReviewsLink,
  SettingsLink,
} from "./MobileNavButton";

const MobileBrowserNavigation = () => {
  const { isAuthenticated, logout } = useAuth0() as Auth0ContextInterface<User>;
  const { currentAgent } = useAppSelector((state) => state.agents);
  const { merchant } = useAppSelector((state) => state.merchant);
  const { colorScheme } = useAppSelector((state) => state.theme);
  const { colorMode, toggleColorMode } = useColorMode();
  const dispatch = useAppDispatch();

  const [selectedLinkColor, setSelectedLinkColor] = useState<string>("inherit");

  useEffect(() => {
    if (colorMode === "light") {
      setSelectedLinkColor(`${colorScheme}.500`);
    } else {
      setSelectedLinkColor(`${colorScheme}.200`);
    }
  }, [colorScheme, colorMode]);

  const toggleColorScheme = (currentColorScheme: string) => {
    const colorSchemes = [
      "blue",
      "yellow",
      "green",
      "purple",
      "pink",
      "cyan",
      "teal",
    ];
    const currentColor = colorSchemes.indexOf(currentColorScheme);

    dispatch(setColorScheme(colorSchemes[currentColor + 1] || colorSchemes[0]));
  };

  const hasMultipleMerchants = currentAgent!.merchants.length > 1;

  return (
    <>
      <Menu
        closeOnBlur={true}
        autoSelect={false}
        flip={false}
        strategy="absolute"
      >
        <MenuButton
          as={IconButton}
          aria-label="Navigate"
          icon={<HamburgerIcon />}
          variant="outline"
        />
        <MenuList
          opacity={1}
          zIndex={6}
          sx={{
            ".selected > button": {
              bgColor: selectedLinkColor,
              color: "white",
            },
          }}
        >
          <InboxLink />
          <ContactsLink />
          <CalendarLink />
          {!canManageCampaigns(merchant.id, currentAgent!) ? null : (
            <CampaignsLink />
          )}
          {!canViewPayments(merchant.id, currentAgent!) ? null : (
            <PaymentsLink />
          )}
          <ReviewsLink />
          {canViewAgentsAnalytics(merchant.id, currentAgent!) ? (
            <AnalyticsLink />
          ) : null}
          <SettingsLink />
          <MenuDivider />
          <MenuItem
            icon={
              <Icon
                width="2rem"
                height="2rem"
                as={QuestionOutlineIcon}
                __css={{
                  g: {
                    stroke: colorMode === "dark" ? "white" : "gray.300",
                  },
                }}
              />
            }
            onClick={() => {
              const faqUrl = "https://www.getfuzey.com/resources/faq";

              if (isMobileApp()) {
                window.natively.openExternalURL(faqUrl);
              } else {
                window.open(faqUrl, "_blank");
              }
            }}
          >
            FAQ
          </MenuItem>
          {!isLocalEnvironment() ? null : (
            <>
              <MenuItem
                onClick={toggleColorMode}
                icon={
                  <Icon
                    as={colorMode === "dark" ? WeatherNight : WeatherSunny}
                    width="2rem"
                    height="2rem"
                    __css={{
                      path: {
                        fill: colorMode === "dark" ? "white" : "gray.300",
                      },
                      rect: {
                        fill: "transparent",
                      },
                    }}
                  />
                }
              >
                Toggle Color Mode
              </MenuItem>
              <MenuItem
                icon={
                  <Icon
                    as={Brush}
                    width="2rem"
                    height="2rem"
                    __css={{
                      path: {
                        fill: colorMode === "dark" ? "white" : "gray.300",
                      },
                      rect: {
                        fill: "transparent",
                      },
                    }}
                  />
                }
                onClick={() => toggleColorScheme(colorScheme)}
              >
                Toggle Color Scheme
              </MenuItem>
            </>
          )}
          {hasMultipleMerchants ? (
            <MenuItem
              icon={
                <Icon
                  width="1.5rem"
                  height="1.5rem"
                  as={AccountSwitchIcon}
                  fill={colorMode === "dark" ? "white" : "gray.300"}
                />
              }
              onClick={() => {
                dispatch(setIsSelectorOpened(true));
              }}
            >
              Switch Account
            </MenuItem>
          ) : null}
          {isAuthenticated ? (
            <MenuItem
              icon={
                <Icon
                  as={FiLogOut}
                  width="2rem"
                  height="2rem"
                  color={colorMode === "dark" ? "white" : "gray.300"}
                  __css={{
                    path: {
                      fill: "transparent",
                    },
                  }}
                />
              }
              onClick={() => {
                logout({
                  logoutParams: {
                    returnTo: window.location.origin,
                  },
                });
              }}
            >
              Log out
            </MenuItem>
          ) : null}
        </MenuList>
      </Menu>
    </>
  );
};

export default memo(MobileBrowserNavigation);
