import { useAuth0 } from "@auth0/auth0-react";
import React from "react";
import { ReactSVG } from "react-svg";
import { Icon as ChakraIcon, IconButton } from "@chakra-ui/react";
import { Color } from "theme/old-design-system/styled-components";
import InboxService from "services/inbox";
import MessageDomain, {
  AttachmentType,
} from "entities/domain/conversations/message-domain";
import { useAppSelector } from "redux/hooks";

export default ({
  setBrokenMediaType,
  setIsLoading,
  isLoading,
  message,
}: {
  setBrokenMediaType: (type: AttachmentType | undefined) => void;
  setIsLoading: (loading: boolean) => void;
  isLoading: boolean;
  message: MessageDomain;
}) => {
  const auth0Context = useAuth0();
  const { colorScheme } = useAppSelector((state) => state.theme);

  const downloadImage = (messageId: number) => {
    InboxService.getFacebookImage(auth0Context, messageId).then((res) => {
      setBrokenMediaType(undefined);
      setIsLoading(false);
    });
  };

  return (
    <IconButton
      aria-label="download media"
      variant="unstyled"
      onClick={() => {
        setIsLoading(true);
        downloadImage(message.id);
      }}
      display="flex"
      isLoading={isLoading}
      m="auto"
    >
      <ChakraIcon
        as={ReactSVG}
        src="/download.svg"
        fontSize={20}
        _hover={{ fill: Color.SMOKY_GREY.value }}
        fill={`${colorScheme}.400`}
      />
    </IconButton>
  );
};
