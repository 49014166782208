import { useEffect } from "react";
import { COMMAND_PRIORITY_LOW, BLUR_COMMAND, $getRoot } from "lexical";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { $convertToMarkdownString, TRANSFORMERS } from "@lexical/markdown";

export default function OnBlurPlugin({
  isRichText = false,
  onBlur,
}: {
  isRichText?: boolean;
  onBlur?: (latestText: string) => void;
}) {
  const [lexicalEditor] = useLexicalComposerContext();

  useEffect(() => {
    if (typeof onBlur === "undefined") {
      return;
    }

    lexicalEditor.registerCommand(
      BLUR_COMMAND,
      (event: FocusEvent) => {
        const currentlyFocused = event.relatedTarget as HTMLElement;

        const isEditorRelated =
          currentlyFocused &&
          (currentlyFocused.id === "editor-ultra-toolbar" ||
            !!currentlyFocused.closest("#editor-ultra-toolbar"));

        if (isEditorRelated) {
          return false;
        }

        const root = $getRoot();

        if (isRichText) {
          onBlur($convertToMarkdownString(TRANSFORMERS));
        } else {
          onBlur(root.getTextContent());
        }

        return true;
      },
      COMMAND_PRIORITY_LOW
    );
  }, [lexicalEditor, isRichText]);

  return null;
}
