import {
  Flex,
  Heading,
  Icon,
  Image,
  Text,
  VStack,
  useBreakpointValue,
  useColorMode,
} from "@chakra-ui/react";
import VehicleDomain from "entities/domain/vehicle";
import React, { useCallback, useState } from "react";
import { ReactSVG } from "react-svg";

const VehicleInfo = ({ vehicle }: { vehicle: VehicleDomain }) => {
  const { colorMode } = useColorMode();
  const isBaseSize = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );
  const [isResolved, setIsResolved] = useState<boolean>(true);

  const vehicleIconFallback = useCallback(
    () => (
      <Image
        src="/car.png"
        borderTopRadius="xl"
        loading="lazy"
        objectFit="cover"
        alt="Abstract Lead Image"
        filter="grayscale(100%) opacity(0.2)"
      />
    ),
    []
  );

  return (
    <Flex
      mx="auto"
      my={2}
      borderRadius="xl"
      direction="column"
      gridGap={4}
      boxSize={isBaseSize ? "xs" : "md"}
      height="fit-content"
      bgColor={colorMode === "dark" ? "gray.900" : "white"}
      pb={8}
    >
      <Flex
        borderTopRadius="xl"
        bgColor={colorMode === "dark" ? "gray.700" : "gray.200"}
        p={4}
        justifyContent="center"
        alignItems="center"
      >
        {vehicle.getIconSource() ? (
          <VStack
            spacing={0}
            width="100%"
            height="100%"
            alignItems="center"
            justifyContent="center"
          >
            <Icon
              as={ReactSVG}
              src={vehicle.getIconSource() || ""}
              boxSize={isResolved ? 32 : "auto"}
              __css={{
                svg: {
                  width: 32,
                  height: 32,
                },
                path: {
                  fill: colorMode === "dark" ? "gray.50" : "gray.800",
                },
              }}
              fallback={vehicleIconFallback}
            />
          </VStack>
        ) : (
          vehicleIconFallback()
        )}
      </Flex>
      <Heading size="md" fontWeight="bold" textAlign="center">
        {vehicle.description}
      </Heading>
      <Heading size="sm" fontWeight="hairline" textAlign="center">
        {vehicle.variant}
      </Heading>
      <VStack spacing={4} alignItems="flex-start" px={8} mt={4}>
        {vehicle.vin && (
          <Flex alignItems="center" justifyContent="space-between" w="100%">
            <Text fontWeight="bold">VIN</Text>
            <Text>{vehicle.vin}</Text>
          </Flex>
        )}
        {vehicle.make && (
          <Flex alignItems="center" justifyContent="space-between" w="100%">
            <Text fontWeight="bold">Make</Text>
            <Text>{vehicle.make}</Text>
          </Flex>
        )}
        {vehicle.model && (
          <Flex alignItems="center" justifyContent="space-between" w="100%">
            <Text fontWeight="bold">Model</Text>
            <Text>{vehicle.model}</Text>
          </Flex>
        )}
        {vehicle.vehicleClass && (
          <Flex alignItems="center" justifyContent="space-between" w="100%">
            <Text fontWeight="bold">Class</Text>
            <Text>{vehicle.vehicleClass.toLocaleLowerCase()}</Text>
          </Flex>
        )}
        {vehicle.year && (
          <Flex alignItems="center" justifyContent="space-between" w="100%">
            <Text fontWeight="bold">Year</Text>
            <Text>{vehicle.year}</Text>
          </Flex>
        )}
        {vehicle.licensePlate && (
          <Flex alignItems="center" justifyContent="space-between" w="100%">
            <Text fontWeight="bold">License Plate</Text>
            <Text>{vehicle.licensePlate}</Text>
          </Flex>
        )}
        {vehicle.exteriorColor && (
          <Flex alignItems="center" justifyContent="space-between" w="100%">
            <Text fontWeight="bold">Exterior Color</Text>
            <Text>{vehicle.exteriorColor}</Text>
          </Flex>
        )}
        {vehicle.interiorColor && (
          <Flex alignItems="center" justifyContent="space-between" w="100%">
            <Text fontWeight="bold">Interior Color</Text>
            <Text>{vehicle.interiorColor}</Text>
          </Flex>
        )}
        {vehicle.registration && (
          <>
            {vehicle.registration.registrationDate && (
              <Flex alignItems="center" justifyContent="space-between" w="100%">
                <Text fontWeight="bold">Registration Date</Text>
                <Text>{vehicle.registration.registrationDate}</Text>
              </Flex>
            )}
            {vehicle.registration.firstRegistrationDate && (
              <Flex alignItems="center" justifyContent="space-between" w="100%">
                <Text fontWeight="bold">First Registration Date</Text>
                <Text>{vehicle.registration.firstRegistrationDate}</Text>
              </Flex>
            )}
          </>
        )}
        {vehicle.govInspection && (
          <>
            {vehicle.govInspection.dueDate && (
              <Flex alignItems="center" justifyContent="space-between" w="100%">
                <Text fontWeight="bold">Inspection Due Date</Text>
                <Text>{vehicle.govInspection.dueDate}</Text>
              </Flex>
            )}
            {vehicle.govInspection.lastDate && (
              <Flex alignItems="center" justifyContent="space-between" w="100%">
                <Text fontWeight="bold">Last Inspection Date</Text>
                <Text>{vehicle.govInspection.lastDate}</Text>
              </Flex>
            )}
          </>
        )}
        {vehicle.branches.length > 0 ? (
          <Flex alignItems="center" justifyContent="space-between" w="100%">
            <Text fontWeight="bold">Branches</Text>
            <Text>
              {vehicle.branches
                .filter((b) => b.department)
                .map((b) => b.department)
                .join(", ")}
            </Text>
          </Flex>
        ) : null}
      </VStack>
    </Flex>
  );
};

export default VehicleInfo;
