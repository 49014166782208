import { Icon, IconButton, useColorMode } from "@chakra-ui/react";
import React from "react";
import { ReactComponent as PaperPlaneButtonIcon } from "assets/icons/paper-plane-top-regular.svg";
import { useAppSelector } from "redux/hooks";

interface SendButtonProps {
  send: () => void;
  isDisabled: boolean;
  isVisible?: boolean;
}

const SendButton = ({
  send,
  isDisabled,
  isVisible = true,
}: SendButtonProps) => {
  const { colorScheme } = useAppSelector((state) => state.theme);
  const { colorMode } = useColorMode();

  const disabledIconColor =
    colorMode === "dark" ? `${colorScheme}.700` : "gray.400";
  const activeIconColor =
    colorMode === "dark" ? `${colorScheme}.200` : `${colorScheme}.400`;

  const sendIcon = (
    <Icon
      as={PaperPlaneButtonIcon}
      __css={{
        path: {
          fill: isDisabled ? disabledIconColor : activeIconColor,
        },
        height: "1rem",
        width: "1rem",
      }}
    />
  );

  return (
    <IconButton
      _focus={{ outline: "none" }}
      colorScheme={colorScheme}
      aria-label="Send message"
      variant="unstyled"
      zIndex={6}
      opacity={isVisible ? 1 : 0}
      display="flex"
      alignItems="end"
      mb={1}
      minWidth={0}
      onClick={send}
      isDisabled={isDisabled}
    >
      {sendIcon}
    </IconButton>
  );
};

export default SendButton;
