export type ButtonColourScheme =
  | "whiteAlpha"
  | "blackAlpha"
  | "gray"
  | "red"
  | "orange"
  | "yellow"
  | "green"
  | "teal"
  | "blue"
  | "cyan"
  | "purple"
  | "pink"
  | "linkedin"
  | "facebook"
  | "messenger"
  | "whatsapp"
  | "twitter"
  | "telegram";

export const BUTTON_COLOUR_SCHEME_VALUES: ButtonColourScheme[] = [
  "whiteAlpha",
  "blackAlpha",
  "gray",
  "red",
  "orange",
  "yellow",
  "green",
  "teal",
  "blue",
  "cyan",
  "purple",
  "pink",
  "linkedin",
  "facebook",
  "messenger",
  "whatsapp",
  "twitter",
  "telegram",
];

export type ContactFormDomain = {
  bgColour?: string;
  fontColour?: string;
  borderColour?: string;
  title?: string;
  subtitle?: string;
  buttonColourScheme?: ButtonColourScheme;
};
