import React from "react";
import FormGroup from "../form-group";
import AssignChannelForm from "../forms/assign-channel";
import BankDetails from "../forms/bank-details";
import ContactFormConfig from "../forms/contact-form-config";
import AddMerchantForm from "../forms/merchant-form";
import ResyncPermissions from "../forms/resync-permissions";
import BusinessDescription from "../forms/business-description";
import UpdateMerchantForm from "../forms/update-merchant-form";
import WidgetConfig from "../forms/widget-config";
import UpdateMerchantTier from "../forms/update-merchant-tier";
import AddKeyloopAccount from "../forms/add-keyloop-account";
import UpdateKeyloopAccount from "../forms/update-keyloop-account";

const Merchants = () => {
  return (
    <>
      <FormGroup title=" Add Merchant">
        <AddMerchantForm />
      </FormGroup>

      <FormGroup title="Update Merchant Information">
        <UpdateMerchantForm />
      </FormGroup>
      <FormGroup title="Assign Channel">
        <AssignChannelForm />
      </FormGroup>
      <FormGroup title="Add Merchant's Bank Information">
        <BankDetails />
      </FormGroup>
      <FormGroup title="Update Merchant's Business Description">
        <BusinessDescription />
      </FormGroup>
      <FormGroup title="Update Merchant's Tier">
        <UpdateMerchantTier />
      </FormGroup>
      <FormGroup title="Widget Config">
        <WidgetConfig />
      </FormGroup>
      <FormGroup title="Contact Form Config">
        <ContactFormConfig />
      </FormGroup>
      <FormGroup title="Resync Permissions">
        <ResyncPermissions />
      </FormGroup>
      <FormGroup title="Add Keyloop Account">
        <AddKeyloopAccount />
      </FormGroup>
      <FormGroup title="Update Keyloop Account">
        <UpdateKeyloopAccount />
      </FormGroup>
    </>
  );
};

export default Merchants;
