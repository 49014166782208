import ChannelDomain from "entities/domain/contacts/contact-domain";
import MerchantUserGuidesDomain from "entities/domain/admin/merchants/merchant-user-guides";
import ExternalMetadataDomain from "./external-metadata-domain";
import { IntegrationName, MerchantIntegrations } from "./merchant-integrations";
import MerchantLocation from "./merchant-location-domain";
import MerchantTierLimitsDomain from "./merchant-tier-limits-domain";

export enum UserGuides {
  ONBOARDING = "onboarding",
}

export enum TierType {
  STARTER = "starter",
  GROWTH = "growth",
  PROFESSIONAL = "professional",
  UNLIMITED = "unlimited",
}

export enum StrictnessType {
  SOFT = "soft",
  HARD = "hard",
}

export default class MerchantDomainBase {
  id: number;

  groupId: string;

  public_id: string;

  name: string;

  registeredCompanyId: string;

  subaccountSid: string;

  externalMetadata: ExternalMetadataDomain;

  channels: ChannelDomain[];

  hasStripeAccount: boolean;

  hasInvoiceAccount: boolean;

  hasOpenBanking: boolean;

  hasReviewsEnabled: boolean;

  location: MerchantLocation | undefined;

  integrations: MerchantIntegrations;

  country: string;

  userGuides: MerchantUserGuidesDomain;

  businessDescription: string | null;

  tier: TierType | null;

  strictness: StrictnessType | null;

  limits: MerchantTierLimitsDomain | null;

  selectedKeyloopPrivacyStatementId?: string | null;

  hasMoreThanOneReviewIntegration(): boolean {
    return (
      this.integrations[IntegrationName.GOOGLE_REVIEWS].connected &&
      this.integrations[IntegrationName.FACEBOOK_REVIEWS].connected
    );
  }

  isPaymentEnabled(): boolean {
    return this.hasStripeAccount || this.hasOpenBanking;
  }

  getMerchantLocation(): string | undefined {
    return this.location?.countryIso2;
  }

  displayMerchantLocation(): string | undefined {
    return this.location?.displayName;
  }

  getPhoneNumber(): string | undefined {
    return this.channels.find(
      (chan) => chan.channelName === "sms" || chan.channelName === "whatsapp"
    )?.identifier;
  }

  getCurrency(): string | undefined {
    return this.location?.currencyIso3;
  }

  getCurrencySymbol(): string | undefined {
    return this.location?.currencySymbol;
  }

  isMerchantChannelEnabled(chan: string): boolean {
    const channels = this.channels.map((channel) => channel.channelName);
    return channels.includes(chan);
  }

  isIntegrationEnabled(name: IntegrationName): boolean {
    if (name === IntegrationName.FACEBOOK_REVIEWS) {
      // sorry about this :shrug: release pressing matter. Better solution has to be sought here.
      // conversation has the channel type as "facebook" but integration is defined as "messenger" thus the mismatch
      // eslint-disable-next-line no-param-reassign
      name = IntegrationName.MESSENGER;
    }
    return this.integrations[name].connected;
  }

  isWhatsappInfoEnabled(): boolean {
    return this.integrations[IntegrationName.WHATSAPP].accountInfoEnabled;
  }

  constructor(
    id: number,
    groupId: string,
    public_id: string,
    name: string,
    registeredCompanyId: string,
    subaccountSid: string,
    externalMetadata: ExternalMetadataDomain,
    channels: ChannelDomain[],
    hasStripeAccount: boolean,
    hasInvoiceAccount: boolean,
    hasOpenBanking: boolean,
    hasReviewsEnabled: boolean,
    integrations: MerchantIntegrations,
    country: string,
    userGuides: MerchantUserGuidesDomain,
    businessDescription: string | null,
    tier: TierType | null,
    strictness: StrictnessType | null,
    limits: MerchantTierLimitsDomain | null,
    selectedKeyloopPrivacyStatementId?: string | null
  ) {
    this.id = id;
    this.groupId = groupId;
    this.public_id = public_id;
    this.name = name;
    this.registeredCompanyId = registeredCompanyId;
    this.subaccountSid = subaccountSid;
    this.externalMetadata = externalMetadata;
    this.channels = channels;
    this.hasStripeAccount = hasStripeAccount;
    this.hasInvoiceAccount = hasInvoiceAccount;
    this.hasOpenBanking = hasOpenBanking;
    this.hasReviewsEnabled = hasReviewsEnabled;
    this.integrations = integrations;
    this.country = country;
    this.userGuides = userGuides;
    this.businessDescription = businessDescription;
    this.tier = tier;
    this.strictness = strictness;
    this.limits = limits;
    if (this.getPhoneNumber()) {
      this.location = MerchantLocation.from(country);
    } else {
      this.location = undefined;
    }
    this.selectedKeyloopPrivacyStatementId = selectedKeyloopPrivacyStatementId;
  }
}
