import { Auth0ContextInterface } from "@auth0/auth0-react/src/auth0-context";
import {
  CreateCampaignPayload,
  DeleteCampaignPayload,
  EditCampaignPayload,
  GetCampaignCustomersPayload,
} from "redux/features/campaigns";
import CampaignDomain from "entities/domain/campaign";
import StatisticsDomain from "entities/domain/campaign/statistics";
import ContactListDomain from "entities/domain/customers/contact-list-domain";
import { CampaignStatisticsDTO } from "entities/dto/CampaignStatisticsDTO";
import { ContactDTO } from "entities/dto/ContactDTO";
import {
  campaignStatisticsTransformFromDtoToDomain,
  campaignTransformFromDtoToDomain,
} from "entities/transformers/campaignTransformer";
import { contactListTransformFromDtoToDomain } from "entities/transformers/contact-transformer";
import { numberOfCampaignsPerLoad } from "util/constants";
import { CampaignDTO } from "../entities/dto/CampaignDTO";
import {
  deleteRequest,
  mutationRequest,
  putRequest,
  request,
} from "../util/methods";
import { RequestType } from "./request-type";

class CampaignsService {
  public static async getCampaignById(
    { getAccessTokenSilently }: Auth0ContextInterface,
    id: string,
    merchantId: number
  ): Promise<CampaignDomain> {
    const accessToken = await getAccessTokenSilently();

    const campaignResponse = (
      await request<CampaignDTO>(
        RequestType.GET,
        accessToken,
        `/merchants/${merchantId}/campaigns/${id}`
      )
    ).data;

    return campaignTransformFromDtoToDomain(campaignResponse);
  }

  public static async getCampaigns(
    { getAccessTokenSilently }: Auth0ContextInterface,
    direction: string = "desc",
    searchText: string = "",
    statuses: string[] = [],
    offset: number = 0,
    merchantId: number
  ): Promise<CampaignDomain[]> {
    const accessToken = await getAccessTokenSilently();
    const queryParams = new URLSearchParams();

    queryParams.append("offset", `${offset}`);
    queryParams.append("maxPageSize", `${numberOfCampaignsPerLoad}`);
    queryParams.append("sorting_direction", direction);

    statuses.forEach((s) => {
      queryParams.append("statuses", s);
    });

    if (searchText) {
      queryParams.append("name", searchText);
    }

    const queryString = queryParams.toString();

    const campaignsResponse = (
      await request<CampaignDTO[]>(
        RequestType.GET,
        accessToken,
        `/merchants/${merchantId}/campaigns?${queryString}`
      )
    ).data;

    return campaignsResponse.map((t) => campaignTransformFromDtoToDomain(t));
  }

  public static async getStatistics(
    { getAccessTokenSilently }: Auth0ContextInterface,
    merchantId: number
  ): Promise<StatisticsDomain[]> {
    const accessToken = await getAccessTokenSilently();

    const campaignsStatisticsResponse = (
      await request<CampaignStatisticsDTO[]>(
        RequestType.GET,
        accessToken,
        `/merchants/${merchantId}/campaigns/statistics`
      )
    ).data;

    return campaignsStatisticsResponse.map((stat) =>
      campaignStatisticsTransformFromDtoToDomain(stat)
    );
  }

  public static async createCampaign(
    { getAccessTokenSilently }: Auth0ContextInterface,
    createCampaign: CreateCampaignPayload,
    merchantId: number
  ): Promise<CampaignDomain> {
    const accessToken = await getAccessTokenSilently();

    const {
      name,
      merchantId: merchant_id,
      status,
      audienceId,
      templateName,
      messageBody,
      sentAt,
      scheduledAt,
      scheduledFor,
      createdAt,
      updatedAt,
      channel,
      idempotencyKey,
      file,
      mediaType,
      mediaUrl,
    } = createCampaign;

    const formData = new FormData();

    formData.append("name", name);
    formData.append("merchant_id", `${merchant_id}`);
    formData.append("status", status);
    formData.append("audience_id", audienceId);
    if (templateName) {
      formData.append("template_name", templateName);
    }
    if (messageBody) {
      formData.append("message_body", messageBody);
    }
    if (sentAt) {
      formData.append("sent_at", sentAt);
    }
    if (scheduledAt) {
      formData.append("scheduled_at", scheduledAt);
    }
    if (scheduledFor) {
      formData.append("scheduled_for", scheduledFor);
    }
    if (createdAt) {
      formData.append("created_at", createdAt);
    }
    if (updatedAt) {
      formData.append("updated_at", updatedAt);
    }
    if (file) {
      formData.append("file", file);
    }
    if (mediaType) {
      formData.append("media_type", mediaType);
    }
    if (mediaUrl) {
      formData.append("media_url", mediaUrl);
    }
    formData.append("channel", channel);

    const campaign = (
      await mutationRequest(
        RequestType.POST,
        accessToken,
        `/merchants/${merchantId}/campaigns`,
        formData,
        "multipart/form-data",
        {
          "Idempotency-Key": idempotencyKey,
        }
      )
    ).data;

    return campaignTransformFromDtoToDomain(campaign);
  }

  public static async editCampaign(
    { getAccessTokenSilently }: Auth0ContextInterface,
    payload: EditCampaignPayload,
    merchantId: number
  ): Promise<CampaignDomain> {
    const accessToken = await getAccessTokenSilently();
    const {
      id,
      name,
      merchantId: merchant_id,
      status,
      audienceId,
      templateName,
      messageBody,
      sentAt,
      scheduledAt,
      scheduledFor,
      createdAt,
      updatedAt,
      channel,
      mediaType,
      mediaUrl,
      file,
    } = payload;

    const formData = new FormData();

    formData.append("name", name);
    formData.append("merchant_id", `${merchant_id}`);
    formData.append("status", status);
    formData.append("audience_id", audienceId);
    if (templateName) {
      formData.append("template_name", templateName);
    }
    if (messageBody) {
      formData.append("message_body", messageBody);
    }
    if (sentAt) {
      formData.append("sent_at", sentAt);
    }
    if (scheduledAt) {
      formData.append("scheduled_at", scheduledAt);
    }
    if (scheduledFor) {
      formData.append("scheduled_for", scheduledFor);
    }
    if (createdAt) {
      formData.append("created_at", createdAt);
    }
    if (updatedAt) {
      formData.append("updated_at", updatedAt);
    }
    if (mediaType && !file) {
      formData.append("media_type", mediaType);
    }
    if (mediaUrl && !file) {
      formData.append("media_url", mediaUrl);
    }
    if (file) {
      formData.append("file", file);
    }
    formData.append("channel", channel);

    const campaignResponse = await mutationRequest<CampaignDTO>(
      RequestType.PUT,
      accessToken,
      `/merchants/${merchantId}/campaigns/${encodeURIComponent(id)}`,
      formData,
      "multipart/form-data"
    );

    return campaignTransformFromDtoToDomain(campaignResponse.data);
  }

  public static async deleteCampaign(
    { getAccessTokenSilently }: Auth0ContextInterface,
    payload: DeleteCampaignPayload,
    merchantId: number
  ): Promise<string> {
    const accessToken = await getAccessTokenSilently();
    const { id } = payload;

    await deleteRequest(
      RequestType.DELETE,
      accessToken,
      `/merchants/${merchantId}/campaigns/${id}`
    );

    return id;
  }

  public static async pauseCampaign(
    { getAccessTokenSilently }: Auth0ContextInterface,
    payload: DeleteCampaignPayload,
    merchantId: number
  ): Promise<string> {
    const accessToken = await getAccessTokenSilently();
    const { id } = payload;

    await putRequest(
      RequestType.PUT,
      accessToken,
      `/merchants/${merchantId}/campaigns/${id}/pause`
    );

    return id;
  }

  public static async cancelCampaign(
    { getAccessTokenSilently }: Auth0ContextInterface,
    payload: DeleteCampaignPayload,
    merchantId: number
  ): Promise<string> {
    const accessToken = await getAccessTokenSilently();
    const { id } = payload;

    await putRequest(
      RequestType.PUT,
      accessToken,
      `/merchants/${merchantId}/campaigns/${id}/cancel`
    );

    return id;
  }

  public static async resumeCampaign(
    { getAccessTokenSilently }: Auth0ContextInterface,
    payload: DeleteCampaignPayload,
    merchantId: number
  ): Promise<string> {
    const accessToken = await getAccessTokenSilently();
    const { id } = payload;

    await putRequest(
      RequestType.PUT,
      accessToken,
      `/merchants/${merchantId}/campaigns/${id}/resume`
    );

    return id;
  }

  public static async getCampaignCustomers(
    { getAccessTokenSilently }: Auth0ContextInterface,
    payload: GetCampaignCustomersPayload,
    merchantId: number
  ): Promise<ContactListDomain> {
    const accessToken = await getAccessTokenSilently();
    const { id } = payload;

    const response = await request<ContactDTO[]>(
      RequestType.GET,
      accessToken,
      `/merchants/${merchantId}/campaigns/${id}/customers`
    );
    const contacts = response.data;
    const totalCount = response.headers["total-count"];

    return contactListTransformFromDtoToDomain(contacts, totalCount);
  }
}

export default CampaignsService;
