import {
  Box,
  Flex,
  Heading,
  Icon,
  Image,
  Text,
  useBreakpointValue,
  useColorMode,
} from "@chakra-ui/react";
import MessageDomain from "entities/domain/conversations/message-domain";
import { ReactComponent as Counter } from "assets/icons/counter.svg";
import { ReactComponent as Brush } from "assets/icons/brush.svg";
import React from "react";
import MessageAttributes from "./MessageAttributes";

interface VehicleLeadProps {
  message: MessageDomain;
}

interface VehicleLeadDetails {
  make: string;
  model: string;
  derivative: string;
  vin: string;
  vrm: string;
  type: string;
  bodyType: string;
  mileage: number;
  colour: string;
  yearOfManufacture: number;
  doors: number;
  transmission: string;
  engineCapacity: string;
  fuelType: string;
  price: string;
}

interface NewVehicleLeadDetails {
  branch: string;
  make: string;
  models: Array<{
    colour: string;
    model: string;
    trim: string;
  }>;
}

function isVehicleLeadDetails(obj: any): obj is VehicleLeadDetails {
  return (
    obj &&
    typeof obj.make === "string" &&
    typeof obj.model === "string" &&
    typeof obj.derivative === "string" &&
    typeof obj.vin === "string" &&
    typeof obj.vrm === "string" &&
    typeof obj.type === "string" &&
    typeof obj.bodyType === "string" &&
    typeof obj.mileage === "number" &&
    typeof obj.colour === "string" &&
    typeof obj.yearOfManufacture === "number" &&
    typeof obj.doors === "number" &&
    typeof obj.transmission === "string" &&
    typeof obj.engineCapacity === "string" &&
    typeof obj.fuelType === "string" &&
    typeof obj.price === "string"
  );
}

function isNewVehicleLeadDetails(obj: any): obj is NewVehicleLeadDetails {
  return (
    obj &&
    typeof obj.branch === "string" &&
    typeof obj.make === "string" &&
    Array.isArray(obj.models) &&
    obj.models.every((model: any) => {
      return (
        typeof model.colour === "string" &&
        typeof model.model === "string" &&
        typeof model.trim === "string"
      );
    })
  );
}

const VehicleLead: React.FC<VehicleLeadProps> = ({ message }) => {
  const isBaseSize = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );
  const { colorMode } = useColorMode();
  const details = message.metadata?.attributes as unknown;

  let vehicleLead;
  let newVehicleLead;

  if (!isVehicleLeadDetails(details) && !isNewVehicleLeadDetails(details)) {
    return (
      <Flex
        mx="auto"
        my={2}
        borderRadius="xl"
        direction="column"
        gridGap={4}
        boxSize={isBaseSize ? "xs" : "md"}
        height="fit-content"
        bgColor={colorMode === "dark" ? "black" : "white"}
        pb={8}
      >
        {message.attachments.length ? (
          <Image
            src={message.attachments[0].url}
            borderTopRadius="xl"
            loading="lazy"
            objectFit="cover"
            alt="Vehicle Lead Image"
            fallbackSrc="/car.png"
          />
        ) : (
          <Flex
            borderTopRadius="xl"
            bgColor={colorMode === "dark" ? "gray.700" : "gray.200"}
            p={4}
            justifyContent="center"
            alignItems="center"
          >
            <Image
              src="/car.png"
              borderTopRadius="xl"
              loading="lazy"
              objectFit="cover"
              alt="Abstract Lead Image"
              filter="grayscale(100%) opacity(0.2)"
            />
          </Flex>
        )}
        <Heading size="md" fontWeight="bold" textAlign="center">
          {message.title}
        </Heading>
        <Heading size="sm" fontWeight="hairline" textAlign="center">
          {message.body}
        </Heading>
        <MessageAttributes
          message={message}
          rejectAttributes={["termsAndConditions", "models"]}
        />
      </Flex>
    );
  }

  if (isVehicleLeadDetails(details)) {
    vehicleLead = details;
  } else {
    newVehicleLead = details;
  }

  return (
    <Flex
      mx="auto"
      my={2}
      borderRadius="xl"
      direction="column"
      gridGap={4}
      boxSize={isBaseSize ? "xs" : "md"}
      height="fit-content"
      bgColor={colorMode === "dark" ? "black" : "white"}
      pb={8}
    >
      {message.attachments.length ? (
        <Image
          src={message.attachments[0].url}
          borderTopRadius="xl"
          loading="lazy"
          objectFit="cover"
          alt="Vehicle Lead Image"
          fallbackSrc="/car.png"
        />
      ) : (
        <Flex
          borderTopRadius="xl"
          bgColor={colorMode === "dark" ? "gray.700" : "gray.200"}
          p={4}
          justifyContent="center"
          alignItems="center"
        >
          <Image
            src="/car.png"
            borderTopRadius="xl"
            loading="lazy"
            objectFit="cover"
            alt="Abstract Lead Image"
            filter="grayscale(100%) opacity(0.2)"
          />
        </Flex>
      )}
      <Heading size="md" fontWeight="bold" textAlign="center">
        {message.title}
      </Heading>
      <Heading size="sm" fontWeight="hairline" textAlign="center">
        {message.body}
      </Heading>
      {vehicleLead ? (
        vehicleLead.price || vehicleLead.colour || vehicleLead.mileage ? (
          <Flex
            gridGap={2}
            justifyContent={
              (vehicleLead.price &&
                !vehicleLead.colour &&
                !vehicleLead.mileage) ||
              (!vehicleLead.price &&
                vehicleLead.colour &&
                !vehicleLead.mileage) ||
              (!vehicleLead.price && !vehicleLead.colour && vehicleLead.mileage)
                ? "center"
                : "space-between"
            }
            px={8}
            my={4}
          >
            {vehicleLead.price ? (
              <Box
                px={4}
                py={2}
                bgColor={colorMode === "dark" ? "green.200" : "green.400"}
                borderRadius="xl"
              >
                <Text color={colorMode === "dark" ? "black" : "white"}>
                  {vehicleLead.price}
                </Text>
              </Box>
            ) : null}
            {vehicleLead.colour ? (
              <Flex
                px={4}
                py={2}
                borderColor={colorMode === "dark" ? "white" : "black"}
                borderWidth="1px"
                borderRadius="xl"
                alignItems="center"
                justifyContent="center"
                gridGap={1}
              >
                <Icon
                  as={Brush}
                  fill={colorMode === "dark" ? "white" : "black"}
                  w="1.5rem"
                  h="1.5rem"
                />
                <Text>{vehicleLead.colour}</Text>
              </Flex>
            ) : null}
            {vehicleLead.mileage ? (
              <Flex
                px={4}
                py={2}
                bgColor={colorMode === "dark" ? "blue.200" : "blue.400"}
                borderRadius="xl"
                alignItems="center"
                justifyContent="center"
                gridGap={1}
              >
                <Icon
                  as={Counter}
                  fill={colorMode === "dark" ? "black" : "white"}
                  w="1.5rem"
                  h="1.5rem"
                />
                <Text color={colorMode === "dark" ? "black" : "white"}>
                  {vehicleLead.mileage}mi
                </Text>
              </Flex>
            ) : null}
          </Flex>
        ) : null
      ) : null}
      {newVehicleLead ? (
        <Flex gridGap={2} justifyContent="space-between" px={8} my={4}>
          {newVehicleLead.branch ? (
            <Box
              px={4}
              py={2}
              bgColor={colorMode === "dark" ? "green.200" : "green.400"}
              borderRadius="xl"
            >
              <Text color={colorMode === "dark" ? "black" : "white"}>
                {newVehicleLead.branch}
              </Text>
            </Box>
          ) : null}
          {newVehicleLead.make ? (
            <Flex
              px={4}
              py={2}
              borderColor={colorMode === "dark" ? "white" : "black"}
              borderWidth="1px"
              borderRadius="xl"
              alignItems="center"
              justifyContent="center"
              gridGap={1}
            >
              <Text>{newVehicleLead.make}</Text>
            </Flex>
          ) : null}
        </Flex>
      ) : null}
      <MessageAttributes
        message={message}
        rejectAttributes={["termsAndConditions", "models"]}
      />
    </Flex>
  );
};

export default VehicleLead;
