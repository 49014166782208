import { useEffect } from "react";
import {
  $getSelection,
  $isRangeSelection,
  COMMAND_PRIORITY_EDITOR,
  PASTE_COMMAND,
} from "lexical";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";

// The idea behind this plugin is to control the pasting of text into the editor.
// RichTextPlugin usually interprets quite well pasted text but it shoots us in the leg
// when doing a more limited "rich text" for Whatsapp and etc.
export default function PastingPlugin(_props: {}) {
  const [lexicalEditor] = useLexicalComposerContext();

  useEffect(() => {
    lexicalEditor.registerCommand<ClipboardEvent>(
      PASTE_COMMAND,
      (event) => {
        const pastedText = event.clipboardData?.getData("text");

        if (pastedText) {
          event.preventDefault();

          lexicalEditor.update(() => {
            const selection = $getSelection();

            if (!$isRangeSelection(selection)) {
              return;
            }

            selection.insertText(pastedText);
          });

          return true;
        }

        return false;
      },
      COMMAND_PRIORITY_EDITOR
    );
  }, [lexicalEditor]);

  return null;
}
