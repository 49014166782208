import { Flex } from "@chakra-ui/react";
import BackIconButton from "components/shared/BackButton";
import Topbar from "components/shared/topbar/TopBar";
import ContactDomain from "entities/domain/customers/contact-domain";
import React from "react";
import { ContactsSorting } from "util/ContactsFilter";
import AdvancedFilterModal from "components/contacts/top-area/AdvancedFilterModal";
import Sorting from "../../Sorting";
import Actions from "./Actions";
import AddNewContact from "../AddNew";

interface MobileTopbarProps {
  prefilledName?: string;
  displayBackIcon: boolean;
  setRecentlyCreatedContact: (contact: ContactDomain | undefined) => void;
  onBackIconClick?: () => void;
  displayMoreIcon: boolean;
  setSorting: (sorting: ContactsSorting | undefined) => void;
  contactToDisplay: ContactDomain | undefined;
  setRecentlyUpdatedContact: (contact: ContactDomain | undefined) => void;
}

const MobileTopbar = ({
  prefilledName,
  setRecentlyCreatedContact,
  setSorting,
  displayBackIcon,
  onBackIconClick,
  displayMoreIcon,
  contactToDisplay,
  setRecentlyUpdatedContact,
}: MobileTopbarProps) => {
  return (
    <Topbar
      showBorder={false}
      title="Contacts"
      leftChildrenMobile={
        displayBackIcon ? (
          <BackIconButton
            onBackIconClick={onBackIconClick}
            displayBackIcon={displayBackIcon}
          />
        ) : (
          <Flex
            gridGap={1}
            alignItems="center"
            justifyContent="start"
            w="100%"
            h="100%"
          >
            <Sorting setSorting={setSorting} />
            <AdvancedFilterModal />
          </Flex>
        )
      }
      rightChildrenMobile={
        displayMoreIcon && contactToDisplay ? (
          <Actions
            contactToDisplay={contactToDisplay}
            setRecentlyUpdatedContact={setRecentlyUpdatedContact}
          />
        ) : (
          <AddNewContact
            prefilledName={prefilledName}
            setRecentlyCreatedContact={setRecentlyCreatedContact}
          />
        )
      }
    />
  );
};

export default MobileTopbar;
