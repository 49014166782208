export default class CustomerAddressDomain {
  firstLine: string;

  secondLine: string;

  city: string;

  country: string;

  state: string | undefined;

  postcode: string;

  constructor(
    firstLine: string,
    secondLine: string,
    city: string,
    country: string,
    state: string | undefined,
    postcode: string
  ) {
    this.firstLine = firstLine;
    this.secondLine = secondLine;
    this.city = city;
    this.country = country;
    this.state = state;
    this.postcode = postcode;
  }

  displayCountryAbbreviateion(): string {
    return this.country === "US" ? "USA" : this.country;
  }
}
