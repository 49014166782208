import {
  Flex,
  Icon,
  IconButton,
  Tooltip,
  useBreakpointValue,
  useColorMode,
} from "@chakra-ui/react";
import { ReactComponent as OpenLockIcon } from "assets/icons/open-lock.svg";
import { ReactComponent as ClosedLockIcon } from "assets/icons/close-lock.svg";
import React from "react";
import {
  OpenClosedFilter,
  setActiveConversationOpenClosedFilter,
} from "redux/features/conversations";
import { useAppDispatch, useAppSelector } from "redux/hooks";

const OpenClosedToggle = () => {
  const isBaseSize = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );
  const { colorMode } = useColorMode();
  const { isOpenOrClosed } = useAppSelector((state) => state.conversations);
  const dispatch = useAppDispatch();

  return (
    <Tooltip
      label={
        isOpenOrClosed === OpenClosedFilter.Open
          ? "Press to show closed conversations only"
          : "Press to show open conversations only"
      }
      borderRadius="1rem"
      px={3}
      py={1}
      backgroundColor="rgba(54, 178, 250, 0.9)"
    >
      <Flex
        _hover={{
          cursor: "pointer",
        }}
        pr={isOpenOrClosed === OpenClosedFilter.Open ? 3 : 0}
        pl={isOpenOrClosed === OpenClosedFilter.Closed ? 3 : 0}
        transition="all 0.25s"
        height="2rem"
        alignItems="center"
        justifyContent={
          isOpenOrClosed === OpenClosedFilter.Open ? "flex-start" : "flex-end"
        }
        borderRadius="full"
        backgroundColor={
          isOpenOrClosed === OpenClosedFilter.Open
            ? colorMode === "dark"
              ? "green.400"
              : "green.700"
            : colorMode === "dark"
            ? "orange.400"
            : "orange.700"
        }
        onClick={() => {
          dispatch(
            setActiveConversationOpenClosedFilter(
              isOpenOrClosed === OpenClosedFilter.Open
                ? OpenClosedFilter.Closed
                : OpenClosedFilter.Open
            )
          );
        }}
        _after={
          isOpenOrClosed === OpenClosedFilter.Open && !isBaseSize
            ? {
                content: "'Open'",
                color: "white",
                ml: 2,
                width: "100%",
                justifySelf: "flex-end",
              }
            : {}
        }
        _before={
          isOpenOrClosed === OpenClosedFilter.Closed && !isBaseSize
            ? {
                content: "'Closed'",
                color: "white",
                mr: 2,
                width: "100%",
                justifySelf: "flex-start",
              }
            : {}
        }
      >
        <IconButton
          aria-label="lock"
          borderRadius="full"
          size="sm"
          colorScheme={
            isOpenOrClosed === OpenClosedFilter.Open ? "green" : "orange"
          }
          isActive={colorMode === "dark"}
          icon={
            <Icon
              as={
                isOpenOrClosed === OpenClosedFilter.Open
                  ? OpenLockIcon
                  : ClosedLockIcon
              }
            />
          }
        />
      </Flex>
    </Tooltip>
  );
};

export default OpenClosedToggle;
