import React, { memo, useEffect, useState } from "react";
import {
  Avatar,
  AvatarBadge,
  Icon,
  useColorMode,
  StyleProps,
} from "@chakra-ui/react";
import { ReactSVG } from "react-svg";
import { isIconFillable } from "util/constants";
import { useAppSelector } from "redux/hooks";

type ProfileAvatarProps = {
  name?: string;
  channelIcon?: string;
  assignedPerson?: any;
  profilePicture: string;
  channelPlacement?: string;
  onClick?: () => void;
  avatarStyle?: StyleProps;
};

const ProfileAvatar = ({
  channelIcon,
  channelPlacement = "bottom-end",
  name,
  profilePicture,
  assignedPerson,
  onClick,
  avatarStyle,
}: ProfileAvatarProps) => {
  const { colorMode } = useColorMode();
  const { colorScheme } = useAppSelector((state) => state.theme);

  const [channelColor, setChannelColor] = useState<string>(
    colorMode === "light" ? `${colorScheme}.500` : `${colorScheme}.200`
  );

  useEffect(() => {
    setChannelColor(
      colorMode === "light" ? `${colorScheme}.500` : `${colorScheme}.200`
    );
  }, [colorMode, colorScheme]);

  return (
    <Avatar
      key={`${name}-${channelIcon}`}
      size="sm"
      src={profilePicture}
      name={name}
      onClick={onClick}
      {...avatarStyle}
    >
      {channelIcon ? (
        <AvatarBadge
          placement={channelPlacement as any}
          boxShadow={colorMode === "dark" ? "none" : "0px -1px 5px 3px white"}
          backgroundColor={colorMode === "dark" ? "inherit" : "white"}
          border={colorMode === "dark" ? "none" : "1px solid white"}
        >
          <Icon
            as={ReactSVG}
            src={channelIcon}
            boxSize={assignedPerson ? "1.25rem" : "1rem"}
            __css={{
              path: isIconFillable(channelIcon)
                ? {
                    fill: channelColor,
                  }
                : {},
            }}
          />
        </AvatarBadge>
      ) : null}
      {assignedPerson ? (
        <AvatarBadge
          placement={
            channelPlacement === "bottom-end" ? "top-end" : "bottom-end"
          }
          cursor="pointer"
          border="none"
        >
          {assignedPerson}
        </AvatarBadge>
      ) : null}
    </Avatar>
  );
};

export default memo(ProfileAvatar);
