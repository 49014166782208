export default class AgentNotificationConfigDomain {
  handle: string | null;

  contactChannel: string;

  preference: string;

  strategy: string;

  timezone: string;

  constructor(
    handle: string | null,
    contactChannel: string,
    preference: string,
    strategy: string,
    timezone: string
  ) {
    this.handle = handle;
    this.contactChannel = contactChannel;
    this.preference = preference;
    this.strategy = strategy;
    this.timezone = timezone;
  }
}
