import {
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Icon,
  Input,
  InputGroup,
  InputLeftAddon,
  Tooltip,
  useBreakpointValue,
  useColorMode,
} from "@chakra-ui/react";
import { InfoIcon } from "@chakra-ui/icons";
import React, { memo, useEffect, useState } from "react";
import { useAppSelector } from "redux/hooks";
import { getReactSelectStyles } from "util/methods";
import Select, { SingleValue } from "react-select";
import { TemplateCategory } from "entities/domain/templates/full_template";
import { ConversationChannel } from "entities/domain/conversations/conversation-domain";

interface SelectCategoryOption {
  value: TemplateCategory;
  label: string;
}

interface TemplateSettingsProps {
  isDisabled: boolean;
  templateTitle: string;
  setTemplateTitle: (title: string) => void;
  templateShortcut: string | null;
  setTemplateShortcut: (shortcut: string | null) => void;
  templateChannels: string[];
  setTemplateChannels: (channels: string[]) => void;
  templateSubject: string | null;
  setTemplateSubject: (subject: string | null) => void;
  templateCategory: TemplateCategory | null;
  setTemplateCategory: (category: TemplateCategory | null) => void;
}

const capitalizeFirstLetter = (string: string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

const TemplateSettings = ({
  isDisabled,
  templateTitle,
  setTemplateTitle,
  templateShortcut,
  setTemplateShortcut,
  templateChannels,
  setTemplateChannels,
  templateSubject,
  setTemplateSubject,
  templateCategory,
  setTemplateCategory,
}: TemplateSettingsProps) => {
  const { merchant } = useAppSelector((state) => state.merchant);
  const { colorMode } = useColorMode();
  const { colorScheme } = useAppSelector((state) => state.theme);
  const isBaseSize = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );

  const categoryOptions: SelectCategoryOption[] = Object.values(
    TemplateCategory
  ).map((category: TemplateCategory) => ({
    label: capitalizeFirstLetter(category),
    value: category,
  }));

  const [selectedCategoryValue, setSelectedCategoryValue] = useState<
    SingleValue<SelectCategoryOption>
  >(categoryOptions.find(({ value }) => value === templateCategory) || null);

  const handleCategoryChange = (
    selectedOption: SingleValue<SelectCategoryOption>
  ) => {
    if (!selectedOption) {
      return;
    }

    setSelectedCategoryValue(selectedOption);
    setTemplateCategory(selectedOption.value);
  };

  useEffect(() => {
    setSelectedCategoryValue(
      categoryOptions.find(({ value }) => value === templateCategory) || null
    );
  }, [templateCategory]);

  return (
    <>
      <FormControl>
        <FormLabel>Title</FormLabel>
        <Input
          id="template-title-input"
          colorScheme={colorScheme}
          value={templateTitle}
          onChange={(e) => setTemplateTitle(e.target.value)}
          isDisabled={isDisabled}
        />
      </FormControl>
      <FormControl>
        <Flex alignItems="center">
          <FormLabel>Shortcut</FormLabel>
          <Tooltip label="Send templates by inserting / followed by your shortcut e.g. “/shortcut">
            <Icon
              as={InfoIcon}
              ml={1}
              color={colorMode === "dark" ? "gray.200" : "gray.500"}
            />
          </Tooltip>
        </Flex>
        <InputGroup>
          <InputLeftAddon borderLeftRadius="full">/</InputLeftAddon>
          <Input
            id="template-shortcut-input"
            colorScheme={colorScheme}
            value={templateShortcut || ""}
            onChange={(e) => setTemplateShortcut(e.target.value)}
            isDisabled={isDisabled}
          />
        </InputGroup>
      </FormControl>
      <FormControl>
        <FormLabel>Channels</FormLabel>
        <HStack
          spacing={5}
          flexWrap="wrap"
          justifyContent={isBaseSize ? "center" : "start"}
        >
          {[...new Set(merchant?.channels)]
            .filter((chan) => chan.channelName !== ConversationChannel.WHATSAPP)
            .map((chan) => {
              return (
                <Tooltip
                  key={chan.id}
                  hasArrow
                  label="Templates used with WhatsApp must be submitted to WhatsApp for approval. WhatsApp usually approve these within 48 hours, after which it will become available for use."
                  isDisabled={isBaseSize || chan.channelName !== "whatsapp"}
                  shouldWrapChildren
                >
                  <Checkbox
                    key={chan.id}
                    spacing={0}
                    id={`template-channel-${chan.channelName}`}
                    flexDir="column-reverse"
                    size="sm"
                    value={chan.channelName}
                    isDisabled={isDisabled}
                    isChecked={templateChannels.includes(chan.channelName)}
                    onChange={(e) =>
                      e.target.checked
                        ? setTemplateChannels([
                            ...(templateChannels || []),
                            e.target.value,
                          ])
                        : setTemplateChannels(
                            templateChannels.filter(
                              (ch) => ch !== e.target.value
                            )
                          )
                    }
                  >
                    {chan.getDisplayChannelName()}
                  </Checkbox>
                </Tooltip>
              );
            })}
        </HStack>
      </FormControl>

      {templateChannels.includes("email") && (
        <FormControl>
          <FormLabel>Email Subject</FormLabel>
          <Input
            colorScheme={colorScheme}
            isDisabled={isDisabled}
            value={templateSubject || ""}
            onChange={(e) => setTemplateSubject(e.target.value)}
          />
        </FormControl>
      )}
      <FormControl>
        <FormLabel>Choose category</FormLabel>
        <Select
          id="template-category-select"
          placeholder="Select category"
          isDisabled={isDisabled}
          onChange={handleCategoryChange}
          isClearable={false}
          options={categoryOptions}
          value={selectedCategoryValue}
          styles={getReactSelectStyles(colorMode, colorScheme)}
        />
      </FormControl>
    </>
  );
};

export default memo(TemplateSettings);
