import YapilyBankMediaDomain from "./yapilyBankMedia";

export default class YapilyBankDomain {
  bank_id: string;

  bank_name: string;

  bank_full_name: string;

  bank_media: YapilyBankMediaDomain[];

  popular: boolean;

  constructor(
    bank_id: string,
    bank_name: string,
    bank_full_name: string,
    bank_media: YapilyBankMediaDomain[],
    popular: boolean
  ) {
    this.bank_id = bank_id;
    this.bank_name = bank_name;
    this.bank_full_name = bank_full_name;
    this.bank_media = bank_media;
    this.popular = popular;
  }
}
